import translate from 'i18n-translations/translate.jsx';
import { useIntl } from 'react-intl';
import { AiConfigCheckbox } from 'components/AI/FeatureFlags/index.js';

const AiConfigCheckboxList = ({
	array,
	onCheckboxChange,
	onSetAsDefault = () => null,
	arrayName,
	header = '',
	title = '',
	subtitle = '',
	errors = null,
	shouldSetAsDefault = true,
	shouldWrapVertically = false,
	shouldShowError = true,
}) => {
	const intl = useIntl();
	return (
		<div className='top-15'>
			{header && (
				<p className='bottom-5'>
					{translate(header, {
						value: intl.formatMessage({ id: title }),
					})}
				</p>
			)}

			<div className='doctor-profile-settings ai-configurations'>
				{subtitle && <p className='bottom-5'>{translate(subtitle)}</p>}
				<div className={!shouldWrapVertically ? 'flex flex-wrap' : ''}>
					{array.map(aiConfig => (
						<AiConfigCheckbox
							key={aiConfig.value}
							aiConfig={aiConfig}
							arrayName={arrayName}
							onCheckboxChange={onCheckboxChange}
							onSetAsDefault={onSetAsDefault}
							shouldSetAsDefault={shouldSetAsDefault}
						/>
					))}
				</div>
				{errors && shouldShowError && <span className='red-error flex margin-top-s'>{errors}</span>}
			</div>
		</div>
	);
};

export default AiConfigCheckboxList;
