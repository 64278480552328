import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import translate from 'i18n-translations/translate.jsx';
import Form from 'components/Common/Form.jsx';
import Alert from 'components/Common/Alert.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import Modal from 'components/Common/Modal.jsx';
import { uploadDigitalBackgroud } from 'api/configurations.js';
import { getBlobSasPicture } from 'api/doctors.js';
import { updateTeamSettings } from 'api/adminConfigurations.js';
import { getConfigurationValue } from 'infrastructure/helpers/commonHelpers.js';
import Button from 'components/Common/Button.jsx';
import { FileTypes } from 'constants/enums.js';
import { validateFile } from 'infrastructure/helpers/mediaHelper.js';
import classNames from 'classnames';

const BackgroundImage = ({
	selectedHealthSystemId,
	setWallpaperBackgroundConfigs,
	wallpaperBackgroundConfigs,
	settingKey,
	container,
	title,
	description,
}) => {
	const intl = useIntl();
	const helloWallpaper = wallpaperBackgroundConfigs[settingKey].value;
	const [error, setError] = useState(null);
	const [errorMessage, setErrorMessage] = useState('');
	const [backgroundImage, setBackgroundImage] = useState(null);
	const [selectedPhotoId, setSelectedPhotoId] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [isSettingChecked, setIsSettingChecked] = useState(helloWallpaper !== 'false');

	const onImageAdded = async event => {
		const input = event.target;
		const file = event.target.files[0];
		if (!validateFile(file, intl, setErrorMessage)) {
			input.value = '';
			return;
		}

		setIsLoading(true);
		setError(null);
		const formData = new FormData();
		formData.append('File', file);
		const response = await uploadDigitalBackgroud(formData, container);
		if (response.error) {
			setError(response.error.message);
			setIsLoading(false);
			return;
		}
		await uploadImage(response.fileName, response.originalUrl);
	};

	const uploadImage = async (fileName, originalUrl) => {
		setError(null);
		setIsLoading(true);
		const params = {
			teamSettings: [
				{
					settingTypeId: settingKey,
					value: fileName,
					isLocked: false,
				},
			],
			teamId: selectedHealthSystemId,
			isOverride: false,
			healthSystemId: selectedHealthSystemId,
		};
		const responseOrg = await updateTeamSettings(params);
		if (responseOrg.error) {
			setError(responseOrg.error.message);
			setSelectedPhotoId(null);
			setIsLoading(false);
			return;
		}
		setWallpaperBackgroundConfigs({
			...wallpaperBackgroundConfigs,
			[settingKey]: {
				...wallpaperBackgroundConfigs[settingKey],
				value: fileName,
			},
		});

		setBackgroundImage(originalUrl ? { url: originalUrl } : null);
		setSelectedPhotoId(null);
		setIsLoading(false);
	};

	useEffect(() => {
		const fetchImages = async () => {
			if (!helloWallpaper || helloWallpaper === 'false') {
				setBackgroundImage(null);
				return;
			}
			const response = await getBlobSasPicture(helloWallpaper, container);

			if (!response.error) {
				setBackgroundImage({ url: response.result.uri.uri });
				return;
			}
			setError(response.error.message);
		};
		fetchImages();
	}, [helloWallpaper, selectedHealthSystemId]);

	const handleSwitch = () => {
		setIsSettingChecked(!isSettingChecked);
		uploadImage('false');
		setSelectedPhotoId(null);
	};

	return (
		<>
			<Form className='digital-background hello-background'>
				<h3>{title}</h3>
				<div className='flex call-config-settings'>
					<div className='call-config-type'>
						<div>
							<img src={`${healthCareCdnUrl}admin/background-image-icon.svg`} alt='icon' />
						</div>
						<div className='call-config-description'>
							<p>{title}</p>
							<span>{description}</span>
						</div>
					</div>
					<div className='toggle-switch call-config-toggle' onClick={handleSwitch}>
						<input type='checkbox' onChange={() => null} checked={isSettingChecked} />
						<span className='toggle-body' />
					</div>
				</div>
				<div className='present-images'>
					<div className='background-images flex'>
						{isSettingChecked && (
							<>
								{!backgroundImage && (
									<div className={classNames('add-background-image', { 'uploading-error': errorMessage })}>
										<label htmlFor='hello-wallpaper-upload'>
											<img src={`${healthCareCdnUrl}admin/add-image-icon.svg`} alt='icon' />
											<span className='virtual-background-text'>{translate('addImage')}</span>
										</label>
										<input
											type='file'
											accept={`${FileTypes.PNG}, ${FileTypes.JPEG}`}
											id='hello-wallpaper-upload'
											name='hello-wallpaper-upload'
											onChange={onImageAdded}
											className='display-none'
										/>
									</div>
								)}
								{backgroundImage && (
									<div className='background-item'>
										<img src={backgroundImage.url} alt={intl.formatMessage({ id: 'backgroundImage' })} />
										<Button
											size='small'
											svgIcon={<i className='fa fa-trash'></i>}
											className='delete-img-button'
											onClick={() => setSelectedPhotoId(getConfigurationValue(wallpaperBackgroundConfigs[settingKey]))}
										/>
									</div>
								)}
							</>
						)}
					</div>
					{errorMessage && <p className='error'>{errorMessage}</p>}
				</div>
			</Form>
			<Modal
				display={selectedPhotoId}
				onModalSubmit={() => uploadImage('false')}
				onModalClose={() => setSelectedPhotoId(null)}
				isLoading={isLoading}
				position='center'
				primaryButtonLabel={translate('delete')}>
				<form>
					<h3>{translate('delete')}</h3>
					<p>
						{translate('areYouSureDeleteItem', {
							value: intl.formatMessage({ id: 'backgroundImage' }),
						})}
					</p>
				</form>
			</Modal>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</>
	);
};

export default BackgroundImage;
