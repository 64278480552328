import classNames from 'classnames';
import { MeasurementTypes, UnitCategoryTypes } from 'constants/enums.js';
import { getAlertClassName, NoMeasurementsValueText, vsmImageUrl } from 'constants/rpm.js';
import { convertMeasurementTypes } from 'infrastructure/helpers/measurementsHelper.js';
import { useSelector } from 'react-redux';

const LifeSignalsMeasurements = ({ lifeSignalsAlerts, measurements, patient, currentMeasurement }) => {
	const preferredUnits = useSelector(state => state.user.unitPreferences);

	const getUnitPreference = categoryId => {
		const selectedPreference = getCategoryPreference(categoryId);
		return selectedPreference?.options.find(item => item.unitSystemId === selectedPreference.unitSystemId);
	};

	const getCategoryPreference = categoryId => preferredUnits.find(item => item.unitCategoryId === categoryId);

	const getMeasurementValue = (item, patientMeasurements) => {
		if (item.type === MeasurementTypes.BLOOD_PRESSURE) {
			return patientMeasurements?.BPSYS && patientMeasurements?.BPDIA
				? `${patientMeasurements.BPSYS}/${patientMeasurements?.BPDIA}`
				: NoMeasurementsValueText;
		}
		if (item.type === MeasurementTypes.TEMPERATURE) {
			return patientMeasurements?.BODYTEMP
				? convertMeasurementTypes(
						UnitCategoryTypes.TEMPERATURE,
						patientMeasurements?.BODYTEMP,
						getUnitPreference(UnitCategoryTypes.TEMPERATURE)?.unitSystemId
				  )
				: NoMeasurementsValueText;
		}
		return patientMeasurements?.[item.value] || NoMeasurementsValueText;
	};

	const getMeasurementUnit = (item, patientMeasurements) => {
		if (getMeasurementValue(item, patientMeasurements) === NoMeasurementsValueText) {
			return '';
		}
		if (item.type === MeasurementTypes.TEMPERATURE) {
			return getUnitPreference(UnitCategoryTypes.TEMPERATURE)?.unit || item.unit;
		}
		return item.unit;
	};

	const foundAlert = lifeSignalsAlerts ? lifeSignalsAlerts.result.find(alert => alert[0] === currentMeasurement.value) : null;
	const patientMeasurements = measurements[patient.userId] || {};
	return (
		<div
			className={classNames(
				'patient-alert-body-vs position-relative',
				foundAlert && lifeSignalsAlerts.userId === patient.userId ? getAlertClassName(foundAlert) : ''
			)}
			key={currentMeasurement.id}>
			<div className='flex'>
				<img src={`${vsmImageUrl}${currentMeasurement.img}.svg`} alt='icon' />
				<p>{getMeasurementValue(currentMeasurement, patientMeasurements)}</p>
				<span className='left-s'>{getMeasurementUnit(currentMeasurement, patientMeasurements)}</span>
			</div>
		</div>
	);
};

export default LifeSignalsMeasurements;
