import { saveManualAlert } from 'api/alerts.js';
import { getDeviceOwnerPatient } from 'api/patients.js';
import classNames from 'classnames';
import { AiAlertsOnInterventions } from 'components/AI/Monitoring/index.js';
import Button from 'components/Common/Button.jsx';
import { Alert, CustomTextarea } from 'components/index.js';
import Drafts from 'components/Monitoring/Drafts.jsx';
import { AlertTypes } from 'constants/enums.js';
import { generateCustomStyles } from 'constants/react-select-style.js';
import translate from 'i18n-translations/translate.jsx';
import { AlertIcon, ListIcon, PatientHistoryIcon, PlusIcon } from 'icons/Monitoring/index.js';
import { getMonitoringActiveIconColor, getMonitoringIconColor } from 'infrastructure/helpers/monitoringTimelineHelpers.js';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import SpeechToText from 'components/SpeechToText/SpeechToText.jsx';
import ManualAlertsListMayo from '../Monitoring/ManualAlertsListMayo.jsx';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from './Tabs.jsx';

const InterventionsMonitoring = ({
	patientId,
	deviceId,
	careEvents,
	feed,
	numberOfFeeds,
	setSnoozeType,
	stopVoiceOver,
	showForwardToNurses,
	onForwardToNurses,
	conferenceId,
	onAlertInfoClose,
	loadMoreCareEventOptions,
}) => {
	const intl = useIntl();
	const user = useSelector(state => state.user);
	const [currentTab, setCurrentTab] = useState(0);
	const [expandedBox, setExpandedBox] = useState(true);
	const [error, setError] = useState(null);
	const [selectedAlertType, setSelectedAlertType] = useState(null);
	const [recognizedTranscription, setRecognizedTranscription] = useState('');
	const [liveTranscription, setLiveTranscription] = useState('');
	const [isSpeechToText, setIsSpeechToText] = useState(false);
	const [isManualInterventionAdding, setIsManualInterventionAdding] = useState(false);
	const patientNotes = useSelector(state => state.patientNotes.savedMode);
	const draftsCount = patientNotes[deviceId]?.draftsCount;

	useEffect(() => {
		const historyTab = 2;
		const historyTabWithoutDrafts = 1;
		if (draftsCount === 0 && currentTab === historyTab) {
			setCurrentTab(historyTabWithoutDrafts);
		}
	}, [currentTab, draftsCount]);

	const handleSubmit = async () => {
		if (isManualInterventionAdding) {
			return;
		}
		let existingPatientId = patientId;
		if (!patientId) {
			const deviceOwnerResponse = await getDeviceOwnerPatient(deviceId);
			if (deviceOwnerResponse.error) {
				setError(deviceOwnerResponse.error.message);
				setIsManualInterventionAdding(false);
				return;
			}
			existingPatientId = deviceOwnerResponse?.healthcareUserId;
		}
		const dataToSend = {
			patientId: existingPatientId,
			deviceId,
			manualAlertTypeId: selectedAlertType.value,
			comment: recognizedTranscription,
			isDraft: false,
			conferenceId,
		};
		const response = await saveManualAlert(dataToSend);
		if (response.error) {
			setError(response.error.message);
			setIsManualInterventionAdding(false);
			return;
		}
		setSelectedAlertType(null);
		setRecognizedTranscription('');
		setLiveTranscription('');
		setIsSpeechToText(false);
		setIsManualInterventionAdding(false);
	};

	return (
		<div className='monitoring-timeline-box interventions'>
			<div className={classNames('timeline-box-header', expandedBox ? 'expanded' : '')}>
				<p className='timeline-box-title'>
					<AlertIcon />
					{translate('interventionsAndAlerts')}
				</p>
				<div className='timeline-box-actions'>
					<Button
						border='none'
						onClick={() => setExpandedBox(prevState => !prevState)}
						icon={expandedBox ? 'expand_less' : 'expand_more'}
					/>
				</div>
			</div>
			{expandedBox && (
				<div className={classNames('timeline-box-content', expandedBox ? 'expanded' : '')}>
					<Tabs activeIndex={currentTab} onChange={index => setCurrentTab(index)}>
						<TabList>
							<Tab className='cursor-pointer'>
								<PlusIcon
									color={currentTab === 0 ? getMonitoringActiveIconColor(user.darkMode) : getMonitoringIconColor(user.darkMode)}
								/>
								{translate('add')}
							</Tab>
							{patientNotes[deviceId]?.draftsCount > 0 && (
								<Tab className='cursor-pointer'>
									<ListIcon
										color={currentTab === 1 ? getMonitoringActiveIconColor(user.darkMode) : getMonitoringIconColor(user.darkMode)}
									/>
									{translate('drafts')}
									<p className='drafts-count' data-position='bottom' data-tooltip='Undocumented interventions'>
										{patientNotes[deviceId]?.draftsCount}
									</p>
								</Tab>
							)}
							<Tab className='cursor-pointer'>
								<PatientHistoryIcon
									color={currentTab === 2 ? getMonitoringActiveIconColor(user.darkMode) : getMonitoringIconColor(user.darkMode)}
								/>
								{translate('history')}
							</Tab>
						</TabList>
						<TabPanels>
							<TabPanel>
								<div className='add-interventions-and-alerts'>
									{careEvents.length === 0 && (
										<>
											<p className='no-interventions'>{translate('noInterventions')}</p>
											<p className='no-interventions'>{translate('askAdministratorForInterventions')}</p>
										</>
									)}
									<ReactSelect
										options={careEvents?.map(item => ({
											label: item.name,
											value: item.id,
										}))}
										value={selectedAlertType}
										onChange={item => setSelectedAlertType(item)}
										id='select'
										classNamePrefix='react-select'
										styles={{
											...generateCustomStyles({ darkMode: user.darkMode, menuMaxHeight: 175, menuListMaxHeight: 175 }),
										}}
										onMenuScrollToBottom={loadMoreCareEventOptions}
									/>
									{selectedAlertType && (
										<CustomTextarea
											disabled={!selectedAlertType || isSpeechToText || careEvents.length === 0}
											maxLength={199}
											onChange={event => setRecognizedTranscription(event.target.value)}
											value={isSpeechToText ? `${recognizedTranscription} ${liveTranscription}`.trim() : recognizedTranscription}
											maxNumberLimit={40}
											rows={5}
											name='text'
											placeholder={intl.formatMessage({ id: 'addAdditionalNotes' })}
											charactersCounter={false}
											icon={
												<SpeechToText
													setRecognizedTranscription={setRecognizedTranscription}
													setLiveTranscription={setLiveTranscription}
													setIsSpeechToText={setIsSpeechToText}
													isSpeechToText={isSpeechToText}
													isDisabled={careEvents.length === 0 || !selectedAlertType}
												/>
											}
											saveBtn={
												careEvents.length > 0 && (
													<Button
														disabled={!selectedAlertType || !!liveTranscription}
														onClick={() => {
															setIsManualInterventionAdding(true);
															handleSubmit();
														}}
														icon={!isManualInterventionAdding ? 'done' : ''}
														text={translate('save')}
														isLoading={isManualInterventionAdding}
													/>
												)
											}
										/>
									)}
									{feed?.activeAlerts?.length > 0 && (
										<AiAlertsOnInterventions
											showForwardToNurses={showForwardToNurses}
											warningObject={feed.warning}
											alertType={AlertTypes.DANGER}
											isFeedExpanded={true}
											onAlertCloseClick={alertTypeId => stopVoiceOver({ alertTypeId, deviceId: feed.deviceId })}
											onAlertInfoClose={onAlertInfoClose}
											numberOfFeeds={numberOfFeeds}
											feedDeviceId={feed.deviceId}
											setSnoozeType={setSnoozeType}
											onForwardToNurses={onForwardToNurses}
											activeAlerts={feed?.activeAlerts}
											conferenceId={conferenceId}
										/>
									)}
								</div>
							</TabPanel>
							{patientNotes[deviceId]?.draftsCount > 0 && (
								<TabPanel>
									<Drafts patientId={patientId} deviceId={deviceId} />
								</TabPanel>
							)}
							<TabPanel>
								<ManualAlertsListMayo patientId={patientId} deviceId={deviceId} roomId={feed.roomId} />
							</TabPanel>
						</TabPanels>
					</Tabs>
				</div>
			)}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</div>
	);
};

export default InterventionsMonitoring;
