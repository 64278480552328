import { deleteDevice, deletePatientDeviceOwner, sendCommand } from 'api/devices.js';
import { UserRoles } from 'constants/enums.js';
import AssignDeviceModal from 'components/Common/AssignDeviceModal.jsx';
import Dropdown from 'components/Common/Dropdown.jsx';
import Form from 'components/Common/Form.jsx';
import Input from 'components/Common/Input.jsx';
import Modal from 'components/Common/Modal.jsx';
import RebootLogExport from 'components/Common/RebootLogsExport.jsx';
import { DeviceCommands } from 'constants/enums.js';
import PairUnpairDeviceRemote from 'containers/Common/PairUnpairDeviceRemote.jsx';
import translate from 'i18n-translations/translate.jsx';
import { getUserRole } from 'infrastructure/auth.js';
import { findSectorById } from 'infrastructure/helpers/commonHelpers.js';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

const DeviceActions = props => {
	const intl = useIntl();
	const userRole = getUserRole();
	const healthSystems = useSelector(state => state.healthSystems);
	const [selectedDevice, setSelectedDevice] = useState({
		serialNumber: '',
		deviceId: '',
		pairedRemote: null,
		defaultCamType: '',
	});
	const [isDeviceRemoteModalOpen, setIsDeviceRemoteModalOpen] = useState(false);
	const [isGetDeviceLogsOpen, setIsGetDeviceOpen] = useState(false);
	const [isDeviceOnline, setIsDeviceOnline] = useState(false);
	const [isRebootDeviceOpen, setIsRebootDeviceOpen] = useState(false);
	const [rebootReason, setRebootReason] = useState('');
	const [showAssignDeviceModal, setShowAssignDeviceModal] = useState(false);
	const [showUnassignDeviceModal, setShowUnassignDeviceModal] = useState(false);
	const [isDeleteDeviceModalOpen, setIsDeleteDeviceModalOpen] = useState(false);
	const [error, setError] = useState(null);

	const toggleDeviceRemotePairModal = (device = { serialNumber: '', deviceId: '', pairedRemote: '', defaultCamType: '' }) => {
		setIsDeviceRemoteModalOpen(prevState => !prevState);
		setSelectedDevice(device);
	};

	const sendPullLogsCommand = async (isOnline, deviceId) => {
		if (isOnline) {
			const response = await sendCommand({ deviceId, command: DeviceCommands.PULL_LOGS, dynamicData: 'logcat' });
			setIsGetDeviceOpen(true);
			setError(!!response.error);
		} else {
			setIsGetDeviceOpen(true);
			setError(null);
		}
		setIsDeviceOnline(isOnline);
	};

	const getModalText = () => {
		if (!isDeviceOnline) {
			return translate('modalLogsFailure');
		}
		if (error) {
			return translate('modalLogsWentWrong');
		}
		return translate('modalLogsSuccess');
	};

	const rebootDevice = async () => {
		if (selectedDevice.deviceId) {
			const params = {
				deviceId: selectedDevice.deviceId,
				command: DeviceCommands.REBOOT,
				reason: rebootReason,
			};
			const response = await sendCommand(params);
			if (response.error) {
				setError(intl.formatMessage({ id: 'errorDuringRequestProcessing' }));
			}
		}
		setIsRebootDeviceOpen(false);
	};

	const unAssignDevice = async () => {
		props.setIsDeleteDeviceModalLoading(true);
		const response = await deleteDevice(selectedDevice.deviceId);
		if (response.error) {
			setError(response.error.message);
		} else {
			props.setDevices(prevState => prevState.filter(item => item.solHelloDeviceId !== props.device.solHelloDeviceId));
			setIsDeleteDeviceModalOpen(prevState => !prevState);
			props.fetchTreeData();
			if (props.fetchUnassociatedDevices) {
				props.fetchUnassociatedDevices();
			}
		}
		props.setIsDeleteDeviceModalLoading(false);
	};

	const unAssignPatient = async () => {
		props.setIsDeleteDeviceModalLoading(true);
		const response = await deletePatientDeviceOwner(selectedDevice.deviceId);
		if (response.error) {
			setError(response.error.message);
		} else {
			setShowUnassignDeviceModal(prevState => !prevState);
			props.fetchTreeData();
			if (props.loadRoom) {
				props.loadRoom();
			}
		}
		props.setIsDeleteDeviceModalLoading(false);
	};

	const requestAllowlist = async () => {
		const params = {
			deviceId: props.device.solHelloDeviceId,
			command: DeviceCommands.REQUEST_ALLOWLIST,
			reason: null,
		};
		const response = await sendCommand(params);
		if (response.error) {
			setError(intl.formatMessage({ id: 'errorDuringRequestProcessing' }));
		}
	};

	return (
		<>
			<Dropdown position='bottom' icon='more_horiz' className='device-options-dropdown'>
				<div className='dropdown__items device-options-wrapper'>
					<ul className='list-group'>
						<div
							className='device-option-details'
							onClick={() =>
								toggleDeviceRemotePairModal({
									deviceId: props.device.solHelloDeviceId,
									serialNumber: props.device.serialNumber,
									pairedRemote: props.device.pairedRemote,
									defaultCamType: '',
								})
							}>
							<i
								className='material-icons-outlined boxed-icon device-icon'
								id={props.device.solHelloDeviceId}
								data-position='top'>
								settings_remote
							</i>
							<span>{intl.formatMessage({ id: 'remoteControl' })}</span>
						</div>
						{[UserRoles.ADMIN, UserRoles.SUPER_USER].includes(userRole) && (
							<>
								<div
									className='device-option-details'
									onClick={() => sendPullLogsCommand(props.device.isOnline, props.device.solHelloDeviceId)}>
									<i className='material-icons-outlined boxed-icon device-icon' id={props.deviceId} data-position='top'>
										assessment
									</i>
									<span>{intl.formatMessage({ id: 'modalLogsButton' })}</span>
								</div>
								<RebootLogExport solHelloDeviceId={props.device.solHelloDeviceId} serialNumber={props.device.serialNumber} />
								<div className='device-option-details' onClick={requestAllowlist}>
									<i
										className='material-icons-outlined boxed-icon device-icon'
										id={props.device.solHelloDeviceId}
										data-position='top'>
										check
									</i>
									<span>{intl.formatMessage({ id: 'endpointWhitelisting' })}</span>
								</div>
								<div
									className='device-option-details'
									onClick={() => {
										setIsRebootDeviceOpen(true);
										setSelectedDevice({
											deviceId: props.device.solHelloDeviceId,
											serialNumber: props.device.serialNumber,
											pairedRemote: props.device.pairedRemote,
											defaultCamType: '',
										});
										setRebootReason('');
									}}>
									<i
										className='material-icons-outlined boxed-icon device-icon'
										id={props.device.solHelloDeviceId}
										data-position='top'>
										refresh
									</i>
									<span>{intl.formatMessage({ id: 'rebootDevice' })}</span>
								</div>
								{userRole !== UserRoles.SUPER_USER && (
									<>
										<div
											className='device-option-details'
											onClick={() => {
												props.setHelloDeviceId(props.device.solHelloDeviceId);
												setShowAssignDeviceModal(true);
											}}>
											<i
												className='material-icons boxed-icon device-icon' 
												id={props.device.solHelloDeviceId}
												data-position='top'>
												swap_vert
											</i>
											<span>{intl.formatMessage({ id: 'reAssignDevice' })}</span>
										</div>
										{!props.isPatientDisabled && (
											<div
												className='device-option-details'
												onClick={() => {
													setSelectedDevice({
														deviceId: props.device.solHelloDeviceId,
														serialNumber: props.device.serialNumber,
														pairedRemote: props.device.pairedRemote,
														defaultCamType: '',
													});
													setShowUnassignDeviceModal(true);
												}}>
												<i
													className='material-icons boxed-icon device-icon' 
													id={props.device.solHelloDeviceId}
													data-position='top'>
													person_remove
												</i>
												<span>{intl.formatMessage({ id: 'unassignPatient' })}</span>
											</div>
										)}
									</>
								)}
							</>
						)}
						{userRole !== UserRoles.SUPER_USER && (
							<div
								className='device-option-details'
								onClick={() => {
									setSelectedDevice({
										deviceId: props.device.solHelloDeviceId,
										serialNumber: props.device.serialNumber,
										pairedRemote: props.device.pairedRemote,
										defaultCamType: '',
									});
									setIsDeleteDeviceModalOpen(prevState => !prevState);
								}}>
								<i
									className='material-icons-outlined boxed-icon device-icon'
									id={props.device.solHelloDeviceId}
									data-position='top'>
									delete
								</i>
								<span>{intl.formatMessage({ id: 'unassignDevice' })}</span>
							</div>
						)}
					</ul>
				</div>
			</Dropdown>
			{isDeviceRemoteModalOpen && (
				<PairUnpairDeviceRemote
					room={findSectorById(healthSystems.treeData.tree, props.device.solHelloDeviceId)}
					device={selectedDevice}
					onModalClose={() => {
						toggleDeviceRemotePairModal();
						props.loadRoom();
					}}
				/>
			)}
			<Modal
				className='centered-modal'
				display={isGetDeviceLogsOpen}
				position='right'
				hideActionButtons={true}
				onModalClose={() => setIsGetDeviceOpen(false)}>
				<form>
					<h3>{translate('modalLogsTitle')}</h3>
					<span>{getModalText()}</span>
				</form>
			</Modal>
			<Modal
				display={isRebootDeviceOpen}
				position='right'
				primaryButtonLabel={translate('reboot')}
				onModalSubmit={rebootDevice}
				onModalClose={() => setIsRebootDeviceOpen(false)}>
				<Form title={translate('rebootDevice')} onSubmit={event => event.preventDefault()}>
					<p>
						{translate('rebootDeviceQuestion')} {selectedDevice.serialNumber}
					</p>
					<Input
						type='text'
						value={rebootReason}
						validationOptions={{ maxLength: 100 }}
						placeholder={intl.formatMessage({ id: 'rebootDeviceReason' })}
						onChange={e => setRebootReason(e.target.value)}
					/>
				</Form>
			</Modal>
			{showAssignDeviceModal && (
				<AssignDeviceModal
					helloDeviceId={props.helloDeviceId}
					setShowAssignDeviceModal={setShowAssignDeviceModal}
					showAssignDeviceModal={showAssignDeviceModal}
					loadCurrentRoom={props.loadRoom}
				/>
			)}
			<Modal
				display={showUnassignDeviceModal}
				isLoading={props.isDeleteDeviceModalLoading}
				position='right'
				primaryButtonLabel={translate('unassignPatient')}
				onModalSubmit={unAssignPatient}
				onModalClose={() => setShowUnassignDeviceModal(prevState => !prevState)}>
				<Form title={translate('unassignPatient')} onSubmit={event => event.preventDefault()}>
					<p>{translate('sureToUnassignPatient', { value: props.device.owner })}</p>
				</Form>
			</Modal>
			<Modal
				display={isDeleteDeviceModalOpen}
				isLoading={props.isDeleteDeviceModalLoading}
				position='right'
				primaryButtonLabel={translate('unassign')}
				onModalSubmit={unAssignDevice}
				onModalClose={() => setIsDeleteDeviceModalOpen(prevState => !prevState)}>
				<Form title={translate('unassignDevice')} onSubmit={event => event.preventDefault()}>
					<p>{translate('sureToUnassignDevice', { value: selectedDevice.serialNumber })}</p>
				</Form>
			</Modal>
		</>
	);
};
export default DeviceActions;
