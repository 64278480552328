import { LocalTrack } from '@solaborate/calls/webrtc';
import { postProcessingConfiguration } from 'calls/virtual-background/config/index.js';
import * as tfBodyPix from '@tensorflow-models/body-pix';
import * as tf from '@tensorflow/tfjs';
import { createTimerWorker } from 'calls/virtual-background/helpers/timerHelper.js';
import {
	SegmentationModels,
	SegmentationPipelines,
	VirtualBackgroundBackend,
	VirtualBackgroundInputResolutions,
	VirtualBackgroundTypes,
} from 'constants/enums.js';
import { buildWebGL2Pipeline } from 'calls/virtual-background/pipelines/webgl2/webgl2Pipeline.js';
import { buildCanvas2dPipeline } from 'calls/virtual-background/pipelines/canvas2d/canvas2dPipeline.js';
import { APP_CONFIG } from 'constants/global-variables.js';
import { getTFLiteModelFileName, isDedicatedOrIntegratedGPU } from 'infrastructure/helpers/commonHelpers.js';

let segmentationConfig = {
	model: SegmentationModels.MLKIT,
	pipeline: SegmentationPipelines.WEBGL2,
	inputResolution: VirtualBackgroundInputResolutions['256x256'],
	backend: VirtualBackgroundBackend.WASM_SIMD,
	targetFps: 65, // 60 introduces fps drop and unstable fps on Chrome
	deferInputResizing: true,
};

class WebGLTrack extends LocalTrack {
	constructor(track, background) {
		super(track.type, track.track, track.source, track.constrains);

		this.stream = new MediaStream([track.track]);

		this.background = background;

		this.canvas = document.createElement('canvas');

		this.originalTrack = track;

		this.pipeline = null;

		this.timerWorker = createTimerWorker();

		this.renderTimeoutId = null;

		this.image = document.createElement('img');

		this.sourceElement = document.createElement('video');

		this.setupPipeline(background);
	}

	async setupPipeline(background) {
		if (this.pipeline) {
			this.pipeline.cleanUp();
			this.pipeline = null;
			this.timerWorker = createTimerWorker();
		}

		const targetTimerTimeoutMs = 1000 / segmentationConfig.targetFps;

		let previousTime = 0;
		let beginTime = 0;
		let eventCount = 0;
		const frameDurations = [];

		const { width, height } = this.track.getSettings() ?? this.track.getConstraints();
		this.sourceElement.srcObject = this.stream;
		this.sourceElement.autoplay = true;
		this.sourcePlayback = {
			htmlElement: this.sourceElement,
			width,
			height,
		};
		this.canvas.width = +width;
		this.canvas.height = +height;
		if (background.type === VirtualBackgroundTypes.IMAGE) {
			this.image.src = background.url;
			this.image.crossOrigin = 'anonymous';
		}

		const newPipeline =
			segmentationConfig.pipeline === SegmentationPipelines.WEBGL2
				? buildWebGL2Pipeline(
						this.sourcePlayback,
						background.type === VirtualBackgroundTypes.NONE ? null : this.image,
						background,
						segmentationConfig,
						this.canvas,
						background.tflite,
						this.timerWorker,
						addFrameEvent,
						this.renderTimeoutId
				  )
				: buildCanvas2dPipeline(
						this,
						this.sourcePlayback,
						background,
						this.image.src,
						segmentationConfig,
						this.canvas,
						bodyPix,
						background.tflite,
						addFrameEvent
				  );

		let self = this;
		newPipeline.updatePostProcessingConfig(postProcessingConfiguration);

		async function render() {
			const startTime = performance.now();

			beginFrame();
			await newPipeline.render();
			endFrame();

			self.renderTimeoutId = self.timerWorker.setTimeout(
				render,
				Math.max(0, targetTimerTimeoutMs - (performance.now() - startTime))
			);
		}

		function beginFrame() {
			beginTime = Date.now();
		}

		function addFrameEvent() {
			const time = Date.now();
			frameDurations[eventCount] = time - beginTime;
			beginTime = time;
			eventCount++;
		}

		function endFrame() {
			const time = Date.now();
			frameDurations[eventCount] = time - beginTime;
			if (time >= previousTime + 1000) {
				previousTime = time;
			}
			eventCount = 0;
		}

		this.pipeline = newPipeline;
		render();

		this.track = this.canvas.captureStream().getVideoTracks()[0];
	}

	cleanupPipeline() {
		this.timerWorker.clearTimeout(this.renderTimeoutId);
		this.timerWorker.terminate();
		if (this.pipeline) {
			this.pipeline.cleanUp();
			this.pipeline = null;
		}
		this.canvas = null;
		this.track.stop();
	}
}

export default WebGLTrack;

let tfliteGlobal = null;
let isSIMDSupported = false;
let tflite = null;
let tfliteSIMD = null;
let bodyPix = null;

export const loadTflite = async () => {
	if (tflite || tfliteSIMD) {
		return;
	}
	window.createTFLiteModule().then(res => {
		tflite = res;
	});
	try {
		const createdTFLiteSIMD = await window.createTFLiteSIMDModule();
		tfliteSIMD = createdTFLiteSIMD;
		isSIMDSupported = true;
	} catch (error) {
		console.warn('Failed to create TFLite SIMD WebAssembly module.', error);
	}
};

export const loadTfliteModel = async () => {
	if (tfliteGlobal) {
		return tfliteGlobal;
	}
	if (
		!tflite ||
		(isSIMDSupported && !tfliteSIMD) ||
		(!isSIMDSupported && segmentationConfig.backend === 'wasmSimd') ||
		(segmentationConfig.model !== 'meet' && segmentationConfig.model !== 'mlkit')
	) {
		return null;
	}

	const newTflite = segmentationConfig.backend === 'wasmSimd' ? tfliteSIMD : tflite;

	// const modelUrl = 'https://static.solaborate.com/healthcare-system/selfiesegmentation_mlkit-256x256-2021_01_19-v1215.f16.tflite'; // local use
	const modelName = await getTFLiteModelFileName(segmentationConfig.inputResolution);
	const modelUrl = `${APP_CONFIG.cdnURL}/healthcare-system/tflite-models/${modelName}.tflite`;
	console.debug('Loading tflite model');

	const modelResponse = await fetch(modelUrl);
	const model = await modelResponse.arrayBuffer();
	console.debug('Model buffer size:', model.byteLength);

	const modelBufferOffset = newTflite._getModelBufferMemoryOffset();
	console.debug('Model buffer memory offset:', modelBufferOffset);
	console.debug('Loading model buffer...');
	newTflite.HEAPU8.set(new Uint8Array(model), modelBufferOffset);
	console.debug('_loadModel result:', newTflite._loadModel(model.byteLength));

	console.debug('Input memory offset:', newTflite._getInputMemoryOffset());
	console.debug('Input height:', newTflite._getInputHeight());
	console.debug('Input width:', newTflite._getInputWidth());
	console.debug('Input channels:', newTflite._getInputChannelCount());

	console.debug('Output memory offset:', newTflite._getOutputMemoryOffset());
	console.debug('Output height:', newTflite._getOutputHeight());
	console.debug('Output width:', newTflite._getOutputWidth());
	console.debug('Output channels:', newTflite._getOutputChannelCount());
	tfliteGlobal = newTflite;
	return newTflite;
};

export const loadBodyPix = async () => {
	if (bodyPix) {
		return;
	}
	console.debug('Loading TensorFlow.js and BodyPix segmentation model');
	await tf.ready();
	bodyPix = await tfBodyPix.load();
	console.debug('TensorFlow.js and BodyPix loaded');
};

export const initWebGLPipeline = async () => {
	try {
		if (tfliteGlobal) {
			return tfliteGlobal;
		}
		console.debug('Initiating pipeline...');
		const supportsWebGL = await isDedicatedOrIntegratedGPU();
		if (!supportsWebGL) {
			segmentationConfig.model = SegmentationModels.MEET;
			segmentationConfig.pipeline = SegmentationPipelines.CANVAS2D;
			segmentationConfig.inputResolution = VirtualBackgroundInputResolutions['160x96'];
		}
		await loadBodyPix();
		await loadTflite();
		const tflite = await loadTfliteModel();
		console.debug('Pipeline initiated');
		return tflite;
	} catch (error) {
		console.error({ error });
	}

	return null;
};
