import styled from 'styled-components';

const StatWidget = styled.div`
	background: ${props => props.$backgroundColor};
	padding: var(--spacing-m);
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: var(--spacing-s);
	border-radius: var(--spacing-s);
	height: 56px;
	h4,
	p {
		margin: 0;
		padding: 0;
		color: ${props => props.$color} !important;
	}
	h4 {
		font-weight: 400;
		font-size: 15px;
	}
	p {
		font-weight: 400;
		font-size: 14px;
	}
	> div {
		display: flex;
		align-items: center;
		gap: var(--spacing-s);
	}
`;

const AiStatWidget = ({ label, value, icon, backgroundColor, color }) => (
	<StatWidget $backgroundColor={backgroundColor} $color={color}>
		<div>
			{icon}
			<h4>{label}</h4>
		</div>
		<p>{value}</p>
	</StatWidget>
);

export default AiStatWidget;
