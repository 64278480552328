import { LanguageActionTypes } from 'constants/action-types.js';
import { LOCALES } from 'i18n-translations/locales.js';

const initialState = {
	locale: LOCALES.ENGLISH,
};

export const language = (state = initialState, action = { type: null, payload: null }) => {
	switch (action.type) {
		case LanguageActionTypes.SET_USER_LANGUAGE:
			return {
				...state,
				locale: action.payload,
			};

		default:
			return state;
	}
};
