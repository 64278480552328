import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import Grid from 'components/Common/Grid.jsx';
import { ConditionType, ObservationType, PrecautionTriggerValues } from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { getPrecautionObservations, getPrecautions, getPrecautionConditions } from 'api/monitoring.js';
import { useIntl } from 'react-intl';
import { getDeviceOwnerPatient, getPatientByUserId } from 'api/patients.js';
import SocketEvents from 'constants/socket-events.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import _ from 'lodash';
import Alert from 'components/Common/Alert.jsx';
import { PrecautionsObservationCodes } from 'constants/monitoring.js';
import { getActiveEncounter } from 'api/whiteboard.js';

const initialPrecautions = [
	{
		id: '22631001',
		abbreviation: 'F',
		name: 'Falls',
		textColor: '#282D30',
		boxColor: '#F3C752',
	},
	{
		id: '77272004',
		abbreviation: 'SZ',
		name: 'Seizure',
		textColor: '#FFFFFF',
		boxColor: '#E270DA',
	},
	{
		id: '441862004',
		abbreviation: 'ISO',
		name: 'Isolation',
		textColor: '#FFFFFF',
		boxColor: '#D64F2D',
	},
	{
		id: '413322009',
		abbreviation: 'SW',
		name: 'Suicide Watch',
		textColor: '#282D30',
		boxColor: '#88D9FB',
	},
	{
		id: '26544005',
		abbreviation: 'SB',
		name: 'Suspicious Behavior',
		textColor: '#FFFFFF',
		boxColor: '#7B35C1',
	},
	{
		id: '49436004',
		abbreviation: 'HP',
		name: 'Hospice',
		textColor: '#FFFFFF',
		boxColor: '#000000',
	},
	{
		id: '71388002',
		abbreviation: 'D',
		name: 'Detox',
		textColor: '#282D30',
		boxColor: '#F2A356',
	},
	{
		id: '233604007',
		abbreviation: 'PO2',
		name: 'Pulling O2',
		textColor: '#282D30',
		boxColor: '#B6D7E4',
	},
	{
		id: '35489007',
		abbreviation: 'IC',
		name: 'Impulsive/Confused',
		textColor: '#282D30',
		boxColor: '#D3D3D3',
	},
	{
		id: '43998006',
		abbreviation: 'NR',
		name: 'Non-redirectable',
		textColor: '#FFFFFF',
		boxColor: '#4BA5F8',
	},
];

const ehrPrecautions = [
	{
		id: PrecautionsObservationCodes.FALLS_CODE,
		abbreviation: 'F',
		name: 'Falls',
		textColor: '#282D30',
		boxColor: '#FFD600',
		icon: 'fall.svg',
		isFallPrecaution: true,
	},
	{
		id: PrecautionsObservationCodes.FALL_RISK_SCORE_CODE,
		abbreviation: 'F',
		name: 'Falls',
		textColor: '#282D30',
		boxColor: '#FFD600',
		icon: 'fall.svg',
		isFallPrecaution: true,
	},
	{
		id: PrecautionsObservationCodes.FALL_RISK_SCALE_CODE,
		abbreviation: 'F',
		name: 'Falls',
		textColor: '#282D30',
		boxColor: '#FFD600',
		icon: 'fall.svg',
		isFallPrecaution: true,
	},
	{
		id: PrecautionsObservationCodes.IBED_FALL_RISK_CODE,
		abbreviation: 'F',
		name: 'Falls',
		textColor: '#282D30',
		boxColor: '#FFD600',
		icon: 'fall.svg',
		isFallPrecaution: true,
	},
	{
		id: PrecautionsObservationCodes.HUMPTY_DUMPTY_CODE,
		abbreviation: 'F',
		name: 'Falls',
		textColor: '#282D30',
		boxColor: '#FFD600',
		icon: 'fall.svg',
		isFallPrecaution: true,
	},
	{
		id: PrecautionsObservationCodes.ORDER,
		abbreviation: 'F',
		name: 'Falls',
		textColor: '#282D30',
		boxColor: '#FFD600',
		icon: 'fall.svg',
	},
	{
		id: 'PRE2',
		abbreviation: 'SZ',
		name: 'Seizure',
		textColor: '#FFFFFF',
		boxColor: '#FFD600',
		icon: 'seizure.svg',
	},
	{
		id: 'PRE3',
		abbreviation: 'SW',
		name: 'Suicide Watch',
		textColor: '#fff',
		boxColor: '#500772',
		icon: 'suicide.svg',
	},
	{
		id: 'PRE6',
		abbreviation: 'AS',
		name: 'Aspiration',
		textColor: '#fff',
		boxColor: '#FFD600',
		icon: 'aspiration.svg',
	},
	{
		id: '140133578',
		abbreviation: 'BSP',
		name: 'Behavioral Safety Plan',
		textColor: '#fff',
		boxColor: '#7B2D9F',
		icon: 'behavioral.svg',
	},
	{
		id: '26544005',
		abbreviation: 'SB',
		name: 'Suspicious Behavior',
		textColor: '#FFFFFF',
		boxColor: '#7B35C1',
	},
];

const PrecautionsInCareNotifications = ({ feed, isEhrField, filteredPrecautions, setPrecautions, precautions }) => {
	const intl = useIntl();
	const [error, setError] = useState('');
	const socket = useContext(SocketContext);
	const suspiciousConditionCode = '26544005';

	const getTypeOfFallPrecaution = (precaution, code) =>
		precaution?.code === code && precaution?.observationTypeId === ObservationType.BRADEN_SCORE;

	const getActiveItem = precaution => {
		const isFallPrecaution =
			[PrecautionsObservationCodes.FALLS_CODE, PrecautionsObservationCodes.FALL_RISK_SCORE_CODE].includes(precaution?.code) &&
			precaution?.observationTypeId === ObservationType.BRADEN_SCORE;
		if (isFallPrecaution) {
			return (precaution?.valueString || precaution?.value) && (+precaution?.valueString >= 7 || +precaution?.value >= 7);
		}
		if (getTypeOfFallPrecaution(precaution, PrecautionsObservationCodes.IBED_FALL_RISK_CODE)) {
			return (
				(precaution?.valueString || precaution?.value) &&
				precaution?.valueString?.toLowerCase() === PrecautionTriggerValues.IBED_FALL_RISK.toLowerCase()
			);
		}
		if (getTypeOfFallPrecaution(precaution, PrecautionsObservationCodes.HUMPTY_DUMPTY_CODE)) {
			return (
				(precaution?.valueString || precaution?.value) &&
				precaution?.valueString?.toLowerCase() === PrecautionTriggerValues.PED_FALL_RISK.toLowerCase()
			);
		}
		if (getTypeOfFallPrecaution(precaution, PrecautionsObservationCodes.FALL_RISK_SCALE_CODE)) {
			return (
				(precaution?.valueString || precaution?.value) &&
				precaution?.valueString?.toLowerCase() === PrecautionTriggerValues.FALL_RISK_SCALE.toLowerCase()
			);
		}
		return !!precaution;
	};
	const getUpdatedData = (fyiFlag, arr, condition, observation) => {
		return arr.map(item => {
			let foundPrecautions = [];
			let foundPrecaution = null;
			if (isEhrField) {
				foundPrecautions = observation.observations.filter(p => p.code === item.id);
				if (foundPrecautions?.length > 0) {
					foundPrecaution = foundPrecautions.reduce((latest, current) => {
						const latestDate = new Date(latest.effectiveDateTime);
						const currentDate = new Date(current.effectiveDateTime);
						return currentDate > latestDate ? current : latest;
					}, foundPrecautions[0]);
				}
			}

			const precaution = condition.conditions.find(p => p.code === item.id) || (isEhrField && foundPrecaution);
			const violentPatient = '140133578';
			return {
				...item,
				active: isEhrField && item.id === violentPatient ? fyiFlag : getActiveItem(precaution),
				effectiveDateTime: precaution?.effectiveDateTime,
			};
		});
	};

	const fetchPrecautions = async (deviceOwnerId, userId) => {
		if (isEhrField) {
			const [encounterIdRes, patientBasicInformation] = await Promise.all([
				getActiveEncounter(deviceOwnerId),
				getPatientByUserId(userId),
			]);
			if (encounterIdRes.error || patientBasicInformation.error) {
				setError(intl.formatMessage({ id: 'somethingWentWrong' }));
			} else {
				const [conditionsRes, observationsRes] = await Promise.all([
					getPrecautionConditions(deviceOwnerId, encounterIdRes?.encounter?.id),
					getPrecautionObservations(deviceOwnerId, encounterIdRes?.encounter?.id),
				]);
				if (conditionsRes.error || observationsRes.error) {
					setError(intl.formatMessage({ id: 'somethingWentWrong' }));
					return;
				}
				const newPrecautions = getUpdatedData(
					patientBasicInformation?.patient?.fyiFlag === 1,
					ehrPrecautions,
					conditionsRes,
					observationsRes
				);
				setPrecautions(newPrecautions);
			}
			return;
		}
		const response = await getPrecautions(deviceOwnerId);
		if (response.error) {
			setError(intl.formatMessage({ id: 'somethingWentWrong' }));

			return;
		}
		const newPrecautions = getUpdatedData(false, initialPrecautions, response);
		setPrecautions(newPrecautions);
	};

	useEffect(() => {
		const getOwner = async () => {
			const deviceOwnerResponse = await getDeviceOwnerPatient(feed.deviceId);
			if (deviceOwnerResponse.error) {
				setError(intl.formatMessage({ id: 'fetchingPrecautionsFailed' }));
				return;
			}

			if (!feed.isDefaultOwner && deviceOwnerResponse?.healthcareUserId && feed.deviceOwner?.userId) {
				fetchPrecautions(deviceOwnerResponse?.healthcareUserId, feed.deviceOwner?.userId);
			}
		};
		getOwner();
	}, [feed.deviceId, feed.roomName, intl, feed.isDefaultOwner, isEhrField, feed.deviceOwner]);

	useEffect(() => {
		const hasMatchingCode = data =>
			data.observations.some(observation => ehrPrecautions.some(precaution => precaution.id === observation.code));

		const handleConditionAdded = data => {
			if (!data.deviceId || !data.conditions || data.deviceId !== feed.deviceId) {
				return;
			}
			if (!isEhrField) {
				let newPrecautions = _.cloneDeep(initialPrecautions);
				if (data.conditions.length === 0) {
					setPrecautions(newPrecautions);
					return;
				}
				if (
					data.conditions.length > 0 &&
					data.conditions.filter(item => item.conditionType === ConditionType.PRECAUTION).length === 0
				) {
					return;
				}
				newPrecautions = _.cloneDeep(initialPrecautions).map(precaution => {
					const foundPrecaution = data.conditions.find(condition => condition.code === precaution.id);
					if (foundPrecaution) {
						precaution.active = true;
					}
					return precaution;
				});
				setPrecautions(newPrecautions);
				return;
			}

			setPrecautions(prevPrecautions => {
				const newPrecautions = ehrPrecautions.map(precaution => {
					const foundPrecaution = data.conditions.find(condition => condition.code === precaution.id);
					const newPrecaution = { ...precaution };
					if (foundPrecaution) {
						newPrecaution.active = true;
						return newPrecaution;
					} else {
						const found = prevPrecautions.find(x => x.id === precaution.id);
						if (found) {
							newPrecaution.active = !!found?.active;
							newPrecaution.effectiveDateTime = found?.effectiveDateTime;
						}
						return newPrecaution;
					}
				});
				return newPrecautions;
			});
		};

		const handleConditionRemoved = data => {
			if (!data.deviceId || !data.conditions || data.deviceId !== feed.deviceId || !isEhrField) {
				return;
			}
			setPrecautions(prevPrecautions => {
				const newPrecautions = prevPrecautions.map(precaution => {
					const foundPrecaution = data.conditions.find(condition => condition.code === precaution.id);
					if (foundPrecaution && precaution?.active) {
						const newPrecaution = { ...precaution };
						newPrecaution.active = false;
						return newPrecaution;
					}
					return precaution;
				});

				return newPrecautions;
			});
		};

		const shouldNotUpdateObservation = data =>
			feed.deviceId !== data.deviceId ||
			!isEhrField ||
			feed.isDefaultOwner ||
			!feed?.deviceOwner?.healthcareUserId ||
			!feed?.deviceOwner?.userId ||
			!hasMatchingCode(data);

		const handleObservationsAdded = data => {
			if (shouldNotUpdateObservation(data)) {
				return;
			}
			fetchPrecautions(feed?.deviceOwner?.healthcareUserId, feed?.deviceOwner?.userId);
		};

		const handleObservationsRemoved = data => {
			if (shouldNotUpdateObservation(data)) {
				return;
			}
			fetchPrecautions(feed?.deviceOwner?.healthcareUserId, feed?.deviceOwner?.userId);
		};

		const handleProfileUpdated = data => {
			const violentPatient = '140133578';
			if (!data?.userId || feed.deviceOwner?.userId !== data.userId) {
				return;
			}
			if (data.fyiFlag !== precautions.find(item => item.id === violentPatient)?.active) {
				setPrecautions(prevState =>
					prevState.map(item => {
						if (item.id === violentPatient) {
							const newItem = { ...item };
							newItem.active = data.fyiFlag;
							return newItem;
						}
						return item;
					})
				);
			}
		};
		socket.on(SocketEvents.HealthCare.PATIENT_OBSERVATIONS_ADDED, handleObservationsAdded);
		socket.on(SocketEvents.HealthCare.PATIENT_OBSERVATIONS_REMOVED, handleObservationsRemoved);
		socket.on(SocketEvents.HealthCare.PATIENT_CONDITIONS_ADDED, handleConditionAdded);
		socket.on(SocketEvents.HealthCare.PATIENT_CONDITIONS_REMOVED, handleConditionRemoved);
		socket.on(SocketEvents.HealthCare.PATIENT_PROFILE_UPDATED, handleProfileUpdated);

		return () => {
			socket.off(SocketEvents.HealthCare.PATIENT_OBSERVATIONS_ADDED, handleObservationsAdded);
			socket.off(SocketEvents.HealthCare.PATIENT_OBSERVATIONS_REMOVED, handleObservationsRemoved);
			socket.off(SocketEvents.HealthCare.PATIENT_CONDITIONS_ADDED, handleConditionAdded);
			socket.off(SocketEvents.HealthCare.PATIENT_CONDITIONS_REMOVED, handleConditionRemoved);
			socket.off(SocketEvents.HealthCare.PATIENT_PROFILE_UPDATED, handleProfileUpdated);
		};
	}, [socket, feed.deviceId, intl, isEhrField, feed.isDefaultOwner, feed?.deviceOwner]);

	return (
		<>
			{filteredPrecautions.length > 0 && (
				<Grid
					rows='repeat(4, min-content)'
					gridGap='var(--spacing-s)'
					className={classNames(
						'precautions-grid',
						filteredPrecautions.length > 4 && filteredPrecautions.length <= 8 ? 'precautions-8' : '',
						filteredPrecautions.length > 8 ? 'precautions-10' : ''
					)}>
					{filteredPrecautions.map(item => (
						<div
							className='precaution-box'
							style={{
								background: item.boxColor,
							}}>
							{(!isEhrField || item.id === suspiciousConditionCode) && (
								<span style={{ color: item.textColor }}>{item.abbreviation}</span>
							)}
							{isEhrField && item.id !== suspiciousConditionCode && (
								<img src={`${healthCareCdnUrl}monitoring/precautions/${item.icon}`} alt='icon' />
							)}
						</div>
					))}
				</Grid>
			)}
			<Alert display={error} fixed hideCloseButton message={error} variant='dark' />
		</>
	);
};

export default PrecautionsInCareNotifications;
