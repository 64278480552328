import React, { useEffect, useState } from 'react';
import Input from 'components/Common/Input.jsx';
import Select from 'react-select';
import translate from 'i18n-translations/translate.jsx';
import { useIntl } from 'react-intl';
import { CareEventPriority, CareEventStatusType, CareEventType } from 'constants/care-events.js';
import {
	SingleValue,
	filterOptions,
	formatOptionLabel,
	getOptionLabel,
	getPriorityColor,
} from 'infrastructure/helpers/careEventsHelper.jsx';
import AsyncSelect from 'react-select/async';
import { getPatientsByLevel } from 'api/careEvents.js';
import { AlertTypes, DeviceListLevel } from 'constants/enums.js';
import { useSelector } from 'react-redux';
import PopUpAlert from 'components/Common/PopUpAlert.jsx';
import TreeView from 'components/Common/TreeView.jsx';
import { getHealthSystemPatients } from 'api/patients.js';
import classNames from 'classnames';
import Room from 'icons/CareEvents/Room.jsx';
import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';

const ExistingPatient = ({
	getNurses,
	nurses,
	selectedNurse,
	setSelectedNurse,
	setPatientDetails,
	showValidationErrors,
	sectorSearchValue,
	onSearchRoom,
	roomTypeId,
	isTreeViewShown,
	onLinkClick,
	tree,
	expandAllTreeItems,
	careEventId,
	setSelectedPriority,
	selectedPriority,
}) => {
	const intl = useIntl();
	const companySettings = useSelector(state => state.company.companySettings);
	const userSession = useSelector(state => state.user.userSession);
	const [selectedPatient, setSelectedPatient] = useState(null);
	const [patients, setPatients] = useState([]);
	const [error, setError] = useState(null);
	const isDarkMode = useSelector(state => state.user.darkMode);

	const priorities = [
		{ label: intl.formatMessage({ id: 'low' }), value: CareEventPriority.LOW },
		{ label: intl.formatMessage({ id: 'medium' }), value: CareEventPriority.MEDIUM },
		{ label: intl.formatMessage({ id: 'high' }), value: CareEventPriority.HIGH },
	];

	useEffect(() => {
		const getPatients = async () => {
			const response = await getPatientsByLevel({ level: DeviceListLevel.HEALTH_SYSTEM, id: userSession.healthSystem.id });
			if (response.error) {
				setError(response.error.message);
				return;
			}
			setPatients(response.patients);
		};
		if (careEventId !== CareEventType.ADMISSION) {
			getPatients();
		}
		if (careEventId === CareEventType.ADMISSION) {
			getPatientByHealthSystem();
		}
	}, [userSession.healthSystem.id, careEventId]);

	const getMappedPatients = patientsRes => {
		const mappedPatients = patientsRes.map(user => ({
			value: user.patientId,
			label: `${user.firstName} ${user.lastName}`,
			mrn: user.mrn,
			profilePicture: user.profilePicture,
			userId: user.userId,
		}));
		setPatients(mappedPatients);
		return mappedPatients;
	};

	const getPatientByHealthSystem = async search => {
		const response = await getHealthSystemPatients({
			healthSystemId: userSession.healthSystem.id,
			search,
			pageIndex: 0,
		});
		if (response.error) {
			setError(response.error.message);
			return [];
		}
		return getMappedPatients(response.patients);
	};

	useEffect(() => {
		setPatientDetails(selectedPatient);
	}, [selectedPatient]);

	const transformArray = array =>
		array.map(item => ({
			value: item.id,
			label: `${item.firstName} ${item.lastName}`,
			userId: item?.userId,
			mrn: item?.mrn,
			roomName: item?.devicePath?.roomName,
		}));

	return (
		<>
			<p className='care-event-title'>{translate('selectExistingPatientWithEvent')}</p>
			<label className='care-events-select'>
				<span className='font-13 semi-bold'>{translate('patient')}</span>
				{careEventId !== CareEventType.ADMISSION && (
					<label>
						<Select
							placeholder={intl.formatMessage({ id: 'searchPatientByMrn' })}
							classNamePrefix='custom-select'
							options={transformArray(patients)}
							onChange={value => setSelectedPatient(value)}
							formatOptionLabel={formatOptionLabel}
							components={{ SingleValue }}
							filterOptions={filterOptions}
							getOptionLabel={getOptionLabel}
							isClearable
						/>
					</label>
				)}
				{careEventId === CareEventType.ADMISSION && (
					<label>
						<AsyncSelect
							loadOptions={async value => await getPatientByHealthSystem(value)}
							placeholder={intl.formatMessage({ id: 'searchPatientByMrn' })}
							onChange={setSelectedPatient}
							getOptionValue={option => option.value}
							classNamePrefix='custom-select'
							defaultOptions={patients}
							isClearable
							value={selectedPatient}
							formatOptionLabel={formatOptionLabel}
							components={{ SingleValue }}
						/>
					</label>
				)}
				{!selectedPatient && showValidationErrors && (
					<div>
						<span className='red-error'>{translate('patientCannotBeEmpty')}</span>
					</div>
				)}
			</label>
			{careEventId !== CareEventType.ADMISSION && selectedPatient && (
				<label>
					<span className='font-13 semi-bold'>{translate('patientIsAtRoom')}</span>
					<Input
						type='text'
						value={selectedPatient?.roomName}
						validationOptions={{}}
						onChange={() => null}
						readonly={true}
						prefixSvg={<Room color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />}
						inputClassName='room-input'
						variant='margin-bottom-m'
					/>
				</label>
			)}
			{careEventId === CareEventType.ADMISSION && (
				<label className='care-events-select'>
					<span className='font-13 semi-bold'>{translate('room')}</span>
					<Input
						type='text'
						name='helloDeviceId'
						placeholder={intl.formatMessage({ id: 'searchRoomName' })}
						value={sectorSearchValue}
						onChange={onSearchRoom}
						validationOptions={{}}
						bottomSpace='15px'
						autoComplete='off'
					/>
					{!roomTypeId && showValidationErrors && (
						<div>
							<span className='red-error'>{translate('roomCannotBeEmpty')}</span>
						</div>
					)}
					{isTreeViewShown && (
						<TreeView data={tree} onLinkClick={onLinkClick} expandAll={expandAllTreeItems} isCareEvents={true} />
					)}
				</label>
			)}
			<label className='care-events-select'>
				<label onClick={() => getNurses('')}>
					<span className='font-13 semi-bold'>{translate('careTeam')}</span>
					<AsyncSelect
						loadOptions={value => getNurses(value)}
						placeholder={intl.formatMessage({ id: 'selectCareTeam' })}
						onChange={setSelectedNurse}
						getOptionValue={option => option.value}
						classNamePrefix='custom-select'
						defaultOptions={nurses}
						isClearable
						value={selectedNurse}
						formatOptionLabel={formatOptionLabel}
						components={{ SingleValue }}
					/>
				</label>
			</label>
			{!selectedNurse && showValidationErrors && (
				<div>
					<span className='red-error'>{translate('somethingCannotBeEmpty', { value: companySettings.nurseDisplayName })}</span>
				</div>
			)}
			<label className={classNames('care-events-select', getPriorityColor(selectedPriority?.value))}>
				<span className='font-13 semi-bold'>{translate('priority')}</span>
				<Select
					value={selectedPriority}
					classNamePrefix='custom-select'
					options={priorities}
					onChange={setSelectedPriority}
					placeholder={intl.formatMessage({ id: 'selectPriority' })}
				/>
			</label>
			<label className='care-events-select new'>
				<span className='font-13 semi-bold'>{translate('status')}</span>
				<Input
					type='text'
					value={intl.formatMessage({ id: CareEventStatusType.NEW })}
					validationOptions={{}}
					bottomSpace='15px'
					autoComplete='off'
					readonly
					disabled
				/>
			</label>
			<PopUpAlert
				alertType={AlertTypes.DANGER}
				display={error}
				onAlertClose={() => setError(null)}
				contentText={error}
				isSilent={true}
				center={true}
			/>
		</>
	);
};

export default ExistingPatient;
