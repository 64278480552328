import { gatewayApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';

export const getADConfigurations = async ({ pageSize = 10, pageIndex = 0, healthSystemId = null, hospitalId = null }) => {
	try {
		const { data } = await gatewayApi.get(`v1/organizations/${getCompanyId()}/external-identity-providers`, {
			params: {
				'page-size': pageSize,
				'page-index': pageIndex,
				'health-system': healthSystemId,
				hospital: hospitalId,
			},
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const getADConfigurationHistory = async ({ id, pageSize = 10, pageIndex = 0 }) => {
	try {
		const { data } = await gatewayApi.get(`v1/organizations/${getCompanyId()}/external-identity-providers/${id}/history`, {
			params: {
				'page-size': pageSize,
				'page-index': pageIndex,
			},
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const getADGroupRoles = async ({ pageSize = 10, pageIndex = 0, healthSystemId = null, hospitalId = null }) => {
	try {
		const { data } = await gatewayApi.get(`v1/organizations/${getCompanyId()}/external-identity-providers/group-roles`, {
			params: {
				'page-size': pageSize,
				'page-index': pageIndex,
				'health-system': healthSystemId,
				hospital: hospitalId,
			},
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const updateADConfiguration = async (id, params) => {
	try {
		const { data } = await gatewayApi.put(`v1/organizations/${getCompanyId()}/external-identity-providers/${id}`, params);
		return data;
	} catch (error) {
		return { error };
	}
};

export const addADConfiguration = async params => {
	try {
		const { data } = await gatewayApi.post(`v1/organizations/${getCompanyId()}/external-identity-providers`, params);
		return data;
	} catch (error) {
		return { error };
	}
};

export const addGroupRole = async ({ roleId, group, teamTypeId, teamId }) => {
	try {
		const dataForm = {
			teamTypeId,
			roleId,
			group,
			teamId,
		};
		const { data } = await gatewayApi.post(
			`v1/organizations/${getCompanyId()}/external-identity-providers/group-roles`,
			dataForm
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const updateGroupRole = async (id, { roleId, group, teamId }) => {
	try {
		const dataForm = {
			teamId,
			roleId,
			group,
		};
		const { data } = await gatewayApi.put(
			`v1/organizations/${getCompanyId()}/external-identity-providers/group-roles/${id}`,
			dataForm
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const deleteADConfiguration = async id => {
	try {
		const { data } = await gatewayApi.delete(`v1/organizations/${getCompanyId()}/external-identity-providers/${id}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const deleteGroupRole = async ({ id, roleId, teamId }) => {
	try {
		const { data } = await gatewayApi.delete(
			`v1/organizations/${getCompanyId()}/external-identity-providers/group-roles/${id}?team-id=${teamId}&role-id=${roleId}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getCompanyLevelExists = async params => {
	try {
		const { data } = await gatewayApi.post(
			`v1/healthcare/organizations/${getCompanyId()}/configurations/active-directories/company-level-exists`,
			params
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const createAdUserInvite = async params => {
	try {
		const { data } = await gatewayApi.post(`v1/tasks/companies/${getCompanyId()}/ad-users/sync`, params);
		return data;
	} catch (error) {
		return { error };
	}
};

export const companyAdUserExists = async (email, params) => {
	try {
		const { data } = await gatewayApi.post(`v1/healthcare/organizations/${getCompanyId()}/ad-users/${email}/exists`, params);

		return data;
	} catch (error) {
		return { error };
	}
};

export const companyLevelAdData = async params => {
	try {
		const { data } = await gatewayApi.post(
			`v1/healthcare/organizations/${getCompanyId()}/configurations/active-directories/company-level`,
			params
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getAccountCreationStatus = async orchestrationTaskId => {
	try {
		const { data } = await gatewayApi.get(`/v1/orchestrations/${orchestrationTaskId}/status`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const retryAccountCreationStatus = async orchestrationTaskId => {
	try {
		const { data } = await gatewayApi.post(`/v1/orchestrations/${orchestrationTaskId}/retry`);
		return data;
	} catch (error) {
		return { error };
	}
};
