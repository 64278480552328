import React, { useCallback, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import translate from 'i18n-translations/translate.jsx';
import { SingleValue, formatOptionLabel } from 'infrastructure/helpers/careEventsHelper.jsx';
import AsyncSelect from 'react-select/async';
import {
	HospitalAtHomeActiveTab,
	HospitalAtHomeDropdownFilter,
	HospitalAtHomeDropdownFilters,
	HospitalAtHomeTabFilter,
	PainLevels,
} from 'constants/hospital-at-home.js';
import { debounce } from 'lodash';
import classNames from 'classnames';
import { getHospitalAtHomeClassName, scrollToTop } from 'infrastructure/helpers/rpmHelper.js';
import Button from 'components/Common/Button.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { getNursesAssigned } from 'api/rpm.js';
import { useSelector } from 'react-redux';
import Alert from 'components/Common/Alert.jsx';
import { getHealthSystemPatients } from 'api/patients.js';
import useOutsideClick from 'infrastructure/helpers/useOutsideClick.js';

const HospitalAtHomeFilters = ({
	setPagination,
	setSearchBox,
	patientFilterCounts,
	setRiskType,
	hospitalAtHomeList,
	setIsLoading,
	setIsRiskFilterLoading,
}) => {
	const intl = useIntl();
	const filtersRef = useRef(null);
	const userSession = useSelector(state => state.user.userSession);
	const companySettings = useSelector(state => state.company.companySettings);
	const [patients, setPatients] = useState([]);
	const [nurses, setNurses] = useState([]);
	const [selectedPatient, setSelectedPatient] = useState(null);
	const [selectedNurse, setSelectedNurse] = useState(null);
	const [selectedPainLevel, setSelectedPainLevel] = useState(null);
	const [isFilterShown, setIsFilterShown] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');
	const [schedule, setSchedule] = useState(null);
	const [currentTab, setCurrentTab] = useState(HospitalAtHomeActiveTab.ALL_PATIENTS);
	const [error, setError] = useState(null);
	const [tabsExpanded, setTabsExpanded] = useState([
		{ id: 1, isExpanded: false },
		{ id: 2, isExpanded: false },
		{ id: 3, isExpanded: false },
		{ id: 4, isExpanded: false },
	]);

	const getPatientByHealthSystem = async search => {
		const response = await getHealthSystemPatients({
			healthSystemId: userSession.healthSystem.id,
			search,
		});
		if (response.error) {
			return { errorMessage: response.error.message };
		}
		return response.patients.map(user => ({
			value: user.patientId,
			label: `${user.firstName} ${user.lastName}`,
			profilePicture: user.profilePicture,
			userId: user.userId,
		}));
	};

	const getNurses = async search => {
		const response = await getNursesAssigned({ healthSystemId: userSession.healthSystem.id, search });
		if (response.error) {
			return { errorMessage: response.error.message };
		}
		return response.users.map(user => ({
			value: user.userId,
			label: `${user.firstName} ${user.lastName}`,
			email: user.email,
			profilePicture: user.profilePicture,
		}));
	};

	const fetchAndSetPatients = async search => {
		const mappedPatients = await getPatientByHealthSystem(search);
		if (mappedPatients.errorMessage) {
			setError(mappedPatients.errorMessage);
			return;
		}
		setPatients(mappedPatients);
	};

	const fetchAndSetNurses = async search => {
		const mappedNurses = await getNurses(search);
		if (mappedNurses.errorMessage) {
			setError(mappedNurses.errorMessage);
			return;
		}
		setNurses(mappedNurses);
	};

	const getSelectedTab = type => tabsExpanded.find(x => x.id === type).isExpanded;

	const toggleFilterTabs = id => {
		const updatedTabs = tabsExpanded.map(tab => {
			if (tab.id === id) {
				const newTab = { ...tab };
				newTab.isExpanded = !newTab.isExpanded;
				return newTab;
			}
			return tab;
		});
		setTabsExpanded(updatedTabs);
	};

	const handleSearchValue = useCallback(debounce(setSearchBox, 300), []);

	const handleSearchTerm = event => {
		setIsLoading(true);
		handleSearchValue(event.target.value);
		setSearchTerm(event.target.value);
		setPagination(prevState => ({ ...prevState, pageIndex: 0 }));
	};

	const handleClearAll = () => {
		setSelectedPatient(null);
		setSchedule(null);
		setSelectedNurse(null);
		setSelectedPainLevel(null);
	};

	const handleFilterTabClick = (index, tab) => {
		setIsLoading(true);
		setIsRiskFilterLoading(true);
		setCurrentTab(index);
		scrollToTop(hospitalAtHomeList);
		if (![HospitalAtHomeTabFilter.ALL_PATIENTS].includes(tab)) {
			setRiskType(tab);
			return;
		}
		setPagination(prevState => ({ ...prevState, pageIndex: 0 }));
		setRiskType(null);
	};

	useOutsideClick(filtersRef, () => setIsFilterShown(false));

	return (
		<div className='flex flex-space-between flex-align-center hospital-at-home-filters'>
			<div className='flex hospital-at-home-tabs'>
				{Object.values(HospitalAtHomeTabFilter).map((tab, index) => (
					<div
						key={tab}
						className={classNames({ active: currentTab === index }, getHospitalAtHomeClassName(index))}
						onClick={() => handleFilterTabClick(index, tab)}>
						{translate(tab)}
						<span className='counter'>{patientFilterCounts?.[tab]}</span>
					</div>
				))}
			</div>

			<div className='care-event-buttons' ref={filtersRef}>
				<div className='flex'>
					<div className='input-wrapper'>
						<input
							type='text'
							placeholder={intl.formatMessage({ id: 'searchByName' })}
							value={searchTerm}
							onChange={handleSearchTerm}
						/>
					</div>
					<Button
						className={classNames('filter-button', { active: isFilterShown })}
						onClick={() => setIsFilterShown(prevState => !prevState)}
						imgIcon={`${healthCareCdnUrl}care-events/filter-icon.svg`}
					/>
				</div>
				<div className='care-events-wrapper full-width position-relative doctor-profile-settings'>
					{isFilterShown && (
						<div className='filters-wrapper'>
							<div className='filter-clear-all'>
								<p>{translate('filters')}</p>
								<p className='clear-filter' onClick={handleClearAll}>
									{translate('clearAll')}
								</p>
							</div>

							{HospitalAtHomeDropdownFilters.map(item => (
								<>
									<div key={item.id} onClick={() => toggleFilterTabs(item.id)}>
										<i className='material-icons-outlined'>
											{tabsExpanded.find(tab => tab.id === item.id)?.isExpanded ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}
										</i>
										<p>{translate(item.title, { value: companySettings.nurseDisplayName })}</p>
									</div>

									{getSelectedTab(HospitalAtHomeDropdownFilter.PATIENTS) && item.id === HospitalAtHomeDropdownFilter.PATIENTS && (
										<div className='select-wrapper' onClick={() => fetchAndSetPatients('')}>
											<AsyncSelect
												loadOptions={getPatientByHealthSystem}
												placeholder={intl.formatMessage({ id: 'searchByName' })}
												onChange={setSelectedPatient}
												getOptionValue={option => option.value}
												classNamePrefix='custom-select'
												defaultOptions={patients}
												isClearable
												value={selectedPatient}
												formatOptionLabel={formatOptionLabel}
												components={{ SingleValue }}
											/>
										</div>
									)}

									{getSelectedTab(HospitalAtHomeDropdownFilter.SCHEDULE) && item.id === HospitalAtHomeDropdownFilter.SCHEDULE && (
										<div className='input-wrapper'>
											<input
												type='text'
												placeholder={intl.formatMessage({ id: 'schedule' })}
												value={schedule}
												onChange={event => setSchedule(event.target.value)}
											/>
										</div>
									)}

									{getSelectedTab(HospitalAtHomeDropdownFilter.ASSIGNED_NURSE) &&
										item.id === HospitalAtHomeDropdownFilter.ASSIGNED_NURSE && (
											<div className='select-wrapper' onClick={() => fetchAndSetNurses('')}>
												<AsyncSelect
													loadOptions={getNurses}
													placeholder={intl.formatMessage({ id: 'selectCareTeam' })}
													onChange={setSelectedNurse}
													getOptionValue={option => option.value}
													classNamePrefix='custom-select'
													defaultOptions={nurses}
													isClearable
													value={selectedNurse}
													formatOptionLabel={formatOptionLabel}
													components={{ SingleValue }}
												/>
											</div>
										)}

									{getSelectedTab(HospitalAtHomeDropdownFilter.PAIN_LEVEL) &&
										item.id === HospitalAtHomeDropdownFilter.PAIN_LEVEL &&
										PainLevels.map(item => (
											<div className='flex flex-wrap input-auto-width-wrapper doctor-profile-settings' key={item}>
												<div
													className='register-practice-modal-grid tick-box-active-input'
													onClick={() => setSelectedPainLevel(item)}>
													<label className='flex full-width remember-me cursor-pointer no-margin'>
														<input type='checkbox' checked={item === selectedPainLevel} name='priority' onChange={() => null} />
														<div className='onb-custom-checkbox-wrapper cursor-pointer'>
															<div className='onb-custom-checkbox' />
														</div>
														<span>{item}</span>
													</label>
												</div>
											</div>
										))}
								</>
							))}
						</div>
					)}
				</div>
			</div>
			<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
		</div>
	);
};

export default HospitalAtHomeFilters;
