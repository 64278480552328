import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import _ from 'lodash';
import { actionCreators as doctorActionCreators } from 'state/doctors/actions.js';
import { PresenceStatusType, UserStatus } from 'constants/enums.js';
import ProfilePicture from 'components/Common/ProfilePicture.jsx';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SocketEvents from 'constants/socket-events.js';
import { getUserInfo } from 'infrastructure/auth.js';
import { getFormattedDate } from 'infrastructure/helpers/dateHelper.js';
import translate from 'i18n-translations/translate.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { getMyDoctors } from 'api/doctors.js';
import { dissociateUserById } from 'api/users.js';
import Alert from 'components/Common/Alert.jsx';
import Loader from 'components/Common/Loader.jsx';
import Dropdown from 'components/Common/Dropdown.jsx';
import { decodeHtml, stringToCamelCase } from 'infrastructure/helpers/commonHelpers.js';
import Input from 'components/Common/Input.jsx';

class DoctorsList extends React.Component {
	state = {
		isLoading: true,
		error: null,
		searchText: '',
	};

	userInfo = getUserInfo();

	componentDidMount = async () => {
		this.context.on(SocketEvents.Client.ON_NOTIFY_UPDATED_USER_PRESENCE, this.onUserUpdatedPresence);
		this.context.on(SocketEvents.Client.ON_USER_ONLINE, this.onUserOnline);
		this.context.on(SocketEvents.Client.ON_USER_OFFLINE, this.onUserOffline);
		this.context.on(SocketEvents.HealthCare.DOCTOR_PATIENT_DISSOCIATED, this.onDoctorPatientDissociate);
		const doctors = await getMyDoctors();
		this.props.doctorActions.setDoctors(doctors);
		if (!this.props.selectedDoctor) {
			this.props.doctorActions.setSelectedDoctor(doctors.length > 0 ? doctors[0] : null);
		}
		this.setState(
			{ isLoading: false },
			() => doctors.length > 0 && this.props.history.push(`/recent/${this.props.selectedDoctor.userId}`)
		);
	};

	onSearch = async searchText => {
		this.setState({
			isLoading: true,
			searchText,
		});
		if (this.timeout) clearTimeout(this.timeout);
		this.timeout = setTimeout(async () => {
			const doctors = await getMyDoctors({ searchText });
			if (doctors.error) {
				this.setState({ error: doctors.error.message });
			} else {
				this.props.doctorActions.setDoctors(doctors);
			}
			this.setState({
				isLoading: false,
			});
		}, 500);
	};

	onUserUpdatedPresence = ({ objectId, presenceStatusTypeId }) => {
		const doctors = [...this.props.doctors];
		const foundDoctor = doctors.find(doctor => doctor.userId === parseInt(objectId, 10));
		if (foundDoctor) {
			foundDoctor.presenceStatusTypeId = presenceStatusTypeId;
		}
		this.props.doctorActions.setDoctors(doctors);
	};

	onUserOnline = ({ userId }) => this.setUserStatus(userId, UserStatus.ONLINE);

	onUserOffline = ({ userId }) => this.setUserStatus(userId, UserStatus.OFFLINE);

	setUserStatus = (userId, status) => {
		const doctors = _.cloneDeep(this.props.doctors);
		const foundDoctor = doctors.find(doctor => doctor.userId === userId);
		if (foundDoctor) {
			foundDoctor.availability = status;
			this.props.doctorActions.setDoctors(doctors);
		}
	};

	componentWillUnmount = () => {
		this.context.off(SocketEvents.Client.ON_NOTIFY_UPDATED_USER_PRESENCE, this.onUserUpdatedPresence);
		this.context.off(SocketEvents.Client.ON_USER_ONLINE, this.onUserOnline);
		this.context.off(SocketEvents.Client.ON_USER_OFFLINE, this.onUserOffline);
		this.context.off(SocketEvents.HealthCare.DOCTOR_PATIENT_DISSOCIATED, this.onDoctorPatientDissociate);
		if (this.timeout) clearTimeout(this.timeout);
	};

	onChatListItemClicked = doctor => {
		const selectedDoctor = { ...this.props.selectedDoctor };
		if (selectedDoctor.lastActivity) {
			selectedDoctor.lastActivity.unReadMessageCount = 0;
		}
		this.props.doctorActions.setSelectedDoctor(doctor);
		this.props.history.push(`/recent/${doctor.userId}`);
	};

	toggleRemoveConnectionDropdown = id => {
		const doctors = [...this.props.doctors];
		const selectedDoctor = doctors.find(item => item.id === id);
		if (selectedDoctor) {
			selectedDoctor.isConnectionDropdownVisible = !selectedDoctor.isConnectionDropdownVisible;
		}
		this.props.doctorActions.setDoctors(doctors);
	};

	removeConnection = async id => {
		this.setState({ isLoading: true });
		this.toggleRemoveConnectionDropdown(id);
		const response = await dissociateUserById(id);
		if (response.error) {
			this.setState({
				error: response.error.message,
			});
		} else {
			const doctors = [...this.props.doctors];
			const newDoctors = doctors.filter(item => item.id !== id);
			const selectedDoctor = newDoctors.length > 0 ? newDoctors[0] : null;
			this.props.doctorActions.setDoctors(newDoctors);
			this.props.doctorActions.setSelectedDoctor(selectedDoctor);
			const url = newDoctors.length > 0 ? `/recent/${selectedDoctor.userId}` : `/recent`;
			this.props.history.push(url);
		}
		this.setState({ isLoading: false });
	};

	onDoctorPatientDissociate = async () => {
		const doctors = await getMyDoctors();
		if (doctors.error) {
			this.setState({ error: doctors.error.message });
			return;
		}
		this.props.doctorActions.setDoctors(doctors);
		this.props.doctorActions.setSelectedDoctor(doctors.length > 0 ? doctors[0] : null);
	};

	render() {
		return (
			<div>
				<div>
					<div className='doctors-list-header sub-page-header'>
						<h2>{translate('recent')}</h2>
					</div>
				</div>
				<Input
					type='text'
					name='sectorSearchValue'
					placeholder={this.props.intl.formatMessage({ id: 'search' })}
					value={this.state.searchText}
					onChange={event => this.onSearch(event.target.value)}
					validationOptions={{}}
					bottomSpace='0px'
					autoComplete='off'
				/>
				<div className='chat-items-area scroll-hover'>
					<div>
						{!this.state.isLoading && this.props.doctors.length === 0 && (
							<div className='chat-item-title'>
								<span className='semi-bold'>{translate('noConnectedDoctorsYet')}</span>
							</div>
						)}
						{this.state.isLoading && (
							<div className='patient-request-loader'>
								<Loader />
							</div>
						)}
						{!this.state.isLoading &&
							this.props.doctors.map(doctor => (
								<div
									key={doctor.id}
									className={`chat-list-item doctors-list-item position-relative flex${
										this.props.selectedDoctor && this.props.selectedDoctor.id === doctor.id ? ' active' : ''
									}`}
									onClick={() => this.onChatListItemClicked(doctor)}>
									<div className='align-self-start position-relative'>
										<div className='flex position-relative chat-list-img-status-wrapper'>
											<ProfilePicture
												className='chat-list-img'
												firstName={doctor.firstName}
												lastName={doctor.lastName}
												profilePicture={doctor.profilePicture}
											/>
											<div className='chat-list-status flex'>
												<span
													className={
														doctor.presenceStatusTypeId === PresenceStatusType.UNAVAILABLE ||
														doctor.availability === UserStatus.OFFLINE
															? 'is-off'
															: 'is-on'
													}
												/>
											</div>
										</div>
										{doctor.lastActivity?.unReadMessageCount > 0 && (
											<div className='conversation-label-blue'>{doctor.lastActivity.unReadMessageCount}</div>
										)}
									</div>
									<div className='chat-list-name header-search-name flex flex-1'>
										<p>
											{doctor.firstName} {doctor.lastName}
										</p>
										<div className='flex'>
											{doctor.specialty && (
												<div className='doctor-specialty-section'>
													<img src={`${healthCareCdnUrl}family-physician.svg`} alt='icon' />
													<span>{translate(stringToCamelCase(doctor.specialty.name))}</span>
												</div>
											)}
										</div>
										{doctor.lastActivity && (
											<span className='chat-list-recent-message'>{decodeHtml(doctor.lastActivity.message)}</span>
										)}
										{doctor.lastActivity && (
											<span className='chat-list-time'>{getFormattedDate(doctor.lastActivity.dateCreated)}</span>
										)}
										{!doctor.lastActivity && (
											<>
												<span className='chat-list-time'>{getFormattedDate(doctor.requestAcceptedTime)}</span>
												<span className='chat-list-recent-message'>
													{`${doctor.firstName} ${doctor.lastName} ${this.props.intl.formatMessage({
														id: 'acceptedYourRequest',
													})}`}
												</span>
											</>
										)}
										<Dropdown position='bottom' icon='more_horiz' className='remove-connection-wrapper'>
											<div className='remove-connection-results' onClick={() => this.removeConnection(doctor.id)}>
												<div className='flex'>
													<img src={`${healthCareCdnUrl}patient-home/RemoveConnection.svg`} alt='icon' />
													<p>{translate('removeConnection')}</p>
												</div>
											</div>
										</Dropdown>
									</div>
								</div>
							))}
					</div>
				</div>
				<Alert
					display={this.state.error !== null}
					message={this.state.error}
					variant='dark'
					fixed={true}
					onClose={() =>
						this.setState({
							error: null,
						})
					}
				/>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		doctors: state.myDoctors.doctors,
		selectedDoctor: state.myDoctors.selectedDoctor,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		doctorActions: bindActionCreators(doctorActionCreators, dispatch),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withRouter(DoctorsList)));

DoctorsList.contextType = SocketContext;
