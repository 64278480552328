import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import { CareEventTypes } from 'constants/care-events.js';
import { getCareEventTypeIcon } from 'infrastructure/helpers/careEventsHelper.jsx';
import Button from 'components/Common/Button.jsx';

const CareEventsDropdown = ({
	setIsCareEventDropdownShown,
	isCareEventDropdownShown,
	setIsCareEventModalOpen,
	setCareEventTitle,
	dropdownRef,
	setCareEventId,
	setIsFilterShown,
	isDarkMode,
}) => (
	<div className='position-relative invite-users-wrapper' ref={dropdownRef}>
		<Button
			className='new-care-event-btn'
			onClick={() => {
				setIsCareEventDropdownShown(prevState => !prevState);
				setIsFilterShown(false);
			}}
			svgIcon={<img src={`${healthCareCdnUrl}care-events/new-care-event.svg`} alt='icon' className='create-appointment-icon' />}
			text={translate('newCareEvent')}
		/>
		{isCareEventDropdownShown && (
			<div className='full-page-input-results care-events'>
				{CareEventTypes.map(item => (
					<div
						key={item.careEventId}
						className='flex cursor-pointer flex-align-center'
						onClick={() => {
							setIsCareEventModalOpen(true);
							setCareEventTitle(item.name);
							setCareEventId(item.careEventId);
						}}>
						{getCareEventTypeIcon(item.careEventId, isDarkMode)}
						<span>{translate(item.name)}</span>
					</div>
				))}
			</div>
		)}
	</div>
);

export default CareEventsDropdown;
