import { addTimeZone } from 'api/timezone.js';
import classNames from 'classnames';
import Alert from 'components/Common/Alert.jsx';
import Form from 'components/Common/Form.jsx';
import { Modal } from 'components/index.js';
import SelectTimeZone from 'components/Common/SelectTimeZone.jsx';
import translate from 'i18n-translations/translate.jsx';
import { findSectorById } from 'infrastructure/helpers/commonHelpers.js';
import { findByCityAndCountry, getFormatedTimeZone } from 'infrastructure/helpers/timezone/timeZonesHelper.js';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators as healthSystemsActionCreators } from 'state/healthSystems/actions.js';

const SelectTimeZoneModal = ({ currentHospital, onModalClose }) => {
	const dispatch = useDispatch();
	const tree = useSelector(state => state.healthSystems.treeData.tree);
	const userSession = useSelector(state => state.user.userSession);
	const [isLoading, setIsLoading] = useState(false);
	const [selectedPlace, setSelectedPlace] = useState(null);
	const intl = useIntl();
	const [error, setError] = useState(null);

	useEffect(() => {
		const fetchTimezone = async () => {
			if (currentHospital.timezone) {
				const place = findByCityAndCountry(currentHospital.timezone.city, currentHospital.timezone.province);
				setSelectedPlace(place ? { ...place, formattedTimeZone: getFormatedTimeZone(place.timezone)?.formattedTimeZone } : null);
			}
		};
		fetchTimezone();
	}, [currentHospital]);

	const handleSubmit = async () => {
		setIsLoading(true);
		const params = {
			healthSystemId: userSession.healthSystem.id,
			regionId: userSession.regionId,
			hospitalId: currentHospital.hospitalId,
			timezone: selectedPlace ?? { timezone: null },
		};
		const response = await addTimeZone(params);
		if (response.error) {
			setError(response.error.message);
		} else {
			const treeData = _.cloneDeep(tree);
			const hospital = findSectorById(treeData, currentHospital.hospitalId);
			if (hospital) {
				hospital.timezone = selectedPlace;
			}
			dispatch(healthSystemsActionCreators.setTreeData(treeData));
		}
		setIsLoading(false);
	};

	return (
		<Modal
			modalSelector='editSectorModal'
			display={true}
			onModalSubmit={handleSubmit}
			onModalClose={onModalClose}
			className={classNames('assign-doctor-modal', isLoading ? 'none-pointer-events' : '')}
			shouldSubmitOnEnter={false}
			isSubmitDisabled={false}
			primaryButtonLoading={isLoading}
			position='right'>
			<Form
				className={isLoading ? 'disabled' : ''}
				title={translate('setTimeZone')}
				onSubmit={event => event.preventDefault()}
				description={intl.formatMessage({ id: 'timeZoneModalDesc' })}>
				<SelectTimeZone selectedPlace={selectedPlace} setSelectedPlace={setSelectedPlace} />
			</Form>
			<Alert display={error} message={error} variant='dark' fixed={true} onClose={() => setError(null)} />
		</Modal>
	);
};

export default SelectTimeZoneModal;
