const AiVitalSigns = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16' {...props}>
		<path
			stroke='currentColor'
			strokeLinejoin='round'
			d='M8 12.818C-2.28 7.148 4.915.958 8 4.851c3.083-3.892 10.278 2.297 0 7.967Z'
		/>
		<path
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
			d='M2 9.003h3.333l.667-1 1 2.666 1-4.333 1 3.333.969-2.101.698 1.101h3.334'
		/>
	</svg>
);

export default AiVitalSigns;
