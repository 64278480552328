import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import Highlighter from 'react-highlight-words';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import translate from 'i18n-translations/translate.jsx';
import { searchPatient } from 'api/patients.js';
import PrintVisitsReport from 'containers/Common/PrintVisitsReport.jsx';
import Alert from 'components/Common/Alert.jsx';

const PatientReports = () => {
	const [searchPatientsValue, setSearchPatientsValue] = useState('');
	const [searchedPatients, setSearchedPatients] = useState([]);
	const [selectedPatient, setSelectedPatient] = useState(null);
	const [totalCount, setTotalCount] = useState(0);
	const [pageIndex, setPageIndex] = useState(0);
	const [error, setError] = useState(null);
	const intl = useIntl();

	useEffect(() => {
		const delayCall = setTimeout(async () => {
			const response = await searchPatient(searchPatientsValue, pageIndex);
			if (response.error) {
				setError(response.error.message);
			} else {
				setSearchedPatients(prevState => prevState.concat(response.patients));
				setTotalCount(response.totalCount);
			}
		}, 500);

		return () => clearTimeout(delayCall);
	}, [searchPatientsValue, pageIndex]);

	useEffect(() => {
		if (selectedPatient) {
			setSearchPatientsValue('');
		}
	}, [selectedPatient]);

	const handleSearch = event => {
		setPageIndex(0);
		setSearchedPatients([]);
		setSearchPatientsValue(event.target.value);
	};

	const maskIdCard = id => `******${id.substring(id.length - 4, id.length)}`;

	const handleScroll = event => {
		const isBottom = event.target.scrollHeight - Math.ceil(event.target.scrollTop) === event.target.clientHeight;
		const hasReachedEnd = totalCount - searchedPatients.length <= 0;
		if (isBottom && !hasReachedEnd) {
			setPageIndex(prevState => prevState + 1);
		}
	};

	return (
		<MainLayout>
			<div className='full-page-input-wrapper'>
				<h3>{translate('selectPatient')}</h3>
				<p>{translate('selectPatientAndContinue')}</p>
				<div className='full-page-input-wrapper-inputs top-15 no-padding-top no-margin-bottom'>
					<div className='full-page-input-search'>
						<input
							type='search'
							placeholder={intl.formatMessage({ id: 'searchPatient' })}
							onChange={handleSearch}
							value={searchPatientsValue}
							maxLength={100}
						/>
						{searchPatientsValue !== '' && (
							<div className='full-page-input-results' onScroll={handleScroll}>
								{searchedPatients.length > 0 &&
									searchedPatients.map(patient => (
										<div key={patient.id} onClick={() => setSelectedPatient(patient)} className='cursor-pointer'>
											<Highlighter
												searchWords={searchPatientsValue.split(' ')}
												autoEscape={true}
												textToHighlight={`${patient.firstName} ${patient.lastName} ${
													patient.idCard ? `(${maskIdCard(patient.idCard)})` : ''
												}`}
											/>
										</div>
									))}
								{searchPatientsValue.length < 2 && (
									<div>
										<span>
											{translate('moreThanCharacters', {
												value: 2,
											})}
										</span>
									</div>
								)}
								{searchedPatients.length === 0 && searchPatientsValue.length > 1 && (
									<div>
										<span>{translate('patientNotFound')}</span>
									</div>
								)}
							</div>
						)}
					</div>
				</div>
			</div>
			{selectedPatient && (
				<PrintVisitsReport selectedPatient={selectedPatient} hideTitle={true} tableClassName='header-fixed reports-list' />
			)}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</MainLayout>
	);
};

export default PatientReports;
