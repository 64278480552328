const Diagnosis = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 22 24' {...props}>
		<rect
			width={16}
			height={18}
			x={4}
			y={3}
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={1.5}
			rx={2}
		/>
		<path
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={1.5}
			d='M6.5 14.5h11m-9.5 3h8M12 6v2.5m0 0V11m0-2.5h2.5m-2.5 0H9.5'
		/>
	</svg>
);

export default Diagnosis;
