import { IntegrationTypesSettings } from 'constants/configurationEnums.js';

export const groupTypes = {
	DEFAULT: { id: 1, title: 'default' },
	ECM: { id: 2, title: 'ecm' },
	SMART_FHIR: { id: 3, title: 'smartFHIR' },
	NURSE_CALLING: { id: 4, title: 'somethingCalling' },
	TRANSLATION_SERVICES: { id: 5, title: 'interpretationServices' },
	PATIENT_INFOTAINMENT: { id: 6, title: 'patientInfotainment' },
	PACS: { id: 7, title: 'pacs' },
	PATIENT_EDUCATION: { id: 8, title: 'patientEducation' },
	CLOUD_STORAGE: { id: 9, title: 'cloudStorage' },
};

export const configurationTypeIds = {
	ECARE_API_KEY: {
		id: 1,
		description: 'E-care API Key',
		groupId: groupTypes.ECM.id,
		visiblePropery: 'apiKey',
		featureFlagKey: null,
		requiredFields: ['apiKey'],
	},
	ECARE_BASE_URL: {
		id: 2,
		description: 'E-care Base URL',
		groupId: groupTypes.ECM.id,
		visiblePropery: 'baseUrl',
		featureFlagKey: null,
		requiredFields: ['baseUrl'],
	},
	EPIC: {
		id: 3,
		description: 'Epic',
		groupId: groupTypes.SMART_FHIR.id,
		visiblePropery: 'clientId',
		featureFlagKey: IntegrationTypesSettings.EPIC,
		requiredFields: ['oauthScopes', 'returnUrl', 'issuer', 'clientSecret', 'clientId'],
	},
	CERNER: {
		id: 4,
		description: 'Cerner',
		groupId: groupTypes.SMART_FHIR.id,
		visiblePropery: 'clientId',
		featureFlagKey: IntegrationTypesSettings.CERNER,
		requiredFields: ['oauthScopes', 'returnUrl', 'issuer', 'clientSecret', 'clientId'],
	},
	// MEDPLUM: {
	// 	id: 5,
	// 	description: 'Med Plum',
	// 	groupId: groupTypes.NURSE_CALLING.id,
	// 	visiblePropery: '',
	// 	featureFlagKey: null,
	//  requiredFields: [],
	// },
	GLOBO: {
		id: 6,
		description: 'Globo',
		groupId: groupTypes.TRANSLATION_SERVICES.id,
		visiblePropery: 'baseUrl',
		featureFlagKey: IntegrationTypesSettings.GLOBO,
		requiredFields: ['authToken', 'sharedToken', 'authSecret', 'baseUrl', 'callType'],
	},
	AMN: {
		id: 7,
		description: 'AMN',
		groupId: groupTypes.TRANSLATION_SERVICES.id,
		visiblePropery: 'baseUrl',
		featureFlagKey: IntegrationTypesSettings.AMN,
		requiredFields: ['sipEndpoint', 'callType'],
	},
	HILL_ROM: {
		id: 8,
		description: 'Hill Rom',
		groupId: groupTypes.NURSE_CALLING.id,
		visiblePropery: 'secretKey',
		featureFlagKey: IntegrationTypesSettings.HILL_ROM,
		requiredFields: ['host', 'port', 'path', 'secretKey', 'partnerName'],
	},
	LANGUAGE_LINE: {
		id: 9,
		description: 'Language Line',
		groupId: groupTypes.TRANSLATION_SERVICES.id,
		visiblePropery: 'baseUrl',
		featureFlagKey: IntegrationTypesSettings.LANGUAGE_LINE,
		requiredFields: ['authCode', 'callType'],
	},
	MOVIES: {
		id: 11,
		description: 'Movies',
		groupId: groupTypes.PATIENT_INFOTAINMENT.id,
		visiblePropery: 'type',
		featureFlagKey: null,
		requiredFields: [],
	},
	LIVE_TV_CHANNELS: {
		id: 12,
		description: 'Live TV Channels',
		groupId: groupTypes.PATIENT_INFOTAINMENT.id,
		visiblePropery: 'type',
		featureFlagKey: null,
		requiredFields: [],
	},
	MUSIC: {
		id: 13,
		description: 'Music',
		groupId: groupTypes.PATIENT_INFOTAINMENT.id,
		visiblePropery: 'type',
		featureFlagKey: null,
		requiredFields: [],
	},
	VOYCE: {
		id: 15,
		description: 'VOYCE',
		groupId: groupTypes.TRANSLATION_SERVICES.id,
		visiblePropery: 'baseUrl',
		featureFlagKey: IntegrationTypesSettings.VOYCE,
		requiredFields: ['token', 'callType'],
	},
	GENERAL_ELECTRIC: {
		id: 16,
		description: 'GE',
		groupId: groupTypes.PACS.id,
		visiblePropery: 'type',
		featureFlagKey: IntegrationTypesSettings.GE,
		requiredFields: ['baseUrl', 'text', 'secretKey'],
	},
	FUJI: {
		id: 17,
		description: 'FUJI',
		groupId: groupTypes.PACS.id,
		visiblePropery: 'type',
		featureFlagKey: IntegrationTypesSettings.FUJI,
		requiredFields: ['baseUrl', 'text', 'secretKey'],
	},
	KALTURA: {
		id: 18,
		description: 'KALTURA',
		groupId: groupTypes.PATIENT_EDUCATION.id,
		visiblePropery: 'baseUrl',
		featureFlagKey: null,
		requiredFields: ['baseUrl', 'partnerId', 'appToken', 'tokenId', 'playerId', 'parentCategoryId'],
	},
	AZURE_BLOB_STORAGE: {
		id: 19,
		description: 'Azure Blob Storage',
		groupId: groupTypes.CLOUD_STORAGE.id,
		visiblePropery: '',
		featureFlagKey: IntegrationTypesSettings.AZURE_BLOB_STORAGE,
		requiredFields: [],
	},
};

export const PatientInfotainmentProviders = {
	MANUAL: 1,
	SWANK: 2,
	MAYO: 3,
};

export const DataTypeOptions = [{ value: 0, label: 'pastCallLogs' }];

export const ScheduleUnit = {
	DAILY: 0,
	WEEKLY: 1,
};

export const ScheduleValueOptions = {
	[ScheduleUnit.DAILY]: [{ label: 'everyDay', value: 0 }],
	[ScheduleUnit.WEEKLY]: [
		{ label: 'sunday', value: 0 },
		{ label: 'monday', value: 1 },
		{ label: 'tuesday', value: 2 },
		{ label: 'wednesday', value: 3 },
		{ label: 'thursday', value: 4 },
		{ label: 'friday', value: 5 },
		{ label: 'saturday', value: 6 },
	],
};
