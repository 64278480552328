import classNames from 'classnames';
import Button from 'components/Common/Button.jsx';
import { HealthDataFilterTabs } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import { getHealthDataButtonText } from 'infrastructure/helpers/measurementsHelper.js';
import React from 'react';

const HealthDataTabs = ({ handleSelect, currentTab }) => (
	<div className='flex column-direction health-data-filter-tabs'>
		{Object.keys(HealthDataFilterTabs).map((tab, index) => (
			<Button
				onClick={() => handleSelect(index)}
				className={classNames('health-data-btn', currentTab === index ? 'active' : '')}
				text={translate(getHealthDataButtonText(index))}
			/>
		))}
	</div>
);

export default HealthDataTabs;
