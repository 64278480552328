import React, { useState, useRef, useEffect } from 'react';
import { useIntl } from 'react-intl';
import Icon from 'calls/components/Icon.jsx';
import translate from 'i18n-translations/translate.jsx';
import { RadioButton } from 'components/index.js';
import { Allergies as AllergyTypes } from 'constants/visitEnums.js';
import { capitalizeFirstLetter } from 'infrastructure/helpers/commonHelpers.js';
import { AddEditPatientRadioTypes } from 'constants/enums.js';
import { SyrupIcon, EnvironmentIcon, LaboratoryIcon, FoodIcon } from 'calls/icons/index.js';

const Allergies = props => {
	const intl = useIntl();
	const [editable, setEditable] = useState(false);
	const [errorOnAllergyEnter, setErrorOnAllergyEnter] = useState({});
	const [allergyStatus, setAllergyStatus] = useState(0);
	const foodInput = useRef(null);
	const medicationInput = useRef(null);
	const environmentalInput = useRef(null);
	const biologicalInput = useRef(null);
	const allergies = props.patientProfile?.allergies;

	const allergiesStatusList = [
		{
			code: 0,
			status: null,
		},
		{
			code: 1,
			status: true,
		},
		{
			code: 2,
			status: false,
		},
	];

	useEffect(() => {
		const allergiesStatus =
			props.patientProfile?.hasAllergy !== undefined
				? allergiesStatusList.find(item => item.status === props.patientProfile?.hasAllergy).code
				: AddEditPatientRadioTypes.NO_ANSWER;
		setAllergyStatus(allergiesStatus);
	}, []);

	const onEnter = e => {
		if (!e.target.value) {
			return;
		}

		const enter = 13;
		const value = capitalizeFirstLetter(e.target.value);
		const foodArr = [...props.foodAllergies];
		const medicArr = [...props.medicationAllergies];
		const environmentalArr = [...props.environmentalAllergies];
		const biologicalArr = [...props.biologicalAllergies];

		if (e.keyCode === enter) {
			if (allergyStatus !== AddEditPatientRadioTypes.YES) {
				setAllergyStatus(AddEditPatientRadioTypes.YES);
			}
			if (!/^[a-zA-Z\s]*$/.test(value)) {
				setErrorOnAllergyEnter({ errorAt: e.target.name, errorMessage: intl.formatMessage({ id: 'allergyNameNonNumber' }) });
				return;
			}
			if (e.target.name === 'foodAllergy') {
				if (!foodArr.find(item => item.toLowerCase() === value.toLowerCase())) {
					foodArr.push(value);
					props.setFoodAllergies(foodArr);
					foodInput.current.value = '';
				}
			}
			if (e.target.name === 'medicationAllergy') {
				if (!medicArr.find(item => item.toLowerCase() === value.toLowerCase())) {
					medicArr.push(value);
					props.setMedicationAllergies(medicArr);
					medicationInput.current.value = '';
				}
			}
			if (e.target.name === 'environmentalAllergy') {
				if (!environmentalArr.find(item => item.toLowerCase() === value.toLowerCase())) {
					environmentalArr.push(value);
					props.setEnvironmentalAllergies(environmentalArr);
					environmentalInput.current.value = '';
				}
			}
			if (e.target.name === 'biologicalAllergy') {
				if (!biologicalArr.find(item => item.toLowerCase() === value.toLowerCase())) {
					biologicalArr.push(value);
					props.setBiologicalAllergies(biologicalArr);
					biologicalInput.current.value = '';
				}
			}
			setErrorOnAllergyEnter({});
		}
	};

	const removeAllergy = (item, type) => {
		const foodArr = [...props.foodAllergies];
		const medicArr = [...props.medicationAllergies];
		const environmentalArr = [...props.environmentalAllergies];
		const biologicalArr = [...props.biologicalAllergies];

		let foundIndex = null;

		if (type.id === AllergyTypes.FOOD.id) {
			foundIndex = foodArr.findIndex(el => el === item);
			if (foundIndex > -1) {
				foodArr.splice(foundIndex, 1);
			}
			props.setFoodAllergies(foodArr);
		}
		if (type.id === AllergyTypes.MEDICATION.id) {
			foundIndex = medicArr.findIndex(el => el === item);
			if (foundIndex > -1) {
				medicArr.splice(foundIndex, 1);
			}
			props.setMedicationAllergies(medicArr);
		}
		if (type.id === AllergyTypes.ENVIRONMENTAL.id) {
			foundIndex = environmentalArr.findIndex(el => el === item);
			if (foundIndex > -1) {
				environmentalArr.splice(foundIndex, 1);
			}
			props.setEnvironmentalAllergies(environmentalArr);
		}
		if (type.id === AllergyTypes.BIOLOGICAL.id) {
			foundIndex = biologicalArr.findIndex(el => el === item);
			if (foundIndex > -1) {
				biologicalArr.splice(foundIndex, 1);
			}
			props.setBiologicalAllergies(biologicalArr);
		}
	};

	const getExistingAllergies = type => {
		if (!allergies) {
			return false;
		}
		const filteredAllergies = allergies.filter(({ categoryId: id }) => id === type.id);
		const allergiesList = filteredAllergies.flatMap(({ note }) => {
			const parsedAllergy = note ? JSON.parse(note) : [];
			return parsedAllergy.map(allergy => (
				<div key={allergy} className='flex'>
					<p>{allergy}</p>
				</div>
			));
		});
		return allergiesList;
	};

	const checkCategoryExists = categoryId => {
		const obj = allergies.find(item => item.categoryId === categoryId);
		if (obj && obj.note) {
			const noteArray = JSON.parse(obj.note);
			return Array.isArray(noteArray) && noteArray.length === 0;
		}
		return false;
	};

	const handlePatientAnswer = event => {
		if (event.target.value !== AddEditPatientRadioTypes.YES) {
			props.setBiologicalAllergies([]);
			props.setEnvironmentalAllergies([]);
			props.setMedicationAllergies([]);
			props.setFoodAllergies([]);
			props.setHealthInformation({});
		}
		setAllergyStatus(+event.target.value);
	};

	const handleSaveAllergies = () => {
		if (!editable) {
			setEditable(true);
			return;
		}
		setEditable(false);

		const allergyTypesArr = Object.values(AllergyTypes);
		const healthInfo = [];
		allergyTypesArr.forEach(element => {
			let note = '';
			if (element.id === AllergyTypes.FOOD.id) {
				const prevAllergies = checkCategoryExists(AllergyTypes.FOOD.id)
					? JSON.parse(allergies.find(({ categoryId: id }) => id === element.id).note)
					: [];
				const foodArr = [...props.foodAllergies, ...prevAllergies];
				note = JSON.stringify(foodArr);
			}
			if (element.id === AllergyTypes.MEDICATION.id) {
				const prevAllergies = checkCategoryExists(AllergyTypes.MEDICATION.id)
					? JSON.parse(allergies.find(({ categoryId: id }) => id === element.id).note)
					: [];
				const medicArr = [...props.medicationAllergies, ...prevAllergies];
				note = JSON.stringify(medicArr);
			}
			if (element.id === AllergyTypes.ENVIRONMENTAL.id) {
				const prevAllergies = checkCategoryExists(AllergyTypes.ENVIRONMENTAL)
					? JSON.parse(allergies.find(({ categoryId: id }) => id === element.id).note)
					: [];
				const environmentalArr = [...props.environmentalAllergies, ...prevAllergies];
				note = JSON.stringify(environmentalArr);
			}
			if (element.id === AllergyTypes.BIOLOGICAL.id) {
				const prevAllergies = checkCategoryExists(AllergyTypes.BIOLOGICAL.id)
					? JSON.parse(allergies.find(({ categoryId: id }) => id === element.id).note)
					: [];
				const biologicalArr = [...props.biologicalAllergies, ...prevAllergies];
				note = JSON.stringify(biologicalArr);
			}
			healthInfo.push({
				categoryId: element.id,
				note,
			});
		});

		props.setHealthInformation({
			hasAllergy: allergyStatus,
			allergies: healthInfo,
		});
	};

	return (
		<div className='allergies-form'>
			<div className='allergies-header'>
				<p>{translate('allergies')}</p>
				<span className='edit-allergies' onClick={handleSaveAllergies}>
					<Icon name={editable ? 'check' : 'add_box'} size={15} />
					{editable && <span>{translate('clickToSave')}</span>}
					{!editable && <span>{translate('addMoreAllergies')}</span>}
				</span>
			</div>
			{!editable && <p>{translate('patientAcknowledgedAllergies')}</p>}
			{editable && (
				<>
					<p>{translate('doesHaveAllergies')}</p>
					<div className='patient-answered'>
						<RadioButton
							name='hasAllergy'
							id='yes'
							value={AddEditPatientRadioTypes.YES}
							text={translate('yes')}
							onChange={event => handlePatientAnswer(event)}
							checked={allergyStatus === AddEditPatientRadioTypes.YES}
						/>
						<RadioButton
							disabled={allergies.length > 0}
							name='hasAllergy'
							id='no'
							value={AddEditPatientRadioTypes.NO}
							text={translate('no')}
							onChange={event => handlePatientAnswer(event)}
							checked={allergyStatus === AddEditPatientRadioTypes.NO}
						/>
						<RadioButton
							disabled={allergies.length > 0}
							name='hasAllergy'
							id='noResponseFromPatient'
							value={AddEditPatientRadioTypes.NO_ANSWER}
							text={translate('noResponseFromPatient')}
							onChange={event => handlePatientAnswer(event)}
							checked={allergyStatus === AddEditPatientRadioTypes.NO_ANSWER}
						/>
					</div>
				</>
			)}
			<div className='allergies-content'>
				<div className='patient-registration-allergy-list food-allergies'>
					<div>
						<div className='allergy-title'>
							<FoodIcon />
							<p>{translate('foodAllergies')}</p>
						</div>
						{editable && (
							<input
								type='text'
								name='foodAllergy'
								placeholder={intl.formatMessage({ id: 'writeAllergyAndEnter' })}
								ref={foodInput}
								onKeyDown={e => onEnter(e)}
								enterKeyHint='go'
							/>
						)}
					</div>
					<div className='allergies-errors'>
						{errorOnAllergyEnter.errorAt === 'foodAllergy' && (
							<span className='red-error'>{errorOnAllergyEnter.errorMessage}</span>
						)}
					</div>
					<div className='flex allergies-list'>
						{getExistingAllergies(AllergyTypes.FOOD)}
						{props.foodAllergies.length > 0 &&
							props.foodAllergies.map(item => (
								<div key={item} className='flex'>
									{editable && (
										<i className='material-icons' onClick={() => removeAllergy(item, AllergyTypes.FOOD)}>
											close
										</i>
									)}
									<p>{item}</p>
								</div>
							))}
						{checkCategoryExists(AllergyTypes.FOOD.id) && props.foodAllergies.length === 0 && (
							<p>{translate('noAddedAllergies')}</p>
						)}
					</div>
				</div>
				<div className='patient-registration-allergy-list medication-allergies'>
					<div>
						<div className='allergy-title'>
							<SyrupIcon />
							<p>{translate('medicationAllergies')}</p>
						</div>
						{editable && (
							<input
								type='text'
								name='medicationAllergy'
								placeholder={intl.formatMessage({ id: 'writeAllergyAndEnter' })}
								ref={medicationInput}
								onKeyDown={e => onEnter(e)}
								enterKeyHint='go'
							/>
						)}
					</div>
					<div className='allergies-errors'>
						{errorOnAllergyEnter.errorAt === 'medicationAllergy' && (
							<span className='red-error'>{errorOnAllergyEnter.errorMessage}</span>
						)}
					</div>
					<div className='flex  allergies-list'>
						{getExistingAllergies(AllergyTypes.MEDICATION)}
						{props.medicationAllergies.length > 0 &&
							props.medicationAllergies.map(item => (
								<div key={item} className='flex'>
									{editable && (
										<i className='material-icons' onClick={() => removeAllergy(item, AllergyTypes.MEDICATION)}>
											close
										</i>
									)}
									<p>{item}</p>
								</div>
							))}
						{checkCategoryExists(AllergyTypes.MEDICATION.id) && props.medicationAllergies.length === 0 && (
							<p>{translate('noAddedAllergies')}</p>
						)}
					</div>
				</div>
				<div className='patient-registration-allergy-list environmental-allergies'>
					<div>
						<div className='allergy-title'>
							<EnvironmentIcon />
							<p>{translate('environmentalAllergies')}</p>
						</div>
						{editable && (
							<input
								type='text'
								name='environmentalAllergy'
								placeholder={intl.formatMessage({ id: 'writeAllergyAndEnter' })}
								ref={environmentalInput}
								onKeyDown={e => onEnter(e)}
								enterKeyHint='go'
							/>
						)}
					</div>
					<div className='allergies-errors'>
						{errorOnAllergyEnter.errorAt === 'environmentalAllergy' && (
							<span className='red-error'>{errorOnAllergyEnter.errorMessage}</span>
						)}
					</div>
					<div className='flex  allergies-list'>
						{getExistingAllergies(AllergyTypes.ENVIRONMENTAL)}
						{props.environmentalAllergies.length > 0 &&
							props.environmentalAllergies.map(item => (
								<div key={item} className='flex'>
									{editable && (
										<i className='material-icons' onClick={() => removeAllergy(item, AllergyTypes.ENVIRONMENTAL)}>
											close
										</i>
									)}
									<p>{item}</p>
								</div>
							))}
						{checkCategoryExists(AllergyTypes.ENVIRONMENTAL.id) && props.environmentalAllergies.length === 0 && (
							<p>{translate('noAddedAllergies')}</p>
						)}
					</div>
				</div>
				<div className='patient-registration-allergy-list biological-allergies'>
					<div>
						<div className='allergy-title'>
							<LaboratoryIcon />
							<p>{translate('biologicalAllergies')}</p>
						</div>
						{editable && (
							<input
								type='text'
								name='biologicalAllergy'
								placeholder={intl.formatMessage({ id: 'writeAllergyAndEnter' })}
								ref={biologicalInput}
								onKeyDown={e => onEnter(e)}
								enterKeyHint='go'
							/>
						)}
					</div>
					<div className='allergies-errors'>
						{errorOnAllergyEnter.errorAt === 'biologicalAllergy' && (
							<span className='red-error'>{errorOnAllergyEnter.errorMessage}</span>
						)}
					</div>
					<div className='flex  allergies-list'>
						{getExistingAllergies(AllergyTypes.BIOLOGICAL)}
						{props.biologicalAllergies.length > 0 &&
							props.biologicalAllergies.map(item => (
								<div key={item} className='flex'>
									{editable && (
										<i className='material-icons' onClick={() => removeAllergy(item, AllergyTypes.BIOLOGICAL)}>
											close
										</i>
									)}
									<p>{item}</p>
								</div>
							))}
						{checkCategoryExists(AllergyTypes.BIOLOGICAL.id) && props.biologicalAllergies.length === 0 && (
							<p>{translate('noAddedAllergies')}</p>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Allergies;
