import { useEffect, useState } from 'react';
import Alert from 'components/Common/Alert.jsx';
import Pagination from 'components/Common/Pagination.jsx';
import CustomTable from 'components/Common/CustomTable.jsx';
import Grid from 'components/Common/Grid.jsx';
import Input from 'components/Common/Input.jsx';
import Button from 'components/Common/Button.jsx';
import ProfilePicture from 'components/Common/ProfilePicture.jsx';
import BadgeModal from 'components/Configurations/BadgeAuthorization/BadgeModal.jsx';
import Select from 'react-select';
import translate from 'i18n-translations/translate.jsx';
import { getNurseCallEvents } from 'api/badgeAuthorization.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import TableEditButton from 'icons/Admin/TableEditButton.jsx';

const tableHeaders = intl => [
	{ title: intl.formatMessage({ id: 'employeeId' }), id: 'id' },
	{ title: intl.formatMessage({ id: 'name' }), id: 'firstName' },
	{ title: intl.formatMessage({ id: 'lastName' }), id: 'lastName' },
	{ title: intl.formatMessage({ id: 'title' }), id: 'title' },
	{ title: intl.formatMessage({ id: 'proxBadge' }), id: 'accessRFID' },
	{ title: intl.formatMessage({ id: 'centrakBadge' }), id: 'nurseCallSystemBadgeId' },
	{ title: intl.formatMessage({ id: 'userPicture' }), id: 'imageUrl' },
	{ title: intl.formatMessage({ id: 'status' }), id: 'isActive' },
	{ title: `PAC ${intl.formatMessage({ id: 'status' })}`, id: 'hasPacsAccess' },
	{ title: '', id: 'action' },
];

const BadgeAuthorization = props => {
	const intl = useIntl();
	const userSession = useSelector(state => state.user.userSession);
	const [isLoading, setLoading] = useState(true);
	const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10, totalCount: 0 });
	const [nurseCallEvents, setNurseCallEvents] = useState([]);
	const [selectedNurseCallEvent, setSelectedNurseCallEvent] = useState(null);
	const [error, setError] = useState(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedHealthSystem, setSelectedHealthSystem] = useState(
		props.healthSystems.length > 0 ? { value: userSession.healthSystem.id, label: userSession.healthSystem.name } : null
	);
	const [searchValue, setSearchValue] = useState('');

	const fetchNurseCallEvents = async () => {
		setLoading(true);
		const response = await getNurseCallEvents({
			pageIndex: pagination.pageIndex,
			pageSize: pagination.pageSize,
			healthSystemId: selectedHealthSystem?.value,
			searchParam: searchValue,
		});
		if (response.error) {
			setError(response.error.message);
			setLoading(false);
			return;
		}
		const images = response.result.data.map(item => ({ ...item, imageUrl: item?.profilePicture?.uri?.uri ?? null }));
		setNurseCallEvents(images);
		setPagination(prevState => ({ ...prevState, totalCount: response.result.pagination.totalCount }));
		setLoading(false);
	};

	useEffect(() => {
		fetchNurseCallEvents();
	}, [pagination.pageIndex, pagination.pageSize, selectedHealthSystem, searchValue]);

	const getTableConfigurations = () => {
		if (isLoading) {
			return [];
		}
		return nurseCallEvents.map(item => ({
			id: item.employeeId,
			firstName: item.firstName || 'N/A',
			lastName: item.lastName || 'N/A',
			title: item.title || 'N/A',
			accessRFID: item.accessRFID || 'N/A',
			nurseCallSystemBadgeId: item.nurseCallSystemBadgeId || 'N/A',
			imageUrl: !item.imageUrl ? (
				<ProfilePicture fullName={`${item.firstName} ${item.lastName}`} className='provider-intervening-img-wrapper' />
			) : (
				<img className='avatar-img' src={item.imageUrl} alt={intl.formatMessage({ id: 'backgroundImage' })} />
			),
			isActive: translate(item.isActive ? 'active' : 'inactive'),
			hasPacsAccess: translate(item.hasPacsAccess ? 'active' : 'inactive'),
			action: (
				<div className='wrapped'>
					<TableEditButton
						onClick={() => {
							setSelectedNurseCallEvent(item);
							setIsModalOpen(true);
						}}
					/>
				</div>
			),
		}));
	};

	const handleSearchChange = value => {
		setSearchValue(value);
		setPagination(prevState => ({ ...prevState, pageIndex: 0 }));
	};

	const modalTitle = () => {
		if (selectedNurseCallEvent?.id) {
			return `${intl.formatMessage({ id: 'edit' })} ${selectedNurseCallEvent?.firstName} ${selectedNurseCallEvent?.lastName}`;
		}
		return translate('addUser');
	};

	return (
		<>
			<CustomTable isLoading={isLoading} headers={tableHeaders(intl)} rows={getTableConfigurations()}>
				<Grid columns='1fr 1fr 2fr' gridGap='10px' vertAlign='center'>
					<Select
						value={selectedHealthSystem}
						placeholder={intl.formatMessage({ id: 'selectHealthSystem' })}
						classNamePrefix='react-select'
						options={props.healthSystems.map(item => ({ value: item.id, label: item.value }))}
						onChange={setSelectedHealthSystem}
					/>
					<Input
						type='text'
						placeholder={intl.formatMessage({ id: 'search' })}
						bottomSpace='0'
						variant='filled flex-1'
						name='searchUsers'
						maxLength={100}
						value={searchValue}
						onChange={event => handleSearchChange(event.target.value)}
					/>

					<div className='position-relative invite-users-wrapper'>
						<Button
							text={translate('addUser')}
							imgIcon={`${healthCareCdnUrl}appointments/create-appointment.svg`}
							onClick={() => setIsModalOpen(true)}
						/>
					</div>
				</Grid>
			</CustomTable>

			<Pagination
				totalCount={pagination.totalCount}
				pageSize={pagination.pageSize}
				pageIndex={pagination.pageIndex}
				onChange={(size, index) => setPagination(prevState => ({ ...prevState, pageSize: size, pageIndex: index }))}
			/>
			<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
			{isModalOpen && (
				<BadgeModal
					title={modalTitle()}
					isLoading={isLoading}
					setIsModalOpen={setIsModalOpen}
					selectedNurseCallEvent={selectedNurseCallEvent}
					setSelectedNurseCallEvent={setSelectedNurseCallEvent}
					fetchNurseCallEvents={fetchNurseCallEvents}
				/>
			)}
		</>
	);
};

export default BadgeAuthorization;
