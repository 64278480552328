import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import FormInput from 'components/Common/FormInput.jsx';
import { doNotAllowSpaceAsFirstCharacter } from 'infrastructure/helpers/commonHelpers.js';
import { DeviceListLevel } from 'constants/enums.js';

const EcareManagerEpicCerner = ({ itemToEdit, setConfigParams, type, fieldErrors }) => {
	const intl = useIntl();
	const [clientId, setClientId] = useState(itemToEdit?.clientId ?? '');
	const [clientSecret, setClientSecret] = useState(itemToEdit?.clientSecret ?? '');
	const [issuer, setIssuer] = useState(itemToEdit?.issuer ?? '');
	const [returnUrl, setReturnUrl] = useState(itemToEdit?.returnUrl ?? '');
	const [oauthScopes, setOauthScopes] = useState(itemToEdit?.oauthScopes ?? '');
	const allHealthSystems = useSelector(state => state.healthSystems.allHealthSystems);
	const [selectedHealthSystem, setSelectedHealthSystem] = useState(
		itemToEdit?.healthSystemId
			? {
					value: itemToEdit.healthSystemId,
					label: allHealthSystems.find(item => item.id === itemToEdit.healthSystemId)?.name,
			  }
			: { value: '0', label: 'All' }
	);

	const onHealthSystemChange = async event => {
		setSelectedHealthSystem(event);
	};

	useEffect(() => {
		setConfigParams({
			configurationTypeId: type,
			nodeId: getNodeId().value !== '0' ? getNodeId().value : null,
			parentNodeId: selectedHealthSystem.value !== '0' ? selectedHealthSystem.value : null,
			nodeName: getNodeId().label,
			nodeLevel: getNodeLevel(),
			configJson: JSON.stringify({ clientId, clientSecret, issuer, returnUrl, oauthScopes }),
		});
	}, [selectedHealthSystem, clientId, clientSecret, issuer, returnUrl, oauthScopes, setConfigParams, type]);

	const getNodeId = () => (selectedHealthSystem.value !== '0' ? selectedHealthSystem : { value: null, label: '' });

	const getNodeLevel = () => {
		if (selectedHealthSystem.value !== '0') {
			return DeviceListLevel.HEALTH_SYSTEM;
		}
		return null;
	};

	const transformArray = array => {
		if (!array) {
			return [];
		}
		const newArray = array.map(item => ({ value: item.id, label: item.name || item.value }));
		newArray.unshift({ value: '0', label: intl.formatMessage({ id: 'all' }) });
		return newArray;
	};

	return (
		<>
			<div className='input'>
				<p className='label'>{intl.formatMessage({ id: 'selectHealthSystem' })}</p>
				<p className='font-14'>{intl.formatMessage({ id: 'selectHSForConfiguration' })}</p>
				<Select
					value={selectedHealthSystem}
					placeholder={intl.formatMessage({ id: 'selectHealthSystem' })}
					classNamePrefix='react-select'
					options={transformArray(allHealthSystems)}
					onChange={onHealthSystemChange}
				/>
			</div>
			<div className='input'>
				<FormInput
					text={`${intl.formatMessage({ id: 'clientId' })}*`}
					id='clientId'
					placeholder={intl.formatMessage({ id: 'clientId' })}
					type='text'
					value={clientId}
					onChange={event => setClientId(event.target.value)}
					maxLength={256}
					onKeyDown={doNotAllowSpaceAsFirstCharacter}
					error={fieldErrors?.clientId}
					touched={fieldErrors?.clientId}
				/>
			</div>
			<div className='input'>
				<FormInput
					text={`${intl.formatMessage({ id: 'clientSecret' })}*`}
					id='clientSecret'
					placeholder={intl.formatMessage({ id: 'clientSecret' })}
					type='text'
					value={clientSecret}
					onChange={event => setClientSecret(event.target.value)}
					maxLength={256}
					onKeyDown={doNotAllowSpaceAsFirstCharacter}
					error={fieldErrors?.clientSecret}
					touched={fieldErrors?.clientSecret}
				/>
			</div>
			<div className='input'>
				<FormInput
					text={`${intl.formatMessage({ id: 'issuer' })}*`}
					id='issuer'
					placeholder={intl.formatMessage({ id: 'issuer' })}
					type='text'
					value={issuer}
					onChange={event => setIssuer(event.target.value)}
					maxLength={256}
					onKeyDown={doNotAllowSpaceAsFirstCharacter}
					error={fieldErrors?.issuer}
					touched={fieldErrors?.issuer}
				/>
			</div>
			<div className='input'>
				<FormInput
					text={`${intl.formatMessage({ id: 'returnUrl' })}*`}
					id='returnUrl'
					placeholder={intl.formatMessage({ id: 'returnUrl' })}
					type='text'
					value={returnUrl}
					onChange={event => setReturnUrl(event.target.value)}
					maxLength={256}
					onKeyDown={doNotAllowSpaceAsFirstCharacter}
					error={fieldErrors?.returnUrl}
					touched={fieldErrors?.returnUrl}
				/>
			</div>
			<div className='input'>
				<FormInput
					text={`${intl.formatMessage({ id: 'oauthScopes' })}*`}
					id='oauthScopes'
					placeholder={intl.formatMessage({ id: 'oauthScopes' })}
					type='text'
					value={oauthScopes}
					onChange={event => setOauthScopes(event.target.value)}
					maxLength={256}
					onKeyDown={doNotAllowSpaceAsFirstCharacter}
					error={fieldErrors?.oauthScopes}
					touched={fieldErrors?.oauthScopes}
				/>
			</div>
		</>
	);
};

export default EcareManagerEpicCerner;
