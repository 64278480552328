import { getPressureInjuryHistory } from 'api/patients.js';
import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';
import { Grid } from 'components/index.js';
import Loader from 'components/Common/Loader.jsx';
import { PatientPositionTypes } from 'constants/enums.js';
import { StyledPatientHistory } from 'css/StyledComponents/index.js';
import translate from 'i18n-translations/translate.jsx';
import LeftLateralIcon from 'icons/Monitoring/LeftLateral.jsx';
import ProneIcon from 'icons/Monitoring/Prone.jsx';
import RightLateralIcon from 'icons/Monitoring/RightLateral.jsx';
import SupineIcon from 'icons/Monitoring/Supine.jsx';
import UpInAChairIcon from 'icons/Monitoring/UpInAChair.jsx';
import { getAnalyticsTypeById } from 'infrastructure/helpers/aiHelper.jsx';
import { getDateWithSeconds } from 'infrastructure/helpers/dateHelper.js';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

const AiPatientPositionHistory = ({ patientId, isDarkMode }) => {
	const [history, setHistory] = useState([]);
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 0, totalCount: 0 });
	const intl = useIntl();

	const hasReachedEnd = useRef(null);

	const getPatientPositionIcon = position => {
		const iconColor = isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive;

		switch (position) {
			case PatientPositionTypes.SUPINE:
				return <SupineIcon color={iconColor} />;
			case PatientPositionTypes.PRONE:
				return <ProneIcon color={iconColor} />;
			case PatientPositionTypes.LEFT_LATERAL:
				return <LeftLateralIcon color={iconColor} />;
			case PatientPositionTypes.RIGHT_LATERAL:
				return <RightLateralIcon color={iconColor} />;
			default:
				return <UpInAChairIcon color={iconColor} />;
		}
	};

	const getPressureInjuryDuration = (startTime, endTime) => {
		const diffMilliseconds = moment(endTime).diff(moment(startTime));
		const diffDuration = moment.duration(diffMilliseconds);

		const days = diffDuration.days();
		const hours = diffDuration.hours();
		const minutes = diffDuration.minutes();
		const seconds = diffDuration.seconds();
		if (days === 0 && hours === 0 && minutes === 0) {
			return `${seconds} ${intl.formatMessage({ id: 'seconds' })}`;
		}
		if (days === 0 && hours === 0 && minutes !== 0) {
			return `${minutes}m ${seconds}s`;
		}
		if (days === 0 && hours !== 0 && minutes !== 0) {
			return `${hours}h ${minutes}m ${seconds}s`;
		}
		return `${days}d ${hours}h`;
	};

	const handleScroll = event => {
		const isBottom = event.target.scrollHeight - Math.ceil(event.target.scrollTop) === event.target.clientHeight;
		if (isBottom && !hasReachedEnd.current) {
			setPagination(prevState => ({ ...prevState, pageIndex: prevState.pageIndex + 1 }));
		}
	};

	useEffect(() => {
		const getPatientPositionHistory = async () => {
			setError(null);
			setIsLoading(true);
			const params = {
				patientId,
				pageIndex: pagination.pageIndex,
				pageSize: pagination.pageSize,
			};
			const response = await getPressureInjuryHistory(params);
			if (response.error) {
				setError(translate('somethingWentWrong'));
				setIsLoading(false);
				return;
			}
			if (pagination.pageIndex === 0) {
				setHistory(response.pressureInjuryHistory);
			} else {
				setHistory(prevState => [...prevState, ...(response.pressureInjuryHistory || [])]);
			}
			setPagination(prevState => ({ ...prevState, totalCount: response.totalCount || 0 }));
			hasReachedEnd.current = response?.pressureInjuryHistory?.length < 10;
			setIsLoading(false);
		};

		getPatientPositionHistory();
	}, [pagination.pageIndex, pagination.pageSize, patientId]);

	return (
		<StyledPatientHistory
			$hasError={error}
			$scroll={history.length > 0}
			$isDarkMode={isDarkMode}
			onScroll={handleScroll}
			$isPatientPosition={true}>
			{error && <p className='error-message'>{error}</p>}
			{isLoading && (
				<Grid columns='1fr' rows='1fr' stretch='250px' horizAlign='center' vertAlign='center'>
					<div className='text-align-center'>
						<Loader />
					</div>
				</Grid>
			)}
			{!history.length && !isLoading && !error && (
				<Grid columns='1fr' rows='1fr' stretch='250px' horizAlign='center' vertAlign='center'>
					<div className='text-align-center'>
						<p>{translate('noHistoryFound')}</p>
					</div>
				</Grid>
			)}
			{history.map(data => (
				<div className='history-container' key={data.detection}>
					<div className='history-info'>
						{getPatientPositionIcon(data.analyticTypeId)}
						<div>
							<p>
								{translate('pressureInjuryHistoryDescription', {
									value1: getPressureInjuryDuration(moment(data.startTime), moment(data.endTime)),
									value2: <span className='semi-bold'>{translate(getAnalyticsTypeById(data.analyticTypeId))}</span>,
								})}
							</p>
							<p>
								{translate('pressureInjuryHistoryTime', {
									value1: getDateWithSeconds(data.startTime),
									value2: getDateWithSeconds(data.endTime),
								})}
							</p>
						</div>
					</div>
				</div>
			))}
		</StyledPatientHistory>
	);
};

export default AiPatientPositionHistory;
