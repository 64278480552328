import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useIntl } from 'react-intl';
import moment from 'moment';
import HealthData from 'views/Patients/Partials/HealthData.jsx';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from 'components/Common/Tabs.jsx';
import CurrentVisit from 'containers/PrimaryCare/Doctor/CurrentVisit.jsx';
import translate from 'i18n-translations/translate.jsx';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import { ParenteralTherapyType, PrimaryCareForms, VisitStatus, VisitTypes, MaritalStatusList } from 'constants/visitEnums.js';
import { getGender } from 'infrastructure/helpers/commonHelpers.js';
import { getPatientMedicalCases, updateMedicalVisitStatus } from 'api/visits.js';
import Alert from 'components/Common/Alert.jsx';
import Button from 'components/Common/Button.jsx';
import Grid from 'components/Common/Grid.jsx';
import { getRoomInformation } from 'infrastructure/helpers/visitsHelper.js';
import NextVisitConfirmationModal from 'containers/PrimaryCare/NextVisitConfirmationModal.jsx';
import PatientHistory from 'containers/PrimaryCare/DigitalClinician/PatientHistory.jsx';
import { Icon } from 'calls/components/index.js';
import { getPatientByUserId } from 'api/patients.js';
import { monthDayYear } from 'infrastructure/helpers/dateHelper.js';
import { getDefaultUnit, getFormattedMeasurement } from 'infrastructure/helpers/measurementsHelper.js';

const InPersonVisit = () => {
	const patientProfileTabs = [
		{
			id: 0,
			tab: 'healthData',
		},
		{
			id: 1,
			tab: 'patientHistory',
		},
	];

	const intl = useIntl();
	const history = useHistory();
	const location = useLocation();
	const { current: currentCase } = useRef(location.state);
	const [patientProfileTab, setPatientProfileTab] = useState(0);
	const [forms, setForms] = useState([]);
	const [vitalSigns, setVitalSigns] = useState([]);
	const [error, setError] = useState(null);
	const [nextVisit, setNextVisit] = useState(null);
	const [isCompleteLoading, setIsCompleteLoading] = useState(false);
	const [diagnoses, setDiagnoses] = useState([]);
	const [patientMedicalCases, setPatientMedicalCases] = useState(null);
	const [showPatientProfile, setShowPatientProfile] = useState(false);
	const [patient, setPatient] = useState({});
	const [hIAllergies, sethIAllergies] = useState();
	const [mainConcerns, setMainConcerns] = useState('');
	const [anamnesis, setAnamnesis] = useState('');
	const [examinations, setExaminations] = useState('');
	const [recommendations, setRecommendations] = useState('');

	const maritalStatusList = [
		{ id: MaritalStatusList.UNMARRIED.id, name: intl.formatMessage({ id: 'single' }) },
		{ id: MaritalStatusList.MARRIED.id, name: intl.formatMessage({ id: 'married' }) },
		{ id: MaritalStatusList.DIVORCED.id, name: intl.formatMessage({ id: 'divorced' }) },
		{ id: MaritalStatusList.WIDOWED.id, name: intl.formatMessage({ id: 'widowWidower' }) },
	];

	useEffect(() => {
		getPatientMedicCases();
		getPatientData(currentCase?.patient.patientUserId);
	}, []);

	const getPatientData = async patientUserId => {
		const result = await getPatientByUserId(patientUserId);
		if (result.error) {
			setError(result.error.message);
			return;
		}
		setPatient(result.patient);
	};

	const getPatientMedicCases = async () => {
		const patientMedicalCasesRes = await getPatientMedicalCases(currentCase.patient.healthCareUserId);
		if (patientMedicalCasesRes.error) {
			setError(patientMedicalCasesRes.error.message);
			return;
		}
		setPatientMedicalCases({ medicalCases: patientMedicalCasesRes.medicalCases });
	};

	const setDoctorReferSuccess = () => {
		history.push('/waiting-room-inperson');
	};

	const getDrugs = () => {
		const drugForms = forms.find(form => form.id === PrimaryCareForms.DRUGS);
		return drugForms ? drugForms.medicationList.map(medic => ({ title: 'drugs', text: medic.name })) : [];
	};

	const getPreviousParentalTherapies = () => currentCase?.visits.map(visit => visit.parenteralTherapyVisits).flat();

	const getMeasurements = () =>
		vitalSigns.map(vitalSign => ({
			userId: currentCase?.patient.patientUserId,
			deviceType: 'web',
			measurementType: vitalSign.type,
			measurementValue: getFormattedMeasurement(vitalSign),
			measurementUnit: getDefaultUnit(vitalSign.type, vitalSign.unit),
			startDate: moment(),
			endDate: moment(),
			isManuallyInserted: true,
			source: 'web',
		}));

	const markVisitAsCompleted = async () => {
		if (!canSubmit()) {
			return;
		}
		setIsCompleteLoading(true);
		const params = {
			visitStatus: VisitStatus.COMPLETED,
			drugs: getDrugs(),
			diagnosis: diagnoses.map(diagnose => diagnose.code),
			parenteralTherapies: [],
			measurementsForVisit: getMeasurements(),
			examinations,
			recommendations,
			anamnesis,
			mainConcerns,
		};

		if (hIAllergies) {
			params.healthInformation = hIAllergies;
		}

		if (forms.length > 0) {
			const researchesObj = { testType: [] };
			forms.forEach(form => {
				if ([PrimaryCareForms.RADIOLOGY, PrimaryCareForms.LABORATORY].includes(form.id)) {
					researchesObj.testType.push(...form.addedTests.map(item => item.id));
					const noteType = form.id === PrimaryCareForms.RADIOLOGY ? 'radiologyNote' : 'laboratoryNote';
					researchesObj[noteType] = form.additionalNotes;
				}

				if ([PrimaryCareForms.PTH_INFUSION, PrimaryCareForms.PTH_INJECTION].includes(form.id)) {
					params.parenteralTherapies.push({
						type: form.id === PrimaryCareForms.PTH_INFUSION ? ParenteralTherapyType.INFUSION : ParenteralTherapyType.INJECTION,
						medications: form.medicationList.map(item => ({
							name: item.name,
							dosage: +item.dosage,
							frequency: +item.frequency,
							duration: +item.duration,
						})),
					});
				}
			});
			if (researchesObj.testType.length > 0) {
				params.diagnosticResearches = researchesObj;
			}
		}

		const response = await updateMedicalVisitStatus(currentCase?.activeVisit?.id, params);
		if (response.error) {
			setError(response.error.message);
			setIsCompleteLoading(false);
		} else {
			setIsCompleteLoading(false);
			setNextVisit({
				...response,
				icon: response.nextVisitInfo?.visitType ? getRoomInformation(intl, response.nextVisitInfo?.visitType).image : '',
				roomType: response.nextVisitInfo?.visitType ? getRoomInformation(intl, response.nextVisitInfo?.visitType).roomType : '',
			});
		}
	};

	const canSubmit = () => {
		let result = true;
		for (let i = 0; i < forms.length; i += 1) {
			const parenteralTherapy = [PrimaryCareForms.PTH_INFUSION, PrimaryCareForms.PTH_INJECTION, PrimaryCareForms.DRUGS].includes(
				forms[i].id
			);
			const laboratoryForm = forms[i].id === PrimaryCareForms.LABORATORY;
			const radiologyForm = forms[i].id === PrimaryCareForms.RADIOLOGY;
			if (
				parenteralTherapy &&
				forms[i].medicationList.some(
					item => item.name.trim() === '' || item.dosage === '' || item.duration === '' || item.frequency === ''
				)
			) {
				result = false;
				break;
			}

			if ((laboratoryForm && forms[i].addedTests.length === 0) || (radiologyForm && forms[i].addedTests.length === 0)) {
				result = false;
				break;
			}
		}

		if (diagnoses.length === 0 || mainConcerns.length === 0 || anamnesis.length === 0 || examinations.length === 0) {
			result = false;
		}
		return result;
	};

	const getPreviousResults = visitType => {
		let result = [];
		if (!currentCase || patientMedicalCases?.medicalCases?.length === 0) {
			return result;
		}
		const visitFound = patientMedicalCases?.medicalCases?.find(item => item.id === currentCase.caseId);
		if (!visitFound) {
			return result;
		}
		result = visitFound.medicalVisits.reduce((acc, curr) => {
			const list = visitType === VisitTypes.LAB ? curr.diagnosticResearches : curr.notes;
			if (curr.typeId === visitType && list.length > 0) {
				return [...acc, ...list];
			}
			return acc;
		}, []);
		return result;
	};

	return (
		<MainLayout>
			<div className='display-block in-person-visit-wrapper'>
				{!showPatientProfile && (
					<div className='current-visit'>
						<div className='flex started-visit-title full-width'>
							<div className=''>
								<Icon name='person' size={30} />
								<div>
									<span>{patient.profile?.idCard}</span>
									<span>{currentCase?.patient?.fullName}</span>
								</div>
							</div>
							<div className='patient-profile-data'>
								<div>
									<span>{translate('dateOfBirth')}</span>
									<p>{monthDayYear(patient.dateOfBirth)}</p>
								</div>
								<div>
									<span>{translate('maritalStatus')} </span>
									<p>{patient?.profile && maritalStatusList.find(item => item.id === patient.profile?.maritalStatusId)?.name}</p>
								</div>
								<div>
									<span>{translate('gender')}</span>
									<p>{getGender(currentCase?.patient?.genderId).description}</p>
								</div>
								<div>
									<span>{translate('profession')} </span>
									<p>{patient.profile?.profession}</p>
								</div>
							</div>
							<div>
								<Button
									type='button'
									className='no-border'
									text={translate('viewProfile')}
									onClick={() => setShowPatientProfile(prevState => !prevState)}
									icon='account_circle'
								/>
								<Button
									type='button'
									icon='arrow_forward'
									className='no-border'
									text={translate('markVisitAsCompleted')}
									onClick={markVisitAsCompleted}
									isLoading={isCompleteLoading}
									isDisabled={!canSubmit() || isCompleteLoading}
								/>
							</div>
						</div>
						<CurrentVisit
							forms={forms}
							setForms={setForms}
							vitalSigns={vitalSigns}
							setVitalSigns={setVitalSigns}
							currentCase={currentCase}
							labCaseAnalyses={getPreviousResults(VisitTypes.LAB)}
							radiologyCaseTests={getPreviousResults(VisitTypes.RADIOLOGY)}
							diagnoses={diagnoses}
							previousParentalTherapy={getPreviousParentalTherapies}
							setDiagnoses={setDiagnoses}
							canSubmit={canSubmit()}
							patientProfile={patient.profile}
							setHealthInformation={sethIAllergies}
							setRecommendations={setRecommendations}
							recommendations={recommendations}
							setExaminations={setExaminations}
							examinations={examinations}
							setAnamnesis={setAnamnesis}
							anamnesis={anamnesis}
							setMainConcerns={setMainConcerns}
							mainConcerns={mainConcerns}
						/>
					</div>
				)}
				{showPatientProfile && (
					<div className='patient-profile in-person-visit'>
						<div className='flex-1'>
							<span onClick={() => setShowPatientProfile(prevState => !prevState)}>
								<Icon name='arrow_back' size={22} />
							</span>
							<p>{translate('personsProfile', { value: currentCase?.patient?.fullName })}</p>
						</div>
						<Tabs className='full-width' activeIndex={patientProfileTab} onChange={index => setPatientProfileTab(index)}>
							<TabList>
								{patientProfileTabs.map(item => (
									<Tab key={item.id}>{translate(item.tab)}</Tab>
								))}
							</TabList>
							<TabPanels>
								<TabPanel>
									<HealthData
										selectedPatient={{
											userId: currentCase?.patient?.patientUserId,
											firstName: patient.firstname,
											lastName: patient.lastname,
											id: currentCase?.patient?.healthCareUserId,
										}}
										showPatientData={false}
										isFromPrimaryCare={true}
										isDoctorView={true}
									/>
								</TabPanel>
								<TabPanel>
									<Grid columns='1fr' stretch='100%'>
										<div className='check-in-patient-wrapper'>
											<div className='full-page-input-wrapper patient-history-wrapper position-relative'>
												<PatientHistory
													selectedPatient={patientMedicalCases}
													setPatientProfileTab={setPatientProfileTab}
													isDoctorView={true}
												/>
											</div>
										</div>
									</Grid>
								</TabPanel>
							</TabPanels>
						</Tabs>
					</div>
				)}
			</div>
			<NextVisitConfirmationModal modalSubmit={setDoctorReferSuccess} nextVisit={nextVisit} />
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</MainLayout>
	);
};

export default InPersonVisit;
