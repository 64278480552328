import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import DatePicker from 'react-datepicker';
import _ from 'lodash';
import { addMinutes, setHours, setMinutes } from 'date-fns';
import translate from 'i18n-translations/translate.jsx';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import Alert from 'components/Common/Alert.jsx';
import Form from 'components/Common/Form.jsx';
import Modal from 'components/Common/Modal.jsx';
import { getDay, parseStringToDate, scheduledDays, subtractMinutes } from 'infrastructure/helpers/dateHelper.js';
import { addDataAcquisitionSchedule, deleteDataAcquisitionSchedule, getDataAcquisitionSchedule } from 'api/dataAcquisition.js';
import Button from 'components/Common/Button.jsx';

const ScheduleDataAcquisition = props => {
	const intl = useIntl();
	const [alertErrorText, setAlertErrorText] = useState('');
	const [scheduledTime, setScheduledTime] = useState(scheduledDays);

	useEffect(() => {
		getDataAcquisition();
	}, []);

	const getInitialScheduledTimeMaped = list => {
		const mapedScheduledHours = list.map(item => ({
			...item,
			hours: item.hours.map(hour => ({
				to: parseStringToDate(hour.to),
				from: parseStringToDate(hour.from),
				id: hour.id,
			})),
		}));
		for (let i = 1; i < 8; i += 1) {
			if (!mapedScheduledHours.find(item => item.weekDay === i)) {
				mapedScheduledHours.push({
					weekDay: i,
					hours: [{ from: null, to: null, id: 0 }],
				});
			}
		}
		mapedScheduledHours.sort((a, b) => a.weekDay - b.weekDay);
		return mapedScheduledHours;
	};

	const getDataAcquisition = async () => {
		const response = await getDataAcquisitionSchedule({ roomId: props.roomId });
		if (response.error) {
			setAlertErrorText(response.error.message);
			return;
		}
		const groupedOptions = response.schedule.reduce((grouped, scheduled) => {
			const newGrouped = { ...grouped };
			const hrs = {
				from: scheduled.from,
				to: scheduled.to,
				id: scheduled.id,
			};
			if (newGrouped[scheduled.weekDay]) {
				newGrouped[scheduled.weekDay].push(hrs);
			} else {
				newGrouped[scheduled.weekDay] = [hrs];
			}
			return newGrouped;
		}, {});

		const asArray = Object.entries(groupedOptions).map(([key, val]) => ({ weekDay: +key, hours: val }));
		setScheduledTime(getInitialScheduledTimeMaped(asArray));
	};

	const onScheduledTimeChange = (date, weekDay, hoursIndex, isTo) => {
		setScheduledTime(prevState => {
			const clonedState = _.cloneDeep(prevState);
			const [selectedItem] = clonedState.splice(weekDay - 1, 1);
			const [splicedHours] = selectedItem.hours.splice(hoursIndex, 1);
			splicedHours[isTo ? 'to' : 'from'] = date;
			selectedItem.hours.splice(hoursIndex, 0, splicedHours);
			selectedItem.isChecked = selectedItem.hours.some(item => item.to && item.from);
			clonedState.splice(weekDay - 1, 0, selectedItem);
			return clonedState;
		});
	};

	const handleAddScheduledHour = weekDay => {
		const scheduledHour = scheduledTime.find(item => item.weekDay === weekDay);
		if (scheduledHour.hours.length === 5) {
			return;
		}
		setScheduledTime(prevState => {
			const clonedState = _.cloneDeep(prevState);
			const selectedItem = clonedState.find(item => item.weekDay === weekDay);
			selectedItem.hours.push({ from: null, to: null, id: selectedItem.hours.length });
			return clonedState;
		});
	};

	const handleRemoveScheduledTime = async (weekDay, index, scheduleId) => {
		setAlertErrorText(null);
		if (index !== scheduleId) {
			const response = await deleteDataAcquisitionSchedule({
				roomId: props.roomId,
				scheduleId,
			});
			if (response.error) {
				setAlertErrorText(response.error.message);
				return;
			}
		}
		setScheduledTime(prevState => {
			const clonedState = _.cloneDeep(prevState);
			const selectedItem = clonedState.find(item => item.weekDay === weekDay);
			if (selectedItem.hours.length === 1) {
				selectedItem.isChecked = false;
				selectedItem.hours[index] = { to: null, from: null, id: 0 };
			} else {
				selectedItem.hours.splice(index, 1);
			}
			return clonedState;
		});
	};

	const dateToString = date =>
		date.toLocaleTimeString('en-GB', {
			hour: '2-digit',
			minute: '2-digit',
			second: '2-digit',
		});

	const scheduledTimeMaped = () =>
		scheduledTime.reduce((result, element) => {
			if (element.isChecked) {
				const hours = element.hours.reduce((hoursRes, hour) => {
					if (hour.from && hour.to) {
						hoursRes.push({ from: dateToString(hour.from), to: dateToString(hour.to) });
					}
					return hoursRes;
				}, []);
				if (hours.length > 0) {
					result.push({ hours, weekDay: element.weekDay });
				}
			}
			return result;
		}, []);

	const createAvailability = async () => {
		const dataToSend = {
			roomId: props.roomId,
			acquisitionSchedule: scheduledTimeMaped(),
			deviceId: props.helloDeviceId,
		};
		const response = await addDataAcquisitionSchedule(dataToSend);
		if (response.error) {
			setAlertErrorText(response.error.message);
			return;
		}
		props.toggleSetUpAvailability();
	};

	return (
		<>
			<Modal
				display={true}
				shouldSubmitOnEnter={false}
				position='right'
				primaryButtonLabel={translate('saveChanges')}
				onModalClose={() => props.toggleSetUpAvailability()}
				onModalSubmit={createAvailability}
				className='wrapper-modal set-up-availability-modal border-radius-modal-wrapper appoinment-next-arrow-modal save-changes-modal schedule-data-acquisition'>
				<Form onSubmit={event => event.preventDefault()} className='modal-form-wrapper'>
					<h3>{translate('scheduleDataAcquisition')}</h3>
					<p>{translate('scheduleTimeForDataAcquisition')}</p>
					<div>
						<div className='create-appointment-modal'>
							{scheduledTime.map(item => (
								<div key={item.weekDay}>
									<div className='flex set-up-break-wrapper'>
										<p className='flex-1'>{getDay(item.weekDay, intl)} </p>
										<Button onClick={() => handleAddScheduledHour(item.weekDay)} icon='add' />
									</div>
									{item.hours.map((hours, index) => (
										<div className='flex set-up-break-wrapper' key={hours.id}>
											<div className='flex-1'>
												<div className='flex available-hours'>
													<DatePicker
														selected={hours.from}
														placeholderText={intl.formatMessage({ id: 'from' })}
														onChange={date => onScheduledTimeChange(date, item.weekDay, index, false)}
														showTimeSelect
														showTimeSelectOnly
														timeIntervals={5}
														dateFormat='h:mm aa'
														popperPlacement='top'
														minTime={setHours(setMinutes(new Date(), 0), 0)}
														maxTime={hours.to ? subtractMinutes(hours.to) : setHours(setMinutes(new Date(), 5), 23)}
													/>
													<DatePicker
														selected={hours.to}
														placeholderText={intl.formatMessage({ id: 'to' })}
														onChange={date => onScheduledTimeChange(date, item.weekDay, index, true)}
														showTimeSelect
														showTimeSelectOnly
														timeIntervals={5}
														dateFormat='h:mm aa'
														popperPlacement='top'
														minTime={hours.from ? addMinutes(hours.from, 5) : setHours(setMinutes(new Date(), 0), 0)}
														maxTime={setHours(setMinutes(new Date(), 5), 23)}
													/>
												</div>
											</div>
											{!(index === 0 && !hours.to && !hours.from) && (
												<Button onClick={() => handleRemoveScheduledTime(item.weekDay, index, hours.id)} icon='remove' />
											)}
										</div>
									))}
								</div>
							))}
						</div>
					</div>
				</Form>
				<Alert
					display={alertErrorText}
					fixed={true}
					message={alertErrorText}
					variant='dark'
					onClose={() => setAlertErrorText(null)}
				/>
			</Modal>
		</>
	);
};

export default ScheduleDataAcquisition;
