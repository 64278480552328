import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import { Alert, Form, Input, Modal } from 'components/index.js';
import { addGroupRole, updateGroupRole } from 'api/activeDirectory.js';
import { CompanyRoles, TeamTypes, UserRoles } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import { useSelector } from 'react-redux';
import { getHealthSystemHospitals } from 'api/userIdleConfigurations.js';
import { getUserRole } from 'infrastructure/auth.js';
import Select from 'react-select';

const getUserRolesList = companySettings =>
	[
		{ value: CompanyRoles.ADMIN, label: translate('admin') },
		{ value: CompanyRoles.SUPER_USER, label: translate('superUser') },
		{ value: CompanyRoles.DOCTOR, label: translate('doctor') },
		{ value: CompanyRoles.VIRTUAL_CARE_PROVIDER, label: companySettings.nurseDisplayName },
		{ value: CompanyRoles.VIRTUAL_SITTER, label: companySettings.virtualSitterDisplayName },
		{ value: CompanyRoles.DIGITAL_CLINICIAN, label: translate('digitalClinician') },
		getUserRole() === UserRoles.SUPER_ADMIN && { value: CompanyRoles.SUPER_ADMIN, label: translate('superAdmin') },
	].filter(Boolean);

const AdGroupRoleModal = props => {
	const intl = useIntl();
	const companySettings = useSelector(state => state.company.companySettings);
	const [error, setError] = useState('');
	const [formHospitals, setFormHospitals] = useState([]);

	const getValidationSchema = () =>
		Yup.object().shape({
			role: Yup.object().required(intl.formatMessage({ id: 'poolRoleRequired' })),
			group: Yup.string().required(intl.formatMessage({ id: 'groupRolesRequired' })),
			healthSystem: Yup.object()
				.nullable()
				.when([], {
					is: () => !props.initialValues && getUserRole() !== UserRoles.SUPER_ADMIN,
					then: () => Yup.object().required(intl.formatMessage({ id: 'healthSystemRequired' })),
				}),
		});

	const getInitialValues = () =>
		props.initialValues
			? {
					...props.initialValues,
					role: getUserRolesList(companySettings).find(role => role.value === props.initialValues.role.id),
					hospital: { value: props.initialValues.team?.id, label: props.initialValues.team?.name },
			  }
			: {
					healthSystem: null,
					group: '',
					hospital: null,
					role:
						getUserRole() === UserRoles.SUPER_ADMIN ? { value: CompanyRoles.SUPER_ADMIN, label: translate('superAdmin') } : null,
					teamTypeId: null,
			  };

	const handleSubmitMyForm = async (values, { resetForm }) => {
		const params = {
			teamId: getUserRole() === UserRoles.SUPER_ADMIN ? null : values.hospital?.value || values.healthSystem?.value,
			...(!props.initialValues && { teamTypeId: values.teamTypeId }),
			roleId: values.role?.value,
			group: values.group,
		};

		const response = props.initialValues ? await updateGroupRole(values.id, params) : await addGroupRole(params);

		if (response.error) {
			setError(response.error.message);
			return;
		}
		resetForm();
		props.onSucceeded();
	};

	const onCloseModal = resetForm => {
		resetForm();
		props.toggleModal();
	};

	const onHealthSystemChange = async (hs, setFieldValue) => {
		setFieldValue('healthSystem', hs);
		setFieldValue('teamTypeId', TeamTypes.HEALTH_SYSTEM);
		const hsHospitals = hs.value !== '0' ? await getHealthSystemHospitals(hs.value) : [];
		if (hsHospitals.error) {
			setError(hsHospitals.error.message);
		} else {
			const hospitalArray = hsHospitals.map(hospital => ({ value: hospital.id, label: hospital.name }));
			setFormHospitals(hospitalArray);
		}
	};

	return (
		<Formik
			validateOnChange={false}
			validateOnBlur={true}
			enableReinitialize={true}
			initialValues={getInitialValues()}
			validationSchema={getValidationSchema}
			onSubmit={handleSubmitMyForm}>
			{({ values, handleSubmit, isSubmitting, resetForm, setFieldValue, errors }) => (
				<Modal
					display={props.isModalOpen}
					position='right'
					onModalSubmit={handleSubmit}
					className='active-directory-form'
					onModalClose={() => onCloseModal(resetForm)}
					shouldSubmitOnEnter={false}
					isLoading={isSubmitting}>
					<Form>
						{!props.initialValues && getUserRole() !== UserRoles.SUPER_ADMIN && (
							<div className='rs-input'>
								<label>{translate('selectHealthSystem')}</label>
								<Select
									name='healthSystem'
									placeholder={intl.formatMessage({ id: 'selectHealthSystem' })}
									options={props.healthSystems}
									value={values.healthSystem}
									onChange={val => onHealthSystemChange(val, setFieldValue)}
								/>
								<p className='red-error'>{errors?.healthSystem}</p>
							</div>
						)}
						{getUserRole() !== UserRoles.SUPER_ADMIN && (
							<div className='rs-input'>
								<label>{translate(props.initialValues ? 'name' : 'selectHospital')}</label>
								<Select
									name='hospital'
									placeholder={intl.formatMessage({ id: 'selectHospital' })}
									options={formHospitals}
									value={values.hospital}
									onChange={val => {
										setFieldValue('teamTypeId', TeamTypes.HOSPITAL);
										setFieldValue('hospital', val);
									}}
									isDisabled={!values.healthSystem || values.healthSystem.value === '0' || props.initialValues}
								/>
								<p className='red-error'>{errors?.hospital}</p>
							</div>
						)}
						<div className='rs-input'>
							<label>{translate('selectGroupRole')}</label>
							<Select
								name='role'
								placeholder={intl.formatMessage({ id: 'selectGroupRole' })}
								options={getUserRolesList(companySettings)}
								value={values.role}
								onChange={val => setFieldValue('role', val)}
								isDisabled={getUserRole() === UserRoles.SUPER_ADMIN}
							/>
							<p className='red-error'>{errors?.role}</p>
						</div>
						<Input
							value={values.group}
							name='group'
							type='text'
							className='rs-input'
							label={intl.formatMessage({ id: 'group' })}
							description={intl.formatMessage({ id: 'typeNameForPool' })}
							onChange={event => setFieldValue('group', event.target.value)}
							error={errors.group}
						/>
						<Alert display={!!error} message={error} variant='error' onClose={() => setError('')} />
					</Form>
				</Modal>
			)}
		</Formik>
	);
};

export default AdGroupRoleModal;
