import EmptyState from 'components/Common/EmptyState.jsx';
import { aiAlertType } from 'constants/alerts.jsx';
import translate from 'i18n-translations/translate.jsx';
import { formattedDate } from 'infrastructure/helpers/dateHelper.js';
import React from 'react';
import { useIntl } from 'react-intl';

const AiAlertsList = ({ aiAlerts, isDarkMode, getAiDetails }) => {
	const intl = useIntl();
	return (
		<>
			{aiAlerts.length > 0 &&
				aiAlerts.map(item => (
					<div key={item.id} className='patient-request-list-item cursor-pointer' onClick={() => getAiDetails(item.id)}>
						<div className='flex'>
							<div className='flex alert-history-inner-wrapper'>
								{aiAlertType(isDarkMode).find(el => el.type === item.aiAlertType.name)?.icon}
								<p className='alert-history-description flex column-direction left-align-items left-s'>
									{intl.formatMessage({ id: item.aiAlertType.name })}
									<br />
									<span className='alert-time no-padding no-margin-left'>{formattedDate(item.dateCreated)}</span>
								</p>
								<i className='material-icons-outlined alert-icon position-absolute'>east</i>
							</div>
						</div>
					</div>
				))}
			{!aiAlerts.length && (
				<div className='flex flex-align-center flex-justify-center empty-state-wrapper'>
					<EmptyState title={translate('noResultsFound')} image='alerts.svg?v2' />
				</div>
			)}
		</>
	);
};

export default AiAlertsList;
