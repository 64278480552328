import { virtualBackgroundWorker } from 'calls/virtual-background/helpers/timerWorker.js';

export const createTimerWorker = () => {
	const callbacks = new Map();

	const worker = new Worker(new URL(virtualBackgroundWorker), { name: 'Virtual Background Worker' });

	worker.onmessage = event => {
		const callback = callbacks.get(event.data.callbackId);
		if (!callback) {
			return;
		}
		callbacks.delete(event.data.callbackId);
		callback();
	};

	let nextCallbackId = 1;

	const setTimeout = (callback, timeoutMs = 0) => {
		const callbackId = nextCallbackId++;
		callbacks.set(callbackId, callback);
		worker.postMessage({ callbackId, timeoutMs });
		return callbackId;
	};

	const clearTimeout = callbackId => {
		if (!callbacks.has(callbackId)) {
			return;
		}
		worker.postMessage({ callbackId });
		callbacks.delete(callbackId);
	};

	const terminate = () => {
		callbacks.clear();
		worker.terminate();
	};

	return { setTimeout, clearTimeout, terminate };
};
