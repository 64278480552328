import { useState, Component } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import {
	capitalizeFirstLetter,
	getBaseUrl,
	checkIfSectorNameExists,
	findSectorById,
} from 'infrastructure/helpers/commonHelpers.js';
import { getUserRole } from 'infrastructure/auth.js';
import { actionCreators as healthSystemsActions } from 'state/healthSystems/actions.js';
import { KeyCodes, SectorTypes, TreeHierarchyType, UserRoles } from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import TimeZoneIcon from 'icons/Organization/TimeZone.jsx';
import { validateSectorName } from 'infrastructure/helpers/validationHelper.js';

const OptionName = ({ option }) => {
	const busyDevices = useSelector(state => state.devices.busyDevices);
	const onlineDevices = useSelector(state => state.devices.onlineDevices);
	const isRoomsOnlyView = useSelector(state => state.healthSystems.isRoomsOnlyView);

	const intl = useIntl();
	const isDeviceBusy = () => {
		if (!option.helloDeviceId) {
			return false;
		}
		return busyDevices.some(id => id === option.helloDeviceId);
	};
	const isDeviceOnline = () => {
		if (!option.helloDeviceId) {
			return false;
		}
		return onlineDevices.some(id => id === option.helloDeviceId);
	};

	const getSubCategoryType = (type, isSingular) => {
		let result;
		switch (type) {
			case SectorTypes.HOSPITAL: {
				result = !isRoomsOnlyView ? SectorTypes.DEPARTMENT : SectorTypes.ROOM;
				break;
			}
			case SectorTypes.DEPARTMENT: {
				result = SectorTypes.FLOOR;
				break;
			}
			case SectorTypes.FLOOR: {
				result = SectorTypes.ROOM;
				break;
			}
			default: {
				result = null;
			}
		}
		result = isSingular ? result : `${result}s`;
		return intl.formatMessage({ id: result });
	};

	const getSavedSubOptionsLength = () => option.subOptions.filter(item => !item.isNewOption).length;

	return (
		<>
			<div className='flex flex-align-center'>
				<span>
					{option.name}
					{[UserRoles.ADMIN, UserRoles.SUPER_USER].includes(getUserRole()) && getSavedSubOptionsLength() > 0 && (
						<span
							style={{ marginLeft: '5px', fontWeight: 500, fontSize: '12px', color: 'var(--gray-5)' }}
							data-tooltip={`${getSavedSubOptionsLength()} ${getSubCategoryType(option.type, getSavedSubOptionsLength() === 1)}`}>
							{`(${getSavedSubOptionsLength()})`}
						</span>
					)}
				</span>

				{option.helloDeviceId && (
					<i className={classNames('material-icons-round', 'room-status', isDeviceOnline() ? 'online' : 'offline')}>
						fiber_manual_record
					</i>
				)}
				{isDeviceBusy() && (
					<i
						className={classNames('material-icons-round', 'room-status', 'on-call')}
						data-tooltip={intl.formatMessage({ id: 'thisDeviceIsOnACall' })}
						data-position='left'>
						fiber_manual_record
					</i>
				)}
			</div>
		</>
	);
};

const OptionsList = ({
	options,
	expandedOptions,
	onChange,
	onAdd,
	onLinkClick,
	onAddDevice,
	isMonitoring,
	isAlertCenter,
	isNurseStation,
	alertFeeds = [],
	videoFeeds = [],
	selectedSectorId,
	setSelectedId,
	expandAll,
	isReassign,
	onRemoveFeed,
}) => {
	const [isValidationShown, setIsValidationShown] = useState(false);
	const [isLoadingCreateSector, setIsLoadingCreateSector] = useState(false);
	const intl = useIntl();
	const monitoredDevices = useSelector(state => state.healthSystems.monitoredDevices);
	const treeData = useSelector(state => state.healthSystems.treeData.tree);
	const findProperId = item => item[`${item.type}Id`];
	const childrenHidden = option => option.subOptions.every(item => item.hidden === true);
	const childrenMatchSearch = option => option.subOptions.some(item => item.matchesSearch === true);
	const allHealthSystems = useSelector(state => state.healthSystems.allHealthSystems);
	const userSession = useSelector(state => state.user.userSession);
	const [sectorExistsMessage, setSectorExistsMessage] = useState(null);
	const treeHierarchyTypeId = allHealthSystems.find(item => item.id === userSession.healthSystem.id)?.treeHierarchyTypeId;
	const privacyDevices = useSelector(state => state.devices.privacyDevices);
	const [newSectorName, setNewSectorName] = useState('');

	const getHierarchyType = () => {
		switch (treeHierarchyTypeId) {
			case TreeHierarchyType.DEFAULT_TREE:
			case TreeHierarchyType.HOSPITAL_DEPT_FLOOR_ROOM:
				return {
					hospital: {
						subtype: 'department',
						icon: 'account_balance',
					},
					department: {
						subtype: 'floor',
						icon: 'money',
					},
					floor: {
						subtype: 'room',
						icon: 'meeting_room',
					},
				};
			case TreeHierarchyType.HOSPITAL_DEPT_ROOM:
				return {
					hospital: {
						subtype: 'department',
						icon: 'account_balance',
					},
					department: {
						subtype: 'room',
						icon: 'meeting_room',
					},
				};
			case TreeHierarchyType.HOSPITAL_FLOOR_ROOM:
				return {
					hospital: {
						subtype: 'floor',
						icon: 'money',
					},
					floor: {
						subtype: 'room',
						icon: 'meeting_room',
					},
				};
			case TreeHierarchyType.HOSPITAL_ROOM:
				return {
					hospital: {
						subtype: 'room',
						icon: 'meeting_room',
					},
				};
			default:
				return {
					hospital: {
						subtype: 'department',
						icon: 'account_balance',
					},
					department: {
						subtype: 'floor',
						icon: 'money',
					},
					floor: {
						subtype: 'room',
						icon: 'meeting_room',
					},
				};
		}
	};

	const toggleOptions = option => {
		if ((childrenHidden(option) && !childrenMatchSearch(option)) || !childrenMatchSearch(option)) {
			option.subOptions.forEach(item => {
				// eslint-disable-next-line no-param-reassign
				item.hidden = !item.hidden;
			});
		} else {
			option.subOptions
				.filter(i => i.matchesSearch)
				.forEach(item => {
					// eslint-disable-next-line no-param-reassign
					item.hidden = !item.hidden;
				});
		}
	};

	const toggleExpand = (selectedOptionId, isAddingSection, option) => {
		if (expandedOptions[selectedOptionId] && !isAddingSection) {
			// eslint-disable-next-line no-param-reassign
			if (option?.subOptions[option.subOptions.length - 1]?.isNewOption) {
				option.subOptions.pop();
			}
			// eslint-disable-next-line no-param-reassign
			delete expandedOptions[selectedOptionId];
		} else {
			// eslint-disable-next-line no-param-reassign
			expandedOptions[selectedOptionId] = {};
		}
		if (expandAll) {
			toggleOptions(option);
		}
		onChange(expandedOptions);
	};

	const addNewOptionClick = selection => {
		const sectors = getHierarchyType();
		if (isMonitoring && videoFeeds.find(item => +item.deviceId === +selection.helloDeviceId)) {
			return;
		}
		if (isAlertCenter && alertFeeds.find(item => +item.deviceId === selection.helloDeviceId)) {
			return;
		}
		if (isNurseStation && alertFeeds.find(item => +item.deviceId === selection.helloDeviceId)) {
			onRemoveFeed(selection.helloDeviceId);
			return;
		}
		if (selection.type === SectorTypes.ROOM && monitoredDevices?.initiatedDevices.includes(selection.helloDeviceId)) {
			onRemoveFeed(selection.helloDeviceId, selection.name);
			return;
		}
		if (selection.type === SectorTypes.ROOM) {
			onAddDevice(selection);
			return;
		}

		selection.subOptions.push({
			...selection,
			icon: sectors[selection.type].icon,
			type: sectors[selection.type].subtype,
			isNewOption: true,
			subOptions: [],
		});
		handleSubOptionsListChange();
		toggleExpand(findProperId(selection), true, selection);
	};

	const addNewOptionOnEnter = async (event, selection) => {
		const newSelection = { ...selection };
		if (
			(event.which === KeyCodes.ENTER && newSectorName?.trim() && !isLoadingCreateSector) ||
			(event.which === KeyCodes.ENTER && newSectorName?.trim() && isReassign)
		) {
			const isValid = checkIfSectorNameExists(newSelection, treeData, newSectorName);
			if (!isValid) {
				setSectorExistsMessage(translate('sectorExistsMessage', { value: intl.formatMessage({ id: newSelection.type }) }));
				return;
			}
			setSectorExistsMessage(null);
			setIsLoadingCreateSector(true);
			newSelection.isNewOption = false;
			newSelection.name = newSectorName;
			newSelection.subOptions = [];
			newSelection.tooltip = `add${capitalizeFirstLetter(selection.type)}`;
			newSelection.isCreatingSector = true;
			handleSubOptionsListChange();
			if (!isMonitoring && !isAlertCenter) {
				setIsValidationShown(false);
			}
			await onAdd(newSelection);
			setIsLoadingCreateSector(false);
			setNewSectorName('');
		}
		if (event.which === KeyCodes.ENTER && !newSectorName?.trim()) {
			setIsValidationShown(true);
		}
	};

	const handleSubOptionsListChange = (optionId, subSelections) => {
		// eslint-disable-next-line no-param-reassign
		expandedOptions[optionId] = subSelections;
		onChange(expandedOptions);
	};

	const selectOption = (event, option) => {
		const newOption = { ...option };
		setSelectedId(newOption[`${option.type}Id`]);
		newOption.isSelected = true;
		if (onLinkClick) {
			onLinkClick(option, event);
		}
		toggleExpand(findProperId(option), !isReassign, option);
	};

	const treeListOnModal = option => {
		return (
			<div
				className='tree-option-wrapper'
				onClick={() => {
					toggleExpand(findProperId(option), false, option);
				}}>
				<i className={classNames('material-icons-outlined', option.type === SectorTypes.ROOM ? 'hide' : '')}>
					{expandedOptions[findProperId(option)] || (expandAll && !childrenHidden(option))
						? 'keyboard_arrow_down'
						: 'keyboard_arrow_right'}
				</i>
				{option.imgIcon && option[`${option.type}Id`] !== selectedSectorId && <img src={option.imgIcon} alt='icon' />}
				{option.imgIcon && option[`${option.type}Id`] === selectedSectorId && <img src={option.activeImgIcon} alt='icon' />}
				{!option.imgIcon && <i className='material-icons-outlined'>{option.icon}</i>}
				{!!option?.link && !isMonitoring && !isAlertCenter && (
					<Link
						to={`${getBaseUrl()}/${option.link}`}
						className='link'
						onClick={event => {
							selectOption(event, option);
						}}>
						<OptionName option={option} />
					</Link>
				)}
				{!(!!option?.link && !isMonitoring && !isAlertCenter) && (
					<span
						className='option link cursor-pointer'
						onClick={event => {
							selectOption(event, option);
						}}>
						<OptionName option={option} />
					</span>
				)}
			</div>
		);
	};
	const treeListOnMenu = option => {
		return (
			<>
				<i
					className={classNames('material-icons-outlined', option.type === SectorTypes.ROOM ? 'hide' : '')}
					onClick={() => toggleExpand(findProperId(option), false, option)}>
					{(!expandAll && expandedOptions[findProperId(option)]) || (expandAll && !childrenHidden(option))
						? 'keyboard_arrow_down'
						: 'keyboard_arrow_right'}
				</i>
				{option.imgIcon && option[`${option.type}Id`] !== selectedSectorId && <img src={option.imgIcon} alt='icon' />}
				{option.imgIcon && option[`${option.type}Id`] === selectedSectorId && <img src={option.activeImgIcon} alt='icon' />}
				{!option.imgIcon && <i className='material-icons-outlined'>{option.icon}</i>}
				{!!option?.link && !isMonitoring && !isAlertCenter && !isReassign && (
					<Link
						to={`${getBaseUrl()}/${option.link}`}
						className='link'
						onClick={event => {
							selectOption(event, option);
						}}>
						<OptionName option={option} />
					</Link>
				)}
				{!(!!option?.link && !isMonitoring && !isAlertCenter && !isReassign) && (
					<span
						className='option link cursor-pointer'
						onClick={event => {
							selectOption(event, option);
						}}>
						<OptionName option={option} />
					</span>
				)}
			</>
		);
	};

	const isPrivacyOn = helloDeviceId => {
		if (!helloDeviceId) {
			return false;
		}
		return privacyDevices.some(id => id === helloDeviceId);
	};

	return (
		<div className='tree-wrapper'>
			{options.map(option => (
				<div
					key={option[`${option.type}Id`]}
					className={classNames('tree', expandAll ? 'search-mode' : '', !option.name && !option.isNewOption ? 'hidden' : '')}>
					<div
						className={classNames(
							'tree__child sector-name',
							option[`${option.type}Id`] === selectedSectorId ? 'selected' : '',
							option.hidden && expandAll ? 'hidden' : ''
						)}>
						{!!option.isNewOption && (
							<>
								<div className='tree__new'>
									<i className='material-icons-outlined'>{option.icon}</i>
									<input
										onChange={event => setNewSectorName(validateSectorName(event.target?.value))}
										onKeyUp={event => addNewOptionOnEnter(event, option)}
										value={newSectorName}
										type='text'
										placeholder={intl.formatMessage({ id: `add${capitalizeFirstLetter(option.type)}` })}
										maxLength={127}
									/>
								</div>
								<span className='red-error'>{sectorExistsMessage}</span>
								{isValidationShown && <span className='red-error'>{translate('sectorIsRequired')}</span>}
							</>
						)}
						{!option.isNewOption && (
							<div>
								{isReassign ? treeListOnModal(option) : treeListOnMenu(option)}
								{isPrivacyOn(option.helloDeviceId) && (
									<div
										className='monitoring-privacy-mode-icon'
										data-tooltip={intl.formatMessage({ id: 'privacyModeIsOn' })}
										data-position='left'>
										<img
											alt='Privacy on'
											className='privacy-mode-tree-view'
											src={`${healthCareCdnUrl}privacy-mode/privacy.svg?v3`}
										/>
									</div>
								)}
								{!isNurseStation &&
									option.tooltip &&
									!option.helloDeviceId &&
									[UserRoles.ADMIN, UserRoles.SUPER_USER].includes(getUserRole()) && (
										<>
											{!option.timezone && SectorTypes.HOSPITAL === option.type && (
												<span
													className='flex right-2'
													data-tooltip={intl.formatMessage({ id: 'timeZoneMustBeSet' })}
													data-position='left'>
													<TimeZoneIcon width={17} height={17} color='#E0AF4C' />
												</span>
											)}
											<span
												className='option action'
												onClick={_.debounce(() => addNewOptionClick(option), 500)}
												data-tooltip={intl.formatMessage({ id: option.tooltip })}
												data-position='left'>
												<i
													className='material-icons-outlined'
													style={{ color: monitoredDevices?.initiatedDevices.includes(option.helloDeviceId) ? '#4cd137' : '' }}>
													add_box
												</i>
											</span>
										</>
									)}
								{isMonitoring &&
									option.tooltip &&
									option.helloDeviceId &&
									UserRoles.ADMIN !== getUserRole() &&
									option.isHelloDevice && (
										<span
											className={classNames('option action', {
												'close-icon': monitoredDevices?.initiatedDevices.includes(option.helloDeviceId),
											})}
											onClick={_.debounce(() => addNewOptionClick(option), 500)}
											data-tooltip={intl.formatMessage({
												id: monitoredDevices?.initiatedDevices.includes(option.helloDeviceId) ? 'removeFeed' : option.tooltip,
											})}
											data-position='left'>
											<i
												className='material-icons-outlined'
												style={{
													color: monitoredDevices?.initiatedDevices.includes(option.helloDeviceId) ? '#4cd137' : '',
												}}>
												{monitoredDevices?.initiatedDevices.includes(option.helloDeviceId) ? '' : 'add_box'}
											</i>
											{/* <i
												className='material-icons-outlined close-feed'
												style={{
													color: monitoredDevices?.initiatedDevices.includes(option.helloDeviceId) ? 'red' : '',
												}}>
												{monitoredDevices?.initiatedDevices.includes(option.helloDeviceId) ? 'close' : 'add_box'}
											</i> */}
										</span>
									)}
								{isAlertCenter &&
									option.tooltip &&
									option.helloDeviceId &&
									UserRoles.ADMIN !== getUserRole() &&
									option.isHelloDevice && (
										<span
											className='option action'
											onClick={_.debounce(() => addNewOptionClick(option), 500)}
											data-tooltip={intl.formatMessage({ id: option.tooltip })}
											data-position='left'>
											<i
												className='material-icons-outlined'
												style={{ color: alertFeeds.find(item => +item.deviceId === option.helloDeviceId) ? '#4cd137' : '' }}>
												{alertFeeds.find(item => +item.deviceId === option.helloDeviceId) ? 'check_circle' : 'add_box'}
											</i>
										</span>
									)}

								{isNurseStation && option.tooltip && option.helloDeviceId && option.isHelloDevice && (
									<span
										onClick={() => addNewOptionClick(option)}
										data-tooltip={intl.formatMessage({
											id: alertFeeds.find(item => +item.deviceId === option.helloDeviceId) ? 'removeFeed' : 'addToNurseStation',
										})}
										data-position='left'
										className='option action'>
										<i
											className='material-icons-outlined'
											style={{ color: alertFeeds.find(item => +item.deviceId === option.helloDeviceId) ? '#4cd137' : '' }}>
											{alertFeeds.find(item => +item.deviceId === option.helloDeviceId) ? 'check_circle' : 'add_box'}
										</i>
									</span>
								)}

								{isReassign && option.type === SectorTypes.ROOM && !option.helloDeviceId && (
									<span
										className='option action'
										onClick={_.debounce(() => addNewOptionClick(option), 500)}
										data-tooltip={intl.formatMessage({ id: 'reAssignDevice' })}
										data-position='left'>
										<i className='material-icons-outlined'>add_box</i>
									</span>
								)}
							</div>
						)}
					</div>
					{option.subOptions && expandAll && (
						<OptionsList
							options={option.subOptions}
							expandedOptions={expandedOptions}
							onChange={subSelections => handleSubOptionsListChange(findProperId(option), subSelections)}
							onAdd={onAdd}
							onLinkClick={onLinkClick}
							onAddDevice={onAddDevice}
							isMonitoring={isMonitoring}
							isAlertCenter={isAlertCenter}
							isNurseStation={isNurseStation}
							alertFeeds={alertFeeds}
							selectedSectorId={selectedSectorId}
							setSelectedId={setSelectedId}
							expandAll={expandAll}
							isReassign={isReassign}
							onRemoveFeed={onRemoveFeed}
						/>
					)}
					{option.subOptions && expandedOptions[findProperId(option)] && !expandAll && (
						<OptionsList
							options={option.subOptions}
							expandedOptions={expandedOptions ? expandedOptions[findProperId(option)] : {}}
							onChange={subSelections => handleSubOptionsListChange(findProperId(option), subSelections)}
							onAdd={onAdd}
							onLinkClick={onLinkClick}
							onAddDevice={onAddDevice}
							isMonitoring={isMonitoring}
							isAlertCenter={isAlertCenter}
							isNurseStation={isNurseStation}
							alertFeeds={alertFeeds}
							selectedSectorId={selectedSectorId}
							setSelectedId={setSelectedId}
							expandAll={expandAll}
							isReassign={isReassign}
							onRemoveFeed={onRemoveFeed}
						/>
					)}
				</div>
			))}
		</div>
	);
};

class TreeView extends Component {
	state = {
		selectedSectorId: this.props.selectedSectorId,
		expandedOptions: this.props.preSelected ? this.props.preSelected : {},
	};

	setSelectedId = sectorId => {
		this.setState({ selectedSectorId: sectorId });
		const foundSector = findSectorById(this.props.data, this.props.selectedSectorId);
		if (this.props.isSmallScreen && foundSector?.type === SectorTypes.ROOM) {
			this.props.healthSystemActions.toggleLeftNavigation();
		}
	};

	componentDidUpdate(prevProps) {
		if (prevProps.selectedSectorId !== this.props.selectedSectorId) {
			this.setSelectedId(this.props.selectedSectorId);
		}
	}

	render() {
		const { selectedSectorId } = this.state;
		return (
			<OptionsList
				onAdd={this.props.onAdd}
				onAddDevice={this.props.onAddDevice}
				onRemoveFeed={this.props.onRemoveFeed}
				onLinkClick={this.props.onLinkClick}
				options={this.props.data}
				onChange={expandedOptions => {
					this.setState({ expandedOptions });
				}}
				expandedOptions={this.state.expandedOptions}
				isMonitoring={this.props.isMonitoring}
				isAlertCenter={this.props.isAlertCenter}
				isNurseStation={this.props.isNurseStation}
				alertFeeds={this.props.alertFeeds}
				selectedSectorId={selectedSectorId}
				setSelectedId={this.setSelectedId}
				expandAll={this.props.expandAll}
				isReassign={this.props.isReassign}
			/>
		);
	}
}

const mapStateToProps = state => ({
	healthSystems: state.healthSystems,
});

const mapDispatchToProps = dispatch => ({
	healthSystemActions: bindActionCreators(healthSystemsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TreeView);
