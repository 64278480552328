import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import { getUpcomingAppointments } from 'api/appointments.js';
import { getUserInfo } from 'infrastructure/auth.js';
import { getPreferredLanguageLocale } from 'infrastructure/helpers/commonHelpers.js';
import { getLatestMeasurements } from 'api/measurements.js';
import SummaryDetail from 'containers/HealthMeasurements/SummaryDetail.jsx';
import { MeasurementTypes, MeasurementUnits } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import Loader from 'components/Common/Loader.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import Alert from 'components/Common/Alert.jsx';
import useOutsideClick from 'infrastructure/helpers/useOutsideClick.js';
import { getMyDoctors } from 'api/doctors.js';
import { actionCreators as doctorActionCreators } from 'state/doctors/actions.js';

const PatientHomePage = () => {
	const [upcomingAppointments, setUpcomingAppointments] = useState([]);
	const [measurements, setMeasurements] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [isMessageShown, setIsMessageShown] = useState(false);
	const vitalSignsMessageWrapper = useRef(null);
	const myDoctors = useSelector(state => state.myDoctors.doctors);
	const dispatch = useDispatch();

	useOutsideClick(vitalSignsMessageWrapper, () => {
		if (isMessageShown) {
			setIsMessageShown(false);
		}
	});

	const measurementTypes = [
		{
			title: translate('heartRate'),
			type: MeasurementTypes.HEART_RATE,
			unit: MeasurementUnits.BPM,
			id: 0,
		},
		{
			title: translate('bloodPressure'),
			type: MeasurementTypes.BLOOD_PRESSURE,
			unit: MeasurementUnits.MMHG,
			id: 1,
		},
		{
			title: translate('perfusionIndex'),
			type: MeasurementTypes.PI,
			unit: MeasurementUnits.PERCENTAGE,
			id: 2,
		},

		{
			title: translate('bodyTemperature'),
			type: MeasurementTypes.TEMPERATURE,
			unit: '',
			id: 3,
		},
		{
			title: translate('oxygenSaturation'),
			type: MeasurementTypes.OXYGEN,
			unit: MeasurementUnits.PERCENTAGE,
			id: 4,
		},

		{
			title: translate('bloodGlucose'),
			type: MeasurementTypes.DIABETES,
			unit: '',
			id: 5,
		},
	];

	const patientHomePageImgUrl = `${healthCareCdnUrl}patient-home/`;
	const { current: userInfo } = useRef(getUserInfo());

	useEffect(() => {
		const fetchMyDoctors = async () => {
			if (myDoctors.length > 0) {
				return;
			}
			let doctors = [];
			const doctorsRes = await getMyDoctors();
			if (!doctorsRes.error) {
				doctors = doctorsRes;
			}
			dispatch(doctorActionCreators.setDoctors(doctors));
			dispatch(doctorActionCreators.setSelectedDoctor(doctors.length > 0 ? doctors[0] : null));
		};

		const getPatientData = async () => {
			const params = {
				userId: userInfo.id,
				pageIndex: 0,
				pageSize: 2,
				error: null,
			};
			const [appointmentRes, measurementRes] = await Promise.all([
				getUpcomingAppointments(params),
				getLatestMeasurements(userInfo.userId),
			]);
			if (appointmentRes.error || measurementRes.error) {
				const responseError = appointmentRes.error || measurementRes.error;
				setError(responseError.message);
			} else {
				setUpcomingAppointments(appointmentRes.upcomingAppointments);
				setMeasurements(measurementRes.measurements);
			}
			setIsLoading(false);
		};
		fetchMyDoctors();
		getPatientData();
	}, [userInfo]);

	const getMeasurementByType = type => {
		const foundMeasurement = measurements.find(measurement => measurement.measurementType === type);
		return foundMeasurement || { measurementValue: 'No Data', type: type };
	};

	const baseMeasurements = measurementTypes.filter(
		measurement =>
			[MeasurementTypes.HEART_RATE, MeasurementTypes.BLOOD_PRESSURE, MeasurementTypes.PI].includes(measurement.type) &&
			measurements.some(item => item.measurementType === measurement.type)
	);
	const secondaryMeasurements = measurementTypes.filter(
		measurement =>
			[MeasurementTypes.OXYGEN, MeasurementTypes.DIABETES, MeasurementTypes.TEMPERATURE].includes(measurement.type) &&
			measurements.some(item => item.measurementType === measurement.type)
	);
	const maxMeasurementsGrid = 3;

	const getSummaryDetail = measurement => (
		<SummaryDetail
			measurement={measurement}
			key={measurement.id}
			currentMeasurement={getMeasurementByType(measurement.type)}
			isDoctorView={false}
			isMinimalVersion={true}
		/>
	);

	return (
		<MainLayout>
			<div className='view-page-wrapper home-page-wrapper'>
				{isLoading && (
					<div className='full-width full-height loader-wrapper flex flex-align-center flex-justify-center'>
						<Loader />
					</div>
				)}
				{!isLoading && (
					<div>
						<div className='home-quick-options-wrapper'>
							<div className='home-title'>
								<h3>{translate('quickOptions')}</h3>
							</div>
							<div className='flex'>
								{getUserInfo().patientExtendedProfile?.healthSystemId && (
									<div>
										<Link to='/select-doctor' />
										<div className='home-icon-wrapper' style={{ backgroundColor: 'rgba(83, 178, 86, 0.1)' }}>
											<img src={`${patientHomePageImgUrl}home-request-doctor.svg`} alt='icon' />
										</div>
										<p>{translate('requestDoctor')}</p>
									</div>
								)}
								<div>
									<Link to='/coming-soon' />
									<div className='home-icon-wrapper' style={{ backgroundColor: 'var(--blue-2-o10)' }}>
										<img src={`${patientHomePageImgUrl}home-dependencies.svg`} alt='icon' />
									</div>
									<p>{translate('addDependents')}</p>
								</div>
								<div
									ref={vitalSignsMessageWrapper}
									className='position-relative cursor-pointer'
									onClick={() => setIsMessageShown(prevState => !prevState)}>
									<div className='flex column-direction flex-justify-center flex-align-center'>
										<div className='home-icon-wrapper' style={{ backgroundColor: 'rgba(241, 94, 94, 0.1)' }}>
											<img src={`${patientHomePageImgUrl}home-heart-rate.svg`} alt='icon' />
										</div>
										<p>{translate('measureYourVitalSigns')}</p>
									</div>
									{isMessageShown && (
										<div className='position-absolute measure-vsm-message flex flex-align-center'>
											<img src={`${healthCareCdnUrl}phone.svg`} alt='icon' />
											<p>{translate('cannotMeasureVS')}</p>
										</div>
									)}
								</div>
								<div>
									<Link
										to={{
											pathname: '/account-settings',
											state: {
												isPatientEditProfilePageVisible: true,
											},
										}}
									/>
									<div className='home-icon-wrapper' style={{ backgroundColor: 'rgba(82, 175, 170, 0.1)' }}>
										<img src={`${patientHomePageImgUrl}home-edit-profile.svg`} alt='icon' />
									</div>
									<p>{translate('updateProfile')}</p>
								</div>
							</div>
						</div>
						<div className='home-upcoming-appointments-wrapper'>
							<div className='flex home-title'>
								<h3>{translate('upcomingAppointments')}</h3>
								<Link to='/appointments'>{translate('goToMyAppointments')}</Link>
							</div>
							<div className='flex'>
								{upcomingAppointments.length === 0 && <p>{translate('noUpcomingAppointment')}</p>}
								{upcomingAppointments.length > 0 &&
									upcomingAppointments.map(item => (
										<div className='flex'>
											<div>
												<img src={`${patientHomePageImgUrl}home-appointments.svg`} alt='icon' />
											</div>
											<div>
												<h4>{item.title}</h4>
												<span>
													{translate('from')}{' '}
													{moment.utc(item.startDateTime).local().locale(getPreferredLanguageLocale()).format('hh:mm A')}{' '}
													{translate('to')}{' '}
													{moment.utc(item.endDateTime).local().locale(getPreferredLanguageLocale()).format('hh:mm A')}
												</span>
												<p>{item.participantFullName}</p>
											</div>
										</div>
									))}
							</div>
						</div>
						<div className='home-vital-signs-wrapper'>
							<div className='flex home-title'>
								<h3>{translate('vitalSigns')}</h3>
								<Link to='/health-data'>{translate('goToMyHealthData')}</Link>
							</div>
							<div className='flex'>
								{baseMeasurements.length === 0 && secondaryMeasurements.length === 0 && (
									<p>{translate('currentlyNoHealthDataMainAnalysis')}</p>
								)}
								{baseMeasurements.length > 0 && baseMeasurements.map(measurement => getSummaryDetail(measurement))}
								{secondaryMeasurements.length > 0 &&
									secondaryMeasurements
										.slice(0, maxMeasurementsGrid - baseMeasurements.length)
										.map(measurement => getSummaryDetail(measurement))}
							</div>
						</div>
					</div>
				)}
			</div>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</MainLayout>
	);
};

export default PatientHomePage;
