import React, { useState, useEffect, useContext } from 'react';
import { ChatOptionsViews } from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { getDateDifferenceLabel, getFormattedDate } from 'infrastructure/helpers/dateHelper.js';
import Modal from 'components/Common/Modal.jsx';
import Form from 'components/Common/Form.jsx';
import { getConversationAttachments, deleteMessage } from 'api/messenger.js';
import translate from 'i18n-translations/translate.jsx';
import EmptyState from 'components/Common/EmptyState.jsx';
import PopUpAlert from 'components/Common/PopUpAlert.jsx';
import SocketEvents from 'constants/socket-events.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import { groupedAttachments, getAttachmentIcon } from 'infrastructure/helpers/commonHelpers.js';
import Download from 'icons/Files/Download.jsx';
import Loader from 'components/Common/Loader.jsx';

const Attachments = props => {
	const [attachments, setAttachments] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [selectedMessageId, setSelectedMessageId] = useState(null);
	const [error, setError] = useState(null);
	const socket = useContext(SocketContext);

	useEffect(() => {
		const fetchAttachments = async () => {
			const response = await getConversationAttachments(props.conversationId, {
				getDocuments: true,
				getMedia: false,
				latest: null,
				limit: 20,
			});
			if (response.error) {
				setIsLoading(false);
				setError(response.error.message);
			} else {
				const newAttachmentsArray = response.attachments.map(attachment => ({
					...attachment,
					dateDifference: getDateDifferenceLabel(attachment.dateCreated),
				}));
				setAttachments(newAttachmentsArray);
				setIsLoading(false);
			}
		};
		fetchAttachments();
	}, [props.conversationId]);

	useEffect(() => {
		const handleNewMessage = data => {
			if (props.conversationId === data.conversationId && data.message?.attachments?.length > 0) {
				data.message?.attachments.forEach(attachment => {
					setAttachments(prevState => [
						{ ...attachment, dateDifference: getDateDifferenceLabel(attachment.dateCreated), sender: data.message.sender },
						...prevState,
					]);
				});
			}
		};
		socket.on(SocketEvents.Conversation.ON_NEW_MESSAGE, handleNewMessage);

		return () => {
			socket.off(SocketEvents.Conversation.ON_NEW_MESSAGE, handleNewMessage);
		};
	}, [props.conversationId, socket]);

	const handleDeleteFile = async () => {
		if (selectedMessageId) {
			const response = await deleteMessage(selectedMessageId, false);
			if (!response.error) {
				setAttachments(prevState => prevState.filter(item => item.messageId !== selectedMessageId));
				setSelectedMessageId(null);
			}
		}
	};

	return (
		<>
			<div className='chat-options-wrapper position-relative'>
				{!isLoading && (
					<div className='flex'>
						<div className='cursor-pointer' onClick={() => props.setDefaultOptions(ChatOptionsViews.DEFAULT)}>
							<img src={`${healthCareCdnUrl}chat/back.svg`} alt='back arrow icon' />
						</div>
						<div>
							<p>{translate('attachments')}</p>
							<p>{translate('sharedAttachments')}</p>
						</div>
					</div>
				)}
				{attachments.length > 0 && !isLoading && (
					<div className='attachments-list'>
						{!props.isFromDoctor &&
							attachments.map(attachment => (
								<div key={attachment.id} className='flex flex-align-center'>
									<img src={`${healthCareCdnUrl}chat/word.svg`} alt='word icon' />
									<div>
										<p>{attachment.originalName}</p>
										<div>
											<a href={attachment.url}>{getFormattedDate(attachment.dateCreated)}</a>
											<i className='material-icons-outlined' onClick={() => setSelectedMessageId(attachment.messageId)}>
												delete
											</i>
											{selectedMessageId}
										</div>
									</div>
								</div>
							))}
						{props.isFromDoctor &&
							Object.keys(groupedAttachments(attachments)).map(key => (
								<React.Fragment key={key}>
									<p className='attachment-time semi-bold'>{translate(key)}</p>
									{groupedAttachments(attachments)[key].map(attachment => (
										<div key={attachment.id} className='flex flex-align-center attachment-details'>
											<img src={getAttachmentIcon(attachment.originalName)} alt='icon' />
											<div>
												<p>{attachment.originalName}</p>
												<a href={attachment.url}>
													<Download />
												</a>
											</div>
										</div>
									))}
								</React.Fragment>
							))}
					</div>
				)}
				{attachments.length === 0 && !isLoading && (
					<div className='empty-state-wrapper-percent'>
						<EmptyState title={translate('noData')} image='no-files.svg' />
					</div>
				)}
				{isLoading && (
					<div className='empty-state-wrapper-percent'>
						<Loader />
					</div>
				)}
			</div>
			{selectedMessageId && (
				<Modal
					modalSelector='deleteAttachmentModal'
					display={true}
					position='center'
					isLoading={false}
					onModalClose={() => setSelectedMessageId(null)}
					onModalSubmit={handleDeleteFile}
					primaryButtonLabel={translate('delete')}
					className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal admin-delete-modal'>
					<Form title={translate('deleteMsgTitle')} height={225}>
						<p className='modal-paragraph'>{translate('deleteMsgWarning')}</p>
						<p className='modal-paragraph'>{translate('actionUndone')}</p>
					</Form>
				</Modal>
			)}
			<PopUpAlert display={error} onAlertClose={() => setError(null)} contentText={error} isSilent={true} center={true} />
		</>
	);
};

export default Attachments;
