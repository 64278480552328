import classNames from 'classnames';
import translate from 'i18n-translations/translate.jsx';
import { formattedDate } from 'infrastructure/helpers/dateHelper.js';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Loader from 'components/Common/Loader.jsx';
import { getAlertsNotifications } from 'api/notifications.js';
import { getHealthConcern } from 'infrastructure/helpers/alertCenterHelper.jsx';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SocketEvents from 'constants/socket-events.js';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min.js';
import { skipDuplicatedObjects } from 'infrastructure/helpers/commonHelpers.js';
import { AiAlertActivityType, AiAlertSign } from 'constants/enums.js';
import { CallWorkflowType } from 'constants/configurationEnums.js';

const AiAlertNotifications = props => {
	const user = useSelector(state => state.user);
	const socket = useContext(SocketContext);
	const history = useHistory();
	const location = useLocation();
	const [alertsNotifications, setAlertsNotifications] = useState([]);
	const [newAlertsNotifications, setNewAlertsNotifications] = useState([]);
	const [alertsNotificationsLoading, setAlertsNotificationsLoading] = useState(false);
	const [notificationsError, setNotificationsError] = useState('');
	const [pageIndex, setPageIndex] = useState(0);
	const [isLast, setIsLast] = useState(false);

	useEffect(() => {
		const fetchAlertsNotifications = async () => {
			setAlertsNotificationsLoading(true);
			const response = await getAlertsNotifications({ pageIndex, levelId: user.userSession.healthSystem.id });
			if (response.error) {
				setNotificationsError(response.error.message);
			} else {
				const uniqueArray = skipDuplicatedObjects([...alertsNotifications, ...response.levelAiAlerts], 'id');
				setAlertsNotifications(uniqueArray);
				if (response.levelAiAlerts.length === 0) {
					setIsLast(true);
				}
			}
			setAlertsNotificationsLoading(false);
		};
		fetchAlertsNotifications();
	}, [pageIndex, user.userSession.healthSystem.id]);

	useEffect(() => {
		const onAlertsNotificationUpdate = data => {
			props.setNewAlertsNotificationsCount(prevState => prevState + 1);
			const newAlert = {
				aiAlertType: { id: data.alertTypeId, name: data.type },
				deviceLocation: data.devieLocation,
				dateCreated: data.creationDate,
				isNewNotification: true,
				isAiAlert: true,
				...data,
			};
			setNewAlertsNotifications([newAlert, ...newAlertsNotifications]);
		};
		socket.on(SocketEvents.Alerts.NEW_AI_ALERT_NOTIFICATION, onAlertsNotificationUpdate);
		socket.on(SocketEvents.Alerts.NEW_AI_ALERT_ACTIVITY, onAiAlertActivity);

		return () => {
			socket.off(SocketEvents.Alerts.NEW_AI_ALERT_NOTIFICATION, onAlertsNotificationUpdate);
			socket.off(SocketEvents.Alerts.NEW_AI_ALERT_ACTIVITY, onAiAlertActivity);
		};
	}, [props, socket]);

	const onAiAlertActivity = data => {
		if (newAlertsNotifications.length === 0) {
			return;
		}
		if (data.deviceId !== newAlertsNotifications.find(item => item.deviceId === data.deviceId)?.deviceId) {
			return;
		}
		if (!data?.workflowTypes.includes(CallWorkflowType.ROUNDING)) {
			return;
		}
		if (
			![AiAlertActivityType.ACKNOWLEDGED, AiAlertActivityType.AI_FAKE_ALERT, AiAlertActivityType.AI_FORWARD_TO_NURSES].includes(
				data.aiAlertActivityTypeId
			)
		) {
			return;
		}
		readNewNotification(data.alertId);
	};

	const readNewNotification = notificationId => {
		const newNotification = newAlertsNotifications.find(item => item.alertId === notificationId);
		if (newNotification) {
			newNotification.isNewNotification = false;
			props.setNewAlertsNotificationsCount(prevState => prevState - 1);
			setNewAlertsNotifications(prevState => prevState.filter(item => item.alertId !== notificationId));
			setAlertsNotifications(prevState => [newNotification, ...prevState]);
		}
	};

	const onAlertClick = notification => {
		const { hospitalId, departmentId, floorId, roomId } = notification.deviceLocation;
		const state = {
			openRoundingTimeline: true,
			helloDeviceId: notification.deviceId,
			...(notification.isNewNotification && {
				notificationData: { type: notification.aiAlertType.id, ...notification, signType: AiAlertSign.WARNING },
			}),
		};
		const roundingAiPath = `/health-system/${hospitalId}/department/${departmentId}/floor/${floorId}/room/${roomId}`;
		if (location.pathname === roundingAiPath) {
			history.replace({
				pathname: roundingAiPath,
				state,
			});
		} else {
			history.push({
				pathname: roundingAiPath,
				state,
			});
		}

		if (notification.isNewNotification) {
			readNewNotification(notification.alertId);
		}
	};

	const getNotificationItems = (notifications, isNew) =>
		notifications.map(notification => (
			<li key={notification?.id + notification?.dateCreated} className={`${isNew ? 'notification-active' : ''}`}>
				<div className='alert-notification-item'>
					<div className='notification-item' onClick={() => onAlertClick(notification)}>
						<div className='flex'>
							<span className='alert-icon'>{getHealthConcern(notification?.aiAlertType?.id, user.darkMode)?.imgIcon}</span>
							<div>
								<h4>{getHealthConcern(notification?.aiAlertType?.id, user.darkMode)?.text}</h4>
								<p>{notification?.deviceLocation?.roomName}</p>
							</div>
						</div>
						<span>{formattedDate(notification?.dateCreated)}</span>
					</div>
				</div>
			</li>
		));

	const UnreadNotifications = () => (
		<>
			{newAlertsNotifications.length > 0 && <p className='notifications-title'>{translate('new')}</p>}
			{getNotificationItems(newAlertsNotifications, true)}
		</>
	);

	const ReadNotifications = () => (
		<>
			{alertsNotifications.length > 0 && <p className='notifications-title'>{translate('earlier')}</p>}
			{getNotificationItems(alertsNotifications, false)}
		</>
	);

	const onScroll = event => {
		const isBottom = Math.abs(event.target.scrollHeight - (event.target.scrollTop + event.target.clientHeight)) <= 5;
		if (!isLast && isBottom && !alertsNotificationsLoading) {
			setPageIndex(pageIndex + 1);
		}
	};

	return (
		<ul
			className={classNames(
				'list-group list-group-notifications overflow-hidden',
				props.newAlertsNotifications?.length > 0 ? ' list-group-unread' : '',
				user.darkMode ? 'dark-list-notifications' : ''
			)}>
			<div className='notifications-list-title'>
				<h3>{translate('notifications')}</h3>
			</div>
			<div className='notifications-list'>
				{notificationsError && <h3>{notificationsError}</h3>}
				<div onScroll={onScroll} className='notifications-list'>
					<UnreadNotifications />
					<ReadNotifications />
					{alertsNotificationsLoading && (
						<div className='loader'>
							<Loader />
						</div>
					)}
				</div>
				{newAlertsNotifications.length === 0 &&
					alertsNotifications.length === 0 &&
					!alertsNotificationsLoading &&
					notificationsError.length === 0 && (
						<li>
							<span className='notification-item'>{translate('noNotifications')}</span>
						</li>
					)}
			</div>
		</ul>
	);
};

export default AiAlertNotifications;
