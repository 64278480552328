import { UserActionTypes } from 'constants/action-types.js';

const initialState = {
	conversationNotifications: [],
	userSession: {
		healthSystem: {
			id: null,
			treeHierarchyTypeId: null,
			name: null,
			isActive: false,
			workflowTypeId: 1,
		},
		regionId: null,
		checkedInChannelId: null,
		isTakingBreak: false,
	},
	userRoles: [],
	presenceStatusTypeId: null,
	waitingRoomCount: 0,
	sessionsQueueCount: 0,
	doctorData: {
		workflowTypeId: null,
	},
	unreadNotificationsCounter: null,
	darkMode: false,
	isAssignedToDoctors: false,
	unreadMissedCallsNotificationsCounter: null,
	unitPreferences: [],
};

export const user = (state = initialState, action = { type: null, payload: null }) => {
	switch (action.type) {
		case UserActionTypes.SET_USER_CONVERSATION_NOTIFICATIONS:
			return {
				...state,
				conversationNotifications: action.payload,
			};
		case UserActionTypes.SET_USER_SESSION:
			return {
				...state,
				userSession: action.payload,
			};
		case UserActionTypes.SET_USER_ROLES:
			return {
				...state,
				userRoles: action.payload,
			};
		case UserActionTypes.SET_USER_PRESENCE_STATUS:
			return {
				...state,
				presenceStatusTypeId: action.payload,
			};
		case UserActionTypes.SET_DOCTOR_DATA:
			return {
				...state,
				doctorData: action.payload,
			};
		case UserActionTypes.SET_USER_WAITING_ROOM_COUNT:
			return {
				...state,
				waitingRoomCount: action.payload,
			};
		case UserActionTypes.SET_SESSIONS_QUEUE_COUNT:
			return {
				...state,
				sessionsQueueCount: action.payload,
			};
		case UserActionTypes.SET_UNREAD_NOTIFICATIONS_COUNTER:
			return {
				...state,
				unreadNotificationsCounter: action.payload,
			};
		case UserActionTypes.SET_UNREAD_NOTIFICATIONS_COUNTER_INCREMENT:
			return {
				...state,
				unreadNotificationsCounter: state.unreadNotificationsCounter + 1,
			};
		case UserActionTypes.SET_USER_DARK_MODE:
			return {
				...state,
				darkMode: action.payload,
			};
		case UserActionTypes.SET_ASSIGNED_TO_DOCTORS:
			return {
				...state,
				isAssignedToDoctors: action.payload,
			};
		case UserActionTypes.SET_UNREAD_MISSED_CALLS_NOTIFICATIONS_COUNTER:
			return {
				...state,
				unreadMissedCallsNotificationsCounter: action.payload,
			};
		case UserActionTypes.SET_UNREAD_MISSED_CALLS_NOTIFICATIONS_COUNTER_INCREMENT:
			return {
				...state,
				unreadMissedCallsNotificationsCounter: state.unreadMissedCallsNotificationsCounter + 1,
			};
		case UserActionTypes.SET_UNIT_PREFERENCES:
			return {
				...state,
				unitPreferences: action.payload,
			};
		default:
			return state;
	}
};
