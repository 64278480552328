import React, { useEffect, useState } from 'react';
import { getPatientAppointments } from 'api/appointments.js';
import CustomTable from 'components/Common/CustomTable.jsx';
import ProfilePicture from 'components/Common/ProfilePicture.jsx';
import translate from 'i18n-translations/translate.jsx';
import { defaultDateFormat } from 'infrastructure/helpers/dateHelper.js';
import moment from 'moment';
import Alert from 'components/Common/Alert.jsx';

const Schedule = ({ selectedPatient, healthSystemId }) => {
	const [appointments, setAppointments] = useState([]);
	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 0, totalCount: 0 });
	const [error, setError] = useState(null);

	useEffect(() => {
		const getAppointments = async () => {
			const response = await getPatientAppointments({
				patientId: selectedPatient.id,
				healthSystemId,
				pageSize: pagination.pageSize,
				pageIndex: pagination.pageIndex,
				from: moment().format('YYYY-MM-DD'),
				to: moment().add(1, 'years').format('YYYY-MM-DD'),
			});

			if (!response.error) {
				setAppointments(prevState => [...prevState, ...response.appointments]);
				setPagination(prevState => ({ ...prevState, totalCount: response.total }));
				return;
			}
			setError(response.error.message);
		};

		getAppointments();
	}, [healthSystemId, pagination.pageIndex]);

	const displaySchedule = () =>
		appointments.map(appointment => ({
			doctor: (
				<div className='flex schedule-item flex-align-center' key={appointment.id}>
					<ProfilePicture
						fullName={appointment?.createdBy.fullName}
						profilePicture={appointment?.createdBy.profilePicture}
						className='doctor-request-img'
					/>
					<div>
						<h5>
							{appointment?.createdBy.firstName} {appointment?.createdBy.lastName}
						</h5>
					</div>
				</div>
			),
			title: appointment.title,
			description: appointment.description,
			attachments: appointment.appointmentAttachments || 'N/A',
			date: defaultDateFormat(appointment?.appointmentSlot?.startDateTime),
		}));

	return (
		<>
			<CustomTable
				headers={[
					{ title: translate('doctor'), id: 'doctor' },
					{ title: translate('title'), id: 'title' },
					{ title: translate('description'), id: 'description' },
					{ title: translate('attachments'), id: 'attachments' },
					{ title: translate('date'), id: 'date' },
				]}
				rows={displaySchedule()}
				className='medications-table'
				isEditable={false}
				setPagination={setPagination}
			/>
			<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
		</>
	);
};

export default Schedule;
