import ProfilePicture from 'components/Common/ProfilePicture.jsx';
import { genderItems } from 'constants/genderItems.js';
import { monthDayYear } from 'infrastructure/helpers/dateHelper.js';
import PatientDetailCell from 'containers/HospitalAtHome/PatientDetailCell.jsx';
import Button from 'components/Common/Button.jsx';
import { Profile } from 'icons/index.js';
import { useSelector } from 'react-redux';
import Diagnosis from 'icons/CareEvents/Diagnosis.jsx';
import Admission from 'icons/Monitoring/Admission.jsx';
import GoBack from 'icons/Monitoring/GoBack.jsx';
import { getCallsButtonColor } from 'infrastructure/helpers/commonHelpers.js';
import classNames from 'classnames';

const PatientDetails = ({
	setPatientDetailsView,
	setSelectedPatient,
	selectedPatient,
	goToOverview,
	talkToPatient,
	isLifeSignalsOpen = false,
}) => {
	const darkMode = useSelector(state => state.user.darkMode);
	return (
		<div className={classNames('patient-details-header', { 'view-details-life-signals': isLifeSignalsOpen })}>
			<div className='flex full-width'>
				<Button
					className='care-logs-btn'
					onClick={() => {
						setPatientDetailsView(false);
						setSelectedPatient(null);
						goToOverview();
					}}
					svgIcon={<GoBack color={getCallsButtonColor(darkMode)} />}
				/>
				<div className='patient-details-wrapper'>
					<ProfilePicture
						fullName={`${selectedPatient.firstName} ${selectedPatient.lastName}`}
						profilePicture={selectedPatient.profilePicture}
						className='doctor-request-img'
					/>
					<span>{`${selectedPatient.firstName} ${selectedPatient.lastName}`}</span>
					<div className='flex patient-info-call'>
						<div className='patient-info-audio' onClick={() => talkToPatient(selectedPatient.solHelloDeviceId ?? null)} />
					</div>
				</div>
				<PatientDetailCell
					title='profileInformation'
					items={[
						{ label: 'mrn', value: selectedPatient.mrn },
						{ label: 'dateOfBirth', value: monthDayYear(selectedPatient.dateOfBirth) },
						{ label: 'sex', value: genderItems.find(item => item.genderId === selectedPatient.genderId?.toString())?.text },
						{ label: 'location', value: selectedPatient.location },
					]}
					icon={<Profile color={getCallsButtonColor(darkMode)} width={20} height={20} />}
				/>
			</div>
			<div className={classNames('flex full-width', { 'top-30': isLifeSignalsOpen })}>
				<PatientDetailCell
					title='admissionDetails'
					items={[
						{ label: 'admitDate', value: monthDayYear(selectedPatient.admitDate) },
						{ label: 'phaseOfCare', value: selectedPatient.phaseCare },
						{ label: 'product', value: selectedPatient.product },
						{ label: 'sponsor', value: selectedPatient.sponsor },
						{ label: 'payer', value: selectedPatient.payer },
					]}
					icon={<Admission color={getCallsButtonColor(darkMode)} />}
				/>
				<PatientDetailCell
					title='diagnosisAndCare'
					items={[{ label: 'diagnosis', value: selectedPatient.diagnosis }]}
					icon={<Diagnosis color={getCallsButtonColor(darkMode)} />}
				/>
			</div>
		</div>
	);
};

export default PatientDetails;
