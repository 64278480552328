import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { APP_CONFIG } from 'constants/global-variables.js';
import { getMeasurementImages, getMeasurementImagesSasToken } from 'api/measurements.js';
import { Alert, EmptyState, Form, Modal } from 'components/index.js';
import { monthDayYear, formattedDate } from 'infrastructure/helpers/dateHelper.js';
import translate from 'i18n-translations/translate.jsx';
import Pagination from 'components/Common/Pagination.jsx';
import { MeasurementTypes, OtoscopeExaminationPoints } from 'constants/enums.js';

const OtoDermatoscopeMeasurements = props => {
	const [isImageOpened, setIsImageOpened] = useState(false);
	const [otoscopeMeasurements, setOtoscopeMeasurements] = useState([]);
	const [selectedImage, setSelectedImage] = useState(null);
	const [sasToken, setSasToken] = useState('');
	const [error, setError] = useState(null);
	const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10, totalCount: 0 });
	const intl = useIntl();

	const getExaminationPoint = measurementType => {
		switch (measurementType) {
			case MeasurementTypes.EAR:
				return OtoscopeExaminationPoints.EAR;
			case MeasurementTypes.THROAT:
				return OtoscopeExaminationPoints.THROAT;
			default:
				return null;
		}
	};

	useEffect(() => {
		const getOtoscopeMeasurement = async () => {
			const sasTokenResponse = await getMeasurementImagesSasToken(props.selectedPatient?.id);
			if (sasTokenResponse.error) {
				setError(sasTokenResponse.error.message);
				setSasToken(null);
				return;
			}
			setSasToken(sasTokenResponse.result?.sasToken);
			const response = await getMeasurementImages({
				patientId: props.selectedPatient?.id,
				pageIndex: pagination.pageIndex,
				pageSize: pagination.pageSize,
				type: props.measurementType === MeasurementTypes.SKIN ? 'dermatoscope' : 'otoscope',
				examPoint: getExaminationPoint(props.measurementType),
			});
			if (response.error) {
				setError(response.error.message);
				return;
			}
			setOtoscopeMeasurements(response.measurementImages);
			setPagination({ pageSize: pagination.pageSize, pageIndex: pagination.pageIndex, totalCount: response.totalCount });
		};
		getOtoscopeMeasurement();
	}, [props.selectedPatient, pagination.pageSize, pagination.pageIndex, props.measurementType]);

	const toggleExpandImageButton = fileName => {
		const foundElement = otoscopeMeasurements.find(item => item.fileName === fileName);
		if (foundElement) {
			setIsImageOpened(prevState => !prevState);
			setSelectedImage(`${APP_CONFIG.blobStorageUrl}measurement-images/${foundElement.fileName}?${sasToken}`);
		}
	};

	return (
		<>
			<div>
				<h4 className='text-align-left left-margin-l semi-bold margin-top-m margin-bottom-0'>
					{translate(props.measurementType === MeasurementTypes.SKIN ? 'dermatoscopeMeasurements' : 'otoscopeMeasurements', {
						value: intl.formatMessage({ id: props.measurementType }),
					})}
				</h4>
				{otoscopeMeasurements.length === 0 && (
					<div className='flex flex-align-center flex-justify-center empty-state-wrapper'>
						<EmptyState title={translate('noResultsFound')} image='health-data.svg' />
					</div>
				)}
			</div>
			<div className='otoscope-history-wrapper'>
				{otoscopeMeasurements.map(item => (
					<div className='otoscope-details' key={item.id}>
						<img
							src={`${APP_CONFIG.blobStorageUrl}measurement-images/${item.fileName}?${sasToken}`}
							alt='ico'
							className='border-top-radius'
						/>
						<div className='flex flex-space-between flex-align-center left-10 margin-right-m'>
							<div className='column-direction'>
								<p className='font-14 no-margin'>{monthDayYear(item.uploadedAt)}</p>
								<p className='font-12'>{`${intl.formatMessage({ id: 'atDate' })}  ${formattedDate(item.uploadedAt)}`}</p>
							</div>
							<div className='flex'>
								<a
									href={`${APP_CONFIG.blobStorageUrl}measurement-images/${item.fileName}?${sasToken}`}
									rel='noopener noreferrer'
									target='_blank'>
									<div id='download-image-icon' className='call-button' data-position='bottom' />
								</a>
								<div
									id='expand-image-icon'
									className={`call-button${isImageOpened ? ' active' : ''}`}
									onClick={() => toggleExpandImageButton(item.fileName)}
									data-position='bottom'
									data-tooltip={intl.formatMessage({
										id: isImageOpened ? 'collapse' : 'expand',
									})}
								/>
							</div>
						</div>
					</div>
				))}
				{isImageOpened && (
					<Modal
						onModalClose={() => setIsImageOpened(false)}
						onModalSubmit={() => setIsImageOpened(false)}
						display={true}
						isViewOnly={true}
						className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal saved-successfully no-padding-appointment-modal otoscope-image-detail'>
						<Form className='no-padding'>
							<img src={selectedImage} alt='ico' className='border-radius-s' />
						</Form>
					</Modal>
				)}
			</div>
			{otoscopeMeasurements.length > 0 && (
				<Pagination
					totalCount={pagination.totalCount}
					pageSize={pagination.pageSize}
					pageIndex={pagination.pageIndex}
					onChange={(pageSize, pageIndex) => setPagination(prevState => ({ ...prevState, pageSize, pageIndex }))}
				/>
			)}
			<Alert display={error} fixed={true} message={error} variant='dark' />
		</>
	);
};

export default OtoDermatoscopeMeasurements;
