import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { DeviceListLevel } from 'constants/enums.js';
import FormInput from 'components/Common/FormInput.jsx';
import { doNotAllowSpaceAsFirstCharacter } from 'infrastructure/helpers/commonHelpers.js';
import translate from 'i18n-translations/translate.jsx';
import { getHealthSystemHospitals } from 'api/userIdleConfigurations.js';

const PatientEducation = ({ itemToEdit, setConfigParams, configurationTypeId, fieldErrors, setError }) => {
	const intl = useIntl();
	const allHealthSystems = useSelector(state => state.healthSystems.allHealthSystems);
	const [selectedHealthSystem, setSelectedHealthSystem] = useState(itemToEdit?.healthSystem ?? { value: '0', label: 'All' });
	const [baseUrl, setBaseUrl] = useState(itemToEdit?.baseUrl ?? '');
	const [partnerId, setPartnerId] = useState(itemToEdit?.partnerId ?? '');
	const [appToken, setAppToken] = useState(itemToEdit?.appToken ?? '');
	const [tokenId, setTokenId] = useState(itemToEdit?.tokenId ?? '');
	const [playerId, setPlayerId] = useState(itemToEdit?.playerId ?? '');
	const [parentCategoryId, setParentCategoryId] = useState(itemToEdit?.parentCategoryId ?? '');

	const transformArray = array => {
		if (!array) {
			return [];
		}
		const newArray = array.map(item => ({ value: item.id, label: item.name || item.value }));
		newArray.unshift({ value: '0', label: intl.formatMessage({ id: 'all' }) });
		return newArray;
	};

	const onHealthSystemChange = async event => {
		const { value, label } = event;
		const hsHospitals = value !== '0' ? await getHealthSystemHospitals(value) : [];
		if (hsHospitals.error) {
			setError(hsHospitals.error.message);
			return;
		}
		setSelectedHealthSystem({ value, label });
	};

	const getNode = () => {
		if (selectedHealthSystem.value !== '0') {
			return {
				id: selectedHealthSystem.value,
				label: selectedHealthSystem.label,
				parentId: null,
				level: DeviceListLevel.HEALTH_SYSTEM,
			};
		}
		return { id: null, label: null, parentId: null, level: null };
	};

	useEffect(() => {
		const node = getNode();
		setConfigParams({
			configurationTypeId,
			nodeName: node.label,
			nodeLevel: node.level,
			nodeId: node.id,
			parentNodeId: node.parentId,
			configJson: JSON.stringify({
				healthSystem: selectedHealthSystem,
				baseUrl,
				appToken,
				partnerId,
				tokenId,
				playerId,
				parentCategoryId,
			}),
		});
	}, [selectedHealthSystem, configurationTypeId, baseUrl, appToken, partnerId, tokenId, playerId, parentCategoryId]);

	return (
		<>
			<div className='input'>
				<p className='label'>{translate('selectHealthSystem')}</p>
				<p className='font-14'>{translate('selectHSForConfiguration')}</p>
				<Select
					value={selectedHealthSystem}
					placeholder={intl.formatMessage({ id: 'selectHealthSystem' })}
					classNamePrefix='react-select'
					options={transformArray(allHealthSystems)}
					onChange={onHealthSystemChange}
				/>
			</div>
			<div className='input'>
				<FormInput
					text={`${intl.formatMessage({ id: 'baseURL' })}*`}
					id='baseUrl'
					placeholder={intl.formatMessage({ id: 'baseURL' })}
					type='text'
					value={baseUrl}
					onChange={event => setBaseUrl(event.target.value)}
					maxLength={256}
					onKeyDown={doNotAllowSpaceAsFirstCharacter}
					error={fieldErrors?.baseUrl}
					touched={fieldErrors?.baseUrl}
				/>
			</div>
			<div className='input'>
				<FormInput
					text={`${intl.formatMessage({ id: 'partnerId' })}*`}
					id='partnerId'
					placeholder={intl.formatMessage({ id: 'partnerId' })}
					type='text'
					value={partnerId}
					onChange={event => setPartnerId(event.target.value)}
					maxLength={256}
					onKeyDown={doNotAllowSpaceAsFirstCharacter}
					error={fieldErrors?.partnerId}
					touched={fieldErrors?.partnerId}
				/>
			</div>
			<div className='input'>
				<FormInput
					text={`${intl.formatMessage({ id: 'appToken' })}*`}
					id='appToken'
					placeholder={intl.formatMessage({ id: 'appToken' })}
					type='text'
					value={appToken}
					onChange={event => setAppToken(event.target.value)}
					maxLength={256}
					onKeyDown={doNotAllowSpaceAsFirstCharacter}
					error={fieldErrors?.appToken}
					touched={fieldErrors?.appToken}
				/>
			</div>
			<div className='input'>
				<FormInput
					text={`${intl.formatMessage({ id: 'tokenId' })}*`}
					id='tokenId'
					placeholder={intl.formatMessage({ id: 'tokenId' })}
					type='text'
					value={tokenId}
					onChange={event => setTokenId(event.target.value)}
					maxLength={256}
					onKeyDown={doNotAllowSpaceAsFirstCharacter}
					error={fieldErrors?.tokenId}
					touched={fieldErrors?.tokenId}
				/>
			</div>
			<div className='input'>
				<FormInput
					text={`${intl.formatMessage({ id: 'playerId' })}*`}
					id='playerId'
					placeholder={intl.formatMessage({ id: 'playerId' })}
					type='text'
					value={playerId}
					onChange={event => setPlayerId(event.target.value)}
					maxLength={256}
					onKeyDown={doNotAllowSpaceAsFirstCharacter}
					error={fieldErrors?.playerId}
					touched={fieldErrors?.playerId}
				/>
			</div>
			<div className='input'>
				<FormInput
					text={`${intl.formatMessage({ id: 'parentCategoryId' })}*`}
					id='parentCategoryId'
					placeholder={intl.formatMessage({ id: 'parentCategoryId' })}
					type='text'
					value={parentCategoryId}
					onChange={event => setParentCategoryId(event.target.value)}
					maxLength={256}
					onKeyDown={doNotAllowSpaceAsFirstCharacter}
					error={fieldErrors?.parentCategoryId}
					touched={fieldErrors?.parentCategoryId}
				/>
			</div>
		</>
	);
};

export default PatientEducation;
