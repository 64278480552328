import { getCareEventHistory } from 'api/careEvents.js';
import Alert from 'components/Common/Alert.jsx';
import Loader from 'components/Common/Loader.jsx';
import Modal from 'components/Common/Modal.jsx';
import { CareEventProp, CareEventStatus } from 'constants/care-events.js';
import translate from 'i18n-translations/translate.jsx';
import React, { useEffect, useRef, useState } from 'react';
import CareEventDetails from 'containers/CareEventsDashboard/CareEventDetails.jsx';

const CareEventHistory = ({ onModalClose, selectedCareEvent }) => {
	const [careEventHistory, setCareEventHistory] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [pageIndex, setPageIndex] = useState(0);
	const hasReachedEnd = useRef(null);
	const [error, setError] = useState(null);

	useEffect(() => {
		const getCareEventsHistory = async () => {
			setIsLoading(true);
			const response = await getCareEventHistory({
				patientId: selectedCareEvent.patientId,
				careEventId: selectedCareEvent.id,
				pageIndex,
			});
			if (response.error) {
				setError(response.error.message);
				setIsLoading(false);
				return;
			}
			if (pageIndex === 0) {
				setCareEventHistory(response.history);
			} else {
				setCareEventHistory(prevState => [...prevState, ...response.history]);
			}
			hasReachedEnd.current = response.history.length < 20;
			setIsLoading(false);
		};
		getCareEventsHistory();
	}, [selectedCareEvent, pageIndex]);

	const handleScroll = event => {
		const isBottom = event.target.scrollHeight - Math.ceil(event.target.scrollTop) === event.target.clientHeight;
		if (isBottom && !hasReachedEnd.current && event.target.scrollTop !== 0) {
			setPageIndex(prevState => prevState + 1);
		}
	};

	const getEventDetails = (item, index) => {
		let status, priority, careMember;

		const getFirstItem = (propName, nestedProp) => {
			if (nestedProp) {
				return {
					value:
						item[propName][nestedProp] !== selectedCareEvent[propName][nestedProp] ? selectedCareEvent[propName] : item[propName],
					isChanged: item[propName][nestedProp] !== selectedCareEvent[propName][nestedProp],
				};
			}
			return {
				value: item[propName] !== selectedCareEvent[propName] ? selectedCareEvent[propName] : item[propName],
				isChanged: item[propName] !== selectedCareEvent[propName],
			};
		};

		const getOtherItem = (propName, nestedProp) => {
			if (nestedProp) {
				return {
					value:
						item[propName][nestedProp] !== careEventHistory[index - 1][propName][nestedProp]
							? careEventHistory[index - 1][propName]
							: item[propName],
					isChanged: item[propName][nestedProp] !== careEventHistory[index - 1][propName][nestedProp],
				};
			}
			return {
				value: item[propName] !== careEventHistory[index - 1][propName] ? careEventHistory[index - 1][propName] : item[propName],
				isChanged: item[propName] !== careEventHistory[index - 1][propName],
			};
		};

		status = index === 0 ? getFirstItem(CareEventProp.STATUS_ID) : getOtherItem(CareEventProp.STATUS_ID);
		priority = index === 0 ? getFirstItem(CareEventProp.PRIORITY_ID) : getOtherItem(CareEventProp.PRIORITY_ID);
		careMember = index === 0 ? getFirstItem(CareEventProp.CARE_MEMBER, 'id') : getOtherItem(CareEventProp.CARE_MEMBER, 'id');

		return { status, priority, careMember };
	};

	return (
		<Modal
			display={true}
			onModalClose={onModalClose}
			position='center'
			className='vsm-alerts-modal border-radius-modal-wrapper rpm-alerts-modal care-event-history'
			onScroll={handleScroll}>
			<h3>{translate('eventHistory')}</h3>

			{isLoading && (
				<div className='flex flex-justify-center flex-align-center'>
					<Loader />
				</div>
			)}
			<div>
				{!isLoading &&
					careEventHistory.map((item, index) => {
						return (
							<CareEventDetails
								user={item.userCreated}
								status={getEventDetails(item, index)?.status}
								dateCreated={item.dateCreated}
								history={careEventHistory}
								index={index}
								careEventId={selectedCareEvent.careEventId}
								item={item}
								selectedCareEvent={selectedCareEvent}
								priority={getEventDetails(item, index)?.priority}
								careMember={getEventDetails(item, index)?.careMember}
							/>
						);
					})}
				{!isLoading && (
					<CareEventDetails
						user={selectedCareEvent.careMember}
						status={{ value: CareEventStatus.NEW }}
						dateCreated={selectedCareEvent.dateCreated}
						isCareEventCreated={true}
						careEventId={selectedCareEvent.careEventId}
						history={careEventHistory}
					/>
				)}
			</div>

			<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
		</Modal>
	);
};

export default CareEventHistory;
