import { getPressureInjurySummary } from 'api/patients.js';
import Loader from 'components/Common/Loader.jsx';
import { PatientPositionSummaryLabels, PressureInjuryStatsColor } from 'constants/enums.js';
import { StyledCircularChartWrapper } from 'css/StyledComponents/StyledChartWrapper.js';
import translate from 'i18n-translations/translate.jsx';
import { groupObjectBySimilarPropertyName, PressureInjuryChartDetails } from 'infrastructure/helpers/aiHelper.jsx';
import { getFontColor } from 'infrastructure/helpers/commonHelpers.js';
import { getDurationFormat } from 'infrastructure/helpers/dateHelper.js';
import { Chart } from 'primereact/chart.js';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

const StyledPatientPositionStats = styled.div`
	padding: var(--spacing-l) 0;

	${props =>
		(props.$isLoading || props.$hasError) &&
		css`
			display: flex;
			justify-content: center;
			padding: var(--spacing-xl);
		`}

	.error-message {
		color: var(--red-4) !important;
	}
`;

const StyledPatientPositionStatsLabels = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: flex-end;
	justify-content: center;
	padding: var(--spacing-s);
	gap: var(--spacing-m);

	& > * + * {
		margin-top: var(--spacing-m);
	}

	.labels-container {
		display: flex;
		align-items: center;
	}

	.positions {
		display: flex;
		flex-direction: column;
		align-items: start;
		width: 100%;
		margin-left: var(--spacing-m);

		> p {
			font-size: 12px;
			font-weight: 400;
			color: ${props => getFontColor(props.$isDarkMode)};
			line-height: 18px;
		}

		span {
			font-size: 14px;
			line-height: 16px;
			font-weight: 600;
			color: ${props => getFontColor(props.$isDarkMode)};
		}
	}

	.duration {
		display: flex;
		align-items: center;

		& > * + * {
			margin-left: 4px !important;
		}

		> p:first-of-type {
			font-size: 14px;
			line-height: 16px;
			font-weight: 400;
			color: ${props => getFontColor(props.$isDarkMode)};
		}

		> p:last-of-type {
			font-size: 14px;
			line-height: 16px;
			font-weight: 600;
			color: ${props => getFontColor(props.$isDarkMode)};
		}
	}
`;

const PositionPill = styled.div`
	background: ${({ $color }) => $color};
	border-radius: var(--spacing-s);
	width: var(--spacing-xl);
	height: 8px;
`;

const AiPatientPositionStats = ({ patientId, isDarkMode }) => {
	const [stats, setStats] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const intl = useIntl();

	const totalPatientPositionStats = useMemo(
		() => getDurationFormat(Object.values(stats || {}).reduce((prev, acc) => prev + acc.value, 0)),
		[stats]
	);
	const graphData = useMemo(
		() => ({
			labels: PressureInjuryChartDetails.map(item => PatientPositionSummaryLabels[item.label]),
			datasets: [
				{
					data: PressureInjuryChartDetails.map(item => (stats ? stats[item.label]?.value : 0)),
					backgroundColor: PressureInjuryChartDetails.map(item => item.color),
					borderColor: 'transparent',
				},
			],
		}),
		[stats]
	);

	const options = {
		legend: {
			display: false,
		},
		maintainAspectRatio: false,
		cutoutPercentage: 80,
		tooltips: {
			callbacks: {
				label: (ttItem, data) => getDurationFormat(data.datasets[0].data[ttItem.index]),
			},
		},
		animation: {
			duration: 0,
		},
	};

	useEffect(() => {
		const getPatientPositionSummary = async () => {
			setError(null);
			setIsLoading(true);
			const response = await getPressureInjurySummary({ patientId });
			if (response.error) {
				setIsLoading(false);
				setError(translate('somethingWentWrong'));
				return;
			}
			const groupedObject = groupObjectBySimilarPropertyName(response);
			setStats(groupedObject);
			setIsLoading(false);
		};
		getPatientPositionSummary();
	}, []);

	return (
		<StyledPatientPositionStats $isLoading={isLoading} $hasError={error}>
			{isLoading && <Loader />}
			{error && <p className='error-message'>{error}</p>}
			{!isLoading && !error && (
				<>
					<StyledCircularChartWrapper $isDarkMode={isDarkMode}>
						<div className='position-relative'>
							<p className='flex column-direction'>
								<span>{intl.formatMessage({ id: 'total' })}</span>
								{totalPatientPositionStats}
							</p>
							<Chart type='RoundedDoughnut' data={graphData} options={options} />
						</div>
					</StyledCircularChartWrapper>
					<StyledPatientPositionStatsLabels $isDarkMode={isDarkMode}>
						{PressureInjuryChartDetails.map(item => (
							<div className='labels-container' key={item.id}>
								<PositionPill $color={PressureInjuryStatsColor[item.label]} />
								<div className='positions'>
									<p>
										{PatientPositionSummaryLabels[item.label]} <span>{`(${stats && stats[item.label]?.percentage}%)`}</span>
									</p>
									<div className='duration'>
										<p>{getDurationFormat(stats && stats[item.label]?.value)}</p>
									</div>
								</div>
							</div>
						))}
					</StyledPatientPositionStatsLabels>
				</>
			)}
		</StyledPatientPositionStats>
	);
};

export default AiPatientPositionStats;
