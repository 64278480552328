import { rpmMeasurements, VitalSignsList } from 'constants/rpm.js';
import translate from 'i18n-translations/translate.jsx';
import Button from 'components/Common/Button.jsx';
import { formattedDate } from 'infrastructure/helpers/dateHelper.js';
import { useCallback, useEffect, useState } from 'react';
import { getLatestMeasurements } from 'api/measurements.js';
import { useIntl } from 'react-intl';
import { Alert, CustomTable } from 'components/index.js';
import { convertMeasurementTypes } from 'infrastructure/helpers/measurementsHelper.js';
import { useSelector } from 'react-redux';

const RecentMetrics = ({ selectedPatient, goToRecentMetric = () => null, isOverview = false }) => {
	const [latestMeasurements, setLatestMeasurements] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const preferredUnits = useSelector(state => state.user.unitPreferences);
	const intl = useIntl();

	useEffect(() => {
		const fetchLatestMeasurements = async () => {
			setIsLoading(true);
			const response = await getLatestMeasurements(selectedPatient?.userId);
			if (response.error) {
				setError(intl.formatMessage({ id: 'somethingWentWrong' }));
			} else {
				const filteredMeasurements = response.measurements.filter(item =>
					VitalSignsList.some(vitalSign => vitalSign.type === item.measurementType)
				);
				setLatestMeasurements(filteredMeasurements);
			}
			setIsLoading(false);
		};

		fetchLatestMeasurements();
	}, [intl, selectedPatient]);

	const getCategoryPreference = useCallback(
		categoryId => preferredUnits.find(item => item.unitCategoryId === categoryId),
		[preferredUnits]
	);

	const getUnitPreference = useCallback(
		categoryId => {
			const selectedPreference = getCategoryPreference(categoryId);
			return selectedPreference?.options.find(item => item.unitSystemId === selectedPreference.unitSystemId);
		},
		[getCategoryPreference]
	);

	const getMeasurementValue = measurement =>
		!measurement.unitCategoryId
			? measurement.measurementValue
			: convertMeasurementTypes(
					measurement.unitCategoryId,
					measurement.measurementValue,
					getUnitPreference(measurement.unitCategoryId)?.unitSystemId
			  );

	const displayRecentMetrics = () =>
		latestMeasurements.map(latest => ({
			measurement: (
				<div className='flex flex-align-center gap-s'>
					<img className='height-20' src={VitalSignsList.find(item => item.type === latest.measurementType)?.icon} alt='ico' />
					<span>{translate(latest.measurementType)}</span>
				</div>
			),
			latestMeasurement: (
				<div className='latest-measurement-column'>
					<img
						src={rpmMeasurements.find(item => item.type === latest.measurementType)?.activeImg}
						alt='ico'
						className='height-20 bottom-5'
					/>
					<p>{getMeasurementValue(latest)}</p>
					<p>{latest.unitCategoryId ? getUnitPreference(latest.unitCategoryId)?.unit : latest.measurementUnit}</p>
				</div>
			),
			dateAndTime: (
				<div>
					<span>{formattedDate(latest.startDate)}</span>
				</div>
			),
		}));

	return (
		<div className={isOverview ? 'overview-table-wrapper' : ''}>
			<div className='flex flex-align-center flex-space-between margin-bottom-m'>
				{isOverview && (
					<>
						<span className='bold-weight'>{translate('recentMetrics')}</span>
						<Button variant='white' className='flex-align-center' onClick={goToRecentMetric} text={translate('showAll')} />
					</>
				)}
			</div>
			<CustomTable
				headers={[
					{ title: translate('measurement'), id: 'measurement' },
					{ title: translate('latestMeasurement'), id: 'latestMeasurement' },
					{ title: translate('dateAndTime'), id: 'dateAndTime' },
				]}
				isLoading={isLoading}
				rows={displayRecentMetrics()}
			/>
			<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
		</div>
	);
};

export default RecentMetrics;
