import moment from 'moment';
import PropTypes from 'prop-types';
import DoctorRequestListItem from 'components/Common/DoctorRequestListItem.jsx';
import ProfilePicture from 'components/Common/ProfilePicture.jsx';
import translate from 'i18n-translations/translate.jsx';
import { getPreferredLanguageLocale, getStatus } from 'infrastructure/helpers/commonHelpers.js';
import { getFormattedDate } from 'infrastructure/helpers/dateHelper.js';

const AppointmentRequestDetails = props => {
	return props.requests.map(request => (
		<div
			key={request.id}
			className={`patient-request-list-item appointment-request cursor-pointer ${
				props.selectedRequest && request.id === props.selectedRequest.id ? 'active' : ''
			}`}
			onClick={() => props.setSelectedRequest(request)}>
			<div>
				<ProfilePicture
					className='patient-request-details-profile'
					firstName={request.participant.firstName}
					lastName={request.participant.lastName}
					profilePicture={request.participant.profilePicture}
				/>
			</div>
			<div className='patient-request-list-item-desc'>
				<p>
					{request.participant.firstName} {request.participant.lastName}
				</p>
				<p className='request-short-desc'>{request.title}</p>
				<p className='appointment-time-slot'>
					{translate('from')}{' '}
					{`${moment.utc(request.appointmentSlot.startDateTime).local().locale(getPreferredLanguageLocale()).format('hh:mm A')} `}
					{translate('to')}{' '}
					{`${moment.utc(request.appointmentSlot.endDateTime).local().locale(getPreferredLanguageLocale()).format('hh:mm A')}`}
				</p>
				<time>{getFormattedDate(request.dateCreated)}</time>
				<DoctorRequestListItem
					className={`${getStatus(request.statusId)} appointment-status`}
					content={translate(getStatus(request.statusId))}
				/>
			</div>
		</div>
	));
};

AppointmentRequestDetails.propTypes = {
	requests: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	selectedRequest: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	setSelectedRequest: PropTypes.func.isRequired,
	status: PropTypes.number.isRequired,
};

export default AppointmentRequestDetails;
