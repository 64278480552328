let authChannel = null;

export const AuthChannelMsg = {
	LOG_OUT: 'logOut',
};

const initializeAuthChannel = () => {
	try {
		if (!authChannel) {
			authChannel = new BroadcastChannel('authChannel');
		}
	} catch (error) {
		console.error('Failed to initialize auth channel:', error);
	}
};

export const postAuthMessage = message => {
	try {
		authChannel.postMessage(message);
	} catch (error) {
		console.error('Failed to post message to auth channel:', error);
	}
};

export const onAuthMessage = handler => {
	try {
		authChannel.onmessage = event => handler(event.data);
	} catch (error) {
		console.error('Failed to set message handler on auth channel:', error);
	}
};

export const closeAuthChannel = () => {
	try {
		authChannel.close();
		authChannel = null;
	} catch (error) {
		console.error('Failed to close auth channel:', error);
	}
};

initializeAuthChannel();
