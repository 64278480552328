import React, { Suspense, useEffect, useState, lazy } from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from 'components/Common/Tabs.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import SelectPatient from 'containers/PrimaryCare/DigitalClinician/SelectPatient.jsx';
const PatientRegistration = lazy(() => import('containers/PrimaryCare/DigitalClinician/PatientRegistration'));
const SelectDoctor = lazy(() => import('containers/PrimaryCare/DigitalClinician/SelectDoctor.jsx'));
import { RoomTypes, VisitStatus, VisitTypes } from 'constants/visitEnums.js';
import { HealthcareErrorCode, SymptomsLength, VisitReasons } from 'constants/enums.js';
const AdditionalHealthData = lazy(() => import('containers/PrimaryCare/DigitalClinician/AdditionalHealthData.jsx'));
import PatientHistory from 'containers/PrimaryCare/DigitalClinician/PatientHistory.jsx';
import { getPatientById } from 'api/patients.js';
import Grid from 'components/Common/Grid.jsx';
import { createMedicalCase, createMedicalVisit, getPatientMedicalCases } from 'api/visits.js';
import { getCompanyId } from 'infrastructure/auth.js';
import { findSectorById, stringToCamelCase } from 'infrastructure/helpers/commonHelpers.js';
import { getSymptoms } from 'api/doctorRequests.js';
import { updatePatientDeviceOwner } from 'api/devices.js';
const PatientVitalSigns = lazy(() => import('containers/CheckInAPatient/PatientVitalSigns.jsx'));

const DigitalClinicianPrimaryCareCheckIn = () => {
	const tabs = { SELECT_PATIENT: 0, PATIENT_HISTORY: 1, ADDITIONAL_DATA: 2, SELECT_DOCTOR: 3 };
	const [currentTab, setCurrentTab] = useState(tabs.SELECT_PATIENT);
	const [symptoms, setSymptoms] = useState([]);
	const [error, setError] = useState('');
	const [selectedSymptoms, setSelectedSymptoms] = useState([]);
	const [selectedDoctor, setSelectedDoctor] = useState(null);
	const [selectedCase, setSelectedCase] = useState(null);
	const [isReferLoading, setIsReferLoading] = useState(null);
	const [referredVisitId, setReferredVisitId] = useState(null);
	const [additionalData, setAdditionalData] = useState({
		medicalQuestionAnswers: [],
		additionalNotes: '',
		attachments: [],
		reasonOfVisit: 1,
		medicalVisitReasonText: '',
		errorMessage: null,
	});
	const [selectedPatient, setSelectedPatient] = useState(null);
	const [specialistType, setSpecialistType] = useState(RoomTypes.DOCTOR.type);
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const imgUrl = `${healthCareCdnUrl}requests/`;
	const [isEditProfile, setIsEditProfile] = useState(false);
	const userSession = useSelector(state => state.user.userSession);
	const tree = useSelector(state => state.healthSystems.treeData.tree);
	const intl = useIntl();
	const [checkInNumber, setCheckInNumber] = useState(0);
	const unitPreferences = useSelector(state => state.user.unitPreferences);

	const foundRoom = findSectorById(tree, userSession.checkedInChannelId);

	const tabList = [
		{
			id: 0,
			title: translate('selectPatient'),
			mainImg: `${imgUrl}1-gray.svg`,
			activeImg: `${imgUrl}1-blue.svg`,
			pastImg: `${imgUrl}1-black.svg`,
		},
		{
			id: 1,
			title: translate(selectedPatient ? 'patientHistory' : 'patientRegistration'),
			mainImg: `${imgUrl}2-gray.svg`,
			activeImg: `${imgUrl}2-blue.svg`,
			pastImg: `${imgUrl}2-black.svg`,
		},
		{
			id: 2,
			title: translate('additionalHealthDataSymptoms'),
			mainImg: `${imgUrl}3-gray.svg`,
			activeImg: `${imgUrl}3-blue.svg`,
			pastImg: `${imgUrl}3-black.svg`,
		},
		...(foundRoom?.roomType === RoomTypes.DIGITAL_CLINIC.type
			? [
					{
						id: 3,
						title: translate('measureVitalSigns'),
						mainImg: `${imgUrl}4-gray.svg`,
						activeImg: `${imgUrl}4-blue.svg`,
						pastImg: `${imgUrl}4-black.svg`,
					},
			  ]
			: []),
		{
			id: 4,
			title: translate('selectDoctor'),
			mainImg: `${imgUrl}${foundRoom?.roomType === RoomTypes.DIGITAL_CLINIC.type ? '5' : '4'}-gray.svg`,
			activeImg: `${imgUrl}${foundRoom?.roomType === RoomTypes.DIGITAL_CLINIC.type ? '5' : '4'}-blue.svg`,
			pastImg: `${imgUrl}${foundRoom?.roomType === RoomTypes.DIGITAL_CLINIC.type ? '5' : '4'}-black.svg`,
		},
	].filter(Boolean);

	const medicalQuestions = [{ id: 1, question: intl.formatMessage({ id: 'howLongSymptoms' }) }];

	useEffect(() => {
		const fetchSymtoms = async () => {
			const symptomsList = await getSymptoms();
			const mappedSymtoms = symptomsList.symptoms.map(item => {
				const translated = intl.formatMessage({ id: stringToCamelCase(item.name) });
				return { name: translated, id: item.id };
			});
			setSymptoms(mappedSymtoms);
		};
		fetchSymtoms();
	}, [intl]);

	const addSymptom = symptom => {
		const checkIfSymptomExists = selectedSymptoms.find(item => symptom.name === item.name);
		if (selectedSymptoms.length >= SymptomsLength.MAX_SYMPTOMS) {
			setError(intl.formatMessage({ id: 'limitedSelectedSymptoms' }));
			return;
		}
		if (!checkIfSymptomExists) {
			selectedSymptoms.push(symptom);
		}
		setSelectedSymptoms(selectedSymptoms);
		setSymptoms(symptoms.filter(item => symptom.name !== item.name));
	};

	const removeSymptom = symptom => {
		setError(null);
		symptoms.push(symptom);
		setSelectedSymptoms(selectedSymptoms.filter(item => symptom.name !== item.name));
		setSymptoms(symptoms);
	};

	const tabChange = async tab => {
		if ([tabs.ADDITIONAL_DATA, tabs.SELECT_DOCTOR].includes(tab) && !selectedPatient) {
			return;
		}
		if (
			tabs.SELECT_DOCTOR === tab &&
			additionalData.reasonOfVisit === VisitReasons.OTHER &&
			!additionalData.medicalVisitReasonText
		) {
			return;
		}
		if (currentTab === tabs.SELECT_DOCTOR) {
			setSelectedCase(null);
		}
		setCurrentTab(tab);
		setError('');
	};

	const addNewPatient = () => {
		setSelectedPatient(null);
		setCurrentTab(1);
	};

	const selectPatient = async patient => {
		const result = await getPatientById(patient.patientId);
		if (result.error) {
			setError(result.error.message);
		} else {
			const patientMedicalCasesRes = await getPatientMedicalCases(patient.id);
			result.medicalCases = patientMedicalCasesRes.medicalCases;
			updateDeviceOwner(result);
		}
	};

	const updateDeviceOwner = async patient => {
		const room = findSectorById(tree, userSession.checkedInChannelId);
		if (room.roomType === RoomTypes.DIGITAL_CLINIC.type && room.helloDeviceId) {
			const deviceParams = {
				userId: patient.userId,
				deviceName: room.name,
				deviceId: room.helloDeviceId,
			};
			const updatePatientDeviceOwnerRes = await updatePatientDeviceOwner(deviceParams);
			if (updatePatientDeviceOwnerRes.error) {
				setError(updatePatientDeviceOwnerRes.error.message);
			} else {
				setSelectedPatient(patient);
				setCurrentTab(1);
			}
		} else {
			setSelectedPatient(patient);
			setCurrentTab(1);
		}
	};

	const selectPatientOnCreateOrEdit = async patient => {
		const result = { ...patient };
		const patientMedicalCasesRes = await getPatientMedicalCases(result.healthcareUserId);
		result.medicalCases = patientMedicalCasesRes.medicalCases;
		setSelectedPatient(result);
		setCurrentTab(tabs.ADDITIONAL_DATA);
	};

	const getVisitTypeBySpecialist = type => {
		let result;
		switch (type) {
			case RoomTypes.DOCTOR.type: {
				result = VisitTypes.DOCTOR;
				break;
			}
			case RoomTypes.RADIOLOGY.type: {
				result = VisitTypes.RADIOLOGY;
				break;
			}
			case RoomTypes.LABORATORY.type: {
				result = VisitTypes.LAB;
				break;
			}
			case RoomTypes.INFUSION_ROOM.type: {
				result = VisitTypes.INFUSION;
				break;
			}
			case RoomTypes.INJECTION_ROOM.type: {
				result = VisitTypes.INJECTION;
				break;
			}
			default: {
				result = 0;
			}
		}
		return result;
	};

	const submitMedicalCase = async specialist => {
		setError('');
		setIsReferLoading(specialist.id);
		let response;
		const roomId = selectedCase ? specialist.teamChannelId : userSession.checkedInChannelId;
		if (selectedCase) {
			const room = findSectorById(tree, roomId);
			const dataToSend = {
				params: {
					performerId: specialist.userId,
					visitType: getVisitTypeBySpecialist(specialistType),
					roomId: specialist.teamChannelId,
					place: room.name,
				},
				patientId: selectedPatient.healthcareUserId,
				caseId: selectedCase.id,
				referredVisitId: referredVisitId,
			};
			response = await createMedicalVisit(dataToSend);
		} else {
			const answerObject = additionalData.medicalQuestionAnswers[0];
			const dataToSend = {
				companyId: getCompanyId(),
				selectedDoctorId: specialist.userId,
				visitType: VisitTypes.FRONT_DESK,
				symptomIds: selectedSymptoms.map(item => item.id),
				medicalVisitInfo: {
					medicalVisitReasonId: additionalData.reasonOfVisit,
					medicalQuestionId: answerObject?.medicalQuestionId ? answerObject.medicalQuestionId : 1,
					answer: answerObject?.answer ? answerObject.answer : '',
					additionalNote: additionalData.additionalNotes,
					...(additionalData.reasonOfVisit === VisitReasons.OTHER && {
						medicalVisitReasonText: additionalData.medicalVisitReasonText,
					}),
				},
			};
			response = await createMedicalCase(selectedPatient.healthcareUserId, dataToSend);
		}
		if (response.error) {
			setError(
				response.error.response.data.code === HealthcareErrorCode.PATIENT_IN_VISIT
					? intl.formatMessage({ id: 'patientIsInVisit' })
					: response.error.message
			);
		} else {
			setCheckInNumber(response.checkInNumber);
			setShowSuccessModal(true);
			setSelectedDoctor(specialist);
			setSelectedSymptoms([]);
			setError('');
		}
		setIsReferLoading(null);
	};

	const setDoctorReferSuccess = () => {
		setCurrentTab(tabs.SELECT_PATIENT);
		setSelectedPatient(null);
		setShowSuccessModal(false);
		setCheckInNumber(0);
		setAdditionalData({ medicalQuestionAnswers: [], additionalNotes: '', attachments: [], reasonOfVisit: 1, errorMessage: null });
		setSelectedCase(null);
	};

	const assignNewDoctor = item => {
		setSelectedCase(item);
		setCurrentTab(tabs.SELECT_DOCTOR);
	};

	const isDoctorInVisit = caseItem => {
		const doctorVisits = caseItem.medicalVisits.filter(visit => visit.typeId === VisitTypes.DOCTOR);
		return doctorVisits.some(docVisit =>
			[VisitStatus.INVITED, VisitStatus.DID_NOT_SHOW_UP, VisitStatus.IN_PROGRESS].includes(docVisit.statusId)
		);
	};

	return (
		<MainLayout>
			<Tabs activeIndex={currentTab} onChange={index => tabChange(index)} className='nurse-checkin-tabs'>
				<TabList className='doctor-wrapper-tabs'>
					{tabList.map(tab => (
						<Tab
							className={classNames(currentTab > tab.id ? 'tab-past' : '', currentTab < tab.id ? 'tab-next' : '')}
							key={tab.id}>
							{currentTab === tab.id && <img src={tab.activeImg} alt='icon' />}
							{currentTab > tab.id && <img src={tab.pastImg} alt='icon' />}
							{currentTab < tab.id && <img src={tab.mainImg} alt='icon' />}
							{tab.title}
						</Tab>
					))}
				</TabList>
				<TabPanels>
					<TabPanel>
						<SelectPatient
							addNewPatient={addNewPatient}
							setSelectedPatient={patient => selectPatient(patient)}
							tabChange={() => tabChange(prevState => prevState + 1)}
							selectedPatient={selectedPatient}
							nextTab={() => setCurrentTab(prevState => prevState + 1)}
						/>
					</TabPanel>
					<TabPanel>
						<Grid columns='1fr' stretch='100%'>
							{selectedPatient && (
								<div className='check-in-patient-wrapper'>
									<div className='full-page-input-wrapper patient-history-wrapper position-relative'>
										{!isEditProfile && (
											<PatientHistory
												selectedPatient={selectedPatient}
												setIsEditProfile={() => setIsEditProfile(prevState => !prevState)}
												goBack={() => setCurrentTab(prevState => prevState - 1)}
												nextTab={() => setCurrentTab(prevState => prevState + 1)}
												assignNewDoctor={assignNewDoctor}
												isDoctorInVisit={isDoctorInVisit}
												setSpecialistType={setSpecialistType}
												setCurrentTab={setCurrentTab}
												setSelectedCase={setSelectedCase}
												setReferredVisitId={setReferredVisitId}
											/>
										)}
										{isEditProfile && (
											<Suspense fallback={<Loader />}>
												<PatientRegistration
													selectedPatient={selectedPatient}
													unitPreferences={unitPreferences}
													setIsEditProfile={() => setIsEditProfile(prevState => !prevState)}
													setSelectedPatient={selectPatientOnCreateOrEdit}
													goBack={() => setCurrentTab(prevState => prevState - 1)}
													nextTab={() => setCurrentTab(prevState => prevState + 1)}
												/>
											</Suspense>
										)}
									</div>
								</div>
							)}
							{!selectedPatient && (
								<div className='check-in-patient-wrapper select-doctor-tabs-wrapper column-direction'>
									<div className='full-page-input-wrapper patient-history-wrapper position-relative'>
										<Suspense fallback={<Loader />}>
											<PatientRegistration
												setSelectedPatient={selectPatientOnCreateOrEdit}
												goBack={() => setCurrentTab(prevState => prevState - 1)}
												nextTab={() => setCurrentTab(prevState => prevState + 1)}
												unitPreferences={unitPreferences}
											/>
										</Suspense>
									</div>
								</div>
							)}
						</Grid>
					</TabPanel>
					<TabPanel>
						<Suspense fallback={<Loader />}>
							<AdditionalHealthData
								symptoms={symptoms}
								selectedSymptoms={selectedSymptoms}
								removeSymptom={symptom => removeSymptom(symptom)}
								addSymptom={symptom => addSymptom(symptom)}
								setIsEditProfile={() => setIsEditProfile(false)}
								error={error}
								setAnswers={answers => setAdditionalData(answers)}
								questions={medicalQuestions}
								additionalData={additionalData}
								isPrimaryCare={true}
								goBack={() => setCurrentTab(prevState => prevState - 1)}
								nextTab={() => {
									if (
										tabs.ADDITIONAL_DATA === currentTab &&
										additionalData.reasonOfVisit === VisitReasons.OTHER &&
										!additionalData.medicalVisitReasonText
									) {
										setAdditionalData({ ...additionalData, errorMessage: translate('enterDetailsOfVisitReason') });
										return;
									}

									setCurrentTab(prevState => prevState + 1);
								}}
							/>{' '}
						</Suspense>
					</TabPanel>
					{foundRoom?.roomType === RoomTypes.DIGITAL_CLINIC.type && (
						<TabPanel>
							{selectedPatient && (
								<Suspense fallback={<Loader></Loader>}>
									<PatientVitalSigns
										goBack={() => setCurrentTab(prevState => prevState - 1)}
										nextTab={() => setCurrentTab(prevState => prevState + 1)}
										selectedPatient={selectedPatient}
										isPrimaryCare={true}
									/>
								</Suspense>
							)}
						</TabPanel>
					)}

					<TabPanel>
						<Suspense fallback={<Loader />}>
							<SelectDoctor
								specialistType={specialistType}
								submitMedicalCase={submitMedicalCase}
								showSuccessModal={showSuccessModal}
								selectedDoctor={selectedDoctor}
								checkInNumber={checkInNumber}
								setShowSuccessModal={setShowSuccessModal}
								setDoctorReferSuccess={setDoctorReferSuccess}
								isReferLoading={isReferLoading}
								error={error}
								setSpecialistType={setSpecialistType}
								setCurrentTab={setCurrentTab}
								setSelectedCase={setSelectedCase}
								setReferredVisitId={setReferredVisitId}
								goBack={() => setCurrentTab(prevState => prevState - 1)}
							/>
						</Suspense>
					</TabPanel>
				</TabPanels>
			</Tabs>
		</MainLayout>
	);
};

export default DigitalClinicianPrimaryCareCheckIn;
