import { lazy, Suspense } from 'react';
import { ControlsActions } from 'calls/enums/index.js';
import { CareEventsActions, PatientMeasurementsActions } from 'calls/constants/index.js';
import {
	CareEventsIcon,
	LiveExaminationIcon,
	PatchIcon,
	PatientHistoryIcon,
	RoomSignIcon,
	WhiteboardIcon,
} from 'calls/icons/index.js';
const AI = lazy(() => import('icons/Monitoring/AI.jsx'));

export const HealthTools = {
	CARE_EVENTS: 1,
	TELEMETRY: 2,
	LIVE_EXAMINATIONS: 3,
	PATIENT_HISTORY: 4,
	WHITEBOARD: 5,
	ROOM_SIGN: 6,
	AI: 7,
};

export const HealthToolButtons = {
	[HealthTools.CARE_EVENTS]: {
		icon: CareEventsIcon,
		label: 'careEvent',
		isActive: configs => configs.medicalDataControls.isCareEventsFormVisible,
		onClick: (configs, handleButtonClick) =>
			handleButtonClick({
				configs,
				actions: CareEventsActions,
				targetAction: ControlsActions.TOGGLE_CARE_EVENTS,
				value: !configs.medicalDataControls.isCareEventsFormVisible,
			}),
	},
	[HealthTools.TELEMETRY]: {
		icon: PatchIcon,
		label: 'telemetry',
		isActive: configs => configs.isTelemetryModalOpen,
		onClick: (configs, handleButtonClick) =>
			handleButtonClick({
				configs,
				actions: PatientMeasurementsActions,
				targetAction: ControlsActions.TOGGLE_TELEMETRY,
				value: !configs.isTelemetryModalOpen,
			}),
	},
	[HealthTools.LIVE_EXAMINATIONS]: {
		icon: LiveExaminationIcon,
		label: 'liveExaminations',
		isActive: configs => configs.isLiveExaminationOpen,
		onClick: (configs, handleButtonClick) =>
			handleButtonClick({
				configs,
				actions: PatientMeasurementsActions,
				targetAction: ControlsActions.TOGGLE_LIVE_EXAMINATIONS,
				value: !configs.isLiveExaminationOpen,
			}),
	},
	[HealthTools.PATIENT_HISTORY]: {
		icon: PatientHistoryIcon,
		label: 'patientHistory',
		isActive: configs => configs.isPatientHistoryOpen,
		onClick: (configs, handleButtonClick) =>
			handleButtonClick({
				configs,
				actions: PatientMeasurementsActions,
				targetAction: ControlsActions.TOGGLE_PATIENT_HISTORY,
				value: !configs.isPatientHistoryOpen,
			}),
	},
	[HealthTools.WHITEBOARD]: {
		icon: WhiteboardIcon,
		label: 'whiteboard',
		isActive: configs => configs.isWhiteboardVisible,
		onClick: (configs, handleButtonClick) =>
			handleButtonClick({
				configs,
				actions: PatientMeasurementsActions,
				targetAction: ControlsActions.TOGGLE_WHITEBOARD,
				value: !configs.isWhiteboardVisible,
			}),
	},
	[HealthTools.ROOM_SIGN]: {
		icon: RoomSignIcon,
		label: 'roomSign',
		isActive: configs => configs.isRoomSignOpen,
		onClick: (configs, handleButtonClick) =>
			handleButtonClick({
				configs,
				actions: PatientMeasurementsActions,
				targetAction: ControlsActions.TOGGLE_ROOM_SIGN,
				value: !configs.isRoomSignOpen,
			}),
	},
	[HealthTools.AI]: {
		icon: AI,
		label: 'ai',
		isActive: configs => configs.isAiOpen,
		onClick: (configs, handleButtonClick) =>
			handleButtonClick({
				configs,
				actions: PatientMeasurementsActions,
				targetAction: ControlsActions.TOGGLE_AI,
				value: !configs.isAiOpen,
			}),
	},
};
