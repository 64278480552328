import { HealthDataTypes } from 'constants/action-types.js';

const initialState = {
	watchMeasurementsVisible: [],
	isFitToScreen: false,
	isSimplifiedPatientForm: false,
};

export const watchMeasurementsList = (state = initialState, action = { type: null, payload: null }) => {
	switch (action.type) {
		case HealthDataTypes.SET_WATCH_MEASUREMENTS_VISIBLE:
			return {
				...state,
				watchMeasurementsVisible: [
					...state.watchMeasurementsVisible.filter(item => item.deviceId !== action.payload.deviceId),
					action.payload,
				],
			};
		case HealthDataTypes.SET_IS_FIT_TO_SCREEN:
			return {
				...state,
				isFitToScreen: action.payload,
			};
		case HealthDataTypes.SET_IS_SIMPLIFIED_PATIENT_FORM:
			return {
				...state,
				isSimplifiedPatientForm: action.payload,
			};
		default:
			return state;
	}
};
