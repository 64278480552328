import { useContext, useEffect, useMemo, useState, lazy } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import _ from 'lodash';
import { actionCreators as healthSystemsActions } from 'state/healthSystems/actions.js';
import SocketEvents from 'constants/socket-events.js';
import { getUserRole } from 'infrastructure/auth.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import { getAge, getSomeRoleConfigurationsValues, isMobileOrTablet } from 'infrastructure/helpers/commonHelpers.js';
import { isAnyAiSettingEnabled, useAiRoomSettings } from 'infrastructure/helpers/aiRoomSettingsHelper.js';
import useScreenType from 'hooks/useScreenType.js';
import { getCompanionDevicesByRoom } from 'api/devices.js';
import { getDeviceOwnerPatient } from 'api/patients.js';
import { getCareEvents } from 'api/teamConfigurationProfiles.js';
import { getActiveEncounter, setEncounterId } from 'api/whiteboard.js';
import { editWhiteboardControl, getWhiteboardControls } from 'api/whiteboardControls.js';
import { CareEventTypes, CompanionModeId, TvSettingTypesId } from 'constants/enums.js';
import { RoundingSettings } from 'constants/configurationEnums.js';
import UserRoles from 'calls/enums/UserRoles.js';
import { StreamSettingsView } from 'calls/views/index.js';
import { RectangleButton, RoundingAi } from 'calls/components/index.js';
import { RoomSignIcon, WhiteboardIcon } from 'calls/icons/index.js';
import { Alert, Button, Grid } from 'components/index.js';
import CallPatient from 'views/Partials/CallPatient.jsx';
import DisplayControl from 'containers/Monitoring/DisplayControl.jsx';
import Whiteboard from 'containers/Monitoring/Whiteboard.jsx';
import RoomSign from 'containers/Rounding/RoomSign/RoomSign.jsx';
const AI = lazy(() => import('icons/Monitoring/AI.jsx'));

const RoundingRoom = ({ helloDeviceId, roomId }) => {
	const intl = useIntl();
	const location = useLocation();
	const screenType = useScreenType();
	const dispatch = useDispatch();
	const socket = useContext(SocketContext);
	const [error, setError] = useState(null);
	const darkMode = useSelector(state => state.user.darkMode);
	const healthSystems = useSelector(state => state.healthSystems);
	const userSession = useSelector(state => state.user.userSession);
	const roleRoundingConfigurations = useSelector(state => state.configurations.roleRoundingConfigurations);
	const [deviceOwner, setDeviceOwner] = useState(null);
	const [isVirtualPatient, setIsVirtualPatient] = useState(false);
	const [callEvents, setCallEvents] = useState([]);
	const [isStreamSettingsOpen, setIsStreamSettingsOpen] = useState(false);
	const [isWhiteboardVisible, setIsWhiteboardVisible] = useState(false);
	const [whiteboardDisplayControls, setWhiteboardDisplayControls] = useState([]);
	const [displayControlLoading, setDisplayControlLoading] = useState(false);
	const [isWhiteboardAssigned, setIsWhiteboardAssigned] = useState(false);
	const [isRoomSignOpen, setIsRoomSignOpen] = useState(false);
	const [isRoundingTimelineOpen, setIsRoundingTimelineOpen] = useState(false);
	const [isRightPanelOpen, setIsRightPanelOpen] = useState(false);
	const aiRoomSettings = useAiRoomSettings(helloDeviceId);
	const isAnyAiSettingRoomEnabled = isAnyAiSettingEnabled(aiRoomSettings);

	useEffect(() => {
		const fetchCareEvents = async () => {
			if (!roleRoundingConfigurations[RoundingSettings.RoundingCareEvents]) {
				return;
			}
			const response = await getCareEvents({
				pageIndex: 0,
				pageSize: 20,
				teamCareEventType: CareEventTypes.Rounding,
				healthSystemId: userSession.healthSystem.id,
			});
			let teamCareEvents = [];
			if (!response.error) {
				teamCareEvents = response?.teamCareEvents?.map(item => ({ label: item.name, value: item.id })) ?? [];
			}
			setCallEvents(teamCareEvents);
		};

		const getEncounterId = async userId => {
			if (!userId) {
				return;
			}
			const response = await getActiveEncounter(userId);
			if (!response.error) {
				setEncounterId(response?.encounter?.id);
			}
		};

		const fetchDeviceOwner = async () => {
			if (![UserRoles.NURSE, UserRoles.DOCTOR, UserRoles.VIRTUAL_SITTER].includes(getUserRole())) {
				return;
			}
			const response = await getDeviceOwnerPatient(helloDeviceId);
			if (response.error) {
				setError(response.error.message);
				setDeviceOwner(null);
				return;
			}
			const details = response
				? {
						patientId: response.healthcareUserId,
						userId: response.userId,
						deviceId: helloDeviceId,
						patientName: response.fullName,
						patientAge: response.dateOfBirth ? getAge(response.dateOfBirth) : '',
				  }
				: null;
			setDeviceOwner(details);
			setIsVirtualPatient(response.isVirtualPatient);
			await getEncounterId(response?.healthcareUserId);

			if (location?.state?.openRoundingTimeline) {
				setIsRoomSignOpen(false);
				setIsWhiteboardVisible(false);
				setIsRoundingTimelineOpen(location?.state?.openRoundingTimeline);
			}
		};

		fetchCareEvents();
		if (helloDeviceId) {
			fetchDeviceOwner();
		} else {
			setDeviceOwner(null);
		}
	}, [helloDeviceId, location?.state?.openRoundingTimeline, roleRoundingConfigurations, userSession.healthSystem.id]);

	useEffect(() => {
		const onWhiteboardDisplayControlUpdated = data => {
			if (roomId !== data?.roomId) {
				return;
			}
			const newClonedData = _.cloneDeep(whiteboardDisplayControls);
			const itemIndex = whiteboardDisplayControls.findIndex(
				item => item?.settingTypeId === TvSettingTypesId[data?.settingType?.toUpperCase()]
			);

			if (itemIndex !== -1) {
				newClonedData[itemIndex] = { ...newClonedData[itemIndex], ...data };
				setWhiteboardDisplayControls(newClonedData);
			}
		};
		socket.on(SocketEvents.HelloDevice.TV_CONTROL_CHANGED, onWhiteboardDisplayControlUpdated);
		return () => {
			socket.off(SocketEvents.HelloDevice.TV_CONTROL_CHANGED, onWhiteboardDisplayControlUpdated);
			setIsWhiteboardVisible(false);
			setIsRoundingTimelineOpen(false);
			setIsRoomSignOpen(false);
		};
	}, [helloDeviceId, socket, roomId, whiteboardDisplayControls]);

	useEffect(() => {
		const fetchWhiteboardControls = async () => {
			const response = await getWhiteboardControls({ roomId });
			if (response?.error) {
				setError(intl.formatMessage({ id: 'somethingWentWrong' }));
				return;
			}
			setWhiteboardDisplayControls(response);
			setIsWhiteboardAssigned(true);
		};

		const clearDisplayControlData = () => {
			setWhiteboardDisplayControls([]);
			setIsWhiteboardAssigned(false);
		};

		const checkCompanionDevice = async () => {
			if (
				!roomId ||
				![UserRoles.NURSE, UserRoles.DOCTOR, UserRoles.VIRTUAL_SITTER].includes(getUserRole()) ||
				!roleRoundingConfigurations[RoundingSettings.DisplayControl]
			) {
				return;
			}

			setDisplayControlLoading(true);
			const response = await getCompanionDevicesByRoom(roomId);
			if (response?.error) {
				setDisplayControlLoading(false);
				setError(intl.formatMessage({ id: 'somethingWentWrong' }));
				return;
			}

			const whiteboardDevice = response?.result?.find(item => item.companionModeId === CompanionModeId.WHITEBOARD);
			if (whiteboardDevice) {
				await fetchWhiteboardControls();
			} else {
				clearDisplayControlData();
			}
			setDisplayControlLoading(false);
		};

		checkCompanionDevice();
	}, [roomId, roleRoundingConfigurations]);

	const toggleDisplayControl = async payload => {
		const response = await editWhiteboardControl({
			roomId,
			payload: {
				tvSettingType: payload.settingType,
				tvSettingValue: payload.settingValue,
				tvIndex: payload.tvIndex,
			},
		});
		if (response?.error) {
			setError(intl.formatMessage({ id: 'somethingWentWrong' }));
			return;
		}
		const newClonedData = _.cloneDeep(whiteboardDisplayControls);
		const itemIndex = whiteboardDisplayControls.findIndex(item => item?.settingTypeId === payload?.settingTypeId);

		if (itemIndex !== -1) {
			newClonedData[itemIndex] = { ...newClonedData[itemIndex], ...payload };
			setWhiteboardDisplayControls(newClonedData);
		}
	};

	const displayControlData = useMemo(() => {
		return whiteboardDisplayControls.find(item => item?.settingTypeId === TvSettingTypesId.DISPLAY);
	}, [whiteboardDisplayControls, roomId]);

	const isDeviceOwnerAndNotVirtual = deviceOwner && !isVirtualPatient;

	const showWhiteboard =
		[UserRoles.NURSE, UserRoles.DOCTOR, UserRoles.VIRTUAL_SITTER].includes(getUserRole()) &&
		getSomeRoleConfigurationsValues(roleRoundingConfigurations, [RoundingSettings.Whiteboard, RoundingSettings.DisplayControl]) &&
		isDeviceOwnerAndNotVirtual;

	const showRoomSign =
		[UserRoles.NURSE, UserRoles.DOCTOR].includes(getUserRole()) &&
		roleRoundingConfigurations[RoundingSettings.RoomSign] &&
		isDeviceOwnerAndNotVirtual;

	const showAI = getUserRole() === UserRoles.NURSE && isAnyAiSettingRoomEnabled && deviceOwner?.deviceId;

	const renderBottomToolbar = showWhiteboard || showRoomSign || showAI;

	const isShowingWhiteboardSection = isWhiteboardVisible && whiteboardDisplayControls;

	const rightSectionOpen = () => isStreamSettingsOpen || isWhiteboardVisible || isRoomSignOpen || isRoundingTimelineOpen;

	const toggleRightPanel = () => dispatch(healthSystemsActions.toggleRightPanel());

	const toggleToolbarButton = button => {
		setIsWhiteboardVisible(button === 'whiteboard' ? prevState => !prevState : false);
		setIsRoundingTimelineOpen(button === 'roundingTimeline' ? prevState => !prevState : false);
		setIsRoomSignOpen(button === 'roomSign' ? prevState => !prevState : false);

		if (isStreamSettingsOpen) {
			setIsStreamSettingsOpen(false);
		}
	};

	const handleRightPanelToggle = () => {
		const shouldToggleRightPanel = rightSectionOpen();
		if (shouldToggleRightPanel !== isRightPanelOpen) {
			toggleRightPanel();
			setIsRightPanelOpen(shouldToggleRightPanel);
		}
	};

	useEffect(() => {
		handleRightPanelToggle();
	}, [isWhiteboardVisible, isRoomSignOpen, isRoundingTimelineOpen]);

	const bottomToolbarButtons = [
		{
			showButton: showWhiteboard,
			isActive: isWhiteboardVisible,
			onClick: () => toggleToolbarButton('whiteboard'),
			icon: WhiteboardIcon,
			label: intl.formatMessage({ id: 'whiteboard' }),
		},
		{
			showButton: showRoomSign,
			isActive: isRoomSignOpen,
			onClick: () => toggleToolbarButton('roomSign'),
			icon: RoomSignIcon,
			label: intl.formatMessage({ id: 'roomSign' }),
		},
		{
			showButton: showAI,
			isActive: isRoundingTimelineOpen,
			onClick: () => toggleToolbarButton('roundingTimeline'),
			icon: AI,
			label: intl.formatMessage({ id: 'ai' }),
		},
	];

	const isMobileOrTabletLayout = () => isMobileOrTablet() || screenType.isSmall;

	return (
		<Grid
			columns={!isMobileOrTabletLayout() && rightSectionOpen() ? '2fr 1fr' : '1fr auto'}
			rows='1fr'
			stretch='100%'
			width='100%'
			horizAlign='center'
			vertAlign='center'>
			{((isMobileOrTabletLayout() && !healthSystems.isRightPanelOpen) || !isMobileOrTabletLayout()) && (
				<div>
					<CallPatient callEvents={callEvents} helloDeviceId={helloDeviceId} roomId={roomId} />
					{(roleRoundingConfigurations[RoundingSettings.ViewPatient] ||
						roleRoundingConfigurations[RoundingSettings.TalkToPatient]) && (
						<Button
							className={classNames('stream-settings-button ', { active: isStreamSettingsOpen })}
							onClick={() => setIsStreamSettingsOpen(prevState => !prevState)}
							icon='settings'
							text={intl.formatMessage({ id: 'streamSettingsModalTitle' })}
						/>
					)}
				</div>
			)}
			{(isShowingWhiteboardSection || isRoomSignOpen || isRoundingTimelineOpen) && (
				<div className={classNames('new-experience', { 'dark-mode': darkMode }, { hidden: isStreamSettingsOpen })}>
					{(isShowingWhiteboardSection || isRoomSignOpen) && (
						<aside className='right-side-monitoring rounding-right-aside'>
							<div className={classNames('monitoring-timeline', { 'rounding-whiteboard-wrapper': isShowingWhiteboardSection })}>
								{isShowingWhiteboardSection && roleRoundingConfigurations[RoundingSettings.DisplayControl] && (
									<DisplayControl
										data={displayControlData}
										toggleDisplayControl={toggleDisplayControl}
										isDeviceAssigned={isWhiteboardAssigned}
										isLoading={displayControlLoading}
									/>
								)}
								{isShowingWhiteboardSection && roleRoundingConfigurations[RoundingSettings.Whiteboard] && (
									<Whiteboard
										deviceOwnerId={deviceOwner?.patientId}
										deviceId={deviceOwner?.deviceId}
										patientName={deviceOwner?.patientName}
										patientAge={deviceOwner?.patientAge}
										initExpanded={true}
									/>
								)}
								{isRoomSignOpen && (
									<RoomSign
										deviceOwnerId={deviceOwner?.patientId}
										userId={deviceOwner?.userId}
										deviceId={deviceOwner?.deviceId}
									/>
								)}
							</div>
						</aside>
					)}

					{isRoundingTimelineOpen && (
						<RoundingAi
							helloDeviceId={helloDeviceId}
							isAiOpen={isAnyAiSettingRoomEnabled}
							isDarkMode={darkMode}
							notificationAiAlert={location?.state?.notificationData}
							deviceOwner={deviceOwner}
						/>
					)}
				</div>
			)}

			{isStreamSettingsOpen && (
				<StreamSettingsView
					onDismiss={() => setIsStreamSettingsOpen(false)}
					isOutsideOfCall={!isMobileOrTabletLayout() && true}
					position={!isMobileOrTabletLayout() && 'right'}
				/>
			)}

			{renderBottomToolbar && (
				<div className='rounding-bottom-toolbar'>
					{bottomToolbarButtons.map(
						(button, index) =>
							button.showButton && (
								<RectangleButton
									key={index}
									darkMode={darkMode}
									isActive={button.isActive}
									onClick={button.onClick}
									icon={button.icon}
									label={button.label}
									outsideCall={true}
								/>
							)
					)}
				</div>
			)}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</Grid>
	);
};

export default RoundingRoom;
