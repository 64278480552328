import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import ProfilePicture from 'components/Common/ProfilePicture.jsx';
import { RpmDevices, rpmMeasurements } from 'constants/rpm.js';
import translate from 'i18n-translations/translate.jsx';
import { getScheduledSurveyType, getSurveyType } from 'infrastructure/helpers/rpmHelper.js';
import { getRpmProgramDetails } from 'api/rpm.js';
import Alert from 'components/Common/Alert.jsx';
import classNames from 'classnames';
import { healthCareCdnUrl } from 'constants/global-variables.js';

const RpmProgramDetails = ({ selectedRpmProgram, isFromEditPatientRpm = false }) => {
	const intl = useIntl();
	const [careTeam, setCareTeam] = useState([]);
	const [devices, setDevices] = useState([]);
	const [surveyTypes, setSurveyTypes] = useState([]);
	const [error, setError] = useState(null);
	const [hasPatch, setHasPatch] = useState(false);

	useEffect(() => {
		const getDetails = async () => {
			if (!selectedRpmProgram) {
				return;
			}
			const response = await getRpmProgramDetails(selectedRpmProgram?.value);
			const devicesArray = [];
			let patchCount = 0;

			if (response.error) {
				setError(response.error.message);
				return;
			}

			response.rpmProgram.devices.forEach(device => {
				if (device.deviceId !== RpmDevices.LIFE_SIGNALS_PATCH) {
					devicesArray.push(device);
				} else {
					patchCount += 1;
				}
			});

			setHasPatch(patchCount > 0);

			const uniqueArray = response.rpmProgram.questions.reduce((accumulator, item) => {
				if (
					!accumulator.find(survey => survey.surveyType === item.surveyType) ||
					!accumulator.find(survey => survey.surveyScheduleType === item.surveyScheduleType)
				) {
					accumulator.push(item);
				}
				return accumulator;
			}, []);

			setCareTeam(response.rpmProgram.careTeam);
			setDevices(devicesArray);
			setSurveyTypes(uniqueArray);
		};
		getDetails();
	}, [selectedRpmProgram]);

	return (
		<>
			<div className={classNames('rpm-details-wrapper', !isFromEditPatientRpm ? 'top-20' : '')}>
				{isFromEditPatientRpm && (
					<div className='bottom-30'>
						<p className='semi-bold'>{translate('careTeam')}</p>
						<div className='available-doctor-items available-members-items care-team-members-list'>
							{careTeam.map(item => (
								<div className='care-team-info'>
									<ProfilePicture
										className='doctor-request-img available-doctor-request-img'
										firstName={item.firstName}
										lastName={item.lastName}
										profilePicture={item.profilePicture}
									/>
									<div className='flex column-direction flex-justify-center'>
										<p className='team-member-name'>
											{item.firstName} {item.lastName}
										</p>
										<span className='additional-info'>{item.email}</span>
										{item.specialty?.name && <span className='additional-info'>{item.specialty.name}</span>}
									</div>
								</div>
							))}
						</div>
					</div>
				)}
				<div className='bottom-30'>
					<p className='semi-bold rpm-details text-align-left'>{translate('devices')}</p>
					<div className='devices-list'>
						<>
							{devices.map(device => {
								const foundMeasurement = rpmMeasurements.find(item => item.type === device.measurementType);
								const iHealthDeviceIncluded = Object.values(RpmDevices).includes(device.deviceId);

								if (iHealthDeviceIncluded) {
									return (
										<div className='flex' key={foundMeasurement.type}>
											<img src={foundMeasurement.activeImg} className='active' alt='ico' />
											<p className='no-margin no-padding font-12 relative text-align-left rpm-details'>
												{translate(`${foundMeasurement.name}`)}
											</p>
										</div>
									);
								}
								return null;
							})}
							{hasPatch && (
								<div className='flex'>
									<img src={`${healthCareCdnUrl}rpm/lifesignals-patch.svg`} className='active' alt='ico' />
									<p className='no-margin no-padding font-12 relative text-align-left rpm-details'>
										{translate('lifesignalsPatch')}
									</p>
								</div>
							)}
						</>
					</div>
				</div>
				<div className='bottom-30'>
					<p className='semi-bold rpm-details text-align-left'>{translate('surveys')}</p>
					<div className='flex column-direction survey-list'>
						{surveyTypes.length > 0 &&
							surveyTypes.map(survey => (
								<p className='rpm-details text-align-left'>
									{survey.surveyScheduleType ? intl.formatMessage({ id: getScheduledSurveyType(survey.surveyScheduleType) }) : ''}{' '}
									{translate('typeOfSurvey', {
										value: intl.formatMessage({
											id: getSurveyType(survey.surveyType),
										}),
									})}
								</p>
							))}
						{surveyTypes.length === 0 && <p className='rpm-details'>{translate('noResultsFound')}</p>}
					</div>
				</div>
			</div>
			<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
		</>
	);
};

export default RpmProgramDetails;
