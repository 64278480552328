import React from 'react';
import translate from 'i18n-translations/translate.jsx';
import { getFormattedDate } from 'infrastructure/helpers/dateHelper.js';
import { decodeHtml } from 'infrastructure/helpers/commonHelpers.js';
import { PresenceStatusType } from 'constants/enums.js';
import ProfilePicture from 'components/Common/ProfilePicture.jsx';
import Loader from 'components/Common/Loader.jsx';

const NurseChatList = ({ selectedNurse, isLoading, setNurse, nurses }) => (
	<>
		{isLoading && <Loader />}
		{nurses.length > 0 && !isLoading && (
			<div>
				{nurses.map(item => (
					<div key={item.id} onClick={() => setNurse(item)}>
						<div
							className={
								selectedNurse && selectedNurse.userId === item.userId
									? 'chat-list-item flex position-relative active'
									: 'chat-list-item flex position-relative'
							}>
							<div className='align-self-start position-relative'>
								<div className='flex position-relative chat-list-img-status-wrapper '>
									<ProfilePicture
										className='chat-list-img'
										fullName={`${item.firstName} ${item.lastName}`}
										profilePicture={item.profilePicture}
									/>
									<div className='chat-list-status flex'>
										<span className={item.presenceStatusTypeId === PresenceStatusType.AVAILABLE ? 'is-on' : 'is-off'} />
									</div>
								</div>
								{item.lastActivity?.unReadMessageCount > 0 && (
									<div className='conversation-label-blue'>{item.lastActivity.unReadMessageCount}</div>
								)}
							</div>
							<div className='chat-list-name header-search-name flex flex-1'>
								<p title={decodeHtml(`${item.firstName} ${item.lastName}`)}>{decodeHtml(`${item.firstName} ${item.lastName}`)}</p>
								{item.lastActivity && (
									<>
										<span className='chat-list-recent-message'>{item.lastActivity.message}</span>
										<span className='chat-list-time'>{getFormattedDate(item.lastActivity.dateCreated)}</span>
									</>
								)}
								{!item.lastActivity && (
									<>
										<span className='chat-list-time'>{getFormattedDate(item.requestAcceptedTime)}</span>
										<span className='chat-list-recent-message'>
											{translate('youHaveAcceptedRequest', { value: `${item.firstName} ${item.lastName}` })}
										</span>
									</>
								)}
							</div>
						</div>
					</div>
				))}
			</div>
		)}
		{nurses.length === 0 && !isLoading && (
			<div className='chat-item-title'>
				<span className='semi-bold'>{translate('noResultsFound')}</span>
			</div>
		)}
	</>
);

export default NurseChatList;
