import React, { useEffect, useRef, useState } from 'react';
import { getDoctorAssigned } from 'api/doctors.js';
import { transferQueue } from 'api/queueManagement.js';
import { PresenceStatusType } from 'constants/enums.js';
import Alert from 'components/Common/Alert.jsx';
import Grid from 'components/Common/Grid.jsx';
import SkeletonLoader from 'components/Common/SkeletonLoader.jsx';
import Button from 'components/Common/Button.jsx';
import ProfilePicture from 'components/Common/ProfilePicture.jsx';
import translate from 'i18n-translations/translate.jsx';

const QueuedDoctorsTable = ({
	selectedHospitalId,
	selectedPatientsIds,
	onBack,
	onCloseModal,
	onPatientTransferComplete,
	selectedQueueDoctorId,
}) => {
	const [doctors, setDoctors] = useState([]);
	const [error, setError] = useState(null);
	const [pageIndex, setPageIndex] = useState(0);
	const hasReachedEnd = useRef(null);
	const [isLoading, setIsLoading] = useState(true);

	const handleScroll = event => {
		const isBottom = event.target.scrollHeight - Math.ceil(event.target.scrollTop) === event.target.clientHeight;
		if (isBottom && !hasReachedEnd.current) {
			setPageIndex(prevState => prevState + 1);
		}
	};

	useEffect(() => {
		const fetchDoctors = async () => {
			const response = await getDoctorAssigned(selectedHospitalId, { pageIndex, presenceStatus: PresenceStatusType.AVAILABLE });
			if (response.error) {
				setError(response.error.message);
				setIsLoading(false);
				return;
			}
			const availableDoctors = response.doctors.filter(doctor => doctor.id !== selectedQueueDoctorId);
			if (pageIndex === 0) {
				setDoctors(availableDoctors);
			} else {
				setDoctors(prevState => [...prevState, ...availableDoctors]);
			}
			setIsLoading(false);
		};
		fetchDoctors();
	}, [selectedHospitalId, pageIndex, selectedQueueDoctorId]);

	const handleTransferQueue = async toDoctorId => {
		const response = await transferQueue(selectedQueueDoctorId, toDoctorId, selectedPatientsIds);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		onCloseModal();
		onPatientTransferComplete();
	};

	return (
		<>
			<div className='inner-queue-modal queued-patients-list' onScroll={handleScroll}>
				<Button className='patient-transfer-back-btn' onClick={onBack} text={translate('back')} icon='arrow_back' />
				{doctors.length === 0 && !isLoading && (
					<div className='text-align-center'>
						<p className='no-data'>{translate('noAvailableDoctor')}</p>
					</div>
				)}
				{doctors.map(doctor => (
					<div
						className='home-waiting-room-inner available-doctor-queue'
						key={doctor.id}
						onClick={() => handleTransferQueue(doctor.id)}>
						<div className='flex'>
							<ProfilePicture
								className='doctor-request-img patient-request-profile-img'
								firstName={doctor.firstName}
								lastName={doctor.lastName}
								profilePicture={doctor.profilePicture}
							/>
							<div>
								<h4>
									{doctor.firstName} {doctor.lastName}
								</h4>
							</div>
							<div className='available-doctor-queue-status'>
								<span>
									<i className='material-icons --green-color'>fiber_manual_record</i>
									{translate('available')}
								</span>
							</div>
						</div>
					</div>
				))}
				{isLoading && (
					<Grid width='100%' horizAlign='center'>
						<SkeletonLoader rows={10} padding='35px 20px' />
					</Grid>
				)}
			</div>
			{error && <Alert display={error} message={error} variant='dark' fixed={true} onClose={() => setError(null)} />}
		</>
	);
};

export default QueuedDoctorsTable;
