import React, { useState, useEffect, useContext, useRef } from 'react';
import queryString from 'query-string';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import ProfilePicture from 'components/Common/ProfilePicture.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SocketEvents from 'constants/socket-events.js';
import { getUserInfo, setUserInfo, getUserId } from 'infrastructure/auth.js';
import {
	RequestStatus,
	MeasurementTypes,
	VisitReasons,
	RequestType,
	AssignedRequestTypes,
	HealthcareErrorCode,
} from 'constants/enums.js';
import Pagination from 'components/Common/Pagination.jsx';
import { getProviderAssignedRequests, getLevelAssignedRequests, updateLevelRequestStatus } from 'api/doctorRequests.js';
import { updateAssignRequest } from 'api/patientRequests.js';
import translate from 'i18n-translations/translate.jsx';
import { convertMeasurementTypes } from 'infrastructure/helpers/measurementsHelper.js';
import { formattedDate, getMonthDayYearDateFormat, getLocalTimeHHmmss } from 'infrastructure/helpers/dateHelper.js';
import {
	stringToCamelCase,
	convertSecondsToHoursFormat,
	getAge,
	getGender,
	getRequestType,
	getRequestColor,
	getConfigurationValue,
} from 'infrastructure/helpers/commonHelpers.js';
import { measurementAlert } from 'constants/alerts.jsx';
import { StartQueryStringKeys } from 'calls/enums/index.js';
import { actionCreators as userActionCreators } from 'state/user/actions.js';
import { HelloIcon } from 'calls/icons/index.js';
import { TelemedicineModesSettings } from 'constants/configurationEnums.js';
import { Button, Grid, Loader, Alert, EmptyState } from 'components/index.js';

const WaitingRoom = () => {
	const socket = useContext(SocketContext);
	const dispatch = useDispatch();
	const intl = useIntl();
	const intervals = useRef([]);
	const companyConfigurations = useSelector(state => state.company.companySettings?.companyConfigurations);
	const userSession = useSelector(state => state.user.userSession);
	const waitingRoomCount = useSelector(state => state.user.waitingRoomCount);
	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 0, totalCount: 0 });
	const [errorApiResponse, setErrorApiResponse] = useState('');
	const [requests, setRequests] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [requestsTime, setRequestsTime] = useState({});
	const [isJoinCallLoading, setIsJoinCallLoading] = useState(null);
	const [rejectCallLoading, setRejectCallLoading] = useState(null);
	const userUnits = useSelector(state => state.user.unitPreferences);

	const setUserWaitingRoomCount = count => dispatch(userActionCreators.setUserWaitingRoomCount(count));
	const medicalQuestions = [{ id: 1, question: intl.formatMessage({ id: 'howLongSymptoms' }) }];
	const isLevelRequest = getConfigurationValue(companyConfigurations[TelemedicineModesSettings.SHARED_QUEUE]);
	const visitReasons = [
		{
			id: 0,
			value: VisitReasons.INSTRUCTION,
			text: intl.formatMessage({ id: 'instruction' }),
		},
		{
			id: 1,
			value: VisitReasons.THERAPY_DESCRIPTION,
			text: intl.formatMessage({ id: 'therapyDescription' }),
		},
		{
			id: 2,
			value: VisitReasons.CHECK_UP,
			text: intl.formatMessage({ id: 'checkUp' }),
		},
		{
			id: 3,
			value: VisitReasons.RE_CHECK_UP,
			text: intl.formatMessage({ id: 'reCheckUp' }),
		},
		{
			id: 4,
			value: VisitReasons.REFERRAL,
			text: intl.formatMessage({ id: 'referral' }),
		},
		{
			id: 5,
			value: VisitReasons.OTHER,
			text: intl.formatMessage({ id: 'other' }),
		},
	];

	useEffect(() => {
		intervals.current.forEach(item => clearInterval(item));
		requests.forEach(item => {
			const request = isLevelRequest ? item : item.request;
			setRequestsTime(prevState => ({ ...prevState, [request.id]: getLocalTimeHHmmss(request.dateCreated) }));
			intervals.current = [
				...intervals.current,
				setInterval(() => {
					setRequestsTime(prevState => ({ ...prevState, [request.id]: getLocalTimeHHmmss(request.dateCreated) }));
				}, 60000),
			];
			return () => {
				intervals.current.forEach(item => clearInterval(item));
			};
		});
	}, [isLevelRequest, requests]);

	const getRequests = async (pageSize, pageIndex) => {
		const params = {
			doctorGuidId: getUserId(),
			pageSize,
			pageIndex,
			type: AssignedRequestTypes.WITHOUT_INTAKE_FORM,
		};
		const response = isLevelRequest
			? await getLevelAssignedRequests({ healthSystemId: userSession.healthSystem.id, ...params })
			: await getProviderAssignedRequests(params);
		if (response.error) {
			setErrorApiResponse(response.error.message);
			setIsLoading(false);
			return;
		}
		setRequests(isLevelRequest ? response.requests : response.assignedRequests);
		setPagination({ pageSize, pageIndex, totalCount: response.totalCount });
		setUserWaitingRoomCount(response.totalCount);
		setIsLoading(false);
	};

	const getEventBasedOnConfig = () =>
		isLevelRequest ? SocketEvents.HealthCare.ON_LEVEL_REQUEST_UPDATED : SocketEvents.HealthCare.ON_MEDIC_REQUEST_UPDATED;

	useEffect(() => {
		const handleRequestUpdated = () => {
			getRequests(pagination.pageSize, pagination.pageIndex);
		};
		getRequests(pagination.pageSize, pagination.pageIndex);
		socket.on(getEventBasedOnConfig(), handleRequestUpdated);
		return () => {
			socket.off(getEventBasedOnConfig(), handleRequestUpdated);
		};
	}, [pagination.pageIndex, pagination.pageSize, pagination.totalCount, socket]);

	const joinCall = async item => {
		let assignedRequestId = item.id;
		if (getConfigurationValue(companyConfigurations[TelemedicineModesSettings.SHARED_QUEUE])) {
			setIsJoinCallLoading(item.id);
			const response = await updateLevelRequestStatus({
				healthSystemId: userSession.healthSystem.id,
				requestId: item.id,
				requestStatusId: RequestStatus.ACCEPTED,
			});
			if (response.error) {
				setErrorApiResponse(
					response.error.response.data.code === HealthcareErrorCode.REQUEST_IS_ASSIGNED
						? intl.formatMessage({ id: 'sessionAlreadyReceived' })
						: response.error.message
				);
				setIsJoinCallLoading(null);
				return;
			}
			assignedRequestId = response.assignedRequestId;
			setIsJoinCallLoading(null);
		}
		const { requestConference } = isLevelRequest ? item : item.request;
		setUserInfo({ ...getUserInfo(), incomingCallsDisabled: true });
		const queryParams = queryString.stringify(
			{
				[StartQueryStringKeys.CONFERENCE_ID]: requestConference.conferenceId,
				assignedRequestId,
			},
			{
				skipNull: true,
			}
		);
		window.open(`/call?${queryParams.toString()}`, '_blank');
		setRequests(prevState => prevState.filter(request => request.id !== item.id));
		setUserWaitingRoomCount(waitingRoomCount - 1);
	};

	const rejectRequest = async item => {
		setRejectCallLoading(item.id);
		const { requestConference } = isLevelRequest ? item : item.request;
		const response = isLevelRequest
			? await updateLevelRequestStatus({
					healthSystemId: userSession.healthSystem.id,
					requestId: item.id,
					requestStatusId: RequestStatus.REJECTED,
			  })
			: await updateAssignRequest(item.id, {
					requestStatusId: RequestStatus.REJECTED,
			  });

		if (response.error) {
			setErrorApiResponse(
				response.error.response.data.code === HealthcareErrorCode.REQUEST_IS_ASSIGNED
					? intl.formatMessage({ id: 'sessionAlreadyReceived' })
					: response.error.message
			);
			setRejectCallLoading(null);
			return;
		}
		if (requestConference) {
			const { conferenceId, creatorId } = requestConference;
			socket.emit(
				SocketEvents.Conference.END,
				{
					conferenceId,
					participantId: creatorId,
				},
				() => {}
			);
		}
		setRequests(prevState => prevState.filter(request => request.id !== item.id));
		setUserWaitingRoomCount(waitingRoomCount - 1);
		setRejectCallLoading(null);
	};

	const getCategoryPreference = categoryId => userUnits.find(item => item.unitCategoryId === categoryId);

	const getUnitPreference = categoryId => {
		const selectedPreference = getCategoryPreference(categoryId);
		return selectedPreference?.options.find(item => item.unitSystemId === selectedPreference.unitSystemId);
	};

	const getMeasurementValue = (measurement, foundMeasurement) => {
		let val = measurement.measurementValue;

		if (measurement.measurementType === MeasurementTypes.SLEEP) {
			val = convertSecondsToHoursFormat(measurement.measurementValue);
		}

		return !foundMeasurement.unitCategoryId
			? val
			: convertMeasurementTypes(
					foundMeasurement.unitCategoryId,
					measurement.measurementValue,
					getUnitPreference(foundMeasurement.unitCategoryId)?.unitSystemId
			  );
	};

	const getMeasurementUnit = measurement =>
		measurement.unitCategoryId ? getUnitPreference(measurement.unitCategoryId)?.unit : measurement.unit;

	const renderRequest = item => {
		const request = isLevelRequest ? item : item.request;
		const unavailableDateOfBirth = '0001-01-01T00:00:00';

		return (
			<div key={request.id} className={request.requestType === RequestType.RAPID ? 'rapid-request-type' : ''}>
				<div className='flex flex-align-center'>
					<ProfilePicture
						firstName={request.patient.firstName}
						lastName={request.patient.lastName}
						className='doctor-request-img'
						profilePicture={request.patient.profilePicture}
					/>
					<div className='flex-1 left-15'>
						<h4>
							{request.patient.firstName} {request.patient.lastName}
						</h4>
						<span className='--blue-color'>
							{request.location}
							<br />
						</span>
						{request.patient.dateOfBirth !== unavailableDateOfBirth && (
							<span className='--blue-color'>
								{getMonthDayYearDateFormat(request.patient.dateOfBirth)} ({translate('age')}:{' '}
								{getAge(request.patient.dateOfBirth)}),{' '}
							</span>
						)}
						{request.patient.genderId !== 0 && (
							<span className='--blue-color'>{getGender(request.patient.genderId).description}</span>
						)}
					</div>
					<div className={`request-type ${getRequestType(request.requestType)}`}>
						<HelloIcon color={getRequestColor(request.requestType)} />
						<span>{translate(getRequestType(request.requestType))}</span>
					</div>
					{request.requestConference && (
						<Button
							text={translate('joinCall')}
							imgIcon={`${healthCareCdnUrl}workflow/start-call.svg`}
							onClick={() => joinCall(item)}
							alt='start-call-icon'
							isLoading={isJoinCallLoading === request.id}
						/>
					)}
					<Button
						isLoading={rejectCallLoading === request.id}
						icon='cancel'
						text={translate('rejectRequest')}
						onClick={() => rejectRequest(item)}
						variant='red'
					/>
				</div>
				{request.requestReason && (
					<div className='top-15'>
						<h4>{translate('reasonOfVisit')}</h4>
						<p className='break-word'>{visitReasons?.find(reason => reason.value === request.requestReason)?.text}</p>
					</div>
				)}

				{request.medicalQuestionAnswers &&
					request.medicalQuestionAnswers.length > 0 &&
					request.medicalQuestionAnswers.map(medicalQuestion => (
						<div className='top-15'>
							<h4>{medicalQuestions?.find(question => question.id === medicalQuestion.medicalQuestionId)?.question}</h4>
							<p className='break-word'>{medicalQuestion.answer}</p>
						</div>
					))}
				{request.description && (
					<div className='top-15'>
						<h4>{translate('healthConcern')}</h4>
						<p className='break-word'>{request.description}</p>
					</div>
				)}
				<div className='top-15'>
					{request.requestSymptoms && request.requestSymptoms.length > 0 && (
						<>
							<h4>{translate('symptoms')}</h4>
							<div className='flex symptoms-grid flex-wrap'>
								{request.requestSymptoms.map(element => (
									<div key={element.symptom.name}>{intl.formatMessage({ id: stringToCamelCase(element.symptom.name) })}</div>
								))}
							</div>
						</>
					)}
				</div>

				<div className='flex flex-align-center'>
					<div className='flex-1'>
						{request.latestMeasurements && request.latestMeasurements.length > 0 && (
							<>
								<div className='latest-health-data'>
									<Button
										onClick={() =>
											setRequests(prevState =>
												[...prevState].map(req => {
													const newItem = { ...req };
													if (newItem.id === request.id) {
														newItem.isExpanded = !newItem.isExpanded;
													}
													return newItem;
												})
											)
										}
										text={translate(request.isExpanded ? 'collapseLatestHealthData' : 'expandLatestHealthData')}
										icon={request.isExpanded ? 'expand_less' : 'expand_more'}
									/>
								</div>
								{request.isExpanded && (
									<div className='flex flex-wrap health-data-small-grid'>
										{request.latestMeasurements.map(measurement => {
											const foundMeasurement = measurementAlert.find(el => el.type === measurement.measurementType);
											const measurementType =
												measurement.measurementType === MeasurementTypes.DIABETES
													? MeasurementTypes.BLOOD_GLUCOSE
													: measurement.measurementType;
											return (
												<div key={measurement.id}>
													{foundMeasurement?.icon && <img src={foundMeasurement.icon} alt='ico' />}

													<p>{translate(measurementType)}</p>

													<div className='flex flex-align-center'>
														<h4 className='line-height-1'>{getMeasurementValue(measurement, foundMeasurement)}</h4>
														{measurement.measurementType !== MeasurementTypes.SLEEP && (
															<span className='break-word'>{getMeasurementUnit(foundMeasurement)}</span>
														)}
													</div>
													<span>{formattedDate(measurement.endDate)}</span>
												</div>
											);
										})}
									</div>
								)}
							</>
						)}
					</div>
					<div className='flex-end column-direction text-align-right request-time'>
						<h5>{requestsTime[request.id]}</h5>
					</div>
				</div>
			</div>
		);
	};

	const hasRequestConference = requests.some(item => (isLevelRequest ? item.requestConference : item.request?.requestConference));
	return (
		<MainLayout showWarningAlert={true}>
			<div className='view-page-wrapper'>
				{!isLoading && requests.length > 0 && (
					<div
						className={`waiting-room-view-inner ${
							requests.every(item => (isLevelRequest ? !item.requestConference : !item.request?.requestConference))
								? ''
								: 'full-width'
						}`}>
						{hasRequestConference && (
							<div className='flex home-title'>
								<h3>
									{translate('youHaveLengthPatients', {
										length: requests.length,
										patients: intl.formatMessage({ id: requests.length === 1 ? 'onePatient' : 'multiplePatients' }).toLowerCase(),
									})}
								</h3>
							</div>
						)}
						<div className='waiting-room-list'>
							{requests
								.filter(item => (isLevelRequest ? item.requestConference : item.request?.requestConference))
								.map(request => renderRequest(request))}
						</div>
						{hasRequestConference && (
							<Pagination
								totalCount={pagination.totalCount}
								pageSize={pagination.pageSize}
								pageIndex={pagination.pageIndex}
								onChange={(pageSize, pageIndex) => {
									setIsLoading(true);
									getRequests(pageSize, pageIndex);
								}}
							/>
						)}
					</div>
				)}
				{!isLoading &&
					(requests.length === 0 ||
						requests.every(item => (isLevelRequest ? !item.requestConference : !item.request?.requestConference))) && (
						<div className='empty-state-wrapper'>
							<EmptyState title={translate('noRequestsFound')} image='no-requests.svg' />
						</div>
					)}
				{isLoading && (
					<Grid columns='1fr' rows='1fr' stretch='calc(100vh - 200px)' horizAlign='center' vertAlign='center'>
						<div style={{ textAlign: 'center' }}>
							<Loader />
						</div>
					</Grid>
				)}
				<Alert display={errorApiResponse} fixed={true} hideCloseButton={true} message={errorApiResponse} variant='dark' />
			</div>
		</MainLayout>
	);
};

export default WaitingRoom;
