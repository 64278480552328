import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Cam } from '@solaborate/calls/webrtc';
import { useIntl } from 'react-intl';
import { isMobile } from 'react-device-detect';
import classNames from 'classnames';
import { Modal, ParticipantVideo } from 'calls/components/index.js';
import { useConferenceConfigurations, useLocalParticipant } from 'calls/hooks/index.js';
import translate from 'i18n-translations/translate.jsx';
import { changeLocalParticipantBackground, imageUrlToBase64 } from 'calls/helpers/index.js';
import LightTheme from 'calls/styles/LightTheme.js';
import DarkTheme from 'calls/styles/DarkTheme.js';
import Loader from 'components/Common/Loader.jsx';
import { getStorage } from 'infrastructure/helpers/commonHelpers.js';
import { getBlobSasPicture } from 'api/doctors.js';
import { useDispatch, useSelector } from 'react-redux';
import { CompanySettings, getConfig, UserSettings } from 'constants/configurationEnums.js';
import { setUserHealthSystemPreferences } from 'api/users.js';
import { getUserRole } from 'infrastructure/auth.js';
import { actionCreators as configurationActionCreators } from 'state/configurations/actions.js';
import { UserRoles, VirtualBackgroundTypes } from 'constants/enums.js';
import SpinLoader from 'icons/Monitoring/SpinLoader.jsx';
import Alert from 'components/Common/Alert.jsx';
import CustomButton from 'components/Common/Button.jsx';
import WebGLTrack, { initWebGLPipeline } from 'calls/WebGLTrack.js';
import { v4 } from 'uuid';
import callsActionCreators from 'state/calls/actions.js';
/**
 * @type {import('styled-components').StyledComponent<"div", any, { $isDarkMode: boolean, $isLoading: boolean }, never>}
 */
const StyledVirtualBackground = styled.div`
	section {
		background: ${props => (props.$isDarkMode ? DarkTheme.colors.grayThree : LightTheme.colors.grayZero)};
		header {
			border-bottom: 1px solid ${props => (props.$isDarkMode ? DarkTheme.colors.grayFour : LightTheme.colors.grayOne)};
			position: relative;
			z-index: 1;

			h1 {
				color: ${props => (props.$isDarkMode ? DarkTheme.colors.grayFive : LightTheme.colors.grayFive)};
			}
			button {
				span {
					color: ${props => (props.$isDarkMode ? DarkTheme.colors.grayFive : LightTheme.colors.grayFive)};
				}
			}
		}
		> div > main {
			margin-bottom: 0;
			height: 100%;
			display: flex;
			flex-direction: column;
			> main {
				flex: 1;
			}

			${props =>
				props.$isDarkMode &&
				`
				> div {
					button:first-of-type {
						border-color: ${DarkTheme.colors.grayThree};
						background: ${DarkTheme.colors.grayFive};
						color: ${DarkTheme.colors.grayTwo};
					}
				}
			`}
		}
	}

	main > header {
		max-height: 150px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: ${LightTheme.spacing[3]}px;
		background: ${props => (props.$isDarkMode ? DarkTheme.colors.grayFour : LightTheme.colors.graySix)};
		border-bottom: none;
		video {
			height: auto;
			max-height: 150px;
			width: auto;
			object-fit: contain;
		}
	}

	footer {
		display: grid;
		grid-template-columns: ${props => (props.$isLoading ? 'repeat(1, minmax(0, 1fr)) ' : 'repeat(3, minmax(0, 1fr))')};
		grid-gap: ${LightTheme.spacing[2]}px;

		button {
			position: relative;
			padding: 0;
			border-radius: ${LightTheme.borderRadius.buttons}px;
			border: 1px solid ${props => (props.$isDarkMode ? 'var(--gray-0-o10)' : 'var(--gray-8-o05)')} !important;
			background: transparent;
			aspect-ratio: 4 / 3;
			span {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				color: ${props => (props.$isDarkMode ? DarkTheme.colors.grayFive : LightTheme.colors.grayFive)};
				p {
					color: ${props => (props.$isDarkMode ? DarkTheme.colors.grayFive : LightTheme.colors.grayFive)};
					margin: 0;
					padding: 0;
					text-transform: none;
					font-size: 12px;
				}
				i {
					position: unset;
					font-size: 22px;
					margin: 0;
				}

				input[type='file'] {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					opacity: 0;
					cursor: pointer;
				}
			}
			img {
				margin: auto;
				object-fit: contain;
				height: -webkit-fill-available;
			}
		}
	}
`;

/**
 * @param {object} props
 * @param {() => void} props.onDismiss
 * @param {string} [props.position]
 */
const VirtualBackgroundView = ({ onDismiss, position }) => {
	const localParticipant = useLocalParticipant();
	/** @type {[WebGLTrack, import('react').Dispatch<import('react').SetStateAction<WebGLTrack>>]} */
	const [trackWithBackground, setTrackWithBackground] = useState(null);
	const [selectedBackground, setSelectedBackground] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [isLoadingSelectedBg, setIsLoadingSelectedBg] = useState(false);
	const [isApplyingBackground, setIsApplyingBackground] = useState(false);

	const [error, setError] = useState(null);
	const conferenceConfigs = useConferenceConfigurations();
	const visualSettings = useSelector(state => state.configurations.unboundHealthSystemSettings.visualsSettings);
	const userSettings = useSelector(state => state.configurations.userSettings);
	const [isBackgroundImgsLoading, setIsBackgroundImgsLoading] = useState(true);
	const userSession = useSelector(state => state.user.userSession);
	const tflite = useSelector(state => state.calls.virtualbackgroundConfig);
	const dispatch = useDispatch();
	const localParticipantVideoRef = useRef(null);
	const intl = useIntl();
	const [backgrounds, setBackgrounds] = useState([
		{
			id: 5,
			description: translate('filterNone'),
			type: VirtualBackgroundTypes.NONE,
		},
		{
			id: 6,
			description: translate('filterBlur'),
			type: VirtualBackgroundTypes.BLUR,
		},
	]);

	// @ts-ignore
	const localTrackFactory = localParticipant.localTrackController.factory;

	const getTracks = async () => {
		setIsLoading(true);
		const background = localParticipant.localTrackController.background;
		let newTflite = tflite;
		if (!tflite) {
			newTflite = await initWebGLPipeline();
			dispatch(callsActionCreators.setVirtualbackgroundConfig(newTflite));
		}
		setSelectedBackground(background);
		const newTracks = await localTrackFactory.createTracks([Cam]);
		const webGLTrack = new WebGLTrack(newTracks[0], background ?? { type: VirtualBackgroundTypes.NONE, tflite: newTflite });
		setTrackWithBackground(webGLTrack);
		localParticipantVideoRef.current.srcObject = new MediaStream([webGLTrack.track]);
		setIsLoading(false);
	};

	const getImageBase64String = async url => {
		const backgroundImageResponse = await getBlobSasPicture(url, 'team-call-background-pictures');
		if (backgroundImageResponse.errorResponse && backgroundImageResponse.error) {
			return null;
		}

		const base64ImageResponse = await imageUrlToBase64(backgroundImageResponse.result.uri.uri);
		return base64ImageResponse;
	};

	const onApply = async () => {
		setIsLoading(false);
		setIsApplyingBackground(true);
		if (UserRoles.GUEST === getUserRole()) {
			changeLocalParticipantBackground({ localParticipant, selectedBackground });
			setIsApplyingBackground(false);
			trackWithBackground?.cleanupPipeline();
			onDismiss();
			return;
		}
		const backgroundValue = selectedBackground?.pictureUrl ?? selectedBackground?.type;
		const background = {
			...selectedBackground,
			...(selectedBackground.url && { url: await getImageBase64String(selectedBackground?.pictureUrl) }),
			tflite,
		};

		const dataToSend = {
			teamSettings: [
				{
					settingTypeId: UserSettings.CALL_BACKGROUND,
					value: backgroundValue,
				},
			],
		};

		const response = await setUserHealthSystemPreferences(userSession.healthSystem.id, dataToSend);
		if (response.error) {
			setError(response.error.message);
			setIsLoading(false);
			return;
		}

		dispatch(
			configurationActionCreators.setUserSettings({
				[UserSettings.CALL_BACKGROUND]: backgroundValue,
			})
		);
		try {
			await changeLocalParticipantBackground({
				localParticipant,
				selectedBackground: background,
			});
		} catch (error) {
			onDismiss();
			conferenceConfigs.setConferenceErrorMessages([{ id: v4(), message: translate('somethingWentWrong') }]);
		}
		trackWithBackground?.cleanupPipeline();
		setIsApplyingBackground(false);
		onDismiss();
	};

	const onCancel = () => {
		trackWithBackground?.cleanupPipeline();
		onDismiss();
	};

	const changeBackground = async (type, url = '', pictureUrl = '') => {
		if (type === VirtualBackgroundTypes.BLUR) {
			setSelectedBackground({ type: VirtualBackgroundTypes.BLUR, tflite });
			if (trackWithBackground) {
				trackWithBackground.setupPipeline({ type: VirtualBackgroundTypes.BLUR, tflite });
			}
			getStorage().setItem('virtualBackground', 'blur');
		} else if (type === VirtualBackgroundTypes.NONE) {
			setSelectedBackground({ type: VirtualBackgroundTypes.NONE, tflite });
			if (trackWithBackground) {
				trackWithBackground.setupPipeline({ type: VirtualBackgroundTypes.NONE, tflite });
			}
			getStorage().setItem('virtualBackground', 'none');
		} else {
			if (isLoadingSelectedBg) {
				return;
			}
			setIsLoadingSelectedBg(true);
			const base64Image = await imageUrlToBase64(url);
			setSelectedBackground({ type: VirtualBackgroundTypes.IMAGE, url: base64Image, pictureUrl, tflite });
			if (trackWithBackground) {
				trackWithBackground.setupPipeline({ type: VirtualBackgroundTypes.IMAGE, url: base64Image, pictureUrl, tflite });
			}
			setIsLoadingSelectedBg(false);
		}
	};

	useEffect(() => {
		const fetchImages = async () => {
			const { NONE, BLUR } = VirtualBackgroundTypes;
			if (UserRoles.GUEST === getUserRole()) {
				setIsBackgroundImgsLoading(false);
				setIsLoading(false);
				if (getStorage().getItem('virtualBackground') === 'blur') {
					changeBackground(BLUR);
				} else {
					changeBackground(NONE);
				}
				return;
			}
			const callBackground = userSettings[UserSettings.CALL_BACKGROUND];
			const backgroundConfig = getConfig(visualSettings[CompanySettings.DIGITAL_BACKGROUND]);
			const defaultHsBackground = visualSettings[CompanySettings.HS_DEFAULT_BACKGROUND]?.value;

			const newBackground = callBackground || defaultHsBackground;

			if (conferenceConfigs.backgroundImages.length > 0) {
				setBackgrounds([...backgrounds, ...conferenceConfigs.backgroundImages]);
			}
			if (!backgroundConfig.value) {
				setIsBackgroundImgsLoading(false);
				return;
			}
			setIsBackgroundImgsLoading(false);

			if ([NONE, BLUR].includes(newBackground)) {
				changeBackground(newBackground);
				setIsBackgroundImgsLoading(false);
			} else if (newBackground) {
				getBackground(newBackground, conferenceConfigs.backgroundImages);
			}
		};
		// this needs to be run only when component mounts
		// eslint-disable-next-line react-hooks/exhaustive-deps
		getTracks();
		fetchImages();
	}, []);

	const getBackground = async (background, images) => {
		if (conferenceConfigs.backgroundImages.length > 0) {
			const found = conferenceConfigs.backgroundImages.find(img => img.pictureUrl === background);
			if (found) {
				changeBackground(VirtualBackgroundTypes.IMAGE, found.url, background);
			}
			return;
		}
		let blobResponse = {};
		if (background) {
			blobResponse = await getBlobSasPicture(background, 'team-call-background-pictures');
		}
		if (!blobResponse.error && !blobResponse.errorResponse) {
			const found = images.find(item => item.pictureUrl === background);
			if (found) {
				changeBackground(VirtualBackgroundTypes.IMAGE, blobResponse.result.uri.uri, background);
			}
		}
	};

	return (
		<StyledVirtualBackground $isDarkMode={conferenceConfigs.isDarkMode} $isLoading={isBackgroundImgsLoading}>
			<Modal onDismiss={onCancel} title={intl.formatMessage({ id: 'selectBackground' })} position={position}>
				<Modal.Content>
					<main>
						{isLoading && (
							<div className='virtual-background-loader'>
								<Loader />
							</div>
						)}
						<header>
							<ParticipantVideo
								className={classNames(
									isMobile ? 'virtual-background-video-mobile' : '',
									isLoading ? 'virtual-background-video-loading' : 'virtual-background-video'
								)}
								ref={localParticipantVideoRef}
								track={localParticipant.localTrackController.tracks[Cam]}
								autoPlay={true}
							/>
						</header>

						<footer>
							{!isBackgroundImgsLoading &&
								backgrounds.map(({ url, id, pictureUrl, description, type }) => (
									<>
										{type !== VirtualBackgroundTypes.IMAGE && (
											<CustomButton
												disabled={isLoadingSelectedBg}
												marginRight='0'
												style={{ height: '100px' }}
												key={id}
												type='button'
												onClick={() => {
													if (selectedBackground?.type === type) {
														return;
													}
													changeBackground(type);
												}}
												className={classNames(
													'flex-justify-center',
													selectedBackground?.type === type
														? 'virtual-background-image-selected'
														: 'virtual-background-image-button'
												)}
												text={
													<span className='virtual-background-text'>
														{type === VirtualBackgroundTypes.BLUR && <i className='material-icons'>blur_on</i>}
														{type === VirtualBackgroundTypes.NONE && <i className='material-icons'>no_accounts</i>}
														<p>{description}</p>
													</span>
												}
											/>
										)}
										{type === VirtualBackgroundTypes.IMAGE && (
											<CustomButton
												disabled={isLoadingSelectedBg}
												key={id}
												type='button'
												borderRadius='var(--spacing-s)'
												onClick={() => {
													if (selectedBackground?.url === url || selectedBackground?.pictureUrl === pictureUrl) {
														return;
													}
													changeBackground(type, url, pictureUrl);
												}}
												className={classNames(
													'flex-justify-center',
													selectedBackground?.url === url || selectedBackground?.pictureUrl === pictureUrl
														? 'virtual-background-image-selected'
														: 'virtual-background-image-button'
												)}
												svgIcon={<img src={url} className='virtual-background-img' alt='Virtual background thumbnail' />}
												style={{ height: '100px' }}
												marginRight='0'
											/>
										)}
									</>
								))}
							{isBackgroundImgsLoading && (
								<div className='center-circle-loader'>
									<SpinLoader />
								</div>
							)}
						</footer>
					</main>
					<div className='virtual-background-buttons-container'>
						<CustomButton
							variant='white'
							text={translate('cancel')}
							disabled={!trackWithBackground || isLoadingSelectedBg}
							onClick={onCancel}
						/>
						<CustomButton
							isLoading={isApplyingBackground}
							text={translate('selectBackground')}
							disabled={!trackWithBackground}
							onClick={() => {
								onApply();
							}}
						/>
					</div>
				</Modal.Content>
			</Modal>
			{error && <Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />}
		</StyledVirtualBackground>
	);
};

export default VirtualBackgroundView;
