import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { addMeasurement, deleteMeasurement, deleteMeasurements } from 'api/measurements.js';
import Form from 'components/Common/Form.jsx';
import _ from 'lodash';
import Input from 'components/Common/Input.jsx';
import Modal from 'components/Common/Modal.jsx';
import Select from 'components/Common/Select.jsx';
import { MeasurementTypes, UnitSystemTypes } from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import PatientMeasurements from 'containers/CheckInAPatient/PatientMeasurements.jsx';
import translate from 'i18n-translations/translate.jsx';
import { convertSecondsToHoursFormat, handleOnKeyDownNumeric, stringToCamelCase } from 'infrastructure/helpers/commonHelpers.js';
import { formattedDate } from 'infrastructure/helpers/dateHelper.js';
import {
	convertMeasurementTypes,
	convertToMetricUnit,
	getDefaultUnit,
	getMeasurementDescription,
	getMeasurementSource,
} from 'infrastructure/helpers/measurementsHelper.js';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import Alert from 'components/Common/Alert.jsx';

const ManageMeasurements = props => {
	const [isTimeValid, setIsTimeValid] = useState(true);
	const [disableOnSubmit, setDisableOnSubmit] = useState(false);
	const [isDeleteRecordModalVisible, setIsDeleteRecordModalVisible] = useState(false);
	const [selectedSleepValue, setSelectedSleepValue] = useState('In Bed');
	const [error, setError] = useState(null);
	const intl = useIntl();
	const minDate = new Date(props.measurementDate);
	minDate.setHours(0, 0, 0);

	const isStep = props.measurement.type === MeasurementTypes.STEPS;
	const language = useSelector(state => state.language.locale);

	const addNewMeasurement = async () => {
		if (!isFormValid()) {
			return;
		}
		setDisableOnSubmit(false);
		const { type } = props.measurement;
		const { unit } = props.measurement;
		const data = {
			userId: props.selectedPatient?.userId,
			deviceType: 'web',
			measurementType: type,
			measurementValue: getFormattedMeasurement(),
			measurementUnit: props.isSleep ? selectedSleepValue : getDefaultUnit(type, unit),
			startDate: props.measurementDate,
			endDate: props.isSleep || isStep ? props.measurementEndDate : props.measurementDate,
			isManuallyInserted: true,
			source: 'web',
		};
		const response = await addMeasurement(data);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		data.id = response.id;

		if (props.showAllMeasurements) {
			props.setMeasurements(prevState => {
				const newMeasurements = [...prevState, data];
				newMeasurements.sort((a, b) => (+new Date(b.startDate) > +new Date(a.startDate) ? 1 : -1));
				return newMeasurements;
			});
		}

		props.mapChartDataAndLabels();
		props.toggleAddMeasurementModal();
		if (props.isFromVitalSigns) {
			props.setIsAddManuallyOpen();
			props.fetchLatestMeasurements();
		}
	};
	const handleMeasurementDateChange = date => {
		props.setMeasurementDate(new Date(date));
		const isValid = isFormValid();
		setIsTimeValid(isValid);
	};
	const getMaxTime = () => {
		const visitDateTime = moment(props.measurementDate);
		return new Date(visitDateTime.isSame(moment(), 'day') ? props.measurementEndDate : '12:00');
	};

	const handleDateChange = date => {
		if (new Date(date).getTime() > new Date(props.measurementDate).getTime()) {
			props.setMeasurementEndDate(new Date(date));
		} else {
			props.setMeasurementDate(prevState => new Date(prevState));
		}
	};

	const isFormValid = () => {
		let canInsert = true;
		if (MeasurementTypes.BLOOD_PRESSURE === props.measurement.type) {
			if (
				!props.measurementValue ||
				!props.measurementValueSecond ||
				(props.measurementValue && (props.measurementValue < 40 || props.measurementValue > 300)) ||
				(props.measurementValueSecond && (props.measurementValueSecond < 30 || props.measurementValueSecond > 200))
			) {
				canInsert = false;
			}
		}
		if (
			!props.measurementValue ||
			parseInt(props.measurementValue, 10) < parseInt(props.measurement.minimumValue, 10) ||
			parseInt(props.measurementValue, 10) > parseInt(props.measurement.maximumValue, 10)
		) {
			canInsert = false;
		}

		if (MeasurementTypes.SLEEP === props.measurement.type) {
			const duration = moment(props.measurementEndDate).diff(moment(props.measurementDate));
			const minutes = duration / 1000 / 60;
			canInsert = minutes > 1 && minutes < 1440;
		}
		return canInsert;
	};

	const handleOnChange = (event, isBloodPressure = false) => {
		if (Number(event.target.value) < 0) {
			return;
		}
		if (isBloodPressure) {
			props.setMeasurementValueSecond(event.target.value);
			return;
		}
		props.setMeasurementValue(event.target.value);
	};

	const toggleDeleteRecordModal = () => {
		setIsDeleteRecordModalVisible(prevState => !prevState);
	};

	const handleDeleteMeasurement = async () => {
		const { id } = props.selectedMeasurement;
		const response = await deleteMeasurement(id);
		if (!response.error || response.hasSucceeded) {
			const measurementsCopied = [...props.measurements];
			const filteredMeasurements = measurementsCopied.filter(measurement => measurement.id !== id);
			props.setMeasurements(filteredMeasurements);
			setIsDeleteRecordModalVisible(false);
			props.setIsDataSourceModalVisible(false);
			props.mapChartDataAndLabels();
		} else {
			setError(response.error.message);
		}
	};

	const handleDeleteMeasurements = async () => {
		const response = await deleteMeasurements(props.measurement.type);
		if (!response.error || response.hasSucceeded) {
			props.setMeasurements([]);
			props.setIsDeleteAllDataModalVisible(false);
		}
	};

	const getFormattedMeasurement = () => {
		const updatedMeasurementValue = props.measurement.isDecimal ? (+props.measurementValue).toFixed(2) : props.measurementValue;
		props.setMeasurementValue(updatedMeasurementValue);
		if (props.isBloodPressure) {
			return `${props.measurementValue}/${props.measurementValueSecond}`;
		}

		if (props.isSleep) {
			const duration = moment(props.measurementEndDate).diff(moment(props.measurementDate));
			return (duration / 1000).toFixed().toString();
		}

		if (props.measurement.unitSystemId !== UnitSystemTypes.METRIC) {
			return convertToMetricUnit(props.measurement.categoryId, props.measurementValue);
		}

		return convertMeasurementTypes(
			props.measurement.categoryId,
			props.measurementValue,
			props.measurement.unitSystemId
		).toString();
	};

	const maxTime = getMaxTime();

	return (
		<>
			<Modal
				modalSelector='addMeasurementModal'
				display={props.showAddMeasurementPopUp}
				position='center'
				onModalClose={props.toggleAddMeasurementModal}
				onModalSubmit={_.debounce(() => addNewMeasurement(), 500)}
				primaryButtonLabel={translate('add')}
				className={`add-measurement-popup ${props.measurement.type}`}
				isSubmitDisabled={!isFormValid()}>
				<Form title={translate('addDataManually')}>
					<div className='input add-measurement'>
						<p className='label'>{props.isSleep || isStep ? translate('startDate') : translate('dateAndTime')}</p>
						<DatePicker
							showTimeSelect
							selected={props.measurementDate}
							onChange={handleMeasurementDateChange}
							maxDate={new Date()}
							minTime={minDate}
							maxTime={maxTime}
							dateFormat='MMMM d, yyyy h:mm aa'
							popperPlacement='bottom-start'
							locale={language.locale}
						/>
						{props.isSleep && !isFormValid() && !isTimeValid && (
							<span className='red-error'>{translate('twentyHrsBetween')}</span>
						)}
					</div>
					{(props.isSleep || isStep) && (
						<div className='input add-measurement'>
							<p className='label'>{translate('endDate')}</p>
							<DatePicker
								showTimeSelect
								selected={props.measurementEndDate}
								onChange={handleDateChange}
								maxDate={new Date()}
								minDate={new Date(props.measurementDate)}
								minTime={minDate}
								maxTime={new Date()}
								dateFormat='MMMM d, yyyy h:mm aa'
								popperPlacement='bottom-start'
								locale={language.locale}
							/>
						</div>
					)}
					{!props.isSleep && props.measurement.isDecimal && (
						<Input
							onChange={handleOnChange}
							name='measurementValue'
							label={intl.formatMessage({ id: 'inputValue' })}
							type='number'
							value={props.measurementValue}
							validationOptions={{}}
							bottomSpace='15px'
							placeholder={`${props.measurement.minimumValue} - ${props.measurement.maximumValue}`}
							description={getMeasurementDescription()}
							maxLength={255}
							min={props.measurement.minimumValue}
							max={props.measurement.maximumValue}
						/>
					)}
					{!props.isSleep && !props.measurement.isDecimal && (
						<Input
							onKeyDown={handleOnKeyDownNumeric}
							onChange={handleOnChange}
							name='measurementValue'
							label={intl.formatMessage({ id: 'inputValue' })}
							type='number'
							value={props.measurementValue}
							validationOptions={{}}
							bottomSpace='15px'
							placeholder={`${props.measurement.minimumValue} - ${props.measurement.maximumValue}`}
							description={getMeasurementDescription()}
							maxLength={255}
							min={props.measurement.minimumValue}
							max={props.measurement.maximumValue}
						/>
					)}
					{props.isSleep && (
						<Select
							onSelect={value => setSelectedSleepValue(value.target.value)}
							value={selectedSleepValue}
							textField='label'
							valueField='value'
							items={[
								{ value: 'In Bed', label: intl.formatMessage({ id: 'inBed' }) },
								{ value: 'Asleep', label: intl.formatMessage({ id: 'asleep' }) },
							]}
							description='Type'
							placeholder={intl.formatMessage({ id: 'select' })}
							className='full-width'
						/>
					)}
					{props.isBloodPressure && (
						<Input
							onKeyDown={handleOnKeyDownNumeric}
							onChange={event => handleOnChange(event, props.isBloodPressure)}
							name='measurementValueSecond'
							type='number'
							value={props.measurementValueSecond}
							validationOptions={{}}
							bottomSpace='15px'
							placeholder={`${props.measurement.minimumValueSecond} - ${props.measurement.maximumValueSecond}`}
							description={props.isBloodPressure ? translate('diastolic') : props.measurement.unit}
							maxLength={255}
							min={props.measurement.minimumValueSecond}
							max={props.measurement.maximumValueSecond}
						/>
					)}
				</Form>
			</Modal>

			<Modal
				modalSelector='addMeasurementModal'
				display={props.showAddMeasurementPopUp}
				position='center'
				onModalClose={props.toggleAddMeasurementModal}
				onModalSubmit={_.debounce(() => addNewMeasurement(), 500)}
				primaryButtonLabel={translate('add')}
				className={`add-measurement-popup ${props.measurement.type}`}
				isSubmitDisabled={!isFormValid()}>
				<Form title={translate('addDataManually')}>
					<div className='input add-measurement'>
						<p className='label'>{props.isSleep || isStep ? translate('startDate') : translate('dateAndTime')}</p>
						<DatePicker
							showTimeSelect
							selected={props.measurementDate}
							onChange={handleMeasurementDateChange}
							maxDate={new Date()}
							minTime={minDate}
							maxTime={getMaxTime()}
							dateFormat='MMMM d, yyyy h:mm aa'
							popperPlacement='bottom-start'
							locale={language.locale}
						/>
						{props.isSleep && !isFormValid() && !isTimeValid && (
							<span className='red-error'>{translate('twentyHrsBetween')}</span>
						)}
					</div>
					{(props.isSleep || isStep) && (
						<div className='input add-measurement'>
							<p className='label'>{translate('endDate')}</p>
							<DatePicker
								showTimeSelect
								selected={props.measurementEndDate}
								onChange={handleDateChange}
								maxDate={new Date()}
								minDate={new Date(props.measurementDate)}
								minTime={minDate}
								maxTime={new Date()}
								dateFormat='MMMM d, yyyy h:mm aa'
								popperPlacement='bottom-start'
								locale={language.locale}
							/>
						</div>
					)}
					{!props.isSleep && props.measurement.isDecimal && (
						<Input
							onChange={handleOnChange}
							name='measurementValue'
							label={intl.formatMessage({ id: 'inputValue' })}
							type='number'
							value={props.measurementValue}
							validationOptions={{}}
							bottomSpace='15px'
							placeholder={`${props.measurement.minimumValue} - ${props.measurement.maximumValue}`}
							description={getMeasurementDescription(props.measurement, intl)}
							maxLength={255}
							min={props.measurement.minimumValue}
							max={props.measurement.maximumValue}
						/>
					)}
					{!props.isSleep && !props.measurement.isDecimal && (
						<Input
							onKeyDown={handleOnKeyDownNumeric}
							onChange={handleOnChange}
							name='measurementValue'
							label={intl.formatMessage({ id: 'inputValue' })}
							type='number'
							value={props.measurementValue}
							validationOptions={{}}
							bottomSpace='15px'
							placeholder={`${props.measurement.minimumValue} - ${props.measurement.maximumValue}`}
							description={getMeasurementDescription(props.measurement, intl)}
							maxLength={255}
							min={props.measurement.minimumValue}
							max={props.measurement.maximumValue}
						/>
					)}
					{props.isSleep && (
						<Select
							onSelect={value => setSelectedSleepValue(value.target.value)}
							value={selectedSleepValue}
							textField='label'
							valueField='value'
							items={[
								{ value: 'In Bed', label: intl.formatMessage({ id: 'inBed' }) },
								{ value: 'Asleep', label: intl.formatMessage({ id: 'asleep' }) },
							]}
							description='Type'
							placeholder={intl.formatMessage({ id: 'select' })}
							className='full-width'
						/>
					)}
					{props.isBloodPressure && (
						<Input
							onKeyDown={handleOnKeyDownNumeric}
							onChange={event => handleOnChange(event, props.isBloodPressure)}
							name='measurementValueSecond'
							type='number'
							value={props.measurementValueSecond}
							validationOptions={{}}
							bottomSpace='15px'
							placeholder={`${props.measurement.minimumValueSecond} - ${props.measurement.maximumValueSecond}`}
							description={props.isBloodPressure ? translate('diastolic') : props.measurement.unit}
							maxLength={255}
							min={props.measurement.minimumValueSecond}
							max={props.measurement.maximumValueSecond}
						/>
					)}
				</Form>
			</Modal>
			<Modal
				modalSelector='iotDataModal'
				className='iot-data-source-modal'
				primaryButtonLabel={translate('deleteThisDataRecord')}
				display={props.isDataSourceModalVisible}
				onModalClose={() => props.toggleDataSourceModal(null)}
				position='center'
				onModalSubmit={toggleDeleteRecordModal}
				closeButtonText={translate('done')}
				submitImgIcon={`${healthCareCdnUrl}measurements-icons/trash-can.svg?v2`}>
				<div className='iot-devices-source-record'>
					<div>
						<h2>{translate('dataDetails')}</h2>
					</div>
					{props.selectedMeasurement && (
						<div className='flex iot-devices-source-grid'>
							<div>
								<h3>{props.measurement.link}</h3>
								<div className='flex'>
									<img src={props.measurement.icon} alt='icon' />

									<p>
										{props.selectedMeasurement.measurementType === MeasurementTypes.SLEEP &&
											convertSecondsToHoursFormat(props.selectedMeasurement.measurementValue)}

										{props.selectedMeasurement.measurementType !== MeasurementTypes.SLEEP &&
											convertMeasurementTypes(
												props.measurement.categoryId,
												props.selectedMeasurement.measurementValue,
												props.measurement.unitSystemId
											)}

										<span>
											{props.selectedMeasurement.measurementType !== MeasurementTypes.SLEEP &&
												props.measurement.unit &&
												intl.formatMessage({ id: stringToCamelCase(props.measurement.unit) }).toLowerCase()}
										</span>
									</p>
								</div>
							</div>
							<div>
								<h3>{translate('dateOfMeasurement')}</h3>
								<div className='flex'>
									<img src={`${healthCareCdnUrl}measurements-icons/calendar-black-icon.svg`} alt='icon' />
									<span>{formattedDate(props.selectedMeasurement.endDate)}</span>
								</div>
							</div>
							<div>
								<h3>{translate('source')}</h3>
								<div className='flex'>
									<span>
										<div className='flex'>
											<img src={getMeasurementSource(props.selectedMeasurement, intl).image} alt='add data' />
											<span className='measurement-src-text'>{getMeasurementSource(props.selectedMeasurement, intl).title}</span>
										</div>
									</span>
								</div>
							</div>
							{props.selectedMeasurement.device && props.selectedMeasurement.manufacturer && (
								<div>
									<h3>{translate('manufacturer')}</h3>
									<div className='flex'>
										<span>{props.selectedMeasurement.manufacturer}</span>
									</div>
								</div>
							)}
							{props.selectedMeasurement.device && props.selectedMeasurement.model && (
								<div>
									<h3>{translate('model')}</h3>
									<div className='flex'>
										<span>{props.selectedMeasurement.model}</span>
									</div>
								</div>
							)}
						</div>
					)}
				</div>
			</Modal>
			<Modal
				modalSelector='deleteIotModal'
				className='iot-delete-record-modal'
				display={isDeleteRecordModalVisible}
				onModalClose={toggleDeleteRecordModal}
				onModalSubmit={handleDeleteMeasurement}
				position='center'
				primaryButtonLabel={translate('delete')}>
				<div className='iot-devices-delete-record'>
					<img src={`${healthCareCdnUrl}measurements-icons/delete-records.svg`} alt='icon' />
					<h2>{translate('areYouSureDeleteThisRecord')}</h2>
				</div>
			</Modal>
			<Modal
				className='iot-delete-record-modal'
				display={props.isDeleteAllDataModalVisible}
				onModalClose={props.showDeleteAllDataModal}
				onModalSubmit={handleDeleteMeasurements}
				position='center'
				primaryButtonLabel={translate('delete')}>
				<div className='iot-devices-delete-record'>
					<img src={`${healthCareCdnUrl}measurements-icons/delete-records.svg`} alt='icon' />
					<h2>{translate('areYouSureDeleteAllRecords')}</h2>
				</div>
			</Modal>
			{props.isFromVitalSigns && (
				<PatientMeasurements
					display={props.isAddManuallyOpen}
					onModalClose={props.setIsAddManuallyOpen}
					onModalSubmit={_.debounce(() => addNewMeasurement(), 500)}
					isSubmitDisabled={!isFormValid() || disableOnSubmit}
					handleMeasurementDateChange={handleMeasurementDateChange}
					handleOnChange={handleOnChange}
					handleDateChange={handleDateChange}
					handleOnKeyDown={handleOnKeyDownNumeric}
					setSelectedSleepValue={value => setSelectedSleepValue(value.target.value)}
					getMeasurementDescription={getMeasurementDescription}
					measurement={props.measurement}
					measurementDate={props.measurementDate}
					measurementEndDate={props.measurementEndDate}
					measurementValue={props.measurementValue}
					measurementValueSecond={props.measurementValueSecond}
					selectedSleepValue={selectedSleepValue}
					minTime={minDate}
					maxTime={getMaxTime()}
					isSleep={props.isSleep}
					isStep={isStep}
					isBloodPressure={props.isBloodPressure}
					isFromVitalSigns={props.isFromVitalSigns}
					isTimeValid={isTimeValid}
				/>
			)}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</>
	);
};

export default ManageMeasurements;
