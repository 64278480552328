import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from 'components/Common/Tabs.jsx';
import HealthDataTabContent from 'containers/HealthMeasurements/HealthDataTabContent.jsx';
import HealthDataSummary from 'containers/HealthMeasurements/HealthDataSummary.jsx';
import { MeasurementTypes } from 'constants/enums.js';
import { generateHealthDataTabs, getMeasurementDetails } from 'infrastructure/helpers/measurementsHelper.js';
import { getUserId } from 'infrastructure/auth.js';
import translate from 'i18n-translations/translate.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import OtoDermatoscopeMeasurements from 'containers/HealthMeasurements/OtoDermatoscopeMeasurements.jsx';
import HeartLungsMeasurements from 'containers/HealthMeasurements/HeartLungsMeasurements.jsx';
import SpirometerHistory from 'containers/HealthMeasurements/SpirometerHistory.jsx';
import { HealthDataTabs } from 'constants/measurements.js';
import { RpmDevices } from 'constants/rpm.js';

class HealthData extends React.Component {
	state = {
		currentTab: 0,
		isExpanded: true,
		measurementTypes: [],
		healthDataRpm: [],
	};

	linkToHealthDataIcons = `${healthCareCdnUrl}health-data/`;

	liveExaminationLink = `${healthCareCdnUrl}live-examination/`;

	unlisten;

	userId = getUserId();

	componentDidMount = () => {
		const { params } = this.props.match;
		if (params.subTab) {
			const { type, parentType } = HealthDataTabs[params.subTab];
			this.toggleActiveTab(parentType);
			this.setState({ currentTab: type });
		}
		if (this.props.patientRpmProgram) {
			let measurementCategories = [];
			this.props.patientRpmProgram?.devices.forEach(device => {
				if (Object.values(RpmDevices).includes(device.deviceId)) {
					measurementCategories.push(device.measurementType);
				}
			});
			let healthDataTypes = generateHealthDataTabs(measurementCategories);
			this.setState({ healthDataRpm: healthDataTypes });
			this.setState({ measurementTypes: measurementCategories });
		}
		this.unlisten = this.props.history.listen(this.propsHistoryListener);
	};

	getCategoryPreference = categoryId => this.props.unitPreferences.find(item => item.unitCategoryId === categoryId);

	getUnitPreference = categoryId => {
		const selectedPreference = this.getCategoryPreference(categoryId);
		return selectedPreference?.options.find(item => item.unitSystemId === selectedPreference.unitSystemId);
	};

	propsHistoryListener = location => {
		const subTab = location.pathname.substring(location.pathname.lastIndexOf('/') + 1, location.pathname.length);
		if (this.props.patientRpmProgram ? !this.state.healthDataRpm[subTab] : !HealthDataTabs[subTab]) {
			return;
		}
		if (this.props.patientRpmProgram) {
			const { type, parentType } = this.state.healthDataRpm[subTab];
			this.toggleActiveTab(parentType, false);
			this.setState({ currentTab: type });
		} else {
			const { type, parentType } = HealthDataTabs[subTab];
			this.toggleActiveTab(parentType, false);
			this.setState({ currentTab: type });
		}
	};

	handleOnClick = type => {
		if (this.props.isFromPrimaryCare || this.props.isCallView) {
			return;
		}
		if (this.props.isDoctorView) {
			this.props.history.push(`/patients/${this.props.selectedPatient.userId}/health-data/${type}`);
		} else {
			this.props.history.push(`/health-data/${type}`);
		}
	};

	toggleActiveTab = (type, isToggle = true) => {
		this.setState(prevState => ({
			[`is${type}Active`]: isToggle ? !prevState[`is${type}Active`] : true,
		}));
	};

	handleTabChange = index => {
		this.setState({ currentTab: index });
		let type = null;
		if (this.props.patientRpmProgram) {
			type = Object.keys(this.state.healthDataRpm)[index];
		} else {
			type = Object.keys(HealthDataTabs)[index];
		}
		this.handleOnClick(type);
	};

	render() {
		return (
			<div className='iot-devices-history iot-devices-history-patient'>
				<div className={`expand-collapse-health-wrapper ${!this.state.isExpanded ? 'collapsed-health-wrapper' : ''}`}>
					<i
						className='material-icons-outlined'
						onClick={() => this.setState(prevState => ({ isExpanded: !prevState.isExpanded }))}>
						{this.state.isExpanded ? 'keyboard_arrow_left' : 'keyboard_arrow_right'}
					</i>
				</div>
				<Tabs
					activeIndex={this.state.currentTab}
					onChange={index => this.handleTabChange(index)}
					isVertical={true}
					className={!this.state.isExpanded ? 'collapsed-tabs' : ''}>
					<TabList>
						<Tab>
							<img src={`${this.linkToHealthDataIcons}summary.svg`} alt='icon' />
							{this.state.isExpanded && translate('summary')}
						</Tab>
						{(this.state.measurementTypes.includes(MeasurementTypes.HEART_RATE) ||
							this.state.measurementTypes.includes(MeasurementTypes.BLOOD_PRESSURE) ||
							!this.props.patientRpmProgram) && (
							<div
								id='heart-dropdown'
								className={`cursor-pointer vertical-list-dropdown vertical-list-button${
									this.state[`is${HealthDataTabs.heart.type}Active`] ? ' active' : ''
								}`}
								onClick={() => this.toggleActiveTab(HealthDataTabs.heart.type)}>
								<img src={`${this.linkToHealthDataIcons}heart.svg`} alt='icon' />
								{this.state.isExpanded && translate('heart')}
								<i className='material-icons'>
									{this.state[`is${HealthDataTabs.heart.type}Active`] ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
								</i>
							</div>
						)}
						{(this.state.measurementTypes.includes(MeasurementTypes.HEART_RATE) || !this.props.patientRpmProgram) && (
							<Tab className='heart-tab vertical-list-dropdown-item'>
								<img src={`${this.linkToHealthDataIcons}heart.svg`} alt='icon' />
								{this.state.isExpanded && translate('heartRate')}
							</Tab>
						)}
						{(this.state.measurementTypes.includes(MeasurementTypes.BLOOD_PRESSURE) || !this.props.patientRpmProgram) && (
							<Tab className='heart-tab vertical-list-dropdown-item'>
								<img src={`${this.linkToHealthDataIcons}heart.svg`} alt='icon' />
								{this.state.isExpanded && translate('bloodPressure')}
							</Tab>
						)}
						{(this.state.measurementTypes.includes(MeasurementTypes.HEART_RATE) || !this.props.patientRpmProgram) && (
							<Tab className={`heart-tab vertical-list-dropdown-item ${this.props.isGalaxyWatch ? 'display-none' : ''}`}>
								<img src={`${this.linkToHealthDataIcons}heart.svg`} alt='icon' />
								{this.state.isExpanded && translate('perfusionIndex')}
							</Tab>
						)}
						{!this.props.patientRpmProgram && (
							<div
								id='activity-dropdown'
								className={`cursor-pointer vertical-list-dropdown vertical-list-button${
									this.state[`is${HealthDataTabs.activity.type}Active`] ? ' active' : ''
								}`}
								onClick={() => this.toggleActiveTab(HealthDataTabs.activity.type)}>
								<img src={`${this.linkToHealthDataIcons}activity.svg`} alt='icon' />{' '}
								{this.state.isExpanded && translate('activity')}
								<i className='material-icons'>
									{this.state[`is${HealthDataTabs.activity.type}Active`] ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
								</i>
							</div>
						)}
						{!this.props.patientRpmProgram && (
							<Tab className='activity-tab vertical-list-dropdown-item'>
								<img src={`${this.linkToHealthDataIcons}activity.svg`} alt='icon' />
								{this.state.isExpanded && translate('steps')}
							</Tab>
						)}
						{!this.props.patientRpmProgram && (
							<Tab className={`activity-tab vertical-list-dropdown-item ${this.props.isGalaxyWatch ? 'display-none' : ''}`}>
								<img src={`${this.linkToHealthDataIcons}activity.svg`} alt='icon' />
								{this.state.isExpanded && translate('flightsClimbed')}
							</Tab>
						)}
						{!this.props.patientRpmProgram && (
							<Tab className='activity-tab vertical-list-dropdown-item'>
								<img src={`${this.linkToHealthDataIcons}activity.svg`} alt='icon' />
								{this.state.isExpanded && translate('walkingRunningDistance')}
							</Tab>
						)}

						{(this.state.measurementTypes.includes(MeasurementTypes.WEIGHT) ||
							this.state.measurementTypes.includes(MeasurementTypes.TEMPERATURE) ||
							!this.props.patientRpmProgram) && (
							<div
								id='body-measurements-dropdown'
								className={classNames(
									`cursor-pointer vertical-list-dropdown vertical-list-button${
										this.state[`is${HealthDataTabs.bodyMeasurements.type}Active`] ? ' active' : ''
									}`,
									this.props.isGalaxyWatch ? 'display-none' : ''
								)}
								onClick={() => this.toggleActiveTab(HealthDataTabs.bodyMeasurements.type)}>
								<img src={`${this.linkToHealthDataIcons}body-measurements.svg`} alt='icon' />
								{this.state.isExpanded && translate('bodyMeasurements')}

								<i className='material-icons'>
									{this.state[`is${HealthDataTabs.bodyMeasurements.type}Active`] ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
								</i>
							</div>
						)}
						{(this.state.measurementTypes.includes(MeasurementTypes.WEIGHT) || !this.props.patientRpmProgram) && (
							<Tab
								className={`body-measurements-tab vertical-list-dropdown-item ${this.props.isGalaxyWatch ? 'display-none' : ''}`}>
								<img src={`${this.linkToHealthDataIcons}body-measurements.svg`} alt='icon' />
								{this.state.isExpanded && translate('weight')}
							</Tab>
						)}
						{(this.state.measurementTypes.includes(MeasurementTypes.TEMPERATURE) || !this.props.patientRpmProgram) && (
							<Tab
								className={`body-measurements-tab vertical-list-dropdown-item ${this.props.isGalaxyWatch ? 'display-none' : ''}`}>
								<img src={`${this.linkToHealthDataIcons}body-measurements.svg`} alt='icon' />
								{this.state.isExpanded && translate('bodyTemperature')}
							</Tab>
						)}
						{(this.state.measurementTypes.includes(MeasurementTypes.WEIGHT) || !this.props.patientRpmProgram) && (
							<Tab
								className={`body-measurements-tab vertical-list-dropdown-item ${this.props.isGalaxyWatch ? 'display-none' : ''}`}>
								<img src={`${this.linkToHealthDataIcons}body-measurements.svg`} alt='icon' />
								{this.state.isExpanded && translate('leanBodyMass')}
							</Tab>
						)}
						{(this.state.measurementTypes.includes(MeasurementTypes.WEIGHT) || !this.props.patientRpmProgram) && (
							<Tab
								className={`body-measurements-tab vertical-list-dropdown-item ${this.props.isGalaxyWatch ? 'display-none' : ''}`}>
								<img src={`${this.linkToHealthDataIcons}body-measurements.svg`} alt='icon' />
								{this.state.isExpanded && translate('bodyFatPercentage')}
							</Tab>
						)}
						{(this.state.measurementTypes.includes(MeasurementTypes.WEIGHT) || !this.props.patientRpmProgram) && (
							<Tab
								className={`body-measurements-tab vertical-list-dropdown-item ${this.props.isGalaxyWatch ? 'display-none' : ''}`}>
								<img src={`${this.linkToHealthDataIcons}body-measurements.svg`} alt='icon' />
								{this.state.isExpanded && translate('bmi')}
							</Tab>
						)}
						{(this.state.measurementTypes.includes(MeasurementTypes.WEIGHT) || !this.props.patientRpmProgram) && (
							<Tab
								className={`body-measurements-tab vertical-list-dropdown-item ${this.props.isGalaxyWatch ? 'display-none' : ''}`}>
								<img src={`${this.linkToHealthDataIcons}body-measurements.svg`} alt='icon' />
								{this.state.isExpanded && translate('waistCircumference')}
							</Tab>
						)}

						{(this.state.measurementTypes.includes(MeasurementTypes.OXYGEN) || !this.props.patientRpmProgram) && (
							<div
								id='respiratory-dropdown'
								className={`cursor-pointer vertical-list-dropdown vertical-list-button${
									this.state[`is${HealthDataTabs.respiratory.type}Active`] ? ' active' : ''
								}`}
								onClick={() => this.toggleActiveTab(HealthDataTabs.respiratory.type)}>
								<img src={`${this.linkToHealthDataIcons}respiratory.svg`} alt='icon' />
								{this.state.isExpanded && translate('respiratory')}

								<i className='material-icons'>
									{this.state[`is${HealthDataTabs.respiratory.type}Active`] ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
								</i>
							</div>
						)}
						{(this.state.measurementTypes.includes(MeasurementTypes.OXYGEN) || !this.props.patientRpmProgram) && (
							<Tab className='respiratory-tab vertical-list-dropdown-item'>
								<img src={`${this.linkToHealthDataIcons}respiratory.svg`} alt='icon' />
								{this.state.isExpanded && translate('oxygenSaturation')}
							</Tab>
						)}
						{!this.props.patientRpmProgram && (
							<Tab className={`respiratory-tab vertical-list-dropdown-item ${this.props.isGalaxyWatch ? 'display-none' : ''}`}>
								<img src={`${this.linkToHealthDataIcons}respiratory.svg`} alt='icon' />
								{this.state.isExpanded && translate('respiratoryRate')}
							</Tab>
						)}
						{!this.props.patientRpmProgram && (
							<div
								id='sleep-dropdown'
								className={`cursor-pointer vertical-list-dropdown vertical-list-button${
									this.state[`is${HealthDataTabs.sleep.type}Active`] ? ' active' : ''
								}`}
								onClick={() => this.toggleActiveTab(HealthDataTabs.sleep.type)}>
								<img src={`${this.linkToHealthDataIcons}sleep.svg`} alt='icon' />
								{this.state.isExpanded && translate('sleep')}
								<i className='material-icons'>
									{this.state[`is${HealthDataTabs.sleep.type}Active`] ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
								</i>
							</div>
						)}
						{!this.props.patientRpmProgram && (
							<Tab className='sleep-tab vertical-list-dropdown-item'>
								<img src={`${this.linkToHealthDataIcons}sleep.svg`} alt='icon' />
								{this.state.isExpanded && translate('sleepAnalysis')}
							</Tab>
						)}

						{(this.state.measurementTypes.includes(MeasurementTypes.DIABETES) || !this.props.patientRpmProgram) && (
							<Tab className={`other-data-tab vertical-list-dropdown-item ${this.props.isGalaxyWatch ? 'display-none' : ''}`}>
								<img src={`${this.linkToHealthDataIcons}blood-glucose.svg`} alt='icon' />
								{this.state.isExpanded && translate('bloodGlucose')}
							</Tab>
						)}
						{(this.state.measurementTypes.includes(MeasurementTypes.STETHOSCOPE) || !this.props.patientRpmProgram) && (
							<div
								id='stethoscope-dropdown'
								className={classNames(
									`cursor-pointer vertical-list-dropdown vertical-list-button${
										this.state[`is${HealthDataTabs.stethoscope.type}Active`] ? ' active' : ''
									}`,
									this.props.isGalaxyWatch ? 'display-none' : ''
								)}
								onClick={() => this.toggleActiveTab(HealthDataTabs.stethoscope.type)}>
								<img src={`${this.linkToHealthDataIcons}stethoscope.svg`} alt='icon' />
								{this.state.isExpanded && translate('stethoscope')}
								<i className='material-icons'>
									{this.state[`is${HealthDataTabs.stethoscope.type}Active`] ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
								</i>
							</div>
						)}
						{(this.state.measurementTypes.includes(MeasurementTypes.STETHOSCOPE) || !this.props.patientRpmProgram) && (
							<Tab className={`heart-lungs-tab vertical-list-dropdown-item ${this.props.isGalaxyWatch ? 'display-none' : ''}`}>
								<img src={`${this.liveExaminationLink}heart-1.svg`} alt='icon' />
								{this.state.isExpanded ? translate('heart') : ''}
							</Tab>
						)}
						{(this.state.measurementTypes.includes(MeasurementTypes.STETHOSCOPE) || !this.props.patientRpmProgram) && (
							<Tab className={`heart-lungs-tab vertical-list-dropdown-item ${this.props.isGalaxyWatch ? 'display-none' : ''}`}>
								<img src={`${this.liveExaminationLink}lungs-1.svg`} alt='icon' />
								{this.state.isExpanded ? translate('lungs') : ''}
							</Tab>
						)}

						{!this.props.patientRpmProgram && (
							<Tab className={`other-data-tab vertical-list-dropdown-item ${this.props.isGalaxyWatch ? 'display-none' : ''}`}>
								<img src={`${this.linkToHealthDataIcons}number-of-times-fallen.svg`} alt='icon' />
								{this.state.isExpanded && translate('numberTimesFallen')}
							</Tab>
						)}
						{!this.props.patientRpmProgram && (
							<div
								id='cholesterol-dropdown'
								className={classNames(
									`cursor-pointer vertical-list-dropdown vertical-list-button${
										this.state[`is${HealthDataTabs.cholesterol.type}Active`] ? ' active' : ''
									}`,
									this.props.isGalaxyWatch ? 'display-none' : ''
								)}
								onClick={() => this.toggleActiveTab(HealthDataTabs.cholesterol.type)}>
								<img src={`${this.linkToHealthDataIcons}blood-glucose.svg`} alt='icon' />
								{this.state.isExpanded && translate('cholesterol')}
								<i className='material-icons'>
									{this.state[`is${HealthDataTabs.cholesterol.type}Active`] ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
								</i>
							</div>
						)}
						{!this.props.patientRpmProgram && (
							<Tab className={`cholesterol-tab vertical-list-dropdown-item ${this.props.isGalaxyWatch ? 'display-none' : ''}`}>
								<img src={`${this.linkToHealthDataIcons}blood-glucose.svg`} alt='icon' />
								{this.state.isExpanded && translate('totalCholesterol')}
							</Tab>
						)}
						{!this.props.patientRpmProgram && (
							<Tab className={`cholesterol-tab vertical-list-dropdown-item ${this.props.isGalaxyWatch ? 'display-none' : ''}`}>
								<img src={`${this.linkToHealthDataIcons}blood-glucose.svg`} alt='icon' />
								{this.state.isExpanded && translate('hdlCholesterol')}
							</Tab>
						)}

						{!this.props.patientRpmProgram && (
							<div
								id='otoscope-dropdown'
								className={classNames(
									`cursor-pointer vertical-list-dropdown vertical-list-button${
										this.state[`is${HealthDataTabs.otoscope.type}Active`] ? ' active' : ''
									}`,
									this.props.isGalaxyWatch ? 'display-none' : ''
								)}
								onClick={() => this.toggleActiveTab(HealthDataTabs.otoscope.type)}>
								<img src={`${this.linkToHealthDataIcons}otoscope.svg`} alt='icon' />
								{this.state.isExpanded && translate('otoscope')}
								<i className='material-icons'>
									{this.state[`is${HealthDataTabs.otoscope.type}Active`] ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
								</i>
							</div>
						)}
						{!this.props.patientRpmProgram && (
							<Tab className={`ear-throat-tab vertical-list-dropdown-item ${this.props.isGalaxyWatch ? 'display-none' : ''}`}>
								<img src={`${this.liveExaminationLink}ear-1.svg`} alt='icon' />
								{this.state.isExpanded ? translate('ear') : ''}
							</Tab>
						)}
						{!this.props.patientRpmProgram && (
							<Tab className={`ear-throat-tab vertical-list-dropdown-item ${this.props.isGalaxyWatch ? 'display-none' : ''}`}>
								<img src={`${this.liveExaminationLink}throat-1.svg`} alt='icon' />
								{this.state.isExpanded ? translate('throat') : ''}
							</Tab>
						)}

						{!this.props.patientRpmProgram && (
							<div
								id='dermatoscope-dropdown'
								className={`cursor-pointer vertical-list-dropdown vertical-list-button${
									this.state[`is${HealthDataTabs.dermatoscope.type}Active`] ? ' active' : ''
								}`}
								onClick={() => this.toggleActiveTab(HealthDataTabs.dermatoscope.type)}>
								<img src={`${this.linkToHealthDataIcons}dermatoscope.svg`} alt='icon' />{' '}
								{this.state.isExpanded && translate('dermatoscope')}
								<i className='material-icons'>
									{this.state[`is${HealthDataTabs.dermatoscope.type}Active`] ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
								</i>
							</div>
						)}
						{!this.props.patientRpmProgram && (
							<Tab className={`skin-tab vertical-list-dropdown-item ${this.props.isGalaxyWatch ? 'display-none' : ''}`}>
								<img src={`${this.liveExaminationLink}skin-1.svg`} alt='icon' />
								{this.state.isExpanded ? translate('skin') : ''}
							</Tab>
						)}

						{!this.props.patientRpmProgram && (
							<Tab className={`other-data-tab vertical-list-dropdown-item ${this.props.isGalaxyWatch ? 'display-none' : ''}`}>
								<img src={`${this.linkToHealthDataIcons}spirometer.svg`} alt='icon' />
								{this.state.isExpanded && translate('spirometer')}
							</Tab>
						)}
					</TabList>
					<TabPanels>
						<TabPanel>
							<HealthDataSummary
								patientRpmProgram={this.props.patientRpmProgram}
								measurementTypeArray={this.state.measurementTypes}
								title='Summary'
								icon={`${healthCareCdnUrl}health-data/summary.svg?v5`}
								selectedPatient={this.props.selectedPatient}
								isCallView={this.props.isCallView}
								isGalaxyWatch={this.props.isGalaxyWatch}
								isFromPrimaryCare={this.props.isFromPrimaryCare}
								isDoctorView={this.props.isDoctorView}
								isHealthShown={this.props.isHealthShown}
							/>
						</TabPanel>
						{(this.state.measurementTypes.includes(MeasurementTypes.HEART_RATE) ||
							this.state.measurementTypes.includes(MeasurementTypes.BLOOD_PRESSURE) ||
							!this.props.patientRpmProgram) && <TabPanel />}
						{(this.state.measurementTypes.includes(MeasurementTypes.HEART_RATE) || !this.props.patientRpmProgram) && (
							<TabPanel>
								<HealthDataTabContent
									measurement={getMeasurementDetails(MeasurementTypes.HEART_RATE, this.props.intl)}
									key={1}
									selectedPatient={this.props.selectedPatient}
									isCallView={this.props.isCallView}
								/>
							</TabPanel>
						)}
						{(this.state.measurementTypes.includes(MeasurementTypes.BLOOD_PRESSURE) || !this.props.patientRpmProgram) && (
							<TabPanel>
								<HealthDataTabContent
									measurement={getMeasurementDetails(MeasurementTypes.BLOOD_PRESSURE, this.props.intl)}
									key={2}
									selectedPatient={this.props.selectedPatient}
									isCallView={this.props.isCallView}
								/>
							</TabPanel>
						)}
						{(this.state.measurementTypes.includes(MeasurementTypes.HEART_RATE) || !this.props.patientRpmProgram) && (
							<TabPanel>
								<HealthDataTabContent
									measurement={getMeasurementDetails(MeasurementTypes.PI, this.props.intl)}
									key={3}
									selectedPatient={this.props.selectedPatient}
									isCallView={this.props.isCallView}
								/>
							</TabPanel>
						)}
						{!this.props.patientRpmProgram && <TabPanel />}
						{!this.props.patientRpmProgram && (
							<TabPanel>
								<HealthDataTabContent
									measurement={getMeasurementDetails(MeasurementTypes.STEPS, this.props.intl)}
									key={4}
									selectedPatient={this.props.selectedPatient}
									isCallView={this.props.isCallView}
								/>
							</TabPanel>
						)}
						{!this.props.patientRpmProgram && (
							<TabPanel>
								<HealthDataTabContent
									measurement={getMeasurementDetails(MeasurementTypes.FLIGHTS_CLIMBED, this.props.intl)}
									key={5}
									selectedPatient={this.props.selectedPatient}
									isCallView={this.props.isCallView}
								/>
							</TabPanel>
						)}
						{!this.props.patientRpmProgram && (
							<TabPanel>
								<HealthDataTabContent
									measurement={getMeasurementDetails(
										MeasurementTypes.DISTANCE_WALKING_RUNNING,
										this.props.intl,
										this.getUnitPreference
									)}
									key={6}
									selectedPatient={this.props.selectedPatient}
									isCallView={this.props.isCallView}
								/>
							</TabPanel>
						)}
						{(this.state.measurementTypes.includes(MeasurementTypes.WEIGHT) ||
							this.state.measurementTypes.includes(MeasurementTypes.TEMPERATURE) ||
							!this.props.patientRpmProgram) && <TabPanel />}

						{(this.state.measurementTypes.includes(MeasurementTypes.WEIGHT) || !this.props.patientRpmProgram) && (
							<TabPanel>
								<HealthDataTabContent
									measurement={getMeasurementDetails(MeasurementTypes.WEIGHT, this.props.intl, this.getUnitPreference)}
									key={7}
									selectedPatient={this.props.selectedPatient}
									isCallView={this.props.isCallView}
								/>
							</TabPanel>
						)}
						{(this.state.measurementTypes.includes(MeasurementTypes.TEMPERATURE) || !this.props.patientRpmProgram) && (
							<TabPanel>
								<HealthDataTabContent
									measurement={getMeasurementDetails(MeasurementTypes.TEMPERATURE, this.props.intl, this.getUnitPreference)}
									key={8}
									selectedPatient={this.props.selectedPatient}
									isCallView={this.props.isCallView}
								/>
							</TabPanel>
						)}
						{(this.state.measurementTypes.includes(MeasurementTypes.WEIGHT) || !this.props.patientRpmProgram) && (
							<TabPanel>
								<HealthDataTabContent
									measurement={getMeasurementDetails(MeasurementTypes.LEAN_BODY_MASS, this.props.intl, this.getUnitPreference)}
									key={9}
									selectedPatient={this.props.selectedPatient}
									isCallView={this.props.isCallView}
								/>
							</TabPanel>
						)}
						{(this.state.measurementTypes.includes(MeasurementTypes.WEIGHT) || !this.props.patientRpmProgram) && (
							<TabPanel>
								<HealthDataTabContent
									measurement={getMeasurementDetails(MeasurementTypes.BODY_FAT, this.props.intl)}
									key={10}
									selectedPatient={this.props.selectedPatient}
									isCallView={this.props.isCallView}
								/>
							</TabPanel>
						)}
						{(this.state.measurementTypes.includes(MeasurementTypes.WEIGHT) || !this.props.patientRpmProgram) && (
							<TabPanel>
								<HealthDataTabContent
									measurement={getMeasurementDetails(MeasurementTypes.BODY_MASS_INDEX, this.props.intl)}
									key={11}
									selectedPatient={this.props.selectedPatient}
									isCallView={this.props.isCallView}
								/>
							</TabPanel>
						)}
						{(this.state.measurementTypes.includes(MeasurementTypes.WEIGHT) || !this.props.patientRpmProgram) && (
							<TabPanel>
								<HealthDataTabContent
									measurement={getMeasurementDetails(
										MeasurementTypes.WAIST_CIRCUMFERENCE,
										this.props.intl,
										this.getUnitPreference
									)}
									key={12}
									selectedPatient={this.props.selectedPatient}
									isCallView={this.props.isCallView}
								/>
							</TabPanel>
						)}
						{(this.state.measurementTypes.includes(MeasurementTypes.OXYGEN) || !this.props.patientRpmProgram) && <TabPanel />}
						{(this.state.measurementTypes.includes(MeasurementTypes.OXYGEN) || !this.props.patientRpmProgram) && (
							<TabPanel>
								<HealthDataTabContent
									measurement={getMeasurementDetails(MeasurementTypes.OXYGEN, this.props.intl)}
									key={13}
									selectedPatient={this.props.selectedPatient}
									isCallView={this.props.isCallView}
								/>
							</TabPanel>
						)}
						{!this.props.patientRpmProgram && (
							<TabPanel>
								<HealthDataTabContent
									measurement={getMeasurementDetails(MeasurementTypes.RESPIRATORY_RATE, this.props.intl)}
									key={14}
									selectedPatient={this.props.selectedPatient}
									isCallView={this.props.isCallView}
								/>
							</TabPanel>
						)}
						{!this.props.patientRpmProgram && <TabPanel />}
						{!this.props.patientRpmProgram && (
							<TabPanel>
								<HealthDataTabContent
									measurement={getMeasurementDetails(MeasurementTypes.SLEEP, this.props.intl)}
									key={15}
									selectedPatient={this.props.selectedPatient}
									isCallView={this.props.isCallView}
								/>
							</TabPanel>
						)}
						{(this.state.measurementTypes.includes(MeasurementTypes.DIABETES) || !this.props.patientRpmProgram) && (
							<TabPanel>
								<HealthDataTabContent
									measurement={getMeasurementDetails(MeasurementTypes.DIABETES, this.props.intl, this.getUnitPreference)}
									key={16}
									selectedPatient={this.props.selectedPatient}
									isCallView={this.props.isCallView}
								/>
							</TabPanel>
						)}
						{(this.state.measurementTypes.includes(MeasurementTypes.STETHOSCOPE) || !this.props.patientRpmProgram) && (
							<TabPanel />
						)}
						{(this.state.measurementTypes.includes(MeasurementTypes.STETHOSCOPE) || !this.props.patientRpmProgram) && (
							<TabPanel>
								<HeartLungsMeasurements isHeartMeasurement={true} selectedPatient={this.props.selectedPatient} />
							</TabPanel>
						)}
						{(this.state.measurementTypes.includes(MeasurementTypes.STETHOSCOPE) || !this.props.patientRpmProgram) && (
							<TabPanel>
								<HeartLungsMeasurements isLungsMeasurement={true} selectedPatient={this.props.selectedPatient} />
							</TabPanel>
						)}
						{!this.props.patientRpmProgram && (
							<TabPanel>
								<HealthDataTabContent
									measurement={getMeasurementDetails(MeasurementTypes.FALLEN_TIMES, this.props.intl)}
									key={18}
									selectedPatient={this.props.selectedPatient}
									isCallView={this.props.isCallView}
								/>
							</TabPanel>
						)}
						{!this.props.patientRpmProgram && <TabPanel />}
						{!this.props.patientRpmProgram && (
							<TabPanel>
								<HealthDataTabContent
									measurement={getMeasurementDetails(MeasurementTypes.TOTAL_CHOLESTEROL, this.props.intl, this.getUnitPreference)}
									key={19}
									selectedPatient={this.props.selectedPatient}
									isCallView={this.props.isCallView}
								/>
							</TabPanel>
						)}
						{!this.props.patientRpmProgram && (
							<TabPanel>
								<HealthDataTabContent
									measurement={getMeasurementDetails(MeasurementTypes.HDL_CHOLESTEROL, this.props.intl, this.getUnitPreference)}
									key={20}
									selectedPatient={this.props.selectedPatient}
									isCallView={this.props.isCallView}
								/>
							</TabPanel>
						)}
						{!this.props.patientRpmProgram && <TabPanel />}
						{!this.props.patientRpmProgram && (
							<TabPanel>
								<OtoDermatoscopeMeasurements
									selectedPatient={this.props.selectedPatient}
									measurementType={MeasurementTypes.EAR}
								/>
							</TabPanel>
						)}
						{!this.props.patientRpmProgram && (
							<TabPanel>
								<OtoDermatoscopeMeasurements
									selectedPatient={this.props.selectedPatient}
									measurementType={MeasurementTypes.THROAT}
								/>
							</TabPanel>
						)}
						{!this.props.patientRpmProgram && <TabPanel />}
						{!this.props.patientRpmProgram && (
							<TabPanel>
								<OtoDermatoscopeMeasurements
									selectedPatient={this.props.selectedPatient}
									measurementType={MeasurementTypes.SKIN}
								/>
							</TabPanel>
						)}
						{!this.props.patientRpmProgram && (
							<TabPanel>
								<SpirometerHistory selectedPatient={this.props.selectedPatient} />
							</TabPanel>
						)}
					</TabPanels>
				</Tabs>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		unitPreferences: state.user.unitPreferences,
	};
};

export default connect(mapStateToProps)(injectIntl(withRouter(HealthData)));
