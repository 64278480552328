import { useEffect, useState } from 'react';
import { AiConfigurationDetails } from 'components/AI/Monitoring/Configurations/index.js';
import { updatePatientAiSetting } from 'api/patients.js';
import Alert from 'components/Common/Alert.jsx';
import { useSelector } from 'react-redux';
import { AiFieldToUpdate, CheckBoxTypes, RadioButtonTypes } from 'constants/ai.jsx';
import _ from 'lodash';

const AiCommonConfigurations = ({ deviceId, patientId, selectedItem, roomId, adminAiConfigs, isRounding }) => {
	const [checkedItems, setCheckedItems] = useState([]);
	const [radioButtons, setRadioButtons] = useState([]);
	const [isItemClicked, setIsItemClicked] = useState(false);
	const [error, setError] = useState(null);
	const [selectedAiSetting, setSelectedAiSetting] = useState(null);
	const aiSettingList = useSelector(state => state.aiSettingsList.aiSettings);

	const getFilteredArr = (arr, numbers) =>
		arr.reduce((acc, item) => {
			if (item.notificationOptions && item.settingTypeId === selectedItem?.aiTypeId) {
				acc.push(...item.notificationOptions.filter(num => numbers.includes(num)));
			}
			return acc;
		}, []);

	useEffect(() => {
		const aiSettingListClone = _.cloneDeep(aiSettingList);
		const foundDevice = aiSettingListClone.find(item => item.deviceId === deviceId);
		if (!foundDevice) {
			return;
		}
		const foundAiSettings = foundDevice.settings.reduce((acc, item) => {
			if (item.settingTypeId === selectedItem.aiTypeId) {
				acc.push({ settingTypeId: item.settingTypeId, notificationOptions: item.notificationOptions, id: item.id });
			}
			return acc;
		}, []);

		const radioButtons = getFilteredArr(foundAiSettings, RadioButtonTypes);
		const checkedItems = getFilteredArr(foundAiSettings, CheckBoxTypes);
		setRadioButtons([...new Set(radioButtons)]);
		setCheckedItems([...new Set(checkedItems)]);
		setSelectedAiSetting(foundAiSettings.length > 0 ? foundAiSettings[0] : null);
	}, [deviceId, selectedItem, aiSettingList]);

	const getMergedArr = () => [...new Set([...checkedItems, ...radioButtons])];

	useEffect(() => {
		const mergedArr = getMergedArr();
		const updateAiConfiguration = async () => {
			setError(null);
			const params = {
				patientId,
				deviceId,
				roomId,
				fieldToUpdate: AiFieldToUpdate.NOTIFICATION_OPTIONS,
				value: mergedArr,
				id: selectedAiSetting?.id,
			};

			const response = await updatePatientAiSetting(params);
			if (response.error) {
				setError(response.error.message);
			}
		};
		if (mergedArr.length > 0) {
			updateAiConfiguration();
		}
	}, [isItemClicked]);

	return (
		<>
			<AiConfigurationDetails
				setCheckedItems={setCheckedItems}
				setRadioButtons={setRadioButtons}
				setIsItemClicked={setIsItemClicked}
				checkedItems={checkedItems}
				radioButtons={radioButtons}
				settingTypeId={selectedItem.aiTypeId}
				aiSettingList={aiSettingList}
				deviceId={deviceId}
				patientId={patientId}
				roomId={roomId}
				adminAiConfigs={adminAiConfigs}
				isRounding={isRounding}
			/>

			<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
		</>
	);
};

export default AiCommonConfigurations;
