import { healthCareCdnUrl } from 'constants/global-variables.js';
import { useIntl } from 'react-intl';

const AiThreeVitalSigns = ({ vitalSigns }) => {
	const intl = useIntl();
	const filteredVitalSigns = vitalSigns.slice(0, 3);
	return (
		<div className='flex vital-signs-ai'>
			{filteredVitalSigns.map(vitalSign => (
				<div key={vitalSign.id} className='flex vital-signs-details'>
					<img src={`${healthCareCdnUrl}vital-signs/ai/${vitalSign.img}.svg`} alt='icon' />
					<div className='flex column-direction left-10'>
						<span>{vitalSign.label}</span>
						<div className='flex'>
							{vitalSign.result !== `${intl.formatMessage({ id: 'measuring' })}...` && (
								<span className='right-s'>{vitalSign.result}</span>
							)}
							{vitalSign.result === `${intl.formatMessage({ id: 'measuring' })}...` && <span className='right-s'>...</span>}
							<span>{vitalSign.unit}</span>
						</div>
					</div>
				</div>
			))}
		</div>
	);
};

export default AiThreeVitalSigns;
