import Button from 'components/Common/Button.jsx';
import { DayPeriodTypes } from 'constants/enums.js';
import { useEffect, useState } from 'react';

const TimePicker = ({ handleTimeChange, value, index = 0, disableOption = true, className = null }) => {
	const defaultTime = value ? new Date(value) : new Date();
	const defaultHours24h = value ? defaultTime.getHours() : 0;
	const [hours, setHours] = useState(value ? defaultHours24h % 12 : null);
	const [minutes, setMinutes] = useState(value ? defaultTime.getMinutes() : null);
	const [seconds, setSeconds] = useState(value ? defaultTime.getSeconds() : null);
	const [dayPeriod, setDayPeriod] = useState(
		value ? (defaultHours24h < 12 ? DayPeriodTypes.AM : DayPeriodTypes.PM) : DayPeriodTypes.AM
	);

	useEffect(() => {
		if (hours === null && minutes === null && seconds === null) {
			handleTimeChange(null);
		} else {
			handleTimeChange({ hours, minutes, seconds, dayPeriod });
		}
	}, [hours, minutes, seconds, dayPeriod]);

	useEffect(() => {
		if (value) {
			const newTime = new Date(value);
			const newHours24h = newTime.getHours();
			setHours(newHours24h % 12);
			setMinutes(newTime.getMinutes());
			setSeconds(newTime.getSeconds());
			setDayPeriod(newHours24h < 12 ? DayPeriodTypes.AM : DayPeriodTypes.PM);
		} else {
			setHours(null);
			setMinutes(null);
			setSeconds(null);
			setDayPeriod(DayPeriodTypes.AM);
		}
	}, [value]);

	const handleSelectChange = setter => e => {
		const newValue = e.target.value === '--' ? '' : +e.target.value;
		setter(newValue);
	};

	return (
		<div className={className}>
			<select
				name='hours'
				autoFocus
				tabIndex={index * 3 + 1}
				value={hours ?? ''}
				onChange={handleSelectChange(setHours)}
				disabled={disableOption && !value}>
				{hours === null && (
					<option hidden key={-1} value='--'>
						--
					</option>
				)}
				{[...Array(12)].map((_, i) => (
					<option key={i} value={(i + 1) % 12}>
						{(i + 1).toString().padStart(2, '0')}
					</option>
				))}
			</select>
			<select
				name='minutes'
				tabIndex={index * 3 + 2}
				value={minutes || ''}
				onChange={handleSelectChange(setMinutes)}
				disabled={disableOption && !value}>
				{minutes === null && (
					<option hidden key={-1} value='--'>
						--
					</option>
				)}
				{[...Array(60)].map((_, i) => (
					<option key={i} value={i}>
						{i.toString().padStart(2, '0')}
					</option>
				))}
			</select>
			<select
				name='seconds'
				tabIndex={index * 3 + 3}
				value={seconds || ''}
				onChange={handleSelectChange(setSeconds)}
				disabled={disableOption && !value}>
				{seconds === null && (
					<option hidden key={-1} value='--'>
						--
					</option>
				)}
				{[...Array(60)].map((_, i) => (
					<option key={i} value={i}>
						{i.toString().padStart(2, '0')}
					</option>
				))}
			</select>
			<div className='time-picker-day-period'>
				<Button
					className={dayPeriod === DayPeriodTypes.AM ? 'active' : ''}
					onClick={() => setDayPeriod(DayPeriodTypes.AM)}
					disabled={disableOption && !value}
					text='AM'
				/>
				<Button
					className={dayPeriod === DayPeriodTypes.PM ? 'active' : ''}
					onClick={() => setDayPeriod(DayPeriodTypes.PM)}
					disabled={disableOption && !value}
					text='PM'
					marginRight={0}
				/>
			</div>
		</div>
	);
};

export default TimePicker;
