import { CallWorkflowType } from 'constants/configurationEnums.js';
import { gatewayApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';

export const getAlertsNotifications = async ({ pageIndex, levelId, pageSize = 10, workflowType = CallWorkflowType.ROUNDING }) => {
	try {
		const { data } = await gatewayApi.get(
			`/v1/organizations/${getCompanyId()}/levels/${levelId}/ai-alerts?page-index=${pageIndex}&page-size=${pageSize}&workflow-type=${workflowType}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};
