import { getPatientsAlertConfigurations } from 'api/rpm.js';
import {
	EWSType,
	LevelOfConsciousness,
	RiskLevelValues,
	RiskLevels,
	SupplementOxygen,
	VitalSignsRiskLevel,
	HealthDataField,
} from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { HospitalAtHomeActiveTab } from 'constants/hospital-at-home.js';
import { MedicationStatus, RpmActiveTab, ScheduledSurveyType, SurveyType } from 'constants/rpm.js';
import { isValidGUID } from 'infrastructure/helpers/validationHelper.js';

const imageUrl = `${healthCareCdnUrl}vsm/`;

export const getScoreRisk = (score, isRedScore = false) => {
	if (score >= 0 && score <= 4) {
		return RiskLevels.LOW;
	}
	if (isRedScore || (score >= 5 && score <= 6)) {
		return RiskLevels.MODERATE;
	}
	if (score >= 7) {
		return RiskLevels.HIGH;
	}
	return 'N/A';
};

export const getEwsScoreRiskClassName = (score, isRedScore = false) => {
	if (score >= 0 && score <= 4) {
		return 'low-score-wrapper';
	}
	if (isRedScore || (score >= 5 && score <= 6)) {
		return 'mod-score-wrapper';
	}
	if (score >= 7) {
		return 'high-score-wrapper';
	}
	return '';
};

export const getScoreRiskInnerClassName = (score, isRedScore = false) => {
	if (score >= 0 && score <= 4) {
		return 'low-risk';
	}
	if (isRedScore || (score >= 5 && score <= 6)) {
		return 'mod-risk';
	}
	if (score >= 7) {
		return 'high-risk';
	}
	return '';
};

export const getImageBasedOnScore = (score, isRedScore = false, isDarkMode = false) => {
	let image = `${healthCareCdnUrl}ews-settings${isDarkMode ? '-white' : ''}.svg`;
	if (isRedScore) {
		image = `${imageUrl}score-orange.svg`;
		return image;
	}
	switch (getScoreRisk(score)) {
		case RiskLevels.LOW:
			image = `${imageUrl}score-blue.svg`;
			break;
		case RiskLevels.MODERATE:
			image = `${imageUrl}score-orange.svg`;
			break;
		case RiskLevels.HIGH:
			image = `${imageUrl}score-red.svg`;
			break;
		case 'N/A':
			image = `${healthCareCdnUrl}ews-settings${isDarkMode ? '-white' : ''}.svg`;
			break;
		default:
			break;
	}
	return image;
};

export const getVitalSignsScoreClassName = (score, isInnerDiv) => {
	switch (score) {
		case VitalSignsRiskLevel.LOW:
			return isInnerDiv ? 'low-risk' : 'low-risk-wrapper';
		case VitalSignsRiskLevel.MODERATE:
			return isInnerDiv ? 'mod-risk' : 'mod-risk-wrapper';
		case VitalSignsRiskLevel.HIGH:
			return isInnerDiv ? 'high-risk' : 'high-risk-wrapper';
		default:
			return '';
	}
};

export const getRadioButtonOptions = name =>
	[
		{
			id: 0,
			content: name === EWSType.SUPPLEMENT_OXYGEN ? 'No' : 'A',
			value: name === EWSType.SUPPLEMENT_OXYGEN ? SupplementOxygen.NO : LevelOfConsciousness.A,
		},
		{
			id: 1,
			content: name === EWSType.SUPPLEMENT_OXYGEN ? 'Yes' : 'VPU',
			value: name === EWSType.SUPPLEMENT_OXYGEN ? SupplementOxygen.YES : LevelOfConsciousness.VPU,
		},
	].filter(Boolean);

export const getSurveyType = survey => {
	switch (survey) {
		case SurveyType.GENERIC:
			return 'generic';
		case SurveyType.SAFETY_RANGE:
			return 'safetyRange';
		case SurveyType.SCHEDULED:
			return 'scheduled';
		default:
			return null;
	}
};

export const getScheduledSurveyType = survey => {
	switch (survey) {
		case ScheduledSurveyType.DAILY:
			return 'daily';
		case ScheduledSurveyType.WEEKLY:
			return 'weekly';
		case ScheduledSurveyType.MONTHLY:
			return 'monthly';
		default:
			return null;
	}
};

export const getBatteryImage = batteryPercentage => {
	if (batteryPercentage >= 0 && batteryPercentage <= 20) {
		return 'low-battery';
	}
	if (batteryPercentage > 20 && batteryPercentage < 90) {
		return 'half-battery';
	}
	if (batteryPercentage >= 90 && batteryPercentage <= 100) {
		return 'full-battery';
	}

	return '';
};

export const getBatteryColorBasedOnScore = (score, batteryLevel) => {
	const imageBase = getBatteryImage(batteryLevel);
	switch (score) {
		case RiskLevelValues.NORMAL:
			return `${imageBase}-blue`;
		case RiskLevelValues.MODERATE:
			return `${imageBase}-orange`;
		case RiskLevelValues.HIGH:
			return `${imageBase}-red`;
		default:
			return `${imageBase}`;
	}
};

export const getMedicationIcon = (status, isMedic = false) => {
	switch (status) {
		case MedicationStatus.SKIPPED:
			return isMedic ? 'red' : 'reject';
		case MedicationStatus.TAKEN:
			return isMedic ? 'green' : 'done';
		default:
			return isMedic ? 'gray' : 'default';
	}
};

export const getHospitalAtHomeClassName = index => {
	switch (index) {
		case HospitalAtHomeActiveTab.ALL_PATIENTS:
			return 'all-patients';
		case HospitalAtHomeActiveTab.MY_PATIENTS:
			return 'my-patients';
		case HospitalAtHomeActiveTab.HIGH_RISK:
			return 'high-risk';
		case HospitalAtHomeActiveTab.MODERATE_RISK:
			return 'moderate-risk';
		case HospitalAtHomeActiveTab.LOW_RISK:
			return 'low-risk';
		default:
			return 'all-patients';
	}
};

export const hasOnlyOneScoreOfThree = arr => {
	let scoreOfThreeCount = 0;
	const highScorePerVitalSign = 3;
	const result = arr.every(obj => {
		if (obj.earlyWarningScore === highScorePerVitalSign) {
			scoreOfThreeCount += 1;
		} else if (obj.earlyWarningScore && obj.earlyWarningScore !== 0) {
			return false;
		}

		return true;
	});

	return result && scoreOfThreeCount === 1;
};

export const getFilterBasedOnScoreLength = (list, score) => list?.filter(item => getScoreRisk(item?.score) === score).length;

export const getScoreClassName = (score, isInnerDiv = false) => {
	switch (score) {
		case VitalSignsRiskLevel.LOW:
			return isInnerDiv ? 'low-score' : 'low-score-wrapper';
		case VitalSignsRiskLevel.MODERATE:
			return isInnerDiv ? 'mod-score' : 'mod-score-wrapper';
		case VitalSignsRiskLevel.HIGH:
			return isInnerDiv ? 'high-score' : 'high-score-wrapper';
		default:
			return '';
	}
};

export const scrollToTop = refList => {
	if (!refList.current) {
		return;
	}
	refList.current.scrollTo({
		top: 0,
		behavior: 'smooth',
	});
};

export const getRpmTabsClassName = index => {
	switch (index) {
		case RpmActiveTab.ALL_PATIENTS:
			return 'all-patients';
		case RpmActiveTab.HIGH_RISK:
			return 'high-risk';
		case RpmActiveTab.MODERATE_RISK:
			return 'moderate-risk';
		case RpmActiveTab.LOW_RISK:
			return 'low-risk';
		default:
			return 'all-patients';
	}
};

export const getArrowColor = score => {
	switch (score) {
		case VitalSignsRiskLevel.LOW:
			return 'blue-arrow';
		case VitalSignsRiskLevel.MODERATE:
			return 'orange-arrow';
		case VitalSignsRiskLevel.HIGH:
			return 'red-arrow';
		default:
			return '';
	}
};

const getRiskLevelConditions = (measurementValue, val) => {
	const systolicValue = +measurementValue?.split('/')[0];
	const diastolicValue = +measurementValue?.split('/')[1];
	const vitalSignsDetails = [
		{
			ids: [HealthDataField.HIGH_SYSTOLIC, HealthDataField.CRITICAL_HIGH_SYSTOLIC],
			value: val.value,
			condition: systolicValue >= val.value,
			riskLevel: VitalSignsRiskLevel.HIGH,
		},
		{
			ids: [HealthDataField.LOW_SYSTOLIC, HealthDataField.CRITICAL_LOW_SYSTOLIC],
			value: val.value,
			condition: systolicValue <= val.value,
			riskLevel: VitalSignsRiskLevel.LOW,
		},
		{
			ids: [HealthDataField.HIGH_DIASTOLIC, HealthDataField.CRITICAL_HIGH_DIASTOLIC],
			value: val.value,
			condition: diastolicValue >= val.value,
			riskLevel: VitalSignsRiskLevel.HIGH,
		},
		{
			ids: [HealthDataField.LOW_DIASTOLIC, HealthDataField.CRITICAL_LOW_DIASTOLIC],
			value: val.value,
			condition: diastolicValue <= val.value,
			riskLevel: VitalSignsRiskLevel.LOW,
		},
		{
			ids: [
				HealthDataField.HIGH_BLOOD_GLUCOSE,
				HealthDataField.HIGH_BODY_TEMPERATURE,
				HealthDataField.HIGH_HEART_RATE,
				HealthDataField.CRITICAL_HIGH_BODY_TEMPERATURE,
			],
			value: val.value,
			condition: +measurementValue >= val.value,
			riskLevel: VitalSignsRiskLevel.HIGH,
		},
		{
			ids: [
				HealthDataField.LOW_BODY_TEMPERATURE,
				HealthDataField.LOW_HEART_RATE,
				HealthDataField.LOW_OXYGEN,
				HealthDataField.CRITICAL_LOW_OXYGEN,
			],
			value: val.value,
			condition: +measurementValue <= val.value,
			riskLevel: VitalSignsRiskLevel.LOW,
		},
	];
	return vitalSignsDetails;
};

export const getVitalSignColorBySafetyRanges = (safetyRanges, latestMeasurement) => {
	let riskLevel = null;

	safetyRanges.forEach(alertConfig => {
		const foundMeasurementType = latestMeasurement?.measurementUnit === alertConfig.unit;
		const measurementValue = latestMeasurement?.measurementValue;
		if (!foundMeasurementType) {
			return riskLevel;
		}
		alertConfig.fields.forEach(val => {
			getRiskLevelConditions(measurementValue, val).forEach(item => {
				if (item.ids.includes(val.id) && item.condition) {
					riskLevel = item.riskLevel;
				}
			});
		});
		return riskLevel;
	});

	return riskLevel;
};

export const fetchPatientsAlertConfigurations = async (patientIds, setError, setPatientSafetyRanges, healthSystemId) => {
	if (
		patientIds.length === 0 ||
		patientIds.length > 60 ||
		patientIds.some(id => !isValidGUID(id)) ||
		new Set(patientIds).size !== patientIds.length
	) {
		return;
	}
	const response = await getPatientsAlertConfigurations(healthSystemId, patientIds);
	if (response.error) {
		setError(response.error.message);
	} else {
		const rangesByPatient = response?.patientAlertsConfigurations?.reduce((acc, curr) => {
			acc[curr.patientId] = curr.alertConfigurations;
			return acc;
		}, {});
		setPatientSafetyRanges(prevState => ({
			...prevState,
			...rangesByPatient,
		}));
	}
};
