import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useRouteMatch } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { actionCreators as healthSystemsActionCreators } from 'state/healthSystems/actions.js';
import { SectorTypes, UserRoles } from 'constants/enums.js';
import Grid from 'components/Common/Grid.jsx';
import TreeView from 'components/Common/TreeView.jsx';
import Loader from 'components/Common/Loader.jsx';
import Input from 'components/Common/Input.jsx';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import { getUserRole } from 'infrastructure/auth.js';
import Breadcrumb from 'components/Common/Breadcrumb.jsx';
import translate from 'i18n-translations/translate.jsx';
import { searchSectors, showPath, buildTree, findSectorById } from 'infrastructure/helpers/commonHelpers.js';
import VisitorRoom from 'views/Partials/VisitorRoom.jsx';
import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';
import ListView from 'icons/TreeView/ListView.jsx';
import TreeViewIcon from 'icons/TreeView/TreeViewIcon.jsx';

const Visitors = () => {
	const [treeItems, setTreeItems] = useState({});
	const [helloDeviceId, setHelloDeviceId] = useState(null);
	const [roomId, setRoomId] = useState(null);
	const [sectorSearchValue, setSectorSearchValue] = useState('');
	const [expandAllTreeItems, setExpandAllTreeItems] = useState(false);
	const { current: userRole } = useRef(getUserRole());
	const healthSystems = useSelector(state => state.healthSystems);
	const darkMode = useSelector(state => state.user.darkMode);
	const [isLoadingView, setIsLoadingView] = useState(false);
	const dispatch = useDispatch();
	const intl = useIntl();
	const match = useRouteMatch();

	const getSelectedSectorId = useCallback(() => {
		// @ts-ignore
		const { healthSystem, department, floor, room } = match.params;
		return room || floor || department || healthSystem;
	}, [match.params]);

	useEffect(() => {
		// @ts-ignore
		const { healthSystem, department, floor, room } = match.params;
		const getExpandedOptions = () => {
			let treeObj = {};
			if (healthSystem) {
				treeObj = { [healthSystem]: {} };
			}
			if (department) {
				treeObj = {
					[healthSystem]: { [department]: {} },
				};
			}
			if (floor) {
				treeObj = {
					[healthSystem]: {
						[department]: { [floor]: {} },
					},
				};
			}
			if (room) {
				treeObj = {
					[healthSystem]: {
						[department]: {
							[floor]: { [room]: {} },
						},
					},
				};
			}
			return treeObj;
		};

		const foundSector = findSectorById(healthSystems.treeData.tree, getSelectedSectorId());
		setTreeItems(getExpandedOptions());
		if (foundSector && foundSector.type === SectorTypes.ROOM) {
			setHelloDeviceId(foundSector.helloDeviceId || null);
			setRoomId(foundSector.roomId);
		}
		const breadCrumb = foundSector ? foundSector.breadcrumb : [];
		dispatch(healthSystemsActionCreators.updateBreadcrumb(breadCrumb));
		return () => {
			healthSystemsActionCreators.updateBreadcrumb([]);
		};
	}, [dispatch, getSelectedSectorId, healthSystems.treeData, match.params]);

	useEffect(() => {
		if (healthSystems.selectedHealthSystem) {
			const treeData = buildTree(healthSystems.selectedHealthSystem, healthSystems.isRoomsOnlyView);
			dispatch(healthSystemsActionCreators.setTreeData(treeData));
		}
	}, [dispatch, healthSystems.isRoomsOnlyView, healthSystems.selectedHealthSystem]);

	useEffect(() => {
		const sectors = searchSectors(healthSystems.treeData.tree, sectorSearchValue.trim());
		setExpandAllTreeItems(!!sectorSearchValue);
		if (sectorSearchValue) {
			showPath(healthSystems.treeData.tree, sectors);
		}
	}, [sectorSearchValue, healthSystems.treeData.tree, healthSystems.isRoomsOnlyView]);

	const onTreeViewLinkClick = option => {
		setHelloDeviceId(option.helloDeviceId);
		setRoomId(option.roomId);
		dispatch(healthSystemsActionCreators.updateBreadcrumb(option.breadcrumb));
	};

	const onBreadcrumbClick = (options, breadcrumbIndex) => {
		const breadcrumb = [];
		options.forEach((option, index) => {
			if (breadcrumbIndex + 1 > index) {
				breadcrumb.push(option);
			}
		});
		dispatch(healthSystemsActionCreators.updateBreadcrumb(breadcrumb));
	};

	const toggleTreeView = isRoomsOnlyView => {
		if (isRoomsOnlyView === healthSystems.isRoomsOnlyView || isLoadingView) {
			return;
		}
		dispatch(healthSystemsActionCreators.toggleIsRoomsView());
		const treeData = buildTree(healthSystems.selectedHealthSystem, isRoomsOnlyView);
		dispatch(healthSystemsActionCreators.setTreeData(treeData));
		setIsLoadingView(false);
	};

	return (
		<MainLayout>
			{!healthSystems.allHealthSystems.length && (
				<Grid width='100%' stretch='100vh' vertAlign='center' horizAlign='center' rows='auto'>
					<Loader />
				</Grid>
			)}
			{healthSystems.allHealthSystems.length && (
				<>
					<Grid columns='1fr 3fr' stretch='100%'>
						<aside className='hello-list hospitals-list'>
							<div>
								<div className='left-nav-header flex'>
									<div>
										<h4>{translate(!healthSystems.isRoomsOnlyView ? 'treeView' : 'listView')}</h4>
									</div>
									<div className='flex flex-1 right-align-content tree-view-options'>
										<div
											onClick={() => toggleTreeView(!healthSystems.isRoomsOnlyView)}
											data-tooltip={intl.formatMessage({ id: healthSystems.isRoomsOnlyView ? 'treeView' : 'listView' })}
											data-position='bottom'
											className='cursor-pointer right-before transform-x-0-before'>
											{!healthSystems.isRoomsOnlyView && (
												<ListView color={darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />
											)}
											{healthSystems.isRoomsOnlyView && (
												<TreeViewIcon color={darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />
											)}
										</div>
									</div>
								</div>

								<Input
									className='tree-search'
									type='text'
									name='sectorSearchValue'
									placeholder={intl.formatMessage({ id: 'search' })}
									value={sectorSearchValue}
									onChange={event => setSectorSearchValue(event.target.value)}
									validationOptions={{}}
									bottomSpace='15px'
									autoComplete='off'
								/>

								<TreeView
									isMonitoring={false}
									data={healthSystems.treeData.tree}
									preSelected={treeItems}
									onAdd={null}
									onLinkClick={onTreeViewLinkClick}
									selectedSectorId={getSelectedSectorId()}
									expandAll={expandAllTreeItems}
								/>
							</div>
						</aside>
						<main className='main-view'>
							<div className='breadcrumb-container hs-breadcrumb-container'>
								<Breadcrumb links={healthSystems.breadcrumb} onBreadcrumbClick={onBreadcrumbClick} isFromHealthSystems={true} />
							</div>
							<section className='health-system-inner-section'>
								<Grid
									width='100%'
									rows='auto'
									vertAlign={[UserRoles.ADMIN, UserRoles.SUPER_USER].includes(userRole) ? 'start' : 'center'}
									horizAlign='center'>
									<>
										{!match.params?.room && (
											<p>
												{translate('communicateWithARoom')}, <br />
												{translate('openHospitalChooseDepartment')}, <br />
												{translate('clickFloorSelectRoom')}.
											</p>
										)}
										{match.params.room && !helloDeviceId && (
											<p>
												{translate('roomNoDeviceAssigned')}. <br />
												{translate('roomsNeedDevice')} {translate('inOrderToCall')}.
											</p>
										)}
									</>
									{helloDeviceId && (
										<Route
											exact
											path='/visitors/:hospitalId/department/:departmentId/floor/:floorId/room/:roomId'
											component={VisitorRoom}
										/>
									)}
								</Grid>
							</section>
						</main>
					</Grid>
				</>
			)}
		</MainLayout>
	);
};

export default Visitors;
