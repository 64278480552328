import LightTheme from 'calls/styles/LightTheme.js';

const AiNotifications = props => (
	<svg
		width='24'
		height='24'
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		color={LightTheme.colors.graySeven}
		{...props}>
		<path
			d='M10 20C10 20.55 10.19 21.02 10.59 21.41C10.98 21.8 11.45 22 12 22C12.55 22 13.02 21.8 13.41 21.41C13.8 21.02 14 20.55 14 20H10Z'
			fill='currentColor'
		/>
		<path
			d='M18 17V10C18 8.61 17.58 7.38 16.75 6.31C15.92 5.23 14.83 4.53 13.5 4.2V3.5C13.5 3.08 13.35 2.73 13.06 2.43C12.77 2.14 12.42 2 12 2C11.58 2 11.23 2.14 10.94 2.43C10.64 2.73 10.5 3.08 10.5 3.5V4.2C9.17 4.53 8.08 5.23 7.25 6.31C6.42 7.38 6 8.61 6 10V17H4V19H20V17H18ZM12.74 16.41L12.16 14.75H9.4L8.83 16.41H7.54L10.23 9.3H11.34L14.03 16.41H12.74ZM16.14 16.41H14.91V9.3H16.14V16.41ZM9.75 13.76H11.81L10.78 10.81L9.75 13.76Z'
			fill='currentColor'
		/>
	</svg>
);

export default AiNotifications;
