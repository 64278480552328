import React, { useState, useEffect, useContext } from 'react';
import { getUserId } from 'infrastructure/auth.js';
import { getFamilyMemberPatients } from 'api/rpm.js';
import translate from 'i18n-translations/translate.jsx';
import Alert from 'components/Common/Alert.jsx';
import EmptyState from 'components/Common/EmptyState.jsx';
import Grid from 'components/Common/Grid.jsx';
import HelloFeatureBlock from 'components/Common/HelloFeatureBlock.jsx';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import classNames from 'classnames';
import ProfilePicture from 'components/Common/ProfilePicture.jsx';
import { PresenceStatusType } from 'constants/enums.js';
import { decodeHtml } from 'infrastructure/helpers/commonHelpers.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { enums } from '@solaborate/calls';
import { StartQueryStringKeys } from 'calls/enums/index.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SocketEvents from 'constants/socket-events.js';

const FamilyMemberPatients = () => {
	const [patients, setPatients] = useState([]);
	const [selectedPatient, setSelectedPatient] = useState(null);
	const [error, setError] = useState(null);
	const socket = useContext(SocketContext);

	useEffect(() => {
		const getPatientsForFamilyMember = async () => {
			const response = await getFamilyMemberPatients(getUserId());
			if (response.error) {
				setError(response.error.message);
				return;
			}
			setPatients(response.patientCareTeam);
			if (response.patientCareTeam.length > 0) {
				setSelectedPatient(response.patientCareTeam[0]);
			}
		};
		getPatientsForFamilyMember();
	}, []);

	useEffect(() => {
		const getPatientsList = async ({ socketEvent, presenceStatusTypeId = null, userId = null, objectId = null }) => {
			const response = await getFamilyMemberPatients(getUserId());
			if (response.error) {
				setError(response.error.message);
				return;
			}
			const foundPatient = response.patientCareTeam.find(patient => patient.userIntId === (userId || parseInt(objectId, 10)));
			if (foundPatient) {
				if (typeof presenceStatusTypeId !== 'undefined') {
					foundPatient.presenceStatus = presenceStatusTypeId;
				} else if (typeof userId !== 'undefined') {
					foundPatient.isOnline = socketEvent === SocketEvents.Client.ON_USER_ONLINE;
				}
				setPatients(response.patientCareTeam);
			}
		};

		socket.on(SocketEvents.Client.ON_NOTIFY_UPDATED_USER_PRESENCE, getPatientsList);
		socket.on(SocketEvents.Client.ON_USER_ONLINE, event =>
			getPatientsList({ userId: event.userId, socketEvent: SocketEvents.Client.ON_USER_ONLINE })
		);
		socket.on(SocketEvents.Client.ON_USER_OFFLINE, getPatientsList);

		return () => {
			socket.off(SocketEvents.Client.ON_NOTIFY_UPDATED_USER_PRESENCE, getPatientsList);
			socket.off(SocketEvents.Client.ON_USER_ONLINE, event =>
				getPatientsList({ userId: event.userId, socketEvent: SocketEvents.Client.ON_USER_ONLINE })
			);
			socket.off(SocketEvents.Client.ON_USER_OFFLINE, getPatientsList);
		};
	}, [socket]);

	const callPatient = type => {
		const queryParams = new URLSearchParams({
			[StartQueryStringKeys.OBJECT_ID]: selectedPatient.userIntId,
			[StartQueryStringKeys.OBJECT_TYPE]: enums.ObjectTypes.USER,
			[StartQueryStringKeys.CONFERENCE_NAME]: `${selectedPatient.firstName} ${selectedPatient.lastName}`,
			[StartQueryStringKeys.CALL_TYPE]: type,
		});
		window.open(`/call?${queryParams.toString()}`, '_blank');
	};

	return (
		<MainLayout>
			<Grid columns='1fr 3fr' stretch='100%'>
				<aside className='patients-list'>
					<p className='patient-header'>{translate('dependants')}</p>
					<div className='chat-items-area scroll-hover'>
						{patients.map(patient => (
							<div key={patient.userIntId} onClick={() => setSelectedPatient(patient)}>
								<div
									className={classNames(
										'chat-list-item flex position-relative',
										selectedPatient?.userIntId === patient.userIntId ? 'active' : ''
									)}>
									<div className='align-self-start position-relative'>
										<div className='flex position-relative chat-list-img-status-wrapper '>
											<ProfilePicture
												className='chat-list-img'
												fullName={`${patient.firstName} ${patient.lastName}`}
												profilePicture={patient.profilePicture}
											/>
											<div className='chat-list-status flex'>
												<span
													className={
														patient.presenceStatus === PresenceStatusType.UNAVAILABLE || !patient.isOnline ? 'is-off' : 'is-on'
													}
												/>
											</div>
										</div>
									</div>
									<div className='chat-list-name flex flex-1'>
										<p>{decodeHtml(`${patient.firstName} ${patient.lastName}`)}</p>
									</div>
								</div>
							</div>
						))}
					</div>
				</aside>
				<main className='main-view flex-justify-center flex-align-center'>
					{selectedPatient && (
						<div className='overview-mob-lap family-member-view'>
							<div className='hello-items overview-hello-items overview-mob-lap-items'>
								<div className='hello-device-description flex'>
									<img src={`${healthCareCdnUrl}overview/mobile.svg`} className='margin-right-m' alt='mobile-icon' />
									<h3>{translate('mobile')}</h3>
								</div>
								<div className='hello-feature-grid'>
									<HelloFeatureBlock
										className='hello-feature-audio'
										onClick={() => callPatient(enums.CallTypes.AUDIO)}
										title={translate('audioCall')}
									/>
									<HelloFeatureBlock
										className='hello-feature-video'
										onClick={() => callPatient(enums.CallTypes.VIDEO)}
										title={translate('videoCall')}
									/>
								</div>
							</div>
						</div>
					)}
					{patients.length === 0 && (
						<div className='empty-state-wrapper'>
							<EmptyState title={translate('familyMemberNotAssigned')} image='patients.svg' />
						</div>
					)}
				</main>
			</Grid>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</MainLayout>
	);
};
export default FamilyMemberPatients;
