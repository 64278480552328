import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import Select from 'react-select';
import Modal from 'components/Common/Modal.jsx';
import Form from 'components/Common/Form.jsx';
import translate from 'i18n-translations/translate.jsx';
import FormInput from 'components/Common/FormInput.jsx';
import { assignWearable } from 'api/lifeSignals.js';
import { WearableProviderIds, WearableTypes, WearableTypeIds } from 'constants/enums.js';

const AssignWearable = props => {
	const [wearableId, setWearableId] = useState('');
	const [wearableTypeId, setWearableTypeId] = useState(null);
	const [providerId, setProviderId] = useState(null);
	const [selectedProvider, setSelectedProvider] = useState(null);
	const [selectedWearableType, setSelectedWearableType] = useState(null);
	const initialErrors = { selectedProvider: '', selectedWearableType: '', wearableId: '' };
	const [errors, setErrors] = useState({ ...initialErrors });
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	const intl = useIntl();

	const providerMappings = {
		LifeSignals: {
			providerId: WearableProviderIds.LIFE_SIGNALS,
			wearableTypeId: WearableTypeIds.LIFE_SIGNALS_PATCH,
		},
		Biobeat: {
			providerId: WearableProviderIds.BIO_BEAT,
			wearables: [
				{ value: WearableTypeIds.BIO_BEAT_PATCH, label: intl.formatMessage({ id: WearableTypes.PATCH }) },
				{ value: WearableTypeIds.BIO_BEAT_WATCH, label: intl.formatMessage({ id: WearableTypes.WATCH }) },
			],
		},
	};

	const LIFESIGNALS_ID_LENGTH = 5;

	useEffect(() => {
		setErrors({ ...initialErrors });
	}, [props.display]);

	useEffect(() => {
		setErrors(prevState => ({ ...prevState, wearableId: '' }));
	}, [wearableId]);

	useEffect(() => {
		setErrors(prevState => ({ ...prevState, selectedProvider: '' }));
	}, [selectedProvider]);

	useEffect(() => {
		setErrors(prevState => ({ ...prevState, selectedWearableType: '' }));
	}, [selectedWearableType]);

	useEffect(() => {
		const selectedProviderLabel = selectedProvider?.label;
		const selectedTypeValue = selectedWearableType?.value;
		if (providerMappings[selectedProviderLabel]) {
			const providerMapping = providerMappings[selectedProviderLabel];
			setProviderId(providerMapping.providerId);

			if (providerMapping.wearableTypeId) {
				setWearableTypeId(providerMapping.wearableTypeId);
				return;
			}
			if (providerMapping.wearables?.length > 0) {
				const foundWearable = providerMapping.wearables.find(item => item.value === selectedTypeValue);
				if (foundWearable) {
					setWearableTypeId(foundWearable.value);
				}
			}
		}
	}, [selectedProvider, selectedWearableType]);

	const closeAssignWearableModal = () => {
		props.setIsAssignWearableModalOpen(false);
		setWearableId('');
		setProviderId(null);
		setWearableTypeId(null);
		setSelectedProvider(null);
		setSelectedWearableType(null);
	};

	const handleSubmit = async () => {
		setIsSubmitLoading(true);
		const validationErrors = { ...initialErrors };
		setErrors(validationErrors);
		if (!selectedProvider) {
			validationErrors.selectedProvider = intl.formatMessage({ id: 'providerError' });
		}
		if (providerId === WearableProviderIds.BIO_BEAT && !selectedWearableType) {
			validationErrors.selectedWearableType = intl.formatMessage({ id: 'wearableTypeError' });
		}
		if (!wearableId) {
			validationErrors.wearableId = intl.formatMessage({ id: 'wearableIdEmpty' });
		}
		if (wearableId && providerId === WearableProviderIds.LIFE_SIGNALS && wearableId.length !== LIFESIGNALS_ID_LENGTH) {
			validationErrors.wearableId = intl.formatMessage({ id: 'invalidWearableId' });
		}
		const hasAnyError = Object.values(validationErrors).some(error => error !== '');
		if (hasAnyError) {
			setErrors(validationErrors);
			setIsSubmitLoading(false);
			return;
		}
		const params = {
			patientId: props.ownerId,
			iotDeviceVendorId: providerId,
			iotDeviceTypeId: wearableTypeId,
			hubDeviceId: props.helloDeviceId?.toString() ?? null,
			iotDeviceId: wearableId,
		};
		const response = await assignWearable(params);
		if (response.error) {
			const responseError = response.error?.response?.data?.message || intl.formatMessage({ id: 'somethingWentWrong' });
			setErrors(prevState => ({ ...prevState, wearableId: responseError }));
		} else {
			closeAssignWearableModal();
		}
		setIsSubmitLoading(false);
	};

	return (
		<Modal
			display={props.display}
			onModalSubmit={handleSubmit}
			position='right'
			className='assign-patients-modal'
			onModalClose={closeAssignWearableModal}
			primaryButtonLoading={isSubmitLoading}>
			<div>
				<Form
					title={intl.formatMessage({ id: 'assignWearable' })}
					onSubmit={evt => evt.preventDefault()}
					className='assign-patch'>
					<div className='input'>
						<p className='label'>{translate('provider')}</p>
						<Select
							value={selectedProvider}
							placeholder={intl.formatMessage({ id: 'selectOption' })}
							classNamePrefix='react-select'
							options={props.enabledProviders}
							onChange={value => setSelectedProvider(value)}
						/>
						{errors.selectedProvider && <span className='red-error'>{errors.selectedProvider}</span>}
					</div>
					{providerId === WearableProviderIds.BIO_BEAT && (
						<div className='input'>
							<p className='label'>{translate('wearableType')}</p>
							<Select
								value={selectedWearableType}
								placeholder={intl.formatMessage({ id: 'selectOption' })}
								classNamePrefix='react-select'
								options={providerMappings.Biobeat.wearables}
								onChange={value => setSelectedWearableType(value)}
							/>
							{errors.selectedWearableType && <span className='red-error'>{errors.selectedWearableType}</span>}
						</div>
					)}
					<FormInput
						id='wearableId'
						name='wearableId'
						text={intl.formatMessage({ id: 'wearableId' })}
						type='text'
						onChange={event => setWearableId(event.target.value)}
						error={errors.wearableId}
						value={wearableId}
						maxLength={10}
						labelClassName='header-searchbar no-padding-left no-padding-right'
						validateOnSubmit={true}
					/>
				</Form>
			</div>
		</Modal>
	);
};

export default AssignWearable;
