import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import translate from 'i18n-translations/translate.jsx';
import { AppointmentStatus } from 'constants/enums.js';
import Loader from 'components/Common/Loader.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import useOutsideClick from 'infrastructure/helpers/useOutsideClick.js';
import AppointmentRequestDetails from 'components/Appointments/AppointmentRequestDetails';

const requestFilters = [
	{
		id: 0,
		name: translate('allRequests'),
		display: true,
	},
	{
		id: AppointmentStatus.ACCEPTED,
		name: translate('accepted'),
		display: true,
	},
	{
		id: AppointmentStatus.PENDING,
		name: translate('pending'),
		display: true,
	},
	{
		id: AppointmentStatus.REJECTED,
		name: translate('rejected'),
		display: true,
	},
	{
		id: AppointmentStatus.RESCHEDULED,
		name: translate('rescheduled'),
		display: false,
	},
	{
		id: AppointmentStatus.CANCELED,
		name: translate('canceled'),
		display: true,
	},
	{
		id: AppointmentStatus.COMPLETED,
		name: translate('completed'),
		display: true,
	},
];

const AppointmentRequestsList = props => {
	const [isFiltersSelectDisplayed, setIsSelectDisplayed] = useState(false);
	const [isFiltersDropdownDisplayed, setIsDropdownDisplayed] = useState(false);
	const [selectedRequestsFilterId, setSelectedRequestFilteredId] = useState(0);

	const wrapperRef = useRef(null);

	useOutsideClick(wrapperRef, () => {
		if (isFiltersDropdownDisplayed) {
			setIsDropdownDisplayed(false);
		}
	});

	const setStatus = status => {
		props.setSelectedStatus(status);
		setSelectedRequestFilteredId(status);
		setIsDropdownDisplayed(false);
	};

	return (
		<>
			{props.isLoading && (
				<div className='patient-request-loader'>
					<Loader />
				</div>
			)}
			{!props.isLoading && (
				<div className={`${props.requests.length > 0 ? 'patient-request-list scroll-hover' : ''}`}>
					<div className='flex appointment-filter-wrapper'>
						<h4>{translate('appointmentRequests')}</h4>
					</div>

					<div className='position-relative flex appointment-request-filter-wrapper'>
						<div
							className='flex doctor-requests-filter appointment-request'
							onClick={() => setIsSelectDisplayed(prevState => !prevState)}>
							<img src={`${healthCareCdnUrl}doctor-request/filter.svg`} alt='icon' />
							{translate('filter')}
						</div>
						{isFiltersSelectDisplayed && (
							<>
								<div className='flex requests-filter-select appointment-request'>
									<p>{translate('status')}</p>
									<div
										className='flex cursor-pointer'
										onClick={() => setIsDropdownDisplayed(prevState => !prevState)}
										ref={wrapperRef}>
										<span>{requestFilters[selectedRequestsFilterId].name}</span>
										<i className='material-icons'>keyboard_arrow_down</i>
									</div>
									{isFiltersDropdownDisplayed && (
										<div className='appointment-status-result'>
											{requestFilters
												.filter(item => item.display)
												.map(item => (
													<div
														className='flex cursor-pointer header-elements'
														key={item.id}
														onClick={() => {
															setStatus(item.id);
														}}>
														<p>{item.name}</p>
														{item.id === selectedRequestsFilterId && <i className='material-icons-outlined check-icon'>done</i>}
													</div>
												))}
										</div>
									)}
								</div>
							</>
						)}
					</div>

					{props.requests.some(item => item?.appointmentNotification) && (
						<>
							<p className='opened-new-appointment'>{translate('unread')}</p>
							<AppointmentRequestDetails
								requests={props.requests.filter(item => item?.appointmentNotification)}
								selectedRequest={props.selectedRequest}
								status={props.status}
								setSelectedRequest={props.setSelectedRequest}
							/>
						</>
					)}
					{props.requests.some(item => !item?.appointmentNotification) && (
						<>
							<p className='opened-new-appointment'>{translate('opened')}</p>
							<AppointmentRequestDetails
								requests={props.requests.filter(item => !item?.appointmentNotification)}
								selectedRequest={props.selectedRequest}
								status={props.status}
								setSelectedRequest={props.setSelectedRequest}
							/>
						</>
					)}
				</div>
			)}
		</>
	);
};

const mapStateToProps = state => {
	return {
		myDoctors: state.myDoctors,
	};
};
export default connect(mapStateToProps)(AppointmentRequestsList);
