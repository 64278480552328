import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import 'font-awesome/css/font-awesome.min.css';
import 'css/index.scss';
import store from './store/configureStore.jsx';
import App from './App';
import { unregister } from './registerServiceWorker.js';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
	<Provider store={store}>
		<BrowserRouter basename='/'>
			<App />
		</BrowserRouter>
	</Provider>
);
unregister();
