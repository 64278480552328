import Modal from 'components/Common/Modal.jsx';
import Form from 'components/Common/Form.jsx';
import React from 'react';
import { useIntl } from 'react-intl';
import translate from 'i18n-translations/translate.jsx';
import { getPriorityColor, getStatusDetails } from 'infrastructure/helpers/careEventsHelper.jsx';
import classNames from 'classnames';
import { capitalizeFirstLetter } from 'infrastructure/helpers/commonHelpers.js';
import { Textarea } from 'components/index.js';
import SpeechToText from 'components/SpeechToText/SpeechToText.jsx';
import ProfilePicture from 'components/Common/ProfilePicture.jsx';
import Reassigned from 'icons/CareEvents/Reassigned.jsx';
import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';
import ToIcon from 'icons/CareEvents/ToIcon.jsx';
import Button from 'components/Common/Button.jsx';

const CareEventNotes = ({
	onModalClose,
	onModalSubmit,
	selectedCareEvent,
	selectedStatus,
	isSpeechToText,
	liveTranscription,
	recognizedTranscription,
	setIsSpeechToText,
	setLiveTranscription,
	setRecognizedTranscription,
	selectedCareMember,
	selectedPriority,
	isDarkMode,
}) => {
	const intl = useIntl();

	const getNotesTitle = () => {
		if (selectedStatus) {
			return 'youUpdatedStatus';
		}
		if (selectedCareMember) {
			return 'youReassignedCareMember';
		}
		if (selectedPriority) {
			return 'youUpdatedPriority';
		}
		return '';
	};

	return (
		<Modal
			className='make-appointment-modal border-radius-modal-wrapper appoinment-next-arrow-modal care-event-notes'
			onModalClose={onModalClose}
			onModalSubmit={onModalSubmit}
			display={true}
			position='center'
			primaryButtonLabel={intl.formatMessage({ id: 'confirm' })}
			shouldSubmitOnEnter={false}>
			<Form height={500} className='modal-form-wrapper'>
				<h4 className='bold'>{translate(getNotesTitle())}</h4>
				<p className='note-description'>{translate('yourInsightsHelp')}</p>
				<div className='flex flex-justify-center changed-status-btn'>
					{selectedStatus && (
						<>
							<Button
								className={classNames(getStatusDetails(selectedCareEvent.statusId)?.clsName, 'previous')}
								text={translate(getStatusDetails(selectedCareEvent.statusId)?.name)}
							/>
							<div className='flex flex-align-center'>
								<ToIcon color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />
							</div>
							<Button className={getStatusDetails(selectedStatus.value)?.clsName} text={translate(selectedStatus?.label)} />
						</>
					)}
					{selectedCareMember && (
						<>
							<div className='flex flex-align-center previous care-member-wrapper'>
								<ProfilePicture
									className='doctor-request-img'
									fullName={`${selectedCareEvent.careMember?.firstName} ${selectedCareEvent.careMember?.lastName}`}
									profilePicture={selectedCareEvent.careMember?.profilePicture}
								/>
								<p className='font-14 no-margin no-padding'>{`${selectedCareEvent.careMember?.firstName} ${selectedCareEvent.careMember?.lastName}`}</p>
							</div>
							<div className='flex flex-align-center'>
								<Reassigned color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />
							</div>
							<div className='flex flex-align-center care-member-wrapper'>
								<ProfilePicture
									className='doctor-request-img'
									fullName={selectedCareMember.label}
									profilePicture={selectedCareMember.profilePicture}
								/>
								<p className='font-14 no-margin no-padding'>{selectedCareMember.label}</p>
							</div>
						</>
					)}
					{selectedPriority && (
						<>
							<Button
								className={classNames(getPriorityColor(selectedCareEvent.priorityId), 'previous')}
								text={translate(getPriorityColor(selectedCareEvent.priorityId))}
							/> 
							<div className='flex flex-align-center'>
								<ToIcon color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />
							</div> 
							<Button className={getPriorityColor(selectedPriority.value)} text={translate(selectedPriority?.label)} />
						</>
					)}
				</div>
				<div>
					<p className='bold no-margin'>{capitalizeFirstLetter(intl.formatMessage({ id: 'note' }))}</p>
					<Textarea
						disabled={false}
						maxLength={400}
						onChange={event => setRecognizedTranscription(event.target.value)}
						value={isSpeechToText ? `${recognizedTranscription} ${liveTranscription}`.trim() : recognizedTranscription}
						maxNumberLimit={40}
						rows={10}
						name='text'
						placeholder={intl.formatMessage({ id: 'describeChange' })}
						icon={
							<SpeechToText
								setRecognizedTranscription={setRecognizedTranscription}
								setLiveTranscription={setLiveTranscription}
								setIsSpeechToText={setIsSpeechToText}
								isSpeechToText={isSpeechToText}
								isDisabled={false}
							/>
						}
					/>
				</div>
			</Form>
		</Modal>
	);
};

export default CareEventNotes;
