import { getAuditLogs, getAuditLogsByCompanyId, getLogTypes } from 'api/auditLogs.js';
import { UserRoles } from 'constants/enums.js';
import Alert from 'components/Common/Alert.jsx';
import AuditLogsDetails from 'components/AuditLogs/AuditLogsDetails.jsx';
import Pagination from 'components/Common/Pagination.jsx';
import CustomTable from 'components/Common/CustomTable.jsx';
import Grid from 'components/Common/Grid.jsx';
import { adminsLogTypes, LanguageTypes } from 'constants/configurationEnums.js';
import { ObjectType } from 'constants/enums.js';
import AuditLogsFilters from 'containers/Common/AuditLogsFilters.jsx';
import { Formik } from 'formik';
import translate from 'i18n-translations/translate.jsx';
import { getCompanyId, getUserRole } from 'infrastructure/auth.js';
import {
	capitalizeLetterAndAddSpaces,
	getPreferredLanguageLocale,
	getRoleValueById,
	getUserTypeBasedOnCompanyRole,
	isObjectValue,
	isValidJSON,
} from 'infrastructure/helpers/commonHelpers.js';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import MainLayout from 'views/Layouts/MainLayout.jsx';

const DisplayEnums = {
	1: 'portrait',
	2: 'landscape',
	3: 'portraitReverse',
	mainDisplay: 'mainDisplay',
	secondDisplay: 'secondDisplay',
};

const PrivacyEnums = {
	1: 'monitoring',
	2: 'alexa',
	3: 'wakeword',
	true: 'enabled',
	false: 'disabled',
	source: 'source',
	active: 'active',
};

const AuditLogsEnums = {
	language: 'Language',
	display: 'Display',
	...PrivacyEnums,
	...DisplayEnums,
};

const AuditLogs = () => {
	const intl = useIntl();
	const companySettings = useSelector(state => state.company.companySettings);
	const locale = useSelector(state => state.language.locale);
	const isClientAuditLog = UserRoles.ADMIN === getUserRole();
	const [logs, setLogs] = useState([]);
	const [logTypes, setLogTypes] = useState([]);
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 1 });
	const [totalCount, setTotalCount] = useState();
	const [isViewDetailsModal, setIsViewDetailsModal] = useState(false);
	const [selectedMetaData, setSelectedMetaData] = useState(null);
	const [filtersParams, setFiltersParams] = useState({});

	const tableHeaders = [
		{ title: translate('client'), id: 'client', showColumn: !isClientAuditLog },
		{ title: translate('companyId'), id: 'tenantId', showColumn: !isClientAuditLog },
		{ title: translate('logType'), id: 'logType', showColumn: true },
		{ title: translate('inputter'), id: 'inputter', showColumn: true },
		{ title: translate('inputterType'), id: 'objectType', showColumn: !isClientAuditLog },
		{ title: translate('inputterId'), id: 'objectId', showColumn: true },
		{ title: translate('email'), id: 'email', showColumn: true, columnClass: 'break-word' },
		{ title: translate('sector'), id: 'sector', showColumn: isClientAuditLog },
		{ title: translate('featureChanged'), id: 'featureChanged', showColumn: isClientAuditLog, columnClass: 'break-word' },
		{ title: translate('variant'), id: 'variant', showColumn: isClientAuditLog },
		{ title: translate('changedForRole'), id: 'changedForRole', showColumn: isClientAuditLog },
		{ title: translate('sourceOfInput'), id: 'sourceOfInput', showColumn: !isClientAuditLog },
		{ title: translate('subjectName'), id: 'actioneeName', showColumn: !isClientAuditLog },
		{ title: translate('subjectId'), id: 'actioneeObjectId', showColumn: !isClientAuditLog },
		{ title: translate('subjectType'), id: 'actioneeObjectType', showColumn: !isClientAuditLog },
		{ title: translate('source'), id: 'source', showColumn: !isClientAuditLog },
		{ title: translate('creationDate'), id: 'creationDate', showColumn: true },
		{ title: '', id: 'details', showColumn: !isClientAuditLog },
	];

	const transformLogName = logType => capitalizeLetterAndAddSpaces(logType).replace(/\./g, ' ');

	useEffect(() => {
		const fetchLogs = async () => {
			setIsLoading(true);
			let auditLogsList = [];
			if (logTypes.length === 0) {
				const auditLogTypesResponse = await getLogTypes();
				if (auditLogTypesResponse.error) {
					setError(auditLogTypesResponse.message);
				} else {
					auditLogsList = isClientAuditLog
						? auditLogTypesResponse.logTypes.filter(log => adminsLogTypes.includes(log.name))
						: auditLogTypesResponse.logTypes;
					setLogTypes(auditLogsList);
				}
			}

			const params = {
				...(isClientAuditLog && auditLogsList.length > 0 && { logTypeId: auditLogsList[0].id }),
				...filtersParams,
				...pagination,
			};

			const auditLogsResponse = isClientAuditLog
				? await getAuditLogsByCompanyId(params)
				: await getAuditLogs({ ...params, tenantId: getCompanyId() });
			if (auditLogsResponse.error) {
				setError(auditLogsResponse.message);
				return;
			}
			setLogs(auditLogsResponse.logs);
			setTotalCount(auditLogsResponse.totalCount);
			setIsLoading(false);
		};
		fetchLogs();
	}, [pagination, filtersParams]);

	const initialValues = useMemo(
		() => ({
			clientId: null,
			logTypeId:
				isClientAuditLog && logTypes[0]
					? {
							value: logTypes[0]?.id,
							label: transformLogName(logTypes[0]?.name),
					  }
					: null,
			objectType: null,
			firstName: '',
			lastName: '',
			objectId: '',
			email: '',
			platformId: null,
			actioneeObjectId: '',
			actioneeObjectType: null,
			source: '',
			tenantId: '',
			fromDate: null,
			toDate: null,
			pageSize: pagination.pageSize,
			pageIndex: pagination.pageIndex,
		}),
		[logTypes]
	);

	const openDetails = details => {
		setIsViewDetailsModal(true);
		setSelectedMetaData(details);
	};

	const buildFeatureChangedText = newSetting => {
		const configName = newSetting?.SettingType;
		const formattedConfigName = configName ? intl.formatMessage({ id: _.camelCase(configName) }) : 'N/A';

		const getLogEnums = log => {
			if (!!PrivacyEnums[log.label]) {
				return PrivacyEnums[log.value];
			} else if (!!DisplayEnums[log.label]) {
				return DisplayEnums[log.value];
			}
			return AuditLogsEnums[log.label];
		};

		const getEnabledDisabledText = value => {
			const status = value === 'true' ? 'enabled' : 'disabled';
			return `${formattedConfigName}: ${intl.formatMessage({ id: status })} `;
		};

		const getLanguageText = value => {
			const language = LanguageTypes[value] ?? value;
			return `${formattedConfigName}: ${intl.formatMessage({ id: language })} `;
		};

		const getJsonTransformedText = value => {
			const jsonValue = JSON.parse(value);
			const transformedSettings = Object.keys(jsonValue).reduce((acc, key) => {
				const jsonPropValue = jsonValue[key];
				if (jsonPropValue !== null && jsonPropValue !== '' && !isObjectValue(jsonPropValue)) {
					acc.push({ label: key, value: jsonValue[key] });
				}
				return acc;
			}, []);
			return transformedSettings.map(item => {
				const hasValue = Object.values(AuditLogsEnums).includes(item.label);
				const itemValue = hasValue ? intl.formatMessage({ id: getLogEnums(item) }) : item.value;
				return `${intl.formatMessage({ id: item.label })} : ${itemValue} `;
			});
		};

		if (newSetting?.Value === 'true' || newSetting?.Value === 'false') {
			return getEnabledDisabledText(newSetting.Value);
		}
		if (configName === AuditLogsEnums.language) {
			return getLanguageText(newSetting?.Value);
		}
		if (isValidJSON(newSetting?.Value)) {
			return getJsonTransformedText(newSetting?.Value);
		}
		return `${formattedConfigName} : ${newSetting?.Value}`;
	};

	const getRoleName = roleId => (roleId ? getRoleValueById(getUserTypeBasedOnCompanyRole(roleId), companySettings) : 'N/A');

	const getTableRows = () =>
		logs.map(log => {
			const changeDetails = log.details && isValidJSON(log.details) ? JSON.parse(log.details) : {};
			let newSettings;
			if (changeDetails.NewSettings) {
				newSettings = changeDetails.NewSettings[0] ?? changeDetails.NewSettings;
			}

			return {
				id: log.creationDate + log.logType + log.details,
				...(!isClientAuditLog && { client: log.client || 'N/A', tenantId: log.tenantId || 'N/A' }),
				logType: <div className='font-13'>{transformLogName(log.logType) || 'N/A'}</div>,
				inputter: log.inputter || 'N/A',
				...(!isClientAuditLog && { objectType: intl.formatMessage({ id: getObjectName(log.objectType) }) || 'N/A' }),
				objectId: log.objectId || 'N/A',
				email: log.email || 'N/A',
				...(isClientAuditLog && {
					sector: changeDetails?.Name || 'N/A',
					featureChanged: buildFeatureChangedText(newSettings),
					variant: newSettings?.Variant ? _.startCase(newSettings.Variant) : 'N/A',
					changedForRole: getRoleName(newSettings?.RoleId),
				}),
				...(!isClientAuditLog && {
					sourceOfInput: log.sourceOfInput || 'N/A',
					actioneeName: log.actioneeName || 'N/A',
					actioneeObjectId: log.actioneeObjectId || 'N/A',
					actioneeObjectType: intl.formatMessage({ id: getObjectName(log.actioneeObjectType) }) || 'N/A',
					source: log.source || 'N/A',
				}),
				creationDate: (
					<div className='font-13'>
						<span className='block'>
							{moment.utc(log.creationDate).local().locale(getPreferredLanguageLocale()).format('MM/DD/YYYY-hh:mm A')}
						</span>
					</div>
				),
				...(log.details &&
					!isClientAuditLog && {
						details: (
							<div className='badge blue audit-logs-view-details' onClick={() => openDetails(log.details)}>
								{translate('viewDetails')}
							</div>
						),
					}),
			};
		});

	const handleOnFilter = async values => {
		const params = {
			...(values.clientId?.value && { clientId: values.clientId?.value }),
			logTypeId: values.logTypeId?.value ?? null,
			objectType: values.objectType?.value,
			...(values.firstName && { firstName: values.firstName }),
			...(values.lastName && { lastName: values.lastName }),
			...(values.email && { email: values.email }),
			...(values.objectId && { objectId: values.objectId }),
			...(values.platformId?.value && { platformId: values.platformId?.value }),
			...(values.actioneeObjectId && { actioneeObjectId: values.actioneeObjectId }),
			...(values.actioneeObjectType?.value && { actioneeObjectType: values.actioneeObjectType?.value }),
			...(values.source && { source: values.source }),
			...(!isClientAuditLog && { tenantId: values.tenantId ?? null }),
			fromDate: values.fromDate ?? null,
			toDate: values.toDate ?? null,
		};
		setPagination({ pageSize: pagination.pageSize, pageIndex: 1 });
		setFiltersParams(params);
	};

	const getObjectName = objectType => {
		const objectTypes = {
			[ObjectType.USER]: 'user',
			[ObjectType.HELLO_DEVICE]: 'helloDevice',
			[ObjectType.SYSTEM]: 'systemExecutedActions',
		};
		return objectTypes[objectType] || 'N/A';
	};

	return (
		<MainLayout>
			<div className='call-logs-view'>
				<Grid columns='0 3fr' stretch='100%'>
					<aside className='hello-list' />
					<main className='main-view'>
						<section>
							<div className='full-width'>
								<h3>{translate('auditLogs')}</h3>
								<CustomTable
									isLoading={isLoading}
									headers={tableHeaders.filter(item => item.showColumn)}
									rows={isLoading ? [] : getTableRows()}
									tableEmptyText={translate('noAuditLogs')}
									className='margin-top-l'>
									<Formik initialValues={initialValues} onSubmit={values => handleOnFilter(values)} enableReinitialize={true}>
										{({ values, handleSubmit, setFieldValue, resetForm }) => (
											<AuditLogsFilters
												logTypes={logTypes}
												values={values}
												setFieldValue={setFieldValue}
												handleSubmit={handleSubmit}
												resetForm={() => {
													setPagination({ pageSize: 10, pageIndex: 1 });
													setFiltersParams(isClientAuditLog ? { logTypeId: logTypes[0].id } : {});
													resetForm();
												}}
												locale={locale}
												transformLogName={transformLogName}
											/>
										)}
									</Formik>
								</CustomTable>
								{isViewDetailsModal && (
									<AuditLogsDetails
										selectedMetaData={selectedMetaData}
										isViewDetailsModal={isViewDetailsModal}
										closeDetailsModal={() => setIsViewDetailsModal(false)}
									/>
								)}
								<Pagination
									totalCount={totalCount}
									pageSize={pagination.pageSize}
									pageIndex={pagination.pageIndex - 1}
									onChange={(pageSize, pageIndex) => setPagination({ pageSize, pageIndex: pageIndex + 1 })}
								/>
							</div>
						</section>
					</main>
				</Grid>
			</div>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</MainLayout>
	);
};

export default AuditLogs;
