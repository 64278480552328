import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import Grid from 'components/Common/Grid.jsx';
import DoctorsList from 'views/Doctors/Partials/DoctorsList.jsx';
import DoctorsMain from 'views/Doctors/Partials/DoctorsMain.jsx';
import EmptyState from 'components/Common/EmptyState.jsx';
import translate from 'i18n-translations/translate.jsx';

const MyDoctors = () => {
	const selectedDoctor = useSelector(state => state.myDoctors.selectedDoctor);
	return (
		<MainLayout>
			<Grid columns='1fr 3fr' stretch='100%'>
				<aside className='patients-list'>
					<DoctorsList />
				</aside>
				<main>
					{selectedDoctor && <DoctorsMain />}
					{!selectedDoctor && (
						<div className='empty-state-wrapper'>
							<EmptyState title={translate('noConnectedDoctor')} image='no-doctor.png'>
								<p>
									{translate('goToThe')} <Link to='doctor-requests'>{translate('requests')}</Link> {translate('toGetStarted')}
								</p>
							</EmptyState>
						</div>
					)}
				</main>
			</Grid>
		</MainLayout>
	);
};

export default MyDoctors;
