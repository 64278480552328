import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import moment from 'moment';
import _ from 'lodash';
import translate from 'i18n-translations/translate.jsx';
import {
	MeasurementsMinMax,
	MeasurementTypes,
	MeasurementUnits,
	PatientWeightLimit,
	UnitCategoryTypes,
} from 'constants/enums.js';
import { PrimaryCareForms, VisitTypes } from 'constants/visitEnums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import Modal from 'components/Common/Modal.jsx';
import Form from 'components/Common/Form.jsx';
import Input from 'components/Common/Input.jsx';
import Alert from 'components/Common/Alert.jsx';
import Textarea from 'components/Common/Textarea.jsx';
import BackgroundIcon from 'components/Common/BackgroundIcon.jsx';
import { handleOnKeyDownNumeric } from 'infrastructure/helpers/commonHelpers.js';
import Select from 'components/Common/Select.jsx';
import { addMeasurement } from 'api/measurements.js';
import { getDefaultUnit, getFormattedMeasurement } from 'infrastructure/helpers/measurementsHelper.js';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from 'components/Common/Tabs.jsx';
import { Icon } from 'calls/components/index.js';
import { default as AllergiesForm } from 'components/Common/Allergies.jsx';
import AddDiagnoses from 'components/Common/AddDiagnoses.jsx';
import { PillIcon, InfusionRoomIcon, InjectionRoomIcon, LaboratoryIcon, RadiologyIcon } from 'calls/icons/index.js';
import LightTheme from 'calls/styles/LightTheme.js';
import { toAddMedicalForms } from 'constants/medical-forms.js';
import ParenteralTherapyForm from 'components/Common/ParenteralTherapyForm.jsx';
import SearchToAddTest from 'components/Common/SearchToAddTest.jsx';
import VisitSummary from 'components/Common/VisitSummary.jsx';

const CurrentVisit = props => {
	const linkToHealthDataIcons = `${healthCareCdnUrl}health-data/`;
	const intl = useIntl();
	const [error, setError] = useState(null);
	const [vitalSignError, setVitalSignError] = useState('');
	const [previousDiagnoses, setPreviousDiagnoses] = useState([]);
	const [menuOptionsTab, setMenuOptionsTab] = useState(0);
	const [foodAllergies, setFoodAllergies] = useState([]);
	const [medicationAllergies, setMedicationAllergies] = useState([]);
	const [environmentalAllergies, setEnvironmentalAllergies] = useState([]);
	const [biologicalAllergies, setBiologicalAllergies] = useState([]);
	const [editRadiology, setEditRadiology] = useState(false);
	const [editLaboratory, setEditLaboratory] = useState(false);
	const [selectedVitalSign, setSelectedVitalSign] = useState(null);

	const menuOptions = [
		{
			id: 0,
			tab: 'returnReport',
		},
		{
			id: 1,
			tab: 'reasonOfVisit',
		},
		{
			id: 2,
			tab: 'allergies',
		},
		{
			id: 3,
			tab: 'vitalSigns',
		},
		{
			id: 4,
			tab: 'medicalAssessment',
		},
		{
			id: 5,
			tab: 'therapy',
		},
		{
			id: 6,
			tab: 'diagnosticResearch',
		},
	];

	const vitalSignsToAdd = [
		{
			id: 0,
			type: MeasurementTypes.BLOOD_PRESSURE,
			value: '',
			secondValue: '',
			unit: intl.formatMessage({ id: 'systolic' }),
			secondUnit: intl.formatMessage({ id: 'diastolic' }),
			title: intl.formatMessage({ id: 'bloodPressure' }),
			enum: 'bloodPressure',
			img: 'heart.svg',
			min: MeasurementsMinMax.SYSTOLIC.MIN,
			max: MeasurementsMinMax.SYSTOLIC.MAX,
			minSecond: MeasurementsMinMax.DIASTOLIC.MIN,
			maxSecond: MeasurementsMinMax.DIASTOLIC.MAX,
		},
		{
			id: 1,
			type: MeasurementTypes.HEART_RATE,
			value: '',
			unit: MeasurementUnits.BPM,
			title: intl.formatMessage({ id: 'heartRate' }),
			enum: 'heartRate',
			img: 'heart.svg',
			min: MeasurementsMinMax.HEART_RATE.MIN,
			max: MeasurementsMinMax.HEART_RATE.MAX,
		},
		{
			id: 2,
			type: MeasurementTypes.WEIGHT,
			value: '',
			unit: MeasurementUnits.KILOGRAMS,
			title: intl.formatMessage({ id: 'weight' }),
			enum: 'weight',
			img: 'body-measurements.svg',
			min: PatientWeightLimit.KILOGRAMS.MIN,
			max: PatientWeightLimit.KILOGRAMS.MAX,
			unitCategoryId: UnitCategoryTypes.WEIGHT,
		},
		{
			id: 3,
			type: MeasurementTypes.TEMPERATURE,
			value: '',
			unit: MeasurementUnits.CELSIUS,
			title: intl.formatMessage({ id: 'temperature' }),
			enum: 'temperature',
			img: 'body-measurements.svg',
			min: MeasurementsMinMax.CELSIUS.MIN,
			max: MeasurementsMinMax.CELSIUS.MAX,
			unitCategoryId: UnitCategoryTypes.TEMPERATURE,
		},
		{
			id: 4,
			type: MeasurementTypes.OXYGEN,
			value: '',
			unit: MeasurementUnits.PERCENTAGE,
			title: intl.formatMessage({ id: 'oxygenSaturation' }),
			enum: 'oxygen',
			img: 'respiratory.svg',
			min: MeasurementsMinMax.OXYGEN_SATURATION.MIN,
			max: MeasurementsMinMax.OXYGEN_SATURATION.MAX,
		},
	];

	useEffect(() => {
		setVitalSignError('');
	}, [props.selectedVitalSign]);

	useEffect(() => {
		if (props.currentCase?.visits) {
			const doctorVisits = props.currentCase?.visits.filter(visit => visit.medicalVisitTypeId === VisitTypes.DOCTOR);
			const diagnosis = doctorVisits.map(visit => visit.diagnostifications).flat();
			setPreviousDiagnoses(diagnosis);
		}
	}, [props.currentCase]);

	const addForm = item => props.setForms(prevState => [...prevState, item]);

	const removeForm = formType => props.setForms(prevState => [...prevState.filter(form => form.id !== formType)]);

	const submitVitalSignValue = async () => {
		setVitalSignError('');
		if (
			+selectedVitalSign.value < selectedVitalSign.min ||
			+selectedVitalSign.value > selectedVitalSign.max ||
			+selectedVitalSign.secondValue < selectedVitalSign.minSecond ||
			+selectedVitalSign.secondValue > selectedVitalSign.maxSecond
		) {
			if (selectedVitalSign.type !== MeasurementTypes.BLOOD_PRESSURE) {
				setVitalSignError(
					`${intl.formatMessage({ id: 'pleaseWriteCorrectValues' })}: Min: ${selectedVitalSign.min}, Max: ${
						selectedVitalSign.max
					}`
				);
			} else {
				const customError = `${intl.formatMessage({ id: 'pleaseWriteCorrectValues' })}: Min ${intl.formatMessage({
					id: 'systolic',
				})}: ${selectedVitalSign.min}, Max ${intl.formatMessage({ id: 'systolic' })}: ${
					selectedVitalSign.max
				}, Min ${intl.formatMessage({ id: 'diastolic' })}: ${selectedVitalSign.minSecond}, Max ${intl.formatMessage({
					id: 'diastolic',
				})}: ${selectedVitalSign.maxSecond}`;

				setVitalSignError(customError);
			}
			return;
		}
		const data = {
			userId: props.currentCase?.patient.patientUserId,
			deviceType: 'web',
			measurementType: selectedVitalSign.type,
			measurementValue: getFormattedMeasurement(selectedVitalSign),
			measurementUnit: getDefaultUnit(selectedVitalSign.type, selectedVitalSign.unit),
			startDate: moment(),
			endDate: moment(),
			isManuallyInserted: true,
			source: 'web',
		};

		const response = await addMeasurement(data);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		props.setVitalSigns(prevState => {
			const vitalSigns = [...prevState];
			const filtered = vitalSigns.filter(item => item.id !== selectedVitalSign.id);
			return filtered.concat(selectedVitalSign);
		});
		setSelectedVitalSign(null);
	};

	const addVitalSign = () => {
		setSelectedVitalSign(vitalSignsToAdd[0]);
	};

	const handleVitalSignChange = value => {
		setSelectedVitalSign(prevState => ({ ...prevState, value }));
	};

	const handleSecondVitalSignInputChange = secondValue => {
		setSelectedVitalSign(prevState => ({ ...prevState, secondValue }));
	};

	const getIconImg = opt => {
		const tabindex = previousDiagnoses.length > 0 ? menuOptionsTab === opt.id : menuOptionsTab + 1 === opt.id;
		return `${linkToHealthDataIcons}${_.kebabCase(opt.tab)}${tabindex ? '-active' : ''}.svg`;
	};

	const handleAdditionalNotes = (value, formType) => {
		const index = props.forms.findIndex(form => form.id === formType);
		props.setForms(prevState => {
			const formsCopied = [...prevState];
			formsCopied[index].additionalNotes = value;
			return formsCopied;
		});
	};

	const getNoteValue = formType => {
		const form = props.forms.find(curr => curr.id === formType);
		return form ? form.additionalNotes : '';
	};

	const filteredMenuOptions = previousDiagnoses.length === 0 ? menuOptions.filter(option => option.id !== 0) : menuOptions;

	return (
		<>
			<div className='started-visit-wrapper waiting-room-view-inner full-width started-visit-content'>
				<Tabs isVertical={true} className='full-width' activeIndex={menuOptionsTab} onChange={index => setMenuOptionsTab(index)}>
					<p>{translate('menuOptions')}</p>
					<TabList>
						{filteredMenuOptions.map(opt => (
							<Tab>
								<img src={getIconImg(opt)} alt='icon' /> {translate(opt.tab)} <Icon name='arrow_forward_ios' size={12} />{' '}
							</Tab>
						))}
					</TabList>
					<TabPanels>
						{previousDiagnoses.length > 0 && (
							<TabPanel>
								<VisitSummary
									vitalSignsToShow={vitalSignsToAdd}
									previousDiagnoses={previousDiagnoses}
									labCaseAnalyses={props.labCaseAnalyses}
									radiologyCaseTests={props.radiologyCaseTests}
									previousParentalTherapy={props.previousParentalTherapy}
									currentCase={props.currentCase}
								/>
							</TabPanel>
						)}
						<TabPanel>
							<div className='reason-of-visit'>
								<Textarea
									value={props.mainConcerns}
									onChange={event => props.setMainConcerns(event.target.value)}
									name='mainConcerns'
									label={`*${intl.formatMessage({ id: 'mainConcerns' })}`}
									maxLength={300}
									placeholder={intl.formatMessage({ id: 'typeHere' })}
								/>
								<Textarea
									value={props.anamnesis}
									onChange={event => props.setAnamnesis(event.target.value)}
									name='anamnesis'
									label={`*${intl.formatMessage({ id: 'anamnesis' })}`}
									maxLength={500}
									placeholder={intl.formatMessage({ id: 'typeHere' })}
								/>
							</div>
						</TabPanel>
						<TabPanel>
							<div className='allergies'>
								<AllergiesForm
									patientProfile={props.patientProfile}
									foodAllergies={foodAllergies}
									setFoodAllergies={setFoodAllergies}
									medicationAllergies={medicationAllergies}
									setMedicationAllergies={setMedicationAllergies}
									environmentalAllergies={environmentalAllergies}
									setEnvironmentalAllergies={setEnvironmentalAllergies}
									biologicalAllergies={biologicalAllergies}
									setBiologicalAllergies={setBiologicalAllergies}
									setHealthInformation={props.setHealthInformation}
								/>
							</div>
						</TabPanel>
						<TabPanel>
							<div className='visit-vital-signs'>
								<div className='flex flex-space-between'>
									<h3>{translate('vitalSigns')}</h3>
									<span className='flex cursor-pointer --blue-color flex-align-center' onClick={addVitalSign}>
										<i className='material-icons-outlined font-16 right-s'>add</i> {translate('addOtherData')}
									</span>
								</div>

								<div className='full-width flex flex-wrap'>
									{props.vitalSigns?.length === 0 && <p className='no-margin top-15'>{translate('addNecessaryVitalSigns')}</p>}
									{props.vitalSigns.map(sign => (
										<div key={sign.id} className='flex'>
											<img src={`${linkToHealthDataIcons}${sign.img}`} alt='icon' />
											<p className='flex-1'>{translate(sign.title)}</p>
											{sign.value && (
												<h3>
													{sign.value}
													{sign.type === MeasurementTypes.BLOOD_PRESSURE && `/${sign.secondValue}`}
													<span>{sign.type === MeasurementTypes.BLOOD_PRESSURE ? MeasurementUnits.MMHG : sign.unit}</span>
												</h3>
											)}
											<div className='flex' onClick={() => setSelectedVitalSign(sign)}>
												<i className='material-icons' style={{ fontSize: sign.value ? '14px' : '18px' }}>
													{sign.value ? 'edit' : 'add'}
												</i>
											</div>
										</div>
									))}
								</div>
							</div>
						</TabPanel>
						<TabPanel>
							<div className='assessment'>
								<Textarea
									value={props.examinations}
									onChange={event => props.setExaminations(event.target.value)}
									name='examinations'
									label={`*${intl.formatMessage({ id: 'examinations' })}`}
									maxLength={400}
									placeholder={intl.formatMessage({ id: 'typeHere' })}
								/>
								<AddDiagnoses
									currentCase={props.currentCase}
									error={setError}
									diagnoses={props.diagnoses}
									setDiagnoses={props.setDiagnoses}
								/>
								<Textarea
									value={props.recommendations}
									onChange={event => props.setRecommendations(event.target.value)}
									name='recommendations'
									label={intl.formatMessage({ id: 'recommendations' })}
									maxLength={400}
									placeholder={intl.formatMessage({ id: 'typeHere' })}
								/>
							</div>
						</TabPanel>
						<TabPanel>
							<div className='therapy flex'>
								<div className='add-therapy flex'>
									<BackgroundIcon
										onClick={() => addForm(toAddMedicalForms(intl).find(form => form.id === PrimaryCareForms.DRUGS))}
										backgroundImgSize={124}
										className={`drugs ${props.forms.find(form => form.id === PrimaryCareForms.DRUGS) ? 'disabled' : ''}`}
										Icon={<PillIcon color={LightTheme.colors.blueLightOne} strokeWidth={1.2} />}
										top={97}
										right={-5}>
										<span>
											<PillIcon />
										</span>
										<div>
											<span>{translate('prescribeDrugs')}</span>
											<span>
												{translate('useToPrescribe', {
													value: intl.formatMessage({ id: 'drugs' }),
												})}
											</span>
										</div>
										<Icon name='add' size={30} />
									</BackgroundIcon>
									<BackgroundIcon
										onClick={() => addForm(toAddMedicalForms(intl).find(form => form.id === PrimaryCareForms.PTH_INFUSION))}
										backgroundImgSize={124}
										className={`infusions ${
											props.forms.find(form => form.id === PrimaryCareForms.PTH_INFUSION) ? 'disabled' : ''
										}`}
										Icon={<InfusionRoomIcon color={LightTheme.colors.greenLightOne} strokeWidth={1.2} />}
										top={97}
										right={-5}>
										<span>
											<InfusionRoomIcon />
										</span>
										<div>
											<span>{translate('prescribeInfusions')}</span>
											<span>
												{translate('useToPrescribe', {
													value: intl.formatMessage({ id: 'infusion' }),
												})}
											</span>
										</div>
										<Icon name='add' size={30} />
									</BackgroundIcon>
									<BackgroundIcon
										onClick={() => addForm(toAddMedicalForms(intl).find(form => form.id === PrimaryCareForms.PTH_INJECTION))}
										backgroundImgSize={124}
										className={`injections ${
											props.forms.find(form => form.id === PrimaryCareForms.PTH_INJECTION) ? 'disabled' : ''
										}`}
										Icon={<InjectionRoomIcon color={LightTheme.colors.greenFour} strokeWidth={1.2} />}
										top={97}
										right={-5}>
										<span>
											<InjectionRoomIcon />
										</span>
										<div>
											<span>{translate('prescribeInjections')}</span>
											<span>
												{translate('useToPrescribe', {
													value: intl.formatMessage({ id: 'injection' }),
												})}
											</span>
										</div>
										<Icon name='add' size={30} />
									</BackgroundIcon>
								</div>
								{props.forms.length > 0 &&
									props.forms.map((item, index) => (
										<div>
											{[PrimaryCareForms.PTH_INFUSION, PrimaryCareForms.PTH_INJECTION, PrimaryCareForms.DRUGS].includes(
												item.id
											) && (
												<ParenteralTherapyForm
													editable={true}
													key={item.id}
													item={item}
													setAddedForms={props.setForms}
													index={index}
													addedForms={props.forms}
												/>
											)}
										</div>
									))}
							</div>
						</TabPanel>
						<TabPanel>
							<div className='diagnostic flex'>
								<BackgroundIcon
									backgroundImgSize={124}
									className='radiology'
									Icon={<RadiologyIcon color={LightTheme.colors.blueLightOne} strokeWidth={1.2} />}
									top={98}
									right={0}>
									<div>
										<span>
											<RadiologyIcon />
										</span>
										<div>
											<span>{translate('radiology')}</span>
											<span>
												{translate('useToReferPatientTo', {
													value: intl.formatMessage({ id: 'radiology' }),
												})}
											</span>
										</div>
										{editRadiology && (
											<span
												onClick={() => {
													setEditRadiology(false);
													removeForm(PrimaryCareForms.RADIOLOGY);
												}}>
												{' '}
												<Icon name='close' size={30} />
											</span>
										)}
										{!editRadiology && (
											<span
												onClick={() => {
													setEditRadiology(true);
													addForm(toAddMedicalForms(intl).find(form => form.id === PrimaryCareForms.RADIOLOGY));
												}}>
												<Icon name='add' size={30} />
											</span>
										)}
									</div>
									{editRadiology && (
										<>
											<div className='radiology-content'>
												{props.forms.map(
													(item, index) =>
														PrimaryCareForms.RADIOLOGY === item.id && (
															<SearchToAddTest
																editable={true}
																key={item.id}
																item={item}
																setAddedForms={props.setForms}
																index={index}
															/>
														)
												)}
											</div>
											<Textarea
												value={getNoteValue(PrimaryCareForms.RADIOLOGY)}
												onChange={event => handleAdditionalNotes(event.target.value, PrimaryCareForms.RADIOLOGY)}
												name='comments'
												label={intl.formatMessage({ id: 'commentForTechnicians' })}
												maxLength={400}
												placeholder={intl.formatMessage({ id: 'typeHere' })}
											/>
										</>
									)}
								</BackgroundIcon>
								<BackgroundIcon
									backgroundImgSize={124}
									className='laboratory'
									Icon={<LaboratoryIcon color={LightTheme.colors.greenLightOne} strokeWidth={1.2} />}
									top={98}
									right={0}>
									<div>
										<span>
											<LaboratoryIcon />
										</span>
										<div>
											<span>{translate('laboratory')}</span>
											<span>
												{translate('useToReferPatientTo', {
													value: intl.formatMessage({ id: 'laboratory' }),
												})}
											</span>
										</div>
										{editLaboratory && (
											<span
												onClick={() => {
													setEditLaboratory(false);
													removeForm(PrimaryCareForms.LABORATORY);
												}}>
												{' '}
												<Icon name='close' size={30} />
											</span>
										)}
										{!editLaboratory && (
											<span
												onClick={() => {
													setEditLaboratory(true);
													addForm(toAddMedicalForms(intl).find(form => form.id === PrimaryCareForms.LABORATORY));
												}}>
												<Icon name='add' size={30} />
											</span>
										)}
									</div>
									{editLaboratory && (
										<>
											<div className='laboratory-content'>
												{props.forms.map(
													(item, index) =>
														PrimaryCareForms.LABORATORY === item.id && (
															<SearchToAddTest
																editable={true}
																key={item.id}
																item={item}
																setAddedForms={props.setForms}
																index={index}
															/>
														)
												)}
											</div>
											<Textarea
												value={getNoteValue(PrimaryCareForms.LABORATORY)}
												onChange={event => handleAdditionalNotes(event.target.value, PrimaryCareForms.LABORATORY)}
												name='comments'
												label={intl.formatMessage({ id: 'commentForTechnicians' })}
												maxLength={400}
												placeholder={intl.formatMessage({ id: 'typeHere' })}
											/>
										</>
									)}
								</BackgroundIcon>
							</div>
						</TabPanel>
					</TabPanels>
				</Tabs>
			</div>
			<Modal
				display={selectedVitalSign}
				onModalClose={() => setSelectedVitalSign(null)}
				onModalSubmit={submitVitalSignValue}
				isSubmitDisabled={!selectedVitalSign?.value}
				className={classNames(
					'add-measurement-popup add-measurement-popup-pc',
					selectedVitalSign ? selectedVitalSign.enum : '',
					selectedVitalSign ? selectedVitalSign.type : '',
					vitalSignError ? 'add-measurement-error' : ''
				)}>
				{selectedVitalSign && (
					<Form title={intl.formatMessage({ id: selectedVitalSign.title })} onSubmit={e => e.preventDefault()}>
						<div className='add-measurement' />
						<div className='input'>
							<Select
								onSelect={event => {
									const foundVitalSign = vitalSignsToAdd.find(item => item.id === +event.target.value);
									setSelectedVitalSign(foundVitalSign);
									setVitalSignError('');
								}}
								value={selectedVitalSign.id}
								textField='label'
								valueField='value'
								items={vitalSignsToAdd.map(vitalSign => ({ label: vitalSign.title, value: vitalSign.id }))}
								label={intl.formatMessage({ id: 'selectData' })}
								placeholder={intl.formatMessage({ id: 'select' })}
								className='full-width'
							/>
							<Input
								name='measurementValue'
								label={intl.formatMessage({ id: 'inputValue' })}
								value={selectedVitalSign.value}
								validationOptions={{}}
								bottomSpace='15px'
								type='number'
								onChange={event => handleVitalSignChange(event.target.value)}
								onKeyDown={event => handleOnKeyDownNumeric(event, true)}
								step='0.01'
								placeholder={`${selectedVitalSign.min} - ${selectedVitalSign.max}`}
								description={selectedVitalSign.unit}
								min={selectedVitalSign.minimumValue}
								max={selectedVitalSign.maximumValue}
							/>

							{selectedVitalSign.type === MeasurementTypes.BLOOD_PRESSURE && (
								<Input
									onChange={event => handleSecondVitalSignInputChange(event.target.value)}
									onKeyDown={event => handleOnKeyDownNumeric(event, true)}
									name='measurementSecondValue'
									label={intl.formatMessage({ id: 'inputValue' })}
									type='number'
									value={selectedVitalSign.secondValue}
									validationOptions={{}}
									bottomSpace='15px'
									maxLength={255}
									placeholder={`${selectedVitalSign.minSecond} - ${selectedVitalSign.maxSecond}`}
									description={selectedVitalSign.secondUnit}
									min={selectedVitalSign.minimumValue}
									max={selectedVitalSign.maximumValue}
								/>
							)}

							{vitalSignError && <p className='red-error'>{vitalSignError}</p>}
						</div>
					</Form>
				)}
			</Modal>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</>
	);
};

export default CurrentVisit;
