import { AiConfigField, PrimaryMeasurements, SecondaryMeasurements } from 'constants/ai.jsx';
import { PatientAiSetting } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import { getAiFieldBySetting, hasFalsyValue, YesNo } from 'infrastructure/helpers/aiHelper.jsx';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

const AiCustomConfigurations = ({ toggleAiSettings, aiSettingList, deviceId, selectedOptions, settingTypeId, fieldToUpdate }) => {
	const intl = useIntl();
	const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
	const [isCheckboxUpdated, setIsCheckboxUpdated] = useState(false);
	const [decibelLevelOptions, setDecibelLevelOptions] = useState(YesNo(intl));

	const primaryMeasurements = useMemo(
		() => selectedOptions.filter(item => PrimaryMeasurements.includes(item.value)),
		[selectedOptions]
	);

	const secondaryMeasurements = useMemo(
		() => selectedOptions.filter(item => SecondaryMeasurements.includes(item.value)),
		[selectedOptions]
	);

	useEffect(() => {
		const foundDevice = aiSettingList.find(item => item.deviceId === deviceId);
		if (!foundDevice) {
			return;
		}

		const handleSetSelectedCheckboxes = foundSettings => {
			const foundOptions = foundSettings.find(item => item.settingTypeId === settingTypeId)?.[getAiFieldBySetting(settingTypeId)];
			if (foundOptions) {
				const onMountOptions = selectedOptions.filter(item => foundOptions.includes(item.value));
				setSelectedCheckboxes(onMountOptions);
			} else {
				setSelectedCheckboxes([]);
			}
		};

		const handleSetSelectedDecibelLevel = foundSettings => {
			if (settingTypeId !== PatientAiSetting.AI_VOICE_ANALYSIS) {
				return;
			}
			const foundDecibelLevel = foundSettings.find(
				item => item.settingTypeId === PatientAiSetting.AI_VOICE_ANALYSIS
			)?.calculateDecibelLevel;
			if (!hasFalsyValue(foundDecibelLevel)) {
				setDecibelLevelOptions(prevState =>
					prevState?.map(option => ({ ...option, isChecked: option.value === foundDecibelLevel }))
				);
			} else {
				setDecibelLevelOptions(YesNo(intl));
			}
		};

		handleSetSelectedCheckboxes(foundDevice.settings);
		handleSetSelectedDecibelLevel(foundDevice.settings);
	}, [aiSettingList, deviceId, selectedOptions]);

	const isOptionChecked = value => selectedCheckboxes.some(item => item.value === value);

	const handleCheckboxChange = value => {
		setSelectedCheckboxes(prevCheckedItems => {
			const isChecked = prevCheckedItems.some(item => item.value === value);
			if (isChecked && prevCheckedItems.length === 1) return prevCheckedItems;
			return isChecked ? prevCheckedItems.filter(item => item.value !== value) : [...prevCheckedItems, { value }];
		});
		setIsCheckboxUpdated(prevState => !prevState);
	};

	const handleDecibelOptionChange = value => {
		setDecibelLevelOptions(prevState => prevState?.map(option => ({ ...option, isChecked: option.value === value })));
		toggleAiSettings({
			settingTypeId: PatientAiSetting.AI_VOICE_ANALYSIS,
			customValue: value,
			fieldToUpdate: AiConfigField.CALCULATE_DECIBEL_LEVEL,
		});
	};

	useEffect(() => {
		if (selectedCheckboxes.length === 0) {
			return;
		}
		const mappedValues = selectedCheckboxes.map(item => {
			const value = Number(item.value);
			return isNaN(value) ? item.value : value;
		});
		const customValue = settingTypeId === PatientAiSetting.EWS_AI_VITAL_SIGNS ? mappedValues : JSON.stringify(mappedValues);

		toggleAiSettings({
			settingTypeId,
			customValue,
			fieldToUpdate,
		});
	}, [isCheckboxUpdated]);

	const AiCheckboxes = ({ item }) => (
		<div className='register-practice-modal-grid tick-box-active-input' key={item.value}>
			<label className='flex remember-me cursor-pointer'>
				<input
					type='checkbox'
					name={item.label}
					checked={isOptionChecked(item.value)}
					onChange={() => handleCheckboxChange(item.value)}
				/>
				<div className='onb-custom-checkbox-wrapper cursor-pointer top-0 ai-configurations-checkbox'>
					<div className='onb-custom-checkbox' />
				</div>
				<span className='font-14 left-s margin-right-xxl'>{item.label}</span>
			</label>
		</div>
	);

	return (
		<>
			<div className='doctor-profile-settings ai-configurations no-margin'>
				{settingTypeId === PatientAiSetting.AI_VOICE_ANALYSIS && (
					<p className='semi-bold font-14'>{intl.formatMessage({ id: 'sendAiVoiceAlert' })}</p>
				)}
				<div className='flex flex-wrap margin-top-m no-border-bottom'>
					{settingTypeId === PatientAiSetting.EWS_AI_VITAL_SIGNS && (
						<div>
							<p className='semi-bold font-14 no-margin'>{translate('primaryMeasurements')}</p>
							{primaryMeasurements.map(item => (
								<AiCheckboxes item={item} />
							))}
							<p className='semi-bold font-14 top-10'>{translate('secondaryMeasurements')}</p>
							{secondaryMeasurements.map(item => (
								<AiCheckboxes item={item} />
							))}
						</div>
					)}
					{settingTypeId === PatientAiSetting.AI_VOICE_ANALYSIS && selectedOptions.map(item => <AiCheckboxes item={item} />)}
				</div>
			</div>
			{settingTypeId === PatientAiSetting.AI_VOICE_ANALYSIS && (
				<div className='ai-configurations no-margin'>
					<p className='semi-bold font-14'>{intl.formatMessage({ id: 'calculateDecibelLevel' })}</p>
					<div className='flex flex-wrap margin-top-s no-border-bottom margin-bottom-0'>
						{decibelLevelOptions?.map(aiConfig => (
							<div
								key={aiConfig.value.toString()}
								className='flex position-relative consent-from-patient margin-right-m margin-top-s'>
								<label className='remember-me cursor-pointer position-relative flex'>
									<input
										className='auto-width ai-configuration-details'
										type='radio'
										checked={aiConfig.isChecked || false}
										value={aiConfig.value.toString()}
										name='calculateDecibelLevel'
										onChange={() => handleDecibelOptionChange(aiConfig.value)}
									/>
									<div className='onb-custom-checkbox-wrapper add-patient-checkbox-wrapper ai-configurations-checkbox'>
										<div className='onb-custom-checkbox full-border-radius' />
									</div>
									<p className='left-s font-14'>{aiConfig.label}</p>
								</label>
							</div>
						))}
					</div>
				</div>
			)}
		</>
	);
};

export default AiCustomConfigurations;
