import { inputResolutions } from 'calls/virtual-background/helpers/segmentationHelper.js';
import { createTimerWorker } from 'calls/virtual-background/helpers/timerHelper.js';
import { VirtualBackgroundTypes } from 'constants/enums.js';

export const buildCanvas2dPipeline = (
	track,
	sourcePlayback,
	backgroundConfig,
	backgroundImage,
	segmentationConfig,
	canvas,
	bodyPix,
	tflite,
	addFrameEvent
) => {
	const ctx = canvas.getContext('2d');

	const [segmentationWidth, segmentationHeight] = inputResolutions[segmentationConfig.inputResolution];
	const segmentationPixelCount = segmentationWidth * segmentationHeight;
	const segmentationMask = new ImageData(segmentationWidth, segmentationHeight);
	const segmentationMaskCanvas = document.createElement('canvas');
	segmentationMaskCanvas.width = segmentationWidth;
	segmentationMaskCanvas.height = segmentationHeight;
	const segmentationMaskCtx = segmentationMaskCanvas.getContext('2d');

	let postProcessingConfig;

	const render = async () => {
		if (backgroundConfig.type !== VirtualBackgroundTypes.NONE) {
			resizeSource();
		}

		addFrameEvent();

		if (backgroundConfig.type !== VirtualBackgroundTypes.NONE) {
			if (segmentationConfig.model === 'bodyPix') {
				await runBodyPixInference();
			} else {
				runTFLiteInference();
			}
		}

		addFrameEvent();

		runPostProcessing();
	};

	const updatePostProcessingConfig = newPostProcessingConfig => {
		postProcessingConfig = newPostProcessingConfig;
	};

	const cleanUp = () => {
		// simulate a cleanup for this pipeline
		if (!track) {
			return;
		}
		track.timerWorker.clearTimeout(track.renderTimeoutId);
		track.timerWorker.terminate();
	};

	const resizeSource = () => {
		const inputMemoryOffset = tflite._getInputMemoryOffset() / 4;
		segmentationMaskCtx.drawImage(
			sourcePlayback.htmlElement,
			0,
			0,
			sourcePlayback.width,
			sourcePlayback.height,
			0,
			0,
			segmentationWidth,
			segmentationHeight
		);

		if (segmentationConfig.model === 'meet' || segmentationConfig.model === 'mlkit') {
			const imageData = segmentationMaskCtx.getImageData(0, 0, segmentationWidth, segmentationHeight);

			for (let i = 0; i < segmentationPixelCount; i++) {
				tflite.HEAPF32[inputMemoryOffset + i * 3] = imageData.data[i * 4] / 255;
				tflite.HEAPF32[inputMemoryOffset + i * 3 + 1] = imageData.data[i * 4 + 1] / 255;
				tflite.HEAPF32[inputMemoryOffset + i * 3 + 2] = imageData.data[i * 4 + 2] / 255;
			}
		}
	};

	const runBodyPixInference = async () => {
		const segmentation = await bodyPix.segmentPerson(segmentationMaskCanvas);
		for (let i = 0; i < segmentationPixelCount; i++) {
			// Sets only the alpha component of each pixel
			segmentationMask.data[i * 4 + 3] = segmentation.data[i] ? 255 : 0;
		}
		segmentationMaskCtx.putImageData(segmentationMask, 0, 0);
	};

	const runTFLiteInference = () => {
		const outputMemoryOffset = tflite._getOutputMemoryOffset() / 4;

		tflite._runInference();

		for (let i = 0; i < segmentationPixelCount; i++) {
			if (segmentationConfig.model === 'meet') {
				const background = tflite.HEAPF32[outputMemoryOffset + i * 2];
				const person = tflite.HEAPF32[outputMemoryOffset + i * 2 + 1];
				const shift = Math.max(background, person);
				const backgroundExp = Math.exp(background - shift);
				const personExp = Math.exp(person - shift);

				// Sets only the alpha component of each pixel
				segmentationMask.data[i * 4 + 3] = (255 * personExp) / (backgroundExp + personExp); // softmax
			} else if (segmentationConfig.model === 'mlkit') {
				const person = tflite.HEAPF32[outputMemoryOffset + i];
				segmentationMask.data[i * 4 + 3] = 255 * person;
			}
		}
		segmentationMaskCtx.putImageData(segmentationMask, 0, 0);
	};

	const runPostProcessing = () => {
		ctx.globalCompositeOperation = 'copy';
		ctx.filter = 'none';

		if (postProcessingConfig?.smoothSegmentationMask) {
			if (backgroundConfig.type === VirtualBackgroundTypes.BLUR) {
				ctx.filter = 'blur(8px)'; // FIXME Does not work on Safari
			} else if (backgroundConfig.type === 'image') {
				ctx.filter = 'blur(4px)'; // FIXME Does not work on Safari
			}
		}

		if (backgroundConfig.type !== VirtualBackgroundTypes.NONE) {
			drawSegmentationMask();
			ctx.globalCompositeOperation = 'source-in';
			ctx.filter = 'none';
		}

		ctx.drawImage(sourcePlayback.htmlElement, 0, 0);

		if (backgroundConfig.type === VirtualBackgroundTypes.BLUR) {
			blurBackground();
		} else if (backgroundConfig.type === VirtualBackgroundTypes.IMAGE) {
			ctx.save();
			ctx.scale(-1, 1);
			changeBackground();
		}
	};

	const drawSegmentationMask = () => {
		ctx.drawImage(
			segmentationMaskCanvas,
			0,
			0,
			segmentationWidth,
			segmentationHeight,
			0,
			0,
			sourcePlayback.width,
			sourcePlayback.height
		);
	};

	const blurBackground = () => {
		ctx.globalCompositeOperation = 'destination-over';
		ctx.filter = 'blur(8px)'; // FIXME Does not work on Safari
		ctx.drawImage(sourcePlayback.htmlElement, 0, 0);
	};

	const changeBackground = () => {
		const virtualImage = document.createElement('img');
		virtualImage.crossOrigin = 'anonymous';
		virtualImage.src = backgroundImage;
		ctx.globalCompositeOperation = 'destination-over';
		ctx.drawImage(virtualImage, -canvas.width, 0, canvas.width, canvas.height);
		ctx.restore();
	};

	return { render, updatePostProcessingConfig, cleanUp };
};
