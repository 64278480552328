import React, { useEffect, useState } from 'react';
import Form from 'components/Common/Form.jsx';
import Modal from 'components/Common/Modal.jsx';
import { AlertTypes, DeviceListLevel, HealthcareErrorCode } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { getRegionSubTree } from 'api/tree.js';
import { buildTree, searchSectors, showPath } from 'infrastructure/helpers/commonHelpers.js';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Formik } from 'formik';
import * as Yup from 'yup';
import ExistingPatient from 'containers/CareEventsDashboard/ExistingPatient.jsx';
import AddPatient from 'containers/CareEventsDashboard/AddPatient.jsx';
import { createPatient } from 'api/patients.js';
import PopUpAlert from 'components/Common/PopUpAlert.jsx';
import { getNursesAssigned } from 'api/rpm.js';
import { addPatientCareEvent, getCareEventsDashboard } from 'api/careEvents.js';
import { getCompanyDeviceOwner, updatePatientDeviceOwner } from 'api/devices.js';
import Alert from 'components/Common/Alert.jsx';
import { CareEventStatus, CareEventType } from 'constants/care-events.js';

const AddCareEventModal = ({
	isCareEventModalOpen,
	setIsCareEventModalOpen,
	careEventTitle,
	careEventId,
	setIsPatientAdmitted,
}) => {
	const healthSystems = useSelector(state => state.healthSystems.allHealthSystems);
	const userSession = useSelector(state => state.user.userSession);
	const [isAddPatientOpen, setIsAddPatientOpen] = useState(false);
	const [isExistingPatientOpen, setIsExistingPatientOpen] = useState(true);
	const [currentHealthSystem, setCurrentHealthSystem] = useState({ id: null, regionId: null });
	const [expandAllTreeItems, setExpandAllTreeItems] = useState(false);
	const [sectorSearchValue, setSectorSearchValue] = useState('');
	const [tree, setTree] = useState([]);
	const [isTreeViewShown, setIsTreeViewShown] = useState(false);
	const [roomTypeId, setRoomTypeId] = useState(null);
	const [nurses, setNurses] = useState([]);
	const [selectedNurse, setSelectedNurse] = useState(null);
	const [helloDeviceId, setHelloDeviceId] = useState(null);
	const [patientDetails, setPatientDetails] = useState(null);
	const [assignPatientError, setAssignPatientError] = useState(null);
	const [showValidationErrors, setShowValidationErrors] = useState(false);
	const [selectedPriority, setSelectedPriority] = useState(null);
	const [isRequestPending, setIsRequestPending] = useState(false);
	const [error, setError] = useState(null);
	const intl = useIntl();

	useEffect(() => {
		const selectedHs = healthSystems.find(hs => hs.id === userSession.healthSystem.id);
		if (!selectedHs) {
			return;
		}
		setCurrentHealthSystem({ id: userSession.healthSystem.id, regionId: selectedHs.regions[0].id });
	}, [healthSystems, userSession.healthSystem.id]);

	useEffect(() => {
		const getHealthSystemTree = async () => {
			const subTreeResponse = await getRegionSubTree(currentHealthSystem.id, currentHealthSystem.regionId);
			if (subTreeResponse.error) {
				setError(subTreeResponse.error.message);
				return;
			}
			const { healthSystem } = subTreeResponse.organization;
			const treeData = buildTree(healthSystem);
			setTree(treeData);
		};
		if (currentHealthSystem.id) {
			getHealthSystemTree();
		}
	}, [currentHealthSystem]);

	useEffect(() => {
		const sectors = searchSectors(tree, sectorSearchValue.trim());
		setExpandAllTreeItems(!!sectorSearchValue);
		if (sectorSearchValue) {
			showPath(tree, sectors);
		}
	}, [sectorSearchValue, tree]);

	const onSearchRoom = event => {
		setIsTreeViewShown(true);
		setSectorSearchValue(event.target.value);
	};

	const onModalClose = resetForm => {
		setIsCareEventModalOpen(false);
		setSectorSearchValue('');
		setIsTreeViewShown(false);
		setRoomTypeId(null);
		setSelectedNurse(null);
		setError(null);
		resetForm();
	};

	const onLinkClick = option => {
		setRoomTypeId(null);
		if (option.helloDeviceId) {
			setRoomTypeId(option.roomType);
			setHelloDeviceId(option.helloDeviceId);
		}
	};

	const updateTheDeviceOwner = async (userId, deviceId) => {
		setError(null);
		setAssignPatientError(null);
		const deviceParams = {
			userId,
			deviceName: '',
			deviceId,
		};
		const deviceOwner = await updatePatientDeviceOwner(deviceParams);
		if (deviceOwner.error || !deviceOwner.hasSucceeded) {
			const errorMessage = deviceOwner.error?.response.data.message || deviceOwner.error?.message;
			return errorMessage;
		}
		return '';
	};

	const addCareEvent = async patientId => {
		const responseCareEvent = await addPatientCareEvent({
			patientId,
			careEventId,
			careMemberUserId: selectedNurse?.value,
			priorityId: selectedPriority?.value,
		});
		if (responseCareEvent.error) {
			return responseCareEvent.error.message;
		}
		return '';
	};

	const shouldShowValidationErrors = () =>
		(isAddPatientOpen && !roomTypeId) ||
		!selectedNurse ||
		(isExistingPatientOpen && !patientDetails) ||
		(isExistingPatientOpen && careEventId === CareEventType.ADMISSION && !roomTypeId);

	const handleOnSubmit = async (values, { resetForm }) => {
		setIsPatientAdmitted(false);
		setError(null);
		if (shouldShowValidationErrors()) {
			setShowValidationErrors(true);
			return;
		}
		setShowValidationErrors(false);
		setIsRequestPending(true);

		if (isAddPatientOpen) {
			const deviceOwnerRes = await getCompanyDeviceOwner(helloDeviceId);
			if (deviceOwnerRes.error) {
				setError(deviceOwnerRes.error.message);
				return;
			}
			if (!deviceOwnerRes.isDefaultOwner) {
				const careEventMessage = await checkPatientEvents(deviceOwnerRes.userId);
				if (careEventMessage) {
					setAssignPatientError(careEventMessage);
					return;
				}
			}
			const params = {
				firstName: values.firstName,
				lastName: values.lastName,
				email: values.email,
				channelTypeId: roomTypeId,
				healthSystemId: currentHealthSystem.id,
			};
			const response = await createPatient(params);
			if (response.error) {
				if (response.error.response.data.code === HealthcareErrorCode.EMAIL_EXISTS) {
					setError(`${intl.formatMessage({ id: 'emailExists' })}.`);
					return;
				}
				setError(response.error.message);
				return;
			}
			const responseMessage = await updateTheDeviceOwner(response.data.result.userId, helloDeviceId);
			if (responseMessage) {
				setAssignPatientError(responseMessage);
				return;
			}
			const careEventMessage = await addCareEvent(response.data.result.patientId);
			if (careEventMessage) {
				setError(careEventMessage);
				return;
			}
		}
		if (isExistingPatientOpen) {
			if (careEventId === CareEventType.ADMISSION) {
				const deviceOwnerRes = await getCompanyDeviceOwner(helloDeviceId);
				if (deviceOwnerRes.error) {
					setError(deviceOwnerRes.error.message);
					return;
				}
				if (!deviceOwnerRes.isDefaultOwner) {
					const careEventMessage = await checkPatientEvents(deviceOwnerRes.userId);
					if (careEventMessage) {
						setAssignPatientError(careEventMessage);
						return;
					}
				}
				const responseMessage = await updateTheDeviceOwner(patientDetails?.userId, helloDeviceId);
				if (responseMessage) {
					setAssignPatientError(responseMessage);
					return;
				}
			}
			const careEventMessage = await addCareEvent(patientDetails?.value);
			if (careEventMessage) {
				setError(careEventMessage);
				return;
			}
		}
		setRoomTypeId(null);
		setIsCareEventModalOpen(false);
		setSectorSearchValue('');
		setIsTreeViewShown(false);
		setSelectedNurse(null);
		setError(null);
		resetForm();
		setIsPatientAdmitted(true);
		setIsRequestPending(false);
	};

	const getInitialValues = () => {
		if (isAddPatientOpen) {
			return {
				firstName: '',
				lastName: '',
				email: '',
				status: '',
			};
		}
		return {
			status: '',
		};
	};

	const getValidationSchema = () => {
		if (isExistingPatientOpen) {
			return null;
		}
		return Yup.object().shape({
			firstName: Yup.string()
				.required(intl.formatMessage({ id: 'firstNameCannotBeEmpty' }))
				.matches(/^\D+$/, `${intl.formatMessage({ id: 'firstNameNonNumber' })}`)
				.max(30, `${intl.formatMessage({ id: 'maxLengthIs' })} 30`)
				.trim(),
			lastName: Yup.string()
				.required(intl.formatMessage({ id: 'lastNameCannotBeEmpty' }))
				.matches(/^\D+$/, `${intl.formatMessage({ id: 'lastNameNonNumber' })}`)
				.max(30, `${intl.formatMessage({ id: 'maxLengthIs' })} 30`)
				.trim(),
			email: Yup.string()
				.max(100, `${intl.formatMessage({ id: 'maxLengthIs' })} 100`)
				.email(intl.formatMessage({ id: 'invalidEmail' }))
				.required(intl.formatMessage({ id: 'emailCannotBeEmpty' }))
				.trim(),
		});
	};

	const onExistingPatientClick = () => {
		setIsExistingPatientOpen(false);
		setIsAddPatientOpen(true);
		setSectorSearchValue('');
		setIsTreeViewShown(false);
		setSelectedNurse(null);
		setRoomTypeId(null);
	};

	const onAddPatientClick = () => {
		setIsAddPatientOpen(false);
		setIsExistingPatientOpen(true);
		setSectorSearchValue('');
		setIsTreeViewShown(false);
		setSelectedNurse(null);
		setRoomTypeId(null);
	};

	const getNurses = async (value = '') => {
		const response = await getNursesAssigned({ healthSystemId: userSession.healthSystem.id, search: value });
		if (response.error) {
			setError(response.error.message);
			return [];
		}
		const newOptions = response.users.map(user => ({
			value: user.userId,
			label: `${user.firstName} ${user.lastName}`,
			email: user.email,
			profilePicture: user.profilePicture,
		}));
		setNurses(newOptions);
		return newOptions;
	};

	const checkPatientEvents = async userId => {
		const level = DeviceListLevel.HEALTH_SYSTEM;
		const id = currentHealthSystem.id;
		const queryParams = {
			assignedTo: null,
			statusId: null,
			pageIndex: 0,
			patientId: null,
			eventId: careEventId,
			from: null,
			to: null,
			search: '',
			priorityId: null,
			sla: null,
			userId,
		};
		const response = await getCareEventsDashboard({ level, id, queryParams });
		if (response.error) {
			return response.error.message;
		}
		if (
			response.patientCareEvents.length > 0 &&
			response.patientCareEvents.some(item => [CareEventStatus.NEW, CareEventStatus.IN_PROGRESS].includes(item.statusId))
		) {
			return intl.formatMessage({ id: 'activeCareEvent' });
		}
		return '';
	};

	return (
		<Formik
			enableReinitialize={true}
			initialValues={getInitialValues()}
			validationSchema={getValidationSchema()}
			onSubmit={handleOnSubmit}>
			{formikProps => {
				const { values, errors, handleSubmit, handleChange } = formikProps;
				return (
					<Modal
						display={isCareEventModalOpen}
						position='center'
						closeButtonText={translate('cancel')}
						className='admit-patient-modal edit-rpm-modal'
						onModalClose={() => onModalClose(formikProps.resetForm)}
						isSubmitDisabled={isRequestPending && !error && !assignPatientError}
						onModalSubmit={handleSubmit}>
						<Form className='admission-modal-form' onSubmit={event => event.preventDefault()}>
							<div className='add-newpatient'>
								<h4>{translate(careEventTitle)}</h4>
								{isExistingPatientOpen && (
									<div className='cursor-pointer care-event-modal-btn' onClick={onExistingPatientClick}>
										<img src={`${healthCareCdnUrl}care-events/add-patient.svg`} alt='test' />
									</div>
								)}
								{isAddPatientOpen && (
									<div className='cursor-pointer care-event-modal-btn' onClick={onAddPatientClick}>
										<img src={`${healthCareCdnUrl}care-events/existing-patient.svg`} alt='test' />
									</div>
								)}
							</div>
							{isExistingPatientOpen && (
								<ExistingPatient
									getNurses={getNurses}
									nurses={nurses}
									selectedNurse={selectedNurse}
									setSelectedNurse={setSelectedNurse}
									setPatientDetails={setPatientDetails}
									showValidationErrors={showValidationErrors}
									tree={tree}
									roomTypeId={roomTypeId}
									onLinkClick={onLinkClick}
									onSearchRoom={onSearchRoom}
									isTreeViewShown={isTreeViewShown}
									sectorSearchValue={sectorSearchValue}
									expandAllTreeItems={expandAllTreeItems}
									careEventId={careEventId}
									setSelectedPriority={setSelectedPriority}
									selectedPriority={selectedPriority}
								/>
							)}
							{isAddPatientOpen && (
								<AddPatient
									tree={tree}
									sectorSearchValue={sectorSearchValue}
									onSearchRoom={onSearchRoom}
									expandAllTreeItems={expandAllTreeItems}
									isTreeViewShown={isTreeViewShown}
									onLinkClick={onLinkClick}
									handleChange={handleChange}
									values={values}
									errors={errors}
									roomTypeId={roomTypeId}
									getNurses={getNurses}
									nurses={nurses}
									selectedNurse={selectedNurse}
									setSelectedNurse={setSelectedNurse}
									setSelectedPriority={setSelectedPriority}
									selectedPriority={selectedPriority}
								/>
							)}
							<PopUpAlert
								alertType={AlertTypes.DANGER}
								display={error}
								onAlertClose={() => setError(null)}
								contentText={error}
								isSilent={true}
								center={true}
							/>
							<Alert display={assignPatientError} message={assignPatientError} variant='neutral' hideCloseButton />
						</Form>
					</Modal>
				);
			}}
		</Formik>
	);
};

export default AddCareEventModal;
