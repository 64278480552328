import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import translate from 'i18n-translations/translate.jsx';
import { getHealthSystemHospitals } from 'api/userIdleConfigurations.js';
import FormInput from 'components/Common/FormInput.jsx';
import { doNotAllowSpaceAsFirstCharacter } from 'infrastructure/helpers/commonHelpers.js';
import { DeviceListLevel } from 'constants/enums.js';
import { ECareConfigurationTypes } from 'constants/e-care-manager-types.js';
import Alert from 'components/Common/Alert.jsx';

const HillRoomAddEdit = props => {
	const intl = useIntl();
	const allHealthSystems = useSelector(state => state.healthSystems.allHealthSystems);
	const [selectedHealthSystem, setSelectedHealthSystem] = useState(
		props?.itemToEdit?.healthSystemId
			? {
					value: props.itemToEdit.healthSystemId,
					label: allHealthSystems.find(item => item.id === props.itemToEdit.healthSystemId).name,
			  }
			: { value: '0', label: 'All' }
	);
	const [selectedHospital, setSelectedHospital] = useState({ value: '0', label: 'All' });
	const [hospitals, setHospitals] = useState([]);
	const [host, setHost] = useState(props?.itemToEdit?.host ?? '');
	const [port, setPort] = useState(props?.itemToEdit?.port ?? '');
	const [path, setPath] = useState(props?.itemToEdit?.path ?? '');
	const [secretKey, setSecretKey] = useState(props?.itemToEdit?.secretKey ?? '');
	const [partnerName, setPartnerName] = useState(props?.itemToEdit?.partnerName ?? '');
	const [isLoading, setIsLoading] = useState(false);
	const [isSubscribed, setIsSubscribed] = useState(!!props?.itemToEdit?.isSubscribed);
	const [error, setError] = useState('');

	useEffect(() => {
		const fetchHospitals = async () => {
			if (!selectedHealthSystem) {
				return;
			}
			let hsHospitals = [];
			if (selectedHealthSystem.value !== '0') {
				setIsLoading(true);
				const res = await getHealthSystemHospitals(selectedHealthSystem.value);
				if (res.error) {
					setError(res.error.message);
				} else {
					hsHospitals = res;
				}
			}
			const hospitalArray = hsHospitals.map(hospital => ({ value: hospital.id, label: hospital.name }));
			hospitalArray.unshift({ value: '0', label: intl.formatMessage({ id: 'all' }) });
			const foundHospital = hospitalArray.find(item => item.value === props?.itemToEdit?.hospitalId);
			setHospitals(hospitalArray);
			setSelectedHospital(foundHospital || hospitalArray[0]);
			setIsLoading(false);
		};
		fetchHospitals();
	}, [selectedHealthSystem]);

	useEffect(() => {
		props.setConfigParams({
			configurationTypeId: ECareConfigurationTypes.REID,
			nodeId: getNodeId().value !== '0' ? getNodeId().value : null,
			parentNodeId: selectedHospital.value !== '0' ? selectedHealthSystem.value : null,
			nodeName: getNodeId().label,
			nodeLevel: getNodeLevel(),
			configJson: JSON.stringify({ host, port, path, secretKey, isSubscribed, partnerName }),
		});
	}, [selectedHealthSystem, selectedHospital, host, port, path, secretKey, props.setConfigParams, isSubscribed, partnerName]);

	const getNodeId = () => (selectedHospital.value !== '0' ? selectedHospital : selectedHealthSystem);

	const getNodeLevel = () => {
		if (selectedHospital.value !== '0') {
			return DeviceListLevel.HOSPITAL;
		}
		if (selectedHealthSystem.value !== '0') {
			return DeviceListLevel.HEALTH_SYSTEM;
		}
		return null;
	};

	const transformArray = array => {
		if (!array) {
			return [];
		}
		const newArray = array.map(item => ({ value: item.id, label: item.name || item.value }));
		newArray.unshift({ value: '0', label: intl.formatMessage({ id: 'all' }) });
		return newArray;
	};

	return (
		<>
			<div className='input'>
				<p className='label'>{translate('selectHealthSystem')}</p>
				<p className='font-14'>{translate('selectHSForConfiguration')}</p>
				<Select
					value={selectedHealthSystem}
					placeholder={intl.formatMessage({ id: 'selectHealthSystem' })}
					classNamePrefix='react-select'
					options={transformArray(allHealthSystems)}
					onChange={setSelectedHealthSystem}
				/>
			</div>

			<div className='input'>
				<p className='label'>{translate('selectHospital')}</p>
				<p className='font-14'>{translate('chooseHospitalForChanges')}</p>
				<Select
					isDisabled={hospitals.length === 1 || isLoading}
					value={selectedHospital}
					placeholder={intl.formatMessage({ id: 'selectHealthSystem' })}
					classNamePrefix='react-select'
					options={hospitals}
					onChange={setSelectedHospital}
				/>
			</div>
			<div className='input'>
				<FormInput
					text={`${intl.formatMessage({ id: 'host' })}*`}
					id='preExistingCondition'
					placeholder={intl.formatMessage({ id: 'host' })}
					type='text'
					value={host}
					onChange={event => setHost(event.target.value)}
					maxLength={256}
					onKeyDown={doNotAllowSpaceAsFirstCharacter}
				/>
			</div>
			<div className='input'>
				<FormInput
					text={`${intl.formatMessage({ id: 'port' })}*`}
					id='preExistingCondition'
					placeholder={intl.formatMessage({ id: 'port' })}
					type='text'
					value={port}
					onChange={event => setPort(event.target.value)}
					maxLength={256}
					onKeyDown={doNotAllowSpaceAsFirstCharacter}
				/>
			</div>
			<div className='input'>
				<FormInput
					text={`${intl.formatMessage({ id: 'path' })}*`}
					id='preExistingCondition'
					placeholder={intl.formatMessage({ id: 'path' })}
					type='text'
					value={path}
					onChange={event => setPath(event.target.value)}
					maxLength={256}
					onKeyDown={doNotAllowSpaceAsFirstCharacter}
				/>
			</div>
			<div className='input'>
				<FormInput
					text={`${intl.formatMessage({ id: 'secretKey' })}*`}
					id='preExistingCondition'
					placeholder={intl.formatMessage({ id: 'secretKey' })}
					type='text'
					value={secretKey}
					onChange={event => setSecretKey(event.target.value)}
					maxLength={256}
					onKeyDown={doNotAllowSpaceAsFirstCharacter}
				/>
			</div>
			<div className='input'>
				<FormInput
					text={`${intl.formatMessage({ id: 'partnerName' })}*`}
					id='preExistingCondition'
					placeholder={intl.formatMessage({ id: 'partnerName' })}
					type='text'
					value={partnerName}
					onChange={event => setPartnerName(event.target.value)}
					maxLength={256}
					onKeyDown={doNotAllowSpaceAsFirstCharacter}
					error={props?.fieldErrors?.partnerName}
					touched={props?.fieldErrors?.partnerName}
				/>
			</div>
			<div className='flex flex-align-center'>
				<p className='font-18'>{translate('isSubscribed')}</p>
				<div
					className='rounded-slider-switch'
					style={{ marginLeft: 'var(--spacing-m)' }}
					onClick={() => setIsSubscribed(prevState => !prevState)}>
					<input type='checkbox' checked={isSubscribed} onChange={() => null} />
					<span className='rounded-slider' />
				</div>
			</div>
			{/* Host, Port, Path, Secret Key, */}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</>
	);
};

export default HillRoomAddEdit;
