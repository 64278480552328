import styled from 'styled-components';
import useConferenceConfigurations from 'calls/hooks/useConferenceConfigurations.js';
import { IconButton } from 'calls/components/index.js';
import LightTheme from 'calls/styles/LightTheme.js';
import DarkTheme from 'calls/styles/DarkTheme.js';

/**
 * @type {import('styled-components').StyledComponent<"div", any, {  $isDarkMode: boolean }, never>}
 */
const StyledCallButton = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	> span {
		font-size: 12px;
		font-weight: 400;
		line-height: 14px;
		text-transform: capitalize;
		padding-top: ${LightTheme.spacing[1]}px;
		color: ${props => (props.$isDarkMode ? DarkTheme.colors.grayFive : LightTheme.colors.grayFive)};
	}
`;

/**
 * @param {object} props
 * @param {string} [props.text]
 * @param {any} [props.children]
 * @param {object} props.buttonProps
 * @param {string} [props.buttonProps.id=null]
 * @param {any} [props.buttonProps.children]
 * @param {string} [props.buttonProps.icon]
 * @param {string} props.buttonProps.background
 * @param {string} props.buttonProps.color
 * @param {number} [props.buttonProps.size]
 * @param {boolean} [props.buttonProps.disabled=null]
 * @param {(...args: any) => void} [props.buttonProps.onClick=null]
 * @param {React.CSSProperties} [props.textStyle]
 */
const CallButton = ({ text, children, buttonProps, textStyle }) => {
	const conferenceConfigurations = useConferenceConfigurations();

	return (
		<StyledCallButton $isDarkMode={conferenceConfigurations.isDarkMode}>
			<IconButton {...buttonProps}>{children}</IconButton>
			{text && <span style={textStyle}>{text}</span>}
		</StyledCallButton>
	);
};

export default CallButton;
