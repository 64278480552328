import { FileTypes } from 'constants/enums.js';
import { APP_CONFIG } from 'constants/global-variables.js';
import { isDocumentAttachment, isPictureAttachment, isVideoAttachment } from 'infrastructure/helpers/commonHelpers.js';

export function getMediaCdnURL(fileName, fileType) {
	if (isDocumentAttachment(fileType)) {
		return `${APP_CONFIG.mediaCdnBaseUrl}files/${fileName}`;
	}
	if (isPictureAttachment(fileType)) {
		return `${APP_CONFIG.mediaCdnBaseUrl}images/${fileName}`;
	}
	if (isVideoAttachment(fileType)) {
		return `${APP_CONFIG.mediaCdnBaseUrl}videos/${fileName}`;
	}
	throw new Error('Invalid file type');
}

export const getCroppedImg = (image, cropObj, fileName) => {
	let fileUrl;
	const canvas = document.createElement('canvas');
	canvas.width = cropObj.width;
	canvas.height = cropObj.height;
	const ctx = canvas.getContext('2d');
	const scaleX = image.naturalWidth / image.width;
	const scaleY = image.naturalHeight / image.height;
	ctx.drawImage(
		image,
		cropObj.x * scaleX,
		cropObj.y * scaleY,
		cropObj.width * scaleX,
		cropObj.height * scaleY,
		0,
		0,
		canvas.width,
		canvas.height
	);
	return new Promise((resolve, reject) => {
		canvas.toBlob(blob => {
			if (!blob) {
				return;
			}
			blob.name = fileName;
			window.URL.revokeObjectURL(fileUrl);
			fileUrl = window.URL.createObjectURL(blob);
			const reader = new FileReader();
			reader.onerror = reject;
			reader.onloadend = () => resolve(fileUrl);
			reader.readAsDataURL(blob);
		}, 'image/png');
	});
};

export const MAX_ALLOWED_BACKGROUND_IMAGES = 10;
const MAX_ALLOWED_FILE_SIZE_MB = 5;
const ONE_MINUTE = 60000;

export const validateFile = (file, intl, setErrorMessage) => {
	if (!file) {
		return false;
	}

	const allowedFormats = [FileTypes.PNG, FileTypes.JPEG];
	const fileExtension = file.name.split('.').pop();

	if (!allowedFormats.includes(file.type)) {
		setErrorMessage(intl.formatMessage({ id: 'unsupportedUploadFormat' }, { value: fileExtension }));
		setTimeout(() => setErrorMessage(''), ONE_MINUTE);
		return false;
	}

	const fileSizeInMB = file.size / 1024 / 1024;
	if (fileSizeInMB > MAX_ALLOWED_FILE_SIZE_MB) {
		setErrorMessage(intl.formatMessage({ id: 'maxAllowedFileSize' }, { value: MAX_ALLOWED_FILE_SIZE_MB }));
		setTimeout(() => setErrorMessage(''), ONE_MINUTE);
		return false;
	}

	setErrorMessage('');
	return true;
};
