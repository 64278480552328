const SleepQualityIcon = props => (
	<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
		<path
			d='M0.666016 9.22057C0.666016 12.2284 3.10432 14.6667 6.11211 14.6667C8.57921 14.6667 10.6632 13.0262 11.3327 10.7766C11.3327 10.7766 8.83516 11.9437 6.11211 9.22057C3.38906 6.49745 4.55609 4 4.55609 4C2.30647 4.66952 0.666016 6.75347 0.666016 9.22057Z'
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path d='M12.791 4.11719H15.3247L12.791 7.17982H15.3247' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
		<path d='M8 1.33203H11.4149L8 5.45266H11.4149' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
	</svg>
);

export default SleepQualityIcon;
