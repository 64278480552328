import classNames from 'classnames';
import ProfilePicture from 'components/Common/ProfilePicture.jsx';
import { Input } from 'components/index.js';
import { generateCustomStyles } from 'constants/react-select-style.js';
import translate from 'i18n-translations/translate.jsx';
import useOutsideClick from 'infrastructure/helpers/useOutsideClick.js';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import Select from 'react-select';

const CustomMenuList = props => {
	const inputRef = useRef(null);
	const intl = useIntl();

	useEffect(() => {
		if (props.selectProps.menuIsOpen) {
			handleFocus();
		}
	}, [props.selectProps.menuIsOpen]);

	const handleFocus = () => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	};

	return (
		<div className='ms-menu-list'>
			{props.menulistTitle && <span>{props.menulistTitle}</span>}
			{props.searchable && (
				<Input
					type='text'
					variant='filled'
					inputRef={inputRef}
					placeholder={props.searchPlaceHolder ?? intl.formatMessage({ id: 'search' })}
					value={props.selectProps.inputValue}
					onChange={e =>
						props.selectProps.onInputChange(e.target.value, {
							action: 'input-change',
							prevInputValue: '',
						})
					}
					onClick={handleFocus}
					prefixIcon='search'
				/>
			)}
			<div ref={props.lisRef}>{props.children}</div>
		</div>
	);
};

const CustomOption = ({ children, lastSelectedValue, reorderSelected, ...props }) => {
	const profileData = props.data?.profile;

	return (
		<label
			key={props.value}
			className={classNames('ms-custom-option', {
				'is-last-selected': reorderSelected && props?.value === lastSelectedValue?.value,
				disabled: props.disabled && (!props.selectLimit || !props.isSelected),
			})}
			onClick={e => e.stopPropagation()}
			onChange={() => props.selectOption(props)}>
			<input type='checkbox' name='checkbox' checked={props.isSelected} onChange={() => null} />
			{profileData && (
				<ProfilePicture
					className='ms-img'
					firstName={profileData.firstName}
					lastName={profileData.lastName}
					profilePicture={profileData.profilePicture}
					isActive={profileData.isActive}
				/>
			)}
			<div>
				<span className={classNames('ms-option-label', { 'has-desc': props.data?.description })}>{props.label}</span>
				{props.data?.description && <span className='ms-option-description'>{props.data?.description}</span>}
			</div>
		</label>
	);
};

const CustomValueContainer = ({ children, placeholder, selectedItemsPlaceholder, selectedOptions, ...props }) => {
	const intl = useIntl();
	const profileData = selectedOptions[0]?.data?.profile;

	return (
		<div
			className={classNames('mss-value-container', {
				placeholder: selectedOptions.length === 0,
				'item-selected': selectedOptions.length === 1,
			})}>
			{selectedOptions.length === 0 && <span> {placeholder ?? intl.formatMessage({ id: 'select' })}</span>}
			{selectedOptions.length === 1 && (
				<label className='ms-custom-option' onChange={props.selectOption}>
					{profileData && (
						<ProfilePicture
							className='ms-img'
							firstName={profileData.firstName}
							lastName={profileData.lastName}
							profilePicture={profileData.profilePicture}
							isActive={profileData.isActive}
						/>
					)}
					<div>
						<span className='ms-option-label'>{selectedOptions[0].label}</span>
						{selectedOptions[0].data?.description && (
							<span className='ms-option-description'>{selectedOptions[0].data?.description}</span>
						)}
					</div>
				</label>
			)}
			{selectedOptions.length > 1 && (
				<span className='ms-items-selected'>
					{selectedOptions.length}
					{` `}
					{selectedItemsPlaceholder
						? translate('valueItemSelected', { value: selectedItemsPlaceholder })
						: translate('selectedItems')}
				</span>
			)}
		</div>
	);
};

const MultiSelectCheckboxes = props => {
	const intl = useIntl();
	const dropdownRef = useRef(null);
	const [options, setOptions] = useState(props.options);
	const [lastSelectedValue, setLastSelectedValue] = useState({ value: -1 });
	const [isDropdownOpen, setIsDropdownOpen] = useState(props.isMenuOpen);
	const [isSelectMoreDisabled, setIsSelectMoreDisabled] = useState(false);
	const customStyles = generateCustomStyles({ darkMode: props.darkMode, menuMaxHeight: 300 });
	const listRef = useRef(null);

	const reorderOptions = selectedValues => {
		const selectedOptions = options.filter(option => selectedValues.some(selected => selected.value === option.value));
		const remainingOptions = options.filter(option => !selectedValues.some(selected => selected.value === option.value));
		setLastSelectedValue(selectedOptions[selectedOptions.length - 1]);
		return [...selectedOptions, ...remainingOptions];
	};

	useOutsideClick(dropdownRef, () => {
		if (isDropdownOpen && !props.keepOpenOnClickOutside) {
			setIsDropdownOpen(false);
		}
	});

	useEffect(() => {
		setOptions(props.options);
	}, [props.options]);

	useEffect(() => {
		if (props.menuScrollPosition && listRef.current) {
			listRef.current.scrollTop = props.menuScrollPosition;
		}
	}, [props.menuScrollPosition]);

	const handleOnChange = (value, { action }) => {
		if (props.menuScrollPosition !== undefined) {
			props.setMenuScrollPosition(listRef.current.scrollTop);
		}

		if (props.maxSelect && props.maxSelect === props.selectedOptions.length && action === 'select-option') {
			setIsSelectMoreDisabled(true);
			return;
		}

		props.onChange(value);

		if (['deselect-option', 'clear'].includes) {
			if (props.closeOnDeselectedLastItem && props.selectedOptions.length === 1) {
				setIsDropdownOpen(false);
			}

			if (props.maxSelect && props.selectedOptions.length <= props.maxSelect) {
				setIsSelectMoreDisabled(false);
			}
		}
	};

	return (
		<div ref={dropdownRef} className={classNames(props.className, 'multiselect-checkboxes')}>
			{props.errorMessage && (isSelectMoreDisabled || props.disabled) && <span className='red-error'>{props.errorMessage}</span>}
			<Select
				name={props.name}
				className={classNames('multiselect-search', { dark: props.darkMode })}
				onMenuOpen={() => {
					setIsDropdownOpen(true);
					setOptions(props.reorderSelected ? reorderOptions(props.selectedOptions) : props.options);
				}}
				menuIsOpen={isDropdownOpen}
				onMenuClose={() => setIsDropdownOpen(false)}
				value={props.selectedOptions}
				options={options}
				noOptionsMessage={() => props.noOptionsMessage ?? intl.formatMessage({ id: 'noOptions' })}
				backspaceRemovesValue={false}
				onBlur={props.onBlur}
				isMulti={true}
				closeMenuOnSelect={false}
				hideSelectedOptions={false}
				onChange={handleOnChange}
				isClearable={props.isClearable}
				styles={customStyles}
				isDisabled={props.isDisabled}
				components={{
					MenuList: menuProps => (
						<CustomMenuList lisRef={listRef} menulistTitle={props.menulistTitle} searchable={props.searchable} {...menuProps} />
					),
					Option: optProps => (
						<CustomOption
							lastSelectedValue={lastSelectedValue}
							reorderSelected={props.reorderSelected}
							disabled={isSelectMoreDisabled || optProps.isDisabled}
							selectLimit={props.maxSelect}
							{...optProps}
						/>
					),
					ValueContainer: valProps => (
						<CustomValueContainer
							placeholder={props.placeholder}
							selectedItemsPlaceholder={props.selectedItemsPlaceholder}
							selectOption={() => props.selectOption(props)}
							selectedOptions={props.selectedOptions || valProps.getValue()}
							{...valProps}
						/>
					),
				}}
			/>
		</div>
	);
};

export default MultiSelectCheckboxes;
