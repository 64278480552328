import { gatewayApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';

export const getDeviceList = async ({
	healthSystemId,
	regionId = null,
	hospitalId = null,
	departmentId = null,
	floorId = null,
	roomId = null,
	pageIndex = 0,
	pageSize = 100,
}) => {
	const companyId = getCompanyId();
	let url = `/v1/tenants/${companyId}/health-systems/${healthSystemId}`;
	if (roomId) {
		url += `/regions/${regionId}/hospitals/${hospitalId}/departments/${departmentId}/floors/${floorId}/rooms/${roomId}/assigned-devices`;
	} else if (floorId) {
		url += `/regions/${regionId}/hospitals/${hospitalId}/departments/${departmentId}/floors/${floorId}/assigned-devices`;
	} else if (departmentId) {
		url += `/regions/${regionId}/hospitals/${hospitalId}/departments/${departmentId}/assigned-devices`;
	} else if (hospitalId) {
		url += `/regions/${regionId}/hospitals/${hospitalId}/assigned-devices`;
	} else if (regionId) {
		url += `/regions/${regionId}/assigned-devices`;
	} else {
		url += `/assigned-device`;
	}
	url += `?page-index=${pageIndex}&page-size=${pageSize}`;
	try {
		const { data } = await gatewayApi.get(url);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getUnassociatedDevices = async () => {
	try {
		const { data } = await gatewayApi.get(`/v1/organizations/${getCompanyId()}/unassociated-devices`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const deleteDevice = async deviceId => {
	try {
		const { data } = await gatewayApi.delete(`/v1/organizations/${getCompanyId()}/devices/${deviceId}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getDeviceSettings = async deviceId => {
	try {
		const { data } = await gatewayApi.get(`/v1/organizations/${getCompanyId()}/devices/${deviceId}/settings`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getDeviceSetting = async (deviceId, settingTypeId) => {
	try {
		const { data } = await gatewayApi.get(
			`/v1/organizations/${getCompanyId()}/devices/${deviceId}/settings?settingTypeId=${settingTypeId}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const updateDeviceSettings = async ({ deviceId, settingTypeId, settingValue }) => {
	try {
		const { data } = await gatewayApi.put(`/v1/organizations/${getCompanyId()}/devices/${deviceId}/settings`, {
			settingTypeId,
			settingValue,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const sendCommand = async ({ deviceId, command, reason = null, dynamicData = null }) => {
	try {
		const { data } = await gatewayApi.post(`/v1/devices/${deviceId}/command`, {
			command,
			reason,
			dynamicData,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const updatePatientDeviceOwner = async ({ userId, deviceName, deviceId }) => {
	try {
		const { data } = await gatewayApi.put(`/v1.1/healthcare/organizations/${getCompanyId()}/devices/${deviceId}/owner`, {
			userId,
			deviceName,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const deletePatientDeviceOwner = async deviceId => {
	try {
		const { data } = await gatewayApi.delete(`/v1.1/healthcare/organizations/${getCompanyId()}/devices/${deviceId}/owner`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getDeviceRebootLogs = async deviceId => {
	try {
		const { data } = await gatewayApi.get(`/v1/devices/${deviceId}/logs/type/command`);
		return data.deviceCommandLogs;
	} catch (error) {
		return { error };
	}
};

export const addUpdateCompanionDevice = async ({ solHelloDeviceId, roomName, hospitalId, departmentId, floorId, roomId }) => {
	try {
		const { data } = await gatewayApi.put(
			`/v1/devices/companion/${getCompanyId()}/hospitals/${hospitalId}/departments/${departmentId}/floors/${floorId}/rooms/${roomId}/location`,
			{
				solHelloDeviceId,
				roomName,
			}
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const deleteCompanionDevice = async deviceId => {
	try {
		const { data } = await gatewayApi.delete(`/v1/devices/companion/${getCompanyId()}/devices/${deviceId}/location`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getCompanionDevicesByRoom = async (roomId, companionType = '') => {
	try {
		const { data } = await gatewayApi.get(
			`/v1/devices/companion/${getCompanyId()}/rooms/${roomId}?companionModeId=${companionType}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getUnassignedCompanionDevices = async (companionType, search) => {
	try {
		const { data } = await gatewayApi.get(
			`/v1/devices/companion/${getCompanyId()}?unassigned=true&companionModeId=${companionType}&search=${search}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getCompanionModes = async () => {
	try {
		const { data } = await gatewayApi.get(`/v1/devices/companion/modes`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getDevicesFromMRN = async mrnId => {
	try {
		const { data } = await gatewayApi.get(`v1.1/healthcare/organizations/${getCompanyId()}/patients/${mrnId}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getCompanyDeviceOwner = async deviceId => {
	try {
		const { data } = await gatewayApi.get(`v1/organizations/${getCompanyId()}/devices/${deviceId}/owner`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getDeviceAllowlist = async deviceId => {
	try {
		const { data } = await gatewayApi.get(`v1/devices/${deviceId}/allowlist/logs`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const assignDevices = async ({ hospitalId, departmentId, floorId, roomId, devices }) => {
	try {
		const url = `/v1/organizations/${getCompanyId()}/hospitals/${hospitalId}/departments/${departmentId}/floors/${floorId}/rooms/${roomId}/devices`;
		const { data } = await gatewayApi.put(url, {
			devices,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const getDeviceRemoteApps = async deviceId => {
	try {
		const { data } = await gatewayApi.get(`v1/devices/${deviceId}/remote-apps/sessions`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const terminateDeviceRemoteApp = async (deviceId, sessionId) => {
	try {
		const { data } = await gatewayApi.delete(`/v1/devices/${deviceId}/remote-apps/sessions/${sessionId}`);
		return data;
	} catch (error) {
		return { error };
	}
};
