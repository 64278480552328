import { useIntl } from 'react-intl';
import { read, utils } from 'xlsx';
import { isEmailValid } from 'infrastructure/helpers/commonHelpers.js';
import translate from 'i18n-translations/translate.jsx';
import Alert from 'components/Common/Alert.jsx';

const BulkUsersExelFile = ({ hasEmailError, setHasEmailError, emailList, setEmailList, uploadedFileRef }) => {
	const intl = useIntl();
	const errorEnums = { EMAIL_NOT_VALID: 1, EMAIL_DUPLICATED: 2 };
	const findDuplicates = items => [
		...new Set(items.filter((item, index) => isEmailValid(item) && items.indexOf(item) !== index)),
	];

	const handleOnChange = async e => {
		setHasEmailError(false);
		setEmailList([]);
		const file = e.target.files[0];
		const data = await file.arrayBuffer();
		const workBook = read(data);
		const workSheet = workBook.Sheets[workBook.SheetNames[0]];
		const jsonData = utils.sheet_to_json(workSheet, { header: 1 });
		const emails = jsonData.flat();
		if (emails.length === 0) {
			setHasEmailError(intl.formatMessage({ id: 'noEmailsExelFile' }));
		}
		const result = findDuplicates(emails).map(item => ({ email: item, errorType: errorEnums.EMAIL_DUPLICATED }));
		for (let i = 0; i < emails.length; i += 1) {
			if (!isEmailValid(emails[i])) {
				result.push({ email: emails[i], errorType: errorEnums.EMAIL_NOT_VALID });
			} else {
				result.push({ email: emails[i], errorType: null });
			}
		}
		setEmailList(result);
	};

	return (
		<div>
			<div className='input no-margin'>
				<p className='label'>{translate('bulkUpload')}</p>
				<p>{translate('description')}</p>
			</div>
			<input type='file' accept='.xlsx' onChange={handleOnChange} ref={uploadedFileRef} />
			{emailList.filter(item => item.errorType).length > 0 && (
				<>
					<div className='input no-margin'>
						<p className='label top-30'>{translate('emailList')}</p>
					</div>
					<div className='flex bulk-errors-table-item title'>
						<div className='flex-1'>
							<p>{translate('email')}</p>
						</div>
						<div className='flex-1'>
							<p>{translate('errorType')}</p>
						</div>
					</div>
				</>
			)}
			{emailList
				.filter(item => item.errorType)
				.map(error => (
					<div className='flex bulk-errors-table-item'>
						<div className='flex-1'>
							<p>{error.email}</p>
						</div>
						<div className='flex-1'>
							<p className='--red-color'>
								{intl.formatMessage({ id: error.errorType === errorEnums.EMAIL_NOT_VALID ? 'emailNotValid' : 'emailDuplicated' })}
							</p>
						</div>
					</div>
				))}
			{hasEmailError && (
				<Alert variant='dark' display={hasEmailError} message={hasEmailError} onClose={() => setHasEmailError(null)} />
			)}
		</div>
	);
};

export default BulkUsersExelFile;
