import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import 'views/Onboarding/css/onboarding.css';
import { useIntl } from 'react-intl';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import Alert from 'components/Common/Alert.jsx';
import Select from 'components/Common/Select.jsx';
import { getHelloHealthCompaniesList } from 'api/organization.js';
import Button from 'components/Common/Button.jsx';
import translate from 'i18n-translations/translate.jsx';

const SelectPatientCompany = () => {
	const [selectedCompany, setSelectedCompany] = useState(null);
	const [companies, setCompanies] = useState([]);
	const [companyError, setCompanyError] = useState('');
	const [alertError, setAlertError] = useState(null);
	const history = useHistory();
	const location = useLocation();
	const intl = useIntl();
	const onboardingImgUrl = 'https://static.solaborate.com/onboarding-process/';

	useEffect(() => {
		const fetchCompanies = async () => {
			const response = await getHelloHealthCompaniesList();

			if (response.error) {
				setAlertError(intl.formatMessage({ id: 'somethingWentWrong' }));
				return;
			}

			if (response?.organizations?.length) {
				const mapped = response.organizations.map(item => ({
					value: item.id,
					name: item.name,
					picture: item.picture,
				}));

				setCompanies(mapped);

				const { state } = location;

				if (state?.company) {
					setSelectedCompany(state?.company);
				}
			}
		};

		fetchCompanies();
	}, [intl, location]);

	const onSelectedCompanyChange = event => {
		setCompanyError('');
		setSelectedCompany(companies.find(company => company.value.toString() === event.target.value));
	};

	const onContinueHandler = () => {
		setCompanyError('');
		if (!selectedCompany) {
			setCompanyError('Provider is required');
			return;
		}
		history.push({
			pathname: '/onboarding/patient',
			state: { company: selectedCompany },
		});
	};

	return (
		<>
			<div className='patient-onboarding flex doctor-onboarding-wrapper'>
				<div className='patient-box lg-box'>
					<div className='flex flex-space-between'>
						<div className='logo'>
							<img src={`${healthCareCdnUrl}hellocare-logo.svg`} alt='logo' />
						</div>
					</div>
					<form>
						<div className='flex'>
							<div className='patient-box-left-info'>
								<h4>Select your provider</h4>
								<p>Select your provider in order to proceed with the next steps of the process.</p>
							</div>
							<div className='patient-box-right-form'>
								<div className='flex flex-space-between flex-wrap'>
									<Select
										label='Select Provider'
										name='selectProvider'
										items={companies}
										valueField='value'
										textField='name'
										placeholder='Select your provider'
										onSelect={onSelectedCompanyChange}
										shouldNotDisable={true}
										value={selectedCompany?.value}
									/>
								</div>
							</div>
						</div>

						<span className='red-error'>{companyError}</span>
						<div className='flex onboarding-buttons'>
							<div className='flex-1'>
								<span>
									Already have an Account? <Link to='/login'>Click here to sign in</Link>
								</span>
							</div>
							<div>
								<Button onClick={onContinueHandler} imgIcon={`${onboardingImgUrl}continue.svg`} text={translate('continue')} />
							</div>
						</div>
					</form>
				</div>
				<div style={{ textAlign: 'center' }}>
					<Alert display={alertError} message={alertError} variant='error' onClose={() => setAlertError(null)} />
				</div>
			</div>
		</>
	);
};

export default SelectPatientCompany;
