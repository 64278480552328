import { useIntl } from 'react-intl';
import Modal from 'components/Common/Modal.jsx';
import ProfilePicture from 'components/Common/ProfilePicture.jsx';
import { primaryCareImageUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';

const NextVisitConfirmationModal = ({ nextVisit, modalSubmit }) => {
	const intl = useIntl();
	return (
		<Modal
			display={nextVisit}
			onModalSubmit={modalSubmit}
			onModalClose={modalSubmit}
			primaryButtonLabel={intl.formatMessage({ id: 'done' })}
			position='center'
			shouldSubmitOnEnter={false}
			hideCloseButton={true}
			className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal saved-successfully'>
			<div>
				<h3>{translate('success')}</h3>
				{nextVisit?.roomType && (
					<>
						<p>
							{translate('nextVisitIn', {
								value: nextVisit?.roomType,
							})}
						</p>

						<div className='saved-successfully-details-wrapper'>
							<div className='profile-details flex-1'>
								<div className='full-width'>
									<>
										<ProfilePicture
											profilePicture={`${primaryCareImageUrl}icons/${nextVisit?.icon}`}
											className='doctor-request-img appointment-img'
										/>
										<div>
											<p>{nextVisit?.nextVisitInfo?.roomName}</p>
										</div>
									</>
								</div>
							</div>
						</div>
					</>
				)}
				{!nextVisit?.nextVisitInfo && nextVisit?.skippedVisitTypes?.length > 0 && (
					<div className='saved-successfully-details-wrapper'>
						<div className='profile-details flex-1'>
							<div className='full-width'>
								<>
									<ProfilePicture
										profilePicture={`${primaryCareImageUrl}icons/frontdesk-nurse.svg`}
										className='doctor-request-img appointment-img'
									/>
									<div>
										<p>{translate('pleaseGoToFrontDesk')}</p>
									</div>
								</>
							</div>
						</div>
					</div>
				)}
			</div>
		</Modal>
	);
};

export default NextVisitConfirmationModal;
