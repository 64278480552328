import { getDeviceDoughnutChartsData, getFleetChartsData } from 'api/fleetManagement.js';
import DeviceAssignmentDoughnut from 'components/FleetManagement/DeviceAssignmentDoughnut.jsx';
import DeviceStatusMultiDoughnuts from 'components/FleetManagement/DeviceStatusMultiDoughnuts.jsx';
import FleetManagementDevices from 'components/FleetManagement/FleetManagementDevices.jsx';
import FleetManagementFilters from 'components/Common/FleetManagementFilters.jsx';
import { Alert, Button, Grid, Loader } from 'components/index.js';
import { DeviceConnectionStatus, DeviceListLevel } from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import DeviceAlerts from 'containers/Common/DeviceAlerts.jsx';
import translate from 'i18n-translations/translate.jsx';
import { isJSON } from 'infrastructure/helpers/commonHelpers.js';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import MainLayout from 'views/Layouts/MainLayout.jsx';

const FleetManagement = () => {
	const userSession = useSelector(state => state.user.userSession);
	const initialHs = {
		value: userSession.healthSystem.id,
		label: userSession.healthSystem.name,
		treeHierarchyTypeId: userSession.healthSystem.treeHierarchyTypeId,
	};
	const [filterValues, setFilterValues] = useState({
		healthSystems: [initialHs],
		hospitals: [],
		departments: [],
		floors: [],
		rooms: [],
		status: null,
		connection: null,
		search: '',
		lastLevel: { name: 'healthSystems', id: DeviceListLevel.HEALTH_SYSTEM },
	});

	const [chartsData, setChartsData] = useState({ dataSets: [], organizationInfo: null });
	const [doughnutCharts, setDoughnutCharts] = useState([]);
	const [isChartsLoading, setIsChartsLoading] = useState(true);
	const [isDoughnutsLoading, setIsDoughnutsLoading] = useState(true);
	const [error, setError] = useState(null);
	const [isDeviceAlertsOpen, setIsDeviceAlertsOpen] = useState(false);
	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 1, totalCount: 0 });

	useEffect(() => {
		setIsChartsLoading(true);
		const lastLevelIds = filterValues[filterValues.lastLevel.name].map(item =>
			isJSON(item.value) ? JSON.parse(item.value).id : item.value
		);

		const fetchChartsDate = async () => {
			const params = {
				levelType: filterValues.lastLevel.id,
				levelIds: lastLevelIds,
				...(filterValues.status && { isOnline: filterValues.status === DeviceConnectionStatus.ONLINE }),
			};
			const response = await getFleetChartsData(params);
			if (response.error) {
				setError(response.error.message);
				return;
			}
			setChartsData({
				dataSets: response.result.dataSets,
				organizationInfo: response.result.organizationTotalRoom,
			});
			setIsChartsLoading(false);
		};
		fetchChartsDate();
	}, [filterValues.lastLevel.id, filterValues.status]);

	useEffect(() => {
		setIsDoughnutsLoading(true);
		const lastLevelIds = filterValues[filterValues.lastLevel.name].map(item =>
			isJSON(item.value) ? JSON.parse(item.value).id : item.value
		);
		const fetchChartsDate = async () => {
			const params = {
				levelType: filterValues.lastLevel.id,
				levelIds: lastLevelIds,
				...(filterValues.status && { isOnline: filterValues.status === DeviceConnectionStatus.ONLINE }),
			};

			const response = await getDeviceDoughnutChartsData(params);
			if (response.error) {
				setError(response.error.message);
				return;
			}
			setDoughnutCharts(response.result.chart.dataSets);
			setIsDoughnutsLoading(false);
		};
		fetchChartsDate();
	}, [filterValues.status, filterValues.lastLevel.id]);

	return (
		<MainLayout>
			<div className='fleet-management-view'>
				<main className='main-view'>
					<section className='fleet-main-section'>
						<div className='full-width'>
							{!isDeviceAlertsOpen && (
								<>
									<div className='fleet-management-header'>
										<h3 className='header-title'>{translate('fleetManagement')}</h3>
										<div className='header-right'>
											<FleetManagementFilters
												setError={setError}
												setFilterValues={setFilterValues}
												filterValues={filterValues}
												initialHs={initialHs}
												setPagination={setPagination}
											/>
											<Button
												text={translate('deviceAlerts')}
												imgIcon={`${healthCareCdnUrl}rpm/alerts-white.svg`}
												onClick={() => setIsDeviceAlertsOpen(prevState => !prevState)}
											/>
										</div>
									</div>
									<Grid columns='repeat(2, 1fr)' gridGap='10px' className='fleet-component'>
										<DeviceAssignmentDoughnut isLoading={isChartsLoading} chartData={chartsData} />
										{isDoughnutsLoading && (
											<Grid columns='1fr' rows='1fr' stretch='100%' horizAlign='center' vertAlign='center'>
												<Loader />
											</Grid>
										)}
										{!isDoughnutsLoading &&
											doughnutCharts.map(item => (
												<DeviceStatusMultiDoughnuts key={item.label} chartData={item.dataSets} title={item.label} />
											))}
									</Grid>
									<FleetManagementDevices
										filterValues={filterValues}
										setFilterValues={setFilterValues}
										pagination={pagination}
										setPagination={setPagination}
									/>
								</>
							)}
							{isDeviceAlertsOpen && <DeviceAlerts setIsDeviceAlertsOpen={setIsDeviceAlertsOpen} setError={setError} />}
						</div>
					</section>
				</main>
				<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
			</div>
		</MainLayout>
	);
};

export default FleetManagement;
