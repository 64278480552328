import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { WindowSize } from 'calls/enums/index.js';
import { CallControlHeight } from 'calls/constants/index.js';
import { IconButton } from 'calls/components/index.js';
import LightTheme from 'calls/styles/LightTheme.js';
import DarkTheme from 'calls/styles/DarkTheme.js';

/**
 * @type {import('styled-components').StyledComponent<"div", any, { $zIndex: number, $isDarkMode: boolean, $position: string }, never>}
 */
const StyledModal = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100vh;
	overflow: auto;
	z-index: ${props => props.$zIndex || 99};
	background: rgba(0, 0, 0, 0.5);

	> section {
		position: relative;
		display: flex;
		flex-direction: column;
		margin: auto;

		background: ${LightTheme.colors.grayZero};
		border-radius: ${LightTheme.borderRadius.base}px;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
		width: 450px;
		max-width: calc(100% - ${LightTheme.spacing[7]}px);

		> header {
			display: flex;
			align-items: center;
			padding: ${LightTheme.spacing[4]}px ${LightTheme.spacing[5]}px;
			border-bottom: 1px solid ${LightTheme.colors.grayOne};

			h1 {
				flex: 1;
				font-size: 16px;
				margin: 0;
				padding: 0;
				color: ${LightTheme.colors.grayFive};
			}

			button {
				box-shadow: none;
				padding: 0;

				span {
					text-shadow: none;
					color: ${LightTheme.colors.grayFive};
					font-size: 20px;
				}
			}
		}

		> div {
			display: flex;
			flex-direction: column;
			padding: ${LightTheme.spacing[4]}px ${LightTheme.spacing[5]}px;

			> main {
				margin-bottom: ${LightTheme.spacing[3]}px;

				p,
				label {
					color: ${DarkTheme.colors.graySeven};
				}

				input {
					background-color: ${LightTheme.colors.grayZero};
					color: ${LightTheme.colors.grayFive};
				}
			}

			> footer {
				margin-top: auto;
				margin-left: auto;

				button {
					margin-left: auto;
				}
			}
		}
	}

	${props =>
		props.$isDarkMode &&
		css`
			${props.$position === 'right' && `border-left: 1px solid ${DarkTheme.colors.grayFour};`}
			section {
				background: ${DarkTheme.colors.grayThree};

				> header {
					border-bottom: 1px solid ${DarkTheme.colors.grayFour};

					h1 {
						color: ${DarkTheme.colors.grayFive};
					}

					button span {
						color: ${DarkTheme.colors.grayFive};
					}
				}

				> div > main {
					p,
					label {
						color: ${DarkTheme.colors.grayFive};
					}

					input {
						background-color: ${DarkTheme.colors.grayFour};
						color: ${DarkTheme.colors.grayFive};
					}
				}
			}
		`}

	${props =>
		props.$position === 'right' &&
		css`
			height: calc(100vh - ${CallControlHeight}px);
			background: transparent;
			z-index: ${props.$zIndex || '3'};
			justify-content: flex-end;
			width: 30%;
			max-width: calc(100% - 30px);
			left: auto;
			right: 0;

			> section {
				margin-top: auto;
				margin-right: 0;
				height: 100%;
				width: 100%;
				border-radius: 0;
				max-width: initial;
				> div {
					height: 100%;
					overflow: auto;

					> footer {
						margin-left: 0;
						> button {
							width: 100%;
						}
					}
				}
			}

			@media (max-width: ${WindowSize.TABLET}px) {
				position: absolute;
				left: 0;
				height: 100%;
				width: 100%;
				max-width: 100%;
				margin: 0 auto;
			}
		`}
`;

/**
 * @param {object} props
 * @param {any} props.children
 * @param {string} props.position
 * @param {string} [props.title]
 * @param {() => void} props.onDismiss
 * @param {number} [props.zIndex]
 */
const Modal = ({ children, title, onDismiss, position, zIndex = 999 }) => {
	const isDarkMode = useSelector(state => state.user.darkMode);
	return (
		<StyledModal $isDarkMode={isDarkMode} $position={position} $zIndex={zIndex}>
			<section>
				<header>
					<h1>{title}</h1>
					<IconButton
						id='stress-test-close-modal-button'
						icon='close'
						background='transparent'
						color={LightTheme.colors.grayFive}
						onClick={evt => {
							evt.stopPropagation();
							onDismiss();
						}}
					/>
				</header>
				<div>{children}</div>
			</section>
		</StyledModal>
	);
};

const ModalContent = ({ children }) => {
	return <main>{children}</main>;
};

const ModalActions = ({ children }) => {
	return <footer>{children}</footer>;
};

Modal.Content = ModalContent;
Modal.Actions = ModalActions;

export default Modal;
