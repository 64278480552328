import React, { useEffect, useState, useContext, useCallback, useRef } from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { enums } from '@solaborate/calls';
import FormInput from 'components/Common/FormInput.jsx';
import ProfilePicture from 'components/Common/ProfilePicture.jsx';
import {
	SortByNameValues,
	Gender,
	PatientRpmStatus,
	RiskLevels,
	EWSType,
	RiskLevelValues,
	MeasurementTypes,
} from 'constants/enums.js';
import { getRecent } from 'api/messenger.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { getUserId, getUserRole } from 'infrastructure/auth.js';
import { convertMeasurementTypes } from 'infrastructure/helpers/measurementsHelper.js';
import { getGender, getRoleConfigurationValue, skipDuplicatedObjects } from 'infrastructure/helpers/commonHelpers.js';
import translate from 'i18n-translations/translate.jsx';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SocketEvents from 'constants/socket-events.js';
import { defaultDateFormat, monthDayYear } from 'infrastructure/helpers/dateHelper.js';
import Alert from 'components/Common/Alert.jsx';
import AlertsModal from 'containers/VitalSignsMonitoring/AlertsModal.jsx';
import { StartQueryStringKeys, UserRoles } from 'calls/enums/index.js';
import { getPatientsCareTeam, getUsersRpmList, getEWSSettings, updateEWSSettings } from 'api/rpm.js';
import { RpmTableHeaders, VitalSignsList } from 'constants/rpm.js';
import Form from 'components/Common/Form.jsx';
import Modal from 'components/Common/Modal.jsx';
import CareLogs from 'containers/Rpm/CareLogs.jsx';
import Survey from 'containers/Rpm/Survey.jsx';
import EditPatientRpmProgram from 'containers/Rpm/EditPatientRpmProgram.jsx';
import {
	getBatteryColorBasedOnScore,
	getScoreClassName,
	getImageBasedOnScore,
	getRadioButtonOptions,
	getScoreRisk,
	getEwsScoreRiskClassName,
	hasOnlyOneScoreOfThree,
	scrollToTop,
	getArrowColor,
	getVitalSignColorBySafetyRanges,
	fetchPatientsAlertConfigurations,
} from 'infrastructure/helpers/rpmHelper.js';
import RpmDropDownOptions from 'containers/Rpm/RpmDropDownOptions.jsx';
import TodaysMedication from 'containers/Rpm/TodaysMedication.jsx';
import _, { debounce } from 'lodash';
import { GeneralAndMenuSettings } from 'constants/configurationEnums.js';
import RemoveInactivePatient from 'containers/Rpm/RemoveInactivePatient.jsx';
import Retry from 'icons/CareEvents/Retry.jsx';
import Button from 'components/Common/Button.jsx';
import RiskLevelFilters from 'containers/Rpm/RiskLevelFilters.jsx';
import { getAdminGeneralSettingConfigValue } from 'infrastructure/helpers/configurationsHelpers.js';
import CustomTable from 'components/Common/CustomTable.jsx';
import { RpmMeasurementTypes } from 'constants/rpm.js';
import Grid from 'components/Common/Grid.jsx';
import Loader from 'components/Common/Loader.jsx';
import PatientOverview from 'components/Patients/Common/PatientOverview.jsx';

const RemotePatientMonitoring = ({ setShouldShowNavigation }) => {
	const [searchBox, setSearchBox] = useState('');
	const [errorApiResponse, setErrorApiResponse] = useState(null);
	const [rpmPatients, setRpmPatients] = useState([]);
	const [totalCount, setTotalCount] = useState(0);
	const [sortByNameValue, setSortByNameValue] = useState(null);
	const [selectedPatient, setSelectedPatient] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [isRpmListLoading, setIsRpmListLoading] = useState(true);
	const [isRiskFilterLoading, setIsRiskFilterLoading] = useState(true);
	const [conversations, setConversations] = useState([]);
	const [isAlertsModalOpen, setAlertsModalVisibility] = useState(false);
	const [careTeamForPatient, setCareTeamForPatient] = useState([]);
	const [shouldShowCareTeam, setShouldShowCareTeam] = useState(false);
	const [modalPageIndex, setModalPageIndex] = useState(0);
	const [hasModalReachedEnd, setHasModalReachedEnd] = useState(false);
	const [shouldShowCareLogs, setShouldShowCareLogs] = useState(false);
	const [shouldShowEWSSettings, setShouldShowEWSSettings] = useState(false);
	const [levelOfConsciousness, setLevelOfConsciousness] = useState(null);
	const [supplementOxygen, setSupplementOxygen] = useState(null);
	const [riskType, setRiskType] = useState(null);
	const [scores, setScores] = useState({ high: 0, moderate: 0, low: 0 });
	const [shouldShowSurvey, setShouldShowSurvey] = useState(false);
	const [shouldShowRpmProgram, setShouldShowRpmProgram] = useState(false);
	const [patientRpmProgram, setPatientRpmProgram] = useState(null);
	const [shouldShowMedication, setShouldShowMedication] = useState(false);
	const [shouldExcludeCpm, setShouldExcludeCpm] = useState(false);
	const [inactivePatient, setInactivePatient] = useState(null);
	const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');
	const [userChangedMeasurement, setUserChangedMeasurement] = useState(false);
	const [shouldUpdateList, setShouldUpdateList] = useState(false);
	const [pagination, setPagination] = useState({ totalCount: 0, pageIndex: 0 });
	const [patientSafetyRanges, setPatientSafetyRanges] = useState(null);
	const [patientDetailsView, setPatientDetailsView] = useState(false);
	const [isConversationClicked, setIsConversationClicked] = useState(false);
	const { darkMode, userSession, unitPreferences } = useSelector(state => state.user);
	const configurations = useSelector(state => state.configurations);
	const socket = useContext(SocketContext);
	const rpmList = useRef(null);
	const userId = getUserId();
	const intl = useIntl();

	const getCategoryPreference = useCallback(
		categoryId => unitPreferences.find(item => item.unitCategoryId === categoryId),
		[unitPreferences]
	);

	const getUnitPreference = useCallback(
		categoryId => {
			const selectedPreference = getCategoryPreference(categoryId);
			return selectedPreference?.options.find(item => item.unitSystemId === selectedPreference.unitSystemId);
		},
		[getCategoryPreference]
	);

	const handleNewMessage = async () => {
		const recentConversations = await getRecent(userId);
		if (recentConversations.error) {
			setIsLoading(false);
			return;
		}
		setConversations(recentConversations.conversations);
	};

	const sortByName = sortType => {
		setSortByNameValue(sortType);
		const patientsList = [...rpmPatients.map(patient => ({ ...patient }))];

		if (sortByNameValue === SortByNameValues.SORTING_ZA) {
			patientsList.sort((a, b) => (a.firstName?.toUpperCase() > b.firstName?.toUpperCase() ? 1 : -1));
		} else {
			patientsList.sort((a, b) => (a.firstName?.toUpperCase() < b.firstName?.toUpperCase() ? 1 : -1));
		}
		setRpmPatients(patientsList);
	};

	const getMeasurementValue = measurement =>
		!measurement.unitCategoryId
			? measurement.measurementValue
			: convertMeasurementTypes(
					measurement.unitCategoryId,
					measurement.measurementValue,
					getUnitPreference(measurement.unitCategoryId)?.unitSystemId
			  );

	const getVitalSignTableCell = (signs, type, safetyRanges) => {
		const selectedVitalSigns = signs
			.filter(item => item.measurementType === type)
			.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

		let latestMeasurement = {};
		let previousMeasurement = {};
		if (selectedVitalSigns.length > 0) {
			latestMeasurement = selectedVitalSigns[0];
			if (selectedVitalSigns.length > 1) {
				previousMeasurement = selectedVitalSigns[1];
			}
		}
		const selectedBatteryLevel = latestMeasurement?.device?.batteryLevel;

		const trendArrow =
			parseFloat(latestMeasurement?.measurementValue) > parseFloat(previousMeasurement?.measurementValue) ? 'rotate' : '';
		const vitalSignRiskLevel = getVitalSignColorBySafetyRanges(safetyRanges, latestMeasurement);

		return (
			<div
				className={classNames('patient-alert-body-vs position-relative', getScoreClassName(vitalSignRiskLevel), {
					'blood-pressure': type === MeasurementTypes.BLOOD_PRESSURE,
					'blood-glucose': type === MeasurementTypes.DIABETES,
					'score-wrapper': getScoreClassName(vitalSignRiskLevel),
				})}
				key={type}>
				<div className={classNames('gap-s', getScoreClassName(vitalSignRiskLevel, true))}>
					<div className='flex column-direction'>
						<div className='flex flex-space-between full-width flex-align-center'>
							<div className='flex flex-align-center'>
								<img src={VitalSignsList.find(item => item.type === type)?.icon} alt='ico' />
								{!latestMeasurement?.measurementValue && <p>N/A</p>}
								{latestMeasurement?.measurementValue && (
									<p>
										{getMeasurementValue(latestMeasurement)}
										<span
											className={classNames('rpm-unit-wrapper', {
												'unit-wrapper': getScoreClassName(vitalSignRiskLevel),
											})}>
											{latestMeasurement.unitCategoryId
												? getUnitPreference(latestMeasurement.unitCategoryId)?.unit
												: latestMeasurement.measurementUnit}
										</span>
									</p>
								)}
							</div>
							{previousMeasurement.measurementValue &&
								latestMeasurement.measurementValue !== previousMeasurement.measurementValue && (
									<i
										className={classNames(
											'cursor-pointer material-icons sort-list-icon trend-arrow',
											trendArrow,
											getArrowColor(vitalSignRiskLevel)
										)}>
										arrow_downward
									</i>
								)}
						</div>
					</div>
					{latestMeasurement?.date && (
						<span className='measurement-date'>{latestMeasurement.date && defaultDateFormat(latestMeasurement.date)}</span>
					)}
					{selectedBatteryLevel >= 0 && (
						<div className='battery-status-wrapper flex-align-center'>
							<img
								className={classNames(vitalSignRiskLevel !== RiskLevelValues.NORMAL ? 'battery-img' : '')}
								src={`${healthCareCdnUrl}rpm/${getBatteryColorBasedOnScore(vitalSignRiskLevel, selectedBatteryLevel)}.svg`}
								alt='Battery status'
							/>
							<span>{selectedBatteryLevel.toFixed()}%</span>
						</div>
					)}
				</div>
			</div>
		);
	};

	const patientRedirect = async (type, patient) => {
		try {
			const queryParams = new URLSearchParams({
				[StartQueryStringKeys.OBJECT_ID]: patient.userId,
				[StartQueryStringKeys.OBJECT_TYPE]: enums.ObjectTypes.USER,
				[StartQueryStringKeys.CONFERENCE_NAME]: `${patient.firstName} ${patient.lastName}`,
				[StartQueryStringKeys.CALL_TYPE]: type,
			});
			window.open(`/call?${queryParams.toString()}`, '_blank');
		} catch (err) {
			setErrorApiResponse(`${intl.formatMessage({ id: 'anErrorOccurred' })}: ${err.message}`);
		}
	};

	const removeFromRpm = patient => {
		setIsDeleteModalShown(true);
		setInactivePatient(patient);
	};

	const getUnreadMessages = patient => {
		const foundConversation = conversations.find(item => item.contact && item.contact.objectId === patient.userId.toString());
		return foundConversation && foundConversation.unreadMessagesCount > 0;
	};

	const toggleAlertsModal = patient => {
		setAlertsModalVisibility(prevState => !prevState);
		setSelectedPatient(patient);
	};

	const getRpmPatients = useCallback(async () => {
		setIsRpmListLoading(true);
		const getFilterBasedOnScoreLength = (list, score) => list?.filter(item => getScoreRisk(item?.score) === score).length;

		const response = await getUsersRpmList({
			userId: getUserId(),
			pageIndex: pagination.pageIndex,
			search: searchBox,
			shouldExcludeCpm,
			healthSystemId: userSession.healthSystem.id,
		});
		if (response.error) {
			setErrorApiResponse(response.error.message);
			setIsRpmListLoading(false);
			setIsRiskFilterLoading(false);
		} else {
			const newArr = response.rpmListPatients.map(patient => ({
				...patient,
				score: patient.totalEarlyWarningScore,
				isRedScore: patient.vitalSigns?.length > 0 ? hasOnlyOneScoreOfThree(patient.vitalSigns) : false,
			}));
			if (!riskType) {
				setPagination(prevState => ({ ...prevState, totalCount: response.totalCount }));
			}
			setIsRiskFilterLoading(false);
			setIsLoading(false);
			const patientIds = newArr.map(patient => patient.id);
			await fetchPatientsAlertConfigurations(
				patientIds,
				setErrorApiResponse,
				setPatientSafetyRanges,
				userSession.healthSystem.id
			);
			if (searchBox) {
				scrollToTop(rpmList);
				setRpmPatients(newArr);
				setIsRpmListLoading(false);
				return;
			}
			const concatenatedArray = rpmPatients.concat(newArr);
			const uniqueArray = skipDuplicatedObjects(concatenatedArray, 'id');
			setIsRpmListLoading(false);

			if (userChangedMeasurement) {
				const foundPatient = uniqueArray.find(item => item.userId === userChangedMeasurement);
				setShouldUpdateList(!foundPatient);
			}
			if (riskType) {
				const filteredBasedOnRiskType = uniqueArray.filter(item => getScoreRisk(item?.score) === riskType);
				setPagination(prevState => ({
					...prevState,
					totalCount: filteredBasedOnRiskType.length,
				}));
				setRpmPatients(filteredBasedOnRiskType);
				return;
			}
			if (shouldExcludeCpm) {
				scrollToTop(rpmList);
				setPagination(prevState => ({
					...prevState,
					totalCount: newArr.length,
				}));
				setRpmPatients(newArr);
				return;
			}
			setRpmPatients(uniqueArray);
			setScores({
				high: getFilterBasedOnScoreLength(uniqueArray, RiskLevels.HIGH),
				moderate: getFilterBasedOnScoreLength(uniqueArray, RiskLevels.MODERATE),
				low: getFilterBasedOnScoreLength(uniqueArray, RiskLevels.LOW),
			});
			setTotalCount(response.totalCount);
		}
	}, [pagination.pageIndex, searchBox, shouldExcludeCpm, riskType]);

	const handleRemovePatient = data => {
		const newPatientArray = rpmPatients.filter(patient => patient.patientId !== data.patientId);
		setRpmPatients(newPatientArray);
	};

	const getLatestMeasurements = data => {
		const foundPatient = rpmPatients.find(patient => patient.userId === data.patientId);
		const foundVitalSign = foundPatient?.vitalSigns?.find(sign => sign.measurementType === data.measurementType);

		if (rpmPatients.length > 0 && !foundPatient) {
			setShouldUpdateList(true);
			setUserChangedMeasurement(data.userId);
		}

		if (foundPatient?.vitalSigns && !foundVitalSign) {
			const vitalSignDetails = {
				device: { batteryLevel: data.batteryLevel },
				date: data.creationDate,
				...data,
			};
			foundPatient.vitalSigns.push(vitalSignDetails);
			foundPatient.hasMeasurementChanged = true;
		}

		if (foundVitalSign) {
			foundVitalSign.measurementValue = data.measurementValue;
			foundVitalSign.device.batteryLevel = data.batteryLevel;
			foundVitalSign.date = data.creationDate;
			foundPatient.hasMeasurementChanged = true;
		}

		const updatedPatients = rpmPatients.map(patient => (patient.userId === foundPatient?.userId ? foundPatient : patient));
		setRpmPatients(updatedPatients);
	};

	useEffect(() => {
		socket.on(SocketEvents.Conversation.ON_NEW_MESSAGE, handleNewMessage);
		socket.on(SocketEvents.HealthCare.MEASUREMENT_ADDED, getLatestMeasurements);
		socket.on(SocketEvents.HealthCare.ON_RPM_PROGRAM_UPDATED, getRpmPatients);
		socket.on(SocketEvents.HealthCare.ON_PATIENT_MISSED_MEDICATIONS, getRpmPatients);
		socket.on(SocketEvents.HealthCare.ON_PATIENT_RPM_PROGRAM_REMOVED, handleRemovePatient);

		return () => {
			socket.off(SocketEvents.Conversation.ON_NEW_MESSAGE, handleNewMessage);
			socket.off(SocketEvents.HealthCare.MEASUREMENT_ADDED, getLatestMeasurements);
			socket.off(SocketEvents.HealthCare.ON_RPM_PROGRAM_UPDATED, getRpmPatients);
			socket.off(SocketEvents.HealthCare.ON_PATIENT_MISSED_MEDICATIONS, getRpmPatients);
			socket.off(SocketEvents.HealthCare.ON_PATIENT_RPM_PROGRAM_REMOVED, handleRemovePatient);
		};
	}, [socket, getRpmPatients, handleRemovePatient, getLatestMeasurements]);

	useEffect(() => {
		const getRecentConversation = async () => {
			const recentConversations = await getRecent(userId);
			if (!recentConversations.error) {
				setConversations(recentConversations.conversations);
			}
			setIsLoading(false);
		};

		if (userSession.healthSystem.id) {
			getRecentConversation();
		}
	}, [userSession.healthSystem.id]);

	useEffect(() => {
		if (userSession.healthSystem.id) {
			getRpmPatients();
		}
	}, [getRpmPatients, userSession.healthSystem.id, userSession.regionId, pagination.pageIndex]);

	const getPatientCareTeam = async (patientId, shouldOpenModal = true) => {
		const response = await getPatientsCareTeam({ patientId, pageIndex: modalPageIndex });
		if (response.error) {
			setErrorApiResponse(response.error.message);
			return;
		}
		const careTeamResponse = response.patientCareTeam.concat(response.rpmCareTeam);
		setCareTeamForPatient(careTeamResponse);
		setShouldShowCareTeam(shouldOpenModal);
		setHasModalReachedEnd(careTeamResponse.length < 10);
	};

	const handOnModalScroll = event => {
		const isBottom = event.target.scrollHeight - Math.ceil(event.target.scrollTop) === event.target.clientHeight;
		if (isBottom && !hasModalReachedEnd) {
			setModalPageIndex(prevState => prevState + 1);
		}
	};

	const toggleCarelogs = patient => {
		setShouldShowCareLogs(prevState => !prevState);
		setSelectedPatient(patient);
	};

	const getScoreTableCell = patient => (
		<div
			className={classNames(
				'patient-alert-body-vs position-relative',
				getEwsScoreRiskClassName(patient?.score, patient.isRedScore),
				getScoreRisk(patient?.score) !== 'N/A' ? 'score' : '',
				'score-wrapper'
			)}>
			<div className='flex'>
				<img src={getImageBasedOnScore(patient?.score, patient.isRedScore, darkMode)} alt='EWS icon' />
				<p>
					{patient?.score}
					{patient.isRedScore ? '*' : ''}
				</p>
				{getScoreRisk(patient?.score) === 'N/A' && <p>N/A</p>}
			</div>
			{getScoreRisk(patient?.score) !== 'N/A' && (
				<span>{`${intl.formatMessage({ id: getScoreRisk(patient?.score, patient.isRedScore) })} clinical risk`}</span>
			)}
		</div>
	);

	const toggleEwsSettings = patient => {
		if (!patient) {
			setShouldShowEWSSettings(false);
			return;
		}
		setShouldShowEWSSettings(prevState => !prevState);
		getEWSSetting(patient?.id);
		setSelectedPatient(patient);
	};

	const updateEWSSetting = async healthcareUserId => {
		const params = {
			levelOfConsciousness,
			supplementOxygen,
		};
		const response = await updateEWSSettings({ healthcareUserId, params });
		if (response.error) {
			setErrorApiResponse(response.error.message);
		} else {
			getRpmPatients();
		}
		toggleEwsSettings(null);
	};

	const getEWSSetting = async healthcareUserId => {
		const response = await getEWSSettings(healthcareUserId);
		if (response.error) {
			setErrorApiResponse(response.error.message);
			return;
		}
		setSupplementOxygen(response.ewsSettings.supplementOxygen);
		setLevelOfConsciousness(response.ewsSettings.levelOfConsciousness);
	};

	const setEWSValues = (name, value) => {
		if (name === EWSType.SUPPLEMENT_OXYGEN) {
			setSupplementOxygen(value);
		}
		if (name === EWSType.LEVEL_OF_CONSCIOUSNESS) {
			setLevelOfConsciousness(value);
		}
	};

	const getRadioButtons = name =>
		getRadioButtonOptions(name).map(item => (
			<div className='flex position-relative consent-from-patient margin-right-m' key={item.id}>
				<label className='remember-me flex'>
					<input
						className='auto-width cursor-pointer'
						type='radio'
						value={item.value.toString()}
						onChange={() => setEWSValues(name, item.value)}
						onBlur={null}
						name={name}
						checked={
							(levelOfConsciousness === item.value && name === EWSType.LEVEL_OF_CONSCIOUSNESS) ||
							(supplementOxygen === item.value && name === EWSType.SUPPLEMENT_OXYGEN)
						}
					/>
					<div className='onb-custom-checkbox-wrapper add-patient-checkbox-wrapper ews-setting cursor-pointer'>
						<div className='onb-custom-checkbox' />
					</div>
					<p>{item.content}</p>
				</label>
			</div>
		));

	const toggleSurvey = patient => {
		setShouldShowSurvey(prevState => !prevState);
		setSelectedPatient(patient);
	};

	const toggleRpmProgram = patient => {
		setShouldShowRpmProgram(prevState => !prevState);
		setSelectedPatient(patient);
		if (patient?.rpmProgram) {
			setPatientRpmProgram({ value: patient.rpmProgram?.id, label: patient.rpmProgram?.name });
		}
	};

	const toggleMedicationModal = patient => {
		setShouldShowMedication(prevState => !prevState);
		setSelectedPatient(patient);
	};

	const handleSearchValue = useCallback(debounce(setSearchBox, 300), []);

	const handleSearchTerm = event => {
		setIsRpmListLoading(true);
		handleSearchValue(event.target.value);
		setSearchTerm(event.target.value);
		setPagination(prevState => ({ ...prevState, pageIndex: 0 }));
	};

	const shouldShowConversation = () =>
		getAdminGeneralSettingConfigValue(configurations, GeneralAndMenuSettings.PATIENTS) &&
		getRoleConfigurationValue(configurations.customRoleConfigurations, GeneralAndMenuSettings.CONVERSATION_HISTORY);

	const shouldShowAlertsHistory = () =>
		getAdminGeneralSettingConfigValue(configurations, GeneralAndMenuSettings.PATIENTS) &&
		getRoleConfigurationValue(configurations?.customRoleConfigurations, GeneralAndMenuSettings.ALERT_HISTORY);

	useEffect(() => {
		setIsRpmListLoading(true);
		setRpmPatients([]);
	}, [searchBox]);

	const handlePatientDetailsClick = isPatientDetailsView => {
		setPatientDetailsView(isPatientDetailsView);
		setShouldShowNavigation(!isPatientDetailsView);
		setIsConversationClicked(false);
	};

	const handleConversationClick = patient => {
		setSelectedPatient(patient);
		handlePatientDetailsClick(true);
		setIsConversationClicked(true);
	};

	const handlePatientProfileCLick = patient => {
		getPatientCareTeam(patient.patientId, false);
		setSelectedPatient(patient);
		setShouldShowNavigation(false);
		setPatientDetailsView(true);
	};

	const displayRpmPatients = () =>
		rpmPatients.map(patient => {
			const vitalSigns = RpmMeasurementTypes.reduce((acc, type) => {
				acc[type.toLowerCase()] = getVitalSignTableCell(patient.vitalSigns, type, patientSafetyRanges?.[patient.id] ?? []);
				return acc;
			}, {});

			return {
				patient: (
					<div key={patient.userId} className='flex full-width'>
						<div className='patient-details-wrapper' onClick={() => handlePatientProfileCLick(patient)}>
							<ProfilePicture
								className='doctor-request-img'
								fullName={`${patient.firstName} ${patient.lastName}`}
								profilePicture={patient.profilePicture}
							/>
							{patient.hasMissedMedications && (
								<div
									className='warning-alert-icon'
									data-tooltip={intl.formatMessage({ id: 'patientSkippedMedication' })}
									data-position='right'>
									<img src={`${healthCareCdnUrl}rpm/warning-alert.svg`} alt='warning-alert' />
								</div>
							)}
							<div>
								<h5>
									{patient.firstName} {patient.lastName}
								</h5>
								<span>
									{patient.genderId === Gender.UNAVAILABLE
										? `${intl.formatMessage({ id: 'sex' })}: N/A`
										: getGender(patient.genderId).description}
								</span>
								<p>
									<span>DOB: {patient.dateOfBirth ? monthDayYear(patient.dateOfBirth) : 'N/A'}</span>
								</p>
								<p>
									<span className='mrn'>MRN: {patient.mrn || 'N/A'}</span>
								</p>
								<p>
									<span>RPM: {patient.rpmProgram?.name || 'N/A'}</span>
								</p>
							</div>
						</div>
					</div>
				),
				ewsScore: getScoreTableCell(patient),
				...vitalSigns,
				actions: (
					<div
						className={classNames('flex position-relative', {
							'rpm-inactive-options': patient.patientRpmStatus === PatientRpmStatus.INACTIVE,
						})}>
						<div className='flex patient-info-call'>
							<div className='patient-info-audio' onClick={() => patientRedirect(enums.CallTypes.VIDEO, patient)} />
							{getUserRole() === UserRoles.DOCTOR && shouldShowConversation() && (
								<div className='patient-info-open-convo' onClick={() => handleConversationClick(patient)}>
									{getUnreadMessages(patient) && (
										<div className='messages-count-inner'>
											{
												conversations.find(item => item.contact && item.contact.objectId === patient.userId.toString())
													.unreadMessagesCount
											}
										</div>
									)}
								</div>
							)}
							<RpmDropDownOptions
								patient={patient}
								toggleAlertsModal={toggleAlertsModal}
								toggleEwsSettings={toggleEwsSettings}
								toggleCarelogs={toggleCarelogs}
								getPatientCareTeam={getPatientCareTeam}
								toggleSurvey={toggleSurvey}
								toggleRpmProgram={toggleRpmProgram}
								toggleMedicationModal={toggleMedicationModal}
								isDarkMode={darkMode}
								isAlertHistoryShown={shouldShowAlertsHistory()}
								isDisabled={patient.patientRpmStatus === PatientRpmStatus.INACTIVE}
								removeFromRpm={removeFromRpm}
								setPatientDetailsView={handlePatientDetailsClick}
								setSelectedPatient={setSelectedPatient}
							/>
						</div>
					</div>
				),
				className: classNames({
					'highlighted-measurement': patient.hasMeasurementChanged,
					'patient-disabled': patient.patientRpmStatus === PatientRpmStatus.INACTIVE,
				}),
				cellClassName: RpmTableHeaders.map(() => 'padding-2'),
			};
		});

	return (
		<>
			{isLoading && (
				<Grid width='100%' stretch='100vh' vertAlign='center' horizAlign='center' rows='auto' className='rpm-wrapper'>
					<Loader />
				</Grid>
			)}
			{!isLoading && !shouldShowCareLogs && !patientDetailsView && (
				<>
					<div
						className={classNames('patient-alerts-wrapper rpm full-width rpm-wrapper', { 'update-rpm-list': shouldUpdateList })}>
						{shouldUpdateList && (
							<div className='full-width care-events-updates'>
								<p>{translate('updateRpmList')}</p>
								<Button
									svgIcon={<Retry />}
									className='flex'
									onClick={() => setPagination(prevState => ({ ...prevState, pageIndex: prevState.pageIndex + 1 }))}
									text={translate('updateList')}
								/>
							</div>
						)}
						<RiskLevelFilters
							setSortByNameValue={setSortByNameValue}
							totalCount={totalCount}
							scores={scores}
							setRiskType={setRiskType}
							rpmList={rpmList}
							setIsRpmListLoading={setIsRpmListLoading}
							setPagination={setPagination}
							setIsRiskFilterLoading={setIsRiskFilterLoading}
						/>
						<div className='flex flex-align-center flex-space-between full-width patients-alert-filter'>
							<FormInput
								id='searchBox'
								labelClassName='full-width right-15'
								name='searchBox'
								text=''
								type='search'
								onChange={handleSearchTerm}
								value={searchTerm}
								placeholder={intl.formatMessage({ id: 'searchByName' })}
								className='full-width'
							/>
							<div
								onClick={() => {
									setIsRpmListLoading(true);
									setShouldExcludeCpm(prevState => !prevState);
								}}
								className='cursor-pointer'>
								<input
									className='cursor-pointer'
									type='checkbox'
									checked={shouldExcludeCpm}
									name='shouldExcludeCpm'
									onChange={() => null}
								/>
								<span className='font-14 left-margin-s'>{translate('excludeCpmPatients')}</span>
							</div>
						</div>
						<div className='patients-alert-table full-height'>
							<div className='patients-alert-table-body rpm-scroll' ref={rpmList}>
								<CustomTable
									headers={[
										{
											title: (
												<>
													{translate('patient')}
													<i
														className={classNames('cursor-pointer material-icons sort-list-icon', {
															rotate: sortByNameValue === SortByNameValues.SORTING_AZ,
														})}
														onClick={() =>
															sortByName(
																sortByNameValue === SortByNameValues.SORTING_ZA
																	? SortByNameValues.SORTING_AZ
																	: SortByNameValues.SORTING_ZA
															)
														}>
														arrow_downward
													</i>
												</>
											),
											id: 'patient',
											columnWidth: '170px',
										},
										{ title: translate('ewsScore'), id: 'ewsScore', columnWidth: '155px' },
										{ title: translate('bloodPressure'), id: 'bloodPressure', columnWidth: '175px' },
										{ title: translate('heartRate'), id: 'heartRate', columnWidth: '165px' },
										{ title: translate('oxygenSaturation'), id: 'oxygenSaturation', columnWidth: '165px' },
										{ title: translate('bloodGlucose'), id: 'bloodGlucose', columnWidth: '170px' },
										{ title: translate('temperature'), id: 'temperature', columnWidth: '165px' },
										{ title: translate('weight'), id: 'weight', columnWidth: '165px' },
										{ title: translate('actions'), id: 'actions' },
									]}
									isLoading={isRpmListLoading}
									rows={isRiskFilterLoading ? [] : displayRpmPatients()}
									className='hospital-at-home-table patient-list-table rpm-table'
									setPagination={setPagination}
									isEditable={false}
									stickyHeader={true}
								/>
							</div>
						</div>
					</div>
					{selectedPatient && isAlertsModalOpen && (
						<AlertsModal
							display={isAlertsModalOpen}
							isLoading={false}
							position='center'
							className='vsm-alerts-modal border-radius-modal-wrapper rpm-alerts-modal'
							onModalClose={() => toggleAlertsModal(null)}
							patientId={selectedPatient.id}
							isVitalSigns={false}
						/>
					)}
					<Modal
						display={shouldShowCareTeam}
						position='center'
						className='standard-modal-wrapper modal-wrapper-wo-btn wrapper-modal vsm-alerts-modal care-team-members-modal'
						onModalClose={() => setShouldShowCareTeam(false)}>
						{shouldShowCareTeam && (
							<Form>
								<h3>{translate('careTeam')}</h3>
								{careTeamForPatient.length === 0 && !isLoading && (
									<div className='flex flex-align-center flex-justify-center'>
										<p>{translate('noResultsFound')}</p>
									</div>
								)}
								<div className='care-team-members-wrapper' onScroll={handOnModalScroll}>
									<div className='available-doctor-items care-team-members-list'>
										{careTeamForPatient.map(item => (
											<div key={item?.userIntId} className='care-team-info'>
												<ProfilePicture
													className='doctor-request-img available-doctor-request-img'
													firstName={item.firstName}
													lastName={item.lastName}
													profilePicture={item.profilePicture}
												/>
												<p className='team-member-name'>
													{item.firstName} {item.lastName}
												</p>
												<span className='additional-info'>{item.email}</span>
												{item.specialty?.name && <span className='additional-info'>{item.specialty.name}</span>}
											</div>
										))}
									</div>
								</div>
							</Form>
						)}
					</Modal>
				</>
			)}
			{shouldShowSurvey && <Survey selectedPatient={selectedPatient} setShouldShowSurvey={setShouldShowSurvey} />}
			{shouldShowCareLogs && (
				<CareLogs
					selectedPatient={selectedPatient}
					setShouldShowCareLogs={setShouldShowCareLogs}
					preferredUnits={unitPreferences}
				/>
			)}
			{selectedPatient && shouldShowEWSSettings && (
				<Modal
					display={shouldShowEWSSettings}
					isLoading={false}
					position='center'
					className='make-appointment-modal border-radius-modal-wrapper appoinment-next-arrow-modal discard rpm-modal-wrapper'
					onModalClose={() => toggleEwsSettings(null)}
					onModalSubmit={() => updateEWSSetting(selectedPatient.id)}
					isSubmitDisabled={supplementOxygen === null || levelOfConsciousness === null}>
					<Form height={220} className='create-appointment-form'>
						<h3>{translate('ewsSettings')}</h3>
						<div className='row-direction full-width flex flex-align-center'>
							<label className='flex-1 ews-option'>{translate('levelOfConsciousness')}</label>
							{getRadioButtons(EWSType.LEVEL_OF_CONSCIOUSNESS)}
						</div>
						<div className='row-direction full-width flex flex-align-center'>
							<label className='flex-1 ews-option'>{translate('supplementOxygen')}</label>
							{getRadioButtons(EWSType.SUPPLEMENT_OXYGEN)}
						</div>
					</Form>
				</Modal>
			)}
			{shouldShowRpmProgram && (
				<EditPatientRpmProgram
					patientRpmProgram={patientRpmProgram}
					setShouldShowRpmProgram={setShouldShowRpmProgram}
					shouldShowRpmProgram={shouldShowRpmProgram}
					selectedPatient={selectedPatient}
					rpmPatients={rpmPatients}
					setRpmPatients={setRpmPatients}
				/>
			)}
			{shouldShowMedication && (
				<TodaysMedication
					selectedPatient={selectedPatient}
					setShouldShowMedication={setShouldShowMedication}
					isDarkMode={darkMode}
				/>
			)}
			{isDeleteModalShown && (
				<RemoveInactivePatient
					isDeleteModalShown={isDeleteModalShown}
					setIsDeleteModalShown={setIsDeleteModalShown}
					inactivePatient={inactivePatient}
				/>
			)}
			{patientDetailsView && (
				<PatientOverview
					setPatientDetailsView={handlePatientDetailsClick}
					setSelectedPatient={setSelectedPatient}
					selectedPatient={selectedPatient}
					isConversationClicked={isConversationClicked}
					talkToPatient={() => patientRedirect(enums.CallTypes.VIDEO, selectedPatient)}
					shouldShowSchedule={false}
					shouldShowVisits={getUserRole() === UserRoles.DOCTOR}
					careTeam={careTeamForPatient}
				/>
			)}
			<Alert
				display={errorApiResponse}
				fixed={true}
				onClose={() => setErrorApiResponse(null)}
				message={errorApiResponse}
				variant='dark'
			/>
		</>
	);
};

export default RemotePatientMonitoring;
