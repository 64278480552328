import React, { useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Form from 'components/Common/Form.jsx';
import { Alert, Loader, Modal } from 'components/index.js';
import translate from 'i18n-translations/translate.jsx';
import { assignMembers } from 'api/users.js';
import { getDoctors } from 'api/doctors.js';
import useOutsideClick from 'infrastructure/helpers/useOutsideClick.js';

const AssignDoctor = props => {
	const [searchValue, setSearchValue] = useState('');
	const [isSearchNursesLoading, setIsSearchDoctorsLoading] = useState(false);
	const [allDoctors, setAllDoctors] = useState([]);
	const [showItemDropdown, setShowItemDropdown] = useState(false);
	const [assignedDoctors, setAssignedDoctors] = useState([]);
	const [error, setError] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const userSession = useSelector(state => state.user.userSession);
	const intl = useIntl();
	const onSearchTypeTimeout = useRef(null);
	const doctorsRef = useRef(null);

	useEffect(() => {
		return () => {
			if (onSearchTypeTimeout.current) {
				clearTimeout(onSearchTypeTimeout.current);
			}
		};
	}, []);

	useEffect(() => {
		const getAllDoctors = async value => {
			if (!value) {
				setAllDoctors([]);
				return;
			}
			setIsSearchDoctorsLoading(true);
			if (onSearchTypeTimeout.current) clearTimeout(onSearchTypeTimeout.current);
			onSearchTypeTimeout.current = setTimeout(async () => {
				const params = { pageIndex: 0, pageSize: 20, levelId: props.levelId, search: searchValue };
				const response = await getDoctors(params);
				if (response.error) {
					setError(response.error.message);
				} else {
					setAllDoctors(searchValue ? response : []);
				}
				setIsSearchDoctorsLoading(false);
			}, 500);
		};
		if (!searchValue) {
			setAllDoctors([]);
			return;
		}
		getAllDoctors(searchValue);
	}, [searchValue]);

	const handleSubmit = async () => {
		setError('');
		if (assignedDoctors.length === 0) {
			return;
		}
		setIsLoading(true);
		const response = await assignMembers(
			userSession.healthSystem.id,
			props.levelId,
			assignedDoctors.map(assignedDoctor => assignedDoctor.id)
		);
		if (response.error) {
			setError(response.error.message);
			setIsLoading(false);
			return;
		}
		props.onSubmit();
		setIsLoading(false);
	};

	const handleChange = event => {
		setSearchValue(event.target.value);
		setShowItemDropdown(true);
	};

	const selectItem = item => {
		if (isAlreadyInPool(item.id)) {
			return;
		}
		if (assignedDoctors.length === 5) {
			setError(intl.formatMessage({ id: 'cantAddMoreDoctors' }));
			return;
		}
		setAssignedDoctors([...assignedDoctors, item]);
	};

	const isAlreadyInPool = doctorId => {
		let result = '';
		if (assignedDoctors.findIndex(doctor => doctor.id === doctorId) > -1) {
			result = intl.formatMessage({ id: 'alreadySelected' });
		}
		if (allDoctors.find(doctor => doctor.id === doctorId)?.isAlreadyInPool) {
			result = intl.formatMessage({ id: 'alreadyInPool' });
		}
		return result;
	};

	const handleDeleteAssignedDoctor = id => () => setAssignedDoctors(assignedDoctors.filter(doctor => id !== doctor.id));

	useOutsideClick(doctorsRef, () => {
		setSearchValue('');
		setShowItemDropdown(false);
	});

	return (
		<Modal
			modalSelector='editSectorModal'
			display={true}
			position='right'
			onModalSubmit={handleSubmit}
			onModalClose={props.onModalClose}
			className={`assign-doctor-modal${isLoading ? ' none-pointer-events' : ''}`}
			shouldSubmitOnEnter={false}
			isSubmitDisabled={assignedDoctors.length === 0}
			primaryButtonLoading={isLoading}>
			<Form
				className={isLoading ? 'disabled' : ''}
				title={translate('addDoctor')}
				description={intl.formatMessage({ id: 'assignDocModalDesc' })}
				onSubmit={event => event.preventDefault()}>
				<div className='mi-input-wrapper'>
					<input
						type='text'
						onChange={handleChange}
						value={searchValue}
						placeholder={intl.formatMessage({ id: 'searchDoctor' })}
					/>
					{!isSearchNursesLoading && allDoctors.length > 0 && showItemDropdown && (
						<div ref={doctorsRef}>
							{allDoctors.map(doctor => (
								<div
									key={doctor.id}
									className={`cursor-pointer ${isAlreadyInPool(doctor.id) ? 'disabled' : ''}`}
									onClick={() => selectItem(doctor)}>
									<p className='gray-color'>{`${doctor.firstName} ${doctor.lastName} ${isAlreadyInPool(doctor.id)}`}</p>
								</div>
							))}
						</div>
					)}
					{allDoctors.length === 0 && searchValue && !isSearchNursesLoading && (
						<div>
							<div>
								<p>{translate('noResultsFound')}</p>
							</div>
						</div>
					)}
					{isSearchNursesLoading && (
						<div className='mi-loading-div position-relative' style={{ minHeight: '50px' }}>
							<Loader />
						</div>
					)}
				</div>
				<div className='mi-selected-list'>
					<p>{translate('selectedDoctors')}</p>
					{assignedDoctors &&
						assignedDoctors?.map(doctor => (
							<div key={doctor.id} className='position-relative cursor-pointer medical-icons'>
								<i className='material-icons' onClick={handleDeleteAssignedDoctor(doctor.id)}>
									close
								</i>
								<p>{`${doctor.firstName} ${doctor.lastName}`}</p>
							</div>
						))}
				</div>
			</Form>
			<Alert display={error} fixed={true} message={error} variant='dark' />
		</Modal>
	);
};

export default AssignDoctor;
