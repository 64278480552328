import React, { useState, useEffect, useContext, useRef } from 'react';
import Dropdown from 'components/Common/Dropdown.jsx';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import Loader from 'components/Common/Loader.jsx';
import translate from 'i18n-translations/translate.jsx';
import { getUserInfo } from 'infrastructure/auth.js';
import { getNurseAnsweredCalls, getUserNotifications, updateUnreadMissedCallsNotifications } from 'api/patientNotifications.js';
import { formattedDate } from 'infrastructure/helpers/dateHelper.js';
import ProfilePicture from 'components/Common/ProfilePicture.jsx';
import { actionCreators as userActionCreators } from 'state/user/actions.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SocketEvents from 'constants/socket-events.js';
import { actionCreators as healthSystemsActionCreators } from 'state/healthSystems/actions.js';
import { MediaPermissionsErrorType, requestMediaPermissions } from 'mic-check';
import { isChrome, isEdgeChromium } from 'react-device-detect';
import { StreamError, MediaPermissions, UserPermissionDeniedErrors, ErrorComponentTypes } from 'constants/enums.js';
import { checkIfMediaDevicesPlugged } from 'infrastructure/helpers/commonHelpers.js';
import StartQueryStringKeys from 'calls/enums/StartQueryStringKeys.js';
import { enums } from '@solaborate/calls';
import { getDeviceOwnerPatient } from 'api/patients.js';
import Calls from 'icons/Header/Calls.jsx';
import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';
import { useIntl } from 'react-intl';
import Button from 'components/Common/Button.jsx';

const MissedCallsNotifications = ({ setIsMissedCallsOpen, isMissedCallsOpen }) => {
	const intl = useIntl();
	const tabTypes = { MISSED_CALLS: 1, ANSWERED_CALLS: 2 };

	const Tabs = [
		{
			description: intl.formatMessage({ id: 'missedCalls' }),
			id: tabTypes.MISSED_CALLS,
		},
		{
			description: intl.formatMessage({ id: 'answeredCalls' }),
			id: tabTypes.ANSWERED_CALLS,
		},
	];

	const unreadMissedCallsNotificationsCounter = useSelector(state => state.user.unreadMissedCallsNotificationsCounter);
	const [notifications, setNotifications] = useState([]);
	const [totalNotificationsCount, setTotalNotificationsCount] = useState(0);
	const [notificationsLoading, setNotificationsLoading] = useState(false);
	const [pageIndex, setPageIndex] = useState(0);
	const [error, setError] = useState(null);
	const user = useSelector(state => state.user);
	const dispatch = useDispatch();
	const socket = useContext(SocketContext);
	const isAllowPermissionPrompt = useRef(false);
	const micStatus = useRef(null);
	const [activeTab, setActiveTab] = useState(tabTypes.MISSED_CALLS);
	const [answeredNotifications, setAnsweredNotifications] = useState([]);
	const [answeredPageIndex, answeredSetPageIndex] = useState(0);
	const [answeredNotificationsLoading, setAnsweredNotificationsLoading] = useState(false);
	const [totalAnsweredNotificationsCount, setTotalAnsweredNotificationsCount] = useState(0);

	const onScroll = event => {
		const isBottom = Math.abs(event.target.scrollHeight - (event.target.scrollTop + event.target.clientHeight)) <= 5;
		if (notifications.length < totalNotificationsCount && isBottom && !notificationsLoading) {
			setPageIndex(prevState => prevState + 1);
		}
	};

	const onScrollAnswered = event => {
		const isBottom = Math.abs(event.target.scrollHeight - (event.target.scrollTop + event.target.clientHeight)) <= 5;
		if (answeredNotifications.length < totalAnsweredNotificationsCount && isBottom && !answeredNotificationsLoading) {
			answeredSetPageIndex(prevState => prevState + 1);
		}
	};

	useEffect(() => {
		setError(null);
		const onNotificationUpdated = () => {
			dispatch(userActionCreators.setUnreadMissedCallsNotificationsCounterIncrement);
		};
		socket.on(SocketEvents.User.ON_NOTIFICATION_UPDATED, onNotificationUpdated);
		return () => {
			socket.off(SocketEvents.User.ON_NOTIFICATION_UPDATED, onNotificationUpdated);
		};
	}, [dispatch, socket]);

	useEffect(() => {
		const fetchTotalUnReadNotifications = async () => {
			const { userId } = getUserInfo();
			const params = { userId, pageIndex: 0, pageSize: 1 };
			const response = await getUserNotifications(params);
			if (!response.error) {
				dispatch(userActionCreators.setUnreadMissedCallsNotificationsCounter(response.totalUnread));
			}
		};
		fetchTotalUnReadNotifications();
	}, [dispatch]);

	const getUnReadNotificationIds = notifications => {
		return notifications.reduce((result, notification) => {
			if (notification.isPreview) {
				result.push(notification.id);
			}
			return result;
		}, []);
	};

	const mapDeviceOwnerData = async (item, index) => {
		const response = await getDeviceOwnerPatient(item.callerObjectId);
		const responseError = response.errorResponse || response.error;
		if (responseError) {
			setError(responseError.message);
			return { ...item, fullName: '', profilePicture: '', id: index };
		}
		return { ...item, fullName: response.fullName, profilePicture: response.profilePicture, id: index };
	};

	useEffect(() => {
		const fetchNotifications = async () => {
			setError(null);
			if (activeTab === tabTypes.ANSWERED_CALLS) {
				return;
			}
			const pageSize = 10;
			const { userId } = getUserInfo();
			setNotificationsLoading(true);
			if (!isMissedCallsOpen) {
				setPageIndex(0);
				setTotalNotificationsCount(0);
				setNotifications([]);
				return;
			}
			const params = { userId, pageIndex, pageSize };
			const response = await getUserNotifications(params);
			if (response.error) {
				setError(response.error.message);
			} else {
				const mapped = await Promise.all(response.notifications.map((item, index) => mapDeviceOwnerData(item, index)));
				setNotifications(prevState => [...prevState, ...mapped]);
				setTotalNotificationsCount(response.total);
				const unReadNotifications = getUnReadNotificationIds(response.notifications);
				const updateResponse = await updateUnreadMissedCallsNotifications(userId, unReadNotifications);
				if (!updateResponse.error) {
					dispatch(
						userActionCreators.setUnreadMissedCallsNotificationsCounter(response.totalUnread - unReadNotifications.length)
					);
				}
			}
			setNotificationsLoading(false);
		};
		fetchNotifications();
	}, [isMissedCallsOpen, pageIndex, activeTab]);

	const showAllowPermissionModal = () => {
		isAllowPermissionPrompt.current = true;
		setTimeout(() => {
			if (!isAllowPermissionPrompt.current) {
				return;
			}

			dispatch(
				healthSystemsActionCreators.setStreamPermissionMessage({
					component: ErrorComponentTypes.Modal,
					type: isChrome || isEdgeChromium ? StreamError.MICROPHONE_BLOCKED.type : StreamError.MICROPHONE_BLOCKED_GENERIC.type,
				})
			);
		}, 500);
	};

	const clearPermissions = () => {
		dispatch(healthSystemsActionCreators.setStreamPermissionMessage(null));
	};

	const handlePermissionErrors = async (callType, permissionError) => {
		const { camera, microphone } = await checkIfMediaDevicesPlugged();
		if ([enums.CallTypes.VIDEO, enums.CallTypes.AUDIO].includes(callType) && (!camera || !microphone)) {
			dispatch(
				healthSystemsActionCreators.setStreamPermissionMessage({
					component: ErrorComponentTypes.Modal,
					type: microphone ? StreamError.MICROPHONE_NOT_FOUND.type : StreamError.CAMERA_NOT_FOUND.type,
				})
			);
			return;
		}
		if (micStatus.current.state === MediaPermissions.DENIED) {
			dispatch(
				healthSystemsActionCreators.setStreamPermissionMessage({
					component: ErrorComponentTypes.Modal,
					type: StreamError.MICROPHONE_BLOCKED.type,
				})
			);
			return;
		}

		const { type, name } = permissionError;
		if (type === MediaPermissionsErrorType.UserPermissionDenied) {
			if (name === UserPermissionDeniedErrors.NotAllowedError) {
				dispatch(
					healthSystemsActionCreators.setStreamPermissionMessage({
						component: isChrome || isEdgeChromium ? ErrorComponentTypes.Popup : ErrorComponentTypes.Modal,
						type: isChrome || isEdgeChromium ? StreamError.MICROPHONE_BLOCKED.type : StreamError.MICROPHONE_BLOCKED_GENERIC.type,
					})
				);
			}
		}
	};

	const openCallViewUrl = notification => {
		const queryParams = new URLSearchParams({
			[StartQueryStringKeys.OBJECT_ID]: notification.callerObjectId || notification.objectId,
			[StartQueryStringKeys.OBJECT_TYPE]: enums.ObjectTypes.HELLO_DEVICE,
			[StartQueryStringKeys.CONFERENCE_NAME]: notification.callerName || notification.name,
			[StartQueryStringKeys.CALL_TYPE]: enums.CallTypes.VIDEO,
		});
		clearPermissions();
		window.open(`/call?${queryParams.toString()}`, '_blank');
	};

	const talkToPatient = async notification => {
		try {
			showAllowPermissionModal();
			await requestMediaPermissions({ audio: true, video: false });
			openCallViewUrl(notification);
		} catch (permissionError) {
			handlePermissionErrors(enums.CallTypes.VIDEO, permissionError);
		}
		isAllowPermissionPrompt.current = false;
	};

	useEffect(() => {
		const fetchAnsweredNotifications = async () => {
			setError(null);
			if (activeTab === tabTypes.MISSED_CALLS) {
				return;
			}
			const pageSize = 10;
			const { userId } = getUserInfo();
			setAnsweredNotificationsLoading(true);
			if (!isMissedCallsOpen) {
				answeredSetPageIndex(0);
				setTotalAnsweredNotificationsCount(0);
				setNotifications([]);
				return;
			}
			const params = { userId, healthSystemId: user.userSession.healthSystem.id, pageIndex: answeredPageIndex, pageSize };
			const response = await getNurseAnsweredCalls(params);
			if (response.error) {
				setError(response.error.message);
			} else {
				setAnsweredNotifications(response.patientParticipants);
				setTotalNotificationsCount(response.totalCount);
				setTotalAnsweredNotificationsCount(response.patientParticipants.length);
			}
			setAnsweredNotificationsLoading(false);
		};
		fetchAnsweredNotifications();
	}, [isMissedCallsOpen, answeredPageIndex, activeTab]);

	const handleSelectedTab = item => {
		if (activeTab === item.id) {
			return;
		}
		setActiveTab(item.id);
		if (tabTypes.MISSED_CALLS === item.id) {
			setNotifications([]);
			answeredSetPageIndex(0);
		}
		if (tabTypes.ANSWERED_CALLS === item.id) {
			setAnsweredNotifications([]);
			setPageIndex(0);
		}
	};

	return (
		<div className='position-relative'>
			<Dropdown
				isPortal={true}
				setIsPortalOpen={val => setIsMissedCallsOpen(val)}
				position='bottom'
				className='notifications-icon-header'
				stayOpenOnClick={true}
				icon={<Calls color={user.darkMode ? DarkTheme.colors.grayFive : LightTheme.colors.grayFive} />}
				unreadNotificationCount={unreadMissedCallsNotificationsCounter}
				toolTip={intl.formatMessage({ id: 'callsNotifications' })}
				toolTipPosition='bottom'>
				<div className='dropdown__items'>
					<ul
						className={classNames(
							'list-group list-group-notifications overflow-hidden',
							0 ? ' list-group-unread' : '',
							user.darkMode ? 'dark-list-notifications' : ''
						)}>
						<div className='missed-notifications-list-title'>
							<h3>{translate('callsNotifications')}</h3>
							{notifications.length >= 0 && (
								<>
									{tabTypes.MISSED_CALLS === activeTab && (
										<span>
											{notifications.length}/{totalNotificationsCount}
										</span>
									)}
									{tabTypes.ANSWERED_CALLS === activeTab && (
										<span>
											{answeredNotifications.length}/{totalAnsweredNotificationsCount}
										</span>
									)}
								</>
							)}
						</div>
						<div className='missed-notifications-list-title patient-notifications-tabs'>
							{Tabs.map(item => (
								<p key={item.id} className={activeTab === item.id ? 'active' : ''} onClick={() => handleSelectedTab(item)}>
									{item.description}
								</p>
							))}
						</div>
						{error && <h3>{error}</h3>}
						{activeTab === tabTypes.MISSED_CALLS && (
							<>
								<div onScroll={onScroll} className='notifications-list'>
									<div className='notification-item missed-calls-notification'>
										{notifications.map(notification => (
											<div key={notification.id} className={classNames('flex', user.darkMode ? 'dark-mode' : '')}>
												<ProfilePicture
													className='profile-picture-wrapper doctor-request-img available-doctor-request-img'
													fullName={notification.fullName}
													profilePicture={notification.profilePicture}
												/>
												<div>
													<h4 style={{ fontWeight: notification.isPreview ? 600 : '' }} className='patient-name'>
														{notification.fullName}
													</h4>
													<p style={{ fontWeight: notification.isPreview ? 600 : '' }}>
														{translate('missedCallFrom')} {notification.callerName}
													</p>
													<div>
														<Button onClick={() => talkToPatient(notification)} text={translate('callBack')} />
													</div>
												</div>
												<span>{formattedDate(notification.dateCreated)} </span>
											</div>
										))}
									</div>
									{notificationsLoading && (
										<div className='loader'>
											<Loader />
										</div>
									)}
								</div>
								{notifications.length === 0 && !notificationsLoading && (
									<li>
										<span className='notification-item'>{translate('noNotifications')}</span>
									</li>
								)}
							</>
						)}
						{activeTab === tabTypes.ANSWERED_CALLS && (
							<>
								<div onScroll={onScrollAnswered} className='notifications-list'>
									<div className='notification-item missed-calls-notification'>
										{answeredNotifications.map(notification => (
											<div key={notification.id} className={classNames('flex', user.darkMode ? 'dark-mode' : '')}>
												<ProfilePicture
													className='profile-picture-wrapper doctor-request-img available-doctor-request-img'
													fullName={notification.patient}
													profilePicture={notification.picture}
												/>
												<div>
													<h4 style={{ fontWeight: notification.isPreview ? 600 : '' }} className='patient-name'>
														{notification.patient}
													</h4>
													<p style={{ fontWeight: notification.isPreview ? 600 : '' }}>{notification.name}</p>
													<div>
														<Button onClick={() => talkToPatient(notification)} text={translate('callBack')} />
													</div>
												</div>
												<span>{formattedDate(notification.timeJoined)} </span>
											</div>
										))}
									</div>
									{answeredNotificationsLoading && (
										<div className='loader'>
											<Loader />
										</div>
									)}
								</div>
								{answeredNotifications.length === 0 && !answeredNotificationsLoading && (
									<li>
										<span className='notification-item'>{translate('noNotifications')}</span>
									</li>
								)}
							</>
						)}
					</ul>
				</div>
			</Dropdown>
		</div>
	);
};

export default MissedCallsNotifications;
