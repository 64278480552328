import React, { useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { enums } from '@solaborate/calls';
import { Alert, Button, EmptyState, Grid, Loader } from 'components/index.js';
import translate from 'i18n-translations/translate.jsx';
import { findSectorById, stringToCamelCase } from 'infrastructure/helpers/commonHelpers.js';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import { getPatientQueue } from 'api/users.js';
import { getUserId } from 'infrastructure/auth.js';
import { updateMedicalVisitStatus } from 'api/visits.js';
import { QueueChangeType, VisitStatus, VisitTypes } from 'constants/visitEnums.js';
import { StartQueryStringKeys } from 'calls/enums/index.js';
import { actionCreators as healthSystemsActionCreators } from 'state/healthSystems/actions.js';
import SocketEvents from 'constants/socket-events.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import { VisitReasons } from 'constants/enums.js';
import { actionCreators as userActionCreators } from 'state/user/actions.js';

const DoctorWaitingRoom = () => {
	const { current: userId } = useRef(getUserId());
	const history = useHistory();
	const intl = useIntl();
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [currentCase, setCurrentCase] = useState(null);
	const [isInviteLoading, setIsInviteLoading] = useState(false);
	const [isDidNotShowUpLoading, setIsDidNotShowUpLoading] = useState(false);
	const [isStartVisitLoading, setIsStartVisitLoading] = useState(false);
	const [caseQueue, setCaseQueue] = useState([]);
	const [hasNoActiveVisit, setHasNoActiveVisit] = useState(false);
	const tree = useSelector(state => state.healthSystems.treeData.tree);
	const dispatch = useDispatch();
	const socket = useContext(SocketContext);
	const user = useSelector(state => state.user);
	const setUserWaitingRoomCount = count => dispatch(userActionCreators.setUserWaitingRoomCount(count));

	const visitReasons = [
		{
			id: 0,
			value: VisitReasons.INSTRUCTION,
			text: intl.formatMessage({ id: 'instruction' }),
		},
		{
			id: 1,
			value: VisitReasons.THERAPY_DESCRIPTION,
			text: intl.formatMessage({ id: 'therapyDescription' }),
		},
		{
			id: 2,
			value: VisitReasons.CHECK_UP,
			text: intl.formatMessage({ id: 'checkUp' }),
		},
		{
			id: 3,
			value: VisitReasons.RE_CHECK_UP,
			text: intl.formatMessage({ id: 'reCheckUp' }),
		},
		{
			id: 4,
			value: VisitReasons.REFERRAL,
			text: intl.formatMessage({ id: 'referral' }),
		},
		{
			id: 5,
			value: VisitReasons.OTHER,
			text: intl.formatMessage({ id: 'other' }),
		},
	];

	useEffect(() => {
		const fetchPatientQueue = async () => {
			const response = await getPatientQueue(userId);
			if (response.error) {
				setError(response.error.message);
			} else {
				setCurrentCase(null);
				setUserWaitingRoomCount(response.length);
				if (response.length > 0) {
					setUserWaitingRoomCount(response.length);
					const firstItem = response.shift();
					setCurrentCase(firstItem);
				}
				setCaseQueue(response);
			}
			setIsLoading(false);
		};
		fetchPatientQueue();
	}, [dispatch, userId, hasNoActiveVisit]);

	const openCallViewUrl = (id, medicalVisitId) => {
		const sector = findSectorById(tree, id);
		const queryParams = new URLSearchParams({
			[StartQueryStringKeys.OBJECT_ID]: sector.helloDeviceId,
			[StartQueryStringKeys.OBJECT_TYPE]: enums.ObjectTypes.HELLO_DEVICE,
			[StartQueryStringKeys.CONFERENCE_NAME]: sector.name,
			[StartQueryStringKeys.CALL_TYPE]: enums.CallTypes.VIDEO,
			[StartQueryStringKeys.MEDICAL_VISIT_ID]: medicalVisitId,
		});
		dispatch(healthSystemsActionCreators.setStreamPermissionMessage(null));
		window.open(`/call?${queryParams.toString()}`, '_blank');
	};

	useEffect(() => {
		const handleQueueUpdated = item => {
			if (!item.medicalVisit.activeVisit) {
				setHasNoActiveVisit(true);
			}
			if (QueueChangeType.NEW === item.changeType) {
				if (!currentCase) {
					setCurrentCase(item.medicalVisit);
				} else {
					setCaseQueue(prevState => [...prevState, item.medicalVisit]);
				}
			}
			if (QueueChangeType.STATUS_CHANGED === item.changeType) {
				if (!currentCase || currentCase.caseId === item.medicalVisit.caseId) {
					setCurrentCase(item.medicalVisit);
				} else {
					setCaseQueue(prevState => [...prevState.filter(queue => queue.caseId !== item.medicalVisit.caseId), item.medicalVisit]);
					setUserWaitingRoomCount(user.waitingRoomCount - 1);
				}
			}
		};
		socket.on(SocketEvents.HealthCare.PATIENT_QUEUE_UPDATED, handleQueueUpdated);
		return () => {
			socket.off(SocketEvents.HealthCare.PATIENT_QUEUE_UPDATED, handleQueueUpdated);
		};
	}, [currentCase, socket]);

	const handleInvitePatientToRoom = async () => {
		setIsInviteLoading(true);
		if (currentCase.activeVisit.isRemote) {
			if (![VisitStatus.INVITED_IN_CALL, VisitStatus.IN_PROGRESS].includes(currentCase.activeVisit.medicalVisitStatusId)) {
				const frontDeskLastVisit = currentCase.visits.filter(item => item.medicalVisitTypeId === VisitTypes.FRONT_DESK).pop();
				openCallViewUrl(frontDeskLastVisit.roomId, currentCase.activeVisit.id);
			}
			const response = await updateMedicalVisitStatus(currentCase.activeVisit.id, {
				visitStatus: [VisitStatus.INVITED_IN_CALL, VisitStatus.IN_PROGRESS].includes(currentCase.activeVisit.medicalVisitStatusId)
					? VisitStatus.COMPLETED
					: VisitStatus.IN_PROGRESS,
			});
			if (response.error) {
				setError(response.error.message);
			} else {
				if (currentCase.activeVisit.medicalVisitStatusId === VisitStatus.INVITED_IN_CALL) {
					const caseQueueCopied = [...caseQueue];
					const queueToSet = caseQueueCopied.shift();
					setCurrentCase(queueToSet || null);
					setCaseQueue(caseQueueCopied);
					setIsInviteLoading(false);
					setUserWaitingRoomCount(user.waitingRoomCount - 1);
					return;
				}
				setCurrentCase({
					...currentCase,
					activeVisit: {
						...currentCase.activeVisit,
						medicalVisitStatusId: VisitStatus.INVITED_IN_CALL,
					},
				});
				setIsInviteLoading(false);
			}
		} else {
			const visitStatus =
				currentCase.activeVisit.medicalVisitStatusId === VisitStatus.DID_NOT_SHOW_UP
					? VisitStatus.SECOND_INVITED
					: VisitStatus.INVITED;
			const response = await updateMedicalVisitStatus(currentCase.activeVisit.id, { visitStatus });
			if (response.error) {
				setError(response.error.message);
			} else {
				const activeCase = { ...currentCase };
				activeCase.activeVisit.medicalVisitStatusId = visitStatus;
				setCurrentCase(activeCase);
			}
		}
		setIsInviteLoading(false);
	};

	const handleStartVisit = async () => {
		setIsStartVisitLoading(true);
		if (currentCase.activeVisit.medicalVisitStatusId === VisitStatus.IN_PROGRESS) {
			setIsStartVisitLoading(false);
			history.push({ pathname: `/waiting-room-inperson/${currentCase.patient.patientId}`, state: currentCase });
			return;
		}
		const response = await updateMedicalVisitStatus(currentCase.activeVisit.id, { visitStatus: VisitStatus.IN_PROGRESS });
		if (response.error) {
			setError(response.error.message);
		} else {
			history.push({ pathname: `/waiting-room-inperson/${currentCase.patient.patientId}`, state: currentCase });
		}
		setIsStartVisitLoading(false);
	};

	const handlePatientDidNotShowUp = async () => {
		setIsDidNotShowUpLoading(true);
		const visitStatus =
			currentCase.activeVisit.medicalVisitStatusId === VisitStatus.SECOND_INVITED
				? VisitStatus.CANCELLED
				: VisitStatus.DID_NOT_SHOW_UP;
		const response = await updateMedicalVisitStatus(currentCase.activeVisit.id, {
			visitStatus,
		});

		if (response.error) {
			setError(response.error.message);
			setIsDidNotShowUpLoading(false);
			return;
		}

		if (currentCase.activeVisit.medicalVisitStatusId === VisitStatus.SECOND_INVITED) {
			const caseQueueCopied = [...caseQueue];
			const queueToSet = caseQueueCopied.shift();
			setCurrentCase(queueToSet);
			setCaseQueue(caseQueueCopied);
			setIsDidNotShowUpLoading(false);
			setUserWaitingRoomCount(user.waitingRoomCount - 1);
			return;
		}

		if (caseQueue.length > 0) {
			const queue = getOrderedQueue();
			setCaseQueue(queue);
		} else {
			const currentCaseCopied = { ...currentCase };
			currentCaseCopied.activeVisit.medicalVisitStatusId = VisitStatus.DID_NOT_SHOW_UP;
			setCurrentCase(currentCaseCopied);
		}
		setIsDidNotShowUpLoading(false);
	};

	const getOrderedQueue = () => {
		const caseQueueCopied = [...caseQueue];
		const firstElement = caseQueueCopied.shift();
		const didNotShowUpCases = caseQueueCopied.filter(
			item => item.activeVisit.medicalVisitStatusId === VisitStatus.DID_NOT_SHOW_UP
		);
		const otherCases = caseQueueCopied.filter(item => item.activeVisit.medicalVisitStatusId !== VisitStatus.DID_NOT_SHOW_UP);
		const currentCaseCopied = { ...currentCase };
		currentCaseCopied.activeVisit.medicalVisitStatusId = VisitStatus.DID_NOT_SHOW_UP;
		setCurrentCase(firstElement);
		return [...didNotShowUpCases, currentCaseCopied, ...otherCases];
	};

	const showMarkAsCompleted = () =>
		(currentCase.activeVisit?.isRemote &&
			[VisitStatus.INVITED_IN_CALL, VisitStatus.IN_PROGRESS].includes(currentCase.activeVisit?.medicalVisitStatusId)) ||
		(!currentCase.activeVisit?.isRemote && currentCase.activeVisit?.medicalVisitStatusId === VisitStatus.INVITED_IN_CALL);

	const showStartVisit = () =>
		(currentCase.activeVisit?.isRemote &&
			[VisitStatus.INVITED, VisitStatus.SECOND_INVITED].includes(currentCase.activeVisit?.medicalVisitStatusId)) ||
		(!currentCase.activeVisit?.isRemote &&
			[VisitStatus.INVITED, VisitStatus.SECOND_INVITED, VisitStatus.IN_PROGRESS].includes(
				currentCase.activeVisit?.medicalVisitStatusId
			));

	const NextVisit = ({ item }) => {
		return (
			<div key={item.caseId}>
				<div className='flex flex-align-center'>
					<div className='flex-1'>
						<div>
							<h4>{item.patient.fullName}</h4>
							<p>{item.patient.idCard}</p>
							<p>
								{translate('caseId')}: <strong>{item.caseId}</strong>
							</p>
							<p>
								{translate('checkInNumber')}: <strong>{item.activeVisit?.checkInNumber ?? 0}</strong>
							</p>
						</div>
					</div>
					<div className='flex' />
					<div className='flex label'>{translate(item.activeVisit?.isRemote ? 'virtualCall' : 'inPerson')}</div>

					{[VisitStatus.INVITED, VisitStatus.SECOND_INVITED].includes(currentCase.activeVisit?.medicalVisitStatusId) && (
						<Button
							type='button'
							text={translate('patientNoShowUp')}
							className='--orange-background'
							onClick={handlePatientDidNotShowUp}
							isLoading={isDidNotShowUpLoading}
							isDisabled={isDidNotShowUpLoading}
						/>
					)}

					{[VisitStatus.PENDING, VisitStatus.DID_NOT_SHOW_UP].includes(currentCase.activeVisit?.medicalVisitStatusId) && (
						<Button
							text={translate('inviteToRoom')}
							type='button'
							onClick={handleInvitePatientToRoom}
							isLoading={isInviteLoading}
							isDisabled={isInviteLoading}
						/>
					)}

					{showMarkAsCompleted() && (
						<Button
							text={translate('markAsCompleted')}
							type='button'
							onClick={handleInvitePatientToRoom}
							isLoading={isInviteLoading}
							isDisabled={isInviteLoading}
						/>
					)}

					{showStartVisit() && (
						<Button
							type='button'
							text={translate('startVisit')}
							onClick={handleStartVisit}
							isLoading={isStartVisitLoading}
							isDisabled={isStartVisitLoading}
						/>
					)}
				</div>

				{item.visits.map(
					visit =>
						visit.medicalVisitTypeId === VisitTypes.FRONT_DESK && (
							<>
								<div className='top-30'>
									<h4>{translate('frontDeskCheckIn')}</h4>
									<p className='break-word'>{visit.performerName}</p>
								</div>
								{visit.informations.length > 0 && (
									<div className='top-30'>
										<h4>{translate('whatReasonOfVisit')}</h4>
										<p className='break-word'>
											{visit?.informations[0]?.medicalVisitReasonId === VisitReasons.OTHER &&
												visit?.informations[0]?.medicalVisitReasonText}
											{visit?.informations[0]?.medicalVisitReasonId !== VisitReasons.OTHER &&
												visitReasons.find(reason => reason.value === visit?.informations[0]?.medicalVisitReasonId)?.text}
										</p>
									</div>
								)}
								<div className='top-30'>
									{visit.symptoms.length > 0 && (
										<>
											<h4>{translate('symptoms')}</h4>
											<div className='flex symptoms-grid flex-wrap'>
												{visit.symptoms.map(symptom => {
													const translated = intl.formatMessage({ id: stringToCamelCase(symptom.name) });
													return <div key={symptom.symptomId}>{translated}</div>;
												})}
											</div>
										</>
									)}
								</div>
								{visit.informations.length > 0 && (
									<>
										{visit?.informations[0]?.answer && (
											<div className='top-30'>
												<h4>{translate('symptomsDuration')}</h4>
												<p className='break-word'>{visit?.informations[0]?.answer}</p>
											</div>
										)}
										{visit?.informations[0]?.additionalNote && (
											<div className='top-30'>
												<h4>{translate('additionalNotes')}</h4>
												<p className='break-word'>{visit?.informations[0]?.additionalNote}</p>
											</div>
										)}
									</>
								)}
							</>
						)
				)}

				{/* <div className='added-forms top-30'>
					<p>{translate('addedForms')}</p>
					{forms.map(el => (
						<div
							className='flex cursor-pointer'
							// onClick={() => setShowFormDetailsModal(item, el)}
						>
							<div>
								<img src={`${primaryCareImageUrl}form.svg`} alt='form icon' />
							</div>
							<div>
								<p>{el.code}</p>
								<p>{el.name}</p>
							</div>
						</div>
					))}
				</div> */}
			</div>
		);
	};

	const WaitingList = props => {
		const { item } = props;
		return (
			<div key={item.id}>
				<div className='flex flex-align-center'>
					<div className='flex-1'>
						<h4>{item.patient.fullName}</h4>

						<p>{item.patient.idCard}</p>
						<p>
							{translate('caseId')}: <strong>{item.caseId}</strong>
						</p>
					</div>
					<div className='flex' />
					<div className='flex label'>{translate(item.activeVisit?.isRemote ? 'virtualCall' : 'inPerson')}</div>
				</div>
			</div>
		);
	};

	return (
		<MainLayout>
			<div className='view-page-wrapper display-block'>
				{!isLoading && (
					<div className='waiting-room-view-inner full-width pc-waiting-room-inner'>
						{currentCase && (
							<div className='waiting-room-list'>
								<p>{translate('nextVisit')}</p>
								<NextVisit item={currentCase} />
							</div>
						)}
						{caseQueue.length > 0 && (
							<div className='waiting-room-list'>
								<p>{translate('waitingList')}</p>
								{caseQueue.map(item => (
									<WaitingList key={item.caseId} item={item} />
								))}
							</div>
						)}
					</div>
				)}
				{!isLoading && caseQueue.length === 0 && !currentCase && (
					<div className='empty-state-wrapper waiting-room-view-inner'>
						<EmptyState title={translate('noVisitsFound')} image='Visits.svg' />
					</div>
				)}
				{isLoading && (
					<Grid
						columns='1fr'
						width='100%'
						rows='1fr'
						stretch='calc(100vh - 200px)'
						className='max-initial-width'
						horizAlign='center'
						vertAlign='center'>
						<div style={{ textAlign: 'center' }}>
							<Loader />
						</div>
					</Grid>
				)}
				<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
			</div>
		</MainLayout>
	);
};

export default DoctorWaitingRoom;
