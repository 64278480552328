import { PatientAiSettingTypes } from 'constants/action-types.js';

const initialState = {
	aiSettings: [],
};

export const aiSettingsList = (state = initialState, action = { type: null, payload: null }) => {
	switch (action.type) {
		case PatientAiSettingTypes.SET_PATIENT_AI_SETTINGS:
			const aiSettings = state.aiSettings.filter(item => item.deviceId !== action.payload.deviceId);

			aiSettings.push({
				deviceId: action.payload.deviceId,
				settings: action.payload.settings,
			});

			return {
				...state,
				aiSettings,
			};

		default:
			return state;
	}
};
