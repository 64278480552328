import { getMultipleLevelFleetDevices } from 'api/fleetManagement.js';
import FleetManagementDeviceActions from 'components/FleetManagement/FleetManagementDeviceActions.jsx';
import FleetManagementDeviceStatus from 'components/FleetManagement/FleetManagementDeviceStatus.jsx';
import { Alert, CustomTable, Input } from 'components/index.js';
import { DeviceConnectionStatus } from 'constants/enums.js';
import SocketEvents from 'constants/socket-events.js';
import { isValidJSON } from 'infrastructure/helpers/commonHelpers.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

const DiagnosticDeviceIcons = {
	CAM: 'videocam_off',
	MIC: 'mic_off',
	LS: 'volume_off',
	RS: 'volume_off',
};

const ptzDevice = 'PTZ Camera';
const getMacAddress = macAddress => (macAddress ? macAddress.trim().replace(' ', ' | ') : 'N/A');

const FleetManagementDevices = ({ setFilterValues, filterValues, pagination, setPagination }) => {
	const intl = useIntl();
	const socket = useContext(SocketContext);
	const delayTimerRef = useRef(null);
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [devices, setDevices] = useState([]);

	const DiagnosticDeviceTooltip = {
		CAM: intl.formatMessage({ id: 'deviceCameraOff' }),
		MIC: intl.formatMessage({ id: 'microphoneOff' }),
		LS: intl.formatMessage({ id: 'leftSpeakerOff' }),
		RS: intl.formatMessage({ id: 'rightSpeakerOff' }),
	};

	const checkUpdateListCallback = useCallback(
		helloDeviceId => {
			return devices.some(
				device =>
					device.solHelloDeviceId === helloDeviceId ||
					device.connectedDevices.some(connected => connected.solHelloDeviceId === helloDeviceId)
			);
		},
		[devices]
	);

	const updateDevicesCallback = useCallback((helloDeviceId, isOnline) => {
		setDevices(prevState =>
			prevState.map(device => ({
				...device,
				isOnline: device.solHelloDeviceId === helloDeviceId ? isOnline : device.isOnline,
				connectedDevices: device.connectedDevices.map(connected => ({
					...connected,
					isOnline: connected.solHelloDeviceId === helloDeviceId ? isOnline : connected.isOnline,
				})),
			}))
		);
	}, []);

	const updatePTZDevicesCallback = useCallback((helloDeviceId, connectedDevices) => {
		setDevices(prevState =>
			prevState.map(device => (device.solHelloDeviceId !== helloDeviceId ? device : { ...device, connectedDevices }))
		);
	}, []);

	useEffect(() => {
		const onUpdateDeviceStatus = (data, isOnline) => {
			const shouldUpdateDevicesList = checkUpdateListCallback(data.helloDeviceId);
			if (!shouldUpdateDevicesList) {
				return;
			}
			updateDevicesCallback(data.helloDeviceId, isOnline);
		};

		const onDeviceOffline = data => onUpdateDeviceStatus(data, false);
		const onDeviceOnline = data => onUpdateDeviceStatus(data, true);

		socket.on(SocketEvents.Client.ON_DEVICE_OFFLINE, onDeviceOffline);
		socket.on(SocketEvents.Client.ON_DEVICE_ONLINE, onDeviceOnline);

		return () => {
			socket.off(SocketEvents.Client.ON_DEVICE_OFFLINE, onDeviceOffline);
			socket.off(SocketEvents.Client.ON_DEVICE_ONLINE, onDeviceOnline);
		};
	}, [checkUpdateListCallback]);

	useEffect(() => {
		const onPTZConnected = data => {
			const shouldUpdateDevicesList = checkUpdateListCallback(data.solDeviceId);
			if (!shouldUpdateDevicesList) {
				return;
			}
			updatePTZDevicesCallback(data.solDeviceId, data.data.value);
		};

		socket.on(SocketEvents.HelloDevice.ON_DEVICE_COMPONENTS_UPDATED, onPTZConnected);

		return () => {
			socket.off(SocketEvents.HelloDevice.ON_DEVICE_COMPONENTS_UPDATED, onPTZConnected);
		};
	}, [checkUpdateListCallback]);

	useEffect(() => {
		const fetchDevices = async () => {
			setIsLoading(true);
			const lastLevelIds = filterValues[filterValues.lastLevel.name].map(item =>
				isValidJSON(item.value) ? JSON.parse(item.value).id : item.value
			);
			const params = {
				...filterValues,
				currentPage: pagination.pageIndex,
				pageSize: pagination.pageSize,
				levelType: filterValues.lastLevel.id,
				levelIds: lastLevelIds,
				...(filterValues.status && { isOnline: filterValues.status === DeviceConnectionStatus.ONLINE }),
				connectionType: filterValues.connection,
			};

			const response = await getMultipleLevelFleetDevices(params);
			if (response.error) {
				setError(response.error.message);
				return;
			}

			setPagination(prevState => ({ ...prevState, totalCount: response.totalRows }));
			setDevices(pagination.pageIndex === 1 ? response.data : prev => [...prev, ...response.data]);
			setIsLoading(false);
		};
		fetchDevices();

		return () => {
			clearTimeout(delayTimerRef.current);
		};
	}, [filterValues, pagination.pageIndex, pagination.pageSize]);

	const devicesHeader = [
		{ title: intl.formatMessage({ id: 'room' }), id: 'roomName' },
		{ title: intl.formatMessage({ id: 'deviceModel' }), id: 'deviceModel' },
		{ title: intl.formatMessage({ id: 'serialNumber' }), id: 'serialNumber', columnClass: 'break-word' },
		{ title: `${intl.formatMessage({ id: 'device' })} ${intl.formatMessage({ id: 'status' })}`, id: 'isOnline' },
		{ title: intl.formatMessage({ id: 'macUpperAddress' }), id: 'macAddress', columnClass: 'break-word' },
		{ title: intl.formatMessage({ id: 'network' }), id: 'network' },
		{ title: intl.formatMessage({ id: 'osVersion' }), id: 'firmwareRevision', columnClass: 'break-word' },
		{ title: intl.formatMessage({ id: 'appVersion' }), id: 'appVersion', columnClass: 'break-word' },
		{ title: `${intl.formatMessage({ id: 'connected' })} ${intl.formatMessage({ id: 'devices' })}`, id: 'connectedDevices' },
		{ title: intl.formatMessage({ id: 'actions' }), id: 'actions' },
	];

	const getConnectedDevices = children => {
		if (children.length === 0) {
			return [];
		}

		const shouldRenderActions = child => {
			const isPtzDevice = child.deviceType === ptzDevice;
			return !isPtzDevice || child.isOnline;
		};

		return children.map(child => {
			const isPtzDevice = child.deviceType === ptzDevice;

			return {
				...child,
				id: child.deviceModel,
				serialNumber: child.serialNumber ?? 'N/A',
				isOnline: (
					<FleetManagementDeviceStatus isOnline={child.isOnline} isPtzDevice={isPtzDevice} lastOnline={child.lastOnline} />
				),
				macAddress: getMacAddress(child.macAddress),
				network: (
					<>
						{child.ssId && <span>SSID {child.ssId} </span>}
						{child.ipAddress && <span>IP {child.ipAddress}</span>}
						{!child.ssId && !child.ipAddress && 'N/A'}
					</>
				),
				firmwareRevision: child.firmwareRevision ?? child.osVersion ?? 'N/A',
				appVersion: child.appVersion ?? 'N/A',
				connectedDevices: !isPtzDevice && '',
				actions: <>{shouldRenderActions(child) && <FleetManagementDeviceActions device={child} isPtzDevice={isPtzDevice} />}</>,
			};
		});
	};

	const devicesRows = () => {
		if (devices.length === 0) {
			return [];
		}

		return devices.map(device => {
			const isAnyChildOffline = device.connectedDevices.some(device => !device.isOnline);
			const isAnyIssue = device.deviceDiagnostics.length > 0;
			const isPtzDevice = device.deviceType === ptzDevice;

			return {
				children: getConnectedDevices(device.connectedDevices),
				id: device.solHelloDeviceId,
				roomName: device.roomName ?? 'N/A',
				deviceModel: device.deviceModel,
				serialNumber: device.serialNumber,
				isOnline: (
					<FleetManagementDeviceStatus isPtzDevice={isPtzDevice} isOnline={device.isOnline} lastOnline={device.lastOnline} />
				),
				macAddress: getMacAddress(device.macAddress),
				network: (
					<>
						{device.ssId && <span>SSID: {device.ssId} </span>}
						{device.ipAddress && <span>IP: {device.ipAddress}</span>}
						{!device.ssId && !device.ipAddress && 'N/A'}
					</>
				),
				firmwareRevision: device.firmwareRevision ?? 'N/A',
				appVersion: device.appVersion ?? 'N/A',
				connectedDevices: (
					<div className='connected-devices'>
						<span>{device.connectedDevices.length}</span>
						{isAnyChildOffline && (
							<span
								data-tooltip={intl.formatMessage({ id: 'deviceOfflineStatus' })}
								data-position='top'
								className='material-symbols-outlined icon'>
								warning
							</span>
						)}
						{isAnyIssue &&
							device.deviceDiagnostics.map(issue => (
								<span
									key={issue.id}
									data-tooltip={DiagnosticDeviceTooltip[issue.uniqueName] ?? null}
									data-position='top'
									className='material-symbols-outlined icon'>
									{DiagnosticDeviceIcons[issue.uniqueName] ?? null}
								</span>
							))}
					</div>
				),
				actions: <FleetManagementDeviceActions device={device} isHelloDevice />,
			};
		});
	};

	const handleSearch = e => {
		clearTimeout(delayTimerRef.current);

		const timer = setTimeout(() => {
			setPagination({ pageIndex: 1, pageSize: 10, totalCount: 0 });
			setFilterValues(prevState => ({ ...prevState, search: e.target.value }));
		}, 500);

		delayTimerRef.current = timer;
	};

	return (
		<div className='fleet-component table-devices'>
			<div>
				<div className='table-devices-header'>
					<label>{intl.formatMessage({ id: 'devices' })}</label>
					<Input
						type='search'
						prefixIcon='search'
						variant='filled'
						placeholder={intl.formatMessage({ id: 'searchByRoomOrSN' })}
						onChange={handleSearch}
					/>
				</div>
				<CustomTable
					headers={devicesHeader}
					rows={devicesRows()}
					isLoading={isLoading}
					stickyHeader={true}
					isNested={true}
					setPagination={setPagination}
				/>
			</div>
			<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
		</div>
	);
};

export default FleetManagementDevices;
