import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import ProfilePicture from 'components/Common/ProfilePicture.jsx';
import translate from 'i18n-translations/translate.jsx';
import { getFormattedDate } from 'infrastructure/helpers/dateHelper.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import Dropdown from 'components/Common/Dropdown.jsx';
import { decodeHtml } from 'infrastructure/helpers/commonHelpers.js';
import { PresenceStatusType, UserStatus } from 'constants/enums.js';
import Loader from 'components/Common/Loader.jsx';
import Input from 'components/Common/Input.jsx';
import { useSelector } from 'react-redux';

const ChatList = props => {
	const helloName = useSelector(state => state.company.companySettings.helloName);

	const intl = useIntl();
	return (
		<div>
			<div>
				<div className='patients-list-header sub-page-header'>
					{props.isDoctorView && <h2>{translate('myPatients')}</h2>}
					{!props.isDoctorView && <h2>{translate('myHellos', { value: helloName })}</h2>}
				</div>
				<Input
					type='text'
					name='sectorSearchValue'
					placeholder={intl.formatMessage({ id: 'search' })}
					value={props.searchValue}
					onChange={event => props.setSearchValue(event.target.value)}
					validationOptions={{}}
					bottomSpace='0px'
					autoComplete='off'
				/>
			</div>
			<div className='chat-items-area scroll-hover' onScroll={props.handleScroll}>
				{props.patients.length > 0 && (
					<div>
						{props.patients.map(item => (
							<div key={item.id} onClick={() => props.onChatListItemClicked(item)}>
								<div
									className={
										props.selectedPatient.id === item.id
											? 'chat-list-item flex position-relative active'
											: 'chat-list-item flex position-relative'
									}>
									<div className='align-self-start position-relative'>
										<div className='flex position-relative chat-list-img-status-wrapper '>
											<ProfilePicture
												className='chat-list-img'
												fullName={`${item.firstName} ${item.lastName}`}
												profilePicture={item.profilePicture}
											/>
											<div className='chat-list-status flex'>
												<span
													className={
														item.presenceStatusTypeId === PresenceStatusType.UNAVAILABLE ||
														item.availability === UserStatus.OFFLINE
															? 'is-off'
															: 'is-on'
													}
												/>
											</div>
										</div>
										{item.lastActivity?.unReadMessageCount > 0 && (
											<div className='conversation-label-blue'>{item.lastActivity.unReadMessageCount}</div>
										)}
									</div>
									<div className='chat-list-name header-search-name flex flex-1'>
										<p title={decodeHtml(`${item.firstName} ${item.lastName}`)}>
											{decodeHtml(`${item.firstName} ${item.lastName}`)}
										</p>
										{item.devicesInfos && (
											<div className='device-name-blue-section'>
												<img src={`${healthCareCdnUrl}hello-icon.svg`} alt='icon' />
												<span>
													{item.devicesInfos.length === 0 && translate('noDevices')}
													{item.devicesInfos.length === 1 &&
														translate('oneDevice', {
															value: item.devicesInfos.length,
														})}
													{item.devicesInfos.length > 1 && translate('moreThanOneDevice', { value: item.devicesInfos.length })}
												</span>
											</div>
										)}
										{item.lastActivity && (
											<span className='chat-list-recent-message'>{decodeHtml(item.lastActivity.message)}</span>
										)}
										{item.lastActivity && (
											<span className='chat-list-time'>{getFormattedDate(item.lastActivity.dateCreated)}</span>
										)}
										{!item.lastActivity && (
											<>
												<span className='chat-list-time'>{getFormattedDate(item.requestAcceptedTime)}</span>
												<span className='chat-list-recent-message'>
													{translate('youHaveAcceptedRequest', { value: `${item.firstName} ${item.lastName}` })}
												</span>
											</>
										)}
										<Dropdown position='bottom' icon='more_horiz' className='remove-connection-wrapper'>
											<div className='remove-connection-results' onClick={() => props.removeConnection(item.id)}>
												<div className='flex'>
													<img src={`${healthCareCdnUrl}patient-home/RemoveConnection.svg`} alt='icon' />
													<p>{translate('removeConnection')}</p>
												</div>
											</div>
										</Dropdown>
									</div>
								</div>
							</div>
						))}
					</div>
				)}
				{props.patients.length === 0 && (
					<div className='chat-item-title'>
						<span className='semi-bold'>{translate(props.searchValue ? 'patientNotFound' : 'noConnectedPatientsYet')}</span>
					</div>
				)}
				{props.isScrollLoading && (
					<div className='patient-request-loader-pagination'>
						<Loader />
					</div>
				)}
			</div>
		</div>
	);
};

ChatList.propTypes = {
	onChatListItemClicked: PropTypes.func.isRequired,
	isDoctorView: PropTypes.bool.isRequired,
};

export default ChatList;
