import { getHealthSystemHospitals } from 'api/userIdleConfigurations.js';
import { Alert, Button } from 'components/index.js';
import { DeviceListLevel } from 'constants/enums.js';
import { configurationTypeIds, PatientInfotainmentProviders } from 'constants/integrationEnums.js';
import translate from 'i18n-translations/translate.jsx';
import { doNotAllowSpaceAsFirstCharacter } from 'infrastructure/helpers/commonHelpers.js';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import FormInput from 'components/Common/FormInput.jsx';

const PatientInfotainment = ({ itemToEdit, setConfigParams, configurationTypeId, fieldErrors }) => {
	const intl = useIntl();
	const allHealthSystems = useSelector(state => state.healthSystems.allHealthSystems);
	const [selectedHealthSystem, setSelectedHealthSystem] = useState(
		itemToEdit?.healthSystemId
			? {
					value: itemToEdit.healthSystemId,
					label: allHealthSystems.find(item => item.id === itemToEdit.healthSystemId).name,
			  }
			: { value: '0', label: 'All' }
	);
	const [selectedHospital, setSelectedHospital] = useState({ value: '0', label: 'All' });
	const [hospitals, setHospitals] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState('');
	const [items, setItems] = useState(
		itemToEdit?.items ?? [
			{
				link: { label: intl.formatMessage({ id: 'link' }), value: '' },
				thumbnail: { label: intl.formatMessage({ id: 'thumbnail' }), value: '' },
				content: { label: intl.formatMessage({ id: 'title' }), value: '' },
			},
		]
	);
	const [validations, setValidations] = useState({});
	const providers = [
		{ value: PatientInfotainmentProviders.MANUAL, label: 'Manual Catalog Input' },
		{ value: PatientInfotainmentProviders.SWANK, label: 'Swank' },
		{ value: PatientInfotainmentProviders.MAYO, label: 'Mayo Swank' },
	];
	const [selectedProvider, setSelectedProvider] = useState(
		itemToEdit?.providerType ? providers.find(item => item.value === itemToEdit.providerType) : providers[0]
	);
	const [userName, setUserName] = useState(itemToEdit?.userName ?? '');
	const [password, setPassword] = useState(itemToEdit?.password ?? '');
	const [clientId, setClientId] = useState(itemToEdit?.clientId ?? '');
	const [clientSecret, setClientSecret] = useState(itemToEdit?.clientSecret ?? '');
	const [portalId, setPortalID] = useState(itemToEdit?.portalId ?? '');
	const [deviceId, setDeviceID] = useState(itemToEdit?.deviceId ?? '');
	const [licenceUrl, setLicenceUrl] = useState(itemToEdit?.licenceUrl ?? '');

	useEffect(() => {
		const fetchHospitals = async () => {
			if (!selectedHealthSystem) {
				return;
			}
			let hsHospitals = [];
			if (selectedHealthSystem.value !== '0') {
				setIsLoading(true);
				const res = await getHealthSystemHospitals(selectedHealthSystem.value);
				if (res.error) {
					setError(res.error.message);
				} else {
					hsHospitals = res;
				}
			}
			const hospitalArray = hsHospitals.map(hospital => ({ value: hospital.id, label: hospital.name }));
			hospitalArray.unshift({ value: '0', label: intl.formatMessage({ id: 'all' }) });
			const foundHospital = hospitalArray.find(item => item.value === itemToEdit?.hospitalId);
			setHospitals(hospitalArray);
			setSelectedHospital(foundHospital || hospitalArray[0]);
			setIsLoading(false);
		};
		fetchHospitals();
	}, [selectedHealthSystem]);

	useEffect(() => {
		const infotainmentItems = items.filter(item => !Object.entries(item).every(([, value]) => !value.value));
		const toSendObject = { items: infotainmentItems, providerType: selectedProvider.value };
		if (selectedProvider.value === PatientInfotainmentProviders.SWANK) {
			toSendObject.userName = userName;
			toSendObject.password = password;
			toSendObject.deviceId = deviceId;
			toSendObject.portalId = portalId;
			toSendObject.licenceUrl = licenceUrl;
		}
		if (selectedProvider.value === PatientInfotainmentProviders.MAYO) {
			toSendObject.clientId = clientId;
			toSendObject.clientSecret = clientSecret;
			toSendObject.portalId = portalId;
			toSendObject.licenceUrl = licenceUrl;
		}
		const configJson = JSON.stringify(toSendObject);

		setConfigParams({
			configurationTypeId,
			nodeId: getNodeId().value !== '0' ? getNodeId().value : null,
			parentNodeId: selectedHospital.value !== '0' ? selectedHealthSystem.value : null,
			nodeName: getNodeId().label,
			nodeLevel: getNodeLevel(),
			configJson,
			validations,
		});
	}, [
		selectedHealthSystem,
		selectedHospital,
		configurationTypeId,
		items,
		selectedProvider,
		userName,
		password,
		portalId,
		deviceId,
		licenceUrl,
		clientSecret,
		clientId,
	]);

	useEffect(() => {
		if (configurationTypeId !== configurationTypeIds.MOVIES.id) {
			setSelectedProvider(providers[0]);
		}
	}, [configurationTypeId]);

	const getNodeId = () => (selectedHospital.value !== '0' ? selectedHospital : selectedHealthSystem);

	const getNodeLevel = () => {
		if (selectedHospital.value !== '0') {
			return DeviceListLevel.HOSPITAL;
		}
		if (selectedHealthSystem.value !== '0') {
			return DeviceListLevel.HEALTH_SYSTEM;
		}
		return null;
	};

	const transformArray = array => {
		if (!array) {
			return [];
		}
		const newArray = array.map(item => ({ value: item.id, label: item.name || item.value }));
		newArray.unshift({ value: '0', label: intl.formatMessage({ id: 'all' }) });
		return newArray;
	};

	const getType = () => {
		let result = intl.formatMessage({ id: 'movies' });
		if (configurationTypeId === configurationTypeIds.MUSIC.id) {
			result = intl.formatMessage({ id: 'music' });
		}
		if (configurationTypeId === configurationTypeIds.LIVE_TV_CHANNELS.id) {
			result = intl.formatMessage({ id: 'liveTvChannels' });
		}
		return result;
	};

	const handleDeleteItem = (index, key) => {
		setItems(prevState => {
			const array = [...prevState.map(item => item)];
			array.splice(index, 1);
			return array;
		});
		setValidations(prevState => {
			const copiedState = { ...prevState };
			Object.keys(copiedState).forEach(key => {
				if (key.startsWith(index)) {
					delete copiedState[key];
				}
			});
			return copiedState;
		});
	};

	const getItemError = (index, key) => (
		<span className='error font-12'>{fieldErrors && fieldErrors[`items[${index}].${key}.value`]}</span>
	);

	return (
		<>
			{configurationTypeId === configurationTypeIds.MOVIES.id && (
				<div className='input'>
					<p className='label'>{translate('provider')}</p>
					<p className='font-14'>{translate('selectProviderDesc')}</p>
					<Select
						isDisabled={itemToEdit?.providerType}
						value={selectedProvider}
						placeholder={intl.formatMessage({ id: 'selectProviderDesc' })}
						classNamePrefix='react-select'
						options={providers}
						onChange={setSelectedProvider}
					/>
				</div>
			)}

			<div className='input'>
				<p className='label'>{translate('selectHealthSystem')}</p>
				<p className='font-14'>{translate('selectHSForConfiguration')}</p>
				<Select
					value={selectedHealthSystem}
					placeholder={intl.formatMessage({ id: 'selectHealthSystem' })}
					classNamePrefix='react-select'
					options={transformArray(allHealthSystems)}
					onChange={setSelectedHealthSystem}
				/>
			</div>

			<div className='input'>
				<p className='label'>{translate('selectHospital')}</p>
				<p className='font-14'>{translate('chooseHospitalForChanges')}</p>
				<Select
					isDisabled={hospitals.length === 1 || isLoading}
					value={selectedHospital}
					placeholder={intl.formatMessage({ id: 'selectHealthSystem' })}
					classNamePrefix='react-select'
					options={hospitals}
					onChange={setSelectedHospital}
				/>
			</div>

			{[PatientInfotainmentProviders.MAYO, PatientInfotainmentProviders.SWANK].includes(selectedProvider.value) && (
				<>
					<div className='input'>
						<FormInput
							text={`${intl.formatMessage({ id: 'portalId' })}*`}
							id='portalId'
							placeholder={intl.formatMessage({ id: 'portalId' })}
							type='text'
							value={portalId}
							onChange={event => setPortalID(event.target.value)}
							maxLength={256}
							onKeyDown={doNotAllowSpaceAsFirstCharacter}
							error={fieldErrors?.portalId}
							touched={fieldErrors?.portalId}
						/>
					</div>
					<div className='input'>
						<FormInput
							text={`${intl.formatMessage({ id: 'licenceUrl' })}*`}
							id='licenceUrl'
							placeholder={intl.formatMessage({ id: 'licenceUrl' })}
							type='text'
							value={licenceUrl}
							onChange={event => setLicenceUrl(event.target.value)}
							maxLength={256}
							onKeyDown={doNotAllowSpaceAsFirstCharacter}
							error={fieldErrors?.licenceUrl}
							touched={fieldErrors?.licenceUrl}
						/>
					</div>
				</>
			)}
			{PatientInfotainmentProviders.SWANK === selectedProvider.value && (
				<>
					<div className='input'>
						<FormInput
							text={`${intl.formatMessage({ id: 'userName' })}*`}
							id='username'
							placeholder={intl.formatMessage({ id: 'userName' })}
							type='text'
							value={userName}
							onChange={event => setUserName(event.target.value)}
							maxLength={256}
							onKeyDown={doNotAllowSpaceAsFirstCharacter}
							error={fieldErrors?.userName}
							touched={fieldErrors?.userName}
						/>
					</div>
					<div className='input'>
						<FormInput
							text={`${intl.formatMessage({ id: 'password' })}*`}
							id='password'
							placeholder={intl.formatMessage({ id: 'password' })}
							type='text'
							value={password}
							onChange={event => setPassword(event.target.value)}
							maxLength={256}
							onKeyDown={doNotAllowSpaceAsFirstCharacter}
							error={fieldErrors?.password}
							touched={fieldErrors?.password}
						/>
					</div>
					<div className='input'>
						<FormInput
							text={`${intl.formatMessage({ id: 'deviceId' })}*`}
							id='deviceId'
							placeholder={intl.formatMessage({ id: 'deviceId' })}
							type='text'
							value={deviceId}
							onChange={event => setDeviceID(event.target.value)}
							maxLength={256}
							onKeyDown={doNotAllowSpaceAsFirstCharacter}
							error={fieldErrors?.deviceId}
							touched={fieldErrors?.deviceId}
						/>
					</div>
				</>
			)}

			{PatientInfotainmentProviders.MAYO === selectedProvider.value && (
				<>
					<div className='input'>
						<FormInput
							text={`${intl.formatMessage({ id: 'clientId' })}*`}
							id='clientId'
							placeholder={intl.formatMessage({ id: 'clientId' })}
							type='text'
							value={clientId}
							onChange={event => setClientId(event.target.value)}
							maxLength={256}
							onKeyDown={doNotAllowSpaceAsFirstCharacter}
							error={fieldErrors?.clientId}
							touched={fieldErrors?.clientId}
						/>
					</div>
					<div className='input'>
						<FormInput
							text={`${intl.formatMessage({ id: 'clientSecret' })}*`}
							id='clientSecret'
							placeholder={intl.formatMessage({ id: 'clientSecret' })}
							type='text'
							value={clientSecret}
							onChange={event => setClientSecret(event.target.value)}
							maxLength={256}
							onKeyDown={doNotAllowSpaceAsFirstCharacter}
							error={fieldErrors?.clientSecret}
							touched={fieldErrors?.clientSecret}
						/>
					</div>
				</>
			)}

			{PatientInfotainmentProviders.MANUAL === selectedProvider.value && (
				<>
					<div className='infotainment-items'>
						{items.map((item, index) => (
							<div className='flex' key={index}>
								<span className='sequence-number'>{index + 1}.</span>
								{Object.keys(item).map(key => (
									<div key={key}>
										<input
											onKeyDown={doNotAllowSpaceAsFirstCharacter}
											type='text'
											value={item[key].value}
											placeholder={item[key].label}
											onChange={event => {
												setItems(prevState => {
													const itemState = [...prevState.map(elem => ({ ...elem }))];
													itemState[index][key].value = event.target.value;
													return itemState;
												});
											}}
										/>
										{getItemError(index, key)}
									</div>
								))}
								<Button onClick={() => handleDeleteItem(index)} icon='delete' background='transparent' color='red' />
							</div>
						))}
						<small>{fieldErrors?.items}</small>
					</div>

					{items.length < 200 && (
						<div className='flex right-align-content top-20'>
							<Button
								text={translate('addSomething', { value: getType() })}
								onClick={() => {
									setItems(prevState => [
										...prevState,
										{
											link: { label: intl.formatMessage({ id: 'link' }), value: '' },
											thumbnail: { label: intl.formatMessage({ id: 'thumbnail' }), value: '' },
											content: { label: intl.formatMessage({ id: 'title' }), value: '' },
										},
									]);
								}}
							/>
						</div>
					)}
				</>
			)}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</>
	);
};

export default PatientInfotainment;
