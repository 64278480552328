import Dropdown from 'components/Common/Dropdown.jsx';
import { UserRoles } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import { getUserRole } from 'infrastructure/auth.js';
import Profile from 'icons/CareEvents/Profile.jsx';
import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';
import Alerts from 'icons/CareEvents/Alerts.jsx';
import EwsSettings from 'icons/RPM/EwsSettings.jsx';
import CareTeam from 'icons/RPM/CareTeam.jsx';
import CareLogs from 'icons/RPM/CareLogs.jsx';
import Surveys from 'icons/RPM/Surveys.jsx';
import Medications from 'icons/RPM/Medications.jsx';
import ChangeRpm from 'icons/RPM/ChangeRpm.jsx';
import RemovePatient from 'icons/RPM/RemovePatient.jsx';
import classNames from 'classnames';
import Button from 'components/Common/Button.jsx';

const RpmDropDownOptions = ({
	patient,
	toggleAlertsModal,
	toggleEwsSettings,
	toggleCarelogs,
	getPatientCareTeam,
	toggleSurvey,
	toggleRpmProgram,
	toggleMedicationModal,
	isDarkMode,
	isAlertHistoryShown,
	isDisabled,
	removeFromRpm,
	setPatientDetailsView,
	setSelectedPatient,
}) => {
	const getIconColor = isDarkMode => (isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive);

	return (
		<Dropdown
			position='bottom'
			icon='more_vert'
			className={classNames('patient-view-more-wrapper', { 'disabled-options': isDisabled })}>
			{!isDisabled && (
				<div className='patient-info-hello-list patient-info-view-more-list'>
					<div className='flex'>
						<Button
							svgIcon={<Profile color={getIconColor(isDarkMode)} />}
							className='flex'
							text={translate('viewProfile')}
							onClick={() => {
								setSelectedPatient(patient);
								getPatientCareTeam(patient.patientId, false);
								setPatientDetailsView(true);
							}}
						/>
					</div>
					{(isAlertHistoryShown || getUserRole() !== UserRoles.DOCTOR) && (
						<div className='flex'>
							<Button
								svgIcon={<Alerts color={getIconColor(isDarkMode)} />}
								className='flex'
								onClick={e => {
									e.stopPropagation();
									toggleAlertsModal(patient);
								}}
								text={translate('viewAlerts')}
							/>
						</div>
					)}
					<div className='flex'>
						<Button
							svgIcon={<EwsSettings color={getIconColor(isDarkMode)} />}
							className='flex'
							onClick={e => {
								e.stopPropagation();
								toggleEwsSettings(patient);
							}}
							text={translate('ewsSettings')}
						/>
					</div>
					<div className='flex'>
						<Button
							svgIcon={<CareLogs color={getIconColor(isDarkMode)} />}
							className='flex'
							onClick={e => {
								e.stopPropagation();
								toggleCarelogs(patient);
							}}
							text={translate('carelogs')}
						/>
					</div>
					<div className='flex'>
						<Button
							svgIcon={<CareTeam color={getIconColor(isDarkMode)} />}
							className='flex'
							onClick={e => {
								e.stopPropagation();
								getPatientCareTeam(patient.patientId);
							}}
							text={translate('careTeam')}
						/>
					</div>
					<div className='flex'>
						<Button
							svgIcon={<Surveys color={getIconColor(isDarkMode)} />}
							className='flex'
							onClick={e => {
								e.stopPropagation();
								toggleSurvey(patient);
							}}
							text={translate('surveys')}
						/>
					</div>
					<div className='flex'>
						<Button
							svgIcon={<Medications color={getIconColor(isDarkMode)} />}
							className='flex'
							onClick={e => {
								e.stopPropagation();
								toggleMedicationModal(patient);
							}}
							text={translate('viewMedications')}
						/>
					</div>
					<div className='flex'>
						<Button
							svgIcon={<ChangeRpm color={getIconColor(isDarkMode)} />}
							className='flex'
							onClick={e => {
								e.stopPropagation();
								toggleRpmProgram(patient);
							}}
							text={translate('changeRpmProgram')}
						/>
					</div>
				</div>
			)}
			{isDisabled && (
				<div className='patient-info-hello-list patient-info-view-more-list'>
					<div className='flex'>
						<Button
							svgIcon={<RemovePatient color={getIconColor(isDarkMode)} />}
							className='flex'
							onClick={() => removeFromRpm(patient)}
							text={translate('removeFromRpm')}
						/>
					</div>
				</div>
			)}
		</Dropdown>
	);
};

export default RpmDropDownOptions;
