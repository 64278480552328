import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import useOutsideClick from 'infrastructure/helpers/useOutsideClick.js';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import translate from 'i18n-translations/translate.jsx';
import { Alert, EmptyState } from 'components/index.js';
import { getCompanyId, getUserId, getUserRole, setDepartmentId, setHelloDeviceId } from 'infrastructure/auth.js';
import { getRegionSubTree } from 'api/tree.js';
import { getPatientQueueCount, updateSession } from 'api/users.js';
import { actionCreators as userActionCreators } from 'state/user/actions.js';
import { getRoomTypeIdsToFilterWith } from 'infrastructure/helpers/commonHelpers.js';
import { HealthcareErrorCode } from 'constants/enums.js';

const DoctorCheckIn = () => {
	const [departments, setDepartments] = useState([]);
	const [floors, setFloors] = useState([]);
	const [rooms, setRooms] = useState([]);
	const [isDropDownOpen, setIsDropDownOpen] = useState(false);
	const [isRoomsDropdownOpen, setIsRoomsDropdownOpen] = useState(false);
	const [isFloorsDropdownOpen, setIsFloorsDropdownOpen] = useState(false);
	const [selectedDepartment, setSelectedDepartment] = useState(null);
	const [selectedFloor, setSelectedFloor] = useState(null);
	const [selectedRoom, setSelectedRoom] = useState(null);
	const [error, setError] = useState(null);
	const [userIsCheckedInError, setUserIsCheckedInError] = useState('');
	const [activeQueueError, setActiveQueueError] = useState('');

	const wrapperRef = useRef(null);
	const floorWrapperRef = useRef(null);
	const roomWrapperRef = useRef(null);
	const userSession = useSelector(state => state.user.userSession);
	const companyId = getCompanyId();
	const dispatch = useDispatch();
	const setUserSession = session => dispatch(userActionCreators.setUserSession(session));
	const { current: userRole } = useRef(getUserRole());
	const intl = useIntl();
	const history = useHistory();

	useEffect(() => {
		const fetchFloors = async () => {
			const floorsList = departments.find(item => item.id === selectedDepartment.id).floors;
			setFloors(floorsList);
			setDepartmentId(selectedDepartment.id);
			if (userSession.checkedInChannelId) {
				const preSelectedFloorIndex = floorsList.findIndex(x => x.rooms.some(item => item.id === userSession.checkedInChannelId));
				setSelectedFloor(floorsList[preSelectedFloorIndex]);
				return;
			}
			setSelectedFloor(floorsList.length > 0 ? floorsList[0] : null);
			if (!floorsList.length) {
				setRooms([]);
				setSelectedRoom(null);
			}
		};
		if (selectedDepartment) {
			fetchFloors();
		}
	}, [departments, selectedDepartment, userSession.checkedInChannelId]);

	useEffect(() => {
		const fetchRooms = async () => {
			const roomsList = getRoomTypeIdsToFilterWith(selectedFloor?.rooms, userRole);
			setRooms(roomsList);
			if (userSession.checkedInChannelId) {
				const preSelectedRoom = roomsList.find(x => x.id === userSession.checkedInChannelId);
				if (!preSelectedRoom) {
					return;
				}
				setSelectedRoom(preSelectedRoom);
				setHelloDeviceId(preSelectedRoom.helloDeviceId);
				return;
			}
			setSelectedRoom(roomsList.length > 0 ? roomsList[0] : null);
		};
		if (selectedFloor) {
			fetchRooms();
		}
	}, [selectedFloor, userSession.checkedInChannelId]);

	useEffect(() => {
		const fetchDepartments = async () => {
			setError('');
			let departmentList = [];
			const response = await getRegionSubTree(userSession.healthSystem.id, userSession.regionId);
			if (response.error) {
				setError(response.error.message);
			} else {
				const { hospitals } = response.organization.healthSystem;
				departmentList = hospitals.length > 0 ? hospitals[0]?.departments : [];
			}
			setDepartments(departmentList);
			if (userSession.checkedInChannelId) {
				const result = departmentList.find(department =>
					department.floors.some(floor => floor.rooms.some(room => room.id === userSession.checkedInChannelId))
				);
				if (result) {
					setSelectedDepartment(result);
					return;
				}
			}
			setSelectedDepartment(departmentList.length > 0 ? departmentList[0] : null);
			setDepartmentId(departmentList[0]?.id);
		};
		if (userSession.healthSystem.id) {
			fetchDepartments();
		}
	}, [userSession]);

	useOutsideClick(wrapperRef, () => {
		if (isDropDownOpen) {
			setIsDropDownOpen(false);
		}
	});

	useOutsideClick(floorWrapperRef, () => {
		if (isFloorsDropdownOpen) {
			setIsFloorsDropdownOpen(false);
		}
	});

	useOutsideClick(roomWrapperRef, () => {
		if (isRoomsDropdownOpen) {
			setIsRoomsDropdownOpen(false);
		}
	});

	const hasActiveQueue = async () => {
		const response = await getPatientQueueCount(getUserId());
		if (response.error) {
			setError(response.error.message);
			return true;
		}
		return response.queueSize > 0;
	};

	const checkInOut = async (id, isInBreak) => {
		setError(null);
		const response = await updateSession({
			healthSystemId: userSession.healthSystem.id,
			checkedInChannelId: id,
			departmentId: selectedDepartment.id,
			floorId: selectedFloor.id,
			companyId,
			isInBreak,
		});

		if (response.error) {
			if (response.error.response.data.code === HealthcareErrorCode.PATIENT_IN_QUEUE) {
				setError(translate('patientInQueue'));
			} else if (response.error.response.data.code === HealthcareErrorCode.MEDICAL_VISIT_INITIATED) {
				setError(translate('medicalVisitInitiated'));
			} else if (response.error.response.data.code === HealthcareErrorCode.USER_IS_CHECKED_IN) {
				setUserIsCheckedInError(
					intl.formatMessage(
						{
							id: 'userIsCheckedIn',
						},
						{ value: response.error.response.data.message }
					)
				);
			} else {
				setError(response.error.message);
			}
			return;
		}

		setUserSession({
			...userSession,
			checkedInChannelId: id,
			departmentId: selectedDepartment.id,
			floorId: selectedFloor.id,
			isInBreak: !!isInBreak,
		});

		if (id && userSession.isInBreak) {
			history.push('/waiting-room-inperson');
		}
	};

	const toggleCheckIn = async () => {
		setActiveQueueError('');
		if (!selectedRoom?.helloDeviceId) {
			return;
		}
		if (!userSession.checkedInChannelId) {
			checkInOut(selectedRoom.id, null);
		}
		setHelloDeviceId(selectedRoom.helloDeviceId);
		if (userSession.checkedInChannelId) {
			if (await hasActiveQueue()) {
				setActiveQueueError(intl.formatMessage({ id: 'activeQueueError' }));
				return;
			}
			checkInOut(null, null);
		}
		setUserSession({ ...userSession, checkedInChannelId: null, departmentId: null, floorId: null, isInBreak: false });
	};

	return (
		<MainLayout>
			{!userIsCheckedInError && (
				<div className='clinic-check-in-wrapper position-relative'>
					<div>
						<p>{translate('placement')}</p>
						<div
							className={classNames(
								'clinic-details-wrapper cursor-pointer flex position-relative',
								userSession.checkedInChannelId ? 'disabled' : ''
							)}
							onClick={() => setIsDropDownOpen(prevState => !prevState)}
							ref={wrapperRef}>
							<img src={`${healthCareCdnUrl}room-icon.svg`} alt='ico' />
							<div>
								<p>{translate('mainMedicalFamilyCenter')}</p>
								<p>{selectedDepartment?.name}</p>
							</div>
							<img src={`${healthCareCdnUrl}teams/arrow.svg`} className='arrow-icon' alt='ico' />
							{isDropDownOpen && (
								<div className='rooms-result'>
									{departments.length > 0 &&
										departments.map(item => {
											return (
												<div
													className='flex cursor-pointer header-elements'
													key={item.id}
													onClick={() => setSelectedDepartment(item)}>
													<p className={item.id === selectedDepartment?.id ? 'active' : ''}>{item.name}</p>
												</div>
											);
										})}
									{departments.length === 0 && (
										<div className='flex cursor-pointer header-elements'>
											<p>N/A</p>
										</div>
									)}
								</div>
							)}
						</div>
						<div
							className={classNames(
								'clinic-details-wrapper cursor-pointer flex position-relative',
								userSession.checkedInChannelId ? 'disabled' : ''
							)}
							onClick={() => setIsFloorsDropdownOpen(prevState => !prevState)}
							ref={floorWrapperRef}>
							<img src={`${healthCareCdnUrl}floor-icon.svg`} alt='ico' />
							<div>
								<p>{translate('medicalFamilyCenter')}</p>
								<p>{selectedFloor?.name}</p>
							</div>
							<img src={`${healthCareCdnUrl}teams/arrow.svg`} className='arrow-icon' alt='ico' />
							{isFloorsDropdownOpen && (
								<div className='rooms-result'>
									{floors.length > 0 &&
										floors.map(item => (
											<div className='flex cursor-pointer header-elements' key={item.id} onClick={() => setSelectedFloor(item)}>
												<p className={item.id === selectedFloor?.id ? 'active' : ''}>{item.name}</p>
											</div>
										))}
									{floors.length === 0 && (
										<div className='flex cursor-pointer header-elements'>
											<p>N/A</p>
										</div>
									)}
								</div>
							)}
						</div>
						<div
							className={classNames(
								'clinic-details-wrapper cursor-pointer flex position-relative',
								userSession.checkedInChannelId ? 'disabled' : ''
							)}
							onClick={() => setIsRoomsDropdownOpen(prevState => !prevState)}
							ref={roomWrapperRef}>
							<img src={`${healthCareCdnUrl}digital-clinic.svg`} alt='ico' />
							<div>
								<p>{translate('digitalClinic')}</p>
								<p>{selectedRoom?.name}</p>
							</div>
							<img src={`${healthCareCdnUrl}teams/arrow.svg`} className='arrow-icon' alt='ico' />
							{isRoomsDropdownOpen && (
								<div className='rooms-result'>
									{rooms.map(item => (
										<div className='flex cursor-pointer header-elements' key={item.id} onClick={() => setSelectedRoom(item)}>
											<p className={item.id === selectedRoom?.id ? 'active' : ''}>{item.name}</p>
										</div>
									))}
									{rooms.length === 0 && (
										<div className='flex cursor-pointer header-elements'>
											<p>N/A</p>
										</div>
									)}
								</div>
							)}
						</div>
						<div
							className={classNames(
								'clinic-details-wrapper cursor-pointer flex flex-justify-center',
								userSession.checkedInChannelId ? 'check-out-btn' : 'check-in-btn',
								selectedRoom?.helloDeviceId ? '' : 'disabled-btn'
							)}
							onClick={toggleCheckIn}>
							{userSession.checkedInChannelId ? translate('checkOut') : translate('checkIn')}
						</div>
						{userSession.checkedInChannelId && (
							<div
								className={classNames(
									'clinic-details-wrapper cursor-pointer flex flex-justify-center',
									userSession.isInBreak ? 'resume-working' : 'take-break-btn',
									selectedRoom?.helloDeviceId ? '' : 'disabled-btn'
								)}
								onClick={() => checkInOut(selectedRoom.id, !userSession.isInBreak)}>
								{translate(userSession.isInBreak ? 'resumeWorking' : 'takeBreak')}
							</div>
						)}
						{activeQueueError && (
							<span className='red-error top-15 position-absolute' style={{ left: '50%', transform: 'translate(-50%)' }}>
								{activeQueueError}
							</span>
						)}
					</div>
				</div>
			)}
			{userIsCheckedInError && (
				<div className='empty-state-wrapper'>
					<EmptyState title={translate('noCheckInAllowed')} paragraph={userIsCheckedInError} image='nurse-no-access.svg'>
						<p className='no-padding-top'>
							<span className='--blue-color cursor-pointer' onClick={() => setUserIsCheckedInError('')}>
								{translate('goBack')}
							</span>
						</p>
					</EmptyState>
				</div>
			)}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</MainLayout>
	);
};

export default DoctorCheckIn;
