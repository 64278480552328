import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import Dropzone from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';
import { CircularProgressbar } from 'react-circular-progressbar';
import Select from 'react-select';
import DatePicker, { registerLocale } from 'react-datepicker';
import sq from 'date-fns/locale/sq/index.js';
import en from 'date-fns/locale/en-US/index.js';
import translate from 'i18n-translations/translate.jsx';
import { createAppointment, getDoctorAvailability } from 'api/appointments.js';
import Modal from 'components/Common/Modal.jsx';
import Form from 'components/Common/Form.jsx';
import { AppointmentType, UserRoles, CanceledReschedulingType, ConversationType } from 'constants/enums.js';
import { getMyPatients } from 'api/patients.js';
import { getConversationByType } from 'api/messenger.js';
import { getUserRole, getUserId } from 'infrastructure/auth.js';
import { getMyDoctors } from 'api/doctors.js';
import { getPreferredLanguageLocale, getAttachmentIcon } from 'infrastructure/helpers/commonHelpers.js';
import 'react-circular-progressbar/dist/styles.css';
import { uploadAttachment } from 'api/media.js';
import Alert from 'components/Common/Alert.jsx';
import ConfirmModal from 'components/Appointments/ConfirmModal.jsx';

const ScheduleAppointment = props => {
	registerLocale('en-US', en);
	registerLocale('sq', sq);
	const language = useSelector(state => state.language.locale);
	const userRole = getUserRole();

	const userId = getUserId();

	const [availableAppointments, setAvailableAppointments] = useState([]);
	const [members, setMembers] = useState([]);
	const [attachments, setAttachments] = useState([]);
	const [isRequestSuccessful, setIsRequestSuccessful] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [notAvailableAppointment, setNotAvailableAppointment] = useState(null);
	const [invitedMemberUserId, setInvitedMemberUserId] = useState(null);
	const [fileUploadError, setFileUploadError] = useState(null);
	const [isDoctorAvailable, setIsDoctorAvailable] = useState(true);
	const [isDiscardModalOpen, setIsDiscardModalOpen] = useState(false);
	const [selectedAppointmentDate, setSelectedAppointmentDate] = useState(null);
	const [error, setError] = useState(null);
	const [uploadProgress, setUploadProgress] = useState(null);
	const [hasReachedEnd, setHasReachedEnd] = useState(false);
	const [pageIndex, setPageIndex] = useState(0);
	const { selectedNotification, selectedCalendarEventStart, selectedCalendarEventEnd, notificationActionType } = props;
	const intl = useIntl();

	const modalRef = useRef(null);

	const setReschedulingAvailableAppointments = useCallback(async () => {
		if (notificationActionType === CanceledReschedulingType.RESCHEDULE) {
			let memberId = '';
			let appointmentDate = moment().format('YYYY-MM-DD');
			let invitedId = '';
			if (selectedNotification) {
				appointmentDate = moment(selectedNotification.payload.Date).format('YYYY-MM-DD');
				invitedId =
					userId === selectedNotification.payload.CreatedBy.Id
						? selectedNotification.payload.AppointmentInvitation.InvitedUser.Id
						: selectedNotification.payload.CreatedBy.Id;
			}
			if (UserRoles.DOCTOR === userRole) {
				memberId = userId;
			} else {
				memberId = invitedId;
			}
			const params = {
				userId: memberId,
				startDate: moment(selectedCalendarEventStart || appointmentDate).format('YYYY-MM-DD'),
				endDate: moment(selectedCalendarEventEnd || appointmentDate).format('YYYY-MM-DD'),
			};
			const returnedAppointments = await getDoctorAvailability(params);
			setAvailableAppointments(returnedAppointments.availableAppointments);
		}
	}, [notificationActionType, selectedNotification, userRole, selectedCalendarEventStart, selectedCalendarEventEnd, userId]);

	useEffect(() => {
		const fetchMyDoctors = async () => {
			const response = await getMyDoctors({ pageIndex });
			if (response.error) {
				setError(`${intl.formatMessage({ id: 'anErrorOccurred' })}: ${response.error.message}`);
				setIsLoading(false);
				return;
			}
			const returnedMembers = response.map(doctor => ({
				email: doctor.email,
				invitedMemberUserId: doctor.userId,
				value: doctor.id,
				label: `${doctor.firstName} ${doctor.lastName}`,
				specialty: doctor.specialty.name,
				profilePicture: doctor.profilePicture,
			}));
			setMembers(prevState => [...prevState, ...returnedMembers]);
			setHasReachedEnd(response.length < 10);
			setIsLoading(false);
		};

		const fetchMyPatients = async () => {
			const response = await getMyPatients({ pageIndex });
			if (response.error) {
				setError(`${intl.formatMessage({ id: 'anErrorOccurred' })}: ${response.error.message}`);
				setIsLoading(false);
				return;
			}
			const returnedMembers = response.map(patient => ({
				email: patient.email,
				invitedMemberUserId: patient.userId,
				value: patient.id,
				label: `${patient.firstName} ${patient.lastName}`,
				profilePicture: patient.profilePicture,
			}));
			setMembers(prevState => [...prevState, ...returnedMembers]);
			setHasReachedEnd(response.length < 10);
			setIsLoading(false);
		};

		const getInitialState = async () => {
			if ([UserRoles.DOCTOR, UserRoles.NURSE].includes(userRole)) {
				fetchMyPatients();
			} else if (userRole === UserRoles.PATIENT) {
				fetchMyDoctors();
			}

			if (selectedNotification) {
				setReschedulingAvailableAppointments();

				if (
					selectedNotification.payload.AppointmentAttachments &&
					selectedNotification.payload.AppointmentAttachments.length > 0
				) {
					const returnedAtattachments = selectedNotification.payload.AppointmentAttachments.map(element => ({
						fileName: element.FileName,
						fileTypeId: element.FileTypeId,
						originalFileName: element.OriginalFileName,
						isFromNotifications: true,
					}));
					setAttachments(returnedAtattachments);
				}
			}
		};
		getInitialState();
	}, [selectedNotification, setReschedulingAvailableAppointments, intl, userRole, pageIndex]);

	const transformToMembers = array =>
		array.map(item => ({ value: item.value, label: item.label, userId: item.invitedMemberUserId }));

	const getValidationSchema = () => {
		return Yup.object().shape({
			topic: Yup.string()
				.required(
					intl.formatMessage({
						id: 'titleIsRequired',
					})
				)
				.max(255, `${intl.formatMessage({ id: 'maxLengthIs' })} 255`)
				.trim(),
			invitedMemberId: Yup.object()
				.required(
					intl.formatMessage({
						id: 'personIsRequired',
					})
				)
				.nullable(),
			appointmentSlotId: Yup.string().required(
				intl.formatMessage({
					id: 'appointmentSlotIsRequired',
				})
			),
			description: Yup.string().required(
				intl.formatMessage({
					id: 'descriptionIsRequired',
				})
			),
		});
	};

	const createAppointmentHandler = async (values, { setSubmitting }) => {
		setSubmitting(true);
		const newAppointmentData = { ...values };
		let invitedId = invitedMemberUserId;
		if (values.invitedMemberId && !invitedMemberUserId) {
			invitedId = members.find(x => x.value === values.invitedMemberId?.value)?.invitedMemberUserId;
		}
		const conversationResponse = await getConversationByType(ConversationType.INDIVIDUAL, invitedId, 0);
		if (conversationResponse.error) {
			setError(`${intl.formatMessage({ id: 'anErrorOccurred' })}: ${conversationResponse.error.message}`);
			return;
		}

		const appointmentInvitation = {
			invitedUserId: newAppointmentData.invitedMemberId.value,
			conversationId: conversationResponse.conversation.id,
		};

		const attachmentsToSend = attachments.map(attachment => ({
			fileName: attachment.fileName || attachment.name,
			originalFileName: attachment.originalFileName || attachment.fileName,
			fileTypeId: attachment.fileTypeId || 0,
		}));

		const appointmentDateTime = selectedCalendarEventStart || values.appointmentDate;
		const slotStartDateTime = availableAppointments.find(x => x.id === newAppointmentData.appointmentSlotId).startDateTime;
		const slotStartTimeLocal = moment.utc(slotStartDateTime).local().locale(getPreferredLanguageLocale()).format('HH:mm:ss');
		const appointmentDate = moment(appointmentDateTime).format('YYYY-MM-DD');
		const appointmentDateParam = moment(`${appointmentDate} ${slotStartTimeLocal}`)
			.utc()
			.local()
			.locale(getPreferredLanguageLocale())
			.format('YYYY-MM-DD');

		const appointment = {
			title: newAppointmentData.topic,
			description: newAppointmentData.description,
			date: appointmentDateParam,
			appointmentSlotId: newAppointmentData.appointmentSlotId,
			typeId: AppointmentType.STANDARD,
			appointmentInvitation: appointmentInvitation,
			appointmentAttachments: attachmentsToSend,
		};

		const response = await createAppointment(appointment);
		if (response.error || !response.hasSucceeded) {
			setError(intl.formatMessage({ id: 'anErrorOccurred' }));
			return;
		}

		if (response.hasSucceeded) {
			setIsRequestSuccessful(true);
			setSelectedAppointmentDate(appointment.date);
			setSubmitting(false);
			props.onSave();
		}
	};

	const onNewMemberInvited = async (invitedMemberId, values, setFieldValue) => {
		try {
			getAvailableAppointments(invitedMemberId, values, setFieldValue);
		} catch (ex) {
			setError(`${intl.formatMessage({ id: 'anErrorOccurred' })}: ${ex.message}`);
		}
	};

	const getAvailableAppointments = async (invitedMemberId, values, setFieldValue) => {
		let memberId = null;
		let notAvailable = null;
		if (UserRoles.DOCTOR === userRole) {
			memberId = userId;
			notAvailable = translate('setUpAvailabilityHours');
		} else {
			memberId = invitedMemberId?.value;
			notAvailable = translate('notAvailableNow');
		}
		const startDate = selectedCalendarEventStart || values.appointmentDate;
		const endDate = selectedCalendarEventEnd || values.appointmentDate;
		const params = {
			userId: memberId,
			startDate: moment(startDate).format('YYYY-MM-DD'),
			endDate: moment(endDate).format('YYYY-MM-DD'),
		};
		const availableAppointmentResponse = await getDoctorAvailability(params);

		if (!availableAppointmentResponse.availableAppointments.length && userRole === UserRoles.PATIENT) {
			notAvailable = translate('notAvailableToday');
		}

		const sortedAvailableAppointments = availableAppointmentResponse.availableAppointments.sort(
			(a, b) =>
				new Date(`1970/01/01 ${getTimeSpan(a.startDateTime)}`).getTime() -
				new Date(`1970/01/01 ${getTimeSpan(b.startDateTime)}`).getTime()
		);

		const doctorAvailability = sortedAvailableAppointments.some(item =>
			moment(startDate).isBetween(item.startDateTime, item.endDateTime, 'minutes', '[]')
		);
		if (selectedCalendarEventStart && sortedAvailableAppointments.length > 0) {
			const selectedAppointment = sortedAvailableAppointments.find(
				x => moment(x.startDateTime).format('HH:mm:ss') === moment(selectedCalendarEventStart).format('HH:mm:ss')
			);
			if (selectedAppointment) {
				setFieldValue('appointmentSlotId', selectedAppointment.id);
			} else {
				setNotAvailableAppointment('Please check your available hours');
			}
		}

		setInvitedMemberUserId(members.find(x => x.value === invitedMemberId?.value)?.invitedMemberUserId);
		setAvailableAppointments(sortedAvailableAppointments);
		setNotAvailableAppointment(doctorAvailability ? null : notAvailable);
		setIsDoctorAvailable(doctorAvailability);
		setFieldValue('invitedMemberId', invitedMemberId);
	};

	const getTimeSpan = dateTime => moment.utc(dateTime).local().locale(getPreferredLanguageLocale()).format('HH:mm:ss');

	const onAppointmentDateChange = async (date, values, setFieldValue) => {
		let memberId = null;
		let notAvailable = null;
		if (UserRoles.DOCTOR === userRole) {
			memberId = userId;
			notAvailable = translate('setUpAvailabilityHours');
		} else if (userRole === UserRoles.PATIENT) {
			memberId = values.invitedMemberId?.value;
			notAvailable = translate('notAvailableNow');
		}
		const params = {
			userId: memberId,
			startDate: moment(date).format('YYYY-MM-DD'),
			endDate: moment(date).format('YYYY-MM-DD'),
		};
		if (memberId) {
			const availableAppointmentResponse = await getDoctorAvailability(params);
			if (!availableAppointmentResponse.availableAppointments.length && userRole === UserRoles.PATIENT) {
				notAvailable = translate('notAvailableToday');
			}

			const sortedAvailableAppointments = availableAppointmentResponse.availableAppointments.sort(
				(a, b) =>
					new Date(`1970/01/01 ${getTimeSpan(a.startDateTime)}`).getTime() -
					new Date(`1970/01/01 ${getTimeSpan(b.startDateTime)}`).getTime()
			);

			setAvailableAppointments(sortedAvailableAppointments);
			setNotAvailableAppointment(sortedAvailableAppointments.length > 0 ? null : notAvailable);
			setFieldValue('appointmentDate', date);
		}
	};

	const uploadAttachmentsToCdnStorage = async selectedAttachment => {
		const formData = new FormData();
		formData.append('File', selectedAttachment);
		const uploadResponse = await uploadAttachment(formData);
		if (uploadResponse.error) {
			setError(uploadResponse.error.message);
			return {};
		}
		return {
			fileName: uploadResponse.fileName,
			originalFileName: uploadResponse.originalFileName,
			fileTypeId: uploadResponse.fileType,
		};
	};

	const uploadedFileHandler = async acceptedAttachments => {
		setError(null);
		const methodAttachments = [...attachments];
		setFileUploadError(null);

		if (methodAttachments.length >= 5) {
			setError(intl.formatMessage({ id: 'fiveAttachmentsMaximumUpload' }));
			return;
		}
		const newAcceptedAttachments = [...acceptedAttachments];
		newAcceptedAttachments.forEach(attachment => {
			if (attachment.size / 1024 / 1024 > 5) {
				setError(intl.formatMessage({ id: 'fileUploadMaximumSize' }));
				return;
			}
			const reader = new FileReader();
			reader.onabort = () => setError(intl.formatMessage({ id: 'fileReadingWasAborted' }));
			reader.onerror = () => setError(intl.formatMessage({ id: 'fileReadingHasFailed' }));

			reader.readAsDataURL(attachment);
			reader.onprogress = event => {
				if (event.lengthComputable) {
					const progress = (event.loaded / event.total) * 100;
					setUploadProgress(Math.trunc(progress));
				}
			};

			reader.onload = async () => {
				const requestAttachment = await uploadAttachmentsToCdnStorage(attachment);
				if (Object.keys(requestAttachment).length > 0) {
					const attachmentIndex = attachments.findIndex(
						selectedAttachment =>
							[selectedAttachment.originalFileName, selectedAttachment.fileName].includes(attachment.name) &&
							selectedAttachment.size === attachment.size
					);
					// eslint-disable-next-line no-param-reassign
					attachment.id = uuidv4();
					if (attachmentIndex === -1) {
						const newAttachment = {
							content: reader.result,
							isUploadCompleted: true,
							fileName: requestAttachment.fileName,
							originalFileName: requestAttachment.originalFileName,
							id: attachment.id,
							accepted: true,
							size: attachment.size,
							type: attachment.type,
							fileTypeId: requestAttachment.fileTypeId,
						};
						setAttachments(prevState => [...prevState, newAttachment]);
					} else {
						setFileUploadError(
							translate('fileUploadError', {
								value: attachment.name,
							})
						);
					}
				}
			};
		});
	};

	const onFileDeleteHandler = id => {
		const prevAttachments = [...attachments];
		const methodAttachments = prevAttachments.filter(attachment => attachment.id !== id);
		setAttachments(methodAttachments);
	};

	const renameFileName = async (file, newName) => {
		setAttachments(prevState => {
			const prevAttachments = [...prevState];
			const newAttachmentIndex = prevAttachments.findIndex(attachment => attachment.id === file.id);
			const name = newName.target.value;
			prevAttachments[newAttachmentIndex].fileName = file.fileName;
			prevAttachments[newAttachmentIndex].originalFileName = name;
			prevAttachments[newAttachmentIndex].fileTypeId = file.fileTypeId;
			return {
				attachments: prevAttachments,
			};
		});
	};

	const setDiscardModalOpen = discardModalOpen => {
		setIsDiscardModalOpen(discardModalOpen);
	};

	const closeAppointmentModal = touched => {
		if (touched || attachments.length) {
			setDiscardModalOpen(true);
		} else {
			props.toggleCreateAppointmentModal();
		}
	};

	const getInitialValues = () => {
		let invitedMemberId = null;
		let appointmentDate = moment().format('YYYY-MM-DD');

		if (selectedNotification) {
			const invitedMemberLabel =
				userId === selectedNotification.payload.CreatedBy.Id
					? `${selectedNotification.payload.AppointmentInvitation.InvitedUser.FirstName} ${selectedNotification.payload.AppointmentInvitation.InvitedUser.LastName}`
					: `${selectedNotification.payload.CreatedBy.FirstName} ${selectedNotification.payload.CreatedBy.LastName}`;
			const invitedUserId =
				userId === selectedNotification.payload.CreatedBy.Id
					? selectedNotification.payload.AppointmentInvitation.InvitedUser.Id
					: selectedNotification.payload.CreatedBy.Id;

			if (notificationActionType === CanceledReschedulingType.RESCHEDULE) {
				invitedMemberId = { label: invitedMemberLabel, value: invitedUserId };
			}
			appointmentDate = moment(selectedNotification.payload.Date).format('YYYY-MM-DD');
		}

		return {
			topic: selectedNotification ? selectedNotification.payload.Title : '',
			appointmentSlotId: '',
			description: selectedNotification ? selectedNotification.payload.Description : '',
			invitedMemberId: invitedMemberId,
			appointmentDate: appointmentDate,
		};
	};

	const handleScroll = async event => {
		const isBottom = event.target.scrollHeight - Math.ceil(event.target.scrollTop) === event.target.clientHeight;
		if (isBottom && !hasReachedEnd) {
			setPageIndex(prevState => prevState + 1);
		}
	};

	return (
		<>
			<Formik
				enableReinitialize={true}
				validationSchema={getValidationSchema()}
				initialValues={getInitialValues()}
				onSubmit={createAppointmentHandler}>
				{formikProps => {
					const {
						values,
						errors,
						touched,
						handleSubmit,
						handleChange,
						handleBlur,
						isSubmitting,
						setFieldTouched,
						setFieldValue,
					} = formikProps;
					const isTouched =
						touched.topic ||
						touched.invitedMemberId ||
						touched.appointmentDate ||
						touched.appointmentSlotId ||
						touched.description;

					if (errors) {
						if (modalRef.current && modalRef.current.querySelector('.red-error')?.textContent?.length > 0) {
							modalRef.current.querySelector('.red-error').scrollIntoView({ block: 'center' });
						}
					}

					return (
						<>
							<Modal
								modalSelector='createAppointmentModal'
								display={props.isCreateAppointmentOpen}
								onModalSubmit={handleSubmit}
								primaryButtonLabel={intl.formatMessage({ id: 'createAppointment' })}
								position='center'
								isLoading={isLoading}
								primaryButtonLoading={isSubmitting}
								onModalClose={() => closeAppointmentModal(isTouched)}
								shouldSubmitOnEnter={false}
								className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal header-w-border'>
								<Form height={500} className='modal-form-wrapper'>
									<h3>{translate('createAppointment')}</h3>
									<div className='create-appointment-modal' ref={modalRef}>
										<div>
											<label htmlFor='topic'>*{translate('topic')}</label>
											<input
												type='text'
												name='topic'
												value={values.topic}
												onChange={handleChange}
												onBlur={handleBlur}
												placeholder={intl.formatMessage({ id: 'enterTopic' })}
											/>
											{errors.topic && touched.topic && <span className='red-error create-app-error'>{errors.topic}</span>}
										</div>
										<div onScroll={handleScroll}>
											<label htmlFor='invitedMemberId'>*{translate('inviteMember')}</label>
											<Select
												options={transformToMembers(members)}
												value={values.invitedMemberId}
												onChange={event => onNewMemberInvited(event, values, setFieldValue)}
												placeholder={intl.formatMessage({ id: 'selectMemberToInvite' })}
												classNamePrefix='react-select'
												className='full-width'
												isSearchable
												name='invitedMemberId'
												onBlur={() => setFieldTouched('invitedMemberId', true)}
											/>
											{errors.invitedMemberId && touched.invitedMemberId && (
												<span className='red-error'>{errors.invitedMemberId}</span>
											)}
										</div>
										{!selectedCalendarEventStart && (
											<div className='appointment-date-wrapper'>
												<label htmlFor='date'>*{translate('appointmentDate')}</label>
												<DatePicker
													className='appointment-topic'
													selected={moment(values.appointmentDate).toDate()}
													onChange={event => onAppointmentDateChange(event, values, setFieldValue)}
													onBlur={handleBlur}
													minDate={new Date()}
													dateFormat='MM/dd/yyyy'
													onKeyDown={event => event.preventDefault()}
													locale={language}
												/>
												{errors.appointmentDate && touched.appointmentDate && (
													<span className='red-error'>{errors.appointmentDate}</span>
												)}
											</div>
										)}
										{selectedCalendarEventStart && (
											<div className='flex available-appointments calendar-events-app-modal'>
												{isDoctorAvailable && (
													<p>
														{`${moment(selectedCalendarEventStart)
															.local()
															.locale(getPreferredLanguageLocale())
															.format('DD MMMM YYYY')} `}
														{translate('from')}{' '}
														{`${moment
															.utc(selectedCalendarEventStart)
															.local()
															.locale(getPreferredLanguageLocale())
															.format('hh:mm A')} `}
														{translate('to')}{' '}
														{`${moment
															.utc(selectedCalendarEventEnd)
															.local()
															.locale(getPreferredLanguageLocale())
															.format('hh:mm A')}`}
													</p>
												)}
												{!isDoctorAvailable && <span className='red-error create-app-error'>{notAvailableAppointment}</span>}
											</div>
										)}
										<div>
											<div className='flex available-appointments calendar-events-app-modal'>
												{!selectedCalendarEventStart && availableAppointments.length === 0 && (
													<span className='red-error create-app-error'>{notAvailableAppointment}</span>
												)}
												{(!selectedCalendarEventStart || notAvailableAppointment) &&
													availableAppointments.map(appointmentSlot => (
														<div key={appointmentSlot.id} className='appointments-list'>
															<label>
																<Field
																	type='radio'
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={appointmentSlot.id}
																	name='appointmentSlotId'
																/>

																<div className='active-av-app' />
																<span>
																	{`${moment
																		.utc(appointmentSlot.startDateTime)
																		.local()
																		.locale(getPreferredLanguageLocale())
																		.format('hh:mm A')} -
																  ${moment.utc(appointmentSlot.endDateTime).local().locale(getPreferredLanguageLocale()).format('hh:mm A')}`}
																</span>
															</label>
														</div>
													))}
												{errors.appointmentSlotId && touched.appointmentSlotId && isDoctorAvailable && (
													<span className='red-error create-app-error'>{errors.appointmentSlotId}</span>
												)}
											</div>
										</div>
										<div>
											<label htmlFor='description'>*{translate('description')}</label>
											<textarea
												maxLength={200}
												value={values.description}
												onChange={handleChange}
												onBlur={handleBlur}
												rows={5}
												name='description'
												placeholder={intl.formatMessage({ id: 'enterDescription' })}
											/>
											{errors.description && touched.description && (
												<span className='red-error create-app-error'>{errors.description}</span>
											)}
										</div>
										<div>
											<Dropzone
												accept='image/jpg, image/jpeg, image/png, text/plain, .pdf, .doc, .docx, .xls, .xlsx'
												onDrop={uploadedFileHandler}>
												{({ getRootProps, getInputProps }) => (
													<>
														<span>{translate('attachments')}</span>
														{attachments.length > 0 && (
															<div className='drag-drop-img-gallery flex'>
																{attachments.map(attachment => (
																	<div className='doctor-request-photo-upload' key={attachment.id}>
																		<i
																			className='material-icons cursor-pointer'
																			onClick={() => onFileDeleteHandler(attachment.id)}>
																			close
																		</i>
																		<a
																			href={attachment.content}
																			rel='noopener noreferrer'
																			target='_blank'
																			download
																			key={attachment.id}>
																			<img src={getAttachmentIcon(attachment.fileName)} alt='file-icon' />
																		</a>
																		<input
																			type='text'
																			name='originalFileName'
																			value={attachment.originalFileName}
																			onChange={event => renameFileName(attachment, event)}
																			onBlur={handleBlur}
																		/>
																		{uploadProgress !== 100 &&
																			uploadProgress !== 0 &&
																			!attachment.isUploadCompleted &&
																			!attachment.isFromNotifications && (
																				<div>
																					<CircularProgressbar value={uploadProgress} text={`${uploadProgress}%`} />
																				</div>
																			)}
																	</div>
																))}
																<p>{fileUploadError}</p>
															</div>
														)}
														<div
															className='drag-drop-user-img available-doctors-drag-drop cursor-pointer appointment-attachment'
															{...getRootProps()}>
															<input {...getInputProps()} />
															<p>{translate('dragAndDropOrSelectAttachments')}</p>
														</div>
													</>
												)}
											</Dropzone>
										</div>
									</div>
								</Form>
							</Modal>
							<Modal
								modalSelector='discardAppointmentModal'
								display={isDiscardModalOpen}
								onModalSubmit={() => props.toggleCreateAppointmentModal()}
								primaryButtonLabel={intl.formatMessage({ id: 'discard' })}
								position='center'
								isLoading={isLoading}
								onModalClose={() => setDiscardModalOpen(false)}
								shouldSubmitOnEnter={false}
								className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal discard'>
								<Form height={200} className='modal-form-wrapper'>
									<h3>{translate('discardChanges')}</h3>
									<p>{translate('discardDescription')}</p>
								</Form>
							</Modal>
							<ConfirmModal
								display={isRequestSuccessful}
								onModalSubmit={() => props.toggleCreateAppointmentModal()}
								onModalClose={() => props.toggleCreateAppointmentModal()}
								isLoading={isLoading}
								userRole={userRole}
								selectedAppointmentDate={selectedAppointmentDate}
								selectedMember={members.find(member => member.value === values.invitedMemberId?.value)}
								appointmentTime={availableAppointments.find(appointmentTime => appointmentTime.id === values.appointmentSlotId)}
								isFromAdd={true}
							/>
						</>
					);
				}}
			</Formik>
			<Alert display={error} fixed hideCloseButton message={error} variant='dark' />
		</>
	);
};

export default ScheduleAppointment;
