import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import classNames from 'classnames/bind.js';
import { actionCreators as healthSystemActionCreators } from 'state/healthSystems/actions.js';
import { actionCreators as deviceActionCreators } from 'state/devices/actions.js';
import { SectorTypes, TreeHierarchyType, UserRoles } from 'constants/enums.js';
import { buildTree, getHealthSystemDevices, searchSectors, showPath } from 'infrastructure/helpers/commonHelpers.js';
import Modal from 'components/Common/Modal.jsx';
import Form from 'components/Common/Form.jsx';
import Select from 'components/Common/Select.jsx';
import { getCompanyId, getUserRole } from 'infrastructure/auth.js';
import translate from 'i18n-translations/translate.jsx';
import Input from 'components/Common/Input.jsx';
import Alert from 'components/Common/Alert.jsx';
import { getRegionSubTree } from 'api/tree.js';
import { createFrontlineHospital, createHospital } from 'api/hospitals.js';
import { createDepartment } from 'api/departments.js';
import { createFloor, createFloorInHospital } from 'api/floors.js';
import { createRoom, createRoomInDepartment } from 'api/rooms.js';
import ListView from 'icons/TreeView/ListView.jsx';
import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';
import TreeViewIcon from 'icons/TreeView/TreeViewIcon.jsx';
import HealthSystemActions from 'components/Common/HealthSystemActions.jsx';
import TreeViewMayo from 'components/Common/TreeViewMayo.jsx';

class SectorList extends React.Component {
	state = {
		healthSystems: [],
		currentRegions: [],
		isSwitchHealthSystemModalOpen: false,
		isSwitchHealthSystemModalLoading: true,
		sectorSearchValue: '',
		expandAllTreeItems: true,
		isLoadingView: false,
		error: null,
	};

	companyId = getCompanyId();

	treeHierarchyTypeId = this.props.healthSystems.allHealthSystems.find(item => item.id === this.props.userSession.healthSystem.id)
		?.treeHierarchyTypeId;

	componentDidMount = () => {
		if (window.location.pathname === '/health-system' && [UserRoles.ADMIN, UserRoles.SUPER_USER].includes(getUserRole())) {
			this.props.history.push(`/health-system/${this.props.currentHospitalId}`);
		}
	};

	hasDefaultHierarchy = () =>
		[TreeHierarchyType.DEFAULT_TREE, TreeHierarchyType.HOSPITAL_DEPT_FLOOR_ROOM].includes(this.treeHierarchyTypeId);

	onNewSector = async selection => {
		const data = { companyId: this.companyId, healthSystemId: this.state.currentHealthSystemId, name: selection.name };
		if (selection.type === SectorTypes.HOSPITAL && this.hasDefaultHierarchy()) {
			const response = await createFrontlineHospital({ ...data, regionId: this.props.userSession.regionId });
			if (response.error) {
				this.setState({ error: response.error.message });
				return;
			}
		} else if (selection.type === SectorTypes.HOSPITAL && !this.hasDefaultHierarchy()) {
			const response = await createHospital({
				...data,
				regionId: this.props.userSession.regionId,
				hasDefaultDepartment: this.treeHierarchyTypeId === TreeHierarchyType.HOSPITAL_ROOM,
			});
			if (response.error) {
				this.setState({ error: response.error.message });
				return;
			}
		} else if (selection.type === SectorTypes.DEPARTMENT) {
			const response = await createDepartment({ ...data, hospitalId: selection.hospitalId });
			if (response.error) {
				this.setState({ error: response.error.message });
				return;
			}
		} else if (selection.type === SectorTypes.FLOOR && this.hasDefaultHierarchy()) {
			const response = await createFloor({
				...data,
				hospitalId: selection.hospitalId,
				departmentId: selection.departmentId,
			});
			if (response.error) {
				this.setState({ error: response.error.message });
				return;
			}
		} else if (selection.type === SectorTypes.FLOOR && this.treeHierarchyTypeId === TreeHierarchyType.HOSPITAL_FLOOR_ROOM) {
			const response = await createFloorInHospital({
				...data,
				hospitalId: selection.hospitalId,
				name: selection.name,
			});
			if (response.error) {
				this.setState({ error: response.error.message });
				return;
			}
		} else if (
			selection.type === SectorTypes.ROOM &&
			(this.hasDefaultHierarchy() || this.treeHierarchyTypeId === TreeHierarchyType.HOSPITAL_FLOOR_ROOM)
		) {
			const response = await createRoom({
				...data,
				hospitalId: selection.hospitalId,
				departmentId: selection.departmentId,
				floorId: selection.floorId,
			});
			if (response.error) {
				this.setState({ error: response.error.message });
				return;
			}
		} else if (
			selection.type === SectorTypes.ROOM &&
			[TreeHierarchyType.HOSPITAL_DEPT_ROOM, TreeHierarchyType.HOSPITAL_ROOM].includes(this.treeHierarchyTypeId)
		) {
			const response = await createRoomInDepartment({
				...data,
				hospitalId: selection.hospitalId,
				departmentId: selection.departmentId || selection.defaultDepartmentId,
				name: selection.name,
			});
			if (response.error) {
				this.setState({ error: response.error.message });
				return;
			}
		}

		this.fetchTreeData();
	};

	createNewHospital = () => {
		this.props.healthSystemActions.createNewHospital();
	};

	transformRegionsArr = regions => {
		const currentRegions = [];
		let currentRegionIndex = 0;
		regions.forEach((region, regionIndex) => {
			if (region.id === this.state.currentRegionId) {
				currentRegionIndex = regionIndex;
			}
			currentRegions.push({
				value: region.name,
				id: region.id,
			});
		});
		return {
			currentRegions,
			currentRegionIndex,
		};
	};

	transformHealthSystemArr = healthSystems => {
		const allHealthSystems = [];
		let currentHealthSystemIndex = 0;
		healthSystems.forEach((healthSystem, healthSystemIndex) => {
			if (healthSystem.id === this.state.currentHealthSystemId) {
				currentHealthSystemIndex = healthSystemIndex;
			}
			allHealthSystems.push({
				value: healthSystem.name,
				name: healthSystem.name,
				id: healthSystem.id,
				firstRegion: healthSystem.regions[0],
				regions: healthSystem.regions,
			});
		});
		return {
			allHealthSystems,
			currentHealthSystemIndex,
		};
	};

	toggleSwitchHealthSystemModal = async () => {
		this.setState(
			prevState => ({
				isSwitchHealthSystemModalOpen: !prevState.isSwitchHealthSystemModalOpen,
			}),
			async () => {
				if (this.state.isSwitchHealthSystemModalOpen) {
					const healthSystems = this.transformHealthSystemArr(this.props.healthSystems.allHealthSystems);
					const regions = this.transformRegionsArr(
						this.props.healthSystems.allHealthSystems[healthSystems.currentHealthSystemIndex].regions
					);
					this.setState({
						healthSystems: healthSystems.allHealthSystems,
						currentHealthSystem: healthSystems.allHealthSystems[healthSystems.currentHealthSystemIndex],
						currentHealthSystemIndex: healthSystems.currentHealthSystemIndex,
						currentRegionId:
							healthSystems.allHealthSystems[healthSystems.currentHealthSystemIndex].regions[regions.currentRegionIndex].id,
						currentRegions: regions.currentRegions,
						currentRegionIndex: regions.currentRegionIndex,
						isSwitchHealthSystemModalLoading: false,
					});
				}
			}
		);
	};

	changeHealthSystem = e => {
		const healthSystem = this.state.healthSystems[e.target.selectedIndex - 1];
		const regions = this.transformRegionsArr(healthSystem.regions);
		this.setState(prevState => ({
			currentRegions: regions.currentRegions,
			currentRegionId: prevState.healthSystems[e.target.selectedIndex - 1].firstRegion.id,
			currentHealthSystemId: prevState.healthSystems[e.target.selectedIndex - 1].id,
			currentHealthSystem: prevState.healthSystems[e.target.selectedIndex - 1],
			currentRegionIndex: 0,
		}));
	};

	changeRegion = e => {
		this.setState(prevState => ({
			currentRegionId: prevState.currentRegions[e.target.selectedIndex - 1].id,
		}));
	};

	setCurrentHealthSystemInfo = () => {
		this.setState({
			isSwitchHealthSystemModalLoading: true,
			expandAllTreeItems: true,
		});
		this.fetchTreeData();
		this.toggleSwitchHealthSystemModal();
		this.setState(prevState => ({
			currentHealthSystemId: prevState.currentHealthSystem.id,
			isSwitchHealthSystemModalLoading: false,
		}));
		this.props.healthSystemActions.updateBreadcrumb([]);
	};

	handleHealthSystemOptions = (event, item) => {
		if (item.id === 'switch_hs') {
			this.toggleSwitchHealthSystemModal();
		}
	};

	showSearch = () => {
		return (
			Object.keys(this.props.healthSystems.treeData.tree[0]).length > 0 && this.props.healthSystems.treeData.tree[0].isRoomsOnly
		);
	};

	componentWillUnmount() {
		searchSectors(this.props.healthSystems.treeData.tree, '');
		this.setState({
			expandAllTreeItems: true,
		});
	}

	setSearchValue = event => {
		const { target } = event;
		this.setState(
			{
				[target.name]: target.value,
			},
			() => {
				const sectors = searchSectors(this.props.healthSystems.treeData.tree, target.value);
				this.setState({
					expandAllTreeItems: true,
				});
				if (target.value) {
					showPath(this.props.healthSystems.treeData.tree, sectors);
				}
			}
		);
	};

	toggleTreeView = isRoomsOnlyView => {
		if (isRoomsOnlyView === this.props.healthSystems.isRoomsOnlyView || this.state.isLoadingView) {
			return;
		}
		this.setState({ sectorSearchValue: '', expandAllTreeItems: true, isLoadingView: true }, () => {
			this.props.healthSystemActions.toggleIsRoomsView();
			const treeData = buildTree(this.props.healthSystems.selectedHealthSystem, isRoomsOnlyView);
			this.props.healthSystemActions.setTreeData(treeData);
			this.setState({ isLoadingView: false });
		});
	};

	fetchTreeData = async () => {
		const subTreeResponse = await getRegionSubTree(this.props.userSession.healthSystem.id, this.props.userSession.regionId);
		if (subTreeResponse.error) {
			this.setState({ error: subTreeResponse.error.message });
		} else {
			const { healthSystem } = subTreeResponse.organization;
			const treeData = buildTree(healthSystem);
			const { online, busy, privacy, pairedRemote } = getHealthSystemDevices(healthSystem);
			this.props.deviceActionCreators.setBulkDevicesBusy(busy);
			this.props.deviceActionCreators.setBulkDevicesOnline(online);
			this.props.deviceActionCreators.setBulkDevicesPrivacy(privacy);
			this.props.deviceActionCreators.setBulkPairedRemoteDevice(pairedRemote);
			this.props.healthSystemActions.setHealthSystem(healthSystem);
			this.props.healthSystemActions.setTreeData(treeData);
		}
	};

	getHealthSystemName = () => {
		const foundHealthSystem = this.props.healthSystems.allHealthSystems.find(
			hs => hs.id === this.props.userSession.healthSystem.id
		);
		return foundHealthSystem ? foundHealthSystem.name : '';
	};

	render() {
		return (
			<>
				<div className={classNames('margin-top-m flex', this.props.isSavedSessionsHidden ? 'no-sessions-subpage' : '')}>
					<div className='switch-hs-wrapper flex-1'>
						<HealthSystemActions />
					</div>
					<div className='flex'>
						{this.props.healthSystems.isRoomsOnlyView && (
							<div
								onClick={() => this.toggleTreeView(false)}
								data-tooltip={this.props.intl.formatMessage({ id: 'treeView' })}
								data-position='bottom'
								className='cursor-pointer right-before transform-x-0-before'>
								<TreeViewIcon color={this.props.darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />
							</div>
						)}
						{!this.props.healthSystems.isRoomsOnlyView && (
							<div
								onClick={() => this.toggleTreeView(true)}
								data-tooltip={this.props.intl.formatMessage({ id: 'listView' })}
								data-position='bottom'
								className='cursor-pointer right-before transform-x-0-before'>
								<ListView color={this.props.darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />
							</div>
						)}
					</div>
				</div>
				<Input
					className='tree-search'
					type='text'
					name='sectorSearchValue'
					placeholder={this.props.intl.formatMessage({ id: 'search' })}
					value={this.state.sectorSearchValue}
					onChange={this.setSearchValue}
					validationOptions={{}}
					bottomSpace='15px'
					autoComplete='off'
				/>
				{this.props.healthSystems.treeData?.tree.length > 0 && this.props.healthSystems.treeData?.tree[0]?.type && (
					<TreeViewMayo
						onAddDevice={this.props.onAddDevice}
						onRemoveFeed={this.props.onRemoveFeed}
						data={this.props.healthSystems.treeData.tree}
						preSelected={this.props.healthSystems.treeData.preSelected}
						onAdd={this.onNewSector}
						onLinkClick={this.props.onTreeViewLinkClick}
						isMonitoring={this.props.isMonitoring}
						isAlertCenter={this.props.isAlertCenter}
						expandAll={this.state.expandAllTreeItems}
						monitoredDevices={this.props.healthSystems.monitoredDevices}
						alertFeeds={this.props.isAlertCenter ? this.props.alertFeeds : []}
						videoFeeds={this.props.isMonitoring ? this.props.videoFeeds : []}
					/>
				)}

				<Modal
					display={this.state.isSwitchHealthSystemModalOpen}
					isLoading={this.state.isSwitchHealthSystemModalLoading}
					onModalSubmit={this.setCurrentHealthSystemInfo}
					position='right'
					onModalClose={this.toggleSwitchHealthSystemModal}>
					<Form title={translate('switchHSorRegion')}>
						<Select
							type='text'
							label={translate('selectHealthSystem')}
							name='currentHealthSystemId'
							value={this.state.currentHealthSystemIndex}
							onSelect={this.changeHealthSystem}
							items={this.state.healthSystems}
							description={translate('chooseHealthSystemYouWantToManage')}
							placeholder={this.props.intl.formatMessage({ id: 'selectHealthSystem' })}
						/>
						<Select
							type='text'
							label={translate('selectRegion')}
							name='currentRegionId'
							value={this.state.currentRegionIndex}
							onSelect={this.changeRegion}
							items={this.state.currentRegions}
							description={translate('chooseRegionFromHSManageHospitals')}
							placeholder={this.props.intl.formatMessage({ id: 'selectRegion' })}
						/>
					</Form>
				</Modal>
				<Alert display={this.state.error} fixed hideCloseButton message={this.state.error} variant='dark' />
			</>
		);
	}
}

SectorList.defaultProps = {
	isMonitoring: false,
	isAlertCenter: false,
};

const mapDispatchToProps = dispatch => {
	return {
		healthSystemActions: bindActionCreators(healthSystemActionCreators, dispatch),
		devicesActionCreators: bindActionCreators(deviceActionCreators, dispatch),
	};
};

const mapStateToProps = state => {
	return {
		healthSystems: state.healthSystems,
		userSession: state.user.userSession,
		darkMode: state.user.darkMode,
	};
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withRouter(SectorList)));
