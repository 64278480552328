import React, { useState } from 'react';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from 'components/Common/Tabs.jsx';
import { DeviceFamilyUniqueTags, ScreenDisplayOrientation } from 'constants/enums.js';
import { isJSON } from 'infrastructure/helpers/commonHelpers.js';

const healthCareCdnUrlAdmin = `${healthCareCdnUrl}admin/`;
const selectModes = [
	{
		id: 1,
		name: translate('landscape'),
		img: `${healthCareCdnUrlAdmin}hello-care-home.svg`,
		active: false,
		value: ScreenDisplayOrientation.LANDSCAPE,
	},
	{
		id: 2,
		name: translate('portrait'),
		img: `${healthCareCdnUrlAdmin}ai-widget.svg`,
		active: false,
		value: ScreenDisplayOrientation.PORTRAIT,
	},
	{
		id: 3,
		name: translate('portraitReverse'),
		img: `${healthCareCdnUrlAdmin}ai-widget.svg`,
		active: false,
		value: ScreenDisplayOrientation.PORTRAIT_REVERSED,
	},
];

const Displays = {
	MAIN: 'mainDisplay',
	SECONDARY: 'secondDisplay',
};

const PortraitMode = ({ settingTypeId, selectedDevice, setSelectedMode, deviceSettings }) => {
	const selectedMode = deviceSettings?.find(item => item.settingTypeId === settingTypeId);
	let mainDisplay = ScreenDisplayOrientation.LANDSCAPE;
	let secondDisplay = ScreenDisplayOrientation.PORTRAIT;
	if (isJSON(selectedMode?.value)) {
		let parsedData = JSON.parse(selectedMode?.value);
		mainDisplay = parsedData?.mainDisplay;
		secondDisplay = parsedData?.secondDisplay;
	}
	const [displayConfig, setDisplayConfig] = useState({ mainDisplay, secondDisplay });
	const displayList =
		selectedDevice.deviceFamily.uniqueTag === DeviceFamilyUniqueTags.HELLO_3
			? [Displays.MAIN, Displays.SECONDARY]
			: [Displays.MAIN];
	const [currentTab, setCurrentTab] = useState(0);

	const handleSelectedMode = async (display, value) => {
		const updated = await setSelectedMode(
			settingTypeId,
			JSON.stringify({
				...displayConfig,
				[display]: value,
			})
		);

		if (updated) {
			setDisplayConfig(prevState => ({
				...prevState,
				[display]: value,
			}));
		}
	};

	return (
		<div className='configurable-settings-display-orientation padding-sl'>
			<h3>{translate('selectMode')}</h3>
			<Tabs activeIndex={currentTab} onChange={setCurrentTab}>
				<TabList>
					{displayList.map(display => (
						<Tab key={display}>{display === Displays.MAIN ? translate('mainDisplay') : translate('secondaryDisplay')}</Tab>
					))}
				</TabList>
				<TabPanels className='configurable-settings-modal-inner'>
					{displayList.map(display => (
						<TabPanel key={display}>
							<div className='flex flex-wrap grid-50'>
								{selectModes.map(item => (
									<div key={item.id} className='flex' onClick={() => handleSelectedMode(display, item.value)}>
										<img src={item.img} alt={item.name} /> <p>{item.name}</p>
										<div className={`cursor-pointer checked-circle${item.value === displayConfig[display] ? ' active' : ''}`}>
											<i className='material-icons'>done</i>
										</div>
									</div>
								))}
							</div>
						</TabPanel>
					))}
				</TabPanels>
			</Tabs>
		</div>
	);
};

export default PortraitMode;
