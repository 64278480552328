import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Alert, EmptyState, Loader } from 'components/index.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import { getHoursWithMinutes, monthDayYear } from 'infrastructure/helpers/dateHelper.js';
import { getConferenceRecordingsCount } from 'api/stethoscope.js';
import HeartLungsDetails from 'containers/HealthMeasurements/HeartLungsDetails.jsx';
import { ExamType } from 'constants/enums.js';
import Button from 'components/Common/Button.jsx';
import { getStethoscopeRecordingsSasToken } from 'api/measurements.js';

const HeartLungsMeasurements = props => {
	const intl = useIntl();
	const [selectedMeasurement, setSelectedMeasurement] = useState(null);
	const [error, setError] = useState(null);
	const [isMeasurementSelected, setIsMeasurementSelected] = useState(false);
	const [measurements, setMeasurements] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [pageIndex, setPageIndex] = useState(0);
	const [hasReachedEnd, setHasReachedEnd] = useState(false);
	const [sasToken, setSasToken] = useState(null);

	useEffect(() => {
		const getStethospeSasToken = async () => {
			const sasTokenResponse = await getStethoscopeRecordingsSasToken('stethoscope-recordings');
			if (sasTokenResponse.error) {
				setError(sasTokenResponse.error.message);
				return;
			}
			setSasToken(sasTokenResponse.result?.sasToken);
		};

		getStethospeSasToken();
	}, []);

	useEffect(() => {
		if (props.isHeartMeasurement || props.isLungsMeasurement) {
			setIsMeasurementSelected(false);
			getConferenceStethoMeasurements(true);
			setPageIndex(0);
		}
	}, [props.isHeartMeasurement, props.isLungsMeasurement]);

	const getConferenceStethoMeasurements = async isTabChanged => {
		setError(null);
		const params = {
			patientId: props.selectedPatient?.userId || props.patientId,
			examType: props.isHeartMeasurement ? ExamType.HEART : ExamType.LUNGS,
			pageIndex,
		};
		const response = await getConferenceRecordingsCount(params);
		if (response.error) {
			setError(response.error.message);
			setIsLoading(false);
			return;
		}
		setMeasurements(prevState =>
			isTabChanged ? response.conferenceRecordingsCounts : prevState.concat(response.conferenceRecordingsCounts)
		);
		setHasReachedEnd(response.conferenceRecordingsCounts.totalCount < 10);
		setIsLoading(false);
	};

	const viewDetails = item => {
		setSelectedMeasurement(item);
		setIsMeasurementSelected(true);
	};

	const handleScroll = async event => {
		if (hasReachedEnd) {
			return;
		}
		const isBottom = event.target.scrollHeight - Math.ceil(event.target.scrollTop) === event.target.clientHeight;
		if (isBottom && !hasReachedEnd) {
			setIsLoading(true);
			getConferenceStethoMeasurements();
			setPageIndex(prevState => prevState + 1);
		}
	};

	return (
		<>
			<div className='flex bottom-15'>
				{props.isFromCall && !isMeasurementSelected && (
					<Button
						onClick={() => props.setSelectedMeasurementType(null)}
						className='visit-btn stethoscope back-btn cursor-pointer'
						imgIcon={`${healthCareCdnUrl}back-arrow.svg`}
						alt='back arrow icon'
					/>
				)}
				<h2 className='stethoscope-measurements-title left-15'>
					{translate('stethoscopeMeasurements', {
						value: props.isHeartMeasurement
							? intl.formatMessage({ id: 'heartStetho' })
							: intl.formatMessage({ id: 'lungsStetho' }),
					})}
				</h2>
			</div>
			{!isMeasurementSelected && measurements.length > 0 && (
				<div className='patient-summary-wrapper stethoscope-measurements' onScroll={handleScroll}>
					<div className='patient-summary-table-wrapper'>
						<table cellPadding='0' cellSpacing='0'>
							<thead>
								<tr>
									<th />
									<th />
									<th />
								</tr>
							</thead>
							<tbody>
								{measurements.map(item => (
									<tr key={item.conferenceId}>
										<td>
											<p className='font-14 no-margin no-padding semi-bold'>{monthDayYear(item.dateTime)}</p>
											<p className='font-12 no-margin no-padding gray-color'>
												{`${intl.formatMessage({ id: 'atDate' })} ${getHoursWithMinutes(item.dateTime)}`}
											</p>
										</td>
										<td className='--blue-color semi-bold'>{translate('totalMeasurements', { value: item.recordingCount })}</td>
										<td>
											<Button
												lassName='visit-btn stethoscope cursor-pointer'
												onClick={() => viewDetails(item)}
												imgIcon={`${healthCareCdnUrl}forward-arrow.svg`}
												alt='forward arrow icon'
											/>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			)}
			{isMeasurementSelected && (
				<HeartLungsDetails
					setMeasurement={setIsMeasurementSelected}
					selectedMeasurement={selectedMeasurement}
					isHeartMeasurement={props.isHeartMeasurement}
					selectedPatient={props.selectedPatient}
					totalMeasurements={measurements?.length}
					patientId={props.isFromCall ? props.patientId : props.selectedPatient?.userId}
					selectedMeasurementType={props.selectedMeasurementType}
					conferenceId={props.isFromCall ? props.conferenceId : selectedMeasurement?.conferenceId}
					isFromCall={props.isFromCall}
					sasToken={sasToken}
				/>
			)}

			{!hasReachedEnd && isLoading && (
				<div className='full-width text-align-center'>
					<Loader />
				</div>
			)}
			{!isLoading && measurements.length === 0 && (
				<div className='empty-state-wrapper'>
					<EmptyState title={translate('noData')} image='health-data.svg' />
				</div>
			)}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</>
	);
};

export default HeartLungsMeasurements;
