import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { primaryCareImageUrl } from 'constants/global-variables.js';
import PatientHistoryAccordion from 'containers/PrimaryCare/DigitalClinician/Partials/PatientHistoryAccordion.jsx';
import translate from 'i18n-translations/translate.jsx';
import CasesAccordion from 'containers/PrimaryCare/LaboratoryAnalysis/Partials/CasesAccordion.jsx';
import { capitalizeFirstLetter, stringToCamelCase } from 'infrastructure/helpers/commonHelpers.js';
import {
	TestTypes,
	CaseStatusTypes,
	VisitTypes,
	DiagnosticResearchType,
	ParenteralTherapyType,
	RoomTypes,
} from 'constants/visitEnums.js';
import { formattedDate } from 'infrastructure/helpers/dateHelper.js';
import { forms, laboratoryTests, radiologyTests } from 'containers/PrimaryCare/LaboratoryAnalysis/constants/labForms.js';
import { VisitReasons, visitStatuses } from 'constants/enums.js';
import { EmptyState } from 'components/index.js';
import Button from 'components/Common/Button.jsx';

const PatientHistory = props => {
	const intl = useIntl();
	const { selectedPatient } = props;
	const [patientDetails, setPatientDetails] = useState(null);
	const tabs = { SELECT_PATIENT: 0, PATIENT_HISTORY: 1, ADDITIONAL_DATA: 2, SELECT_DOCTOR: 3 };

	useEffect(() => {
		if (props.isDoctorView) {
			setPatientDetails(selectedPatient?.medicalCases.find(item => item.patient)?.patient);
		}
	}, [props.isDoctorView, selectedPatient]);

	const visitReasons = [
		{
			id: 0,
			value: VisitReasons.INSTRUCTION,
			text: intl.formatMessage({ id: 'instruction' }),
		},
		{
			id: 1,
			value: VisitReasons.THERAPY_DESCRIPTION,
			text: intl.formatMessage({ id: 'therapyDescription' }),
		},
		{
			id: 2,
			value: VisitReasons.CHECK_UP,
			text: intl.formatMessage({ id: 'checkUp' }),
		},
		{
			id: 3,
			value: VisitReasons.RE_CHECK_UP,
			text: intl.formatMessage({ id: 'reCheckUp' }),
		},
		{
			id: 4,
			value: VisitReasons.REFERRAL,
			text: intl.formatMessage({ id: 'referral' }),
		},
		{
			id: 5,
			value: VisitReasons.OTHER,
			text: intl.formatMessage({ id: 'other' }),
		},
	];

	const getVisitStatusById = medicalVisitStatusId => visitStatuses.find(status => status.id === medicalVisitStatusId).description;

	const getCase = (title, arr) => (
		<div className='cases'>
			<p>{translate(title)}</p>

			<div className='flex cases-table-head' style={{ padding: 'var(--spacing-l)' }}>
				<span className='flex-1'>{translate('patient')}</span>
				<span className='flex-1'>{translate('registryNumber')}</span>
				<span className='flex-1'>{translate('visitStart')}</span>
				<span className='flex-1'>{translate('visitEnd')}</span>
				<span className='flex-1'>{translate('type')}</span>
				<span className='flex-1'>{translate('status')}</span>
			</div>
			<div>
				{arr.map(item => {
					return (
						<CasesAccordion
							statusId={item.statusId}
							patientFullName={`${item.patient.firstName} ${item.patient.lastName}`}
							id={item.id}
							idCard={item.patient.idCard}
							closedAt={item.closedAt}
							openedAt={item.openedAt}>
							{item.medicalVisits.length > 0 && (
								<>
									{item.medicalVisits.map((medicalVisit, index) => (
										<div className='case-section full-width' key={medicalVisit.id}>
											{medicalVisit.typeId === VisitTypes.FRONT_DESK && (
												<>
													{index === 0 && <h4>{translate('itinerary')}</h4>}
													<div>
														<PatientHistoryAccordion
															image='frontdesk-nurse-1.svg'
															alt='front desk icon'
															title={intl.formatMessage({ id: 'frontDeskCheckIn' })}
															name={`${medicalVisit.performer.firstName} ${medicalVisit.performer.lastName}`}
															content={
																<>
																	{medicalVisit.medicalVisitInformations.length > 0 && (
																		<div>
																			<p>{translate('whatReasonOfVisit')}</p>
																			<p>
																				{medicalVisit?.medicalVisitInformations[0]?.medicalVisitReasonId === VisitReasons.OTHER &&
																					medicalVisit?.medicalVisitInformations[0]?.medicalVisitReasonText}

																				{medicalVisit?.medicalVisitInformations[0]?.medicalVisitReasonId !== VisitReasons.OTHER &&
																					visitReasons.find(
																						reason =>
																							reason.value === medicalVisit?.medicalVisitInformations[0]?.medicalVisitReasonId
																					)?.text}
																			</p>
																		</div>
																	)}
																	{medicalVisit.symptoms.length > 0 && (
																		<div>
																			<p>{translate('symptoms')}</p>
																			<p className='flex symptoms'>
																				{medicalVisit.symptoms.length > 0 &&
																					medicalVisit.symptoms.map(symptom => (
																						<span key={symptom.id}>
																							{intl.formatMessage({ id: stringToCamelCase(symptom.name) })}
																						</span>
																					))}
																			</p>
																		</div>
																	)}
																	{medicalVisit.medicalVisitInformations.length > 0 && (
																		<>
																			{medicalVisit.medicalVisitInformations[0].answer && (
																				<div>
																					<p>{translate('symptomsDuration')}</p>
																					<p>{medicalVisit.medicalVisitInformations[0].answer}</p>
																				</div>
																			)}
																			{medicalVisit.medicalVisitInformations[0].additionalNote && (
																				<div>
																					<p>{translate('additionalNote')}</p>
																					<p>{medicalVisit.medicalVisitInformations[0].additionalNote}</p>
																				</div>
																			)}
																		</>
																	)}
																</>
															}
														/>

														<p className={!medicalVisit.endedAt ? '--blue-color' : ''}>
															{medicalVisit.endedAt
																? formattedDate(medicalVisit.endedAt)
																: intl.formatMessage({ id: getVisitStatusById(medicalVisit.statusId) })}
														</p>
													</div>
												</>
											)}
											{medicalVisit.typeId === VisitTypes.DOCTOR && (
												<div>
													<PatientHistoryAccordion
														image='doctor-1.svg'
														alt='doctor checkup icon'
														title={intl.formatMessage({ id: 'doctorCheckUp' })}
														name={`${medicalVisit.performer.firstName} ${medicalVisit.performer.lastName}`}
														content={
															<>
																<div>
																	<p>{translate('room')}</p>
																	<p>{medicalVisit.place}</p>
																</div>
																{medicalVisit?.parenteralTherapies.length > 0 && (
																	<div className='top-15 lab-visit-content'>
																		<h4>
																			{translate(
																				medicalVisit.typeId === VisitTypes.INFUSION ? 'infusionTherapy' : 'injectionTherapy'
																			)}
																		</h4>
																		{parentalTherapyItem(medicalVisit)}
																	</div>
																)}
																{medicalVisit.diagnostifications.length > 0 && (
																	<div className='top-15 lab-visit-content'>
																		<p className='tests-title'>
																			{capitalizeFirstLetter(intl.formatMessage({ id: 'diagnoses' }))}
																		</p>
																		<div className='flex flex-wrap'>
																			{medicalVisit.diagnostifications.map(diagnose => (
																				<div key={diagnose}>
																					<p className='no-margin no-padding'>{diagnose}</p>
																				</div>
																			))}
																		</div>
																	</div>
																)}
																{medicalVisit.diagnosticResearches.length > 0 && (
																	<>
																		<div className='top-15 lab-visit-content'>
																			<p className='tests-title'>{capitalizeFirstLetter(intl.formatMessage({ id: 'tests' }))}</p>
																			<div className='flex flex-wrap'>
																				{medicalVisit.diagnosticResearches.map(sample => (
																					<div key={sample.testType}>
																						<p className='no-margin no-padding'>
																							{laboratoryTests(intl).find(labTest => labTest.id === sample.testType)
																								? laboratoryTests(intl).find(labTest => labTest.id === sample.testType)
																										.description
																								: radiologyTests(intl).find(labTest => labTest.id === sample.testType)
																										?.description}
																						</p>
																					</div>
																				))}
																			</div>
																		</div>
																	</>
																)}
															</>
														}
													/>

													<p className={!medicalVisit.endedAt ? '--blue-color' : ''}>
														{medicalVisit.endedAt
															? formattedDate(medicalVisit.endedAt)
															: intl.formatMessage({ id: getVisitStatusById(medicalVisit.statusId) })}
													</p>
												</div>
											)}
											{medicalVisit.typeId === VisitTypes.LAB && (
												<div>
													<PatientHistoryAccordion
														image='laboratory-1.svg'
														alt='lab test icon'
														title={intl.formatMessage({ id: 'laboratoryTesting' })}
														name={`${medicalVisit.performer.firstName} ${medicalVisit.performer.lastName}`}
														content={
															<>
																<div>
																	<p>{translate('room')}</p>
																	<p>{medicalVisit.place}</p>
																</div>
																<div className='full-width results-accordion-content view-results-accordion-content'>
																	<div />

																	{medicalVisit.diagnosticResearches.length > 0 &&
																		medicalVisit.diagnosticResearches.map(analyses => {
																			const selectedAnalysis = forms(intl).find(formItem => formItem.id === analyses.testType);
																			return (
																				<React.Fragment key={selectedAnalysis.id}>
																					<div className='flex flex-align-center'>
																						<div>
																							<img src={`${primaryCareImageUrl}${selectedAnalysis.icon}`} alt='icon' />
																						</div>
																						<div>
																							<p className='no-margin'>{selectedAnalysis.description}</p>
																						</div>
																					</div>
																					<div
																						className={classNames(
																							'flex flex-wrap',
																							selectedAnalysis.id === TestTypes.URINE ? 'urine-results' : ''
																						)}>
																						{analyses.attachments.map(sub => {
																							const selectedResult = selectedAnalysis.results.find(
																								resultItem => resultItem.id === sub.typeId
																							);
																							return (
																								<div className='flex flex-align-center' style={{ marginTop: '5px' }}>
																									<p className='flex-1 no-margin'>{selectedResult.description}</p>
																									{selectedAnalysis.id === TestTypes.URINE && (
																										<textarea
																											name={selectedResult.description}
																											value={sub.value}
																											rows={sub.isTextarea ? 2 : 1}
																											readOnly
																											placeholder={intl.formatMessage({ id: 'description' })}
																										/>
																									)}
																									{selectedAnalysis.id !== TestTypes.URINE && (
																										<>
																											<input
																												name={selectedResult.description}
																												type='text'
																												value={sub.value}
																												readOnly
																											/>
																											<span>{selectedResult.unit || ''}</span>
																										</>
																									)}
																								</div>
																							);
																						})}
																					</div>
																				</React.Fragment>
																			);
																		})}
																</div>
															</>
														}
													/>

													<p className={!medicalVisit.endedAt ? '--blue-color' : ''}>
														{medicalVisit.endedAt
															? formattedDate(medicalVisit.endedAt)
															: intl.formatMessage({ id: getVisitStatusById(medicalVisit.statusId) })}
													</p>
												</div>
											)}
											{[VisitTypes.INFUSION, VisitTypes.INJECTION].includes(medicalVisit.typeId) && (
												<div>
													<PatientHistoryAccordion
														image={medicalVisit.typeId === VisitTypes.INFUSION ? 'infusion-room-1.svg' : 'injection-room-1.svg'}
														alt='infusion injection visit'
														title={intl.formatMessage({
															id: medicalVisit.typeId === VisitTypes.INFUSION ? 'infusionRoom' : 'injectionRoom',
														})}
														name={`${medicalVisit.performer.firstName} ${medicalVisit.performer.lastName}`}
														content={
															<>
																<div>
																	<p>{translate('room')}</p>
																	<p>{medicalVisit.place}</p>
																</div>

																<div className='top-15 lab-visit-content'>
																	<h4>
																		{translate(
																			medicalVisit.typeId === VisitTypes.INFUSION ? 'infusionTherapy' : 'injectionTherapy'
																		)}
																	</h4>
																	{parentalTherapyItem(medicalVisit)}
																</div>
															</>
														}
													/>

													<p className={!medicalVisit.endedAt ? '--blue-color' : ''}>
														{medicalVisit.endedAt
															? formattedDate(medicalVisit.endedAt)
															: intl.formatMessage({ id: getVisitStatusById(medicalVisit.statusId) })}
													</p>
												</div>
											)}
											{medicalVisit.typeId === VisitTypes.RADIOLOGY && (
												<div>
													<PatientHistoryAccordion
														image='radiology-1.svg'
														alt='radiology test icon'
														title={intl.formatMessage({
															id: 'radiologyRoom',
														})}
														name={`${medicalVisit.performer.firstName} ${medicalVisit.performer.lastName}`}
														content={
															<>
																<div>
																	<p>{translate('room')}</p>
																	<p>{medicalVisit.place}</p>
																</div>

																<div className='top-15 lab-visit-content'>
																	{medicalVisit.notes?.length && (
																		<h4>
																			{translate('comment')}: {medicalVisit.notes[0]?.text}
																		</h4>
																	)}
																	<div className='flex flex-wrap'>
																		{medicalVisit.diagnosticResearches.map(test => (
																			<div key={test.testType}>
																				<p className='no-margin no-padding'>
																					{radiologyTests(intl).find(labTest => labTest.id === test.testType)?.description}
																				</p>
																			</div>
																		))}
																	</div>
																</div>
															</>
														}
													/>

													<p className={!medicalVisit.endedAt ? '--blue-color' : ''}>
														{medicalVisit.endedAt
															? formattedDate(medicalVisit.endedAt)
															: intl.formatMessage({ id: getVisitStatusById(medicalVisit.statusId) })}
													</p>
												</div>
											)}
											{!item.patientIsInQueue && !props.isDoctorView && (
												<div className='flex' style={{ marginLeft: 'calc(var(--spacing-sssl) + var(--spacing-s))' }}>
													{showDiagnosticResearch(medicalVisit, DiagnosticResearchType.LABORATORY) &&
														ReAssignToQueue(() => reAssign(medicalVisit.id, item, RoomTypes.LABORATORY.type), 'laboratory')}
													{showDiagnosticResearch(medicalVisit, DiagnosticResearchType.RADIOLOGY) &&
														ReAssignToQueue(() => reAssign(medicalVisit.id, item, RoomTypes.RADIOLOGY.type), 'radiology')}
													{showParentalTherapy(medicalVisit, ParenteralTherapyType.INFUSION) &&
														ReAssignToQueue(() => reAssign(medicalVisit.id, item, RoomTypes.INFUSION_ROOM.type), 'infusion')}
													{showParentalTherapy(medicalVisit, ParenteralTherapyType.INJECTION) &&
														ReAssignToQueue(() => reAssign(medicalVisit.id, item, RoomTypes.INJECTION_ROOM.type), 'injection')}
												</div>
											)}
										</div>
									))}
									{!props.isDoctorView && (
										<div
											className={classNames(
												'flex margin-left-auto full-width front-desk-btn top-30',
												props.isDoctorInVisit(item) ? 'disabled' : ''
											)}>
											{!item.patientIsInQueue && (
												<Button
													className='blue-highlight-btn'
													onClick={() => props.assignNewDoctor(item)}
													text={translate('assignNewDoctor')}
												/>
											)}
										</div>
									)}
								</>
							)}
						</CasesAccordion>
					);
				})}
			</div>
		</div>
	);

	const showDiagnosticResearch = (visit, diagnosticResearchType) =>
		visit.pendingDiagnosticResearches.filter(diagnostic => diagnostic.diagnosticResearchType === diagnosticResearchType).length >
		0;

	const showParentalTherapy = (visit, parentalTherapyType) =>
		visit.parenteralTherapies.filter(parentalTherapy => parentalTherapy.type === parentalTherapyType).length > 0;

	const ReAssignToQueue = (submit, btnText) => (
		<Button
			imgIcon={`${primaryCareImageUrl}back-arrow.svg`}
			className='blue-btn blue-highlight-btn top-15 right-15'
			onClick={submit}
			text={translate('reassignToQueue', {
				value: translate(btnText),
			})}
		/>
	);

	const reAssign = (visitId, caseItem, specialistType) => {
		props.setSpecialistType(specialistType);
		props.setCurrentTab(tabs.SELECT_DOCTOR);
		props.setSelectedCase(caseItem);
		props.setReferredVisitId(visitId);
	};

	const parentalTherapyItem = medicalVisit => (
		<div className='flex flex-wrap parenteral-therapy-box'>
			{medicalVisit?.parenteralTherapiesVisits?.[0]?.parenteralTherapy.medications?.map(therapy => (
				<div>
					<p className='no-margin no-padding'>{therapy.name}</p>
					<p>
						{translate('dosage')}: {therapy.dosage}ml, {translate('duration')}: {therapy.duration}
						{translate('durationDays')}, {translate('frequency')}: {therapy.frequency}
						{translate('timesADay')}
					</p>
				</div>
			))}
		</div>
	);

	const getPatientDetails = () => {
		let result = '';
		if (props.isDoctorView) {
			result = selectedPatient && `${patientDetails?.firstName} ${patientDetails?.lastName} ${patientDetails?.idCard}`;
		} else {
			result = `${selectedPatient?.firstName} ${selectedPatient?.lastName} ${selectedPatient?.idCard}`;
		}
		return result;
	};

	return (
		<>
			{!props.isDoctorView && (
				<div className='flex flex-space-between patient-history-header'>
					<Button
						imgIcon={`${primaryCareImageUrl}back-arrow.svg`}
						className='visit-btn'
						onClick={props.goBack}
						text={translate('goBack')}
					/>
					<div className='flex'>
						<Button
							imgIcon={`${primaryCareImageUrl}profile.svg`}
							className='visit-btn blue-highlight-btn'
							onClick={props.setIsEditProfile}
							text={translate('editProfile')}
						/>

						<Button
							imgIcon={`${primaryCareImageUrl}next-arrow.svg`}
							className='visit-btn blue-btn'
							onClick={props.nextTab}
							text={translate('next')}
						/>
					</div>
				</div>
			)}
			<div>
				<h3>
					{translate('visitHistoryFor')} {getPatientDetails()}
				</h3>
				{selectedPatient?.medicalCases?.length === 0 && (
					<div className='patient-has-no-visits'>
						<EmptyState title={translate('patientNoVisits')} image='empty-history-for-patient.svg' />
					</div>
				)}
				{selectedPatient?.medicalCases?.length > 0 &&
					selectedPatient.medicalCases.filter(item => item.statusId === CaseStatusTypes.ACTIVE).length > 0 &&
					getCase(
						'openCases',
						selectedPatient.medicalCases.filter(item => item.statusId === CaseStatusTypes.ACTIVE)
					)}

				{selectedPatient?.medicalCases?.length > 0 &&
					selectedPatient.medicalCases.filter(item => item.statusId !== CaseStatusTypes.ACTIVE).length > 0 &&
					getCase(
						'closedCases',
						selectedPatient.medicalCases.filter(item => item.statusId !== CaseStatusTypes.ACTIVE)
					)}
			</div>
		</>
	);
};

export default PatientHistory;
