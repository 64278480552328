import { getConversationByType } from 'api/messenger.js';
import AlertHistory from 'components/Common/AlertHistory.jsx';
import CustomTable from 'components/Common/CustomTable.jsx';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from 'components/Common/Tabs.jsx';
import { ConversationType } from 'constants/enums.js';
import { HospitalAtHomeTabsEnum } from 'constants/hospital-at-home.js';
import translate from 'i18n-translations/translate.jsx';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Conversation from 'views/Chat/Conversation.jsx';
import OverviewTab from 'containers/HospitalAtHome/OverviewTab.jsx';
import PatientDetails from 'containers/HospitalAtHome/PatientDetails.jsx';
import { getUserId, getUserRole } from 'infrastructure/auth.js';
import { getPatientMedications } from 'api/rpm.js';
import { getPatientMeasurementAlerts } from 'api/alerts.js';
import { useSelector } from 'react-redux';
import Alert from 'components/Common/Alert.jsx';
import ProfilePicture from 'components/Common/ProfilePicture.jsx';
import { defaultDateFormat } from 'infrastructure/helpers/dateHelper.js';
import Form from 'components/Common/Form.jsx';
import Schedule from 'containers/HospitalAtHome/Schedule.jsx';
import RecentMetrics from 'containers/HospitalAtHome/RecentMetrics.jsx';
import ContinuousPatientMonitoring from 'containers/Rpm/ContinuousPatientMonitoring.jsx';
import Visits from 'views/Partials/Visits.jsx';
import { getRoleConfigurationValue } from 'infrastructure/helpers/commonHelpers.js';
import { GeneralAndMenuSettings } from 'constants/configurationEnums.js';

const PatientOverview = ({
	setPatientDetailsView,
	setSelectedPatient,
	selectedPatient,
	talkToPatient,
	careTeam,
	shouldShowSchedule = true,
	shouldShowVisits = false,
	isConversationClicked = false,
}) => {
	const [currentTabIndex, setCurrentTabIndex] = useState(0);
	const [conversationId, setConversationId] = useState(null);
	const [medications, setMedications] = useState([]);
	const [alerts, setAlerts] = useState([]);
	const [isLifeSignalsOpen, setIsLifeSignalsOpen] = useState(false);
	const [error, setError] = useState(null);
	const healthSystemId = useSelector(state => state.user.userSession.healthSystem.id);
	const customRoleConfigurations = useSelector(state => state.configurations.customRoleConfigurations);
	const intl = useIntl();

	const tabs = [
		{
			id: HospitalAtHomeTabsEnum.OVERVIEW,
			title: 'overview',
		},
		shouldShowSchedule && {
			id: HospitalAtHomeTabsEnum.SCHEDULE,
			title: 'schedule',
		},
		{
			id: HospitalAtHomeTabsEnum.MEDICATION,
			title: 'medication',
		},
		{
			id: HospitalAtHomeTabsEnum.ALERTS,
			title: 'alerts',
		},
		{
			id: HospitalAtHomeTabsEnum.RECENT_METRICS,
			title: 'recentMetrics',
		},
		getRoleConfigurationValue(customRoleConfigurations, GeneralAndMenuSettings.CPM) && {
			id: HospitalAtHomeTabsEnum.TELEMETRY,
			title: 'telemetry',
		},
		{
			id: HospitalAtHomeTabsEnum.CARE_TEAM,
			title: 'careTeam',
		},
		shouldShowVisits && {
			id: HospitalAtHomeTabsEnum.VISITS,
			title: 'visits',
		},
		{
			id: HospitalAtHomeTabsEnum.CHAT,
			title: 'chat',
		},
	].filter(Boolean);

	useEffect(() => {
		if (currentTabIndex !== HospitalAtHomeTabsEnum.TELEMETRY && isLifeSignalsOpen) {
			setIsLifeSignalsOpen(false);
		}
	}, [currentTabIndex, isLifeSignalsOpen]);

	useEffect(() => {
		if (isConversationClicked) {
			setCurrentTabIndex(HospitalAtHomeTabsEnum.CHAT);
		}
	}, [isConversationClicked]);

	useEffect(() => {
		const getPatientHealth = async () => {
			if (!selectedPatient) {
				return;
			}
			const medicationsParams = {
				userId: getUserId(),
				patientId: selectedPatient?.id,
				role: getUserRole(),
				isRpmRequest: false,
				pageIndex: 0,
			};
			const alertsParams = {
				pageSize: 5,
				pageIndex: 0,
				userId: selectedPatient.id,
			};

			const [medicationsResponse, alertsResponse] = await Promise.all([
				getPatientMedications(medicationsParams),
				getPatientMeasurementAlerts(alertsParams),
			]);
			const error = medicationsResponse?.error || alertsResponse?.error;
			if (error) {
				setError(error?.message);
				return;
			}
			setMedications(medicationsResponse);
			setAlerts(alertsResponse.measurementAlerts);
		};

		getPatientHealth();
	}, [selectedPatient]);

	useEffect(() => {
		const loadConversationId = async () => {
			if (!selectedPatient) {
				return;
			}
			const response = await getConversationByType(ConversationType.INDIVIDUAL, selectedPatient?.userId, 0);
			setConversationId(response?.conversation?.id);
		};
		loadConversationId();
	}, [conversationId, selectedPatient]);

	const displayMedications = () =>
		medications.map(medication => ({
			medication: medication.medicine.name,
			dosage: medication.dosage,
			frequency: `${medication.frequency} ${intl.formatMessage({ id: medication.frequency > 1 ? 'timesAday' : 'timeAday' })} `,
			duration: `${medication.duration} ${intl.formatMessage({ id: medication.duration > 1 ? 'days' : 'day' })}`,
			refills: medication.refills,
			prescriptionDate: defaultDateFormat(medication.visitedAt),
		}));

	return (
		<>
			<div className='patient-tabs'>
				<PatientDetails
					setPatientDetailsView={setPatientDetailsView}
					setSelectedPatient={setSelectedPatient}
					selectedPatient={selectedPatient}
					goToOverview={() => setCurrentTabIndex(HospitalAtHomeTabsEnum.OVERVIEW)}
					talkToPatient={talkToPatient}
					isLifeSignalsOpen={isLifeSignalsOpen}
				/>
				<Tabs activeIndex={currentTabIndex} onChange={setCurrentTabIndex}>
					<TabList>
						{tabs.map(tab => (
							<Tab customIndex={tab.id} key={tab.id}>
								{translate(tab.title)}
							</Tab>
						))}
					</TabList>
					<TabPanels>
						<TabPanel>
							<OverviewTab
								goToRecentMetric={() => setCurrentTabIndex(HospitalAtHomeTabsEnum.RECENT_METRICS)}
								goToMedications={() => setCurrentTabIndex(HospitalAtHomeTabsEnum.MEDICATION)}
								goToTelemetry={() => setCurrentTabIndex(HospitalAtHomeTabsEnum.TELEMETRY)}
								goToAlerts={() => setCurrentTabIndex(HospitalAtHomeTabsEnum.ALERTS)}
								goToCareTeam={() => setCurrentTabIndex(HospitalAtHomeTabsEnum.CARE_TEAM)}
								alerts={alerts}
								careTeam={careTeam}
								medications={medications}
								shouldShowSchedule={shouldShowSchedule}
								selectedPatient={selectedPatient}
							/>
						</TabPanel>
						<TabPanel>
							<div className='patient-tab-wrapper medications-wrapper'>
								<Schedule selectedPatient={selectedPatient} healthSystemId={healthSystemId} />
							</div>
						</TabPanel>

						<TabPanel>
							<div className='patient-tab-wrapper medications-wrapper'>
								<CustomTable
									headers={[
										{ title: translate('medication'), id: 'medication' },
										{ title: translate('dosage'), id: 'dosage' },
										{ title: translate('frequency'), id: 'frequency' },
										{ title: translate('duration'), id: 'duration' },
										{ title: translate('refills'), id: 'refills' },
										{ title: translate('date'), id: 'prescriptionDate' },
									]}
									rows={displayMedications()}
									className='medications-table'
									isEditable={false}
								/>
							</div>
						</TabPanel>
						<TabPanel>
							<div className='patient-tab-wrapper'>
								<AlertHistory patientId={selectedPatient.id} isCallView={false} showOnlyMeasurementAlerts={true} />
							</div>
						</TabPanel>
						<TabPanel>
							<div className='patient-tab-wrapper'>
								<RecentMetrics selectedPatient={selectedPatient} />
							</div>
						</TabPanel>
						<TabPanel>
							<div className='patient-tab-wrapper'>
								<ContinuousPatientMonitoring selectedPatient={selectedPatient} setIsLifeSignalsOpen={setIsLifeSignalsOpen} />
							</div>
						</TabPanel>
						<TabPanel>
							<div className='patient-tab-wrapper'>
								{careTeam && (
									<Form>
										{careTeam.length === 0 && (
											<div className='flex flex-align-center flex-justify-center'>
												<p>{translate('noResultsFound')}</p>
											</div>
										)}
										<div className='care-team-members-wrapper'>
											<div className='available-doctor-items care-team-members-list'>
												{careTeam.map(item => (
													<div key={item?.userIntId} className='care-team-info'>
														<ProfilePicture
															className='doctor-request-img available-doctor-request-img'
															firstName={item.firstName}
															lastName={item.lastName}
															profilePicture={item.profilePicture}
														/>
														<p className='team-member-name'>
															{item.firstName} {item.lastName}
														</p>
														<span className='additional-info'>{item.email}</span>
														{item.specialty?.name && <span className='additional-info'>{item.specialty.name}</span>}
													</div>
												))}
											</div>
										</div>
									</Form>
								)}
							</div>
						</TabPanel>
						<TabPanel>
							<Visits selectedPatient={selectedPatient} />
						</TabPanel>
						<TabPanel>
							<Conversation
								selectedUser={{
									id: selectedPatient?.userId,
									name: `${selectedPatient?.firstName} ${selectedPatient?.lastName}`,
									profilePicture: selectedPatient?.profilePicture,
								}}
								conversationId={conversationId}
								isFromDoctor={true}
								isPatientDoctorChat={true}
								hideRightSide={false}
							/>
						</TabPanel>
					</TabPanels>
				</Tabs>
			</div>
			<Alert display={error} message={error} variant='dark' fixed={true} onClose={() => setError(null)} />
		</>
	);
};

export default PatientOverview;
