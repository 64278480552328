import translate from 'i18n-translations/translate.jsx';
import { useIntl } from 'react-intl';
import Button from 'components/Common/Button.jsx';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { monthDayYear } from 'infrastructure/helpers/dateHelper.js';
import ProfilePicture from 'components/Common/ProfilePicture.jsx';
import { getPreferredLanguageLocale } from 'infrastructure/helpers/commonHelpers.js';
import { getPatientAppointments } from 'api/appointments.js';
import { useSelector } from 'react-redux';
import { Alert } from 'components/index.js';

const PatientScheduledAppointments = ({ selectedPatient }) => {
	const [dateRange, setDateRange] = useState({
		from: moment(new Date()).format('YYYY-MM-DD'),
		to: moment(new Date()).format('YYYY-MM-DD'),
	});
	const [appointments, setAppointments] = useState([]);
	const [error, setError] = useState(null);
	const healthSystemId = useSelector(state => state.user.userSession.healthSystem.id);
	const intl = useIntl();
	const isToday = dateRange.from === moment(new Date()).format('YYYY-MM-DD');

	useEffect(() => {
		const getAppointments = async () => {
			if (!selectedPatient) {
				return;
			}
			const response = await getPatientAppointments({
				patientId: selectedPatient.id,
				healthSystemId,
				from: dateRange.from,
				to: dateRange.to,
			});
			if (response.error) {
				setError(intl.formatMessage({ id: 'somethingWentWrong' }));
				return;
			}
			setAppointments(response.appointments);
		};

		getAppointments();
	}, [selectedPatient, dateRange, healthSystemId, intl]);

	return (
		<div className='schedule-wrapper appointments'>
			<div className='flex flex-space-between'>
				<span>
					{translate('schedule')}
					{' ('}
					{intl.formatMessage({ id: isToday ? 'today' : 'tomorrow' }) + ' ' + monthDayYear(dateRange.from)}
					{')'}
				</span>
				<div className='flex gap-s'>
					<Button
						variant='white'
						text={translate(isToday ? 'next' : 'previous')}
						className='flex-align-center'
						onClick={() =>
							setDateRange({
								from: isToday ? moment(new Date()).add(1, 'day').format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD'),
								to: isToday ? moment(new Date()).add(1, 'day').format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD'),
							})
						}
					/>
				</div>
			</div>

			<div className='schedule-items'>
				{appointments?.length > 0 &&
					appointments.map(appointment => (
						<div className='flex schedule-item' key={appointment.id}>
							<ProfilePicture
								fullName={appointment?.createdBy.fullName}
								profilePicture={appointment?.createdBy.profilePicture}
								className='doctor-request-img'
							/>
							<div>
								<h5>
									{translate('appointmentWith')} Dr. {appointment?.createdBy.firstName} {appointment?.createdBy.lastName}
								</h5>
								<span>
									{translate('at', {
										value: moment
											.utc(appointment?.appointmentSlot?.startDateTime)
											.local()
											.locale(getPreferredLanguageLocale())
											.format('LT'),
									})}
								</span>
							</div>
						</div>
					))}
			</div>
			{appointments.length === 0 && (
				<div className='flex flex-justify-center'>
					<p className='semi-bold'>{translate('noResultsFound')}</p>
				</div>
			)}
			<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
		</div>
	);
};

export default PatientScheduledAppointments;
