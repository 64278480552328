import { gatewayApi } from 'constants/global-variables.js';
import { getCompanyId, getUserRoleId } from 'infrastructure/auth.js';
import { buildQueryString } from 'infrastructure/helpers/commonHelpers.js';

export const getRpmPrograms = async ({ pageSize = 10, pageIndex = 0, healthSystemId = null }) => {
	try {
		const { data } = await gatewayApi.get(
			`v1/healthcare/organizations/${getCompanyId()}/rpm-programs?pageSize=${pageSize}&pageIndex=${pageIndex}&hs-id=${healthSystemId}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const createRpmProgram = async ({ name, devices, questions, careTeam, healthSystemId }) => {
	try {
		const { data } = await gatewayApi.post(`v1/healthcare/organizations/${getCompanyId()}/rpm-programs`, {
			name,
			devices,
			questions,
			careTeam,
			healthSystemId,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const updateRpmProgram = async ({ id, name, devices, questions, careTeam, healthSystemId }) => {
	try {
		const { data } = await gatewayApi.put(`v1/healthcare/organizations/${getCompanyId()}/rpm-programs/${id}`, {
			name,
			devices,
			questions,
			careTeam,
			healthSystemId,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const deleteRpmProgram = async ({ id }) => {
	try {
		const { data } = await gatewayApi.delete(`v1/healthcare/organizations/${getCompanyId()}/rpm-programs/${id}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPatientsCareTeam = async ({ patientId, pageIndex = 0, pageSize = 10 }) => {
	try {
		const { data } = await gatewayApi.get(
			`v1/healthcare/organizations/${getCompanyId()}/patients/${patientId}/care-team?pageIndex=${pageIndex}&pageSize=${pageSize}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getUsersRpmList = async ({
	userId,
	pageIndex = 0,
	pageSize = 60,
	search = '',
	shouldExcludeCpm = false,
	includeAppointment = false,
	healthSystemId = null,
}) => {
	try {
		const { data } = await gatewayApi.get(`v1/healthcare/users/${userId}/rpm`, {
			params: {
				role: getUserRoleId(),
				search,
				'page-index': pageIndex,
				'page-size': pageSize,
				'exclude-cpm': shouldExcludeCpm,
				'include-appointment': includeAppointment,
				'hs-id': healthSystemId,
			},
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const addRpmPatient = async params => {
	try {
		const { data } = await gatewayApi.post('v1.2/tasks/patients', params);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getNursesAssigned = async ({ healthSystemId, search = '', pageIndex = 0, pageSize = 20 }) => {
	try {
		const { data } = await gatewayApi.get(
			`v1/organizations/${getCompanyId()}/health-systems/${healthSystemId}/vcps?pageIndex=${pageIndex}&pageSize=${pageSize}&search=${search}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPatientRpmProgram = async userId => {
	try {
		const { data } = await gatewayApi.get(`v1/healthcare/organizations/${getCompanyId()}/users/${userId}/patients/rpm-program`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPatientCareLogs = async ({ patientId, pageIndex = 0, pageSize = 10 }) => {
	try {
		const { data } = await gatewayApi.get(
			`v1/healthcare/organizations/${getCompanyId()}/patients/${patientId}/care-logs?pageIndex=${pageIndex}&pageSize=${pageSize}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getEWSSettings = async healthcareUserId => {
	try {
		const { data } = await gatewayApi.get(
			`v1/healthcare/organizations/${getCompanyId()}/patients/${healthcareUserId}/ews-settings`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const updateEWSSettings = async ({ healthcareUserId, params }) => {
	try {
		const { data } = await gatewayApi.put(
			`v1/healthcare/organizations/${getCompanyId()}/patients/${healthcareUserId}/ews-settings`,
			params
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getRpmProgramDetails = async id => {
	try {
		const { data } = await gatewayApi.get(`v1/healthcare/organizations/${getCompanyId()}/rpm-programs/${id}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPatientSurvey = async ({ patientId, id }) => {
	try {
		const { data } = await gatewayApi.get(
			`v1/healthcare/organizations/${getCompanyId()}/patients/${patientId}/rpm-programs/${id}/survey`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const addRpmToExistingPatient = async (patientId, params) => {
	try {
		const { data } = await gatewayApi.post(
			`/v1/healthcare/organizations/${getCompanyId()}/patients/${patientId}/rpm-programs`,
			params
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const updatePatientRpmProgram = async ({ patientId, rpmProgramId }) => {
	try {
		const { data } = await gatewayApi.put(`v1/healthcare/organizations/${getCompanyId()}/patients/${patientId}/rpm-programs`, {
			rpmProgramId,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const getFamilyMemberPatients = async userId => {
	try {
		const { data } = await gatewayApi.get(`v1/healthcare/users/${userId}/patients/family-members`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPatientMedications = async ({ userId, patientId, role, isRpmRequest, pageSize = 10, pageIndex = 0 }) => {
	try {
		const { data } = await gatewayApi.get(
			`/v1/healthcare/users/${userId}/medical-visits/prescriptions?patientId=${patientId}&role=${role}&is-rpm=${isRpmRequest}&pageSize=${pageSize}&pageIndex=${pageIndex}`
		);
		return data.prescriptions;
	} catch (error) {
		return { error };
	}
};

export const notifyPatientForMedication = async (patientId, missedPrescriptionIds) => {
	try {
		const { data } = await gatewayApi.post(`v1/healthcare/patients/${patientId}/prescriptions/notifications`, {
			missedPrescriptionIds,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const validateVisitorEmail = async (email, patientId) => {
	try {
		const { data } = await gatewayApi.get(
			`v1/healthcare/organizations/${getCompanyId()}/users/visitors/${email}/is-valid${
				patientId ? `?patientId=${patientId}` : ''
			}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getCpmList = async ({ userId, queryParams }) => {
	try {
		const { data } = await gatewayApi.get(
			`v1/healthcare/organizations/${getCompanyId()}/users/${userId}/cpm?${buildQueryString(queryParams)}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const deleteInactivePatientRpm = async patientId => {
	try {
		const { data } = await gatewayApi.delete(`v1/healthcare/organizations/${getCompanyId()}/patients/${patientId}/rpm-programs`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPatientsAlertConfigurations = async (healthSystemId, patientIds) => {
	try {
		const { data } = await gatewayApi.post(
			`v1/healthcare/organizations/${getCompanyId()}/health-systems/${healthSystemId}/patients/alert-configurations`,
			{ patientIds }
		);
		return data;
	} catch (error) {
		return { error };
	}
};
