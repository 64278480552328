import React, { useContext, useEffect, useState, useRef } from 'react';
import queryString from 'query-string';
import Grid from 'components/Common/Grid.jsx';
import Button from 'components/Common/Button.jsx';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import { incomingCallSound, stopIncomingCallSound, dropSound } from 'components/Common/CallSounds.jsx';
import Avatar from 'components/Common/Avatar.jsx';
import SocketEvents from 'constants/socket-events.js';
import { buildProfilePic } from 'infrastructure/helpers/thumbnailHelper.js';
import { getUserInfo, setUserInfo } from 'infrastructure/auth.js';
import { StartQueryStringKeys } from 'calls/enums/index.js';
import IncomingCallBrowserNotification from 'components/Common/IncomingCallBrowserNotification.jsx';
import { getDeviceOwnerPatient } from 'api/patients.js';
import Alert from 'components/Common/Alert.jsx';
import { ScreenMode } from 'constants/enums.js';

const IncomingCall = props => {
	let incomingCallTimer;
	const socket = useContext(SocketContext);
	const incomingConferenceInfo = useRef(null);
	const [callerInfo, setCallerInfo] = useState(null);
	const [patientName, setPatientName] = useState(null);
	const [deviceModes, setDeviceModes] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);

	const addOnUnloadEvent = () => {
		window.addEventListener('beforeunload', () => {
			if (incomingConferenceInfo.current) {
				socket.emit(SocketEvents.Conference.DECLINE, {
					conferenceId: incomingConferenceInfo.current.conferenceId,
					participantId: incomingConferenceInfo.current.participantId,
				});
			}
			hideIncomingCall();
		});
	};

	useEffect(() => {
		addOnUnloadEvent();
		addSocketListeners();
		return () => {
			removeSocketListeners();
		};
	}, []);

	const startIncomingCallTimer = () => {
		incomingCallTimer = setTimeout(() => {
			if (incomingConferenceInfo.current) {
				socket.emit(SocketEvents.Conference.NOT_ANSWERING, {
					participantId: incomingConferenceInfo.current.participantId,
					conferenceId: incomingConferenceInfo.current.conferenceId,
				});
			}
			hideIncomingCall();
		}, 20000);
	};

	const onAnsweredElsewhere = () => {
		hideIncomingCall();
	};

	const onInitiatorLeft = async () => {
		hideIncomingCall();
		await dropSound();
	};

	const onIncomingCall = async conferenceInfo => {
		incomingConferenceInfo.current = conferenceInfo;
		setCallerInfo(conferenceInfo.from);
		setDeviceModes(conferenceInfo?.additionalData ?? []);
		startIncomingCallTimer();
		await incomingCallSound();
	};

	const addSocketListeners = () => {
		if (!socket) return;
		socket.on(SocketEvents.Conference.ON_ANSWERED_ELSEWHERE, onAnsweredElsewhere);
		socket.on(SocketEvents.Conference.ON_INITIATOR_LEFT, onInitiatorLeft);
		socket.on(SocketEvents.Conference.ON_INCOMING, onIncomingCall);
	};

	const removeSocketListeners = () => {
		if (!socket) return;
		socket.off(SocketEvents.Conference.ON_ANSWERED_ELSEWHERE, onAnsweredElsewhere);
		socket.off(SocketEvents.Conference.ON_INITIATOR_LEFT, onInitiatorLeft);
		socket.off(SocketEvents.Conference.ON_INCOMING, onIncomingCall);
	};

	const declineIncomingCall = () => {
		if (incomingConferenceInfo.current) {
			socket.emit(SocketEvents.Conference.DECLINE, {
				conferenceId: incomingConferenceInfo.current.conferenceId,
				participantId: incomingConferenceInfo.current.participantId,
			});
		}
		hideIncomingCall();
	};

	const acceptIncomingCall = () => {
		const { conferenceId, participantId, callType, from } = incomingConferenceInfo.current;
		let userInfo = getUserInfo();
		userInfo = { ...userInfo, incomingCallsDisabled: true };
		setUserInfo(userInfo);

		const queryParams = queryString.stringify(
			{
				[StartQueryStringKeys.CONFERENCE_ID]: conferenceId,
				[StartQueryStringKeys.PARTICIPANT_ID]: participantId,
				[StartQueryStringKeys.CALL_TYPE]: callType,
				[StartQueryStringKeys.OBJECT_ID]: from.objectId,
				[StartQueryStringKeys.OBJECT_TYPE]: from.objectType,
			},
			{
				skipNull: true,
			}
		);

		const { onAcceptIncomingCall } = props;
		if (!onAcceptIncomingCall) {
			window.open(`/call?${queryParams.toString()}`, '_blank');
		} else {
			onAcceptIncomingCall(incomingConferenceInfo.current);
		}
		hideIncomingCall();
	};

	const clearIncomingCallTimer = () => {
		if (incomingCallTimer) {
			clearTimeout(incomingCallTimer);
			incomingCallTimer = null;
		}
	};

	const hideIncomingCall = () => {
		if (incomingConferenceInfo.current) {
			incomingConferenceInfo.current = null;
			setCallerInfo(null);
			startIncomingCallTimer();
			stopIncomingCallSound();
			clearIncomingCallTimer();
		}
	};

	useEffect(() => {
		setPatientName(null);
		const getPatientName = async () => {
			setIsLoading(true);
			const deviceOwnerResponse = await getDeviceOwnerPatient(callerInfo.objectId);
			if (deviceOwnerResponse.error) {
				setError(deviceOwnerResponse.error.message);
				setIsLoading(false);
				return;
			}
			setPatientName(deviceOwnerResponse.fullName);
			setIsLoading(false);
		};

		if (callerInfo?.objectId && deviceModes.some(mode => mode.value === ScreenMode.HOSPITAL_AT_HOME)) {
			getPatientName();
		}
	}, [callerInfo, deviceModes]);

	return (
		<>
			<div style={{ textAlign: 'center' }}>
				{callerInfo && (
					<Grid className='incoming-calls' columns='1fr' rows='1fr' horizAlign='center' vertAlign='center' stretch='100vh'>
						<div>
							<Avatar src={buildProfilePic(callerInfo.picture)} size='large' fullName='' pulseAnimation='incoming-call-img' />
							{!isLoading && <p className='incoming-call-text'>{patientName ?? callerInfo.name}</p>}
							<Button onClick={declineIncomingCall} icon='call_end' background='red' borderRadius='30px' marginRight='15px' />
							<Button onClick={acceptIncomingCall} icon='call' background='#22cb36' borderRadius='30px' />
						</div>
					</Grid>
				)}
			</div>
			{typeof Notification !== 'undefined' && Notification && <IncomingCallBrowserNotification callerInfo={callerInfo} />}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</>
	);
};

export default IncomingCall;
