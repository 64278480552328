import React, { useState, useEffect, useContext } from 'react';
import queryString from 'query-string';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SocketEvents from 'constants/socket-events.js';
import { getUserInfo, setUserInfo, getUserId } from 'infrastructure/auth.js';
import { AssignedRequestTypes, RequestStatus } from 'constants/enums.js';
import Pagination from 'components/Common/Pagination.jsx';
import { getProviderAssignedRequests } from 'api/doctorRequests.js';
import { updateAssignRequest } from 'api/patientRequests.js';
import Grid from 'components/Common/Grid.jsx';
import Loader from 'components/Common/Loader.jsx';
import Alert from 'components/Common/Alert.jsx';
import EmptyState from 'components/Common/EmptyState.jsx';
import translate from 'i18n-translations/translate.jsx';
import StartQueryStringKeys from 'calls/enums/StartQueryStringKeys.js';
import { actionCreators as userActionCreators } from 'state/user/actions.js';
import CardRequest from 'containers/CardRequest/CardRequest.jsx';

const SessionsQueue = () => {
	const socket = useContext(SocketContext);
	const dispatch = useDispatch();
	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 0, totalCount: 0 });
	const [errorApiResponse, setErrorApiResponse] = useState('');
	const [requests, setRequests] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const intl = useIntl();
	const sessionsQueueCount = useSelector(state => state.user.sessionsQueueCount);
	const setUserSessionQueueCount = count => dispatch(userActionCreators.setUserSessionQueueCount(count));

	const getRequests = async (pageSize, pageIndex) => {
		const params = {
			doctorGuidId: getUserId(),
			pageSize,
			pageIndex,
			type: AssignedRequestTypes.WITH_INTAKE_FORM,
		};
		const response = await getProviderAssignedRequests(params);
		if (response.error) {
			setErrorApiResponse(response.error.message);
			setIsLoading(false);
			return;
		}
		setRequests(response.assignedRequests);
		setPagination({ pageSize, pageIndex, totalCount: response.totalCount });
		setUserSessionQueueCount(response.totalCount);
		setIsLoading(false);
	};

	useEffect(() => {
		const handleRequestUpdated = () => {
			getRequests(pagination.pageSize, pagination.pageIndex);
		};
		getRequests(pagination.pageSize, pagination.pageIndex);
		socket.on(SocketEvents.HealthCare.ON_MEDIC_REQUEST_UPDATED, handleRequestUpdated);
		return () => {
			socket.off(SocketEvents.HealthCare.ON_MEDIC_REQUEST_UPDATED, handleRequestUpdated);
		};
	}, [pagination.pageIndex, pagination.pageSize, pagination.totalCount, socket]);

	const joinCall = async item => {
		const { requestConference } = item.request;
		setUserInfo({ ...getUserInfo(), incomingCallsDisabled: true });
		const queryParams = queryString.stringify(
			{
				[StartQueryStringKeys.CONFERENCE_ID]: requestConference.conferenceId,
				assignedRequestId: item.id,
			},
			{
				skipNull: true,
			}
		);
		window.open(`/call?${queryParams.toString()}`, '_blank');
		setRequests(prevState => prevState.filter(request => request.id !== item.id));
		setUserSessionQueueCount(sessionsQueueCount - 1);
	};

	const rejectRequest = async item => {
		const { requestConference } = item.request;
		const response = await updateAssignRequest(item.id, {
			RequestStatusId: RequestStatus.REJECTED,
		});
		if (response.error) {
			setErrorApiResponse(response.error.message);
			return;
		}
		if (requestConference) {
			const { conferenceId, creatorId } = requestConference;
			socket.emit(
				SocketEvents.Conference.END,
				{
					conferenceId,
					participantId: creatorId,
				},
				() => {}
			);
		}
		setRequests(prevState => prevState.filter(request => request.id !== item.id));
		setUserSessionQueueCount(sessionsQueueCount - 1);
	};

	const hasRequestConference = requests.some(item => item.request?.requestConference);

	return (
		<MainLayout>
			<div className='view-page-wrapper'>
				{!isLoading && requests.length > 0 && (
					<div
						className={`waiting-room-view-inner ${requests.every(item => !item.request?.requestConference) ? '' : 'full-width'}`}>
						{hasRequestConference && (
							<div className='flex home-title'>
								<h3>
									{translate('youHaveLengthPatients', {
										length: requests.length,
										patients: intl.formatMessage({ id: requests.length === 1 ? 'onePatient' : 'multiplePatients' }).toLowerCase(),
									})}
								</h3>
							</div>
						)}
						<div className='waiting-room-list'>
							<CardRequest
								requests={requests.filter(item => item.request?.requestConference)}
								joinCall={joinCall}
								rejectRequest={rejectRequest}
							/>
						</div>
						{hasRequestConference && (
							<Pagination
								totalCount={pagination.totalCount}
								pageSize={pagination.pageSize}
								pageIndex={pagination.pageIndex}
								onChange={(pageSize, pageIndex) => {
									setIsLoading(true);
									getRequests(pageSize, pageIndex);
								}}
							/>
						)}
					</div>
				)}
				{!isLoading && (requests.length === 0 || requests.every(item => !item.request?.requestConference)) && (
					<div className='empty-state-wrapper'>
						<EmptyState title={translate('noRequestsFound')} image='no-requests.svg' />
					</div>
				)}
				{isLoading && (
					<Grid columns='1fr' rows='1fr' stretch='calc(100vh - 200px)' horizAlign='center' vertAlign='center'>
						<div style={{ textAlign: 'center' }}>
							<Loader />
						</div>
					</Grid>
				)}
				<Alert display={errorApiResponse} fixed={true} hideCloseButton={true} message={errorApiResponse} variant='dark' />
			</div>
		</MainLayout>
	);
};

export default SessionsQueue;
