import { clinicalTablesApi, gatewayApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';

export const getClinicalDiagnosesHS = async searchValue => {
	try {
		const { data } = await gatewayApi.get(`/v1/healthcare/medical-visits/icd-10?search=${searchValue}`);
		return data.icdCodeList;
	} catch (error) {
		return { error };
	}
};

export const getClinicalProcedures = async searchValue => {
	try {
		const { data } = await clinicalTablesApi.get(`/hcpcs/v3/search?sf=code,name&terms=${searchValue}`);
		return data[3].map(item => ({ code: item[0], description: item[1] }));
	} catch (error) {
		return { error };
	}
};

export const getClinicalDiagnoses = async searchValue => {
	try {
		const { data } = await clinicalTablesApi.get(`/icd10cm/v3/search?terms=${searchValue}&sf=code,name`);
		return data[3].map(item => ({ code: item[0], description: item[1] }));
	} catch (error) {
		return { error };
	}
};

export const getMedicinesAndStrengths = async searchValue => {
	try {
		const { data } = await clinicalTablesApi.get(`/rxterms/v3/search?terms=${searchValue}&ef=STRENGTHS_AND_FORMS,RXCUIS`);
		if (!data[2].RXCUIS) {
			return [];
		}
		return data[2]?.RXCUIS?.[0].map((item, index) => ({
			rxCuisCode: item,
			strengthAndForm: data[2]?.STRENGTHS_AND_FORMS?.[0][index],
			name: data[1]?.[0],
		}));
	} catch (error) {
		return { error };
	}
};

export const createVisit = async ({ patientId, doctorId, place, conferenceId = null }) => {
	try {
		const { data } = await gatewayApi.post(`/v1/healthcare/medical-visits`, {
			patientId,
			doctorId,
			place,
			conferenceId,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const deactivateVisit = async visitId => {
	try {
		const { data } = await gatewayApi.patch(`/v1/healthcare/medical-visits/${visitId}/deactivate`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getVisitSummary = async visitId => {
	try {
		const { data } = await gatewayApi.get(`/v1/healthcare/medical-visits/${visitId}/summary`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const createMedicalInfo = async ({ visitId, code, description, type }) => {
	try {
		const { data } = await gatewayApi.post(`/v1/healthcare/medical-visits/${visitId}/${type}`, {
			code: code,
			description: description,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const createNote = async ({ visitId, title, text }) => {
	try {
		const { data } = await gatewayApi.post(`/v1/healthcare/medical-visits/${visitId}/notes`, {
			title: title,
			text: text,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const createEditPrescription = async (params, visitId) => {
	try {
		const putOrPost = !params.id ? 'post' : 'put';
		const { data } = await gatewayApi[putOrPost](
			`/v1/healthcare/medical-visits/${visitId}/prescriptions${params.id ? `/${params.id}` : ''}`,
			params
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const deleteMedicalInfo = async ({ visitId, id, type }) => {
	try {
		const { data } = await gatewayApi.delete(`/v1/healthcare/medical-visits/${visitId}/${type}/${id}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getMedicalInfo = async ({ pageSize, pageIndex, userId, type, patientId = null }) => {
	try {
		const { data } = await gatewayApi.get(
			`/v1/healthcare/users/${userId}/medical-visits/${type}?pageIndex=${pageIndex}&pageSize=${pageSize}${
				patientId ? `&patientId=${patientId}` : ''
			}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPrescriptions = async ({ userId, patientId, pageIndex, pageSize }) => {
	try {
		const { data } = await gatewayApi.get(
			`/v1/healthcare/users/${userId}/medical-visits/prescriptions?pageIndex=${pageIndex}&pageSize=${pageSize}${
				patientId ? `&patientId=${patientId}` : ''
			}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getVisits = async ({ userId, patientId, role, pageIndex, pageSize = 10 }) => {
	try {
		const { data } = await gatewayApi.get(
			`v1/healthcare/users/${userId}/medical-visits?pageIndex=${pageIndex}&pageSize=${pageSize}
			${patientId ? `&patientId=${patientId}` : ''}
			${role ? `&role=${role}` : ''}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPatientVisits = async ({ userId, patientId, pageIndex, pageSize = 10 }) => {
	try {
		const { data } = await gatewayApi.get(
			`v1/healthcare/organizations/${getCompanyId()}/users/${userId}/patients/${patientId}/medical-visits?page-index=${pageIndex}&page-size=${pageSize}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getVisitDetails = async ({ userId, patientId, visitId, pageIndex, pageSize = 10 }) => {
	try {
		const { data } = await gatewayApi.get(
			`v1/healthcare/organizations/${getCompanyId()}/users/${userId}/patients/${patientId}/medical-visits/${visitId}?page-index=${pageIndex}&page-size=${pageSize}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const saveVisitEndDateTime = async visitId => {
	try {
		const { data } = await gatewayApi.patch(`/v1/healthcare/medical-visits/${visitId}/ended-at`);
		return data;
	} catch (error) {
		return { error };
	}
};
