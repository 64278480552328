import { Cam, LocalTrackAdded, LocalTrackController } from '@solaborate/calls/webrtc';
import WebGLTrack from 'calls/WebGLTrack.js';

class WebGLTrackController extends LocalTrackController {
	background = null;

	constructor(connection, factory, mediaDeviceController) {
		super(connection, factory, mediaDeviceController);
	}

	async add(trackType) {
		const req = Array.isArray(trackType) ? trackType : [trackType];
		const tracks = await this.factory.createTracks(req);

		const videoTrackIdx = tracks.findIndex(t => t.type === Cam);
		if (videoTrackIdx !== -1 && this.background !== null) {
			const prevTrack = this.tracks[Cam];
			if (prevTrack instanceof WebGLTrack) {
				if (prevTrack.originalTrack === tracks[videoTrackIdx]) {
					delete tracks[videoTrackIdx];
				} else {
					prevTrack.stop();
					tracks[videoTrackIdx] = new WebGLTrack(tracks[videoTrackIdx], this.background);
				}
			} else {
				tracks[videoTrackIdx] = new WebGLTrack(tracks[videoTrackIdx], this.background);
			}
		}

		tracks.forEach(t => {
			this.tracks[t.type] = t;
			this.fire(new LocalTrackAdded(t));
			t.track.onended = () => {
				t.stop();
				this.remove(t.type);
			};
		});
		await this.connection.negotiate(Object.values(this.tracks));
	}

	async remove(trackType) {
		const req = Array.isArray(trackType) ? trackType : [trackType];
		req.forEach(type => {
			if (type === Cam) {
				const videoTrack = this.tracks[Cam];
				if (videoTrack instanceof WebGLTrack) {
					this.background = videoTrack.background;
					videoTrack.cleanupPipeline();
				} else {
					this.background = null;
				}
			}
		});
		await super.remove(trackType);
	}

	async setBackground(background) {
		this.background = background;
		const videoTrack = this.tracks[Cam];

		if (!videoTrack) {
			this.add([Cam]);
			return;
		} else if (!(videoTrack instanceof WebGLTrack)) {
			this.tracks[Cam] = new WebGLTrack(videoTrack, background);
			this.fire(new LocalTrackAdded(this.tracks[Cam]));
		} else {
			videoTrack.setupPipeline(background);
		}
		await this.connection.negotiate(Object.values(this.tracks));
	}
}

export default WebGLTrackController;
