import { useContext, useEffect, useState } from 'react';
import { Button, CustomDropdown } from 'components/index.js';
import FormInput from 'components/Common/FormInput.jsx';
import translate from 'i18n-translations/translate.jsx';
import { useIntl } from 'react-intl';
import {
	addNewVerbalRedirection,
	addOrUpdateVerbalRedirectionLanguages,
	deleteVerbalRedirection,
	getAllVerbalRedirectionLanguages,
	getHealthSystemVerbalRedirectionLanguages,
	getVerbalRedirections,
	addOrUpdateQuickVerbalRedirect,
} from 'api/verbalRedirections.js';
import classNames from 'classnames';
import { containsLetters } from 'infrastructure/helpers/commonHelpers.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import { TaskStatus } from 'constants/enums.js';
import SocketEvents from 'constants/socket-events.js';

const VerbalRedirectionsAdmin = ({ healthSystemId, setError, shouldShowExpanded }) => {
	const intl = useIntl();
	const socket = useContext(SocketContext);
	const [newVerbalRedirection, setNewVerbalRedirection] = useState('');
	const [verbalRedirections, setVerbalRedirections] = useState([]);
	const [allLanguages, setAllLanguages] = useState([]);
	const [mySelectedLanguages, setMySelectedLanguages] = useState([]);
	const [isSubmitLanguagesLoading, setIsSubmitLanguagesLoading] = useState(false);
	const [verbalRedirectionsError, setVerbalRedirectionsError] = useState('');
	const [taskId, setTaskId] = useState(null);
	const [languagesError, setLanguagesError] = useState('');
	const [isButtonLoading, setIsButtonLoading] = useState(false);

	const fetchVerbalRedirections = async () => {
		const response = await getVerbalRedirections(healthSystemId);
		if (response.error) {
			setError(intl.formatMessage({ id: 'somethingWentWrong' }));
			return;
		}
		setVerbalRedirections(response.healthSystemVerbalRedirections.sort((a, b) => b.isQuickRedirect - a.isQuickRedirect));
	};

	useEffect(() => {
		const fetchAllLanguages = async () => {
			const response = await getAllVerbalRedirectionLanguages();
			if (response.error) {
				setError(intl.formatMessage({ id: 'somethingWentWrong' }));
				return;
			}
			const mapped = response.languages.map(item => ({
				...item,
				value: item.id,
				label: item.localeName,
			}));
			setAllLanguages(mapped);
		};
		fetchAllLanguages();
	}, [intl]);

	useEffect(() => {
		if (healthSystemId) {
			fetchVerbalRedirections();
		}
	}, [intl, healthSystemId]);

	useEffect(() => {
		const fetchSelectedLanguages = async () => {
			const response = await getHealthSystemVerbalRedirectionLanguages(healthSystemId);
			if (response.error) {
				setError(intl.formatMessage({ id: 'somethingWentWrong' }));
				return;
			}

			let mapped = [];

			if (response.languages.length === 0) {
				const defaultLang = allLanguages.find(item => item.isDefault);
				if (defaultLang) {
					mapped = [{ ...defaultLang, value: defaultLang.id, label: defaultLang.localeName }];
				}
			} else {
				mapped = response.languages.map(item => ({
					...item,
					value: item.id,
					label: item.localeName,
				}));
			}

			setMySelectedLanguages(mapped);
		};

		if (healthSystemId && allLanguages.length > 0) {
			fetchSelectedLanguages();
		}
	}, [intl, healthSystemId, allLanguages]);

	const saveVerbalRedirection = async () => {
		setVerbalRedirectionsError('');
		const maxOptions = 30;
		if (!newVerbalRedirection) {
			return;
		}

		if (verbalRedirections.find(item => item.title.toLowerCase() === newVerbalRedirection.toLowerCase())) {
			setVerbalRedirectionsError(intl.formatMessage({ id: 'optionAlreadyExists' }));
			return;
		}

		if (!containsLetters(newVerbalRedirection)) {
			setVerbalRedirectionsError(intl.formatMessage({ id: 'descriptionMustHaveLetter' }));
			return;
		}

		if (verbalRedirections.length >= maxOptions) {
			setVerbalRedirectionsError(intl.formatMessage({ id: 'maxNumberOfOptionsReached' }));
			return;
		}
		setIsButtonLoading(true);
		const response = await addNewVerbalRedirection(healthSystemId, newVerbalRedirection);
		if (response.error) {
			setError(intl.formatMessage({ id: 'somethingWentWrong' }));
			setIsButtonLoading(false);
			return;
		}
		setVerbalRedirections(prevState => [...prevState, { id: newVerbalRedirection, title: newVerbalRedirection }]);
		setTaskId(response.taskId);
		setIsButtonLoading(false);
		setNewVerbalRedirection('');
	};

	useEffect(() => {
		const handleTaskUpdated = data => {
			if (data.taskId === taskId && [TaskStatus.COMPLETED, TaskStatus.FAULTED].includes(data.taskStatusId)) {
				fetchVerbalRedirections();
				setTaskId(null);
			}
		};

		socket.on(SocketEvents.BACKGROUND.TASK_UPDATED, handleTaskUpdated);
		return () => {
			socket.off(SocketEvents.BACKGROUND.TASK_UPDATED, handleTaskUpdated);
		};
	}, [socket, taskId]);

	const deleteOneVerbalRedirection = async id => {
		setVerbalRedirectionsError('');
		if (!id) {
			return;
		}
		if (verbalRedirections.length <= 1) {
			setVerbalRedirectionsError(intl.formatMessage({ id: 'atLeastOneMustBeSet' }));
			return;
		}
		const response = await deleteVerbalRedirection(healthSystemId, id);
		if (response.error) {
			setError(intl.formatMessage({ id: 'somethingWentWrong' }));
			return;
		}
		setVerbalRedirections(prevState => prevState.filter(item => item.id !== id));
	};

	const updateQuickVerbalRedirect = async id => {
		if (!id || !healthSystemId) {
			return;
		}
		const item = verbalRedirections.find(verbalRedirection => verbalRedirection.id === id);
		const response = await addOrUpdateQuickVerbalRedirect(healthSystemId, id, !item.isQuickRedirect);
		if (response.error) {
			setError(intl.formatMessage({ id: 'somethingWentWrong' }));
			return;
		}
		await fetchVerbalRedirections();
	};

	const submitSelectedLanguages = async () => {
		setIsSubmitLanguagesLoading(true);
		setLanguagesError('');

		const mapped = mySelectedLanguages.map(({ value, label, ...rest }) => ({
			...rest,
		}));
		if (mapped.length === 0) {
			setLanguagesError(intl.formatMessage({ id: 'atLeastOneMustBeSet' }));
			return;
		}
		const response = await addOrUpdateVerbalRedirectionLanguages(healthSystemId, mapped);
		if (response.error) {
			setError(intl.formatMessage({ id: 'somethingWentWrong' }));
		}
		setIsSubmitLanguagesLoading(false);
	};

	if (!shouldShowExpanded) {
		return <></>;
	}

	return (
		<>
			<div className='position-relative feature-flag-options flex'>
				<p>{translate('languages')}</p>
				<CustomDropdown
					defaultOptions={mySelectedLanguages}
					initialOptions={allLanguages}
					onSelect={setMySelectedLanguages}
					title={intl.formatMessage({ id: 'languages' })}
					placeholder={intl.formatMessage({ id: 'languages' })}
					isDarkModeCompatible={false}
				/>
				{languagesError && <p className='--red-color error'>{languagesError}</p>}
				<Button
					type='button'
					onClick={submitSelectedLanguages}
					className={classNames('align-self-end', { disabled: mySelectedLanguages.length === 0 })}
					text={translate('submit')}
					isLoading={isSubmitLanguagesLoading}
				/>
			</div>
			<div className='position-relative feature-flag-options flex'>
				<p>{translate('verbalRedirection')}</p>
				<ul>
					{verbalRedirections.length === 0 && <li>{intl.formatMessage({ id: 'noAddedVerbalRedirectionSounds' })}</li>}
					{verbalRedirections.map((item, index) => (
						<li key={item.id} className={!item?.sounds && 'disabled'}>
							{item.title}
							<div>
								<Button
									position={index === 0 ? 'bottom-left' : 'top-left'}
									tooltip={intl.formatMessage({
										id: item.isQuickRedirect ? 'removeQuickRedirect' : 'setAsQuickRedirect',
									})}
									icon={item.isQuickRedirect ? 'bookmark_added' : 'bookmark_add'}
									iconType={item.isQuickRedirect}
									className={classNames('quick-verbal-redirection', { show: item.isQuickRedirect })}
									onClick={() => updateQuickVerbalRedirect(item.id)}
								/>
								<Button
									position={index === 0 ? 'bottom-left' : 'top-left'}
									tooltip={intl.formatMessage({ id: 'removeVerbalRedirection' })}
									icon='delete'
									onClick={() => deleteOneVerbalRedirection(item.id)}
								/>
							</div>
						</li>
					))}
				</ul>
				<FormInput
					labelClassName='feature-flag-txt-label'
					id='verbalRedirection'
					placeholder={intl.formatMessage({ id: 'writeNewVerbalRedirection' })}
					type='text'
					value={newVerbalRedirection}
					onChange={e => setNewVerbalRedirection(e.target.value)}
					className='text-input feature-flag-txt-input'
					maxLength={127}
				/>
				{verbalRedirectionsError && <p className='--red-color error'>{verbalRedirectionsError}</p>}
				<Button
					type='button'
					onClick={saveVerbalRedirection}
					className={classNames('align-self-end', { disabled: !newVerbalRedirection })}
					text={translate('addNew')}
					isDisabled={!newVerbalRedirection || isButtonLoading}
					isLoading={isButtonLoading}
				/>
			</div>
		</>
	);
};

export default VerbalRedirectionsAdmin;
