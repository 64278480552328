import { useDispatch } from 'react-redux';
import Modal from 'components/Common/Modal.jsx';
import { StreamError } from 'constants/enums.js';
import Form from 'components/Common/Form.jsx';
import translate from 'i18n-translations/translate.jsx';

const StreamPermissionsModal = ({ setStreamPermissionMessage, reason }) => {
	const dispatch = useDispatch();
	return (
		<Modal
			display={true}
			className='permission-modal'
			position='center'
			closeButtonText='Dismiss'
			primaryButtonLabel=''
			onModalClose={() => {
				dispatch(setStreamPermissionMessage(null));
			}}>
			{(reason.type === StreamError.CAMERA_BLOCKED.type ||
				reason.type === StreamError.MICROPHONE_BLOCKED.type ||
				reason.type === StreamError.CAM_AND_MIC_BLOCKED.type) && (
				<Form
					title={
						reason.type === StreamError.CAM_AND_MIC_BLOCKED.type
							? translate('cameraAndMicrophoneBlockedTitle')
							: (reason.type === StreamError.CAMERA_BLOCKED.type && translate('cameraBlockedTitle')) ||
							  (reason.type === StreamError.MICROPHONE_BLOCKED.type && translate('microphoneBlockedTitle'))
					}>
					<p>
						{reason.type === StreamError.CAM_AND_MIC_BLOCKED.type
							? translate('cameraAndMicrophoneBlockedMessage')
							: (reason.type === StreamError.CAMERA_BLOCKED.type && translate('cameraBlockedMessage')) ||
							  (reason.type === StreamError.MICROPHONE_BLOCKED.type && reason.message
									? translate(reason.message)
									: translate('microphoneBlockedMessage'))}
					</p>
				</Form>
			)}
			{(reason.type === StreamError.MICROPHONE_NOT_FOUND.type || reason.type === StreamError.CAMERA_NOT_FOUND.type) && (
				<Form
					title={
						reason.type === StreamError.MICROPHONE_NOT_FOUND.type
							? translate('cantFindMicrophoneTitle')
							: translate('cantFindCameraTitle')
					}>
					<p>
						{reason.type === StreamError.MICROPHONE_NOT_FOUND.type
							? translate('cantFindMicrophoneMessage')
							: translate('cantFindCameraMessage')}
					</p>
				</Form>
			)}
			{(reason.type === StreamError.MICROPHONE_BLOCKED_GENERIC.type ||
				reason.type === StreamError.CAMERA_BLOCKED_GENERIC.type) && (
				<Form
					title={
						reason.type === StreamError.MICROPHONE_BLOCKED_GENERIC.type
							? translate('microphoneBlockedTitleGeneric')
							: translate('cameraBlockedTitleGeneric')
					}>
					<p>
						{reason.type === StreamError.MICROPHONE_BLOCKED_GENERIC.type
							? translate('microphoneBlockedMessageGeneric')
							: translate('cameraBlockedMessageGeneric')}
					</p>
				</Form>
			)}
		</Modal>
	);
};

export default StreamPermissionsModal;
