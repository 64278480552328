import React, { useState, useEffect, useContext } from 'react';
import { ChatOptionsViews } from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { getConversationLinks } from 'api/messenger.js';
import { EmptyState } from 'components/index.js';
import translate from 'i18n-translations/translate.jsx';
import PopUpAlert from 'components/Common/PopUpAlert.jsx';
import SocketEvents from 'constants/socket-events.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import { getDateDifferenceLabel } from 'infrastructure/helpers/dateHelper.js';
import { groupedAttachments } from 'infrastructure/helpers/commonHelpers.js';
import Loader from 'components/Common/Loader.jsx';

const Links = props => {
	const [links, setLinks] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const socket = useContext(SocketContext);

	useEffect(() => {
		const fetchLinks = async () => {
			const response = await getConversationLinks(props.conversationId);
			if (response.error) {
				setError(response.error.message);
				setIsLoading(false);
			} else {
				const links = response.links.map(attachment => ({
					...attachment,
					dateDifference: getDateDifferenceLabel(attachment.dateCreated),
				}));
				setLinks(links);
				setIsLoading(false);
			}
		};
		fetchLinks();
	}, [props.conversationId]);

	useEffect(() => {
		const handleNewMessage = data => {
			if (props.conversationId === data.conversationId && data.message?.links?.length > 0) {
				data.message?.links.forEach(link => {
					setLinks(prevState => [
						{ ...link, dateDifference: getDateDifferenceLabel(link.dateCreated), sender: data.message.sender },
						...prevState,
					]);
				});
			}
		};

		socket.on(SocketEvents.Conversation.ON_NEW_MESSAGE, handleNewMessage);

		return () => {
			socket.off(SocketEvents.Conversation.ON_NEW_MESSAGE, handleNewMessage);
		};
	}, [props.conversationId, socket]);

	const redirect = link => {
		window.open(processLink(link), '_blank');
	};

	const processLink = link => {
		return link.indexOf('http://') !== -1 || link.indexOf('https://') !== -1 ? link : `http://${link}`;
	};

	return (
		<>
			<div className='chat-options-wrapper position-relative'>
				{!isLoading && (
					<div className='flex'>
						<div className='cursor-pointer' onClick={() => props.setDefaultOptions(ChatOptionsViews.DEFAULT)}>
							<img src={`${healthCareCdnUrl}chat/back.svg`} alt='back arrow icon' />
						</div>
						<div>
							<p>{translate('links')} </p>
							<p> {translate('sharedLinks')}</p>
						</div>
					</div>
				)}
				{links.length > 0 && !isLoading && (
					<div className='links-list'>
						{Object.keys(groupedAttachments(links)).map(key => (
							<div key={key}>
								{props.isFromDoctor && <p className='semi-bold'>{translate(key)}</p>}
								{groupedAttachments(links)[key].map(item => (
									<div key={item.id} onClick={() => redirect(item.link)}>
										<p className='cursor-pointer --blue-color'>{item.link}</p>
									</div>
								))}
							</div>
						))}
					</div>
				)}
				{links.length === 0 && !isLoading && (
					<div className='empty-state-wrapper-percent'>
						<EmptyState title={translate('noData')} image='no-files.svg' />
					</div>
				)}
				{isLoading && (
					<div className='empty-state-wrapper-percent'>
						<Loader />
					</div>
				)}
			</div>
			<PopUpAlert display={error} onAlertClose={() => setError(null)} contentText={error} isSilent={true} center={true} />
		</>
	);
};

export default Links;
