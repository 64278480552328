import { getUserIdleConfigurations, updateUserIdleConfiguration } from 'api/userIdleConfigurations.js';
import Button from 'components/Common/Button.jsx';
import Pagination from 'components/Common/Pagination.jsx';
import UserIdleForm from 'components/Configurations/UserIdleForm.jsx';
import CustomTable from 'components/Common/CustomTable.jsx';
import Grid from 'components/Common/Grid.jsx';
import { ConfigHistoryTypes } from 'constants/configurationEnums.js';
import { CompanyRoles, UserRoles } from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { getUserRole } from 'infrastructure/auth.js';
import { formattedDate } from 'infrastructure/helpers/dateHelper.js';
import { getRoleValueById, getUserTypeBasedOnCompanyRole } from 'infrastructure/helpers/commonHelpers.js';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import TableHistoryButton from 'icons/Admin/TableHistoryButton.jsx';
import TableEditButton from 'icons/Admin/TableEditButton.jsx';
import { useSelector } from 'react-redux';

const UserIdleConfiguration = props => {
	const intl = useIntl();
	const role = getUserRole();
	const companySettings = useSelector(state => state.company.companySettings);
	const [isLoading, setLoading] = useState(true);
	const [isModalOpen, setModalOpen] = useState(false);
	const [configs, setConfigs] = useState({
		userIdleConfigurations: [],
		total: 0,
	});
	const [editConfig, setEditConfig] = useState(null);

	const rolesOptions = [
		role === UserRoles.ADMIN && { id: '0', value: '0', label: intl.formatMessage({ id: 'all' }) },
		{ id: CompanyRoles.ADMIN, value: CompanyRoles.ADMIN, label: intl.formatMessage({ id: 'admin' }) },
		{
			id: CompanyRoles.VIRTUAL_CARE_PROVIDER,
			value: CompanyRoles.VIRTUAL_CARE_PROVIDER,
			label: companySettings.nurseDisplayName,
		},
		{
			id: CompanyRoles.VIRTUAL_SITTER,
			value: CompanyRoles.VIRTUAL_SITTER,
			label: companySettings.virtualSitterDisplayName,
		},
		{ id: CompanyRoles.SUPER_USER, value: CompanyRoles.SUPER_USER, label: intl.formatMessage({ id: 'superUser' }) },
		{
			id: CompanyRoles.DIGITAL_CLINICIAN,
			value: CompanyRoles.DIGITAL_CLINICIAN,
			label: intl.formatMessage({ id: 'digitalClinician' }),
		},
		{ id: CompanyRoles.DOCTOR, value: CompanyRoles.DOCTOR, label: intl.formatMessage({ id: 'doctor' }) },
		{ id: CompanyRoles.VISITOR, value: CompanyRoles.VISITOR, label: intl.formatMessage({ id: 'visitor' }) },
	].filter(Boolean);

	useEffect(() => {
		const getConfigurations = async (isSubmitted = false) => {
			if (props.isLoading) {
				return;
			}
			if (isSubmitted) {
				setLoading(true);
			}
			const { userIdleConfigurations, total, error } = await getUserIdleConfigurations({
				pageSize: props.pagination.size,
				pageIndex: props.pagination.index,
				healthSystemId: props.selectedHealthSystem.value === '0' ? null : props.selectedHealthSystem.value,
				roleId: props.selectedRole.value === '0' ? null : props.selectedRole.value,
			});

			if (!error) {
				setConfigs({ userIdleConfigurations, total });
			}
			setLoading(false);
		};
		getConfigurations();
	}, [props.pagination.index, props.pagination.size, props.selectedHealthSystem, props.isLoading, isLoading, props.selectedRole]);

	const onHealthSystemSelected = selectedHealthSystem => {
		setLoading(true);
		props.setSelectedHealthSystem(selectedHealthSystem);
	};

	const onRoleSelected = async selectedRole => {
		setLoading(true);
		props.setSelectedRole(selectedRole);
	};

	const onPaginationChange = value => {
		setLoading(true);
		props.onPaginationChange(value);
	};

	const transformArray = array => array.map(item => ({ id: item.id, value: item.label }));

	const transformArrayWithLabels = array => {
		const newArray = array.map(item => ({ value: item.id, label: item.value }));
		if (role === UserRoles.ADMIN) {
			newArray.unshift({ value: '0', label: intl.formatMessage({ id: 'all' }) });
		}
		return newArray;
	};

	const openEditModal = configuration => {
		setEditConfig({
			healthSystem: configuration.team.id,
			role: configuration.role.id,
			minutes: configuration.timeoutMinutes,
		});
		setModalOpen(true);
	};

	const handleTimeTrackerSwitch = async config => {
		const response = await updateUserIdleConfiguration(config.id, !config.isEnabled);
		if (!response.error) {
			setConfigs(prevState => {
				const configsCopied = _.cloneDeep(prevState);
				const findIndex = configsCopied.userIdleConfigurations.findIndex(item => config.id === item.id);
				configsCopied.userIdleConfigurations[findIndex].isEnabled = !config.isEnabled;
				return configsCopied;
			});
		} else {
			props.setError(response.error.message);
		}
	};

	const getButtons = configuration => {
		return (
			<div className='wrapped idle-configs-button'>
				<span
					className='cursor-pointer flex'
					data-tooltip={intl.formatMessage(
						{ id: 'timeTrackerValue' },
						{ value: intl.formatMessage({ id: !configuration.isEnabled ? 'enable' : 'disable' }) }
					)}
					data-position='top'
					onClick={() => handleTimeTrackerSwitch(configuration)}>
					<img src={`${healthCareCdnUrl}admin/time-tracker-${configuration.isEnabled ? 'off' : 'on'}.svg`} alt='icon' />
				</span>
				<Link to={`/configurations/${configuration.id}/type/${ConfigHistoryTypes.UserIdle}`} onClick={props.onHistoryClick}>
					<div
						className='material-icons-outlined boxed-icon view-history-icon'
						data-tooltip={intl.formatMessage({ id: 'viewHistory' })}
						data-position='top'>
						<TableHistoryButton />
					</div>
				</Link>
				<TableEditButton onClick={() => openEditModal(configuration)} />
			</div>
		);
	};
	const getRoleName = roleId => (roleId ? getRoleValueById(getUserTypeBasedOnCompanyRole(roleId), companySettings) : 'N/A');

	const getTableConfigurations = () => {
		if (isLoading) {
			return [];
		}
		return configs.userIdleConfigurations.map(item => ({
			id: item.id,
			healthSystem: item.team.name,
			role: getRoleName(item.role.id),
			timeoutMinutes: item?.isEnabled ? item.timeoutMinutes : intl.formatMessage({ id: 'disabled' }),
			createdBy: `${item.userCreated.firstName} ${item.userCreated.lastName}`,
			dateCreated: formattedDate(item.dateCreated),
			edit: getButtons(item),
		}));
	};

	return (
		<div>
			<CustomTable
				isLoading={isLoading}
				headers={[
					{ title: intl.formatMessage({ id: 'healthSystem' }), id: 'healthSystem' },
					{ title: intl.formatMessage({ id: 'role' }), id: 'role' },
					{ title: intl.formatMessage({ id: 'minutes' }), id: 'timeoutMinutes' },
					{ title: intl.formatMessage({ id: 'createdBy' }), id: 'createdBy' },
					{ title: intl.formatMessage({ id: 'dateCreated' }), id: 'dateCreated' },
					{ title: '', id: 'edit' },
				]}
				rows={getTableConfigurations()}>
				<Grid columns='1fr 1fr 2fr' gridGap='10px' vertAlign='center'>
					<Select
						value={props.selectedHealthSystem}
						placeholder={intl.formatMessage({ id: 'selectHealthSystem' })}
						isDisabled={role === UserRoles.SUPER_USER}
						classNamePrefix='react-select'
						options={transformArrayWithLabels(props.healthSystems)}
						onChange={hs => onHealthSystemSelected(hs)}
					/>
					<Select
						value={props.selectedRole}
						placeholder={`${intl.formatMessage({ id: 'selectRole' })}...`}
						classNamePrefix='react-select'
						options={rolesOptions}
						onChange={selectedRole => onRoleSelected(selectedRole)}
					/>
					<Button
						className='text-transform-none'
						text={intl.formatMessage({ id: 'setTimeOut' })}
						horizAlign='end'
						onClick={() => setModalOpen(true)}
					/>
				</Grid>
			</CustomTable>
			{!isLoading && (
				<Pagination
					totalCount={configs.total}
					pageSize={props.pagination.size}
					pageIndex={props.pagination.index}
					onChange={(size, index) => onPaginationChange({ size, index })}
				/>
			)}
			<UserIdleForm
				isModalOpen={isModalOpen}
				initialValues={editConfig || null}
				isSuperUser={role === UserRoles.SUPER_USER}
				healthSystems={props.healthSystems}
				toggleModal={() => {
					setModalOpen(!isModalOpen);
					setEditConfig(null);
				}}
				onSucceeded={() => setLoading(true)}
				roles={transformArray(rolesOptions)}
			/>
		</div>
	);
};

export default UserIdleConfiguration;
