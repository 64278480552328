import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import Modal from 'components/Common/Modal.jsx';
import Form from 'components/Common/Form.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import ProfilePicture from 'components/Common/ProfilePicture.jsx';
import { downloadFileFromUrl, getAttachmentIcon, getPreferredLanguageLocale } from 'infrastructure/helpers/commonHelpers.js';
import { getMediaCdnURL } from 'infrastructure/helpers/mediaHelper.js';
import { AppointmentStatus } from 'constants/enums.js';
import { getAppointmentRequestDetails } from 'api/appointments.js';
import Alert from 'components/Common/Alert.jsx';
import Loader from 'components/Common/Loader.jsx';

const AppointmentDetailsModal = props => {
	const [appointmentDetails, setAppointmentDetails] = useState(null);
	const [isPending, setIsPending] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState('');
	const intl = useIntl();

	useEffect(() => {
		const getAppointmentDetails = async () => {
			const response = await getAppointmentRequestDetails(props.appointmentId);
			if (response.error) {
				setError(response.error.message);
			} else {
				setAppointmentDetails(response.appointmentDetails);
				setIsPending(response.appointmentDetails.statusId === AppointmentStatus.PENDING);
			}
			setIsLoading(false);
		};
		getAppointmentDetails();
	}, [props.appointmentId]);

	const toDataURL = async url => {
		const response = await fetch(url);
		const blob = await response.blob();
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onloadend = () => resolve(reader.result);
			reader.onerror = reject;
			reader.readAsDataURL(blob);
		});
	};

	const downloadBlob = async attachment => {
		downloadFileFromUrl(await toDataURL(getMediaCdnURL(attachment.fileName, attachment.fileTypeId)), attachment.originalFileName);
	};
	return (
		<>
			{isLoading && (
				<div className='patient-request-loader'>
					<Loader />
				</div>
			)}
			{!isLoading && (
				<>
					<Modal
						display={props.display}
						onModalSubmit={
							isPending
								? event => props.updateAppointmentStatus(props.appointmentId, AppointmentStatus.ACCEPTED, event?.target)
								: props.onModalClose
						}
						onModalClose={
							isPending
								? event => props.updateAppointmentStatus(props.appointmentId, AppointmentStatus.REJECTED, event?.target)
								: props.onModalClose
						}
						primaryButtonLabel={intl.formatMessage({ id: isPending ? 'acceptRequest' : 'done' })}
						closeButtonText={isPending && intl.formatMessage({ id: 'rejectRequest' })}
						closeImgIcon={`${healthCareCdnUrl}appointments/cancel-appointment.svg`}
						position='center'
						isLoading={props.isLoading}
						shouldSubmitOnEnter={false}
						className={classNames(
							'wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal',
							'saved-successfully',
							'no-padding-appointment-modal',
							isPending ? 'app-details' : ''
						)}>
						<Form height={550} className='modal-form-wrapper saved-successfully-details-form'>
							<h3>{translate('appointmentDetail')}</h3>
							<br />
							<div>
								<p>{translate('topic')}</p>
								<p className='appointment-details-topic'>{appointmentDetails.title}</p>
							</div>
							<div>
								<p>{translate('invitee')}</p>
								<div className='invitee-details'>
									<ProfilePicture
										fullName={`${appointmentDetails.appointmentInvitation.invitedUser.firstName} ${appointmentDetails.appointmentInvitation.invitedUser.lastName}`}
										profilePicture={appointmentDetails.appointmentInvitation.invitedUser?.profilePicture}
										className='doctor-request-img appointment-img'
									/>
									<div>
										<p>{`${appointmentDetails.appointmentInvitation.invitedUser.firstName} ${appointmentDetails.appointmentInvitation.invitedUser.lastName}`}</p>
										{appointmentDetails.appointmentInvitation.invitedUser.specialty && (
											<p className='doctor-specialty'>{appointmentDetails.appointmentInvitation.invitedUser.specialty}</p>
										)}
									</div>
								</div>
							</div>
							<div>
								<p>{translate('appointmentDate')}</p>
								<p className='appointment-date-and-place'>
									{`${moment(appointmentDetails.appointmentSlot.startDateTime)
										.locale(getPreferredLanguageLocale())
										.local()
										.format('DD MMMM')}, `}
									{`${moment
										.utc(appointmentDetails.appointmentSlot.startDateTime)
										.local()
										.locale(getPreferredLanguageLocale())
										.format('hh:mm')} - `}
									{`${moment
										.utc(appointmentDetails.appointmentSlot.endDateTime)
										.local()
										.locale(getPreferredLanguageLocale())
										.format('hh:mm A')}`}
								</p>
							</div>
							{appointmentDetails.description && (
								<div>
									<p>{translate('description')}</p>
									<p className='appointment-details-description'>{appointmentDetails.description}</p>
								</div>
							)}
							{appointmentDetails.appointmentAttachments.length > 0 && (
								<div>
									<p>{translate('attachments')}</p>
									<div className='flex drag-drop-img-gallery'>
										{appointmentDetails.appointmentAttachments.map(attachment => (
											<div className='appointment-details-attachment cursor-pointer' onClick={() => downloadBlob(attachment)}>
												<img src={getAttachmentIcon(attachment.fileName)} alt='attachment-icon' />
												<p>{attachment.originalFileName}</p>
											</div>
										))}
									</div>
								</div>
							)}
						</Form>
					</Modal>
					<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
				</>
			)}
		</>
	);
};

export default AppointmentDetailsModal;
