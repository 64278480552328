import React, { useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, EmptyState, Grid, Loader } from 'components/index.js';
import translate from 'i18n-translations/translate.jsx';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import { getPatientQueue } from 'api/users.js';
import { getUserId } from 'infrastructure/auth.js';
import { updateMedicalVisitStatus } from 'api/visits.js';
import { QueueChangeType, VisitStatus } from 'constants/visitEnums.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SocketEvents from 'constants/socket-events.js';
import { capitalizeFirstLetter } from 'infrastructure/helpers/commonHelpers.js';
import { getRoomInformation } from 'infrastructure/helpers/visitsHelper.js';
import NextVisitConfirmationModal from 'containers/PrimaryCare/NextVisitConfirmationModal.jsx';
import { actionCreators as userActionCreators } from 'state/user/actions.js';

const InjectionWaitingRoom = () => {
	const intl = useIntl();
	const dispatch = useDispatch();
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [currentCase, setCurrentCase] = useState(null);
	const [caseQueue, setCaseQueue] = useState([]);
	const [isInviteLoading, setIsInviteLoading] = useState(false);
	const [isDidNotShowUpLoading, setIsDidNotShowUpLoading] = useState(false);
	const [isStartVisitLoading, setIsStartVisitLoading] = useState(false);
	const [isCompleteLoading, setIsCompleteLoading] = useState(false);
	const [nextVisit, setNextVisit] = useState(null);
	const { current: userId } = useRef(getUserId());
	const socket = useContext(SocketContext);
	const user = useSelector(state => state.user);
	const setUserWaitingRoomCount = count => dispatch(userActionCreators.setUserWaitingRoomCount(count));

	useEffect(() => {
		const fetchPatientQueue = async () => {
			const response = await getPatientQueue(userId);

			if (response.error) {
				setError(response.error.message);
				setIsLoading(false);
			} else {
				if (response.length > 0) {
					setUserWaitingRoomCount(response.length);
					const firstItem = response.shift();
					setCurrentCase(firstItem);
				}
				setCaseQueue(response);
				setIsLoading(false);
			}
		};
		fetchPatientQueue();
	}, [userId]);

	const handleStartVisit = async () => {
		setIsStartVisitLoading(true);
		const response = await updateMedicalVisitStatus(currentCase.activeVisit.id, { visitStatus: VisitStatus.IN_PROGRESS });
		if (response.error) {
			setError(response.error.message);
		} else {
			const activeCase = { ...currentCase };
			activeCase.activeVisit.medicalVisitStatusId = VisitStatus.IN_PROGRESS;
			setCurrentCase(activeCase);
		}
		setIsStartVisitLoading(false);
	};

	const handleInvitePatientToRoom = async () => {
		setIsInviteLoading(true);
		const visitStatus =
			currentCase.activeVisit.medicalVisitStatusId === VisitStatus.DID_NOT_SHOW_UP
				? VisitStatus.SECOND_INVITED
				: VisitStatus.INVITED;
		const response = await updateMedicalVisitStatus(currentCase.activeVisit.id, { visitStatus });
		if (response.error) {
			setError(response.error.message);
		} else {
			const activeCase = { ...currentCase };
			activeCase.activeVisit.medicalVisitStatusId = visitStatus;
			setCurrentCase(activeCase);
		}
		setIsInviteLoading(false);
	};

	const handleMarkAsTaken = async () => {
		setIsCompleteLoading(true);
		const response = await updateMedicalVisitStatus(currentCase.activeVisit.id, { visitStatus: VisitStatus.COMPLETED });
		if (response.error) {
			setError(response.error.message);
		} else {
			setNextVisit({
				...response,
				icon: response.nextVisitInfo?.visitType ? getRoomInformation(intl, response.nextVisitInfo?.visitType).image : '',
				roomType: response.nextVisitInfo?.visitType ? getRoomInformation(intl, response.nextVisitInfo?.visitType).roomType : '',
			});
			const caseQueueCopied = [...caseQueue];
			const queueToSet = caseQueueCopied.shift();
			setCurrentCase(queueToSet);
			setCaseQueue(caseQueueCopied);
			setUserWaitingRoomCount(user.waitingRoomCount - 1);
		}
		setIsCompleteLoading(false);
	};

	useEffect(() => {
		const handleQueueUpdated = item => {
			if (QueueChangeType.NEW === item.changeType) {
				if (!currentCase) {
					setCurrentCase(item.medicalVisit);
				} else {
					setCaseQueue(prevState => [...prevState, item.medicalVisit]);
				}
			}
			if (QueueChangeType.STATUS_CHANGED === item.changeType) {
				if (!currentCase || currentCase.caseId === item.medicalVisit.caseId) {
					setCurrentCase(item.medicalVisit);
				} else {
					setCaseQueue(prevState => [...prevState.filter(queue => queue.caseId !== item.medicalVisit.caseId), item.medicalVisit]);
					setUserWaitingRoomCount(user.waitingRoomCount - 1);
				}
			}
		};
		socket.on(SocketEvents.HealthCare.PATIENT_QUEUE_UPDATED, handleQueueUpdated);
		return () => {
			socket.off(SocketEvents.HealthCare.PATIENT_QUEUE_UPDATED, handleQueueUpdated);
		};
	}, [socket, currentCase]);

	const handlePatientDidNotShowUp = async () => {
		setIsDidNotShowUpLoading(true);
		const visitStatus =
			currentCase.activeVisit.medicalVisitStatusId === VisitStatus.SECOND_INVITED
				? VisitStatus.CANCELLED
				: VisitStatus.DID_NOT_SHOW_UP;

		const response = await updateMedicalVisitStatus(currentCase.activeVisit.id, { visitStatus });

		if (response.error) {
			setError(response.error.message);
			setIsDidNotShowUpLoading(false);
			return;
		}
		if (currentCase.activeVisit.medicalVisitStatusId === VisitStatus.SECOND_INVITED) {
			const caseQueueCopied = [...caseQueue];
			const queueToSet = caseQueueCopied.shift();
			setCurrentCase(queueToSet);
			setCaseQueue(caseQueueCopied);
			setIsDidNotShowUpLoading(false);
			setUserWaitingRoomCount(user.waitingRoomCount - 1);

			return;
		}
		if (caseQueue.length > 0) {
			const queue = getOrderedQueue();
			setCaseQueue(queue);
		} else {
			const currentCaseCopied = { ...currentCase };
			currentCaseCopied.activeVisit.medicalVisitStatusId = VisitStatus.DID_NOT_SHOW_UP;
			setCurrentCase(currentCaseCopied);
		}
		setIsDidNotShowUpLoading(false);
	};

	const getOrderedQueue = () => {
		const caseQueueCopied = [...caseQueue];
		const firstElement = caseQueueCopied.shift();
		const didNotShowUpCases = caseQueueCopied.filter(
			item => item.activeVisit.medicalVisitStatusId === VisitStatus.DID_NOT_SHOW_UP
		);
		const otherCases = caseQueueCopied.filter(item => item.activeVisit.medicalVisitStatusId !== VisitStatus.DID_NOT_SHOW_UP);
		const currentCaseCopied = { ...currentCase };
		currentCaseCopied.activeVisit.medicalVisitStatusId = VisitStatus.DID_NOT_SHOW_UP;
		setCurrentCase(firstElement);
		return [...didNotShowUpCases, currentCaseCopied, ...otherCases];
	};

	const NextVisit = props => {
		const { item } = props;
		return (
			<div key={item.caseId}>
				<div className='flex flex-align-center'>
					<div className='flex-1'>
						<div>
							<h4>{item.patient.fullName}</h4>
							<p>{item.patient.idCard}</p>
						</div>
					</div>
					<div className='flex' />
					<div className='flex' />
					{[VisitStatus.SECOND_INVITED, VisitStatus.INVITED].includes(currentCase.activeVisit.medicalVisitStatusId) && (
						<Button
							type='button'
							text={translate('patientNoShowUp')}
							className='--orange-background'
							onClick={handlePatientDidNotShowUp}
							isDisabled={isDidNotShowUpLoading}
							isLoading={isDidNotShowUpLoading}
						/>
					)}

					{[VisitStatus.PENDING, VisitStatus.DID_NOT_SHOW_UP].includes(currentCase.activeVisit.medicalVisitStatusId) && (
						<Button
							type='button'
							onClick={handleInvitePatientToRoom}
							text={translate('inviteToRoom')}
							isDisabled={isInviteLoading}
							isLoading={isInviteLoading}
						/>
					)}
					{[VisitStatus.SECOND_INVITED, VisitStatus.INVITED].includes(currentCase.activeVisit.medicalVisitStatusId) && (
						<Button
							type='button'
							onClick={handleStartVisit}
							text={translate('startVisit')}
							isDisabled={isStartVisitLoading}
							isLoading={isStartVisitLoading}
						/>
					)}

					{currentCase.activeVisit.medicalVisitStatusId === VisitStatus.IN_PROGRESS && (
						<Button
							type='button'
							onClick={handleMarkAsTaken}
							text={translate('markVisitAsCompleted')}
							isDisabled={isCompleteLoading}
							isLoading={isCompleteLoading}
						/>
					)}
				</div>
				{currentCase.activeVisit.medicalVisitStatusId === VisitStatus.IN_PROGRESS && (
					<div className='top-15 lab-visit-content'>
						<h3>{translate('injectionTherapy')}</h3>
						<div className='flex flex-wrap'>
							{item.activeVisit.parenteralTherapyVisits?.length > 0 &&
								item.activeVisit.parenteralTherapyVisits.map(injection => (
									<React.Fragment key={injection.id}>
										<p className='full-width'>
											{capitalizeFirstLetter(intl.formatMessage({ id: 'note' }))}: {injection.parenteralTherapy.note}
										</p>
										{injection.parenteralTherapy.medications.map(el => (
											<div key={el.id}>
												<p className='word-break'>{el.name}</p>
												<span className='word-break'>
													{translate('dosage')}: {el.dosage}ml, {translate('duration')}: {el.duration}
													{translate('durationDays')}, {translate('frequency')}: {el.frequency} {translate('timesADay')}
												</span>
											</div>
										))}
									</React.Fragment>
								))}
						</div>
					</div>
				)}
			</div>
		);
	};

	const WaitingList = props => {
		const { item } = props;
		return (
			<div key={item.id}>
				<div className='flex flex-align-center'>
					<div className='flex-1'>
						<h4>{item.patient.fullName}</h4>
						<p>{item.patient.idCard}</p>
					</div>
					<div className='flex' />
					<div className='flex' />
				</div>
			</div>
		);
	};

	return (
		<MainLayout>
			<div className='view-page-wrapper display-block'>
				{!isLoading && (
					<>
						<div className='waiting-room-view-inner full-width pc-waiting-room-inner'>
							{currentCase && (
								<div className='waiting-room-list'>
									<p>{translate('nextVisit')}</p>
									<NextVisit item={currentCase} />
								</div>
							)}

							{caseQueue.length > 0 && (
								<div className='waiting-room-list'>
									<p>{translate('waitingList')}</p>
									{caseQueue.map(item => (
										<WaitingList key={item.caseId} item={item} />
									))}
								</div>
							)}
						</div>
					</>
				)}
				{!isLoading && caseQueue.length === 0 && !currentCase && (
					<div className='empty-state-wrapper waiting-room-view-inner'>
						<EmptyState title={translate('noVisitsFound')} image='Visits.svg' />
					</div>
				)}
				{isLoading && (
					<Grid
						columns='1fr'
						width='100%'
						rows='1fr'
						stretch='calc(100vh - 200px)'
						className='max-initial-width'
						horizAlign='center'
						vertAlign='center'>
						<div style={{ textAlign: 'center' }}>
							<Loader />
						</div>
					</Grid>
				)}

				<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
			</div>
			<NextVisitConfirmationModal modalSubmit={() => setNextVisit(null)} nextVisit={nextVisit} />
		</MainLayout>
	);
};
export default InjectionWaitingRoom;
