import React from 'react';
import { Dropdown } from 'components/index.js';
import Button from 'components/Common/Button.jsx';
import translate from 'i18n-translations/translate.jsx';
import Alerts from 'icons/CareEvents/Alerts.jsx';
import { useSelector } from 'react-redux';
import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';
import CareTeam from 'icons/RPM/CareTeam.jsx';
import Profile from 'icons/Monitoring/Profile.jsx';

const HospitalAtHomeDropdownOptions = ({
	patient,
	toggleAlertsModal,
	getPatientCareTeam,
	setIsCareTeamOpen,
	setSelectedPatient,
	setPatientDetailsView,
}) => {
	const isDarkMode = useSelector(state => state.user.darkMode);
	const getIconColor = isDarkMode => (isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive);

	return (
		<Dropdown position='bottom' icon='more_vert' className='patient-view-more-wrapper'>
			<div className='patient-info-hello-list patient-info-view-more-list'>
				<div className='flex'>
					<Button
						className='flex'
						border='none'
						svgIcon={<Profile color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />}
						onClick={() => {
							getPatientCareTeam(patient.patientId);
							setSelectedPatient(patient);
							setPatientDetailsView(true);
						}}
						text={translate('viewProfile')}
					/>
				</div>
				<div className='flex'>
					<Button
						border='none'
						svgIcon={<Alerts color={getIconColor(isDarkMode)} />}
						className='flex'
						onClick={e => {
							e.stopPropagation();
							toggleAlertsModal(patient);
						}}
						text={translate('viewAlerts')}
					/>
				</div>

				<div className='flex'>
					<Button
						border='none'
						svgIcon={<CareTeam color={getIconColor(isDarkMode)} />}
						className='flex'
						onClick={e => {
							e.stopPropagation();
							setIsCareTeamOpen(true);
							getPatientCareTeam(patient.patientId);
						}}
						text={translate('careTeam')}
					/>
				</div>
			</div>
		</Dropdown>
	);
};

export default HospitalAtHomeDropdownOptions;
