import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';
import translate from 'i18n-translations/translate.jsx';
import { UserRoles, AppointmentStatus } from 'constants/enums.js';
import Grid from 'components/Common/Grid.jsx';
import Loader from 'components/Common/Loader.jsx';
import ProfilePicture from 'components/Common/ProfilePicture.jsx';
import EmptyState from 'components/Common/EmptyState.jsx';
import Modal from 'components/Common/Modal.jsx';
import { getAppointmentInvitations, updateAppointmentStatus, updateAsRead } from 'api/appointments.js';
import { getUserId, getUserRole } from 'infrastructure/auth.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import RescheduleAppointment from 'containers/Appointments/RescheduleAppointment';
import DoctorProfile from 'components/Doctors/DoctorProfile.jsx';
import AppointmentRequestsList from 'containers/Appointments/AppointmentRequestsList';
import AppointmentParticipantProfile from 'containers/Appointments/AppointmentParticipantProfile';
import { getGender, getPreferredLanguageLocale } from 'infrastructure/helpers/commonHelpers.js';
import Alert from 'components/Common/Alert.jsx';

const AppointmentRequests = props => {
	const [selectedRequest, setSelectedRequest] = useState(null);
	const [requests, setRequests] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isViewProfileModalVisible, setIsViewProfileModalVisible] = useState(false);
	const [error, setError] = useState(null);
	const [isRescheduleModalVisible, setisRescheduleModalVisible] = useState(false);
	const [status, setStatus] = useState(null);
	const [appointmentId, setAppointmentId] = useState(null);
	const intl = useIntl();
	const [appointmentStatusChanged, setAppointmentStatusChanged] = useState(false);

	useEffect(() => {
		const getRequests = async () => {
			const params = {
				userId: getUserId(),
				pageIndex: 0,
				pageSize: 10,
				as: getUserRole() === UserRoles.DOCTOR ? 'doctor' : 'patient',
				status: status === 0 ? null : status,
			};
			const request = await getAppointmentInvitations(params);
			if (request.error) {
				setError(`${intl.formatMessage({ id: 'anErrorOccurred' })}: ${request.error.message}`);
			} else {
				setIsLoading(true);
				setRequests(request.appointmentInvitations || []);
				if (selectedRequest && (status === null || status === 0)) {
					const updatedSelectedRequest = request.appointmentInvitations.find(person => person.id === selectedRequest.id);
					if (updatedSelectedRequest) {
						setSelectedRequest(updatedSelectedRequest);
					}
					setIsLoading(false);
					setAppointmentStatusChanged(false);
					return;
				}
				if (request.appointmentInvitations.length > 0 && (status === null || status === 0)) {
					const lastOpenedAppointment = request.appointmentInvitations.filter(item => !item?.appointmentNotification)[0];
					if (lastOpenedAppointment) {
						setSelectedRequest(lastOpenedAppointment);
						setIsLoading(false);
						setAppointmentStatusChanged(false);
						return;
					}
				}
				setSelectedRequest(request.appointmentInvitations.length > 0 ? request.appointmentInvitations[0] : null);
			}
			setIsLoading(false);
		};
		getRequests();
	}, [intl, status, appointmentStatusChanged]);

	const selectRequest = async selectedRequestOnClick => {
		const unreadAppointment = selectedRequestOnClick?.appointmentNotification;
		if (unreadAppointment) {
			const response = await updateAsRead(selectedRequestOnClick.appointmentNotification.notificationId);
			if (response.error) {
				setError(`${intl.formatMessage({ id: 'anErrorOccurred' })}`);
				return;
			}
		}
		setSelectedRequest(selectedRequestOnClick);
		if (unreadAppointment) {
			props.updateUnreadNotifications();
			setAppointmentStatusChanged(true);
		}
	};

	const updateAppointmentStatusList = async (selectedAppointmentId, statusId) => {
		try {
			setIsLoading(true);
			const response = await updateAppointmentStatus(selectedAppointmentId, statusId);
			if (response.error) {
				setError(translate('anErrorOccurred'));
			}
			setAppointmentStatusChanged(true);
			setIsLoading(false);
		} catch (ex) {
			setError(`${intl.formatMessage({ id: 'anErrorOccurred' })}: ${ex.message}`);
			setIsLoading(false);
		}
	};

	const toggleRescheduleModal = selectedAppointmentId => {
		setisRescheduleModalVisible(prevState => !prevState);
		setAppointmentId(selectedAppointmentId);
	};

	const toggleViewProfileModal = () => {
		setIsViewProfileModalVisible(prevState => !prevState);
	};

	const getRequestEmptyStateText = () => {
		switch (status) {
			case AppointmentStatus.ACCEPTED:
				return translate('noAcceptedAppointments');
			case AppointmentStatus.PENDING:
				return translate('noPendingAppointments');
			case AppointmentStatus.REJECTED:
				return translate('noRejectedAppointments');
			case AppointmentStatus.CANCELED:
				return translate('noCanceledAppointments');
			case AppointmentStatus.COMPLETED:
				return translate('noCompletedAppointments');
			default:
				return translate('noAppointmentRequests');
		}
	};

	return (
		<div>
			<div className='app-wrapper'>
				<Grid columns={requests.length > 0 ? '1fr 1fr' : '1fr'} stretch='100%'>
					<div className='patient-request-list-wrapper doctor-appointment-request-wrapper-list'>
						<AppointmentRequestsList
							requests={requests}
							selectedRequest={selectedRequest}
							isLoading={isLoading}
							setSelectedRequest={selectRequest}
							updateAppointmentStatus={updateAppointmentStatusList}
							setSelectedStatus={statusToSet => setStatus(statusToSet)}
							status={status}
						/>
					</div>
					{isLoading && (
						<div className='full-width empty-state-wrapper' style={{ textAlign: 'center' }}>
							<Loader />
						</div>
					)}
					{!isLoading && selectedRequest && (
						<>
							<AppointmentParticipantProfile
								selectedRequest={selectedRequest}
								toggleRescheduleModal={toggleRescheduleModal}
								updateAppointmentStatus={updateAppointmentStatusList}
								status={selectedRequest.statusId}
								toggleViewProfileModal={toggleViewProfileModal}
							/>

							<Modal
								modalSelector='viewProfileModal'
								display={isViewProfileModalVisible}
								isLoading={isLoading}
								position='center'
								className='standard-modal-wrapper modal-wrapper-wo-btn'
								onModalClose={toggleViewProfileModal}>
								{[UserRoles.DOCTOR].includes(getUserRole()) ? (
									<div className='standard-modal-inner modal-wrapper-wo-btn-inner view-patient-profile-modal'>
										{selectedRequest && (
											<>
												<div>
													<ProfilePicture
														className='doctor-request-img patient-request-profile-img'
														isActive={true}
														firstName={selectedRequest.participant.firstName}
														lastName={selectedRequest.participant.lastName}
														profilePicture={selectedRequest.participant.profilePicture}
													/>
													<div>
														<h3>
															{selectedRequest.participant.firstName} {selectedRequest.participant.lastName}
														</h3>
													</div>
													<div className='view-patient-profile-grid flex full-width'>
														<div className='flex'>
															<img src={`${healthCareCdnUrl}doctor-request/location-icon.svg`} alt='icon' />
															<div>
																<p>{translate('location')}</p>
																<span>{selectedRequest.participant.currentLocation}</span>
															</div>
														</div>
														<div className='flex'>
															<img src={`${healthCareCdnUrl}doctor-request/birthday-icon.svg`} alt='icon' />
															<div>
																<p>{translate('dateOfBirth')}</p>
																<span>
																	{moment(selectedRequest.participant.dateOfBirth)
																		.local()
																		.locale(getPreferredLanguageLocale())
																		.format('MMM DD, YYYY')}
																</span>
															</div>
														</div>
														<div className='flex'>
															{getGender(selectedRequest.participant.genderId).icon && (
																<img src={getGender(selectedRequest.participant.genderId).icon} alt='icon' />
															)}
															<div>
																<p>{translate('sex')}</p>
																<span>{getGender(selectedRequest.participant.genderId).description}</span>
															</div>
														</div>
													</div>
												</div>
											</>
										)}
									</div>
								) : (
									selectedRequest && <DoctorProfile doctor={selectedRequest.participant} />
								)}
							</Modal>
						</>
					)}
					{!isLoading && !selectedRequest && (
						<div className='empty-state-wrapper'>
							<EmptyState title={getRequestEmptyStateText()} image='no-requests.svg' />
						</div>
					)}
				</Grid>
			</div>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />

			{isRescheduleModalVisible && (
				<RescheduleAppointment
					isRescheduleModalVisible={isRescheduleModalVisible}
					appointmentId={appointmentId}
					toggleRescheduleModal={toggleRescheduleModal}
					userId={UserRoles.PATIENT === getUserRole() ? selectedRequest.participant.id : getUserId()}
					participantName={selectedRequest.participant.firstName}
				/>
			)}
		</div>
	);
};

export default AppointmentRequests;
