import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { getFormattedValidDate } from 'infrastructure/helpers/dateHelper.js';

const FleetManagementDeviceStatus = ({ lastOnline, isPtzDevice, isOnline }) => {
	const intl = useIntl();
	const onlineText = isPtzDevice ? 'ptzConnected' : 'online';
	const offlineText = isPtzDevice ? 'ptzDisconnected' : 'offline';

	return (
		<div
			data-tooltip={
				!isOnline && !isPtzDevice ? `${intl.formatMessage({ id: 'lastOnline' })} ${getFormattedValidDate(lastOnline)} UTC` : null
			}
			data-position='top'
			className={classNames('status', { online: isOnline })}>
			{intl.formatMessage({ id: isOnline ? onlineText : offlineText })}
		</div>
	);
};

export default FleetManagementDeviceStatus;
