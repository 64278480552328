import { injectIntl } from 'react-intl';
import moment from 'moment';
import Modal from 'components/Common/Modal.jsx';
import Form from 'components/Common/Form.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import { UserRoles } from 'constants/enums.js';
import ProfilePicture from 'components/Common/ProfilePicture.jsx';
import { getPreferredLanguageLocale } from 'infrastructure/helpers/commonHelpers.js';

const ConfirmModal = props => (
	<Modal
		modalSelector='confirmModal'
		display={props.display}
		onModalSubmit={props.onModalSubmit}
		onModalClose={props.onModalClose}
		primaryButtonLabel={props.intl.formatMessage({ id: 'done' })}
		position='center'
		isLoading={props.isLoading}
		shouldSubmitOnEnter={false}
		className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal saved-successfully no-padding-appointment-modal'>
		<Form height={270} className='modal-form-wrapper'>
			<h3>{props.isFromAdd ? translate('appointmentCreated') : translate('appointmentUpdated')}</h3>
			<br />
			<p>{translate('appointmentDetails')}</p>
			<div className='saved-successfully-details-wrapper'>
				<div className='profile-details'>
					<p>{props.userRole === UserRoles.DOCTOR ? translate('patient') : translate('doctor')}:</p>
					<div>
						{props.selectedMember && (
							<>
								<ProfilePicture
									fullName={props.selectedMember.label}
									profilePicture={props.selectedMember.profilePicture}
									className={props.selectedMember.profilePicture ? 'flex' : 'doctor-request-img appointment-img'}
								/>
								<div>
									<p>{props.selectedMember.label}</p>
									{props.userRole === UserRoles.PATIENT && (
										<p className='doctor-specialty appointments-specialty'>{props.selectedMember.specialty}</p>
									)}
								</div>
							</>
						)}
					</div>
				</div>
				<div className='time-details'>
					<p>{translate('dateAndTime')}:</p>
					<div>
						<img src={`${healthCareCdnUrl}appointments/calendar-icon.svg`} alt='calendar-icon' />
						<div>
							{props.appointmentTime && (
								<p>
									{`${moment(props.selectedAppointmentDate).locale(getPreferredLanguageLocale()).format('MMMM DD, YYYY')} `}
									<br />
									{translate('from')} {`${moment.utc(props.appointmentTime.startDateTime).local().format('HH:mm')} `}
									{translate('to')} {`${moment.utc(props.appointmentTime.endDateTime).local().format('HH:mm')}`}
								</p>
							)}
						</div>
					</div>
				</div>
			</div>
		</Form>
	</Modal>
);

export default injectIntl(ConfirmModal);
