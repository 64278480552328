import { deleteADConfiguration, deleteGroupRole, getADConfigurations, getADGroupRoles } from 'api/activeDirectory.js';
import { getHealthSystemHospitals } from 'api/userIdleConfigurations.js';
import { Alert, Button, CustomTable, Modal } from 'components/index.js';
import Pagination from 'components/Common/Pagination.jsx';
import { ConfigHistoryTypes } from 'constants/configurationEnums.js';
import { ExternalIdentityProviders, UserRoles } from 'constants/enums.js';
import ActiveDirectoryForm from 'containers/Configurations/ActiveDirectoryForm.jsx';
import TableDeleteButton from 'icons/Admin/TableDeleteButton.jsx';
import TableEditButton from 'icons/Admin/TableEditButton.jsx';
import TableHistoryButton from 'icons/Admin/TableHistoryButton.jsx';
import { getUserRole } from 'infrastructure/auth.js';
import { stringToCamelCase } from 'infrastructure/helpers/commonHelpers.js';
import { formattedDate } from 'infrastructure/helpers/dateHelper.js';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import AdGroupRoleModal from 'containers/Configurations/AdGroupRoleModal.jsx';
import { Tab, TabList, Tabs } from 'components/Common/Tabs.jsx';
import translate from 'i18n-translations/translate.jsx';

const activeSubTab = {
	ActiveDirectory: 0,
	GroupRoles: 1,
};

const activeDirectoriesHeaders = [
	{ id: 'location', title: translate('location') },
	{ id: 'domain', title: translate('domain') },
	{ id: 'validGroupName', title: translate('validGroupName') },
	{ id: 'createdBy', title: translate('createdBy') },
	{ id: 'dateCreated', title: translate('dateCreated') },
	{ id: 'eipConfigurationTypeId', title: translate('type') },
	{ id: 'edit', title: '' },
];

const groupRoleHeaders = [
	{ id: 'location', title: translate('location') },
	{ id: 'role', title: translate('role') },
	{ id: 'group', title: translate('group') },
	{ id: 'createdBy', title: translate('createdBy') },
	{ id: 'dateCreated', title: translate('dateCreated') },
	{ id: 'edit', title: '' },
];

const ActiveDirectory = props => {
	const intl = useIntl();
	const healthSystems = useSelector(state => state.healthSystems.allHealthSystems);
	const [activeDirectories, setActiveDirectories] = useState([]);
	const [groupRoles, setGroupRoles] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [hospitals, setHospitals] = useState([]);
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const [selectedConfig, setSelectedConfig] = useState(null);
	const [total, setTotal] = useState(0);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

	const transformRows = () => {
		if (props.activeSubTab === activeSubTab.ActiveDirectory) {
			return activeDirectories.map(item => ({
				id: item.id,
				location: getUserRole() === UserRoles.SUPER_ADMIN || !item.team ? translate('allHealthSystems') : item?.team?.name,
				domain: item.domain,
				validGroupName: item.validGroupName,
				createdBy: `${item.userCreated.firstName} ${item.userCreated.lastName}`,
				dateCreated: formattedDate(item.dateCreated),
				eipConfigurationTypeId: getActiveConfigurationTypeId(item.eipConfigurationTypeId),
				edit: getConfigButtons(item),
			}));
		}
		return groupRoles.map(item => ({
			id: item.id,
			location: getUserRole() === UserRoles.SUPER_ADMIN || !item.team ? translate('allHealthSystems') : item?.team?.name,
			role: item.role?.name ? translate(stringToCamelCase(item.role?.name)) : 'N/A',
			group: item.group,
			createdBy: `${item.userCreated.firstName} ${item.userCreated.lastName}`,
			dateCreated: formattedDate(item.dateCreated),
			edit: getConfigButtons(item),
		}));
	};

	const transformArray = array => {
		const newArray = array.map(item => ({ value: item.id, label: item.name }));
		if (getUserRole() === UserRoles.ADMIN) {
			newArray.unshift({ value: '0', label: translate('all') });
		}
		return newArray;
	};

	useEffect(() => {
		const getHospitals = async healthSystem => {
			const response = await getHealthSystemHospitals(healthSystem?.value || healthSystem?.id);
			if (response?.error) {
				setError(response.error.message);
			} else {
				setHospitals(transformArray(response));
			}
		};

		if (props.selectedHealthSystem && props.selectedHealthSystem.value !== '0' && getUserRole() !== UserRoles.SUPER_ADMIN) {
			getHospitals(props.selectedHealthSystem);
		}
	}, [props.selectedHealthSystem]);

	const fetchDirectories = useCallback(async () => {
		setIsLoading(true);
		const formData = {
			pageSize: props.pagination.size,
			pageIndex: props.pagination.index,
			healthSystemId: props.selectedHealthSystem.value === '0' ? null : props.selectedHealthSystem.value,
			hospitalId: props.selectedHospital?.value === '0' ? null : props.selectedHospital?.value,
		};
		const response = await getADConfigurations(formData);
		if (response.error) {
			setError(response.error.message);
		} else {
			setActiveDirectories(response.eipConfigurations ?? []);
			setTotal(response.total);
		}
		setIsLoading(false);
	}, [props.pagination, props.selectedHealthSystem, props.selectedHospital]);

	const fetchGroupRoles = useCallback(async () => {
		setIsLoading(true);
		const formData = {
			pageSize: props.pagination.size,
			pageIndex: props.pagination.index,
			healthSystemId: props.selectedHealthSystem.value === '0' ? null : props.selectedHealthSystem.value,
			hospitalId: props.selectedHospital?.value === '0' ? null : props.selectedHospital?.value,
		};

		const response = await getADGroupRoles(formData);
		if (response.error) {
			setError(response.error.message);
		} else {
			setGroupRoles(response.adGroupRoles ?? []);
			setTotal(response.total);
		}
		setIsLoading(false);
	}, [props.pagination, props.selectedHealthSystem.value, props.selectedHospital]);

	useEffect(() => {
		if (props.activeSubTab === activeSubTab.ActiveDirectory) {
			fetchDirectories();
			return;
		}
		fetchGroupRoles();
	}, [props.activeSubTab, fetchGroupRoles, fetchDirectories]);

	const getActiveConfigurationTypeId = config => {
		const configDomain = {
			[ExternalIdentityProviders.AZURE]: translate('azureAd'),
			[ExternalIdentityProviders.PING_FEDERATE]: translate('pingFederate'),
			[ExternalIdentityProviders.OKTA]: translate('okta'),
			[ExternalIdentityProviders.DUO_SSO]: translate('duo'),
		};
		return configDomain[config];
	};

	const getConfigButtons = config => (
		<div className='wrapped'>
			{props.activeSubTab === activeSubTab.ActiveDirectory && (
				<Link to={`/configurations/${config.id}/type/${ConfigHistoryTypes.ADs}`} onClick={props.onHistoryClick}>
					<div
						className='material-icons-outlined boxed-icon view-history-icon'
						data-tooltip={intl.formatMessage({ id: 'viewHistory' })}
						data-position='top'>
						<TableHistoryButton />
					</div>
				</Link>
			)}
			<TableEditButton onClick={() => toggleEditModal(config)} />
			<TableDeleteButton onClick={() => toggleDeleteModal(config)} />
		</div>
	);

	const toggleEditModal = (config = null) => {
		setSelectedConfig(config);
		setIsEditModalOpen(prevState => !prevState);
	};

	const toggleDeleteModal = values => {
		setSelectedConfig(values);
		setIsDeleteModalOpen(prevState => !prevState);
	};

	const handleDelete = async () => {
		setIsLoading(true);
		toggleDeleteModal(null);
		const params = { id: selectedConfig.id, teamId: selectedConfig.team?.id, roleId: selectedConfig.role?.id };
		const response =
			props.activeSubTab === activeSubTab.ActiveDirectory
				? await deleteADConfiguration(params.id)
				: await deleteGroupRole(params);

		if (response.error) {
			setError(response.error.message);
		} else {
			if (props.activeSubTab === activeSubTab.ActiveDirectory) {
				setActiveDirectories(prevState => prevState.filter(item => item.id !== selectedConfig.id));
			} else {
				setGroupRoles(prevState => prevState.filter(item => item.id !== selectedConfig.id));
			}
		}
		setIsLoading(false);
	};

	const fetchData = () => {
		if (props.activeSubTab === activeSubTab.ActiveDirectory) {
			fetchDirectories();
		} else {
			fetchGroupRoles();
		}
		toggleEditModal();
	};

	return (
		<div>
			<Tabs className='configs-sub-tabs' activeIndex={props.activeSubTab} onChange={props.onSubTabChange}>
				<TabList>
					<Tab>{translate('externalIdentityProviders')}</Tab>
					<Tab>{translate('groupRoles')}</Tab>
				</TabList>
			</Tabs>
			<CustomTable
				isLoading={isLoading}
				headers={props.activeSubTab === activeSubTab.ActiveDirectory ? activeDirectoriesHeaders : groupRoleHeaders}
				rows={isLoading ? [] : transformRows()}
				headerClass='configs-table-headers'>
				{getUserRole() !== UserRoles.SUPER_ADMIN && (
					<>
						<Select
							value={props.selectedHealthSystem}
							placeholder={translate('all')}
							classNamePrefix='custom-select'
							options={transformArray(healthSystems)}
							onChange={props.setSelectedHealthSystem}
							isDisabled={getUserRole() === UserRoles.SUPER_USER}
						/>
						<Select
							placeholder={translate('select')}
							value={props.selectedHospital ? hospitals.find(x => x.value === props.selectedHospital) : null}
							isDisabled={props.selectedHealthSystem === '0'}
							classNamePrefix='custom-select'
							options={hospitals}
							onChange={props.setSelectedHospital}
						/>
					</>
				)}
				{(activeDirectories.length === 0 || props.activeSubTab === activeSubTab.GroupRoles) && !isLoading && (
					<Button
						onClick={() => setIsEditModalOpen(prevState => !prevState)}
						horizAlign='end'
						text={translate(props.activeSubTab === activeSubTab.ActiveDirectory ? 'addConfiguration' : 'addGroupRoles')}
						className='text-transform-none'
					/>
				)}
			</CustomTable>
			{!isLoading && (
				<Pagination
					totalCount={total}
					pageSize={props.pagination.size}
					pageIndex={props.pagination.index}
					onChange={(pageSize, pageIndex) => props.onPaginationChange({ pageSize, pageIndex })}
				/>
			)}
			{isEditModalOpen && props.activeSubTab === activeSubTab.ActiveDirectory && (
				<ActiveDirectoryForm
					isModalOpen={isEditModalOpen}
					healthSystems={transformArray(healthSystems)}
					toggleModal={toggleEditModal}
					initialValues={selectedConfig}
					onSucceeded={fetchData}
				/>
			)}
			{isEditModalOpen && props.activeSubTab === activeSubTab.GroupRoles && (
				<AdGroupRoleModal
					isModalOpen={isEditModalOpen}
					healthSystems={transformArray(healthSystems)}
					toggleModal={toggleEditModal}
					initialValues={selectedConfig}
					onSucceeded={fetchData}
				/>
			)}
			<Alert display={error !== null} message={error} variant='dark' fixed={true} onClose={() => setError(null)} />
			<Modal
				modalSelector='delete'
				display={isDeleteModalOpen}
				position='center'
				submitButtonText='Delete'
				onModalSubmit={() => handleDelete()}
				onModalClose={() => toggleDeleteModal(null)}>
				<form>
					<h3>{translate('warning')}</h3>
					<p>{translate('areYouSureToDeleteConfig')}</p>
				</form>
			</Modal>
		</div>
	);
};

export default ActiveDirectory;
