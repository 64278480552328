import Dropdown from 'components/Common/Dropdown.jsx';
import { useIntl } from 'react-intl';

const NurseStationActions = ({ device, setCurrentNSTObj, setIsDeleteModalOpen, setIsRebootDeviceOpen }) => {
	const intl = useIntl();

	const openUnassignNSTModal = device => {
		setCurrentNSTObj(device);
		setIsDeleteModalOpen(true);
	};

	const openRebootModal = device => {
		setCurrentNSTObj(device);
		setIsRebootDeviceOpen(true);
	};

	return (
		<Dropdown position='bottom' icon='more_horiz' className='device-options-dropdown'>
			<div className='dropdown__items device-options-wrapper'>
				<ul className='list-group'>
					<div className='device-option-details' onClick={() => openRebootModal(device)}>
						<i className='material-icons-outlined boxed-icon device-icon' data-position='top'>
							refresh
						</i>
						<span>{intl.formatMessage({ id: 'rebootDevice' })}</span>
					</div>
					<div className='device-option-details' onClick={() => openUnassignNSTModal(device)}>
						<i className='material-icons-outlined boxed-icon device-icon' data-position='top'>
							delete
						</i>
						<span>{intl.formatMessage({ id: 'unassignDevice' })}</span>
					</div>
				</ul>
			</div>
		</Dropdown>
	);
};

export default NurseStationActions;
