import React from 'react';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import Grid from 'components/Common/Grid.jsx';
import CompanyList from 'components/Companies/CompanyList.jsx';
import translate from 'i18n-translations/translate.jsx';

const Companies = () => {
	return (
		<MainLayout>
			<div className='users-view test'>
				<Grid stretch='100%'>
					<main className='main-view'>
						<section>
							<Grid width='100%' vertAlign='start'>
								<h3>{translate('companies')}</h3>
								<CompanyList />
							</Grid>
						</section>
					</main>
				</Grid>
			</div>
		</MainLayout>
	);
};

export default Companies;
