import React, { useContext, useEffect, useState } from 'react';
import translate from 'i18n-translations/translate.jsx';
import { deleteConditions, getIsolations, setConditions } from 'api/whiteboard.js';
import _ from 'lodash';
import classNames from 'classnames';
import CustomDropdown from 'components/Common/CustomDropdown.jsx';
import { useIntl } from 'react-intl';
import Alert from 'components/Common/Alert.jsx';
import SocketEvents from 'constants/socket-events.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import { initialIsolations, isolationOptions } from 'constants/monitoring.js'; 
import ActionButtons from 'containers/Monitoring/Whiteboard/ActionButtons.jsx';

const Isolations = ({ deviceOwnerId, deviceId }) => {
	const socket = useContext(SocketContext);

	const [isolations, setIsolations] = useState([]);
	const [expanded, setExpanded] = useState(true);
	const [showEditForm, setShowEditForm] = useState(false);
	const [error, setError] = useState(null);
	const intl = useIntl();

	useEffect(() => {
		const ConditionActionTypes = {
			REMOVE: 1,
			ADD: 2,
		};

		const handleIsolationsUpdate = (data, type) => {
			if (data.deviceId !== deviceId) {
				return;
			}
			if (type === ConditionActionTypes.ADD) {
				const filtered = data.conditions.filter(item => item.conditionType === 4);
				setIsolations(
					filtered.map(item => ({
						src: initialIsolations.find(x => item.code === x.key)?.src,
						name: item.display,
						key: item.code,
					}))
				);
			}
			if (type === ConditionActionTypes.REMOVE) {
				setIsolations(prevState =>
					prevState.filter((_, index) => index !== prevState.findIndex(item => item.key === data.conditions[0].code))
				);
			}
		};
		socket.on(SocketEvents.HealthCare.PATIENT_CONDITIONS_ADDED, data => handleIsolationsUpdate(data, ConditionActionTypes.ADD));
		socket.on(SocketEvents.HealthCare.PATIENT_CONDITIONS_REMOVED, data =>
			handleIsolationsUpdate(data, ConditionActionTypes.REMOVE)
		);

		return () => {
			socket.off(SocketEvents.HealthCare.PATIENT_CONDITIONS_ADDED, data =>
				handleIsolationsUpdate(data, ConditionActionTypes.ADD)
			);
			socket.off(SocketEvents.HealthCare.PATIENT_CONDITIONS_REMOVED, data =>
				handleIsolationsUpdate(data, ConditionActionTypes.REMOVE)
			);
		};
	}, [socket, deviceId]);

	useEffect(() => {
		const fetchData = async () => {
			const response = await getIsolations(deviceOwnerId);
			if (response.error) {
				setError(translate('somethingWentWrong'));
				return;
			}
			const filteredItems = _.cloneDeep(initialIsolations).filter(item =>
				response.conditions.find(condition => condition.code === item.key)
			);
			setIsolations(filteredItems);
		};

		if (deviceOwnerId) {
			fetchData();
		}
	}, [deviceOwnerId]);

	const setSelectedIsolations = async values => {
		const secondArrayValues = values.map(item => item.value);

		let response = null;

		const differences = isolations.filter(item => !secondArrayValues.includes(item.key));

		if (differences.length > 0) {
			response = await deleteConditions(deviceOwnerId, {
				deviceId,
				conditions: differences.map(item => ({
					conditionType: 4,
					display: item.name,
					code: item.key,
				})),
			});
		} else {
			response = await setConditions(deviceOwnerId, {
				deviceId,
				conditions: values.map(item => ({
					conditionType: 4,
					display: item.label,
					code: item.value,
				})),
			});
		}

		if (response.error) {
			setError(translate('somethingWentWrong'));
			return;
		}
		setIsolations(
			values.map(item => ({
				src: initialIsolations.find(x => item.value === x.key)?.src,
				name: item.label,
				key: item.value,
			}))
		);
	};

	return (
		<>
			<div className={classNames('timeline-box-header sub-timeline-box-header', expanded ? 'expanded' : '')}>
				<p className='timeline-box-title'>{translate('isolations')}</p>
				<div className='timeline-box-actions'>
					<ActionButtons
						handleEditClick={() => {
							setShowEditForm(prevState => !prevState);
							setExpanded(true);
						}}
						handleExpandClick={() => setExpanded(prevState => !prevState)}
						expanded={expanded}
					/>
				</div>
			</div>
			{expanded && (
				<>
					{!showEditForm && (
						<table className='patient-table-informations' style={{ width: '100%' }}>
							<tbody>
								{isolations.map(item => (
									<tr key={item.key}>
										<td className='isolations-td'>
											<img src={item.src} alt={item.name} />
											<span>{item.name}</span>
										</td>
									</tr>
								))}
								{isolations.length === 0 && (
									<tr>
										<td>{translate('noData')}</td>
									</tr>
								)}
							</tbody>
						</table>
					)}
					{showEditForm && (
						<div className='flex-basis-100'>
							<CustomDropdown
								defaultOptions={
									isolations?.length > 0
										? isolations.map(item => ({
												value: item.key,
												label: item.name,
										  }))
										: []
								}
								initialOptions={isolationOptions}
								onSelect={selectedOptions => setSelectedIsolations(selectedOptions)}
								title={intl.formatMessage({ id: 'isolations' })}
								showTitleInPlaceholder={true}
								placeholder=''
							/>
						</div>
					)}
				</>
			)}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</>
	);
};

export default Isolations;
