import { useSelector } from 'react-redux';
import Button from 'components/Common/Button.jsx';
import { EditIcon } from 'icons/Monitoring/index.js';
import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';

const ActionButtons = ({ handleEditClick, handleExpandClick, expanded }) => {
	const isMonitoring = () => window.location.pathname.startsWith('/monitoring');
	const isDarkMode = useSelector(state => state.user.darkMode);

	return (
		<>
			{!isMonitoring() && (
				<Button
					onClick={handleEditClick}
					svgIcon={<EditIcon color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayThree} />}
					background='var(---button-bg)'
					border='none'></Button>
			)}
			<Button onClick={handleExpandClick} icon={expanded ? 'expand_less' : 'expand_more'} border='none' />
		</>
	);
};

export default ActionButtons;
