import translate from 'i18n-translations/translate.jsx';

const PatientDetailCell = ({ items, icon, title }) => (
	<div className='patient-cell-wrapper'>
		<div className='flex gap-s'>
			{icon}
			<span className='semi-bold'>{translate(title)}</span>
		</div>
		{items.map(item => (
			<div key={item.id} className='flex flex-space-between text-align-right'>
				<span>{translate(item.label)}</span>
				<span>{item.value}</span>
			</div>
		))}
	</div>
);

export default PatientDetailCell;
