import { enums } from '@solaborate/calls';
import StartQueryStringKeys from 'calls/enums/StartQueryStringKeys.js';
import translate from 'i18n-translations/translate.jsx';
import {
	SingleValue,
	formatOptionLabel,
	getCareEventStatusOptions,
	getCareEventTypeIcon,
	getPriorityColor,
	getSLAColor,
	getStatusDetails,
} from 'infrastructure/helpers/careEventsHelper.jsx';
import { actionCreators as healthSystemsActionCreators } from 'state/healthSystems/actions.js';
import { StreamError, MediaPermissions, UserPermissionDeniedErrors, ErrorComponentTypes } from 'constants/enums.js';
import { MediaPermissionsErrorType, requestMediaPermissions } from 'mic-check';
import { isChrome, isEdgeChromium } from 'react-device-detect';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkIfMediaDevicesPlugged, findSectorById } from 'infrastructure/helpers/commonHelpers.js';
import { CareEventPriority, CareEventProp, CareEventStatus, CareEventTypes } from 'constants/care-events.js';
import ProfilePicture from 'components/Common/ProfilePicture.jsx';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { useIntl } from 'react-intl';
import { defaultDateFormat, monthDayYear } from 'infrastructure/helpers/dateHelper.js';
import classNames from 'classnames';
import CareEventsDropDownOptions from 'containers/CareEventsDashboard/CareEventsDropDownOptions.jsx';
import { getPatientByUserId } from 'api/patients.js';
import { getCountries } from 'api/users.js';
import Alert from 'components/Common/Alert.jsx';

const PatientsCareEvents = ({
	careEventsDashboard,
	toggleViewHistory,
	nurses,
	getNurses,
	handleChangeProp,
	setPatientDetails,
	setCountries,
	setViewProfileModalVisibility,
	setSelectedCountry,
	setAlertsModalVisibility,
	setPatientAlerts,
}) => {
	const intl = useIntl();
	const [error, setError] = useState(null);
	const micStatus = useRef(null);
	const isAllowPermissionPrompt = useRef(false);
	const priorities = [
		{ label: intl.formatMessage({ id: 'low' }), value: CareEventPriority.LOW },
		{ label: intl.formatMessage({ id: 'medium' }), value: CareEventPriority.MEDIUM },
		{ label: intl.formatMessage({ id: 'high' }), value: CareEventPriority.HIGH },
	];
	const dispatch = useDispatch();
	const healthSystems = useSelector(state => state.healthSystems);
	const isDarkMode = useSelector(state => state.user.darkMode);

	const showAllowPermissionModal = () => {
		isAllowPermissionPrompt.current = true;
		setTimeout(() => {
			if (!isAllowPermissionPrompt.current) {
				return;
			}
			dispatch(
				healthSystemsActionCreators.setStreamPermissionMessage({
					component: ErrorComponentTypes.Modal,
					type: isChrome || isEdgeChromium ? StreamError.MICROPHONE_BLOCKED.type : StreamError.MICROPHONE_BLOCKED_GENERIC.type,
				})
			);
		}, 500);
	};

	const openViewProfileModal = patient => {
		getPatientDetails(patient.patientUserId);
	};

	const getPatientDetails = async patientId => {
		if (!patientId) {
			return;
		}
		const [patientProfileDetails, countriesList] = await Promise.all([getPatientByUserId(patientId), getCountries()]);
		const responseError = patientProfileDetails.error || countriesList.error;
		if (responseError) {
			setError(responseError.message);
			return;
		}
		setSelectedCountry(countriesList.countries.find(item => item.code === patientProfileDetails.patient.profile?.country)?.name);
		setCountries(countriesList.countries);
		setPatientDetails(patientProfileDetails.patient);
		setViewProfileModalVisibility(true);
	};

	const openAlertsModal = patient => {
		setAlertsModalVisibility(true);
		setPatientAlerts(patient);
	};

	const openCallViewUrl = (callType, sector) => {
		const foundSector = findSectorById(healthSystems.treeData.tree, sector.solDeviceId);
		const queryParams = new URLSearchParams({
			[StartQueryStringKeys.OBJECT_ID]: foundSector.helloDeviceId,
			[StartQueryStringKeys.OBJECT_TYPE]: enums.ObjectTypes.HELLO_DEVICE,
			[StartQueryStringKeys.CONFERENCE_NAME]: foundSector.name,
			[StartQueryStringKeys.CALL_TYPE]: callType,
			[StartQueryStringKeys.ROOM_TYPE]: foundSector.roomType,
		});
		dispatch(healthSystemsActionCreators.setStreamPermissionMessage(null));
		const url = callType === enums.CallTypes.SECURITYCAM ? 'patient-feed' : 'call';
		window.open(`/${url}?${queryParams.toString()}`, '_blank');
	};

	const viewPatient = async sector => {
		try {
			showAllowPermissionModal();
			await requestMediaPermissions({ audio: true, video: false });
		} catch (e) {}
		isAllowPermissionPrompt.current = false;
		openCallViewUrl(enums.CallTypes.SECURITYCAM, sector);
	};

	const talkToPatient = async sector => {
		try {
			showAllowPermissionModal();
			await requestMediaPermissions({ audio: true, video: false });
			openCallViewUrl(enums.CallTypes.VIDEO, sector);
		} catch (permissionError) {
			handlePermissionErrors(enums.CallTypes.VIDEO, permissionError);
		}
		isAllowPermissionPrompt.current = false;
	};

	const handlePermissionErrors = async (callType, permissionError) => {
		const { camera, microphone } = await checkIfMediaDevicesPlugged();
		if ([enums.CallTypes.VIDEO, enums.CallTypes.AUDIO].includes(callType) && (!camera || !microphone)) {
			dispatch(
				healthSystemsActionCreators.setStreamPermissionMessage({
					component: ErrorComponentTypes.Modal,
					type: microphone ? StreamError.MICROPHONE_NOT_FOUND.type : StreamError.CAMERA_NOT_FOUND.type,
				})
			);
			return;
		}
		if (micStatus.current.state === MediaPermissions.DENIED) {
			dispatch(
				healthSystemsActionCreators.setStreamPermissionMessage({
					component: ErrorComponentTypes.Modal,
					type: StreamError.MICROPHONE_BLOCKED.type,
				})
			);
			return;
		}

		const { type, name } = permissionError;
		if (type === MediaPermissionsErrorType.UserPermissionDenied) {
			if (name === UserPermissionDeniedErrors.NotAllowedError) {
				dispatch(
					healthSystemsActionCreators.setStreamPermissionMessage({
						component: isChrome || isEdgeChromium ? ErrorComponentTypes.Popup : ErrorComponentTypes.Modal,
						type: isChrome || isEdgeChromium ? StreamError.MICROPHONE_BLOCKED.type : StreamError.MICROPHONE_BLOCKED_GENERIC.type,
					})
				);
			}
		}
	};

	return careEventsDashboard.map(item => {
		const foundCareEvent = CareEventTypes.find(el => item.careEventId === el.careEventId);
		return (
			<>
				<div key={item.id} className='flex full-width cursor-pointer care-event-row'>
					<div className='care-event-details flex'>
						<ProfilePicture
							className='doctor-request-img patient-care-event'
							fullName={item.patientFullName}
							profilePicture={item.patientProfilePicture}
						/>
						<div className='flex column-direction patient-details'>
							<h4>{item.patientFullName}</h4>
							<span className='mrn'>MRN: {item.mrn || 'N/A'}</span>
							<span>DOB: {item.birthdate ? monthDayYear(item.birthdate) : 'N/A'}</span>
						</div>
					</div>
					<div className='care-event-details'>
						<span>{defaultDateFormat(item.dateCreated)}</span>
					</div>
					<div className='care-event-details'>
						<span>{item.deviceCompanyPath.roomName}</span>
					</div>
					<div className='flex flex-align-center care-event-type care-event-details'>
						{getCareEventTypeIcon(foundCareEvent?.careEventId, isDarkMode)}
						<span>{translate(foundCareEvent?.name)}</span>
					</div>
					<div className='flex flex-align-center care-event-details'>
						<label className={classNames('sla-options left-margin-s', getSLAColor(item.serviceLevelAgreement))} />
					</div>
					<div className='care-event-details'>
						<label className={classNames('care-events-select', getPriorityColor(item.priorityId))}>
							<Select
								value={{
									label: intl.formatMessage({ id: getPriorityColor(item.priorityId) }),
									value: item.priorityId,
								}}
								classNamePrefix='custom-select'
								options={priorities}
								onChange={priorityItem => handleChangeProp({ item, propItem: priorityItem, propName: CareEventProp.PRIORITY_ID })}
							/>
						</label>
					</div>
					<div className='care-event-details'>
						<label className={classNames('care-events-select', getStatusDetails(item.statusId)?.clsName)}>
							<Select
								value={{
									label: intl.formatMessage({ id: getStatusDetails(item.statusId)?.name }),
									value: item.statusId,
								}}
								isDisabled={item.statusId === CareEventStatus.COMPLETED}
								classNamePrefix='custom-select'
								options={getCareEventStatusOptions(item.statusId, intl)}
								onChange={statusItem => handleChangeProp({ item, propItem: statusItem, propName: CareEventProp.STATUS_ID })}
							/>
						</label>
					</div>

					<div className='care-event-details flex'>
						<label onClick={() => getNurses('')} className='full-width care-team-event-select'>
							<AsyncSelect
								loadOptions={value => getNurses(value)}
								placeholder={intl.formatMessage({ id: 'selectCareTeam' })}
								onChange={careTeam =>
									handleChangeProp({ item, propItem: careTeam, propName: CareEventProp.CARE_MEMBER, nestedProp: 'id' })
								}
								getOptionValue={option => option.value}
								classNamePrefix='custom-select'
								defaultOptions={nurses}
								isClearable={false}
								value={{
									label: `${item.careMember.firstName} ${item.careMember.lastName}`,
									value: item.careMember.userId,
									profilePicture: item.careMember.profilePicture,
								}}
								formatOptionLabel={formatOptionLabel}
								components={{ SingleValue }}
								styles={{
									menu: provided => ({
										...provided,
										width: '250px',
									}),
								}}
							/>
						</label>
					</div>
					<div className='flex position-relative'>
						<div className='care-event-details flex patient-info-call'>
							<div className='patient-info-view' onClick={() => viewPatient(item.deviceCompanyPath)} />
							<div className='patient-info-audio' onClick={() => talkToPatient(item.deviceCompanyPath)} />
							<CareEventsDropDownOptions
								toggleViewHistory={toggleViewHistory}
								openViewProfileModal={openViewProfileModal}
								openAlertsModal={openAlertsModal}
								patient={item}
							/>
						</div>
					</div>
				</div>
				<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
			</>
		);
	});
};
export default PatientsCareEvents;
