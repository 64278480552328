import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import { getUserInfo, getUserId, isFitBitAuthenticated } from 'infrastructure/auth.js';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from 'components/Common/Tabs.jsx';
import HealthData from 'views/Patients/Partials/HealthData.jsx';
import IntegrateAppTab from 'components/Common/IntegrateAppTab.jsx';
import translate from 'i18n-translations/translate.jsx';
import AlertHistory from 'components/Common/AlertHistory.jsx';
import { getStorage } from 'infrastructure/helpers/commonHelpers.js';

const MyVitalSigns = () => {
	const [currentTab, setCurrentTab] = useState(0);
	const [isIntegrateAppTabVisible, setIsIntegrateAppTabVisible] = useState(false);
	const history = useHistory();

	useEffect(() => {
		const { location } = history;
		if (location.hash && !isFitBitAuthenticated()) {
			const { hash } = location;
			const accessToken = '#access_token=';
			const startingIndex = hash.lastIndexOf(accessToken);
			const endingIndex = hash.lastIndexOf('&user_id');
			const token = hash.substring(startingIndex + accessToken.length, endingIndex);
			getStorage().setItem('fitbit_token', token);
		}
	}, [history]);

	const userInfo = getUserInfo();

	const userId = getUserId();

	return (
		<MainLayout>
			<div className='full-width full-height my-vital-signs'>
				<main className='main-view'>
					<Tabs
						activeIndex={currentTab}
						onChange={index => {
							setCurrentTab(index);
						}}>
						<TabList>
							<Tab>{translate('healthCategories')}</Tab>
							<Tab>{translate('alertHistory')}</Tab>
							<li className='cursor-pointer'>
								<span onClick={() => setIsIntegrateAppTabVisible(true)}>+</span>
							</li>
						</TabList>
						<TabPanels>
							<TabPanel>
								<HealthData
									selectedPatient={{
										userId: userInfo.userId,
										firstName: userInfo.firstName,
										lastName: userInfo.lastName,
										id: userInfo.id,
									}}
									showPatientData={false}
									isDoctorView={false}
								/>
							</TabPanel>
							<TabPanel>
								<AlertHistory patientId={userId} defaultTab={0} />
							</TabPanel>
							<TabPanel />
						</TabPanels>
					</Tabs>
				</main>
				{isIntegrateAppTabVisible && <IntegrateAppTab onCloseAppsModal={() => setIsIntegrateAppTabVisible(false)} />}
			</div>
		</MainLayout>
	);
};

export default MyVitalSigns;
