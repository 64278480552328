import { useContext, useEffect, useMemo, useState } from 'react';
import { getPatientMobilitySummary } from 'api/patients.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SocketEvents from 'constants/socket-events.js';
import Alert from 'components/Common/Alert.jsx';
import { AiAnalyticsTypeId } from 'constants/enums.js';
import { getDurationFormat } from 'infrastructure/helpers/dateHelper.js';
import { useIntl } from 'react-intl';
import { StyledCircularChartWrapper } from 'css/StyledComponents/StyledChartWrapper.js';
import { Chart } from 'primereact/chart.js';
import Grid from 'components/Common/Grid.jsx';
import Loader from 'components/Common/Loader.jsx';
import { MobilityChartDetails } from 'infrastructure/helpers/aiHelper.jsx';
import styled from 'styled-components';
import { ValidAnalyticsTypes } from 'constants/ai.jsx';

const LegendMarker = styled.span`
	width: var(--spacing-xl);
	height: var(--spacing-m);
	background: ${props => props.$chartColor};
	border-radius: var(--spacing-s);
	margin-right: var(--spacing-m);
`;

const AiPatientMobilityHistory = ({ patientId, deviceId, isDarkMode }) => {
	const socket = useContext(SocketContext);
	const intl = useIntl();
	const [error, setError] = useState(null);
	const [patientMobilityHistory, setPatientMobilityHistory] = useState(null);
	const [totalPatientMobilityHistory, setTotalPatientMobilityHistory] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const fetchPatientMobilityHistory = async () => {
			const response = await getPatientMobilitySummary({ patientId });
			if (response.error) {
				setError(response.error.message);
				setIsLoading(false);
				return;
			}
			setPatientMobilityHistory(response);
			setIsLoading(false);
		};
		fetchPatientMobilityHistory();
	}, [patientId]);

	const calculatePercentage = (value, total) => (total ? parseFloat(((value / total) * 100).toFixed(2)) : 0);

	const getUpdatedPatientMobilityHistory = (position, value) => {
		const lyingValue = patientMobilityHistory?.lying ?? 0;
		const sittingValue = patientMobilityHistory?.sitting ?? 0;
		const walkingValue = patientMobilityHistory?.walking ?? 0;

		let newValue = (patientMobilityHistory?.[position] ?? 0) + value;
		let totalSum = lyingValue + sittingValue + walkingValue + value;

		return {
			...patientMobilityHistory,
			[position]: newValue,
			lyingPercentage: calculatePercentage(lyingValue + (position === 'lying' ? value : 0), totalSum),
			sittingPercentage: calculatePercentage(sittingValue + (position === 'sitting' ? value : 0), totalSum),
			walkingPercentage: calculatePercentage(walkingValue + (position === 'walking' ? value : 0), totalSum),
		};
	};
	useEffect(() => {
		const onAlertAnalyticsAdded = data => {
			if (!ValidAnalyticsTypes.includes(data.analyticsType) || data.deviceId !== deviceId || data.patientId !== patientId) {
				return;
			}

			switch (data.analyticsType) {
				case AiAnalyticsTypeId.BED_LYING_DOWN:
				case AiAnalyticsTypeId.BED_LYING_FRONT:
				case AiAnalyticsTypeId.BED_LYING_SIDE:
				case AiAnalyticsTypeId.CHAIR_LYING_DOWN:
				case AiAnalyticsTypeId.CHAIR_LYING_FRONT:
				case AiAnalyticsTypeId.CHAIR_LYING_SIDE:
					setPatientMobilityHistory(getUpdatedPatientMobilityHistory('lying', +data.value));
					break;
				case AiAnalyticsTypeId.BED_SITTING:
				case AiAnalyticsTypeId.CHAIR_SITTING:
					setPatientMobilityHistory(getUpdatedPatientMobilityHistory('sitting', +data.value));
					break;
				case AiAnalyticsTypeId.WALKING:
					setPatientMobilityHistory(getUpdatedPatientMobilityHistory('walking', +data.value));
					break;
				default:
					setPatientMobilityHistory({ ...patientMobilityHistory });
			}
		};

		const total = getDurationFormat(
			(patientMobilityHistory?.lying ?? 0) + (patientMobilityHistory?.sitting ?? 0) + (patientMobilityHistory?.walking ?? 0)
		);
		setTotalPatientMobilityHistory(total);

		socket.on(SocketEvents.Alerts.ALERT_ANALYTICS_ADDED, onAlertAnalyticsAdded);
		return () => {
			socket.off(SocketEvents.Alerts.ALERT_ANALYTICS_ADDED, onAlertAnalyticsAdded);
		};
	}, [patientMobilityHistory]);

	const data = useMemo(() => {
		return {
			labels: MobilityChartDetails.map(item => item.label),
			datasets: [
				{
					data: [patientMobilityHistory?.lying || 0, patientMobilityHistory?.sitting || 0, patientMobilityHistory?.walking || 0],
					backgroundColor: MobilityChartDetails.map(item => item.color),
					borderColor: 'transparent',
				},
			],
		};
	}, [patientMobilityHistory]);

	const options = {
		legend: {
			display: false,
		},
		maintainAspectRatio: false,
		cutoutPercentage: 80,
		tooltips: {
			callbacks: {
				label: (ttItem, data) => getDurationFormat(data.datasets[0].data[ttItem.index]),
			},
		},
		animation: {
			duration: 0,
		},
	};

	return (
		<>
			{isLoading && (
				<Grid columns='1fr' rows='1fr' stretch='250px' horizAlign='center' vertAlign='center'>
					<div className='text-align-center'>
						<Loader />
					</div>
				</Grid>
			)}
			{!isLoading && Object.values(patientMobilityHistory).some(val => val !== 0) && (
				<>
					<h3 className='semi-bold font-14'>{intl.formatMessage({ id: 'history' })}</h3>
					<StyledCircularChartWrapper $isDarkMode={isDarkMode}>
						<div>
							<p className='flex column-direction'>
								<span>{intl.formatMessage({ id: 'total' })}</span>
								{totalPatientMobilityHistory}
							</p>
							<Chart type='RoundedDoughnut' data={data} options={options} style={{ width: '100%', height: '100%' }} />
						</div>
						<div className='flex full-width flex-space-around top-15'>
							{MobilityChartDetails.map(history => (
								<div key={history.id} className='flex flex-align-center '>
									<LegendMarker $chartColor={history.color} />
									<div className='flex column-direction'>
										<p className='font-13'>{intl.formatMessage({ id: history.label })}</p>
										<span className='font-13 semi-bold'>{getDurationFormat(patientMobilityHistory?.[history?.label])}</span>{' '}
										<p className='font-13 semi-bold'>{patientMobilityHistory[history.percentage]}%</p>
									</div>
								</div>
							))}
						</div>
					</StyledCircularChartWrapper>
				</>
			)}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</>
	);
};
export default AiPatientMobilityHistory;
