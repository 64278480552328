import { MeasurementTypes } from 'constants/enums.js';

export const measurementTypes = [
	MeasurementTypes.BLOOD_PRESSURE,
	MeasurementTypes.HEART_RATE,
	MeasurementTypes.OXYGEN,
	MeasurementTypes.DIABETES,
	MeasurementTypes.TEMPERATURE,
];

export const hospitalAtHomeColumns = [
	'patient',
	'schedule',
	MeasurementTypes.BLOOD_PRESSURE,
	MeasurementTypes.HEART_RATE,
	MeasurementTypes.OXYGEN,
	MeasurementTypes.DIABETES,
	MeasurementTypes.TEMPERATURE,
];

export const HospitalAtHomeTabsEnum = {
	OVERVIEW: 0,
	SCHEDULE: 1,
	MEDICATION: 2,
	ALERTS: 3,
	RECENT_METRICS: 4,
	TELEMETRY: 5,
	CARE_TEAM: 6,
	VISITS: 7,
	CHAT: 8,
};

export const HospitalAtHomeTabFilter = {
	ALL_PATIENTS: 'allPatients',
	MY_PATIENTS: 'myPatients',
	HIGH_RISK: 'high',
	MODERATE_RISK: 'mod',
	LOW_RISK: 'low',
};

export const HospitalAtHomeDropdownFilter = {
	PATIENTS: 1,
	SCHEDULE: 2,
	ASSIGNED_NURSE: 3,
	PAIN_LEVEL: 4,
};

export const HospitalAtHomeActiveTab = {
	ALL_PATIENTS: 0,
	MY_PATIENTS: 1,
	HIGH_RISK: 2,
	MODERATE_RISK: 3,
	LOW_RISK: 4,
};

export const HospitalAtHomeDropdownFilters = [
	{
		id: HospitalAtHomeDropdownFilter.PATIENTS,
		title: 'patients',
	},
	{
		id: HospitalAtHomeDropdownFilter.SCHEDULE,
		title: 'schedule',
	},
	{
		id: HospitalAtHomeDropdownFilter.ASSIGNED_NURSE,
		title: 'assignedSomething',
	},
	{
		id: HospitalAtHomeDropdownFilter.PAIN_LEVEL,
		title: 'painLevel',
	},
];

export const PainLevels = [0, 2, 4, 6, 8, 10];
