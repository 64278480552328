import React, { useState } from 'react';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import Select from 'react-select';
import Input from 'components/Common/FormElements/Input.jsx';
import { Alert, Form, Modal, Input as CustomInput } from 'components/index.js';
import { addADConfiguration, updateADConfiguration } from 'api/activeDirectory.js';
import { ExternalIdentityProviders, KeyCodes, TeamTypes, UserRoles } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import { useSelector } from 'react-redux';
import { IntegrationTypesSettings } from 'constants/configurationEnums.js';
import { getConfigurationValue } from 'infrastructure/helpers/commonHelpers.js';
import { isValidEmailDomain } from 'infrastructure/helpers/validationHelper.js';
import { getHealthSystemHospitals } from 'api/userIdleConfigurations.js';
import { getUserRole } from 'infrastructure/auth.js';
import classNames from 'classnames';

const ActiveDirectoryForm = props => {
	const intl = useIntl();
	const companyConfigurations = useSelector(state => state.company.companySettings?.companyConfigurations);
	const [error, setError] = useState('');
	const [formHospitals, setFormHospitals] = useState([]);

	const shouldShowConfigType = settingTypeId =>
		getConfigurationValue(companyConfigurations[settingTypeId]) || props.initialValues;

	const configTypes = [
		shouldShowConfigType(IntegrationTypesSettings.AZURE_AD) && {
			value: ExternalIdentityProviders.AZURE,
			label: 'Azure',
		},
		shouldShowConfigType(IntegrationTypesSettings.PING_FEDERATE) &&
			getUserRole() !== UserRoles.SUPER_ADMIN && {
				value: ExternalIdentityProviders.PING_FEDERATE,
				label: 'Ping Federate',
			},
		shouldShowConfigType(IntegrationTypesSettings.OKTA) &&
			getUserRole() !== UserRoles.SUPER_ADMIN && {
				value: ExternalIdentityProviders.OKTA,
				label: 'OKTA',
			},
		shouldShowConfigType(IntegrationTypesSettings.DUO_SSO) &&
			getUserRole() !== UserRoles.SUPER_ADMIN && {
				value: ExternalIdentityProviders.DUO_SSO,
				label: 'Duo',
			},
	].filter(Boolean);

	const getValidationSchema = () =>
		Yup.object().shape({
			domain: Yup.string().when('isCompanyLevelConfiguration', {
				is: () => !props.initialValues,
				then: () => Yup.string().required(intl.formatMessage({ id: 'domainRequired' })),
			}),
			username: Yup.string().when('isCompanyLevelConfiguration', {
				is: () => !props.initialValues,
				then: () => Yup.string().required(intl.formatMessage({ id: 'clientIdRequired' })),
			}),
			password: Yup.string().when('isCompanyLevelConfiguration', {
				is: () => !props.initialValues,
				then: () => Yup.string().required(intl.formatMessage({ id: 'clientSecretRequired' })),
			}),
			validGroupName: Yup.string().when('selectedConfig.value', {
				is: selectedConfigValue => selectedConfigValue === ExternalIdentityProviders.AZURE && !props.initialValues,
				then: () => Yup.string().required(intl.formatMessage({ id: 'setValidGroupNameRequired' })),
			}),
			emailDomains: Yup.array().when(['autoSyncOnLogin', 'selectedConfig.value'], {
				is: (autoSyncOnLogin, selectedConfigValue) => autoSyncOnLogin && selectedConfigValue === ExternalIdentityProviders.AZURE,
				then: () =>
					Yup.array().test(
						'required',
						intl.formatMessage({ id: 'formShouldContainOneDomainEmail' }),
						value => value && value.length > 0
					),
			}),
			healthSystem: Yup.object()
				.nullable()
				.when('isCompanyLevelConfiguration', {
					is: isCompanyLevelConfiguration =>
						!isCompanyLevelConfiguration && !props.initialValues && getUserRole() !== UserRoles.SUPER_ADMIN,
					then: () => Yup.object().required(intl.formatMessage({ id: 'healthSystemRequired' })),
				}),
		});

	const getDomainDescription = config => {
		const configDomain = {
			[ExternalIdentityProviders.AZURE]: 'https://login.microsoftonline.com/[tenantId]',
			[ExternalIdentityProviders.PING_FEDERATE]: 'https://fed.sample.com',
			[ExternalIdentityProviders.OKTA]: 'https://[sub-domain].okta.com/',
			[ExternalIdentityProviders.DUO_SSO]: 'https://sso-[tenant-subdomain].sso.duosecurity.com/oidc',
		};
		return configDomain[+config];
	};

	const getInitialValues = () =>
		props.initialValues
			? {
					...props.initialValues,
					selectedConfig: configTypes.find(item => item.value === props.initialValues?.eipConfigurationTypeId),
					emailDomains: props.initialValues.emailDomains?.map(item => item.domain) || [],
					hospital: {
						value: props.initialValues.team?.id,
						label: props.initialValues.team?.name,
					},
			  }
			: {
					selectedConfig: configTypes[0],
					healthSystem: getUserRole() !== UserRoles.SUPER_ADMIN ? props.healthSystems[0] : null,
					hospital: null,
					validGroupName: '',
					username: '',
					password: '',
					domain: '',
					includeRoles: false,
					autoSyncOnLogin: false,
					emailDomains: [],
					emailDomain: '',
					isSingleSignOutEnabled: false,
					autoAdd: true,
					autoUpdate: true,
					autoDelete: true,
					isCompanyLevelConfiguration: getUserRole() !== UserRoles.SUPER_ADMIN,
					teamTypeId: null,
			  };

	const handleSubmitMyForm = async (values, { resetForm }) => {
		const isAzure = values.selectedConfig?.value === ExternalIdentityProviders.AZURE;
		const isAzureOrOkta = [ExternalIdentityProviders.AZURE, ExternalIdentityProviders.OKTA].includes(
			values.selectedConfig?.value
		);
		const payload = {
			teamId: values.isCompanyLevelConfiguration ? null : values.hospital?.value || values.healthSystem?.value,
			...(!props.initialValues && { teamTypeId: values.teamTypeId }),
			validGroupName: values.validGroupName,
			username: values.username,
			password: values.password,
			domain: values.domain,
			includeRoles: values.includeRoles,
			eipConfigurationTypeId: values.selectedConfig?.value,
			isCompanyLevelConfiguration: isAzure ? values.isCompanyLevelConfiguration : true,
			autoAdd: !values.isCompanyLevelConfiguration ? values.autoAdd : false,
			autoUpdate: isAzure ? values.autoUpdate : false,
			autoDelete: isAzure ? values.autoDelete : false,
			isSingleSignOutEnabled: isAzureOrOkta ? values.isSingleSignOutEnabled : false,
			emailDomains: values.emailDomains.map(item => ({ emailDomain: item })),
			autoSyncOnLogin: values.emailDomains.length > 0,
		};
		const response = props.initialValues ? await updateADConfiguration(values.id, payload) : await addADConfiguration(payload);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		resetForm();
		props.onSucceeded();
	};

	const onCloseModal = resetForm => {
		resetForm();
		props.toggleModal();
	};

	const handleDomainInput = ({ value, setFieldValue, setFieldError, name, emailDomains }) => {
		if (!value) {
			return;
		}

		if (emailDomains.length === 5) {
			setFieldError(name, intl.formatMessage({ id: 'maximumEmailDomainsAllowedAre5' }));
			return;
		}

		if (emailDomains.includes(`@${value}`)) {
			setFieldError(name, intl.formatMessage({ id: 'youAlreadyHaveAddedThisDomain' }));
			return;
		}

		if (!isValidEmailDomain(value)) {
			setFieldError(name, intl.formatMessage({ id: 'theProvidedDomainIsInvalid' }));
			return;
		}

		setFieldValue(name, [...emailDomains, `@${value}`]);
		setFieldValue('emailDomain', '');
		setFieldError(name, '');
	};

	const removeEmailFromList = (value, setFieldValue, emailDomains) => {
		const newArray = emailDomains.filter(item => item !== value);
		setFieldValue('emailDomains', newArray);
	};

	const onHealthSystemChange = async (hs, setFieldValue) => {
		setFieldValue('healthSystem', hs);
		setFieldValue('teamTypeId', TeamTypes.HEALTH_SYSTEM);
		const hsHospitals = hs.value !== '0' ? await getHealthSystemHospitals(hs.value) : [];
		if (hsHospitals.error) {
			setError(hsHospitals.error.message);
		} else {
			const hospitalArray = hsHospitals.map(hospital => ({ value: hospital.id, label: hospital.name }));
			setFormHospitals(hospitalArray);
		}
	};

	return (
		<Formik
			validateOnChange={false}
			validateOnBlur={true}
			enableReinitialize={true}
			initialValues={getInitialValues()}
			validationSchema={getValidationSchema}
			onSubmit={handleSubmitMyForm}>
			{({ values, handleSubmit, isSubmitting, resetForm, setFieldValue, setFieldError, errors }) => (
				<Modal
					display={props.isModalOpen}
					position='right'
					onModalSubmit={handleSubmit}
					className='active-directory-form'
					onModalClose={() => onCloseModal(resetForm)}
					shouldSubmitOnEnter={false}
					isLoading={isSubmitting}>
					<Form>
						<h3>{intl.formatMessage({ id: 'externalIdentityProviders' })}</h3>
						<div className='input'>
							<p className='label'>{intl.formatMessage({ id: 'selectConfiguration' })}</p>
							<p className='font-14 semi-bold'>{intl.formatMessage({ id: 'selectConfigurationDesc' })}</p>
							<Select
								isDisabled={props.initialValues?.eipConfigurationTypeId}
								value={values.selectedConfig}
								name='selectedConfig'
								placeholder={intl.formatMessage({ id: 'selectConfiguration' })}
								classNamePrefix='react-select'
								options={configTypes}
								onChange={val => {
									setFieldValue('isCompanyLevelConfiguration', true);
									setFieldValue('selectedConfig', val);
								}}
							/>
						</div>
						{values.selectedConfig?.value === ExternalIdentityProviders.AZURE && (
							<div
								className={classNames('bottom-30 flex', {
									disabled: props.initialValues || getUserRole() === UserRoles.SUPER_ADMIN,
								})}>
								<Field
									type='checkbox'
									checked={values.isCompanyLevelConfiguration}
									name='isCompanyLevelConfiguration'
									onChange={() => {
										setFieldValue('isCompanyLevelConfiguration', !values.isCompanyLevelConfiguration);
										setFieldValue('autoUpdate', !values.isCompanyLevelConfiguration);
										setFieldValue('autoDelete', !values.isCompanyLevelConfiguration);
									}}
								/>
								<label className='font-14 semi-bold'>{intl.formatMessage({ id: 'isCompanyLevelConfiguration' })}</label>
							</div>
						)}
						{!values.isCompanyLevelConfiguration && getUserRole() !== UserRoles.SUPER_ADMIN && (
							<>
								{!props.initialValues && (
									<div className='rs-input'>
										<label>{translate('selectHealthSystem')}</label>
										<Select
											name='healthSystem'
											placeholder={intl.formatMessage({ id: 'selectHealthSystem' })}
											options={props.healthSystems}
											value={values.healthSystem}
											onChange={val => onHealthSystemChange(val, setFieldValue)}
										/>
										<p className='red-error'>{errors?.healthSystem}</p>
									</div>
								)}
								<div className='rs-input'>
									<label>{translate(props.initialValues ? 'name' : 'selectHospital')}</label>
									<Select
										name='hospital'
										placeholder={intl.formatMessage({ id: 'selectHospital' })}
										options={formHospitals}
										value={values.hospital}
										onChange={val => {
											setFieldValue('teamTypeId', TeamTypes.HOSPITAL);
											setFieldValue('hospital', val);
										}}
										isDisabled={!values.healthSystem || values.healthSystem.value === '0' || props.initialValues}
									/>
									<p className='red-error'>{errors?.hospital}</p>
								</div>
							</>
						)}
						<Field
							name='domain'
							type='text'
							label={intl.formatMessage({
								id: values.selectedConfig?.value === ExternalIdentityProviders.AZURE ? 'azureAdUrl' : 'domain',
							})}
							description={getDomainDescription(values.selectedConfig?.value)}
							value={values.domain}
							component={Input}
						/>
						{values.selectedConfig?.value === ExternalIdentityProviders.AZURE && (
							<Field
								name='validGroupName'
								type='text'
								label={intl.formatMessage({ id: 'setValidGroupName' })}
								description={intl.formatMessage({ id: 'setValidGroupNameDescription' })}
								value={values.validGroupName}
								component={Input}
							/>
						)}
						<Field
							name='username'
							type='text'
							label={intl.formatMessage({ id: 'clientId' })}
							description={translate('clientIdDescription', {
								value:
									values.selectedConfig?.value === ExternalIdentityProviders.AZURE
										? intl.formatMessage({ id: 'activeDirectory' })
										: values.selectedConfig?.label,
							})}
							component={Input}
							value={values.username}
						/>
						<Field
							name='password'
							type='password'
							label={intl.formatMessage({ id: 'clientSecret' })}
							description={translate('clientSecretDescription', {
								value:
									values.selectedConfig?.value === ExternalIdentityProviders.AZURE
										? intl.formatMessage({ id: 'activeDirectory' })
										: values.selectedConfig?.label,
							})}
							component={Input}
							value={values.password}
						/>
						{values.selectedConfig?.value === ExternalIdentityProviders.AZURE && !values.isCompanyLevelConfiguration && (
							<>
								<div className='flex'>
									<Field
										type='checkbox'
										name='autoSyncOnLogin'
										onChange={() => {
											if (!values.autoSyncOnLogin) {
												setFieldValue('autoAdd', false);
												setFieldValue('autoDelete', false);
												setFieldValue('autoUpdate', false);
											}
											setFieldValue('autoSyncOnLogin', !values.autoSyncOnLogin);
										}}
									/>
									<label className='font-14 semi-bold'>{intl.formatMessage({ id: 'autoSynchronizeLogin' })}</label>
								</div>
								{values.autoSyncOnLogin && (
									<>
										<CustomInput
											className='ed-input'
											value={values.emailDomain}
											onChange={event => setFieldValue('emailDomain', event.target.value)}
											postfixTooltip={intl.formatMessage({ id: 'addDomain' })}
											tooltipPosition='top'
											type='text'
											prefixIcon='alternate_email'
											postfixIcon='library_add'
											placeholder={intl.formatMessage({ id: 'enterEmailDomain' })}
											name='emailDomain'
											onPostfixClick={() => {
												handleDomainInput({
													value: values.emailDomain,
													setFieldValue,
													setFieldError,
													name: 'emailDomains',
													emailDomains: values.emailDomains,
												});
											}}
											onKeyDown={event =>
												event.keyCode === KeyCodes.ENTER
													? handleDomainInput({
															value: event.target.value,
															setFieldValue,
															setFieldError,
															name: 'emailDomains',
															emailDomains: values.emailDomains,
													  })
													: null
											}
											error={errors.emailDomains}
										/>
										<div className='email-list'>
											{values.emailDomains.map(item => (
												<div key={item}>
													<span>{item}</span>
													<i
														className='material-icons prefix-icon'
														onClick={() => removeEmailFromList(item, setFieldValue, values.emailDomains)}>
														close
													</i>
												</div>
											))}
										</div>
									</>
								)}
							</>
						)}
						{[ExternalIdentityProviders.AZURE, ExternalIdentityProviders.OKTA].includes(values.selectedConfig?.value) && (
							<div className='flex'>
								<Field type='checkbox' name='isSingleSignOutEnabled' />
								<label className='font-14 semi-bold'>{intl.formatMessage({ id: 'isSingleSignOutEnabled' })}</label>
							</div>
						)}
						{values.selectedConfig?.value === ExternalIdentityProviders.AZURE && (
							<>
								{!values.isCompanyLevelConfiguration && (
									<div className='flex'>
										<Field type='checkbox' name='includeRoles' />
										<label className='font-14 semi-bold'>{intl.formatMessage({ id: 'includeRoles' })}</label>
									</div>
								)}
								{!values.isCompanyLevelConfiguration && (
									<div className='flex'>
										<Field
											type='checkbox'
											name='autoAdd'
											onChange={() => {
												if (!values.autoAdd) {
													setFieldValue('autoSyncOnLogin', false);
												}
												setFieldValue('autoAdd', !values.autoAdd);
											}}
										/>
										<label className='font-14 semi-bold'>{intl.formatMessage({ id: 'autoAdd' })}</label>
									</div>
								)}
								<div className='flex'>
									<Field
										type='checkbox'
										name='autoUpdate'
										onChange={() => {
											if (!values.autoUpdate) {
												setFieldValue('autoSyncOnLogin', false);
											}
											setFieldValue('autoUpdate', !values.autoUpdate);
										}}
									/>
									<label className='font-14 semi-bold'>{intl.formatMessage({ id: 'autoUpdate' })}</label>
								</div>
								<div className='flex'>
									<Field
										type='checkbox'
										name='autoDelete'
										onChange={() => {
											if (!values.autoDelete) {
												setFieldValue('autoSyncOnLogin', false);
											}
											setFieldValue('autoDelete', !values.autoDelete);
										}}
									/>
									<label className='font-14 semi-bold'>{intl.formatMessage({ id: 'autoDelete' })}</label>
								</div>
							</>
						)}
						<Alert display={!!error} message={error} variant='error' onClose={() => setError('')} />
					</Form>
				</Modal>
			)}
		</Formik>
	);
};

export default ActiveDirectoryForm;
