import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { useSelector } from 'react-redux';
import translate from 'i18n-translations/translate.jsx';
import useOutsideClick from 'infrastructure/helpers/useOutsideClick.js';
import { addNewPatient } from 'api/tasks';
import { getCompanyId, setHealthCareUserId } from 'infrastructure/auth.js';
import PatientDetailsForm from 'components/CheckInAPatient/PatientDetailsForm.jsx';
import { PatientAgeLimit, AddEditPatientRadioTypes, TaskStatus, MeasurementUnitOptions, AlertTypes } from 'constants/enums.js';
import { Allergies } from 'constants/visitEnums.js';
import { convertToMmol, convertFeetToCm, convertInchToCm, convertToKg } from 'infrastructure/helpers/measurementsHelper.js';
import Loader from 'components/Common/Loader.jsx';
import { getPatientByIdCard } from 'api/patients.js';
import { getCountries } from 'api/users.js';
import { findSectorById, getStorage } from 'infrastructure/helpers/commonHelpers.js';
import PopUpAlert from 'components/Common/PopUpAlert.jsx';
import SocketEvents from 'constants/socket-events.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';

const AddNewPatient = props => {
	const [error, setError] = useState(null);
	const [isGenderOpen, setIsGenderOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isTaskLoading, setIsTaskLoading] = useState(false);
	const [newPatient, setNewPatient] = useState(null);
	const [birthDateError, setBirthDateError] = useState(null);
	const [countries, setCountries] = useState([]);
	const genderWrapperRef = useRef(null);
	const [foodAllergies, setFoodAllergies] = useState([]);
	const [medicationAllergies, setMedicationAllergies] = useState([]);
	const [environmentalAllergies, setEnvironmentalAllergies] = useState([]);
	const [biologicalAllergies, setBiologicalAllergies] = useState([]);
	const helloDeviceId = parseInt(getStorage().getItem('helloDeviceId'), 10);
	const tree = useSelector(state => state.healthSystems.treeData.tree);
	const [alreadyOnCallMessage, setAlreadyOnCallMessage] = useState(null);
	const intl = useIntl();
	const socket = useContext(SocketContext);

	useEffect(() => {
		getCountriesList();
	}, []);

	const getCountriesList = async () => {
		const countriesList = await getCountries();
		if (countriesList.error) {
			setError(countriesList.error.message);
			return;
		}
		setCountries(countriesList.countries);
	};

	const getPatientByCard = useCallback(async () => {
		if (!newPatient) {
			return;
		}
		const addedPatientRes = await getPatientByIdCard(newPatient.idCard);
		if (addedPatientRes.error) {
			setIsLoading(false);
			setError(addedPatientRes.error.message);
			return;
		}
		setHealthCareUserId(addedPatientRes.healthcareUserId);
		props.setPatientUserId(addedPatientRes.userId);
		setIsLoading(false);
		props.setSelectedPatient(newPatient);
		props.hidePatientForm();
		props.setIsSecondTabEnabled(true);
		props.goToSymptoms();
		setIsTaskLoading(false);
	}, [newPatient]);

	useEffect(() => {
		const handleTaskStatusUpdated = async data => {
			if (data.taskStatusId === TaskStatus.COMPLETED) {
				await getPatientByCard();
			}
			if (data.taskStatusId === TaskStatus.FAULTED) {
				setError(translate('somethingWentWrong'));
				setIsTaskLoading(false);
				setIsLoading(false);
			}
		};
		socket.on(SocketEvents.BACKGROUND.TASK_UPDATED, handleTaskStatusUpdated);
		return () => {
			socket.off(SocketEvents.BACKGROUND.TASK_UPDATED, handleTaskStatusUpdated);
		};
	}, [socket, getPatientByCard]);

	const genders = [
		{ id: 1, name: intl.formatMessage({ id: 'male' }) },
		{ id: 2, name: intl.formatMessage({ id: 'female' }) },
	];

	const getDateOfBirth = values => {
		if (!values.birthMonth || !values.birthYear) {
			return null;
		}
		const month = moment(values.birthMonth).format('MM');
		const year = moment(values.birthYear).format('YYYY');
		let day = values.birthDay.toString();
		if (day.length === 1) {
			day = `${0}${day}`;
		}
		return `${year}-${month}-${day}`;
	};

	const onSubmitForm = async values => {
		const allergies = [];
		if (values.allergies?.length > 0) {
			values.allergies.forEach(element => {
				let note = '';
				if (parseInt(element, 10) === Allergies.FOOD.id && foodAllergies.length > 0) {
					const foodArr = [...foodAllergies];
					note = JSON.stringify(foodArr);
				}
				if (parseInt(element, 10) === Allergies.MEDICATION.id && medicationAllergies.length > 0) {
					const medicArr = [...medicationAllergies];
					note = JSON.stringify(medicArr);
				}
				if (parseInt(element, 10) === Allergies.ENVIRONMENTAL.id && environmentalAllergies.length > 0) {
					const environmentalArr = [...environmentalAllergies];
					note = JSON.stringify(environmentalArr);
				}
				if (parseInt(element, 10) === Allergies.BIOLOGICAL.id && biologicalAllergies.length > 0) {
					const biologicalArr = [...biologicalAllergies];
					note = JSON.stringify(biologicalArr);
				}
				allergies.push({
					categoryId: parseInt(element, 10),
					note,
				});
			});
		}

		setError(null);

		if (!moment(getDateOfBirth(values)).isValid() || moment(getDateOfBirth(values)).diff(moment(), 'days') >= 0) {
			setBirthDateError(intl.formatMessage({ id: 'dateOfBirthNotValid' }));
			return;
		}
		if (moment().diff(getDateOfBirth(values), 'years') > PatientAgeLimit.MAX) {
			setBirthDateError(intl.formatMessage({ id: 'maxBirthdayLimit' }));
			return;
		}
		setBirthDateError(null);

		let tobaccoSmokeCode = '';
		if (parseInt(values.isTobaccoSmoker, 10) === AddEditPatientRadioTypes.YES) {
			tobaccoSmokeCode = values.tobaccoYesCode;
		}
		if (parseInt(values.isTobaccoSmoker, 10) === AddEditPatientRadioTypes.NO) {
			tobaccoSmokeCode = values.tobaccoNoCode;
		}

		const deviceId = getStorage().getItem('helloDeviceId');
		const params = {
			companyId: getCompanyId(),
			deviceId: parseInt(deviceId, 10),
			idCard: values.idNumber.toString(),
			firstName: values.firstName.trim(),
			lastName: values.lastName.trim(),
			address: values.address,
			phoneNumber: values.phoneNumber.toString(),
			email: values.emailAddress,
			genderId: values.gender.id,
			birthDate: getDateOfBirth(values) || null,
			showConsent: values.showConsent,
			healthInformation: {
				isTobaccoSmoker: parseInt(values.isTobaccoSmoker, 10),
				hasDiabet: parseInt(values.hasDiabet, 10),
				hasHyperTension: parseInt(values.hasHyperTension, 10),
				isTakingMedication: parseInt(values.isTakingMedication, 10),
				hasAllergy: parseInt(values.hasAllergy, 10),
				hasPreExistingCondition: parseInt(values.hasPreExistingCondition, 10),
				height:
					values.heightUnit === MeasurementUnitOptions.HEIGHT.value[1]?.text || !values.height
						? values.height.toString()
						: convertFeetToCm(parseFloat(values.height)).toString(),
				heightUnit: 'cm',
				weight:
					values.weightUnit === MeasurementUnitOptions.WEIGHT.value[1]?.text || !values.weight
						? values.weight.toString()
						: convertToKg(parseFloat(values.weight)).toString(),
				weightUnit: 'kg',
				totalCholesterol:
					values.totalCholesterolUnit === MeasurementUnitOptions.BLOOD_GLUCOSE.value[1]?.text || !values.totalCholesterol
						? values.totalCholesterol.toString()
						: convertToMmol(parseFloat(values.totalCholesterol)).toString(),
				totalCholesterolUnit: values.totalCholesterol ? 'mmol/L' : '',
				hdlCholesterol:
					values.hdlCholesterolUnit === MeasurementUnitOptions.BLOOD_GLUCOSE.value[1]?.text || !values.hdlCholesterol
						? values.hdlCholesterol.toString()
						: convertToMmol(parseFloat(values.hdlCholesterol)).toString(),
				hdlCholesterolUnit: values.hdlCholesterol ? 'mmol/L' : '',
				waistCircumference:
					values.waistCircumferenceUnit === MeasurementUnitOptions.WAIST_CIRCUMFERENCE.value[1]?.text ||
					!values.waistCircumference
						? values.waistCircumference.toString()
						: convertInchToCm(parseFloat(values.waistCircumference)).toString(),
				waistCircumferenceUnit: values.waistCircumference ? 'cm' : '',
				tobaccoSmokeCode,
				diabeticStatusCode: parseInt(values.hasDiabet, 10) === AddEditPatientRadioTypes.YES ? values.diabeticStatusCode : '',
				allergies,
				preExistingCondition: values.preExistingCondition.trim(),
			},
			postalCode: values.zipCode?.toString(),
			city: values?.city,
			country: values.country,
			communicationLanguage: null,
		};
		const room = findSectorById(tree, helloDeviceId);
		if (room?.deviceBusy) {
			setAlreadyOnCallMessage(intl.formatMessage({ id: 'youAreAlreadyOnCall' }));
			return;
		}
		if (params.healthInformation?.allergies?.some(item => !item.note)) {
			return;
		}
		setIsLoading(true);
		const response = await addNewPatient(params);

		if (response.error) {
			setError(response.error.message);
			setIsLoading(false);
			return;
		}
		setNewPatient(params);
		setIsTaskLoading(true);
	};

	useOutsideClick(genderWrapperRef, () => {
		if (isGenderOpen) {
			setIsGenderOpen(false);
		}
	});

	const toggleGenderDropDown = (item, formikProps) => {
		formikProps.setFieldValue('gender', item);
		setIsGenderOpen(false);
	};

	return (
		<>
			{isTaskLoading && (
				<div className='full-width full-height flex flex-align-center flex-justify-center column-direction'>
					<Loader />
					<h4>{translate('patientIsBeingRegistered')}...</h4>
				</div>
			)}
			{!isTaskLoading && props.display && (
				<PatientDetailsForm
					title={translate('addNewPatient')}
					isAddPatient={true}
					genderWrapperRef={genderWrapperRef}
					setIsGenderOpen={() => setIsGenderOpen(prevState => !prevState)}
					isGenderOpen={isGenderOpen}
					genders={genders}
					toggleGenderDropDown={toggleGenderDropDown}
					birthDateError={birthDateError}
					setBirthDateError={value => setBirthDateError(value)}
					submitButtonText={intl.formatMessage({ id: 'addPatient' })}
					countries={countries}
					foodAllergies={foodAllergies}
					environmentalAllergies={environmentalAllergies}
					biologicalAllergies={biologicalAllergies}
					setFoodAllergies={setFoodAllergies}
					medicationAllergies={medicationAllergies}
					setMedicationAllergies={setMedicationAllergies}
					setEnvironmentalAllergies={setEnvironmentalAllergies}
					setBiologicalAllergies={setBiologicalAllergies}
					hidePatientForm={props.hidePatientForm}
					getDateOfBirth={getDateOfBirth}
					onSubmitForm={onSubmitForm}
					isLoading={isLoading}
					error={error}
				/>
			)}
			<PopUpAlert
				alertType={AlertTypes.DANGER}
				display={alreadyOnCallMessage}
				onAlertClose={() => setAlreadyOnCallMessage(null)}
				contentText={alreadyOnCallMessage}
				isSilent={true}
				center={true}
			/>
		</>
	);
};

export default AddNewPatient;
