import Grid from 'components/Common/Grid.jsx';
import { StreamError } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import { isMacintosh } from 'infrastructure/helpers/commonHelpers.js';

const StreamPermissions = props => (
	<div style={{ textAlign: 'center' }}>
		<Grid
			className='incoming-calls'
			columns='1fr'
			rows='1fr'
			horizAlign='center'
			vertAlign='center'
			stretch='100vh'
			backgroundColor='rgba(0,0,0,.85)'>
			<div>
				{props.reason.type === StreamError.PERMISSION_DISMISSED.type && (
					<div>
						<h2>{translate('clickAllowToMakeCall')}</h2>
						<br />
						<p>
							{translate('allowPermissionToMakeCall')}
							<br />
							{translate('participantHearAndSeeYou')}
						</p>
						<br />
						<p>{translate('canTurnOffMicCameraLater')}</p>
					</div>
				)}

				{props.reason.type === StreamError.STREAM_NOT_ALLOWED.type && (
					<div>
						<h3>{translate('turnOnMicCamera')}</h3>
						<br />
						<p>{translate('allowPermissionToMakeCall')}</p>
					</div>
				)}

				{props.reason.type === StreamError.DEVICE_NOT_FOUND.type && (
					<div>
						<h2>{translate('deviceNotFound')}</h2>
						<br />
						<p>
							{translate('videoCallTurnOnCamera')}
							<br />
							{translate('audioCallTurnOnMicRefreshPage')}
						</p>
					</div>
				)}

				{props.reason.type === StreamError.NOT_READABLE_ERROR.type && (
					<div>
						<h2 style={{ color: '#fff' }}>{translate('cameraInUseHeader')}</h2>
						<br />
						<p style={{ color: '#fff' }}>
							{translate('cameraInUseParagraph1')}.
							<br />
							{translate('cameraInUseParagraph2')}.
						</p>
					</div>
				)}
				{props.reason.type === StreamError.CAMERA_BLOCKED.type && (
					<div className='blocked-stream'>
						<main>
							<p>{window.location.origin}</p>
							<img
								src='https://static.solaborate.com/healthcare-system/healthcare-camera-permission.svg'
								alt='Camera permission'
							/>
						</main>
						<h3>{translate('popUpCameraBlockedTitle')}</h3>
						<br />
						<p>
							• {translate('popUpCameraBlockedMessage')}
							<br />• {translate('allowAccessRefreshPage')}
						</p>
					</div>
				)}

				{props.reason.type === StreamError.MICROPHONE_BLOCKED.type && (
					<div className='blocked-stream'>
						<main>
							<p>{window.location.origin}</p>
							<img
								src={`https://static.solaborate.com/healthcare-system/${
									isMacintosh() ? 'healthcare-microphone-permission.svg' : 'healthcare-camera-permission.svg'
								}`}
								alt='Microphone permission'
							/>
						</main>
						<h3>{translate('popUpMicrophoneBlockedTitle')}</h3>
						<br />
						<p>
							• {translate('popUpMicrophoneBlockedMessage')}
							<br />• {translate('allowAccessRefreshPage')}
						</p>
					</div>
				)}

				{props.reason.type === StreamError.CAM_AND_MIC_BLOCKED.type && (
					<div className='blocked-stream'>
						<main>
							<p>{window.location.origin}</p>
							<img
								src='https://static.solaborate.com/healthcare-system/healthcare-camera-permission.svg'
								alt='Microphone and camera permission'
							/>
						</main>
						<h3>{translate('popUpCameraAndMicrophoneBlockedTitle')}</h3>
						<br />
						<p>
							• {translate('popUpCameraBlockedMessage')}
							<br />• {translate('allowAccessRefreshPage')}
						</p>
					</div>
				)}
			</div>
		</Grid>
	</div>
);

export default StreamPermissions;
