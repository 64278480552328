import { PrimaryCareForms } from 'constants/visitEnums.js';
import { laboratoryTests, radiologyTests } from 'containers/PrimaryCare/LaboratoryAnalysis/constants/labForms.js';
import translate from 'i18n-translations/translate.jsx';

export const toAddMedicalForms = intl => [
	{
		id: PrimaryCareForms.LABORATORY,
		code: `DM 0103 ${intl.formatMessage({ id: 'laboratory' })}`,
		title: translate('instructionsForDiagnosticResearch'),
		addedTests: [],
		additionalNotes: '',
		name: intl.formatMessage({ id: 'laboratory' }),
		tests: laboratoryTests(intl),
	},
	{
		id: PrimaryCareForms.RADIOLOGY,
		code: `DM 0103 ${intl.formatMessage({ id: 'radiology' })}`,
		title: translate('instructionsForDiagnosticResearch'),
		addedTests: [],
		additionalNotes: '',
		name: intl.formatMessage({ id: 'radiology' }),
		tests: radiologyTests(intl),
	},
	{
		id: PrimaryCareForms.PTH_INFUSION,
		code: `DM 0108 ${intl.formatMessage({ id: 'infusion' })}`,
		title: translate('instructionsForInfusion'),
		medicationList: [{ id: 0, name: '', note: '', dosage: '', frequency: '', duration: '' }],
		name: intl.formatMessage({ id: 'infusion' }),
	},
	{
		id: PrimaryCareForms.PTH_INJECTION,
		code: `DM 0108 ${intl.formatMessage({ id: 'injection' })}`,
		title: translate('instructionsForInjection'),
		medicationList: [{ id: 0, name: '', note: '', dosage: '', frequency: '', duration: '' }],
		name: intl.formatMessage({ id: 'injection' }),
	},
	{
		id: PrimaryCareForms.DRUGS,
		code: intl.formatMessage({ id: 'drugs' }),
		medicationList: [{ id: 0, name: '' }],
		name: intl.formatMessage({ id: 'drugs' }),
	},
];
