import { Mic, Cam } from '@solaborate/calls/webrtc';
import { enums } from '@solaborate/calls';
import html2canvas from 'html2canvas';
import { UserRoles, UserTypes } from 'calls/enums/index.js';
import RemoteHelloParticipant from 'calls/RemoteHelloParticipant.js';
import { getUserRole } from 'infrastructure/auth.js';
import { getStorage } from 'infrastructure/helpers/commonHelpers.js';
import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';

const imageUrlToBase64 = url =>
	fetch(url)
		.then(response => response.blob())
		.then(
			blob =>
				new Promise((resolve, reject) => {
					const reader = new FileReader();
					reader.onloadend = () => resolve(reader.result);
					reader.onerror = reject;
					reader.readAsDataURL(blob);
				})
		);

const captureFrame = async element => {
	if (!element) {
		return;
	}
	const canvas = await html2canvas(element);
	const anchor = document.createElement('a');
	anchor.href = canvas.toDataURL('image/jpeg');
	anchor.download = `${+new Date()}.jpeg`;
	anchor.hidden = true;
	document.body.appendChild(anchor);
	anchor.click();
	anchor.remove();
};

/**
 * Method used to return requested track types from call type
 * @param {import('@solaborate/calls').types.CallType} callType
 */
const callTypeToTrackTypes = callType => {
	let localTrackTypes = [Mic, Cam];
	let remoteTrackTypes = [Mic, Cam];

	if (callType === enums.CallTypes.SECURITYCAM) {
		localTrackTypes = [];
	} else if (callType === enums.CallTypes.MONITORING) {
		localTrackTypes = [];
		remoteTrackTypes = [Cam];
	} else if (callType === enums.CallTypes.AUDIO || callType === enums.CallTypes.FIRST_RESPONDER) {
		localTrackTypes = [Mic];
		remoteTrackTypes = [Mic];
	}

	return {
		localTrackTypes,
		remoteTrackTypes,
	};
};

const getUserRoleId = () => {
	const userRoleId =
		sessionStorage.getItem('userRole') === UserRoles.GUEST
			? sessionStorage.getItem('userRoleId')
			: getStorage().getItem('userRoleId');

	if (!userRoleId) {
		return null;
	}

	return +userRoleId;
};

const isMedicalInfoModalsOpen = medicalDataControls => {
	const {
		isDiagnosesVisible,
		isProceduresVisible,
		isNotesVisible,
		isPrescriptionsVisible,
		isPhysicalExercisesVisible,
		isCareEventsFormVisible,
	} = medicalDataControls;

	return (
		isDiagnosesVisible ||
		isProceduresVisible ||
		isNotesVisible ||
		isPrescriptionsVisible ||
		isPhysicalExercisesVisible ||
		isCareEventsFormVisible
	);
};

const attachSinkId = async (element, sinkId) => {
	if (typeof element?.sinkId !== 'undefined') {
		try {
			return await element.setSinkId(sinkId);
		} catch (error) {
			return { error };
		}
	} else {
		return { error: 'Browser does not support output device selection.' };
	}
};

const getConferenceEndedTimeout = () => {
	const threeSeconds = 3000;
	const tenSeconds = 10000;
	if (getUserRole() === UserRoles.VISITOR) {
		return tenSeconds;
	}
	return threeSeconds;
};

const getSavedStreamSettings = () => {
	const streamSettings = getStorage().getItem('streamSettings');
	return streamSettings ? JSON.parse(streamSettings) : {};
};

const changeLocalParticipantBackground = async ({ localParticipant, selectedBackground }) => {
	localParticipant.localTrackController.setBackground(selectedBackground);
};

const findRemoteHelloParticipant = participants => {
	return [...participants.values()].find(item => item instanceof RemoteHelloParticipant);
};

const findPatientRoleParticipant = participants => {
	return [...participants.values()].find(item => item?.role === UserTypes.PATIENT);
};

const getCallsButtonBackground = darkMode => {
	return darkMode ? DarkTheme.colors.grayFour : LightTheme.colors.grayZero;
};

const getCallsControlsButtonBackground = (darkMode, modalCallControlsButton = false) => {
	if (modalCallControlsButton) {
		return darkMode ? DarkTheme.colors.grayThree : LightTheme.colors.grayNinetyEight;
	}
	return darkMode ? DarkTheme.colors.grayFour : LightTheme.colors.grayNinetyEight;
};

export {
	captureFrame,
	callTypeToTrackTypes,
	getUserRoleId,
	imageUrlToBase64,
	getConferenceEndedTimeout,
	attachSinkId,
	getSavedStreamSettings,
	isMedicalInfoModalsOpen,
	changeLocalParticipantBackground,
	getCallsButtonBackground,
	getCallsControlsButtonBackground,
	findRemoteHelloParticipant,
	findPatientRoleParticipant,
};
