import React, { useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { getCpmList } from 'api/rpm.js';
import classNames from 'classnames';
import Alert from 'components/Common/Alert.jsx';
import ProfilePicture from 'components/Common/ProfilePicture.jsx';
import { Gender, WearableProviderIds, WearablesStatus } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import { getUserId, getUserInfo, getUserRole } from 'infrastructure/auth.js';
import { getGender } from 'infrastructure/helpers/commonHelpers.js';
import { monthDayYear } from 'infrastructure/helpers/dateHelper.js';
import { useSelector } from 'react-redux';
import { getExternalDevices } from 'api/lifeSignals.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SocketEvents from 'constants/socket-events.js';
import Button from 'components/Common/Button.jsx';
import { APP_CONFIG } from 'constants/global-variables.js';
import LifeSignalsMeasurements from 'containers/Rpm/LifeSignalsMeasurements.jsx';
import CustomTable from 'components/Common/CustomTable.jsx';
import { LifeSignalsTypes } from 'constants/rpm.js';

const ContinuousPatientMonitoring = ({
	searchedPatient = null,
	selectedPatient = null,
	isOverview = false,
	setIsLifeSignalsOpen = isOpen => isOpen,
}) => {
	const intl = useIntl();
	const [patients, setPatients] = useState([]);
	const [error, setError] = useState(null);
	const [iframeSrc, setIframeSrc] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [isInit, setIsInit] = useState(true);
	const [measurements, setMeasurements] = useState({});
	const [lifeSignalsAlerts, setLifeSignalsAlerts] = useState(null);
	const [pagination, setPagination] = useState({ totalCount: 0, pageIndex: 0 });
	const hasReachedEnd = useRef(null);
	const isDarkMode = useSelector(state => state.user.darkMode);
	const socket = useContext(SocketContext);

	useEffect(() => {
		const getPatients = async () => {
			const queryParams = {
				role: getUserRole(),
				pageIndex: pagination.pageIndex,
				pageSize: 50,
				search: searchedPatient,
				gender: null,
				rpm: null,
			};
			const response = await getCpmList({ userId: getUserId(), queryParams });
			if (response.error) {
				setError(response.error.message);
				setIsLoading(false);
				return;
			}
			if (!selectedPatient) {
				setPatients(prevState =>
					pagination.pageIndex === 0 ? response.cpmListPatients : [...prevState, ...response.cpmListPatients]
				);
			} else {
				setPatients([selectedPatient]);
			}
			hasReachedEnd.current = response.cpmListPatients.length < 50;
			if (isInit) {
				socket.emit(SocketEvents.LifeSignals.CPM_SUBSCRIBE, {
					patientIds: selectedPatient?.userId ? [selectedPatient?.userId] : response.patientUserIds,
					nurseId: getUserInfo()?.userId,
				});
			}
			setIsInit(false);
			setIsLoading(false);
			setPagination(prevState => ({ ...prevState, totalCount: response.totalCount }));
		};

		getPatients();
	}, [pagination.pageIndex, searchedPatient]);

	useEffect(() => {
		const getLifeSignalVitalSign = (type, lifeSignals, isTemperature = false) => {
			const lastValidValue = lifeSignals.SensorData.reduce((acc, current) => {
				if (current[type] && current[type].length > 0 && current[type][current[type].length - 1] >= 0) {
					return current[type][current[type].length - 1];
				}
				return acc;
			}, null);

			return lastValidValue && isTemperature ? lastValidValue / 1000 : lastValidValue;
		};
		const measurementsListener = result => {
			if (patients.length === 0) {
				return;
			}
			const foundPatient = patients.some(item => item.userId === +result.PatientId);
			if (foundPatient) {
				setMeasurements(prevMeasurements => ({
					...prevMeasurements,
					[result.PatientId]: {
						RR: getLifeSignalVitalSign('RR', result),
						SPO2: getLifeSignalVitalSign('SPO2', result),
						HR: getLifeSignalVitalSign('HR', result),
						BPSYS: getLifeSignalVitalSign('BPSYS', result),
						BPDIA: getLifeSignalVitalSign('BPDIA', result),
						BODYTEMP: getLifeSignalVitalSign('BODYTEMP', result, true),
					},
				}));

				if (result.StreamAlert?.length > 0) {
					setLifeSignalsAlerts({ result: result.StreamAlert, userId: +result.PatientId });
				}
			}
		};
		socket.on(SocketEvents.LifeSignals.PATCH_DATA, measurementsListener);
		return () => {
			socket.off(SocketEvents.LifeSignals.PATCH_DATA, measurementsListener);
		};
	}, [socket, patients, measurements]);

	useEffect(() => {
		return () => {
			socket.emit(SocketEvents.LifeSignals.CPM_UN_SUBSCRIBE, {
				nurseId: getUserInfo()?.userId,
			});
		};
	}, [socket]);

	const getExternalDevice = async patientId => {
		const response = await getExternalDevices({ patientId, status: WearablesStatus.ACTIVE });
		if (response.error) {
			setError(response.error.message);
			return;
		}
		if (!response.error && response?.patientExternalIotDevices?.length > 0) {
			const found = response.patientExternalIotDevices.find(
				wearable => wearable.externalIotDeviceType?.vendorId === WearableProviderIds.LIFE_SIGNALS
			);
			if (found) {
				setIframeSrc(`${APP_CONFIG.lifeSignalsUrl}/#/dashboard/dashboard-zoom-view/${found.iotDeviceId}`);
				setIsLifeSignalsOpen(true);
			}
		}
	};

	const displayCpmPatients = () =>
		patients.map(patient => {
			const lifeSignalDetails = LifeSignalsTypes.reduce((acc, measurement) => {
				acc[measurement.name] = (
					<LifeSignalsMeasurements
						lifeSignalsAlerts={lifeSignalsAlerts}
						measurements={measurements}
						patient={patient}
						currentMeasurement={measurement}
					/>
				);
				return acc;
			}, {});

			return {
				patient: (
					<div key={patient.userId} className='flex full-width'>
						<div className='cpm-patient-details'>
							<ProfilePicture
								className='doctor-request-img'
								fullName={`${patient.firstName} ${patient.lastName}`}
								profilePicture={patient.profilePicture}
							/>
							<div>
								<h4>
									{patient.firstName} {patient.lastName}
								</h4>
								<span>
									{patient.genderId === Gender.UNAVAILABLE
										? `${intl.formatMessage({ id: 'sex' })}: N/A`
										: getGender(patient.genderId).description}
								</span>
								<p>
									<span>DOB: {patient.dateOfBirth ? monthDayYear(patient.dateOfBirth) : 'N/A'}</span>
								</p>
								<p>
									<span className='mrn'>MRN: {patient.mrn || 'N/A'}</span>
								</p>
							</div>
						</div>
					</div>
				),
				...lifeSignalDetails,
				actions: (
					<div className='flex position-relative'>
						<Button
							text={translate('openLifeSignals')}
							className='life-signals-btn'
							onClick={() => getExternalDevice(patient.userId)}
						/>
					</div>
				),
			};
		});

	return (
		<div
			className={classNames(
				{ 'patient-alerts-wrapper rpm rpm-wrapper top-10': !selectedPatient },
				iframeSrc ? 'life-signals-open' : 'full-width'
			)}>
			<CustomTable
				headers={[
					!selectedPatient && { title: translate('patient'), id: 'patient' },
					{ title: translate('respiration'), id: 'respiration' },
					{ title: translate('oxygen'), id: 'oxygen' },
					{ title: translate('heartRate'), id: 'heartRate' },
					{ title: translate('bloodPressure'), id: 'bloodPressure' },
					{ title: translate('bodyTemperature'), id: 'bodyTemperature' },
					!isOverview && { title: translate('actions'), id: 'actions' },
				].filter(Boolean)}
				rows={displayCpmPatients()}
				className='hospital-at-home-table cpm patient-list-table rpm-table cpm'
				isLoading={isLoading}
				setPagination={setPagination}
			/>

			{iframeSrc && (
				<aside className='right-side live-examination-kit iframe-view life-signals-view'>
					<Button
						className={classNames('back-button', { 'back-button-dark': isDarkMode })}
						color='var(--blue-5)'
						icon='arrow_back'
						onClick={() => {
							setIframeSrc(null);
							setIsLifeSignalsOpen(false);
						}}
					/>
					<iframe title='Bio beat' src={iframeSrc} />
				</aside>
			)}
			<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
		</div>
	);
};

export default ContinuousPatientMonitoring;
