import React, { useRef } from 'react';
import { addAlertActivity, addManualAlertActivity } from 'api/alerts.js';
import { MeasurementActivityTypes, AlertTabs } from 'constants/enums.js';
import { getUserInfo } from 'infrastructure/auth.js';
import { insertMessage } from 'api/messenger.js';
import { useSelector } from 'react-redux';

const ChatAdaptiveCard = ({ payload, conversationId }) => {
	const isDarkMode = useSelector(state => state.user.darkMode);

	const formatDate = isoString => {
		const date = new Date(isoString);
		return date
			.toLocaleString('en-US', {
				weekday: 'short',
				month: 'short',
				day: 'numeric',
				year: 'numeric',
				hour: 'numeric',
				minute: 'numeric',
				hour12: true,
			})
			.replace(',', ' at');
	};

	const formatMessage = text => {
		const dateRegex = /{{DATE\((.*?)\s*,\s*(.*?)\)}}/;
		const timeRegex = /{{TIME\((.*?)\)}}/;
		const dateMatch = text.match(dateRegex);
		const timeMatch = text.match(timeRegex);
		if (dateMatch && timeMatch) {
			const dateStr = dateMatch[1];
			const dateObj = new Date(dateStr);
			return formatDate(dateObj);
		}
		return text;
	};

	const getTextVariant = size => {
		switch (size) {
			case 'small':
				return 'subtitle2';
			case 'medium':
				return 'body1';
			case 'large':
				return 'h6';
			case 'extraLarge':
				return 'h5';
			default:
				return 'body2';
		}
	};

	const getColor = color => {
		switch (color) {
			case 'attention':
				return 'var(--dark-theme-salmon-1)';
			case 'accent':
				return 'var(--dark-theme-blue-1)';
			case 'light':
				return isDarkMode ? 'var(--gray-2)' : 'var(--gray-4)';
			default:
				return 'inherit';
		}
	};

	const renderElement = element => {
		switch (element.type) {
			case 'TextBlock':
				return (
					<p
						key={element.text}
						className={`ac-textBlock ${getTextVariant(element.size)} ${element.weight === 'bolder' ? 'bold' : ''}`}
						style={{
							color: getColor(element.color),
							marginTop: element.spacing === 'medium' ? '16px' : '8px',
							marginBottom: element.spacing === 'none' ? '0px' : '8px',
						}}>
						{formatMessage(element.text)}
					</p>
				);

			case 'ColumnSet':
				return (
					<div
						key={element.columns.map((_, idx) => `col-${idx}`).join('-')}
						className='ac-columnSet '
						style={{ borderBottom: '1px solid var(--gray-2)', paddingBottom: '5px' }}>
						{element.columns.map((col, idx) => (
							<div key={`col-${idx}`} className='ac-column'>
								{col.items.map(item => renderElement(item))}
							</div>
						))}
					</div>
				);

			case 'Image':
				return (
					<div key={element.url}>
						<img key={element.url} alt={element.alt || 'Image'} src={element.url} className='ac-image  ' />
					</div>
				);

			case 'RichTextBlock':
				return (
					<p
						key={element.inlines.map(inline => inline.text).join(' ')}
						className='ac-richTextBlock'
						style={{ borderBottom: '1px solid var(--gray-2)', paddingBottom: '5px' }}>
						{element.inlines.map((inline, idx) => (
							<span
								key={`inline-${idx}`}
								className={inline.weight === 'bolder' ? 'bold' : ''}
								style={{ color: getColor(inline.color) }}>
								{formatMessage(inline.text)}
							</span>
						))}
					</p>
				);

			default:
				return null;
		}
	};

	const payLoadRef = useRef(payload);
	const getSelectedAction = id => payLoadRef.current.actions.find(item => item.id === id);

	const getMessageObject = content => ({
		attachments: [],
		clientMessageId: `${conversationId}_${getUserInfo().userId}_${new Date().getTime()}`,
		content,
		links: [],
		mentions: [],
	});

	const confirm = async actionId => {
		const selectedAction = getSelectedAction(actionId);
		if (!selectedAction) {
			return;
		}

		if ([AlertTabs.AI, AlertTabs.MEASUREMENT].includes(selectedAction.data.MainAlertType)) {
			const params = {
				alertId: selectedAction.data.AlertId,
				activityType: MeasurementActivityTypes.ADDED_AS_HOSPITAL_CARETAKER,
				isAiAlert: selectedAction.data.MainAlertType === AlertTabs.AI,
			};
			await addAlertActivity(params);
		} else {
			await addManualAlertActivity(selectedAction.data.AlertId, MeasurementActivityTypes.ADDED_AS_HOSPITAL_CARETAKER);
		}

		insertMessage(
			conversationId,
			getMessageObject(
				selectedAction?.data?.CaseId ? `Case: ${selectedAction?.data?.CaseId}. ${selectedAction?.title}` : selectedAction.title
			)
		);
	};

	const reject = actionId => {
		const selectedAction = getSelectedAction(actionId);
		if (!selectedAction) {
			return;
		}

		insertMessage(
			conversationId,
			getMessageObject(
				selectedAction?.data?.CaseId ? `Case: ${selectedAction?.data?.CaseId}. ${selectedAction?.title}` : selectedAction?.title
			)
		);
	};

	const actionTypes = { POSITIVE: 'positive', NEGATIVE: 'negative' };

	return (
		<div className='ac-adaptiveCard'>
			<div className='ac-card'>
				<div className='ac-cardContent'>
					{payload.body.map((element, idx) => (
						<div key={`element-${idx}`}>{renderElement(element)}</div>
					))}
				</div>
				<div className='ac-actions'>
					{payload?.actions?.map(action => (
						<div className='right-15' key={action?.id}>
							{action?.id === actionTypes.POSITIVE && (
								<button className='ac-button ac-buttonPositive' onClick={() => confirm(action.id)}>
									I've Got This
								</button>
							)}
							{action?.id === actionTypes.NEGATIVE && (
								<button className='ac-button ac-buttonNegative' onClick={() => reject(action.id)}>
									I Can't Make It
								</button>
							)}
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default ChatAdaptiveCard;
