import classNames from 'classnames';
import ProfilePicture from 'components/Common/ProfilePicture.jsx';
import { CareEventStatus, CareEventTypes } from 'constants/care-events.js';
import translate from 'i18n-translations/translate.jsx';
import { getPriorityColor, getStatusDescription } from 'infrastructure/helpers/careEventsHelper.jsx';
import { capitalizeFirstLetter } from 'infrastructure/helpers/commonHelpers.js';
import { defaultDateFormat, getHoursMinutesSeconds } from 'infrastructure/helpers/dateHelper.js';
import moment from 'moment';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';
import Priority from 'icons/CareEvents/Priority.jsx';
import Reassigned from 'icons/CareEvents/Reassigned.jsx';
import InProgress from 'icons/CareEvents/InProgress.jsx';
import New from 'icons/CareEvents/New.jsx';
import Completed from 'icons/CareEvents/Completed.jsx';

const CareEventDetails = ({
	isCareEventCreated = false,
	history = [],
	index = 0,
	status,
	user,
	dateCreated,
	careEventId,
	item = null,
	selectedCareEvent = null,
	priority = null,
	careMember = null,
}) => {
	const foundCareEvent = CareEventTypes.find(el => careEventId === el.careEventId);
	const isCareMemberUpdated = item?.careMember?.id !== selectedCareEvent?.careMember?.id;
	const isPriorityUpdated = item?.priorityId !== selectedCareEvent?.priorityId;
	const isDarkMode = useSelector(state => state.user.darkMode);
	const intl = useIntl();

	const getCareEventDetails = (currentStatus, isEventUpdated = false) => {
		if (priority?.value && isPriorityUpdated && priority?.isChanged) {
			return {
				text: 'priorityUpdated',
				ico: <Priority color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
			};
		}
		if (careMember?.value && isCareMemberUpdated && careMember?.isChanged) {
			return {
				text: 'reassigned',
				ico: <Reassigned color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
			};
		}
		switch (currentStatus) {
			case CareEventStatus.NEW:
				return {
					text: 'statusUpdated',
					ico: isEventUpdated ? (
						<InProgress color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />
					) : (
						<New color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />
					),
				};
			case CareEventStatus.IN_PROGRESS:
				return {
					text: 'statusUpdated',
					ico: <InProgress color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
				};
			case CareEventStatus.COMPLETED:
				return {
					text: 'eventCompleted',
					ico: <Completed color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
				};
			default:
				return { text: 'eventCreated', ico: <New color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} /> };
		}
	};

	return (
		<div className='care-event-history-wrapper'>
			<div className='flex'>
				<div className='top-15 right-15 position-relative timeline-wrapper'>
					{index > 0 && (
						<div className='care-event-minutes'>
							<span>{getHoursMinutesSeconds(moment(dateCreated), moment(history[index - 1]?.dateCreated))}</span>
						</div>
					)}

					{isCareEventCreated && history.length > 0 && (
						<div className='care-event-minutes'>
							<span>{getHoursMinutesSeconds(moment(dateCreated), moment(history[history.length - 1]?.dateCreated))}</span>
						</div>
					)}
					<div className='event-icon'>{getCareEventDetails(status?.value, !isCareEventCreated)?.ico}</div>
				</div>
				<div>
					<div className='flex flex-align-end'>
						<p className='bold no-margin'>
							{isCareEventCreated
								? translate('eventCreated', { value: translate(foundCareEvent.name) })
								: translate(getCareEventDetails(status?.value)?.text, {
										value: translate(foundCareEvent.name),
								  })}
						</p>
						<p className={classNames('event-status', getStatusDescription(status?.value))}>
							{translate(getStatusDescription(status?.value))}
						</p>
					</div>
					<div className='flex'>
						<ProfilePicture
							className='doctor-request-img'
							fullName={`${user.firstName} ${user.lastName}`}
							profilePicture={user?.profilePicture}
						/>
						<div className='flex flex-align-center'>
							<p>{`${user.firstName} ${user.lastName}`}</p>
							<p className='event-date'>{defaultDateFormat(dateCreated)}</p>
						</div>
					</div>
					{!isCareEventCreated && careMember?.value && careMember?.isChanged && (
						<div>
							<p className='no-margin'>{intl.formatMessage({ id: 'assignedCareTeam' })}:</p>
							<span className='notes-description'>{`${careMember?.value?.firstName} ${careMember?.value?.lastName}`}</span>
						</div>
					)}
					{!isCareEventCreated && priority?.value && isPriorityUpdated && priority?.isChanged && (
						<div>
							<p className='no-margin'>{intl.formatMessage({ id: 'priority' })}:</p>
							<label className={classNames('care-events-priority', getPriorityColor(priority?.value))}>
								{translate(getPriorityColor(priority?.value))}
							</label>
						</div>
					)}
					{!isCareEventCreated && history.length > 0 && history[index]?.note && (
						<div>
							<p className='no-margin'>{capitalizeFirstLetter(intl.formatMessage({ id: 'note' }))}:</p>
							<span className='notes-description'>{history[index]?.note}</span>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default CareEventDetails;
