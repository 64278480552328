import translate from 'i18n-translations/translate.jsx';
import { useSelector } from 'react-redux';
import EmptyState from 'components/Common/EmptyState.jsx';

const DeviceOffline = () => {
	const helloName = useSelector(state => state.company.companySettings.helloName);

	return (
		<div className='empty-state-wrapper'>
			<EmptyState
				title={translate('helloIsDisconnected', { value: helloName })}
				paragraph={translate('connectionCannotEstablish', { value: helloName })}
				image='no-hello.svg'
			/>
		</div>
	);
};

export default DeviceOffline;
