import LightTheme from 'calls/styles/LightTheme.js';

const Messages = props => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='24'
		height='24'
		viewBox='0 -960 960 960'
		color={LightTheme.colors.graySeven}
		{...props}>
		<path
			d='M80-80v-720q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v480q0 33-23.5 56.5T800-240H240L80-80Zm160-320h320v-80H240v80Zm0-120h480v-80H240v80Zm0-120h480v-80H240v80Z'
			fill='currentColor'
		/>
	</svg>
);

export default Messages;
