import CustomTable from 'components/Common/CustomTable.jsx';
import { Button } from 'components/index.js';
import { RequestAcuity, RequestType, VisitReasons } from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import ContactInfo from 'containers/CardRequest/ContactInfo.jsx';
import translate from 'i18n-translations/translate.jsx';
import { getDescFromEnum } from 'infrastructure/helpers/commonHelpers.js';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

const CardRequest = ({ requests, joinCall, rejectRequest }) => {
	const intl = useIntl();
	const companySettings = useSelector(state => state.company.companySettings);
	const tableHeaders = [
		{ title: 'MRN', id: 'mrn' },
		{ title: intl.formatMessage({ id: 'reasonToConnect' }), id: 'reasonToConnect' },
		{ title: intl.formatMessage({ id: 'acuity' }), id: 'acuity' },
		{ title: intl.formatMessage({ id: 'clinicalConcern' }), id: 'clinicalConcern' },
		{ title: intl.formatMessage({ id: 'somethingInfo' }, { value: companySettings.nurseDisplayName }), id: 'nurseInfo' },
		{ title: intl.formatMessage({ id: 'clinicalContact' }), id: 'clinicalContact' },
		{ title: '', id: 'actions' },
	];

	const getAcuityDesc = typeId => {
		let result = '';
		switch (typeId) {
			case RequestAcuity.IMMEDIATE: {
				result = intl.formatMessage({ id: 'immediate' });
				break;
			}
			case RequestAcuity.TEN_MINUTES: {
				result = `10 ${intl.formatMessage({ id: 'minutes' })}`;
				break;
			}
			case RequestAcuity.THIRTY_MINUTES: {
				result = `30 ${intl.formatMessage({ id: 'minutes' })}`;
				break;
			}
			case RequestAcuity.SIXTY_MINUTES: {
				result = `60 ${intl.formatMessage({ id: 'minutes' })}`;
				break;
			}
			case RequestAcuity.ROUNDING: {
				result = intl.formatMessage({ id: 'rounding' });
				break;
			}
			default: {
				result = 'N/A';
				break;
			}
		}
		return result;
	};

	const transformRows = () => {
		if (!requests || requests.length === 0) {
			return [];
		}
		return requests.map(item => ({
			id: item.id,
			className: item.request?.requestType === RequestType.RAPID ? 'rapid-request-type' : '',
			mrn: item.request.patientExtendedProfile?.mrn,
			reasonToConnect: item.request?.requestReason
				? intl.formatMessage({ id: getDescFromEnum(item.request.requestReason, VisitReasons) })
				: 'N/A',
			acuity: item.request.requestAcuity ? getAcuityDesc(item.request?.requestAcuity) : 'N/A',
			clinicalConcern: item.request.description ? item.request.description : 'N/A',
			nurseInfo: item.request.requestIntakeInfo ? (
				<ContactInfo
					name={item.request.requestIntakeInfo.nurseFullName}
					phone={item.request.requestIntakeInfo.nurseStationPhone}
					ext={item.request.requestIntakeInfo.nurseExt}
				/>
			) : (
				'N/A'
			),
			clinicalContact: item.request.requestIntakeInfo ? (
				<ContactInfo
					name={item.request.requestIntakeInfo.clinicalContactFullName}
					phone={item.request.requestIntakeInfo.clinicalContactPhone}
					ext={item.request.requestIntakeInfo.clinicalContactExt}
				/>
			) : (
				'N/A'
			),
			actions: (
				<div className='flex position-relative patient-card-btns'>
					<Button
						text={translate('join')}
						imgIcon={`${healthCareCdnUrl}workflow/start-call.svg`}
						onClick={() => joinCall(item)}
					/>
					<Button icon='cancel' text={translate('reject')} onClick={() => rejectRequest(item)} variant='red' />
				</div>
			),
		}));
	};
	return <CustomTable headers={tableHeaders} rows={transformRows()} isEditable={false} className='waiting-room-table' />;
};
export default CardRequest;
