import { useState } from 'react';
import { useIntl } from 'react-intl';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import translate from 'i18n-translations/translate.jsx';
import QueueManagement from 'views/QueueManagement/QueueManagement.jsx';
import UnAssignedPatients from 'views/QueueManagement/UnAssignedPatients.jsx';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from 'components/Common/Tabs.jsx';

const QueueManagementWrapper = () => {
	const intl = useIntl();
	const tabList = [
		{ id: 0, text: intl.formatMessage({ id: 'patientQueue' }) },
		{ id: 1, text: intl.formatMessage({ id: 'doctorQueue' }) },
	];
	const [activeTab, setActiveTab] = useState(tabList[0].id);

	return (
		<MainLayout>
			<div className='inner-main-view queue-management'>
				<div className='patient-alerts-wrapper '>
					<div className='flex flex-space-between flex-align-center'>
						<h4>{translate('queueManagement')}</h4>
					</div>
					<Tabs activeIndex={activeTab} onChange={index => setActiveTab(index)}>
						<TabList>
							{tabList.map(tab => (
								<Tab key={tab.id}>{tab.text}</Tab>
							))}
						</TabList>
						<TabPanels>
							<TabPanel>
								<UnAssignedPatients />
							</TabPanel>
							<TabPanel>
								<QueueManagement />
							</TabPanel>
						</TabPanels>
					</Tabs>
				</div>
			</div>
		</MainLayout>
	);
};

export default QueueManagementWrapper;
