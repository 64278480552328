import {
	BlendMode,
	SegmentationModels,
	SegmentationPipelines,
	VirtualBackgroundBackend,
	VirtualBackgroundInputResolutions,
} from 'constants/enums.js';

// default config that uses the GPU
export const segmentationConfiguration = {
	model: SegmentationModels.MLKIT,
	pipeline: SegmentationPipelines.WEBGL2,
	inputResolution: VirtualBackgroundInputResolutions['256x256'],
	backend: VirtualBackgroundBackend.WASM_SIMD,
	targetFps: 65, // 60 introduces fps drop and unstable fps on Chrome
	deferInputResizing: true,
};

export const postProcessingConfiguration = {
	smoothSegmentationMask: true,
	jointBilateralFilter: { sigmaSpace: 6.9, sigmaColor: 0.37 },
	coverage: [0.5, 0.6],
	lightWrapping: 0.3,
	blendMode: BlendMode.SCREEN,
};
