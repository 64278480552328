import React, { useState } from 'react';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import Button from 'components/Common/Button.jsx';

const SoftwareUpdate = ({ updateApp }) => {
	const [isUpdateRequested, setIsUpdateRequested] = useState(false);

	const requestUpdate = () => {
		if (isUpdateRequested) {
			return;
		}
		setIsUpdateRequested(prevState => !prevState);
		updateApp();
	};
	return (
		<div className='configurable-settings-modal-inner'>
			<h3>{translate('softwareUpdate')}</h3>
			{/* Will be used in the future */}
			{/* {isSoftwareUpdateLatest && (
				<div className='text-align-center'>
					<img src={`${healthCareCdnUrl}admin/software-update.svg`} alt='software-icon' />
					<p>There are no updates available at the moment</p>
				</div>
			)} */}
			<div className='software-update-list flex'>
				<img src={`${healthCareCdnUrl}admin/software-update.svg`} alt='software-icon' />
				<div>
					<p className='bold'>hellocare 1.0.2</p>
				</div> 
				<Button onClick={requestUpdate} text={!isUpdateRequested ? translate('updateNow') : translate('updateRequested')} />
			</div>
		</div>
	);
};

export default SoftwareUpdate;
