import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { DeviceListLevel } from 'constants/enums.js';
import FormInput from 'components/Common/FormInput.jsx';
import { doNotAllowSpaceAsFirstCharacter } from 'infrastructure/helpers/commonHelpers.js';
import translate from 'i18n-translations/translate.jsx';
import { getHealthSystemHospitals } from 'api/userIdleConfigurations.js';

const PACS = ({ itemToEdit, setConfigParams, configurationTypeId, fieldErrors, setError }) => {
	const intl = useIntl();
	const allHealthSystems = useSelector(state => state.healthSystems.allHealthSystems);
	const [hospitals, setHospitals] = useState([]);
	const [selectedHealthSystem, setSelectedHealthSystem] = useState(itemToEdit?.healthSystem ?? { value: '0', label: 'All' });
	const [selectedHospital, setSelectedHospital] = useState(itemToEdit?.hospital ?? { value: '0', label: 'All' });
	const [isLoading, setIsLoading] = useState(true);
	const [baseUrl, setBaseUrl] = useState(itemToEdit?.baseUrl ?? '');
	const [text, setText] = useState(itemToEdit?.text ?? '');
	const algorithm = 'AES-256';
	const [secretKey, setSecretKey] = useState(itemToEdit?.secretKey ?? '');
	const cipherMode = 'CBC';
	const paddingList = [
		{ value: 'PKCS5', label: 'PKCS5' },
		{ value: 'PKCS7', label: 'PKCS7' },
	];
	const bitslist = [
		{ value: '128', label: '128' },
		{ value: '192', label: '192' },
		{ value: '256', label: '256' },
	];
	const [keySize, setKeySize] = useState(
		itemToEdit?.keySize ? bitslist.find(item => item.value === itemToEdit.keySize) : { value: '128', label: '128' }
	);
	const [padding, setPadding] = useState(
		itemToEdit?.padding ? paddingList.find(item => item.value === itemToEdit.padding) : { value: 'PKCS5', label: 'PKCS5' }
	);
	const [iv, setIv] = useState(itemToEdit?.iv ?? '');

	useEffect(() => {
		const fetchHospitals = async () => {
			let hsHospitals = [];
			if (selectedHealthSystem.value !== '0' && allHealthSystems.find(item => item.id === selectedHealthSystem.value)) {
				setIsLoading(true);
				const res = await getHealthSystemHospitals(selectedHealthSystem.value);
				if (res.error) {
					setError(res.error.message);
				} else {
					hsHospitals = res;
				}
			}
			setHospitals(hsHospitals);
			setIsLoading(false);
			const hospitalArray = hsHospitals.map(hospital => ({ value: hospital.id, label: hospital.name }));
			hospitalArray.unshift({ value: '0', label: intl.formatMessage({ id: 'all' }) });
			const foundHospital = hospitalArray.find(item => item.value === itemToEdit?.hospitalId);
			setHospitals(hospitalArray);
			setSelectedHospital(foundHospital || hospitalArray[0]);
			setIsLoading(false);
		};
		fetchHospitals();
	}, [selectedHealthSystem]);

	const transformArray = array => {
		if (!array) {
			return [];
		}
		const newArray = array.map(item => ({ value: item.id, label: item.name || item.value }));
		newArray.unshift({ value: '0', label: intl.formatMessage({ id: 'all' }) });
		return newArray;
	};

	const onHealthSystemChange = async event => {
		const { value, label } = event;
		const hsHospitals = value !== '0' ? await getHealthSystemHospitals(value) : [];
		if (hsHospitals.error) {
			setError(hsHospitals.error.message);
		} else {
			const hospitalArray = hsHospitals.map(hospital => ({ value: hospital.id, label: hospital.name }));
			setHospitals(hospitalArray);
		}
		setSelectedHealthSystem({ value, label });
	};

	const getNode = () => {
		if (selectedHospital.value !== '0') {
			return {
				id: selectedHospital.value,
				label: `${selectedHealthSystem.label} > ${selectedHospital.label}`,
				parentId: selectedHealthSystem.value,
				level: DeviceListLevel.HOSPITAL,
			};
		}
		if (selectedHealthSystem.value !== '0') {
			return {
				id: selectedHealthSystem.value,
				label: selectedHealthSystem.label,
				parentId: null,
				level: DeviceListLevel.HEALTH_SYSTEM,
			};
		}
		return { id: null, label: null, parentId: null, level: null };
	};

	useEffect(() => {
		const node = getNode();
		setConfigParams({
			configurationTypeId,
			nodeName: node.label,
			nodeLevel: node.level,
			nodeId: node.id,
			parentNodeId: node.parentId,
			configJson: JSON.stringify({
				healthSystem: selectedHealthSystem,
				hospital: selectedHospital,
				baseUrl,
				text,
				secretKey,
				cipherMode,
				algorithm,
				keySize: keySize.value,
				padding: padding.value,
				iv,
			}),
		});
	}, [configurationTypeId, selectedHealthSystem, selectedHospital, baseUrl, text, secretKey, keySize, padding, iv]);

	return (
		<>
			<div className='input'>
				<p className='label'>{translate('selectHealthSystem')}</p>
				<p className='font-14'>{translate('selectHSForConfiguration')}</p>
				<Select
					isDisabled={isLoading}
					value={selectedHealthSystem}
					placeholder={intl.formatMessage({ id: 'selectHealthSystem' })}
					classNamePrefix='react-select'
					options={transformArray(allHealthSystems)}
					onChange={onHealthSystemChange}
				/>
			</div>
			<div className='input'>
				<p className='label'>{translate('selectHospital')}</p>
				<p className='font-14'>{translate('chooseHospitalForChanges')}</p>
				<Select
					isLoading={isLoading}
					isDisabled={isLoading}
					value={selectedHospital}
					placeholder={intl.formatMessage({ id: 'selectHospital' })}
					classNamePrefix='react-select'
					options={hospitals}
					onChange={setSelectedHospital}
				/>
			</div>
			<div className='input'>
				<FormInput
					text={`${intl.formatMessage({ id: 'baseURL' })}*`}
					id='baseUrl'
					placeholder={intl.formatMessage({ id: 'baseURL' })}
					type='text'
					value={baseUrl}
					onChange={event => setBaseUrl(event.target.value)}
					maxLength={256}
					onKeyDown={doNotAllowSpaceAsFirstCharacter}
					error={fieldErrors?.baseUrl}
					touched={fieldErrors?.baseUrl}
				/>
			</div>
			<div className='input'>
				<FormInput
					text={`${intl.formatMessage({ id: 'text' })}*`}
					id='text'
					placeholder={`E.x mrn={{mrn}}&timeStamp={{timeStamp:mm/dd/yyyy}}`}
					type='text'
					value={text}
					onChange={event => setText(event.target.value)}
					maxLength={256}
					onKeyDown={doNotAllowSpaceAsFirstCharacter}
					error={fieldErrors?.text}
					touched={fieldErrors?.text}
				/>
			</div>

			<div className='input'>
				<FormInput
					text={intl.formatMessage({ id: 'algorithm' })}
					id='algorithm'
					placeholder={intl.formatMessage({ id: 'algorithm' })}
					type='text'
					isDisabled={true}
					value={algorithm}
				/>
			</div>
			<div className='input'>
				<FormInput
					text={`${intl.formatMessage({ id: 'secretKey' })}*`}
					id='secretKey'
					placeholder={intl.formatMessage({ id: 'secretKey' })}
					type='text'
					value={secretKey}
					onChange={event => setSecretKey(event.target.value)}
					maxLength={256}
					onKeyDown={doNotAllowSpaceAsFirstCharacter}
					error={fieldErrors?.secretKey}
					touched={fieldErrors?.secretKey}
				/>
			</div>
			<div className='input'>
				<FormInput
					text={intl.formatMessage({ id: 'cipherMode' })}
					id='cipherMode'
					placeholder={intl.formatMessage({ id: 'cipherMode' })}
					type='text'
					isDisabled={true}
					value={cipherMode}
				/>
			</div>
			<div className='input'>
				<p className='label'>{translate('selectPadding')}</p>
				<Select
					value={padding}
					placeholder={intl.formatMessage({ id: 'selectPadding' })}
					classNamePrefix='react-select'
					options={paddingList}
					onChange={setPadding}
				/>
			</div>
			<div className='input'>
				<FormInput
					text={intl.formatMessage({ id: 'Enter IV' })}
					id='iv'
					placeholder={intl.formatMessage({ id: 'Enter IV (Optional)' })}
					type='text'
					value={iv}
					onChange={event => setIv(event.target.value)}
					maxLength={256}
					onKeyDown={doNotAllowSpaceAsFirstCharacter}
					error={fieldErrors?.iv}
					touched={fieldErrors?.iv}
				/>
			</div>
			<div className='input'>
				<p className='label'>{translate('selectKey')}</p>
				<p className='font-14'>{translate('Key Size in Bits*')}</p>
				<Select value={keySize} classNamePrefix='react-select' options={bitslist} onChange={setKeySize} />
			</div>
		</>
	);
};

export default PACS;
