import React from 'react';
import styled, { css } from 'styled-components';
import { Icon } from 'calls/components/index.js';

/**
 * @type {import('styled-components').StyledComponent<"button", any, { $background: string, $color: string }, never>}
 */
const StyledIconButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: var(--spacing-m);
	border-radius: 100%;
	background: none;
	transition: 0.2s;
	user-select: none;

	${props =>
		props.$background &&
		css`
			background: ${props.$background};
		`}

	span {
		${props =>
			props.$color &&
			css`
				color: ${props.$color};
			`}
	}

	&:disabled {
		pointer-events: none;
		opacity: 0.85;
	}
`;

/**
 * @param {object} props
 * @param {string} [props.id=null]
 * @param {any} [props.children]
 * @param {string} [props.icon] Material icon name
 * @param {string} props.background
 * @param {string} props.color
 * @param {number} [props.size] Size in pixels
 * @param {boolean} [props.disabled=null]
 * @param {(...args: any) => void} [props.onClick=null]
 */
const IconButton = ({ children, icon, size, background, color, disabled = null, onClick = null, ...props }) => (
	<StyledIconButton type='button' $background={background} $color={color} disabled={disabled} onClick={onClick} {...props}>
		{children || <Icon name={icon} />}
	</StyledIconButton>
);

export default IconButton;
