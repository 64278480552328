import { CallsActionTypes } from 'constants/action-types.js';

const callsActionCreators = {
	setIncomingCallRenderedElsewhere: isRenderedElsewhere => dispatch => {
		dispatch({ type: CallsActionTypes.incomingCallRenderedElsewhere, payload: isRenderedElsewhere });
	},
	setVirtualbackgroundConfig: config => dispatch => {
		dispatch({ type: CallsActionTypes.virtualbackgroundConfig, payload: config });
	},
};

export default callsActionCreators;
