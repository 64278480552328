import EmptyState from 'components/Common/EmptyState.jsx';
import { AiAnalyticType } from 'constants/alerts.jsx';
import translate from 'i18n-translations/translate.jsx';
import { formattedDate, getPositionDuration } from 'infrastructure/helpers/dateHelper.js';
import React from 'react';
import { useIntl } from 'react-intl';

const PatientPositionsAnalytics = ({ patientPositions, isDarkMode }) => {
	const intl = useIntl();
	return (
		<>
			{patientPositions.length > 0 &&
				patientPositions.map(item => (
					<div key={item.id} className='patient-request-list-item cursor-pointer'>
						<div className='flex alert-history-inner-wrapper position-alerts'>
							{AiAnalyticType(isDarkMode).find(el => el.id === item.analyticType.id)?.icon}
							<p className='alert-history-description'>
								{translate('analyticPositionDescription', {
									value: intl.formatMessage({
										id: item.analyticType.name,
									}),
									value1: getPositionDuration(item.value, intl),
								})}
								<br />
								<span className='alert-time no-padding no-margin-left'>{formattedDate(item.dateCreated)}</span>
							</p>
						</div>
					</div>
				))}

			{patientPositions.length === 0 && (
				<div className='flex flex-align-center flex-justify-center empty-state-wrapper'>
					<EmptyState title={translate('noResultsFound')} image='alerts.svg?v2' />
				</div>
			)}
		</>
	);
};

export default PatientPositionsAnalytics;
