import React, { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
const Callback = lazy(() => import('components/auth/callback.jsx'));
const Logout = lazy(() => import('components/auth/logout.jsx'));
const LogoutCallback = lazy(() => import('components/auth/logoutCallback.jsx'));
const PrivateRoute = lazy(() => import('routes/privateRoute.jsx'));
const SilentRenew = lazy(() => import('components/auth/silentRenew.jsx'));
import HealthSystem from 'views/HealthSystem.jsx';
import Patients from 'views/Patients/Patients.jsx';
import Nurses from 'components/Common/Nurses.jsx';
import Appointments from 'views/Appointments.jsx';
import CallLogs from 'views/CallLogs/CallLogs.jsx';
const Call = lazy(() => import('views/Call.jsx'));
import Users from 'views/Users.jsx';
import Companies from 'views/Companies.jsx';
const CreateHealthSystem = lazy(() => import('views/CreateHealthSystem.jsx'));
const CompleteProfile = lazy(() => import('views/CompleteProfile.jsx'));
const SelectCountry = lazy(() => import('views/Onboarding/Doctor/SelectCountry.jsx'));
const DoctorOnboarding = lazy(() => import('views/Onboarding/Doctor/DoctorOnboarding.jsx'));
const UserActivation = lazy(() => import('views/Onboarding/UserActivation.jsx'));
const CompleteCompany = lazy(() => import('views/CompleteCompany.jsx'));
const PatientOnboarding = lazy(() => import('views/Onboarding/Patient/PatientOnboarding.jsx'));
import MyDoctors from 'views/Doctors/MyDoctors.jsx';
import MyHelloDevices from 'views/HelloDevices/MyHelloDevices.jsx';
const SignsSymptoms = lazy(() => import('views/Doctors/Partials/SignsSymptoms.jsx'));
import AvailableDoctors from 'views/Doctors/AvailableDoctors.jsx';
import MyVitalSigns from 'views/Patients/MyVitalSigns.jsx';
const AccountSettings = lazy(() => import('views/AccountSettings.jsx'));
import { RouteTypes, UserRoles } from 'constants/enums.js';
import Configurations from 'views/Configurations.jsx';
const Recover = lazy(() => import('views/Recover.jsx'));
const ResetPassword = lazy(() => import('views/ResetPassword.jsx'));
const VerifyChangedEmail = lazy(() => import('views/VerifyChangedEmail.jsx'));
const ComingSoon = lazy(() => import('components/Common/ComingSoon.jsx'));
const PrivacyPolicy = lazy(() => import('views/PrivacyPolicy.jsx'));
const TermsOfUse = lazy(() => import('views/TermsOfUse.jsx'));
const DoctorRequestsWrapper = lazy(() => import('views/DoctorRequestsWrapper.jsx'));
const VitalSignsMonitoring = lazy(() => import('views/VitalSignsMonitoring.jsx'));
import ConfigurationsHistory from 'views/ConfigurationsHistory.jsx';
const PatientSummary = lazy(() => import('components/Common/PatientSummary.jsx'));
import Teams from 'views/Teams.jsx';
const Help = lazy(() => import('views/Help.jsx'));
const CompleteRegistration = lazy(() => import('views/Doctors/CompleteRegistration.jsx'));
import PatientReports from 'containers/Patients/PatientReports.jsx';
const ConferenceCall = lazy(() => import('calls/Conference.jsx'));
const conferenceRouteRender = lazy(() => import('calls/conferenceRouteRender.jsx'));
const JoinAsGuest = lazy(() => import('calls/views/JoinAsGuest.jsx'));
const CreateCompanyWrapper = lazy(() => import('views/CreateCompanyWrapper.jsx'));
const ContinueAs = lazy(() => import('containers/Common/ContinueAs.jsx'));
const HelloLandingPage = lazy(() => import('views/HelloLandingPage.jsx'));
const TechSpecs = lazy(() => import('views/TechSpecs.jsx'));
import DigitalClinicianVisits from 'containers/PrimaryCare/DigitalClinician/DigitalClinicianVisits.jsx';
import DigitalClinicianCheckIn from 'views/DigitalClinicianCheckIn.jsx';
import AuditLogs from 'views/AuditLogs.jsx';
import WaitingRoom from 'views/WaitingRoom.jsx';
import Visitors from 'views/Visitors.jsx';
// import FamilyMembersChat from 'views/FamilyMembersChat/FamilyMembersChat.jsx';
const Home = lazy(() => import('views/Partials/Home.jsx'));
import SelectPatientCompany from 'views/Onboarding/Patient/SelectPatientCompany.jsx';
import LabWaitingResults from 'containers/PrimaryCare/LaboratoryAnalysis/LabWaitingResults.jsx';
import WaitingRoomHome from 'views/WaitingRoomHome.jsx';
import InPersonVisit from 'containers/PrimaryCare/Doctor/InPersonVisit.jsx';
import CheckInHome from 'views/CheckInHome.jsx';
const CallPatientOptions = lazy(() => import('views/ehr/CallPatientOptions.jsx'));
const MonitorDevice = lazy(() => import('views/ehr/MonitorDevice.jsx'));
import PatientHomePage from 'views/PatientHomePage.jsx';
import Visitor from 'views/Visitor.jsx';
import VcpCheckIn from 'containers/PrimaryCare/LaboratoryAnalysis/VcpCheckIn.jsx';
import DoctorHomePageWrapper from 'views/Doctors/DoctorHomePageWrapper.jsx';
const QrCode = lazy(() => import('views/QrCode.jsx'));
const RpmPatientOnboarding = lazy(() => import('containers/Rpm/RpmOnboardingPatient.jsx'));
import Logistics from 'views/Logistics.jsx';
import Dashboard from 'views/Dashboard.jsx';
const CallRedirect = lazy(() => import('calls/CallRedirect.jsx'));
const AddEditRpmProgram = lazy(() => import('containers/Rpm/AddEditRpmProgram.jsx'));
const EHRCallPatientJoinAsGuest = lazy(() => import('views/Partials/EHRCallPatientJoinAsGuest.jsx'));
import QueueManagementWrapper from 'views/QueueManagement/QueueManagementWrapper.jsx';
import FamilyMemberPatients from 'containers/Rpm/FamilyMemberPatients.jsx';
import SessionsQueue from 'views/SessionsQueue.jsx';
import NursePatients from 'components/Common/NursePatients.jsx';
import CareEventsDashboard from 'containers/CareEventsDashboard/CareEventsDashboard.jsx';
import MonitoringWrapper from 'views/MonitoringWrapper.jsx';
import CareNotificationsWrapper from 'views/CareNotifications/CareNotificationsWrapper.jsx';
import FleetManagement from 'views/FleetManagement.jsx';
import PatientMonitoring from 'containers/Rpm/PatientMonitoring.jsx';
import HospitalAtHome from 'containers/HospitalAtHome/HospitalAtHome.jsx';
const DetailedReport = lazy(() => import('components/DashboardCharts/PatientCentric/DetailedReport.jsx'));
import NurseStation from 'views/NurseStationTablets/NurseStation.jsx';
import Grid from 'components/Common/Grid.jsx';
import Loader from 'components/Common/Loader.jsx';

const DefaultRoutes = (
	<Suspense
		fallback={
			<Grid columns='1fr' rows='1fr' stretch='100vh' horizAlign='center' vertAlign='center'>
				<div className='text-align-center'>
					<Loader />
				</div>
			</Grid>
		}>
		<Switch>
			<Route exact={true} path='/signin-oidc' component={Callback} />
			<Route exact={true} path='/logout' component={Logout} />
			<Route exact={true} path='/logout/callback' component={LogoutCallback} />
			<Route exact={true} path='/silent-renew' component={SilentRenew} />
			<Route exact={true} path='/recover' component={Recover} />
			<Route exact={true} path='/validate/recoverPassword' component={ResetPassword} />
			<Route exact={true} path='/validate/changeEmail' component={VerifyChangedEmail} />
			<Route path='/complete-profile' component={CompleteProfile} />
			<Route path='/privacy-policy' component={PrivacyPolicy} />
			<Route path='/terms-of-use' component={TermsOfUse} />
			<Route path='/call-session/:conferenceId/invitation/:invitationSecret' component={JoinAsGuest} />
			<PrivateRoute
				roles={[UserRoles.GUEST]}
				path='/virtual-session/:conferenceId/invitation/:invitationSecret/:refToken'
				component={JoinAsGuest}
			/>
			<PrivateRoute
				roles={[UserRoles.GUEST]}
				path='/virtual-session/:startConferenceId/:callType/:deviceId/:name'
				component={JoinAsGuest}
			/>
			<PrivateRoute
				roles={[UserRoles.NURSE]}
				path='/virtual-care-session/:conferenceId/invitation/:invitationSecret/:refToken/:isDirect'
				component={EHRCallPatientJoinAsGuest}
			/>
			<PrivateRoute
				roles={[UserRoles.NURSE]}
				path='/virtual-care-session/:startConferenceId/:callType/:deviceId/:isDirect'
				component={EHRCallPatientJoinAsGuest}
			/>
			<Route path='/help' component={Help} />
			<Route path='/qrcode' component={QrCode} />
			<Route exact={true} path='/' component={Home} />
			<PrivateRoute exact={true} roles={[UserRoles.PATIENT]} path='/patient-home' component={PatientHomePage} />
			<PrivateRoute exact={true} roles={[UserRoles.DOCTOR]} path='/doctor-home' component={DoctorHomePageWrapper} />
			<PrivateRoute exact={true} roles={[UserRoles.NURSE]} path='/nurse-home' component={VcpCheckIn} />
			<Route exact={true} path='/hello/specs' component={TechSpecs} />
			<Route exact={true} path='/hello' component={HelloLandingPage} />
			<PrivateRoute exact path='/continue-as' component={ContinueAs} />
			<PrivateRoute roles={[UserRoles.DOCTOR]} path='/patients/:deviceId/:tab/:subTab' component={Patients} />
			<PrivateRoute roles={[UserRoles.DOCTOR]} path='/patients/:deviceId/:tab' component={Patients} />
			<PrivateRoute roles={[UserRoles.DOCTOR]} path='/patients/:deviceId' component={Patients} />
			<PrivateRoute roles={[UserRoles.DOCTOR]} path='/patients' component={Patients} />
			<PrivateRoute roles={[UserRoles.DOCTOR, UserRoles.NURSE]} path='/hospital-at-home' component={HospitalAtHome} />
			<PrivateRoute roles={[UserRoles.DIGITAL_CLINICIAN]} path='/digital-clinician/patients' component={PatientReports} />
			<PrivateRoute roles={[UserRoles.NURSE]} path='/nurses/:id' component={Nurses} />
			<PrivateRoute roles={[UserRoles.NURSE]} path='/nurses' component={Nurses} />
			<PrivateRoute roles={[UserRoles.NURSE]} path='/nurse-patients/:id' component={NursePatients} />
			<PrivateRoute roles={[UserRoles.NURSE]} path='/nurse-patients' component={NursePatients} />
			{/* <PrivateRoute roles={[UserRoles.VISITOR, UserRoles.NURSE]} path='/family-members-chat' component={FamilyMembersChat} /> */}
			<PrivateRoute roles={[UserRoles.PATIENT]} path='/health-data/:subTab' component={MyVitalSigns} />
			<PrivateRoute roles={[UserRoles.PATIENT]} path='/health-data' component={MyVitalSigns} />
			<PrivateRoute roles={[UserRoles.PATIENT]} path='/recent/:id' component={MyDoctors} />
			<PrivateRoute roles={[UserRoles.PATIENT]} path='/recent' component={MyDoctors} />
			<PrivateRoute roles={[UserRoles.PATIENT]} path='/summary' component={PatientSummary} />
			<PrivateRoute roles={[UserRoles.PATIENT]} path='/select-doctor' component={DoctorRequestsWrapper} />
			<PrivateRoute exact={true} roles={[UserRoles.SUPER_ADMIN, UserRoles.ADMIN]} path='/audit-logs' component={AuditLogs} />
			<PrivateRoute
				exact={true}
				roles={[UserRoles.NURSE, UserRoles.DOCTOR]}
				path='/call-patient-options'
				component={CallPatientOptions}
			/>
			<PrivateRoute
				roles={[UserRoles.NURSE, UserRoles.DOCTOR, UserRoles.ADMIN, UserRoles.SUPER_USER]}
				path='/health-system/:hospital/department/:department/floor/:floor/room/:room'
				component={HealthSystem}
			/>
			<PrivateRoute
				roles={[UserRoles.NURSE, UserRoles.DOCTOR, UserRoles.ADMIN, UserRoles.SUPER_USER]}
				path='/health-system/:hospital/department/:department/floor/:floor'
				component={HealthSystem}
			/>
			<PrivateRoute
				roles={[UserRoles.NURSE, UserRoles.DOCTOR, UserRoles.ADMIN, UserRoles.SUPER_USER]}
				path='/health-system/:hospital/department/:department'
				component={HealthSystem}
			/>
			<PrivateRoute
				roles={[UserRoles.NURSE, UserRoles.DOCTOR, UserRoles.ADMIN, UserRoles.SUPER_USER]}
				path='/health-system/:hospital'
				component={HealthSystem}
			/>
			<PrivateRoute
				roles={[UserRoles.NURSE, UserRoles.DOCTOR, UserRoles.ADMIN, UserRoles.SUPER_USER]}
				path='/health-system'
				component={HealthSystem}
			/>
			<PrivateRoute
				roles={[UserRoles.NURSE]}
				path='/visitors/:hospital/department/:department/floor/:floor/room/:room'
				component={Visitors}
			/>
			<PrivateRoute
				roles={[UserRoles.NURSE]}
				path='/visitors/:hospital/department/:department/floor/:floor'
				component={Visitors}
			/>
			<PrivateRoute roles={[UserRoles.NURSE]} path='/visitors/:hospital/department/:department' component={Visitors} />
			<PrivateRoute roles={[UserRoles.NURSE]} path='/visitors/:hospital' component={Visitors} />
			<PrivateRoute roles={[UserRoles.NURSE]} path='/visitors' component={Visitors} />
			<PrivateRoute roles={[UserRoles.NURSE, UserRoles.VIRTUAL_SITTER]} path='/monitoring' component={MonitoringWrapper} />
			<PrivateRoute
				roles={[UserRoles.NURSE, UserRoles.DOCTOR]}
				type={RouteTypes.DEFAULT}
				path='/waiting-room'
				component={WaitingRoom}
			/>
			<PrivateRoute roles={[UserRoles.DOCTOR]} type={RouteTypes.DEFAULT} path='/sessions-queue' component={SessionsQueue} />
			<PrivateRoute
				roles={[UserRoles.NURSE, UserRoles.VIRTUAL_SITTER]}
				path='/monitor-device/:deviceId'
				component={MonitorDevice}
			/>
			<PrivateRoute roles={[UserRoles.DOCTOR, UserRoles.PATIENT]} path='/appointments' component={Appointments} />
			<PrivateRoute roles={[UserRoles.ADMIN]} path='/create-health-system' component={CreateHealthSystem} />
			<PrivateRoute
				roles={[UserRoles.ADMIN, UserRoles.SUPER_USER]}
				path='/dashboard/detailed-report'
				component={DetailedReport}
			/>
			<PrivateRoute roles={[UserRoles.ADMIN, UserRoles.SUPER_USER]} path='/fleet-management' component={FleetManagement} />
			<PrivateRoute roles={[UserRoles.ADMIN, UserRoles.SUPER_USER]} path='/nurse-station' component={NurseStation} />
			<PrivateRoute roles={[UserRoles.ADMIN, UserRoles.SUPER_USER]} path='/dashboard' component={Dashboard} />
			<PrivateRoute roles={[UserRoles.ADMIN, UserRoles.SUPER_USER, UserRoles.SUPER_ADMIN]} path='/users' component={Users} />
			<PrivateRoute roles={[UserRoles.SUPER_ADMIN]} path='/companies' component={Companies} />
			<PrivateRoute roles={[UserRoles.SUPER_ADMIN]} path='/create-company' component={CreateCompanyWrapper} />
			<PrivateRoute roles={[UserRoles.ADMIN, UserRoles.SUPER_USER]} path='/call-logs' component={CallLogs} />
			<PrivateRoute roles={[UserRoles.ADMIN, UserRoles.SUPER_USER]} exact path='/configurations' component={Configurations} />
			<PrivateRoute
				roles={[UserRoles.ADMIN, UserRoles.SUPER_USER, UserRoles.SUPER_ADMIN]}
				exact
				path='/configurations/:id/type/:type'
				component={ConfigurationsHistory}
			/>
			<Route exact={true} path='/onboarding/country' component={SelectCountry} />
			<Route exact={true} path='/onboarding/doctor' component={DoctorOnboarding} />
			<Route exact={true} path='/onboarding/patient' component={PatientOnboarding} />
			<Route exact={true} path='/onboarding/select-company' component={SelectPatientCompany} />
			<Route exact={true} path='/onboarding/activation' component={UserActivation} />
			<Route exact={true} path='/validate/company' component={CompleteCompany} />
			<PrivateRoute
				roles={[UserRoles.DOCTOR, UserRoles.PATIENT, UserRoles.NURSE, UserRoles.DIGITAL_CLINICIAN]}
				path='/call/:callType/:patientId/:conferenceName/:incoming'
				component={Call}
			/>
			<PrivateRoute
				roles={[UserRoles.DOCTOR, UserRoles.PATIENT, UserRoles.NURSE, UserRoles.DIGITAL_CLINICIAN, UserRoles.GUEST]}
				path='/call'
				component={ConferenceCall}
				render={conferenceRouteRender}
			/>
			<PrivateRoute
				roles={[UserRoles.DOCTOR, UserRoles.PATIENT, UserRoles.NURSE, UserRoles.DIGITAL_CLINICIAN]}
				path='/patient-feed'
				component={ConferenceCall}
				render={conferenceRouteRender}
			/>
			<PrivateRoute
				roles={[UserRoles.DOCTOR, UserRoles.PATIENT, UserRoles.NURSE, UserRoles.DIGITAL_CLINICIAN, UserRoles.GUEST]}
				path='/start-call/:objectId/:objectType/:name/:type/:conferenceId'
				component={CallRedirect}
			/>
			<PrivateRoute roles={[UserRoles.DIGITAL_CLINICIAN]} path='/check-in-dc' component={DigitalClinicianCheckIn} />
			<PrivateRoute roles={[UserRoles.DIGITAL_CLINICIAN]} path='/vital-signs-monitoring' component={VitalSignsMonitoring} />
			<PrivateRoute
				roles={[UserRoles.DOCTOR, UserRoles.NURSE, UserRoles.DIGITAL_CLINICIAN]}
				path='/vital-signs-monitoring'
				component={VitalSignsMonitoring}
			/>
			<PrivateRoute roles={[UserRoles.DOCTOR, UserRoles.NURSE]} path='/logistics' component={Logistics} />
			<PrivateRoute
				roles={[UserRoles.NURSE, UserRoles.DIGITAL_CLINICIAN, UserRoles.VIRTUAL_SITTER]}
				path='/teams'
				component={Teams}
			/>
			<PrivateRoute roles={[UserRoles.PATIENT]} path='/my-hello-devices/:id' component={MyHelloDevices} />
			<PrivateRoute roles={[UserRoles.PATIENT]} path='/my-hello-devices' component={MyHelloDevices} />
			<PrivateRoute roles={[UserRoles.VISITOR]} path='/devices' component={Visitor} />
			<PrivateRoute roles={[UserRoles.PATIENT]} path='/signs-symptoms' component={SignsSymptoms} />
			<PrivateRoute roles={[UserRoles.PATIENT]} path='/available-doctors' component={AvailableDoctors} />
			<PrivateRoute roles={[UserRoles.DIGITAL_CLINICIAN]} path='/front-desk-visits' component={DigitalClinicianVisits} />
			<PrivateRoute roles={[UserRoles.DOCTOR]} path='/complete-registration' component={CompleteRegistration} />
			<PrivateRoute path='/account-settings' component={AccountSettings} />
			<PrivateRoute path='/coming-soon' component={ComingSoon} />
			<PrivateRoute
				type={RouteTypes.PRIMARY_CARE}
				roles={[UserRoles.DOCTOR]}
				path='/waiting-room-inperson/:patientId'
				component={InPersonVisit}
			/>
			<PrivateRoute
				type={RouteTypes.PRIMARY_CARE}
				roles={[UserRoles.NURSE, UserRoles.DOCTOR]}
				path='/waiting-room-inperson'
				component={WaitingRoomHome}
			/>
			<PrivateRoute
				type={RouteTypes.PRIMARY_CARE}
				roles={[UserRoles.DOCTOR, UserRoles.PATIENT, UserRoles.NURSE, UserRoles.DIGITAL_CLINICIAN]}
				path='/call/:callType/:patientId/:conferenceName/:incoming'
				component={Call}
			/>
			<PrivateRoute roles={[UserRoles.DIGITAL_CLINICIAN]} path='/check-in-patient' component={CheckInHome} />
			<PrivateRoute roles={[UserRoles.NURSE]} path='/waiting-results' component={LabWaitingResults} />
			<PrivateRoute roles={[UserRoles.DOCTOR, UserRoles.NURSE]} path='/rpm-patient-onboarding' component={RpmPatientOnboarding} />
			<PrivateRoute roles={[UserRoles.DOCTOR, UserRoles.NURSE]} path='/patient-monitoring' component={PatientMonitoring} />
			<PrivateRoute roles={[UserRoles.ADMIN]} path='/add-edit-rpm' component={AddEditRpmProgram} />
			<PrivateRoute roles={[UserRoles.SUPER_USER]} path='/queue-management' component={QueueManagementWrapper} />
			<PrivateRoute roles={[UserRoles.VISITOR]} path='/dependants' component={FamilyMemberPatients} />
			<PrivateRoute
				roles={[UserRoles.NURSE, UserRoles.VIRTUAL_SITTER]}
				path='/care-notifications'
				component={CareNotificationsWrapper}
			/>
			<PrivateRoute roles={[UserRoles.DOCTOR, UserRoles.NURSE]} path='/care-events-dashboard' component={CareEventsDashboard} />
			<PrivateRoute path='*' component={Home} />
		</Switch>
	</Suspense>
);

export default DefaultRoutes;
