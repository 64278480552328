import { getAiAlerts } from 'api/dashboard.js';
import DoughnutChart from 'components/Common/Charts/DoughnutChart.jsx';
import { EmptyState, Grid, Loader } from 'components/index.js';
import { AlertAndSettingMapping } from 'constants/ai.jsx';
import { aiAlertsChartOptions } from 'constants/dashboard.jsx';
import { AiAlertActivityType, AiAlertsDetails } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import { getConfigurationValue } from 'infrastructure/helpers/commonHelpers.js';
import { debounce } from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

const LegendWrapper = styled.div`
	display: flex;
	padding: 0;
	margin-top: var(--spacing-xl);
	justify-content: flex-start;
	flex-wrap: wrap;
	gap: var(--spacing-m);
	row-gap: normal;
	> div {
		display: inline-flex;
		gap: 6px;
		align-items: center;
		font-size: 12px;
		cursor: pointer;
		> span {
			pointer-events: none;
			user-select: none;
		}
	}
`;

const LegendMarker = styled.span`
	width: var(--spacing-xl);
	height: 8px;
	border-radius: 4px;
	background: ${props => props.$backgroundColor};
`;

const ActivityType = styled.span`
	padding-bottom: var(--spacing-s);
	font-size: 13px;
	cursor: pointer;
	user-select: none;
	${props =>
		props.$active &&
		css`
			border-bottom: 2px solid var(--blue-2);
			color: var(--blue-2);
		`}

	&:hover {
		color: var(--blue-2);
	}
`;

const activityTypes = [
	{ id: 0, name: 'total' },
	{ id: AiAlertActivityType.ACKNOWLEDGED, name: 'acknowledged' },
	{ id: AiAlertActivityType.AI_FORWARD_TO_NURSES, name: 'forwarded' },
	{ id: AiAlertActivityType.AI_FAKE_ALERT, name: 'false' },
];

const defaultActivityType = { id: 0, name: 'total' };

const AiAlertsChart = props => {
	const [alerts, setAlerts] = useState([]);
	const [chartSummary, setChartSummary] = useState({ total: 0, acknowledged: 0, forwarded: 0, false: 0 });
	const [selectedActivityType, setSelectedActivityType] = useState(defaultActivityType);
	const [isLoading, setIsLoading] = useState(true);
	const legendRef = useRef(null);
	const chartRef = useRef(null);
	const controller = useRef(null);
	const { adminConfigurations, intl } = props;

	const getTotal = items => (items ? items.reduce((accumulator, item) => accumulator + item.count, 0) : 0);

	const findActivityValue = (alert, activity = selectedActivityType.id) =>
		!activity ? alert?.total : getTotal(alert?.alertActivities?.filter(item => item.type === activity));

	const getActiveSettingsFromResponse = alerts => {
		const activeAlerts = alerts?.filter(alert => getConfigurationValue(adminConfigurations[AlertAndSettingMapping[alert.type]]));
		const alertActivities = activeAlerts.flatMap(alert => alert.alertActivities);

		return { activeAlerts, alertActivities };
	};

	const buildAlertData = async alerts =>
		alerts
			? alerts
					.map(item => {
						const foundAlerts = AiAlertsDetails.find(alert => alert.id === item.type);
						return {
							...item,
							total: item.count,
							name: foundAlerts?.name ? intl.formatMessage({ id: foundAlerts?.name }) : '',
							color: foundAlerts?.color,
						};
					})
					.sort((a, b) => b.count - a.count)
			: [];

	const getAlerts = useCallback(
		debounce(async (params, signal) => {
			setIsLoading(true);
			const response = await getAiAlerts(params, signal);

			if (!response.error) {
				const settings = getActiveSettingsFromResponse(response.alerts);
				setChartSummary({
					total: getTotal(settings.activeAlerts),
					acknowledged: findActivityValue(settings, AiAlertActivityType.ACKNOWLEDGED),
					forwarded: findActivityValue(settings, AiAlertActivityType.AI_FORWARD_TO_NURSES),
					false: findActivityValue(settings, AiAlertActivityType.AI_FAKE_ALERT),
				});
				const alertsData = await buildAlertData(settings.activeAlerts);
				setAlerts(alertsData);
			} else if (response.error.code !== 'ERR_CANCELED') {
				props.setError(response.error.message);
			}
			setIsLoading(false);
		}, 500),
		[adminConfigurations]
	);

	useEffect(() => {
		if (!props.selectedLevel?.levelId) {
			return;
		}
		if (controller.current) {
			controller.current.abort();
		}
		controller.current = new AbortController();
		const signal = controller.current.signal;
		if (Object.keys(adminConfigurations).length !== 0) {
			const params = {
				fromDate: moment(props.start).format('YYYY-MM-DDTHH:mm:ss'),
				toDate: moment(props.end).format('YYYY-MM-DDTHH:mm:ss'),
				...props.selectedLevel,
				...(props.selectedTimezone.zone && { timezone: props.selectedTimezone.zone }),
				...(props.patientId && { patientId: props.patientId }),
			};
			getAlerts(params, signal);
		}
	}, [props.start, props.end, props.selectedLevel, props.selectedTimezone, adminConfigurations, props.patientId]);

	const toggleDataset = (datasetIndex, target) => {
		const chartInstance = chartRef.current.chart;
		const meta = chartInstance.getDatasetMeta(0);
		const hidden = !meta.data[datasetIndex].hidden;
		meta.data[datasetIndex].hidden = hidden;
		chartInstance.update();
		if (target) {
			target.style.opacity = hidden ? 0.3 : 1;
		}
	};

	const handleActivityType = type => {
		setSelectedActivityType(type);
		legendRef.current?.querySelectorAll('div').forEach(legendItem => {
			legendItem.style = '';
		});
	};

	useEffect(() => {
		return () => {
			controller.current?.abort();
		};
	}, []);

	return (
		<div className='justify-content-start ai-alerts-chart dashboard-item'>
			<h3>{translate('aiAlerts')}</h3>
			{!isLoading && alerts.length > 0 && (
				<>
					<div className='top-15 bottom-20 flex flex-wrap gap-m'>
						{activityTypes.map(activityType => (
							<ActivityType
								key={activityType.id}
								$active={selectedActivityType.id === activityType.id}
								onClick={() => handleActivityType(activityType)}>
								{translate(activityType.name)} ({chartSummary[activityType.name]})
							</ActivityType>
						))}
					</div>
					<DoughnutChart
						chartRef={chartRef}
						data={{
							labels: alerts.map(alert => alert.name),
							datasets: [
								{
									data: alerts.map(alert => findActivityValue(alert)),
									backgroundColor: alerts.map(alert => alert.color),
									borderWidth: 0,
								},
							],
						}}
						options={aiAlertsChartOptions(intl.formatMessage({ id: selectedActivityType.name }))}
					/>
					<LegendWrapper ref={legendRef}>
						{alerts.map((alert, index) => (
							<div
								key={index}
								onClick={e => toggleDataset(index, e.target)}
								className={findActivityValue(alert) === 0 ? 'display-none' : ''}>
								<LegendMarker $backgroundColor={alert.color} />
								{alert?.name && (
									<span>
										{alert.name} <b>({findActivityValue(alert)})</b>
									</span>
								)}
							</div>
						))}
					</LegendWrapper>
				</>
			)}
			{alerts.length === 0 && !isLoading && <EmptyState title={translate('noAlert')} image='alerts.svg?v2' />}
			{isLoading && (
				<Grid columns='1fr' rows='1fr' stretch='450px' horizAlign='center' vertAlign='center'>
					<Loader />
				</Grid>
			)}
		</div>
	);
};

export default AiAlertsChart;
