import * as React from 'react';
import LightTheme from 'calls/styles/LightTheme.js';

const Admission = props => {
	return (
		<svg
			width={25}
			height={24}
			viewBox='0 0 25 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			color={LightTheme.colors.grayZero}
			{...props}>
			<path d='M15 5H17C17.5523 5 18 5.44772 18 6V10M20 21H19C18.4477 21 18 20.5523 18 20V16' stroke='currentColor' />
			<path d='M21 10V16' stroke='currentColor' strokeMiterlimit='10' strokeLinecap='round' />
			<path d='M18 13H24' stroke='currentColor' strokeMiterlimit='10' strokeLinecap='round' />
			<path
				d='M5 21V5.80217C5 5.33347 5.32553 4.92766 5.78307 4.82598L12.7831 3.27043C13.4076 3.13165 14 3.60687 14 4.24662V20C14 20.5523 13.5523 21 13 21H5ZM5 21H3'
				stroke='currentColor'
			/>
			<circle cx='11' cy='13' r='1' fill='currentColor' />
		</svg>
	);
};

export default Admission;
