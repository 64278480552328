import React, { useState, useEffect, useRef } from 'react';
import { injectIntl, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Modal from 'components/Common/Modal.jsx';
import { postUserIdleMessage, onUserIdleMessage, closeUserIdleChannel } from 'infrastructure/helpers/userIdleChannel.js';
import { getUserIdleConfigurationMinutes } from 'api/userIdleConfigurations.js';
import { UserRoles } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import { getUserRole } from 'infrastructure/auth.js';
import { getUserRoleId } from 'infrastructure/helpers/commonHelpers.js';

const UserIdleCheck = () => {
	const intl = useIntl();

	const alertDisplayTime = 30; // seconds

	const [isLogoutAlertOn, setLogoutAlert] = useState(false);
	const [secondCounter, setSecondCounter] = useState(alertDisplayTime);
	const [minutes, setMinutes] = useState(null);

	const userIdleTimeout = useRef(null);
	const userSession = useSelector(state => state.user.userSession);
	const logoutInterval = useRef(null);

	const postMessages = {
		CONFIRMED: 'CONFIRMED',
		IN_CALL: 'IN_CALL',
	};

	useEffect(() => {
		bindListeners();
		return () => {
			unbindListeners();
			clearTimeout(userIdleTimeout.current);
			clearInterval(logoutInterval.current);
			closeUserIdleChannel();
		};
	}, []);

	useEffect(() => {
		const getUserIdleConfiguration = async (healthSystemId, roleId) => {
			if (!healthSystemId || !roleId) {
				return;
			}
			const result = await getUserIdleConfigurationMinutes(healthSystemId, roleId);
			if (!result.error) {
				setMinutes(result && !result.error ? result.minutes : null);
			}
		};
		if (![UserRoles.GUEST, UserRoles.PATIENT, UserRoles.SUPER_ADMIN].includes(getUserRole())) {
			unbindListeners();
			const roleId = getUserRoleId(getUserRole());
			if (roleId) {
				getUserIdleConfiguration(userSession.healthSystem.id, roleId);
			}
			startTimeout();
			bindListeners();
		}
	}, [getUserRole(), userSession.healthSystem.id, minutes]);

	useEffect(() => {
		if (secondCounter === 0) {
			clearInterval(logoutInterval.current);
			const { pathname } = window.location;
			if (pathname.startsWith('/call') || pathname.startsWith('/patient-feed') || pathname === '/monitoring') {
				setLogoutAlert(false);
				window.postMessage(postMessages.IN_CALL, window.location.href);
			} else {
				window.location.href = '/logout';
			}
		}
	}, [secondCounter]);

	const setActiveUser = () => {
		postUserIdleMessage();
		resetTimeout();
	};

	const resetTimeout = () => {
		if (userIdleTimeout.current && !isLogoutAlertOn) {
			clearTimeout(userIdleTimeout.current);
			startTimeout();
		}
	};

	const bindListeners = () => {
		window.addEventListener('mousemove', setActiveUser);
		window.addEventListener('scroll', setActiveUser);
		window.addEventListener('keydown', setActiveUser);
		window.addEventListener('resize', setActiveUser);
		window.addEventListener('click', setActiveUser);
		onUserIdleMessage(tabsListener);
	};

	const tabsListener = message => {
		if (message.data === postMessages.CONFIRMED) {
			resetActivity();
		} else {
			resetTimeout();
		}
	};

	const unbindListeners = () => {
		window.removeEventListener('mousemove', setActiveUser);
		window.removeEventListener('scroll', setActiveUser);
		window.removeEventListener('keydown', setActiveUser);
		window.removeEventListener('resize', setActiveUser);
		window.removeEventListener('click', setActiveUser);
	};

	const startTimeout = () => {
		if (minutes) {
			userIdleTimeout.current = setTimeout(() => {
				openLogoutAlert();
			}, minutes * 60000);
		}
	};

	const openLogoutAlert = () => {
		clearTimeout(userIdleTimeout.current);
		clearInterval(logoutInterval.current);

		setLogoutAlert(true);
		setSecondCounter(alertDisplayTime);
		logoutInterval.current = setInterval(() => {
			if (secondCounter > 0) {
				setSecondCounter(prevState => prevState - 1);
			}
		}, 1000);
	};

	const closeAlert = () => {
		postUserIdleMessage(postMessages.CONFIRMED);
		resetActivity();
	};

	const resetActivity = () => {
		setLogoutAlert(false);
		clearInterval(logoutInterval.current);
		setActiveUser();
	};

	return (
		<Modal
			className='user-idle-modal'
			display={isLogoutAlertOn}
			position='center'
			onModalSubmit={closeAlert}
			onModalClose={closeAlert}
			hideCloseButton={true}
			primaryButtonLabel={intl.formatMessage({ id: 'imHere' })}>
			<>
				<h4>{translate('idleMessage', { value: minutes })}</h4>
				<p>{translate('idleMessageDescription', { value: secondCounter })}</p>
			</>
		</Modal>
	);
};

export default injectIntl(UserIdleCheck);
