import translate from 'i18n-translations/translate.jsx';
import TimePicker from 'components/Common/TimePicker.jsx';
import { Range } from 'constants/enums.js';
import { timeStringToUnixTimeStamp } from 'infrastructure/helpers/dateHelper.js';

const AiSleepScheduler = ({ startTime, endTime, handleSleepScheduleChange, isMonitoring = false }) => (
	<div className='top-15 ai-timer-schedule'>
		{!isMonitoring && <p className='bottom-5'>{translate('setSleepSchedule')}</p>}
		<div className='flex-1'>
			<div className='picker-container'>
				<span>{translate('startTime')}</span>
				<div className='row-1'>
					<div className='date-range-time-pickers'>
						<TimePicker
							key={Range.START}
							value={timeStringToUnixTimeStamp(startTime)}
							index={0}
							handleTimeChange={value => handleSleepScheduleChange(Range.START, value)}
							disableOption={false}
						/>
					</div>
				</div>
			</div>
			<div className='picker-container'>
				<span>{translate('endTime')}</span>
				<div className='row-1'>
					<div className='date-range-time-pickers'>
						<TimePicker
							key={Range.END}
							value={timeStringToUnixTimeStamp(endTime)}
							index={1}
							handleTimeChange={value => handleSleepScheduleChange(Range.END, value)}
							disableOption={false}
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
);

export default AiSleepScheduler;
