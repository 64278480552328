
import { DoctorActionTypes } from 'constants/action-types.js';

const initialState = {
	selectedDoctor: null,
	doctors: [],
	isRequestUpdated: false,
};

export const myDoctors = (state = initialState, action = { type: null, payload: null }) => {
	switch (action.type) {
		case DoctorActionTypes.SET_SELECTED_DOCTOR:
			return {
				...state,
				selectedDoctor: action.payload,
			};
		case DoctorActionTypes.SET_DOCTORS:
			return {
				...state,
				doctors: action.payload,
			};
		case DoctorActionTypes.SET_IS_REQUEST_UPDATED:
			return {
				...state,
				isRequestUpdated: action.payload,
			};
		default:
			return state;
	}
};
