import React, { useState, useEffect, useRef, useContext } from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import {
	isPictureAttachment,
	isVideoAttachment,
	removeSpecialCharacters,
	isDocumentAttachment,
} from 'infrastructure/helpers/commonHelpers.js';
import { buildProfilePic, onErrorDefaultSrc } from 'infrastructure/helpers/thumbnailHelper.js';
import { getUserInfo } from 'infrastructure/auth.js';
import Modal from 'components/Common/Modal.jsx';
import Form from 'components/Common/Form.jsx';
import { getConversationAttachments, deleteMessage } from 'api/messenger.js';
import SocketEvents from 'constants/socket-events.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import { convertUTCDateToLocalDateOrTime } from 'infrastructure/helpers/dateHelper.js';
import translate from 'i18n-translations/translate.jsx';
import { APP_CONFIG } from 'constants/global-variables.js';

const MediaViewer = props => {
	const galleryContentElement = useRef(null);
	const [attachments, setAttachments] = useState({
		list: props.attachments,
		index: props.attachments.findIndex(attachment => attachment.id === props.selectedAttachment.id),
		reachedEnd: false,
	});
	const [rotation, setRotation] = useState(0);
	const [showDeletePrompt, setShowDeletePrompt] = useState(false);
	const [isGridView, setIsGridView] = useState(false);
	const [isRotationVertical, setIsRotationVertical] = useState(false);
	const [isLoadingMore, setIsLoadingMore] = useState(false);
	const intl = useIntl();
	const socket = useContext(SocketContext);
	const { setSelectedAttachment, onModalClose } = props;

	useEffect(() => {
		if (props.isFromMonitoring) {
			document.addEventListener('contextmenu', e => {
				e.preventDefault();
			});
			document.onkeydown = e => {
				if (e.code === 'F12') {
					return false;
				}
				if (e.ctrlKey && e.shiftKey && e.code === 'KeyI') {
					return false;
				}
				if (e.ctrlKey && e.shiftKey && e.code === 'KeyC') {
					return false;
				}
				if (e.ctrlKey && e.shiftKey && e.code === 'KeyJ') {
					return false;
				}
				if (e.ctrlKey && e.code === 'KeyU') {
					return false;
				}
				return false;
			};
		}
		return () => {
			document.onkeydown = () => {};
			document.removeEventListener('contextmenu', () => {});
		};
	}, [props.isFromMonitoring]);

	useEffect(() => {
		const escFunction = event => {
			if (event.keyCode === 27) {
				setSelectedAttachment({});
				onModalClose();
			}
		};
		const handleOnMessageDeleted = data => {
			if (props.conversationId !== data.conversationId) {
				return;
			}

			setAttachments(prevState => {
				const attachmentsList = [...prevState.list];
				const foundIndex = attachmentsList.findIndex(item => item.messageId === data.messageId);
				if (foundIndex !== -1) {
					attachmentsList.splice(foundIndex, 1);
				}
				const newIndex = attachmentsList.length === prevState.index ? prevState.index - 1 : prevState.index;
				return {
					list: attachmentsList,
					index: newIndex,
					...prevState,
				};
			});
			setShowDeletePrompt(false);
			if (attachments.list.length === 0) {
				setSelectedAttachment({});
				onModalClose();
			}
		};
		document.addEventListener('keydown', escFunction, false);
		socket.on(SocketEvents.Conversation.ON_MESSAGE_DELETED, handleOnMessageDeleted);
		return () => {
			document.removeEventListener('keydown', escFunction, false);
			socket.off(SocketEvents.Conversation.ON_MESSAGE_DELETED, handleOnMessageDeleted);
		};
	}, [attachments.list.length, setSelectedAttachment, onModalClose, props.conversationId, socket]);

	const closeModal = () => {
		props.setSelectedAttachment({});
		props.onModalClose();
	};

	const getSenderName = senderId => {
		const { userId, firstName, lastName } = getUserInfo();
		let fullName = `${firstName} ${lastName}`;
		if (userId !== parseInt(senderId, 10)) {
			const selectedMember = getSelectedMember();
			if (selectedMember) {
				fullName = `${selectedMember.firstName} ${selectedMember.lastName}`;
			}
		}
		return fullName;
	};

	const getSenderImage = senderId => {
		const { userId, profilePicture } = getUserInfo();
		let pic = `${APP_CONFIG.profilePicBaseUrl}${profilePicture}`;
		if (userId !== parseInt(senderId, 10)) {
			const selectedMember = getSelectedMember();
			if (selectedMember) {
				pic = buildProfilePic(selectedMember.profilePicture);
			}
		}
		return pic;
	};

	const getSelectedMember = () => {
		return props.selectedUser
			? props.selectedUser.owner
			: props.members.find(member => member.userId === +props.selectedAttachment.sender?.objectId);
	};

	const handleOnRecentPicsClick = index => {
		setAttachments(prevState => ({ ...prevState, index }));
		setRotation(0);
	};

	const nextMedia = () => {
		if (attachments.reachedEnd && attachments.index === attachments.list.length - 1) {
			return;
		}
		if (attachments.index === attachments.list.length - 1) {
			loadMoreAttachments();
			return;
		}
		setAttachments(prevState => ({ ...prevState, index: prevState.index + 1 }));
		setRotation(0);
	};

	const previousMedia = () => {
		setAttachments(prevState => ({ ...prevState, index: prevState.index - 1 }));
		setRotation(0);
	};

	const getLatestDate = () => {
		if (attachments.list.length === 0) {
			return null;
		}
		const dates = attachments.list.map(item => item.dateCreated);
		return Math.min(...dates);
	};

	const rotate = () => {
		let newRotation = rotation + 90;
		const verticalRotationPoints = [90, 270, -270, -90];
		if (newRotation >= 360) {
			newRotation = -360;
		}
		setRotation(newRotation);
		setIsRotationVertical(verticalRotationPoints.includes(newRotation));
	};

	const loadMoreAttachments = async () => {
		if (attachments.reachedEnd) {
			return;
		}
		setIsLoadingMore(true);
		const response = await getConversationAttachments(props.conversationId, {
			getDocuments: false,
			getMedia: true,
			latest: getLatestDate(),
			limit: 20,
		});
		setIsLoadingMore(false);
		setAttachments(prevState => ({
			index: response.attachments.length === 0 ? prevState.index : prevState.index + 1,
			list: prevState.list.concat(response.attachments),
			reachedEnd: response.attachments.length === 0,
		}));
	};

	const handleDeleteFile = () => {
		const selectedAttachment = attachments.list[attachments.index];
		deleteMessage(selectedAttachment.messageId, false);
		closeModal();
	};

	const handleGalleryItemClick = index => {
		setAttachments(prevState => ({ ...prevState, index }));
		setIsGridView(prevState => !prevState);
	};

	const handleScroll = () => {
		const element = galleryContentElement.current;
		if (element.offsetHeight + element.scrollTop === element.scrollHeight) {
			loadMoreAttachments();
		}
	};

	let currentIndex;
	let sender;
	let senderName;
	let senderImage;
	let recentIndexes;
	let dateCreated;
	if (attachments.list.length !== 0) {
		currentIndex = attachments.index;
		sender = attachments.list[currentIndex].sender;
		senderName = getSenderName(sender.objectId);
		senderImage = getSenderImage(sender.objectId);
		dateCreated = convertUTCDateToLocalDateOrTime(attachments.list[currentIndex].dateCreated);
	}
	if (currentIndex === 0) {
		recentIndexes = [currentIndex, currentIndex + 1, currentIndex + 2];
	} else if (currentIndex !== 0 && attachments.list.length - 1 !== currentIndex) {
		recentIndexes = [currentIndex - 1, currentIndex, currentIndex + 1];
	} else if (attachments.list.length - 1 === currentIndex) {
		recentIndexes = [currentIndex - 2, currentIndex - 1, currentIndex];
	}
	return (
		<div
			className={classNames(
				'modal',
				'center',
				props.display ? 'show' : '',
				props.isLoading ? 'loading' : '',
				props.isFromMonitoring ? 'is-from-monitoring' : ''
			)}>
			{!isGridView && (
				<div className='media__wrapper'>
					<div className='media-viewer-header'>
						<div className='media-sender'>
							<div className='media-sender-info'>
								<div>
									<div className='media-sender-profile-pic'>
										<img src={senderImage} alt='profile' />
									</div>
								</div>
								<div>
									<p>{senderName}</p>
									<p>{dateCreated}</p>
								</div>
							</div>
							{!props.isFromMonitoring && (
								<div className='media-action-buttons'>
									<a
										className='download-media'
										title={intl.formatMessage({ id: 'download' })}
										href={props.selectedAttachment.url}>
										<img
											alt='download'
											src='https://solaborateappeus.blob.core.windows.net:443/cdnstag/img/outline-cloud_download-24px.svg'
										/>
									</a>
									<span
										onClick={() => setShowDeletePrompt(prevState => !prevState)}
										id='deleteMedia'
										className='delete-media'
										title={intl.formatMessage({ id: 'delete' })}>
										<i className='material-icons delete-media-icon'>delete_outline</i>
									</span>
									<span title={intl.formatMessage({ id: 'rotate' })} onClick={rotate}>
										<i className='material-icons'>rotate_right</i>
									</span>
								</div>
							)}
							<div className='media-recent-images'>
								{attachments.list.map((attachment, index) => {
									return (
										<React.Fragment key={attachment.id}>
											{recentIndexes.includes(index) && (
												<div
													onClick={() => handleOnRecentPicsClick(index)}
													className={index === attachments.index ? 'active' : ''}>
													<img alt='recent' src={attachment.thumbnailUrl} />
												</div>
											)}
										</React.Fragment>
									);
								})}
								<div onClick={() => setIsGridView(prevState => !prevState)} className='view-gallery'>
									<img
										src='https://solaborateappeus.blob.core.windows.net:443/cdnstag/img/outline-burst_mode-24px.svg'
										alt='outline-burst'
									/>
								</div>
							</div>

							<div>
								<span className='close-media-viewer' onClick={closeModal}>
									<i className='material-icons'>close</i>
								</span>
							</div>
						</div>
					</div>
					{isLoadingMore && (
						<div className='media-viewer-content'>
							<span className='circle-loader' />
						</div>
					)}
					{!isLoadingMore && !props.isFromMonitoring && (
						<div className='media-viewer-content'>
							{attachments.list.map((attachment, index) => {
								return (
									<React.Fragment key={attachment.id}>
										{index === attachments.index && (
											<>
												{isPictureAttachment(attachment.typeId) && (
													<img
														style={{ transform: `rotate(${rotation}deg)` }}
														alt='chat'
														src={attachment.url}
														className={isRotationVertical ? 'chatimg rotated' : 'chatimg'}
														onError={e => onErrorDefaultSrc(e)}
													/>
												)}
												{isVideoAttachment(attachment.typeId) && (
													<>
														<video
															style={{ transform: `rotate(${rotation}deg)` }}
															className={isRotationVertical ? 'rotated' : ''}
															controls
															key={attachment.id}>
															<source src={attachment.url} type={`video/${removeSpecialCharacters(attachment.extension)}`} />
														</video>
													</>
												)}
											</>
										)}
									</React.Fragment>
								);
							})}

							<div>
								<>
									{attachments.index !== 0 && (
										<i onClick={previousMedia} className='material-icons arrow-left'>
											chevron_left
										</i>
									)}
									{!(attachments.index === attachments.list.length - 1 && attachments.reachedEnd) && (
										<i onClick={nextMedia} className='material-icons arrow-right'>
											chevron_right
										</i>
									)}
								</>
							</div>
							<Modal
								display={showDeletePrompt}
								position='center'
								onModalClose={() => setShowDeletePrompt(false)}
								onModalSubmit={handleDeleteFile}
								primaryButtonLabel='Delete'>
								<Form title={translate('deleteMediaTitle')} height={225}>
									<p className='modal-paragraph'>{translate('deleteMediaWarning')}</p>
									<p className='modal-paragraph'>{translate('actionUndone')}</p>
								</Form>
							</Modal>
						</div>
					)}
					{!isLoadingMore && props.isFromMonitoring && (
						<div className='media-viewer-content'>
							<video
								autoPlay
								playsInline
								controls
								controlsList='nodownload'
								style={{ transform: `rotate(${rotation}deg)` }}
								className={isRotationVertical ? 'rotated' : ''}>
								<source src={props.selectedRecording} />
							</video>
						</div>
					)}
				</div>
			)}
			{isGridView && (
				<div className='media__wrapper'>
					<div className='media-viewer-header media-gallery-header'>
						<div>
							<span onClick={() => setIsGridView(prevState => !prevState)}>
								<i className='material-icons'>keyboard_arrow_left</i>
								<span>Back to photo view</span>
							</span>
						</div>
					</div>
					<div className='media-viewer-gallery' ref={galleryContentElement} onScroll={handleScroll}>
						{attachments.list.map((attachment, index) => {
							return (
								<React.Fragment key={attachment.id}>
									{!isDocumentAttachment(attachment.typeId) && (
										<div onClick={() => handleGalleryItemClick(index)} className='media-grid-item' key={attachment.id}>
											<img
												alt='gallery'
												src={!isVideoAttachment(attachment.typeId) ? attachment.url : attachment.thumbnailUrl}
												className='chatimg'
												onError={e => onErrorDefaultSrc(e)}
											/>
										</div>
									)}
								</React.Fragment>
							);
						})}
					</div>
				</div>
			)}
		</div>
	);
};

export default MediaViewer;
