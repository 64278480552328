import classNames from 'classnames';
import PropTypes from 'prop-types';

const Toggle = props => {
	return (
		<div
			className={classNames(
				'toggle',
				props.className,
				props.toggledOn ? 'toggle-on' : 'toggle-off',
				props.disabled ? 'disabled' : ''
			)}
			onClick={!props.disabled ? props.onToggle : null}>
			<span>{props.toggleOnText}</span>
			<span>{props.toggleOffText}</span>
		</div>
	);
};

Toggle.propTypes = {
	toggleOnText: PropTypes.string.isRequired,
	toggleOffText: PropTypes.string.isRequired,
	toggledOn: PropTypes.bool.isRequired,
	className: PropTypes.string.isRequired,
	onToggle: PropTypes.func.isRequired,
	disabled: PropTypes.bool.isRequired,
};
export default Toggle;
