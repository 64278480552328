import Dropdown from 'components/Common/Dropdown.jsx';
import translate from 'i18n-translations/translate.jsx';
import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';
import { useSelector } from 'react-redux';
import Profile from 'icons/CareEvents/Profile.jsx';
import Alerts from 'icons/CareEvents/Alerts.jsx';
import CareEvents from 'icons/CareEvents/CareEvents.jsx';
import PatientHistoryIcon from 'calls/icons/PatientHistory.jsx';
import Button from 'components/Common/Button.jsx';

const CareEventsDropDownOptions = ({ toggleViewHistory, patient, openViewProfileModal, openAlertsModal }) => {
	const isDarkMode = useSelector(state => state.user.darkMode);
	return (
		<Dropdown position='bottom' icon='more_vert' className='patient-view-more-wrapper'>
			<div className='patient-info-hello-list patient-info-view-more-list'>
				<div className='flex'>
					<Button
						onClick={() => toggleViewHistory(patient)}
						className='flex'
						svgIcon={<PatientHistoryIcon color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />}
						text={translate('history')}
					/>
				</div>
				<div className='flex'>
					<Button
						onClick={() => openViewProfileModal(patient)}
						className='flex'
						svgIcon={<Profile color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />}
						text={translate('viewProfile')}
					/>
				</div>
				<div className='flex'>
					<Button
						onClick={() => openAlertsModal(patient)}
						className='flex'
						svgIcon={<Alerts color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />}
						text={translate('viewAlerts')}
					/>
				</div>
				<div className='flex'>
					<Button
						className='flex'
						svgIcon={<CareEvents color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />}
						text={translate('careEvents')}
					/>
				</div>
			</div>
		</Dropdown>
	);
};

export default CareEventsDropDownOptions;
