import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { Modal, Form, Alert } from 'components/index.js';
import { addUpdateCompanionDevice, getCompanionModes, getUnassignedCompanionDevices } from 'api/devices.js';
import translate from 'i18n-translations/translate.jsx';
import { HealthcareErrorCode } from 'constants/enums.js';

const Companion = props => {
	const { hospitalId, departmentId, floorId, roomId } = useParams();
	const [isAddDeviceModalLoading, setIsAddDeviceModalLoading] = useState(false);
	const [error, setError] = useState(null);
	const [formSubmitError, setFormSubmitError] = useState(null);
	const [companionMode, setCompanionMode] = useState(null);
	const [companionDevice, setCompanionDevice] = useState(null);
	const intl = useIntl();

	const getMappedCompanionModes = async () => {
		const response = await getCompanionModes();
		if (response.error) {
			setError(response.error?.message);
			return [];
		}
		return response.data.map(mode => ({
			value: mode.id,
			label: mode.companionModes,
		}));
	};

	const getMappedCompanionDevices = async search => {
		if (!companionMode) {
			return [];
		}
		const response = await getUnassignedCompanionDevices(companionMode.value, search);
		if (response?.error) {
			setError(response.error?.message);
			return [];
		}
		return response.result.map(device => ({
			value: device.solHelloDeviceId,
			label: `${device.deviceFamilyName} / ${device.serialNumber}`,
		}));
	};

	const handleSubmit = async () => {
		if (!companionDevice) {
			setError(intl.formatMessage({ id: 'pleaseSelectCompanionDevice' }));
			return;
		}
		const solHelloDeviceId = companionDevice.value;
		const roomName = props.roomName;
		setIsAddDeviceModalLoading(true);
		setFormSubmitError(null);
		const response = await addUpdateCompanionDevice({ solHelloDeviceId, roomName, hospitalId, departmentId, floorId, roomId });
		if (response.error) {
			const responseErrorCode = response.error.response.data.code;
			const errorMessage =
				responseErrorCode === HealthcareErrorCode.EXISTING_COMPANION_MODE
					? intl.formatMessage({ id: 'assignCompanionDeviceError' })
					: response.error?.message;

			setFormSubmitError(errorMessage);
			setError(null);
			setIsAddDeviceModalLoading(false);
			return;
		}
		props.loadRoom();
		props.setIsCompanionModalOpen(false);
	};

	const handleSelectDropdown = (e, actionMeta) => {
		if (actionMeta.name === 'companionMode') {
			setCompanionMode(e);
			setCompanionDevice(null);
			return;
		}
		setCompanionDevice(e);
		setError(null);
	};

	return (
		<Modal
			display={props.isCompanionModalOpen}
			isLoading={isAddDeviceModalLoading}
			onModalSubmit={handleSubmit}
			position='right'
			className='assign-patients-modal'
			onModalClose={() => props.setIsCompanionModalOpen(false)}
			hideCloseButton={false}
			primaryButtonLabel={intl.formatMessage({ id: 'save' })}>
			<Form title={translate('roomDevice')}>
				<p className='label'>{translate('assignCompanionDevice')}</p>
				<p>{translate('selectCompanionMode')}</p>
				<AsyncSelect
					name='companionMode'
					loadOptions={getMappedCompanionModes}
					defaultOptions
					cacheOptions
					value={companionMode}
					onChange={handleSelectDropdown}
					noOptionsMessage={() => intl.formatMessage({ id: 'noOptions' })}
					classNamePrefix='react-select'
					placeholder={intl.formatMessage({ id: 'select' })}
				/>
				<p>{translate('selectCompanionDevice')}</p>
				<AsyncSelect
					key={companionMode?.value}
					name='companionDevice'
					isDisabled={!companionMode?.value}
					loadOptions={getMappedCompanionDevices}
					defaultOptions
					cacheOptions
					value={companionDevice}
					onChange={handleSelectDropdown}
					noOptionsMessage={() => intl.formatMessage({ id: 'noOptions' })}
					classNamePrefix='react-select'
					placeholder={intl.formatMessage({ id: 'select' })}
				/>
				<Alert display={error} message={error} variant='error' onClose={() => setError(null)} />
				<Alert display={formSubmitError} message={formSubmitError} variant='dark' onClose={() => setFormSubmitError(null)} />
			</Form>
		</Modal>
	);
};

export default Companion;
