import classNames from 'classnames';
import { healthCareCdnUrl } from 'constants/global-variables.js';

const AiVitalSignDetails = ({ vitalSign, className }) => (
	<div key={vitalSign.id} className={classNames('flex', className)}>
		<img src={`${healthCareCdnUrl}vital-signs/ai/${vitalSign.img}.svg`} alt='icon' />
		<div className='flex column-direction left-10'>
			<span>{vitalSign.label}</span>
			<div className='flex'>
				<span className='right-s'>{vitalSign.result}</span>
				<span>{vitalSign.unit}</span>
			</div>
		</div>
	</div>
);

export default AiVitalSignDetails;
