import styled, { css } from 'styled-components';
import { WindowSize } from 'calls/enums/index.js';
import { CallControlHeight } from 'calls/constants/index.js';
import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';

const getVisibleParticipantsBox = count => css`
	> div:not(:nth-of-type(-n + ${count})):not(.more-participants) {
		display: none;
	}
`;

const calculateGridBox = (rowCount, columnCount, screenWidth = 100, screenHeight = 100) => css`
	> div {
		width: calc((16 / 9) * (${screenHeight / rowCount}dvh - ${CallControlHeight / rowCount}px) - var(--spacing-s));
		max-width: calc(${screenWidth / columnCount}dvw - var(--spacing-s));
		height: calc(((9 / 16) * ${screenWidth / columnCount}dvw) - var(--spacing-s));
		max-height: calc((${screenHeight / rowCount}dvh - ${CallControlHeight / rowCount}px) - var(--spacing-s));
	}
`;

/**
 * @type {import('styled-components').StyledComponent<"aside", any, { $isDarkMode: boolean, $isMinimizedView: boolean, $isGridView: boolean, $isRightToLeft: boolean, $isParticipantsViewHidden: boolean, $isPatientMeasurementsOpen: boolean, $isMedicalInfoOpen: boolean, $isCallSettingsPanelOpen: boolean, $isLiveCaptionsOpen: boolean, $isMoreParticipantsBoxDisplayed: boolean, $isMoreParticipantsViewOpen: boolean, $isWhiteboardVisible: boolean, $gridCount: number, $isAiOpen: boolean, $isRoomSignOpen: boolean}, never>}
 */
const StyledParticipantWrapper = styled.aside`
	${props =>
		!props.$isGridView &&
		props.$gridCount <= 2 &&
		css`
			position: absolute;
			z-index: 1;
			right: var(--spacing-m);
			top: var(--spacing-m);
			> section {
				> div {
					height: 130px;
					margin-bottom: var(--spacing-s);
				}
				@media (max-width: ${WindowSize.TABLET}px) {
					display: none;
				}
			}
		`};

	${props =>
		(props.$isGridView || props.$gridCount > 2) &&
		css`
			> section {
				display: grid;
				grid-gap: var(--spacing-s);
			}
		`};

	${props =>
		props.$isRightToLeft &&
		css`
			left: ${props.$isPatientMeasurementsOpen ? `calc(50% + ${LightTheme.spacing[2]}px)` : `${LightTheme.spacing[5]}px`};
			right: auto;
		`};

	${props =>
		props.$gridCount > 2 &&
		!props.$isGridView &&
		css`
			${props.$isParticipantsViewHidden && 'display: none;'}

			> section {
				grid-template-columns: repeat(5, 1fr);
				margin-bottom: var(--spacing-s);

				${props.$isCallSettingsPanelOpen ||
				props.$isLiveCaptionsOpen ||
				props.$isMedicalInfoOpen ||
				props.$isPatientMeasurementsOpen ||
				props.$isMoreParticipantsViewOpen ||
				props.$isWhiteboardVisible ||
				props.$isAiOpen ||
				props.$isRoomSignOpen
					? css`
							> div {
								height: calc(9 / 16 * ${props.$isPatientMeasurementsOpen && !props.$isCallSettingsPanelOpen ? 16.6 : 14}dvw);
							}
							&:not(.grid-1):not(.grid-2):not(.grid-3):not(.grid-4):not(.grid-5):not(.grid-6) {
								> div {
									height: calc(9 / 16 * ${props.$isPatientMeasurementsOpen && !props.$isCallSettingsPanelOpen ? 12.5 : 14}dvw);
								}
							}
						`
					: css`
							> div {
								width: auto;
								height: 100px;
								@media (min-height: 700px) {
									height: 120px;
								}
								@media (min-height: 800px) {
									height: 130px;
								}
							}
						`}
				@media (max-width: ${WindowSize.TABLET}px) {
					grid-template-columns: repeat(4, 1fr);
				}
				@media (max-width: ${WindowSize.MOBILE}px) {
					grid-template-columns: repeat(3, 1fr);
				}
			}
		`}

	${props =>
		!props.$isGridView &&
		(props.$isCallSettingsPanelOpen ||
			(!props.$isMedicalInfoOpen &&
				!props.$isPatientMeasurementsOpen &&
				!props.$isWhiteboardVisible &&
				!props.$isAiOpen &&
				!props.$isRoomSignOpen)) &&
		css`
			> section {
				${props.$isMoreParticipantsBoxDisplayed ? getVisibleParticipantsBox(4) : getVisibleParticipantsBox(5)}

				@media (max-width: ${WindowSize.TABLET}px) {
					${props.$isMoreParticipantsBoxDisplayed ? getVisibleParticipantsBox(3) : getVisibleParticipantsBox(4)}
				}

				@media (max-width: ${WindowSize.MOBILE}px) {
					${props.$isMoreParticipantsBoxDisplayed ? getVisibleParticipantsBox(2) : getVisibleParticipantsBox(3)}
				}
			}
		`}

	${props =>
		!props.$isGridView &&
		!props.$isCallSettingsPanelOpen &&
		props.$isPatientMeasurementsOpen &&
		css`
			> section {
				grid-template-columns: repeat(3, 1fr);

				&:not(.grid-1):not(.grid-2):not(.grid-3):not(.grid-4):not(.grid-5):not(.grid-6) {
					grid-template-columns: repeat(4, 1fr);
				}
			}
		`}

		${props =>
		!props.$isGridView &&
		props.$isRightToLeft &&
		!props.$isPatientMeasurementsOpen &&
		css`
			left: ${LightTheme.spacing[5]}px;
			right: auto;
		`}

	${props =>
		props.$isGridView &&
		css`
			z-index: 1;
			align-self: center;
			> section {
				${!props.$isMinimizedView &&
				css`
					@media (min-width: ${WindowSize.TABLET}px) {
						&.grid-1,
						&.grid-2 {
							grid-template-columns: repeat(1, 1fr);
						}

						&.grid-1 {
							${calculateGridBox(1, 1)}
						}

						&.grid-2 {
							${calculateGridBox(2, 1)}
						}

						&.grid-3,
						&.grid-4 {
							grid-template-columns: repeat(2, 1fr);
							${calculateGridBox(2, 2)}
						}

						&.grid-3 > div:last-of-type {
							left: 50%;
						}

						&.grid-5,
						&.grid-6,
						&.grid-7,
						&.grid-8,
						&.grid-9 {
							grid-template-columns: repeat(3, 1fr);
						}

						&.grid-5,
						&.grid-6 {
							${calculateGridBox(2, 3)}
						}

						&.grid-7,
						&.grid-8,
						&.grid-9 {
							${calculateGridBox(3, 3)}
						}

						&.grid-10,
						&.grid-11,
						&.grid-12,
						&.grid-13,
						&.grid-14,
						&.grid-15,
						&.grid-16 {
							grid-template-columns: repeat(4, 1fr);
						}

						&.grid-10,
						&.grid-11,
						&.grid-12 {
							${calculateGridBox(3, 4)}
						}

						&.grid-13,
						&.grid-14,
						&.grid-15,
						&.grid-16 {
							${calculateGridBox(4, 4)}
						}
					}
				`}

				${props.$isMinimizedView &&
				css`
					width: 100%;
					grid-template-columns: repeat(2, 1fr);
					margin: auto;
					grid-gap: var(--spacing-s);
				`}

				@media (max-width: ${WindowSize.TABLET}px) {
					width: 100%;
					grid-template-columns: repeat(2, 1fr);
					padding: ${LightTheme.spacing[3]}px;
					padding-bottom: 0;
					grid-gap: ${LightTheme.spacing[3]}px;

					${props.$gridCount < 7 &&
					css`
						height: 100%;
						align-content: center;
					`}
				}
			}

			@media (max-width: ${WindowSize.TABLET}px) {
				height: 100%;
				width: 100%;
			}

			${props.$isMinimizedView &&
			css`
				overflow: auto;
			`}

			${(props.$isCallSettingsPanelOpen ||
				props.$isLiveCaptionsOpen ||
				props.$isMedicalInfoOpen ||
				props.$isPatientMeasurementsOpen ||
				props.$isWhiteboardVisible ||
				props.$isAiOpen ||
				props.$isRoomSignOpen) &&
			css`
				margin: 0;
				> section {
					&.grid-3,
					&.grid-4,
					&.grid-5,
					&.grid-6 {
						grid-template-columns: repeat(2, 1fr);
					}

					&.grid-7,
					&.grid-8,
					&.grid-9,
					&.grid-10,
					&.grid-11,
					&.grid-12 {
						grid-template-columns: repeat(3, 1fr);
					}

					&.grid-13,
					&.grid-14,
					&.grid-15,
					&.grid-16 {
						grid-template-columns: repeat(${props.$isPatientMeasurementsOpen && !props.$isCallSettingsPanelOpen ? 3 : 4}, 1fr);
					}
				}
			`}

			${!props.$isMinimizedView &&
			(props.$isCallSettingsPanelOpen ||
				props.$isLiveCaptionsOpen ||
				props.$isMedicalInfoOpen ||
				props.$isWhiteboardVisible ||
				props.$isAiOpen ||
				props.$isRoomSignOpen) &&
			css`
				> section {
					&.grid-3,
					&.grid-4 {
						${calculateGridBox(2, 2, 70)}
					}

					&.grid-5,
					&.grid-6 {
						${calculateGridBox(3, 2, 70)}
					}

					&.grid-7,
					&.grid-8,
					&.grid-9 {
						${calculateGridBox(3, 3, 70)}
					}

					&.grid-10,
					&.grid-11,
					&.grid-12 {
						${calculateGridBox(4, 3, 70)}
					}

					&.grid-13,
					&.grid-14,
					&.grid-15,
					&.grid-16 {
						${calculateGridBox(4, 4, 70)}
					}
				}
			`}
			
			${!props.$isCallSettingsPanelOpen &&
			props.$isPatientMeasurementsOpen &&
			css`
				> section {
					&.grid-3,
					&.grid-4 {
						${calculateGridBox(2, 2, 50)}
					}

					&.grid-5,
					&.grid-6 {
						${calculateGridBox(3, 2, 50)}
					}

					&.grid-7,
					&.grid-8,
					&.grid-9 {
						${calculateGridBox(3, 3, 50)}
					}

					&.grid-10,
					&.grid-11,
					&.grid-12 {
						${calculateGridBox(4, 3, 50)}
					}

					&.grid-13,
					&.grid-14,
					&.grid-15,
					&.grid-16 {
						${calculateGridBox(5, 3, 50)}
					}
				}
			`}
		`}

	.more-participants {
		background: ${props => (props.$isDarkMode ? DarkTheme.colors.blueFive : LightTheme.colors.blueFive)};
		padding: var(--spacing-m);
		justify-content: center;
		align-items: center;
		display: flex;
		h1,
		p,
		span {
			color: ${LightTheme.colors.blueFour};
		}
		h1 {
			padding: 0;
			margin: 0;
			padding-right: var(--spacing-m);
			font-size: 50px;
			font-weight: 500;
		}
		p {
			font-weight: 500;
			font-size: 14px;
			padding: 0;
			margin: 0;
		}
		span {
			font-weight: 300;
			font-size: 12px;
		}
		&:hover {
			cursor: pointer;
			background: var(--blue-light-5-o30);
			transition: all 0.2s linear;
		}
		@media (max-width: ${WindowSize.TABLET}px) {
			flex-direction: column;
			align-items: flex-start;
			h1 {
				font-size: 40px;
			}
			span {
				display: none;
			}
		}
	}
`;

export default StyledParticipantWrapper;
