import { getRoomSettings } from 'api/adminConfigurations.js';
import { getPatientsAiSettings, updatePatientAiSetting, updatePatientAiSettings } from 'api/patients.js';
import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';
import classNames from 'classnames';
import Button from 'components/Common/Button.jsx';
import { Alert, ToastMessage } from 'components/index.js';
import {
	AiCommonConfigurations,
	AiCustomConfigurations,
	AiMultipleSelect,
} from 'components/AI/Monitoring/Configurations/index.js';
import { AiFieldToUpdate, FallPreventionSettingTypes, FallPreventionTypes } from 'constants/ai.jsx';
import { AiSetting, CallWorkflowType, SettingsCategory } from 'constants/configurationEnums.js';
import {
	DeviceFamilyTypes,
	ExcludedAiSettings,
	ExcludedAiSettingsHello3,
	IncludedAiSettings,
	KeyCodes,
	PatientAiSetting,
	RailType,
	SensitivityType,
} from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { generateCustomStyles } from 'constants/react-select-style.js';
import SocketEvents from 'constants/socket-events.js';
import { AiPatientMobilityHistory } from 'containers/AI/Monitoring/index.js';
import translate from 'i18n-translations/translate.jsx';
import {
	AiMeasurements,
	AiVoiceAnalysisOptions,
	BodyPosition,
	RailOptions,
	SensitivityTypes,
	TimerValues,
	aiFormatOptionLabel,
	aiTypesToShow,
	getAdminAiConfigurationsById,
	getCommonRoomAiConfigs,
	getConfiguredAiSettings,
	getAiFieldBySetting,
	isAnySettingActive,
	isSettingEnabled,
} from 'infrastructure/helpers/aiHelper.jsx';
import { getConfigurationValue, validateMaxLength } from 'infrastructure/helpers/commonHelpers.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import { actionCreators as aiSettingsActionCreators } from 'state/aiSettings/actions.js';

const AiPatientSettings = ({
	conferenceId,
	deviceId,
	patientId,
	onAiSettingClick,
	adminAiSettingsConfigurations,
	areEmergencyButtonsHiden,
	isNoteShowing,
	isDarkMode,
	toggleFallPrevention,
	isFallPrevention,
	userId,
	onAlertInfoClose,
	feedWarning,
	stopVoiceOver,
	roomId,
	deviceFamily,
	setIvBagPercentage,
	isRounding = false,
}) => {
	const intl = useIntl();
	const socket = useContext(SocketContext);
	const [selectedInactiveTime, setSelectedInactiveTime] = useState(null);
	const [selectedSensitivity, setSelectedSensitivity] = useState(null);
	const [selectedRails, setSelectedRails] = useState([]);
	const [selectedBodyPositions, setSelectedBodyPositions] = useState([]);
	const [error, setError] = useState(null);
	const [isMoreThanOneAi, setIsMoreThanOneAi] = useState(false);
	const [ivBagWarningValue, setIvBagWarningValue] = useState(null);
	const [isInit, setIsInit] = useState(true);
	const [isSettingsClicked, setIsSettingsClicked] = useState(false);
	const [selectedItem, setSelectedItem] = useState(null);
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [adminAiConfigs, setAdminAiConfigs] = useState([]);
	const [sensitivityTypes, setSensitivityTypes] = useState(SensitivityTypes(intl));
	const [railsOptions, setRailOptions] = useState(RailOptions(intl));
	const [bodyPostionOptions, setBodyPositionOptions] = useState(BodyPosition(intl));
	const [inactiveHours, setInactiveHours] = useState(TimerValues(intl));
	const [disabledSettings, setDisabledSettings] = useState({});
	const [aiVoiceEventOptions, setAiVoiceEventOptions] = useState(AiVoiceAnalysisOptions(intl));
	const [aiMeasurements, setAiMeasurements] = useState(AiMeasurements(intl));
	const aiSettingList = useSelector(state => state.aiSettingsList.aiSettings);
	const dispatch = useDispatch();
	const setAiSettingsAction = ai => dispatch(aiSettingsActionCreators.setPatientAiSettings(ai));
	const user = useSelector(state => state.user);
	const workflowType = isRounding ? CallWorkflowType.ROUNDING : CallWorkflowType.MONITORING;
	const initialSettings = aiSettingList.find(item => item.deviceId === deviceId)?.settings;

	useEffect(() => {
		const getPatientAiSettings = async () => {
			const response = await getPatientsAiSettings({ patientId, deviceId, roomId, workflowType: CallWorkflowType.MONITORING });
			if (response.error) {
				setError(response.error.message);
				return;
			}
			const pressureInjurySetting = response.patientAiSettings.find(
				item => item.settingTypeId === PatientAiSetting.PRESSURE_INJURY
			);
			if (pressureInjurySetting?.value) {
				setSelectedInactiveTime(inactiveHours.find(item => item.value === pressureInjurySetting.value));
			}
			const isAiDrawingsEnabled = isSettingEnabled(response.patientAiSettings, PatientAiSetting.AI_DRAWING);
			const fallPreventionStatus = response.patientAiSettings
				.filter(item => FallPreventionSettingTypes.includes(item.settingTypeId))
				.some(item => item.isEnabled);
			toggleFallPrevention(fallPreventionStatus, deviceId);
			onAiSettingClick(isAiDrawingsEnabled);
			setAiSettingsAction({ deviceId, settings: response.patientAiSettings });
			setIsInit(false);
		};
		getPatientAiSettings();
	}, [patientId, deviceId, isSettingsClicked]);

	useEffect(() => {
		const fetchRoomSettings = async () => {
			const adminAiSettings = await getRoomSettings(roomId, SettingsCategory.AI_SETTINGS);
			if (adminAiSettings.error) {
				setError(adminAiSettings.error.message);
				return;
			}
			setAdminAiConfigs(adminAiSettings.settings);
		};
		fetchRoomSettings();
	}, [roomId]);

	useEffect(() => {
		if (adminAiConfigs.length === 0 || !selectedItem) {
			return;
		}
		const aiConfigs = getAdminAiConfigurationsById(adminAiConfigs, selectedItem.aiTypeId, workflowType);
		if (!aiConfigs) {
			return;
		}

		handleSetSelectedOptions(selectedItem.aiTypeId, aiConfigs);
	}, [adminAiConfigs, selectedItem]);

	useEffect(() => {
		const onPatientAiSettingsUpdated = data => {
			if (deviceId !== data.deviceId || data.workflowType !== workflowType) {
				return;
			}
			const pressureInjurySetting = data.patientAiSettings.find(item => item.settingTypeId === PatientAiSetting.PRESSURE_INJURY);
			if (pressureInjurySetting?.value) {
				setSelectedInactiveTime(inactiveHours.find(item => item.value === pressureInjurySetting.value));
			}
			const isAiDrawingsEnabled = isSettingEnabled(data.patientAiSettings, PatientAiSetting.AI_DRAWING);
			const fallPreventionStatus = data.patientAiSettings
				.filter(item => FallPreventionSettingTypes.includes(item.settingTypeId))
				.some(item => item.isEnabled);

			toggleFallPrevention(fallPreventionStatus, deviceId);
			onAiSettingClick(isAiDrawingsEnabled);
			setAiSettingsAction({ deviceId, settings: data.patientAiSettings });
		};
		socket.on(SocketEvents.Alerts.PATIENT_AI_SETTINGS_UPDATED, onPatientAiSettingsUpdated);
		return () => {
			socket.off(SocketEvents.Alerts.PATIENT_AI_SETTINGS_UPDATED, onPatientAiSettingsUpdated);
		};
	}, [socket, userId, deviceId]);

	useEffect(() => {
		if (adminAiConfigs.length > 0) {
			const aiConfigs = getAdminAiConfigurationsById(adminAiConfigs, PatientAiSetting.PRESSURE_INJURY, workflowType);
			if (!aiConfigs) {
				return;
			}
			setInactiveHours(getCommonRoomAiConfigs(TimerValues(intl), aiConfigs?.values));
		}
	}, [adminAiConfigs, intl]);

	const selectedAiSetting = (foundSettings, settingTypeId) =>
		foundSettings.find(item => item.settingTypeId === settingTypeId)?.value;

	useEffect(() => {
		const foundDevice = aiSettingList.find(item => item.deviceId === deviceId);
		if (!foundDevice) {
			return;
		}
		handleSetSelectedSensitivity(foundDevice.settings);
		handleSetSelectedRails(foundDevice.settings);
		handleSetSelectedIVBag(foundDevice.settings);
		handleSetSelectedBodyPositions(foundDevice.settings);
	}, [aiSettingList, deviceId]);

	const handleSetSelectedOptions = (settingTypeId, aiConfigs) => {
		switch (settingTypeId) {
			case PatientAiSetting.PATIENT_GETTING_OUT_OF_BED:
				setSensitivityTypes(getCommonRoomAiConfigs(SensitivityTypes(intl), aiConfigs.values));
				break;
			case PatientAiSetting.RAILS:
				setRailOptions(getCommonRoomAiConfigs(RailOptions(intl), aiConfigs.values));
				break;
			case PatientAiSetting.PRESSURE_INJURY:
				setBodyPositionOptions(getCommonRoomAiConfigs(BodyPosition(intl), aiConfigs.bodyPositionValues));
				break;
			case PatientAiSetting.AI_VOICE_ANALYSIS:
				setAiVoiceEventOptions(getCommonRoomAiConfigs(AiVoiceAnalysisOptions(intl), aiConfigs.values));
				break;
			case PatientAiSetting.EWS_AI_VITAL_SIGNS:
				setAiMeasurements(getCommonRoomAiConfigs(AiMeasurements(intl), aiConfigs.measurementTypes));
				break;
			default:
				break;
		}
	};

	const handleSetSelectedSensitivity = foundSettings => {
		const selectedSensitivityValue = selectedAiSetting(foundSettings, PatientAiSetting.PATIENT_GETTING_OUT_OF_BED);
		if (selectedSensitivityValue) {
			setSelectedSensitivity(sensitivityTypes.find(item => item.value === selectedSensitivityValue));
		}
		if (!selectedSensitivityValue && isInit) {
			setSelectedSensitivity(null);
		}
	};

	const handleSetSelectedRails = foundSettings => {
		const foundRailDown = selectedAiSetting(foundSettings, PatientAiSetting.RAILS);
		if (foundRailDown) {
			const onMountRails = railsOptions.filter(rail => foundRailDown.includes(rail.value));
			setSelectedRails(onMountRails);
		}
		if (!foundRailDown && isInit) {
			setSelectedRails([]);
		}
	};

	const handleSetSelectedBodyPositions = foundSettings => {
		const foundBodyPosition = foundSettings.find(
			item => item.settingTypeId === PatientAiSetting.PRESSURE_INJURY
		)?.pressureInjuryBodyPositions;

		if (foundBodyPosition && isInit) {
			const onMountBodyPosition = bodyPostionOptions.filter(position => foundBodyPosition.includes(position.value));
			setSelectedBodyPositions(onMountBodyPosition);
		}
		if (!foundBodyPosition && isInit) {
			setSelectedBodyPositions([]);
		}
	};

	const handleSetSelectedIVBag = foundSettings => {
		const ivBagFluidLevel = selectedAiSetting(foundSettings, PatientAiSetting.IV_BAG);
		if (ivBagFluidLevel) {
			setIvBagWarningValue(parseInt(ivBagFluidLevel, 10));
		} else {
			setIvBagWarningValue(null);
		}
	};

	useEffect(() => {
		const toastTimeOut = setTimeout(() => {
			setIsMoreThanOneAi(false);
		}, 3000);

		return () => {
			clearTimeout(toastTimeOut);
		};
	}, [isMoreThanOneAi]);

	const updateAiSettings = async (params, isToggleBulk = false) => {
		const response = isToggleBulk ? await updatePatientAiSettings(params) : await updatePatientAiSetting(params);

		if (response.error) {
			setError(response.error.message);
			setAiSettingsAction({
				settings: initialSettings,
				deviceId,
			});
		}
	};

	const toggleBulkSettings = async isEnabled => {
		const dataToSend = [
			{
				settingTypeId: PatientAiSetting.PATIENT_GETTING_OUT_OF_BED,
				value: getConfigurationValue(adminAiSettingsConfigurations[AiSetting.GET_OUT_OF_BED])
					? selectedSensitivity?.value || SensitivityType.MEDIUM
					: null,
				isEnabled,
			},
			{
				settingTypeId: PatientAiSetting.RAILS,
				value: getConfigurationValue(adminAiSettingsConfigurations[AiSetting.RAILS])
					? `${RailType.TOP_RIGHT}-${RailType.TOP_LEFT}`
					: null,
				isEnabled,
			},
			{
				settingTypeId: PatientAiSetting.FALL_DETECTED,
				value: getConfigurationValue(adminAiSettingsConfigurations[AiSetting.FALL_DETECTION]) ? 'true' : 'false',
				isEnabled,
			},
		];

		if (shouldDisableClick(PatientAiSetting.FALL_PREVENTION, isEnabled)) {
			setIsMoreThanOneAi(true);
			return;
		}
		toggleFallPrevention(!isFallPrevention, deviceId);
		if (isFallPrevention) {
			setSelectedSensitivity(null);
			setSelectedRails([]);
		}
		const found = aiSettingList.find(item => item.deviceId === deviceId);
		if (!found) {
			return;
		}

		const sendData = dataToSend.map(item => ({
			isEnabled: item.isEnabled,
			settingTypeId: item.settingTypeId,
			workflowType: CallWorkflowType.MONITORING,
			conferenceId,
		}));

		const params = {
			patientId,
			deviceId,
			roomId,
			sendData,
		};

		await updateAiSettings(params, true);
		setDisabledSettings(prev => ({ ...prev, [PatientAiSetting.FALL_PREVENTION]: false }));
	};

	const shouldDisableClick = (settingTypeId, value) => {
		const settings = _.cloneDeep(aiSettingList);
		const found = settings.find(item => item.deviceId === deviceId);

		const foundInFallPrevention = FallPreventionTypes.find(item => item === settingTypeId);

		const excludedSettings = deviceFamily === DeviceFamilyTypes.HELLO_3 ? ExcludedAiSettingsHello3 : ExcludedAiSettings;

		let isAiSettingEnabled = found.settings.some(
			item => !ExcludedAiSettings.includes(item.settingTypeId) && item.isEnabled && item.settingTypeId !== settingTypeId
		);

		const isIndependentAiActive = isAnySettingActive(found.settings, IncludedAiSettings, settingTypeId, true);

		if (foundInFallPrevention) {
			isAiSettingEnabled = found.settings.reduce((acc, item) => {
				const isValid =
					!ExcludedAiSettings.includes(item.settingTypeId) &&
					item.isEnabled &&
					item.settingTypeId !== settingTypeId &&
					!FallPreventionTypes.includes(item.settingTypeId);

				return acc || isValid;
			}, false);
		}

		return (
			isAiSettingEnabled &&
			value &&
			settingTypeId !== PatientAiSetting.HAND_WASHING &&
			(!excludedSettings.includes(settingTypeId) || isIndependentAiActive)
		);
	};

	const closeFeedAlerts = settingTypeId => {
		const found = aiSettingList.find(item => item.deviceId === deviceId);
		if (!found) {
			return;
		}
		if (settingTypeId !== PatientAiSetting.HAND_WASHING) {
			stopVoiceOver({ alertTypeId: feedWarning.alertTypeId, deviceId });
		} else {
			onAlertInfoClose(deviceId);
		}
	};

	const getUpdatedStatus = (setting, fieldToUpdate) =>
		fieldToUpdate === AiFieldToUpdate.IS_ENABLED ? !setting.isEnabled : setting.isEnabled;

	const toggleAiSettings = async ({ settingTypeId, customValue = null, fieldToUpdate = AiFieldToUpdate.VALUE }) => {
		const found = aiSettingList.find(item => item.deviceId === deviceId);
		if (!found) {
			return;
		}
		const selectedSetting = found.settings.find(item => item.settingTypeId === settingTypeId);

		if (!selectedSetting) {
			return;
		}

		const updatedStatus = getUpdatedStatus(selectedSetting, fieldToUpdate);

		if (shouldDisableClick(settingTypeId, updatedStatus)) {
			setIsMoreThanOneAi(true);
			return;
		}

		const params = {
			conferenceId,
			patientId,
			deviceId,
			roomId,
			fieldToUpdate,
			value: fieldToUpdate === AiFieldToUpdate.IS_ENABLED ? !selectedSetting.isEnabled : customValue,
			id: selectedSetting.id,
		};

		await updateAiSettings(params);
		setDisabledSettings(prev => ({ ...prev, [settingTypeId]: false }));
	};

	const getAiTypes = () => {
		const settings = _.cloneDeep(aiSettingList);
		const found = settings.find(item => item.deviceId === deviceId);
		if (!found) {
			return [];
		}
		return found.settings;
	};

	const getAiTypeValue = aiType => getAiTypes().find(item => item.settingTypeId === aiType)?.isEnabled;

	const isOptionChecked = aiTypeId => {
		if (aiTypeId === PatientAiSetting.FALL_PREVENTION && isFallPrevention) {
			return isFallPrevention;
		}
		return getAiTypeValue(aiTypeId);
	};

	const handleRailsOnSelect = item => {
		setSelectedRails(item);
		const stringifiedValues = item.map(rail => rail.value).join('-');
		toggleAiSettings({ settingTypeId: PatientAiSetting.RAILS, customValue: stringifiedValues });
	};

	const handleBodyPositionOnSelect = (item, { action }) => {
		setSelectedBodyPositions(item);
		const mappedValues = item.map(position => position.value);
		toggleAiSettings({
			settingTypeId: PatientAiSetting.PRESSURE_INJURY,
			customValue: mappedValues,
			fieldToUpdate: AiFieldToUpdate.PRESSURE_INJURY_BODY_POSITIONS,
		});
		if (action === 'deselect-option' && selectedBodyPositions.length === 1) {
			setIsMenuOpen(false);
		}
	};

	const handleOnEnter = event => {
		if (event.which === KeyCodes.ENTER) {
			toggleAiSettings({ settingTypeId: PatientAiSetting.IV_BAG, customValue: event.target.value });
			return;
		}
		event.preventDefault();
	};

	const handlePersonInactive = item => {
		setSelectedInactiveTime(item);
		toggleAiSettings({ settingTypeId: PatientAiSetting.PRESSURE_INJURY, customValue: item.value });
	};

	const handleSettingToggle = settingTypeId => {
		closeFeedAlerts(settingTypeId);
		if (settingTypeId === PatientAiSetting.FALL_PREVENTION) {
			toggleBulkSettings(!isFallPrevention);
		} else {
			toggleAiSettings({ settingTypeId, fieldToUpdate: AiFieldToUpdate.IS_ENABLED });
			if (settingTypeId === PatientAiSetting.IV_BAG) {
				setIvBagPercentage(deviceId, 0);
				setIvBagWarningValue('');
			}
		}
	};

	const getSelectedOptions = settingTypeId => {
		switch (settingTypeId) {
			case PatientAiSetting.AI_VOICE_ANALYSIS:
				return aiVoiceEventOptions;
			case PatientAiSetting.EWS_AI_VITAL_SIGNS:
				return aiMeasurements;
			default:
				return [];
		}
	};

	return (
		<div className='ai-alerts-wrapper'>
			{aiTypesToShow({ adminAiSettingsConfigurations, isDarkMode, intl }).map(
				aiType =>
					!isSettingsClicked &&
					aiType.show && (
						<div
							className={classNames(
								'flex flex-space-between alert-settings',
								aiType.subTitle || getConfiguredAiSettings(aiType.aiTypeId) ? 'flex-wrap' : '',
								aiType.noBorder ? 'no-border-bottom' : '',
								aiType.isTitle ? 'title-alert-setting' : '',
								FallPreventionSettingTypes.includes(aiType.aiTypeId) && !isFallPrevention ? 'disabled' : ''
							)}
							key={aiType.aiTypeId}>
							{aiType.subTitle && <span>{aiType.subTitle}</span>}
							<div className='flex flex-justify-center flex-align-center'>
								{aiType.img}
								<label className='left-s right-s'>{aiType.label}</label>
							</div>
							<div className='flex flex-align-center'>
								<label
									className={classNames(
										'switch-label',
										aiType.aiTypeId === PatientAiSetting.IV_BAG && !isOptionChecked(aiType.aiTypeId) && !ivBagWarningValue
											? 'disabled'
											: ''
									)}>
									<input
										type={isOptionChecked(aiType.aiTypeId) ? 'checkbox' : ''}
										checked={isOptionChecked(aiType.aiTypeId)}
										onClick={() => handleSettingToggle(aiType.aiTypeId)}
										disabled={disabledSettings[aiType.aiTypeId]}
									/>
									<span className='slider-input-alert round' />
								</label>
								{getConfiguredAiSettings(aiType.aiTypeId) && (
									<Button
										className='no-background padding-right-0'
										onClick={() => {
											setIsSettingsClicked(true);
											setSelectedItem(aiType);
										}}
										svgIcon={
											<img
												className='dropdown-icon-img'
												src={`${healthCareCdnUrl}${!isDarkMode ? 'header' : 'dark-mode'}/account-settings.svg`}
												alt={intl.formatMessage({
													id: 'monitoringSettings',
												})}
											/>
										}
									/>
								)}
							</div>
							{aiType.aiTypeId === PatientAiSetting.PRESSURE_INJURY && (
								<div className='flex full-width top-15'>
									<ReactSelect
										options={inactiveHours}
										value={selectedInactiveTime}
										id='inactiveElement'
										onChange={handlePersonInactive}
										classNamePrefix='react-select'
										styles={{
											...generateCustomStyles({ darkMode: user.darkMode }),
											menu: base => ({ ...base, width: '100%' }),
											menuList: base => ({
												...base,
												height: 'fit-content',
												width: '100%',
												backgroundColor: isDarkMode ? DarkTheme.colors.grayThree : LightTheme.colors.grayZero,
											}),
										}}
									/>
								</div>
							)}
							{aiType.aiTypeId === PatientAiSetting.IV_BAG && (
								<>
									<div className='flex flex-basis-100 top-15 flex-align-center'>
										<label className='right-s'>{translate('warningAlertAt')}</label>
										<div className='position-relative'>
											<input
												type='number'
												name='ivBagFluidLevel'
												className='full-width'
												value={ivBagWarningValue}
												onChange={e => setIvBagWarningValue(e.target.value)}
												onKeyDown={event => validateMaxLength(event, 2)}
												onKeyUp={handleOnEnter}
												placeholder={intl.formatMessage({ id: 'writeAndClickEnter' })}
												max={100}
											/>
											<span>%</span>
										</div>
									</div>
									<div className='flex-basis-100 top-15'>
										<small>{translate('enableIvBagWarning')}</small>
									</div>
								</>
							)}
						</div>
					)
			)}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
			<ToastMessage
				showToast={isMoreThanOneAi}
				onClose={() => setIsMoreThanOneAi(false)}
				className={classNames(
					'video-feed-toast-message',
					isNoteShowing || !areEmergencyButtonsHiden ? 'emergency-toast-message' : '',
					isNoteShowing && !areEmergencyButtonsHiden ? 'notes-toast-message' : ''
				)}>
				<span>{translate('noMoreThanOneAiType')}</span>
			</ToastMessage>
			{isSettingsClicked && (
				<>
					<div className='configuration-details-title'>
						<div className='cursor-pointer --blue-color go-back' onClick={() => setIsSettingsClicked(false)}>
							<img src={`${healthCareCdnUrl}monitoring/video-feed/back-arrow.svg`} alt='back arrow icon' />
						</div>
						<p className='semi-bold left-10 font-12'>{translate(selectedItem?.label)}</p>
					</div>
					{selectedItem.aiTypeId === PatientAiSetting.PATIENT_GETTING_OUT_OF_BED && (
						<>
							<p className='semi-bold font-14'>{intl.formatMessage({ id: 'sensitivity' })}</p>
							<div className='flex full-width top-15 ai-configurations-select no-border-bottom margin-bottom-0'>
								<ReactSelect
									options={sensitivityTypes}
									value={selectedSensitivity}
									onChange={item => {
										setSelectedSensitivity(item);
										toggleAiSettings({ settingTypeId: PatientAiSetting.PATIENT_GETTING_OUT_OF_BED, customValue: item.value });
									}}
									classNamePrefix='react-select'
									placeholder={intl.formatMessage({ id: 'sensitivity' })}
									formatOptionLabel={data => aiFormatOptionLabel(data, intl)}
									styles={generateCustomStyles({ darkMode: user.darkMode })}
								/>
							</div>
						</>
					)}
					{selectedItem.aiTypeId === PatientAiSetting.RAILS && (
						<AiMultipleSelect
							isMenuOpen={isMenuOpen}
							options={railsOptions}
							selectedOptions={selectedRails}
							handleOnSelect={handleRailsOnSelect}
							setIsMenuOpen={setIsMenuOpen}
							customContent='rails'
							menulistTitle='sendRailsAlert'
							placeholder='selectRails'
						/>
					)}
					{selectedItem.aiTypeId === PatientAiSetting.PRESSURE_INJURY && (
						<AiMultipleSelect
							isMenuOpen={isMenuOpen}
							options={bodyPostionOptions}
							selectedOptions={selectedBodyPositions}
							handleOnSelect={handleBodyPositionOnSelect}
							setIsMenuOpen={setIsMenuOpen}
							customContent='patientPositions'
							menulistTitle='sendPatientPositionAlert'
							placeholder='selectPatientPosition'
						/>
					)}
					{[PatientAiSetting.AI_VOICE_ANALYSIS, PatientAiSetting.EWS_AI_VITAL_SIGNS].includes(selectedItem.aiTypeId) && (
						<AiCustomConfigurations
							toggleAiSettings={toggleAiSettings}
							aiSettingList={aiSettingList}
							deviceId={deviceId}
							settingTypeId={selectedItem.aiTypeId}
							selectedOptions={getSelectedOptions(selectedItem.aiTypeId)}
							fieldToUpdate={getAiFieldBySetting(selectedItem.aiTypeId)}
						/>
					)}
					{getConfiguredAiSettings(selectedItem.aiTypeId) && (
						<AiCommonConfigurations
							deviceId={deviceId}
							patientId={patientId}
							selectedItem={selectedItem}
							roomId={roomId}
							adminAiConfigs={adminAiConfigs}
							isRounding={isRounding}
						/>
					)}
					{selectedItem.aiTypeId === PatientAiSetting.PATIENT_MOBILITY && (
						<AiPatientMobilityHistory patientId={patientId} deviceId={deviceId} isDarkMode={isDarkMode} />
					)}
				</>
			)}
		</div>
	);
};

export default AiPatientSettings;
