import classNames from 'classnames';
import ProfilePicture from 'components/Common/ProfilePicture.jsx';
import { WarningIndexes } from 'constants/enums.js';
import { VitalSignsList } from 'constants/rpm.js';
import translate from 'i18n-translations/translate.jsx';
import Pill from 'icons/RPM/Pill.jsx';
import { stringToCamelCase } from 'infrastructure/helpers/commonHelpers.js';
import { useIntl } from 'react-intl';
import OverviewDetails from 'containers/HospitalAtHome/OverviewDetails.jsx';
import RecentMetrics from 'containers/HospitalAtHome/RecentMetrics.jsx';
import Button from 'components/Common/Button.jsx';
import ContinuousPatientMonitoring from 'containers/Rpm/ContinuousPatientMonitoring.jsx';
import PatientScheduledAppointments from 'containers/HospitalAtHome/PatientScheduledAppointments.jsx';
import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';
import { useSelector } from 'react-redux';

const OverviewTab = ({
	goToRecentMetric,
	goToMedications,
	goToAlerts,
	goToCareTeam,
	goToTelemetry,
	medications,
	careTeam,
	alerts,
	shouldShowSchedule = true,
	selectedPatient,
}) => {
	const intl = useIntl();
	const darkMode = useSelector(state => state.user.darkMode);
	return (
		<div className='overview-container patient-tab-wrapper'>
			<div className='left-items'>
				{shouldShowSchedule && <PatientScheduledAppointments selectedPatient={selectedPatient} />}
				<OverviewDetails title='medication' onClick={goToMedications}>
					{medications.length > 0 &&
						[...medications].splice(0, 4).map(item => (
							<div key={item.id} className='flex flex-space-between rpm-medication-wrapper'>
								<div className='flex right-align-items'>
									<Pill className='medication-icon' color={darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />
									<div className='column-direction'>
										<p className='no-margin'>{item.medicine?.name}</p>
										<span>
											{item.dosage} {item.medicine?.strengthAndForm}
										</span>
									</div>
								</div>
							</div>
						))}
					{medications.length === 0 && (
						<div className='flex flex-justify-center'>
							<p className='semi-bold'>{translate('noResultsFound')}</p>
						</div>
					)}
				</OverviewDetails>

				<OverviewDetails className='top-10' title='alerts' onClick={goToAlerts}>
					<div className='patient-summary-table-wrapper'>
						{alerts.map(item => (
							<div key={item.id} className='patient-request-list-item cursor-pointer'>
								<div className='alert-icon'>
									<img src={VitalSignsList.find(items => items.type === item.measurement.type)?.icon} alt='ico' />
								</div>
								<div className='flex column-direction'>
									<p>
										{`${intl.formatMessage({ id: stringToCamelCase(item.measurement.type) })} ${intl.formatMessage({
											id: 'wasDetected',
										})} ${item.measurement.value} ${item.measurement.unit}`}
									</p>
									<span
										className={classNames(
											[WarningIndexes.LOW, WarningIndexes.HIGH].includes(item.type.warningIndex) ? 'moderate-risk' : 'high-risk'
										)}>
										{[WarningIndexes.LOW, WarningIndexes.HIGH].includes(item.type.warningIndex)
											? translate('moderateRisk')
											: translate('highRisk')}
									</span>
								</div>
							</div>
						))}
						{alerts.length === 0 && (
							<div className='flex flex-justify-center'>
								<p className='semi-bold'>{translate('noResultsFound')}</p>
							</div>
						)}
					</div>
				</OverviewDetails>
			</div>
			<div className='right-items'>
				<RecentMetrics goToRecentMetric={goToRecentMetric} selectedPatient={selectedPatient} isOverview={true} />
				<div className='overview-table-wrapper top-10'>
					<div className='flex flex-align-center flex-space-between margin-bottom-m'>
						<span className='bold-weight'>{translate('telemetry')}</span>
						<Button variant='white' className='flex-align-center' text={translate('showAll')} onClick={goToTelemetry} />
					</div>
					<ContinuousPatientMonitoring selectedPatient={selectedPatient} isOverview={true} />
				</div>
				<OverviewDetails className='top-10' title='careTeam' onClick={goToCareTeam}>
					{[...careTeam].splice(0, 3).map(member => (
						<div className='flex flex-space-between'>
							<div className='flex flex-align-center'>
								<ProfilePicture
									firstName={member.firstName}
									lastName={member.lastName}
									profilePicture={member.profilePicture}
									className='doctor-request-img'
								/>
								<div className='care-team-details'>
									<p>
										{member.firstName} {member.lastName}
									</p>
									<span>{member.specialty?.name}</span>
								</div>
							</div>
						</div>
					))}
				</OverviewDetails>
			</div>
		</div>
	);
};

export default OverviewTab;
