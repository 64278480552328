import React, { useEffect, useRef, useState } from 'react';
import { APP_CONFIG, healthCareCdnUrl } from 'constants/global-variables.js';
import Alert from 'components/Common/Alert.jsx';
import translate from 'i18n-translations/translate.jsx';

const BuildVersion = () => {
	const [displayContentUpdatedAlert, setDisplayContentUpdatedAlert] = useState(false);
	const versionTimeout = useRef(null);

	const shouldDisplayReleaseVersion = (reactAppBuildNumber, shouldShowReleaseVersion) =>
		APP_CONFIG.buildNumber &&
		reactAppBuildNumber &&
		APP_CONFIG.buildNumber !== reactAppBuildNumber &&
		shouldShowReleaseVersion === 'true';

	const startVersionTimeout = () => {
		const compareVersions = async () => {
			try {
				const response = await fetch('/env/react-config.js');
				let configText = await response.text();
				configText = configText.replace(/'/g, `"`);
				const { VITE_BUILD_NUMBER, VITE_SHOW_RELEASE_VERSION } = JSON.parse(
					configText.substring(configText.indexOf('{'), configText.lastIndexOf('}') + 1)
				);
				if (shouldDisplayReleaseVersion(VITE_BUILD_NUMBER, VITE_SHOW_RELEASE_VERSION)) {
					setDisplayContentUpdatedAlert(true);
				}
			} catch (err) {
				// eslint-disable-next-line no-console
				console.error(err);
			}
			start();
		};

		const start = () => {
			if (versionTimeout.current) {
				clearTimeout(versionTimeout.current);
			}
			const versionTimeoutInterval = +APP_CONFIG.versionCheckInterval || 10;
			versionTimeout.current = setTimeout(
				() => {
					compareVersions();
				},
				1000 * 60 * versionTimeoutInterval
			);
		};

		start();
	};

	useEffect(() => {
		startVersionTimeout();
	}, []);

	return (
		<>
			<div className='new-build-available'>
				<Alert
					display={displayContentUpdatedAlert}
					fixed={true}
					persist={true}
					variant='dark'
					hideCloseButton={true}
					message={
						<div className='flex'>
							<span className='cursor-pointer' onClick={() => window.location.reload()}>
								<img src={`${healthCareCdnUrl}new-build.svg`} alt='new version icon' />
							</span>
							<div>
								<p>{translate('newVersionAvailable')}</p>

								<p>
									{translate('reloadLatestFeatures', {
										value: (
											<span className='cursor-pointer' onClick={() => window.location.reload()}>
												{translate('reload')}
											</span>
										),
									})}
								</p>
							</div>
						</div>
					}
					onClose={() => setDisplayContentUpdatedAlert(false)}
				/>
			</div>
		</>
	);
};

export default BuildVersion;
