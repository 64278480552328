import React, { useState } from 'react';
import 'react-day-picker/dist/style.css';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from 'components/Common/Tabs.jsx';
import Grid from 'components/Common/Grid.jsx';
import PastCallsList from 'containers/CallLogs/PastCallsList.jsx';
import OngoingCallsList from 'containers/CallLogs/OngoingCallsList.jsx';
import translate from 'i18n-translations/translate.jsx';

const CallLogs = () => {
	const [currentTab, setCurrentTab] = useState(0);
	return (
		<MainLayout>
			<div className='call-logs-view'>
				<Grid columns='0 3fr' stretch='100%'>
					<aside className='hello-list' />
					<main className='main-view'>
						<section>
							<div className='full-width'>
								<h3>{translate('callLogs')}</h3>
								<Tabs
									activeIndex={currentTab}
									onChange={index => {
										setCurrentTab(index);
									}}>
									<TabList>
										<Tab>{translate('ongoing')}</Tab>
										<Tab>{translate('past')}</Tab>
									</TabList>
									<TabPanels>
										<TabPanel>
											<OngoingCallsList />
										</TabPanel>
										<TabPanel>
											<PastCallsList />
										</TabPanel>
									</TabPanels>
								</Tabs>
							</div>
						</section>
					</main>
				</Grid>
			</div>
		</MainLayout>
	);
};

export default CallLogs;
