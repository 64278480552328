import { getRoundingKPIs } from 'api/dashboard.js';
import { Grid, Loader } from 'components/index.js';
import { RoundingOrigins } from 'constants/dashboard.jsx';
import translate from 'i18n-translations/translate.jsx';
import { Duration, DurationAvg, Incoming, Outgoing, RoomSignage, View } from 'icons/Dashboard/index.js';
import { capitalizeEveryFirstLetter } from 'infrastructure/helpers/commonHelpers.js';
import { formatTimeDurationWithSeconds, timezoneToUTCTimestamp } from 'infrastructure/helpers/dateHelper.js';
import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const ChartWrapper = styled.div`
	border: 1px solid var(--gray-15);
	background: var(--gray-0);
	padding: var(--spacing-xl);
	display: flex;
	flex-direction: column;
	flex-grow: 1;

	> div {
		margin-top: var(--spacing-xl);
		flex-grow: 1;
		display: flex;
		gap: 10px;
		div {
			flex: 1 1 0;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding: var(--spacing-m);
			background: ${props => `${props.$type.color}1A`};
			h4,
			p {
				margin: 0;
				padding: 0;
				font-size: 14px;
			}
			h4 {
				font-size: 26px;
				font-weight: 400;
			}

			&:not(:first-of-type) {
				h4 {
					font-size: 18px;
					white-space: nowrap;
				}
				p {
					font-size: 12px;
				}
			}
		}
	}
	i.material-icons-outlined {
		font-size: 20px;
		color: var(--gray-4);
	}
`;

const RoundingKPIs = ({
	roundingType,
	roundingOrigin,
	start,
	end,
	selectedHealthSystem,
	selectedHospital,
	selectedDepartment,
	selectedFloor,
	selectedRoom,
	selectedTimezone,
	additionalFilters,
	setError,
	intl,
}) => {
	const [isLoading, setIsLoading] = useState(true);
	const [roundingStats, setRoundingStats] = useState({ totalSessions: 0, avgDuration: 0, totalDuration: 0 });
	const controller = useRef(null);

	const fetchData = useCallback(
		debounce(async (params, type, signal) => {
			setIsLoading(true);
			const response = await getRoundingKPIs(params, type, signal);

			if (!response.error) {
				const { totalSessions, avgDuration, totalDuration } = response.sessionKpis;
				setRoundingStats({ totalSessions, avgDuration, totalDuration });
			} else if (response.error.code !== 'ERR_CANCELED') {
				setError(response.error.message);
			}
			setIsLoading(false);
		}, 500),
		[]
	);

	useEffect(() => {
		if (controller.current) {
			controller.current.abort();
		}
		controller.current = new AbortController();
		const signal = controller.current.signal;

		const params = {
			start: timezoneToUTCTimestamp(start, selectedTimezone.zone),
			end: timezoneToUTCTimestamp(end, selectedTimezone.zone),
			callOrigin: roundingOrigin.type,
			healthSystemId: selectedHealthSystem,
			regionId: selectedHospital?.regionId,
			hospitalId: selectedHospital?.value,
			departmentId: selectedDepartment?.value,
			floorId: selectedFloor?.value,
			roomId: selectedRoom?.value,
			...additionalFilters,
		};

		fetchData(params, roundingType, signal);
	}, [start, end, selectedHealthSystem, selectedHospital, selectedDepartment, selectedFloor, selectedRoom, additionalFilters]);

	useEffect(() => {
		return () => {
			controller.current?.abort();
		};
	}, []);

	return (
		<ChartWrapper $type={roundingOrigin}>
			<header className='flex flex-space-between'>
				<h3>
					{[RoundingOrigins.INCOMING, RoundingOrigins.OUTGOING].includes(roundingOrigin) &&
						`${capitalizeEveryFirstLetter(intl.formatMessage({ id: 'talkToPatient' }))} - `}
					{translate(roundingOrigin.title)} {translate('sessions')}
				</h3>
				{roundingOrigin.description && (
					<i
						className='material-icons-outlined'
						data-tooltip={intl.formatMessage({ id: roundingOrigin.description })}
						data-position='left'>
						info
					</i>
				)}
			</header>
			{isLoading && (
				<Grid columns='1fr' rows='1fr' stretch='70px' horizAlign='center' vertAlign='center'>
					<Loader />
				</Grid>
			)}
			{!isLoading && (
				<>
					<div>
						<div>
							{roundingOrigin === RoundingOrigins.OUTGOING && <Outgoing />}
							{roundingOrigin === RoundingOrigins.INCOMING && <Incoming />}
							{roundingOrigin === RoundingOrigins.VIEW_PATIENT && <View />}
							{roundingOrigin === RoundingOrigins.ROOM_SIGNAGE && <RoomSignage />}
							<h4>{roundingStats.totalSessions}</h4>
							<p>{translate('totalSessions')}</p>
						</div>
						<div>
							<DurationAvg color={roundingOrigin.color} />
							<h4>{formatTimeDurationWithSeconds({ seconds: roundingStats.avgDuration, joinWith: ' ', showDays: true })}</h4>
							<p>{translate('averageSessionDuration')}</p>
						</div>
						<div>
							<Duration color={roundingOrigin.color} />
							<h4>{formatTimeDurationWithSeconds({ seconds: roundingStats.totalDuration, joinWith: ' ', showDays: true })}</h4>
							<p>{translate('totalSessionsDuration')}</p>
						</div>
					</div>
				</>
			)}
		</ChartWrapper>
	);
};

export default RoundingKPIs;
